export const SetupConstants = {
  roles: {
    ROLE_STATUS_ACTIVE: 1,
    ROLE_STATUS_INACTIVE: 0,
    ROLE_TYPE_SITEIQ: "S",
    ROLE_TYPE_SITEOWNER: "O",
    ROLE_TYPE_SERVICEPROVIDER: "P",
  },
  organizations: {
    COMPANY_TYPE_SITEIQ: "S",
    COMPANY_TYPE_SITEOWNER: "O",
    COMPANY_TYPE_SERVICEPROVIDER: "P",
    ENTITY_TYPE_COMPANY: "C",
    ENTITY_TYPE_DIVISION: "D",
    ENTITY_TYPE_SITE: "S",
    ORGANIZATION_STATUS_ACTIVE: 1,
    ORGANIZATION_STATUS_INACTIVE: 0,
    INCLUDE_ROOT_TRUE: 1,
    INCLUDE_ROOT_FALSE: 0,
  },
  sites: {
    SITE_STATUS_ACTIVE: 1,
    SITE_STATUS_INACTIVE: 0,
  },
  users: {
    USER_TYPE_SITEIQ: "S",
    USER_TYPE_SITEOWNER: "O",
    USER_TYPE_SERVICEPROVIDER: "P",
    USER_STATUS_ACTIVE: 1,
    USER_STATUS_INACTIVE: 0,
    LOGIN_STATUS_ACTIVE: 1,
    LOGIN_STATUS_INACTIVE: 0,
  },
  highFlowDispenserFlowrate: [{ key: 21, value: '21' }, { key: 22, value: '22' }, { key: 23, value: '23' }, { key: 24, value: '24' }, { key: 25, value: '25' }, { key: 26, value: '26' }, { key: 27, value: '27' }, { key: 28, value: '28' }, { key: 29, value: '29' }, { key: 30, value: '30' }, { key: 31, value: '31' }, { key: 32, value: '32' }, { key: 33, value: '33' }, { key: 34, value: '34' }, { key: 35, value: '35' }, { key: 36, value: '36' }, { key: 37, value: '37' }, { key: 38, value: '38' }, { key: 39, value: '39' }, { key: 40, value: '40' }],
  lowFlowDispenserFlowrate: [{ key: 1, value: '1' }, { key: 2, value: '2' }, { key: 3, value: '3' }, { key: 4, value: '4' }, { key: 5, value: '5' }, { key: 6, value: '6' }, { key: 7, value: '7' }, { key: 8, value: '8' }, { key: 9, value: '9' }, { key: 10, value: '10' }, { key: 11, value: '11' }, { key: 12, value: '12' }]
};
