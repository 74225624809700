import React, { Component } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { configureStore } from "./sram/store/store";
import AppUtils from "./sram/core/helpers/app-utils";
import BlankLayout from "./sram/layout/blank-layout";
import FullLayout from "./sram/layout/full-layout";
import PrivateRoute from "./sram/routes/private-route";
import ErrorBoundary from "./sram/views/error-boundary";
import siteVisitor from "./sram/core/components/site-visitor";

class App extends Component {
  render() {
    AppUtils.setRequestHeaders();
    return (
      <ErrorBoundary>
        <Provider store={configureStore()}>
          <BrowserRouter basename="/">
            <Switch>
              <Route path="/login" component={BlankLayout} />
              <Route path="/recover-password" component={BlankLayout} />
              {/* <Route path="/join-now" component={BlankLayout} /> */}
              <Route path="/client-approval" exact component={siteVisitor} />
              <Route path="/error" component={BlankLayout} />
              <Route path="/404" component={BlankLayout} />
              <PrivateRoute path="/" component={FullLayout} />
            </Switch>
          </BrowserRouter>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
