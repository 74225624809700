import React, { Component } from "react";
import { Card, CardTitle, CardBody, Row, Col } from "reactstrap";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import { SetupURLProps } from "../settings/setup-urls";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { ServiceProviderMsgResProps } from "../messages/service-provider-properties";
import { manageError } from "../../core/actions/common-actions";
import { isPagePartPrivileged } from "../../core/actions/identity-actions";
import { getDivision } from "../actions/service-provider-actions";

class ServiceProviderDivisionView extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcServiceProviderDivisionView = "AS-SPV-DVVW";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      orgDivision: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    const organizationId = this.props.match.params.oid;
    await this.loadPageData(organizationId);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const organizationId = this.props.match.params.oid;
    if (organizationId !== prevProps.match.params.oid) {
      PageUtils.scrollToTop();
      await this.loadPageData(organizationId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async (organizationId) => {
    if (
      organizationId === null ||
      organizationId === undefined ||
      organizationId !== parseInt(organizationId).toString()
    )
      this.props.history.push(SetupURLProps.serviceProviders.listDivision);

    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ orgDivision: null });
    await this.checkPartPrivilege();
    await getDivision(organizationId, this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ orgDivision: res.data.result });
        } else {
          this.props.history.push(SetupURLProps.serviceProviders.listCompany);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcServiceProviderDivisionView}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            {this.state.orgDivision ? (
              <Card className="sram-page-view">
                <CardTitle>
                  <span>
                    {
                      ServiceProviderMsgResProps.body.content
                        .serviceProviderViewDivision
                    }
                  </span>
                  <span className="float-right">
                    {PageDisplay.showGoBackLink(
                      "L",
                      SetupURLProps.serviceProviders.listDivision,
                      this.props.history
                    )}
                  </span>
                </CardTitle>
                <CardBody>
                  <div className="sram-view-main">
                    <div className="sram-view-data">
                      <Row form>
                        {this.state.orgDivision.parentOrganization ? (
                          <Col sm={12} md={12} lg={12}>
                            {PageDisplay.getFormFieldLabelValue(
                              ServiceProviderMsgResProps.body.form
                                .parentCompanyDivision.label,
                              this.state.orgDivision.parentOrganization
                                .organizationTreepath
                            )}
                          </Col>
                        ) : null}
                      </Row>

                      <Row form>
                        <Col sm={12} md={12} lg={12}>
                          {PageDisplay.getFormFieldLabelValue(
                            ServiceProviderMsgResProps.body.form.divisionName
                              .label,
                            this.state.orgDivision.organizationName
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            ServiceProviderMsgResProps.body.form.streetAddress1
                              .label,
                            this.state.orgDivision.streetAddress1
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            ServiceProviderMsgResProps.body.form.streetAddress2
                              .label,
                            this.state.orgDivision.streetAddress2
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            ServiceProviderMsgResProps.body.form.city.label,
                            this.state.orgDivision.city
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            ServiceProviderMsgResProps.body.form.state.label,
                            this.state.orgDivision.state
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            ServiceProviderMsgResProps.body.form.country.label,
                            this.state.orgDivision.country
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            ServiceProviderMsgResProps.body.form.zipCode.label,
                            this.state.orgDivision.zipCode
                          )}
                        </Col>
                      </Row>
                      {/**  
                    <Row form>
                      <Col sm={12} md={6} lg={6}>
                        {PageDisplay.getFormFieldLabelValue(
                          ServiceProviderMsgResProps.body.form.locationLatitude
                            .label,
                          this.state.orgDivision.locationLatitude
                        )}
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        {PageDisplay.getFormFieldLabelValue(
                          ServiceProviderMsgResProps.body.form.locationLongitude
                            .label,
                          this.state.orgDivision.locationLongitude
                        )}
                      </Col>
                    </Row>
                    */}
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  ServiceProviderMsgResProps.body.content.noDataFound
                )}
              </>
            )}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default ServiceProviderDivisionView;
