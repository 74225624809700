import React, { Component } from "react";
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Alert,
  Spinner,
  Row,
  Col,
} from "reactstrap";
import axios from "axios";
import { deleteDevices } from "../actions/device-actions";
import { DeviceMsgResProps } from "../messages/device-properties";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";

class DeleteDevice extends Component {
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  constructor(props) {
    super(props);
    this.state = {
      showSuccessResponse: false,
      showErrorResponse: false,
      dispenserId: null,
      submitspinner: false
    }
    this.handleDeleteDevice = this.handleDeleteDevice.bind(this);
  }

  componentWillReceiveProps() {
    this.setState({ showSuccessResponse: false, showErrorResponse: false })
  }
  async handleDeleteDevice(e) {
    e.preventDefault();
    this.setState({ showSuccessResponse: false, showErrorResponse: false, submitspinner: true });
    let dispenserId = this.props.dispenserItem?.dispenserId;
    await deleteDevices(dispenserId, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          this.setState({ showSuccessResponse: true, submitspinner: false });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        this.setState({ showErrorResponse: true, submitspinner: false });
        // await manageError(err, this.props.history);
      });
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.isOpenDeviceModal}
          toggle={this.props.toggleDeviceModal}
          size="md"
          backdrop="static"
          centered={false}
          className="sram-modal-content"
        >
          <ModalHeader>{DeviceMsgResProps.body.content.deleteDevice}</ModalHeader>
          <ModalBody>
            <Row>
              <Col sm={12} md={12} lg={12}>
                <h5 className="font-mild">
                  {this.props.dispenserItem?.siteName} &nbsp; Dispenser &nbsp;
                  {this.props.dispenserItem?.sideANumber +
                    "/" +
                    this.props.dispenserItem?.sideBNumber}
                </h5>
              </Col>
              <Col>
                <Form onSubmit={(e) => e.preventDefault()} noValidate>
                  {this.state.showSuccessResponse === true ?
                    <div>
                      <Alert color="success">
                        {DeviceMsgResProps.body.notification.success.deleteDevice}
                      </Alert>
                    </div>
                    : null}
                  {this.state.showSuccessResponse === false ?
                    <Label>
                      {DeviceMsgResProps.body.notification.confirmation.deleteDeviceTitle}
                    </Label>
                    : null}
                  {this.state.showErrorResponse === true ?
                    <div>
                      <Alert color="warning">
                        {DeviceMsgResProps.body.notification.error.message}
                      </Alert>
                    </div>
                    : null}
                </Form>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="m-1 p-2">
            {this.state.showErrorResponse === true ?
              <>
                <Button
                  type="button"
                  className="sram-btn-blue mx-2"
                  onClick={this.handleDeleteDevice}
                >
                  {DeviceMsgResProps.body.form.retry.label}
                  {this.state.submitspinner === true ?
                    <Spinner
                      size="sm"
                      color="light"
                      style={{ marginBottom: "2px" }}
                    />
                    : null
                  }
                </Button>
                <Button
                  type="button"
                  className="sram-btn-white mx-2"
                  onClick={this.props.toggleDeviceModal}
                >
                  {DeviceMsgResProps.body.form.no.label}
                </Button>
              </>
              :
              this.state.showSuccessResponse === true ?
                <Button
                  type="button"
                  className="sram-btn-white mx-2"
                  onClick={this.props.toggleDeviceModal}
                >
                  {DeviceMsgResProps.body.form.close.label}
                </Button>
                :
                <>
                  <Button
                    type="button"
                    className="sram-btn-blue mx-2"
                    onClick={this.handleDeleteDevice}
                  >
                    {DeviceMsgResProps.body.form.yes.label}
                    {this.state.submitspinner === true ?
                      <Spinner
                        size="sm"
                        color="light"
                        style={{ marginBottom: "2px" }}
                      />
                      : null
                    }
                  </Button>
                  <Button
                    type="button"
                    className="sram-btn-white mx-2"
                    onClick={this.props.toggleDeviceModal}
                  >
                    {DeviceMsgResProps.body.form.no.label}
                  </Button>
                </>
            }
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default DeleteDevice;
