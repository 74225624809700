import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Spinner,
  Input,
} from "reactstrap";
import PageDisplay from "../../core/helpers/page-display";
import { AlertMsgResProps } from "../messages/alert-properties";
import { manageError } from "../../core/actions/common-actions";
import { AppMsgResProps } from "../../core/messages/app-properties";
import axios from "axios";
import { updateStatus } from "../actions/alert-actions";
import { AppConfigProps } from "../../core/settings/app-config";
import WithLoading from "../../core/helpers/with-loading";
// import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

class AlertStatusUpdateModal extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  constructor(props) {
    super(props);
    this.state = {
      alertStatus: [
        {
          name: "In Progress",
          value: "I",
        },
        {
          name: "Pending Close",
          value: "P",
        },
        {
          name: "Closed",
          value: "C",
        },
      ],
      workInProgress: [
        {
          label: "Contact Site",
          value: "Contact Site",
          checked: true,
        },
        {
          label: "Reset/Reboot",
          value: "Reset/Reboot",
        },
        {
          label: "Contact Customer",
          value: "Contact Customer",
        },
        {
          label: "Dispatched to Branch",
          value: "Dispatched to Branch",
        },
        {
          label: "Software Upgrades",
          value: "Software Upgrades",
        },
        {
          label: "Software Bug Fix",
          value: "Software Bug Fix",
        },
      ],
      resolutionCategoryPendingClosed: [
        {
          name: "Dispatched to Vendor/3rd Party",
          value: "Dispatched to Vendor/3rd Party",
        },
        {
          name: "Customer Rejected",
          value: "Customer Rejected",
        },
        {
          name: "No Response from Customer",
          value: "No Response from Customer",
        },
        {
          name: "Credit Hold",
          value: "Credit Hold",
        },
      ],
      resolutionCategoryClosed: [
        {
          name: "Remote Fix",
          value: "Remote Fix",
        },
        {
          name: "Resolved by Branch",
          value: "Resolved by Branch",
        },
        {
          name: "Customer Resolved",
          value: "Customer Resolved",
        },
        {
          name: "Resolved by Vendor/3rd Party",
          value: "Resolved by Vendor/3rd Party",
        },
      ],
      actionTaken: [
        {
          name: "Warm Start",
          value: "Warm Start",
        },
        {
          name: "Software Reset",
          value: "Software Reset",
        },
        {
          name: "Software Upgrades",
          value: "Software Upgrades",
        },
        {
          name: "Filters Replaced",
          value: "Filters Replaced",
        },
        {
          name: "Remote Fix",
          value: "Remote Fix",
        },
      ],
      resolutionCategoryCustomerRejected: [
        {
          name: "Customer Rejected",
          value: "Customer Rejected",
        },
      ],
      resolutionCategoryPCNClosed: [
        {
          name: "Remote Fix",
          value: "Remote Fix",
        },
        {
          name: "Resolved by Branch",
          value: "Resolved by Branch",
        },
        {
          name: "Customer Resolved",
          value: "Customer Resolved",
        },
        {
          name: "Resolved by Vendor/3rd Party",
          value: "Resolved by Vendor/3rd Party",
        },
        {
          name: "Customer Rejected",
          value: "Customer Rejected",
        },
        {
          name: "Customer Ignored",
          value: "Customer Ignored",
        },
        {
          name: "Credit Hold",
          value: "Credit Hold",
        },
        {
          name: "Existing Branch Ticket",
          value: "Existing Branch Ticket",
        },
      ],
      resolutionCategoryCrindGreaterClosed: [
        {
          name: "Remote Fix",
          value: "Remote Fix",
        },
        {
          name: "Resolved by Branch",
          value: "Resolved by Branch",
        },
        {
          name: "Customer Resolved",
          value: "Customer Resolved",
        },
        {
          name: "Resolved by Vendor/3rd Party",
          value: "Resolved by Vendor/3rd Party",
        },
        {
          name: "Customer Ignored",
          value: "Customer Ignored",
        },
        {
          name: "Credit Hold",
          value: "Credit Hold",
        },
        {
          name: "Existing Branch Ticket",
          value: "Existing Branch Ticket",
        },
      ],
      selectedActionTaken: "",
      alertStatusValue: "",
      notes: "",
      wip: "",
      purchaseOrder: "",
      isSubmitting: false,
      error: null,
      error1: null,
      error2: null,
      ticketAlertStatus: null,
      showCloseError: false,
      statusMessage: null,
      isTicketConfirmModal: false,
      approvedToCloseTicketStatus: "",
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    // PageUtils.scrollToTop();
    window.appHistory = this.props.history;
    this.setStateAsync({ showCloseError: false });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  async componentWillReceiveProps() {
    this.setStateAsync({
      ticketAlertStatus: this.props.ticketAlertEventStatus,
    });
    // if(this.props.statusDataFetched){
    // if(this.props.status === "Pending Close"){
    //   await this.setStateAsync({alertStatusValue: 'P'})
    // }
    if (this.props.status === "In Progress") {
      await this.setStateAsync({ alertStatusValue: "I" });
    }
    // }
  }

  handleChange = async (e) => {
    await this.setStateAsync({
      alertStatusValue: e.target.value,
      error: null,
      error1: null,
      wip: "",
    });
    if (this.state.alertStatusValue === "C") {
      await this.setStateAsync({ wip: this.props.statusData });
    }
  };
  handleWIP = async (e) => {
    await this.setStateAsync({
      wip: e.target.value,
      error: null,
      error1: null,
    });
    // this.setStateAsync({ wip: "" });
    // if (e && e.length > 0) {
    //   await this.setStateAsync({ wip: e, error: null, error1: null });
    // } else {
    //   await this.setStateAsync({ error1: "" });
    // }
  };
  handleResolution = async (e) => {
    await this.setStateAsync({
      wip: e.target.value,
      error: null,
      error1: null,
    });
  };
  handleActionTaken = async (e) => {
    await this.setStateAsync({ selectedActionTaken: e.target.value });
  };
  handleNotes = async (e) => {
    let notes = e.target.value.replace(/[\\,&*<>/]/g, "");
    await this.setStateAsync({ notes: notes, error2: e.target.value });
  };
  handlePOChange = async (e) => {
    await this.setStateAsync({ purchaseOrder: e.target.value });
  };
  updateTicketStatus = async () => {
    await this.setStateAsync({
      approvedToCloseTicketStatus: "Yes",
    });
    await this.checkStatus();
  };

  checkStatus = async () => {
    if (this.state.error2 === null) {
      await this.setStateAsync({ error2: "" });
    }
    if (this.state.alertStatusValue) {
      if (this.state.wip && this.state.error1 !== "") {
        if (this.state.error2 !== null && this.state.error2 !== "") {
          // if (this.state.alertStatusValue === "C") {
          this.setStateAsync({ showCloseError: true });
          // } else {
          await this.updateStatus();
          // }
        } else {
          await this.setStateAsync({ error2: "" });
        }
      } else {
        await this.setStateAsync({ error1: "" });
      }
    } else {
      await this.setStateAsync({ error: "" });
    }
  };

  updateStatus = async () => {
    await this.setStateAsync({ isSubmitting: true });
    var changeType = "";
    if (this.state.alertStatusValue === "C") {
      changeType = "C";
    } else {
      changeType = "S";
    }
    var changeCategory = [];
    if (this.state.alertStatusValue === "I") {
      // for (const data of this.state.wip) {
      //   changeCategory.push(data.value);
      // }
      changeCategory.push(this.state.wip);
    } else {
      changeCategory.push(this.state.wip);
    }
    // changeCategory = this.state.wip
    let purchaseOrder = "";
    if (this.state.purchaseOrder) {
      purchaseOrder = this.state.purchaseOrder;
    } else if (this.props.purchaseOrder) {
      purchaseOrder = this.props.purchaseOrder;
    }

    const ticketObj = {
      alertTicketId: this.props.alertTicketId,
      changeType: changeType,
      changeValue: this.state.alertStatusValue,
      changeCategory: changeCategory,
      actionTaken: this.state.selectedActionTaken,
      customerPurchaseOrder: purchaseOrder,
      changeNotes: this.state.notes,
    };
    if (this.state.approvedToCloseTicketStatus !== "") {
      Object.assign(ticketObj, { approvedToCloseTicket: "Yes" });
    }
    await updateStatus(ticketObj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data
        ) {
          this.props.closeAlertStatusUpdateModal();
          this.props.getAlertTickets();
          this.props.getHistory(this.props.alertTicketId);
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        if (err.data && err.data.errors && err.data.errors.length > 0) {
          const errors = err.data.errors;
          errors.forEach(async (error) => {
            if (error.element && error.message && error.location === "body") {
              const statusMsg = ["E", error.message];
              await this.setStateAsync({ statusMessage: statusMsg });
              // helpers.setFieldError(error.element, error.message);
            } else if (error.message) {
              const statusMsg = ["S", error.message];
              await this.setStateAsync({ statusMessage: statusMsg });
              await this.openTicketConfirmModal();
            }
          });
        }

        // await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isSubmitting: false });
  };

  closeTicketConfirmModal = async () => {
    await this.setStateAsync({
      isTicketConfirmModal: false,
      approvedToCloseTicketStatus: "",
    });
  };

  openTicketConfirmModal = async () => {
    await this.setStateAsync({
      isTicketConfirmModal: true,
      approvedToCloseTicketStatus: "",
    });
  };

  render() {
    // let selected= [{
    //   label: "Contact Site",
    //   value: "Contact Site"
    // },
    // {
    //   label: "Refresh/Reset",
    //   value: "Refresh/Reset",
    // }]
    const hasPcn =
      this.props?.ticketCurrentStatus &&
      this.props.ticketCurrentStatus?.alertTickets.some(
        (obj) =>
          obj.hasOwnProperty("componentName") &&
          obj.componentName === "pumpcontrolnode"
      );
    const allAlertsClosed =
      this.props?.ticketCurrentStatus &&
      this.props.ticketCurrentStatus?.alertTickets.every(
        (obj) => obj.alertEventStatusDescription === "Close"
      );
    const oldestDate = new Date(
      this.props?.ticketCurrentStatus &&
        this.props.ticketCurrentStatus?.alertTickets.reduce((prev, current) =>
          new Date(prev.alertCreateTimestamp) <
          new Date(current.alertCreateTimestamp)
            ? prev
            : current
        ).alertCreateTimestamp
    );
    const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    return (
      <Modal
        isOpen={this.props.isAlertStatusUpdateModal}
        toggle={this.props.closeAlertStatusUpdateModal}
        size="md"
        backdrop="static"
        centered={false}
        className="sram-modal-content sram-index"
      >
        <ModalHeader>Alert Status Update</ModalHeader>
        <WithLoading
          type="page"
          isPageDataFetched={this.props.statusDataFetched}
          className="mt-2 ml-1"
        >
          <ModalBody className="mb-2">
            <Row>
              <Col sm={12} md={12} lg={12} className="mb-2">
                <label className="font-mild">Status</label>
                <select
                  className="form-control"
                  defaultValue={this.state.alertStatusValue}
                  onChange={this.handleChange}
                >
                  <option value="">Select Status</option>
                  {this.state.alertStatus.map((obj, i) => {
                    return (
                      <option key={i} value={obj.value}>
                        {obj.name}
                      </option>
                    );
                  })}
                </select>
                {this.state.error === "" ? (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    status is required
                  </div>
                ) : null}
              </Col>
              {this.state.alertStatusValue !== "" &&
              this.state.alertStatusValue === "I" ? (
                <Col sm={12} md={12} lg={12} className="mb-2">
                  {/* <label className="font-mild">WIP</label>
                  <ReactMultiSelectCheckboxes

                    options={this.state.workInProgress}
                    // defaultValue={this.props.wip}
                    onChange={this.handleWIP}
                  /> */}
                  <label className="font-mild">WIP</label>
                  <select className="form-control" onChange={this.handleWIP}>
                    <option value="">Select WIP</option>
                    {this.state.workInProgress.map((obj, i) => {
                      return (
                        <option key={i} value={obj.value}>
                          {obj.label}
                        </option>
                      );
                    })}
                  </select>
                  {this.state.error1 === "" ? (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      WIP is required
                    </div>
                  ) : null}
                </Col>
              ) : null}
              {this.state.alertStatusValue !== "" &&
              this.state.alertStatusValue === "P" ? (
                <Col sm={12} md={12} lg={12} className="mb-2">
                  <label className="font-mild">Resolution Category</label>
                  <select
                    className="form-control"
                    onChange={this.handleResolution}
                  >
                    <option value="">Select Resolution Category</option>
                    {this.state.resolutionCategoryPendingClosed.map(
                      (obj, i) => {
                        return (
                          <option key={i} value={obj.value}>
                            {obj.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                  {this.state.error1 === "" ? (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      Resolution Category is required
                    </div>
                  ) : null}
                </Col>
              ) : null}

              {this.state.alertStatusValue !== "" &&
              this.state.alertStatusValue === "C" ? (
                <Col sm={12} md={12} lg={12} className="mb-2">
                  <label className="font-mild">Resolution Category</label>
                  <select
                    className="form-control"
                    onChange={this.handleResolution}
                    defaultValue={this.props.statusData}
                  >
                    <option value="">Select Resolution Category</option>
                    {this.props?.ticketCurrentStatus &&
                    this.props?.ticketCurrentStatus
                      ?.approveStatusDescription === "Rejected by Customer"
                      ? this.state.resolutionCategoryCustomerRejected.map(
                          (obj, i) => {
                            return (
                              <option key={i} value={obj.value}>
                                {obj.name}
                              </option>
                            );
                          }
                        )
                      : allAlertsClosed
                      ? this.state.resolutionCategoryCrindGreaterClosed.map(
                          (obj, i) => {
                            return (
                              <option key={i} value={obj.value}>
                                {obj.name}
                              </option>
                            );
                          }
                        )
                      : !hasPcn && oldestDate < thirtyDaysAgo
                      ? this.state.resolutionCategoryCrindGreaterClosed.map(
                          (obj, i) => {
                            return (
                              <option key={i} value={obj.value}>
                                {obj.name}
                              </option>
                            );
                          }
                        )
                      : !hasPcn && oldestDate > thirtyDaysAgo
                      ? this.state.resolutionCategoryCustomerRejected.map(
                          (obj, i) => {
                            return (
                              <option key={i} value={obj.value}>
                                {obj.name}
                              </option>
                            );
                          }
                        )
                      : hasPcn
                      ? this.state.resolutionCategoryPCNClosed.map((obj, i) => {
                          return (
                            <option key={i} value={obj.value}>
                              {obj.name}
                            </option>
                          );
                        })
                      : this.state.resolutionCategoryClosed.map((obj, i) => {
                          return (
                            <option key={i} value={obj.value}>
                              {obj.name}
                            </option>
                          );
                        })}
                  </select>
                  {this.state.error1 === "" ? (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      Resolution Category is required
                    </div>
                  ) : null}
                </Col>
              ) : null}

              {this.state.wip && this.state.wip === "Remote Fix" ? (
                <Col sm={12} md={12} lg={12} className="mb-2">
                  <label className="font-mild">Action Taken</label>
                  <select
                    className="form-control"
                    onChange={this.handleActionTaken}
                  >
                    <option value="">Select Action Taken</option>
                    {this.state.actionTaken.map((obj, i) => {
                      return (
                        <option key={i} value={obj.value}>
                          {obj.name}
                        </option>
                      );
                    })}
                  </select>
                </Col>
              ) : null}

              <Col sm={12} md={12} lg={12}>
                <label className="font-mild">Purchase Order</label>
                <div>
                  <Input
                    // type="number"
                    oninput={
                      (this.state.purchaseOrder = this.state.purchaseOrder
                        .replace(/[^0-9.]/g, "")
                        .replace(/(\..*?)\..*/g, "$1"))
                    }
                    defaultValue={this.props.purchaseOrder}
                    // value={this.state.purchaseOrder}
                    min="10000"
                    max="99999"
                    maxlength="12"
                    className="form-control"
                    disabled={this.props.purchaseOrder ? "disabled" : ""}
                    onChange={this.handlePOChange}
                  />
                </div>
                {/* 
                {this.state.statusMessage ? (
                  <div
                    style={{ display: "block" }}
                    className="invalid-feedback"
                  >
                    {this.state.statusMessage[0] === "E"
                      ? this.state.statusMessage[1]
                      : null}
                  </div>
                ) : null} */}
              </Col>

              <Col sm={12} md={12} lg={12}>
                <label className="font-mild">Notes</label>
                <div>
                  <textarea
                    value={this.state.notes}
                    style={
                      this.state.error2 === ""
                        ? { border: "1px solid red" }
                        : null
                    }
                    className="form-control"
                    placeholder="Notes"
                    onChange={this.handleNotes}
                  ></textarea>
                  <span className="text-secondary font-10">
                    {
                      "Special Characters like ',&*<>/' are not allowed in Notes."
                    }
                  </span>
                  {this.state.error2 === "" ? (
                    <div
                      style={{ display: "block" }}
                      className="invalid-feedback"
                    >
                      Notes is required
                    </div>
                  ) : null}
                </div>
              </Col>
              {this.state.statusMessage &&
              this.state.statusMessage[0] === "E" ? (
                <div className="pt-4">
                  <Col sm={12} md={12} lg={12}>
                    {PageDisplay.showWarningNotification(
                      this.state.statusMessage[0] === "E"
                        ? this.state.statusMessage[1]
                        : null
                    )}
                  </Col>
                </div>
              ) : null}
            </Row>
            {/* <br /> */}
          </ModalBody>
          <ModalFooter className="m-1 p-2">
            <Button
              type="button"
              className="sram-btn-blue mx-2"
              disabled={this.state.isSubmitting}
              onClick={this.checkStatus}
            >
              Save
              {this.state.isSubmitting ? (
                <Spinner size="sm" color="light" className="pb-1 ml-2" />
              ) : null}
            </Button>
            <Button
              type="button"
              className="sram-btn-white mx-2"
              onClick={this.props.closeAlertStatusUpdateModal}
            >
              {AlertMsgResProps.footer.close.label}
            </Button>
          </ModalFooter>
        </WithLoading>
        {this.state.isTicketConfirmModal ? (
          <Modal
            isOpen={this.state.isTicketConfirmModal}
            toggle={this.closeTicketConfirmModal}
            size="md"
            backdrop="static"
            centered={false}
            className="sram-modal-content"
          >
            <ModalHeader>
              {AppMsgResProps.body.content.confirmation}
            </ModalHeader>
            <ModalBody className="mb-2">
              <Row>
                <Col sm={12} md={12} lg={12} className="pt-2">
                  {
                    "Are you sure you want to close this ticket with an open alert?"
                  }
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="m-1 p-2">
              <Button
                type="button"
                className="sram-btn-blue mx-2"
                onClick={this.updateTicketStatus.bind(this)}
              >
                {AppMsgResProps.body.form.yes.label}
              </Button>
              <Button
                type="button"
                className="sram-btn-white mx-2"
                onClick={this.closeTicketConfirmModal}
              >
                {AppMsgResProps.body.form.no.label}
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </Modal>
    );
  }
}

export default AlertStatusUpdateModal;
