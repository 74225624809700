import axios from "axios";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";

const actionFileName = "dispenser-actions.js";

export function dispenserView(siteId, dispenserId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">dispenserView()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Door Alerts
export function doorAlerts(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/door-events?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">doorAlerts()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Technical Details

export function technicalDetails(siteId, dispenserId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/pump-config",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">technicalDetails()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// Technical Details CARD READER & UPM

export function technicalDetailsUpmCard(siteId, dispenserId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/crind-hw-sw",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">technicalDetails()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// sensor data

export function sensorTempHumidity(
  siteId,
  dispenserId,
  queryData,
  cancelToken
) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/sensor-events?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">sensorTempHumidity()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// Prices

export function prices(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/price-config?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">prices()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Meter Volume

export function meterVolume(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/meter-data?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">meterVolume()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Pump ErrorLogs

export function pumpErrorLogs(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/pump-errors?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">pumpErrorLogs()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Flow Rates

export function flowRates(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/flow-data?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">flowRates()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// OverRuns

export function overRuns(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/performance-data/overrun?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">overRuns()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// StandAlones

export function standAlones(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/performance-data/standalone?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">standAlones()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Reboot History

export function rebootHistory(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/reboot-data?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">rebootHistory()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Reboot Progress

export function rebootProgress(siteId, dispenserId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/reboot",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">rebootProgress()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Reboot

export function reboot(siteId, dispenserId, obj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/reboot",
        obj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">reboot()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Reboot Cancel

export function rebootCancel(siteId, dispenserId, obj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/reboot",
        obj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">rebootProgress()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

//schedule Reboot

export function scheduleReboot(siteId, dispenserId, obj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/schedule-reboot",
        obj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">scheduleReboot()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

//get schedule Reboot data

export function getScheduleRebootData(siteId, dispenserId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/schedule-reboot",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">getScheduleRebootData()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

//Cancle previously scheduled reboot

export function cancleScheduleReboot(
  siteId,
  dispenserId,
  queryData,
  objData,
  cancelToken
) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/schedule-reboot?rebootStatus=" +
          queryData,
        objData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">cancleScheduleReboot()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

//Prices Data

export function priceConfig(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/price-data?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">priceConfig()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

//Transaction Data

export function transactionData(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/transaction-data?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">transactionData()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

//Transaction Flow Data

export function transactionFlowData(
  siteId,
  dispenserId,
  queryData,
  cancelToken
) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/peak-flowrate?" +
          // "/transaction-flow?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">transactionData()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// flowRatesData

export function flowRatesData(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/flow-rate?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">flowRatesData()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// flow-snapshot

export function flowSnapshot(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/flow-snapshot?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">flowSnapshot()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// component-failure

export function componentFailure(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/component-failure?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">componentFailure()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// Firmware Reboot

export function firmwareReboot(siteId, dispenserId, obj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/firmware-reboot",
        obj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">firmwareReboot()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Reboot Progressv2

export function rebootProgressV2(siteId, dispenserId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/firmware-reboot",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">rebootProgressV2()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// Reboot Cancel V2

export function rebootCancelV2(siteId, dispenserId, obj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/firmware-reboot",
        obj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">rebootCancelV2()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// alertTickets

export function alertTickets(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/alert-tickets?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">alertTickets()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// alerts

export function alerts(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/alerts?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">alerts()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

export function alertEvents(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/alertsinfo?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">alertEvents()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// PCN Alert Events
export function pcnAlertEvents(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/alertsinfo?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">pcnAlertEvents()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// PCN Alert Events Conversion
export function pcnEventAlerts(
  siteId,
  dispenserId,
  queryData,
  bodyObj,
  cancelToken
) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/alertsinfo?" +
          queryData,
        bodyObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">pcnEventAlerts()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// No Transaction data
export function noTrasactionStatus(
  siteId,
  dispenserId,
  queryData,
  cancelToken
) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/no-transactions?" +
          queryData,
        // "/no-transaction-data?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">noTrasactionStatus()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// Flow Rate Alerts data
export function flowRateAlerts(siteId, dispenserId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/component-failure-flowrate?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">flowRateAlerts()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Post Warm Start Data to DB
export function postWarmStartData(siteId, dispenserId, bodyObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
          "portal/sites/" +
          siteId +
          "/dispensers/" +
          dispenserId +
          "/device-warm-start",
        bodyObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">postWarmStartData()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function generatewarmStartJWTToken() {
  const url =
    "https://auth.hub.invenco.com/auth/realms/insite360-users/protocol/openid-connect/token";
  const data = {
    client_id: "insite360-client",
    client_secret: "87b1779a-92ba-4730-9357-01550724ddc0",
    grant_type: "password",
    scope: "openid",
    username: "jgale@guardianfueltech.com",
    password: "bHn%hh&qL7BBkAX*",
  };

  return new Promise((resolve, reject) => {
    axios
      .post(url, new URLSearchParams(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          logMessage(
            "warn",
            actionFileName + ">generatewarmStartJWTToken()",
            error.message
          );
        } else {
          reject(error.response);
        }
      });
  });
}

export function warmStartDryRun(siteId, fuelingPointId, accessToken) {
  const url = "https://api.insite360.io/v1/cp/cmd/command/warmstart/dryrun";
  const apiKey = "61fA5CGT3hGYFAISk19SxVKmxShW83ClCCH8c7BYKnfPRzcm";

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "x-apikey": apiKey,
    "Content-Type": "application/json",
  };

  const requestData = {
    targets: {
      targetSiteList: [{ siteId: `${siteId}`, localId: `${fuelingPointId}` }],
    },
  };

  return new Promise((resolve, reject) => {
    axios
      .post(url, requestData, { headers })
      .then((response) => resolve(response))
      .catch((error) => {
        if (axios.isCancel(error)) {
          logMessage(
            "warn",
            actionFileName + ">warmStartDryRun()",
            error.message
          );
        } else {
          reject(error.response);
        }
      });
  });
}

export function warmStartCommands(siteId, fuelingPointId, accessToken) {
  const url = "https://api.insite360.io/v1/cp/cmd/command/warmstart";
  const apiKey = "61fA5CGT3hGYFAISk19SxVKmxShW83ClCCH8c7BYKnfPRzcm";

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "x-apikey": apiKey,
    "Content-Type": "application/json",
  };

  const data = {
    targets: {
      targetSiteList: [{ siteId: `${siteId}`, localId: `${fuelingPointId}` }],
    },
  };

  return new Promise(function (resolve, reject) {
    axios
      .post(url, data, { headers: headers })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">warmStartCommands()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function getWSOperationTarget(operationId, accessToken) {
  const url = `https://api.insite360.io/v1/cp/cmd/operations/${operationId}`;
  const apiKey = "61fA5CGT3hGYFAISk19SxVKmxShW83ClCCH8c7BYKnfPRzcm";

  const headers = {
    Authorization: `Bearer ${accessToken}`,
    "x-apikey": apiKey,
    "Content-Type": "application/json",
  };

  return new Promise(function (resolve, reject) {
    axios
      .get(url, { headers: headers })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">getWSOperationTarget()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}
