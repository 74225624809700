import axios from "axios";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";

const actionFileName = "onboarding-actions.js";

// List of  Sites
export function listSites(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "";
    if (queryData) {
      queryParams = "?" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "sites" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listSites()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

//site dispensers list
export function dispensersList(siteId,cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "sites/" + siteId + "/dispensers",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">dispensersList()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// List of  manufacurers
export function manufacturersList(cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "manufacturers",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">manufacturersList()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// List of  models
export function modelsList(cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "models",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">modelsList()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Add Device
export function addDevice(deviceObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "/devices",
        deviceObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">addDevice()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Types of  meters
export function meterTypes(cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "general/meter-types",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">metersTypes()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Types of  grades
export function gradeTypes(cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "general/grade-types",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">gradesTypes()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}