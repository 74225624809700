export const SetupValidations = {
  roles: {
    roleCode: /^[A-Za-z0-9]{3,5}$/,
    roleName: /^[A-Za-z0-9 -]{5,50}$/,
    PartCodesNotApplicable: {
      siteOwner: [
        "AS-ROL-RADD",
        "AS-ROL-RVIW",
        "AS-ROL-REDT",
        "AS-SIQ-CPVW",
        "AS-SIQ-CPED",
        "AS-SOW-CPAD",
        "AS-SPV-CPAD",
        "AS-SPV-CPVW",
        "AS-SPV-CPED",
        "AS-SPV-DVAD",
        "AS-SPV-DVVW",
        "AS-SPV-DVED",
        "AS-SPV-STVW",
        "AS-SPV-STLK",
        "AS-SPV-STAD",
        "AS-SPV-STED",
        "AS-SPV-STUA",
        // "AT-MFG-MADD",
        // "AT-MFG-MVIW",
        // "AT-MFG-MEDT",
        // "AT-MDL-MADD",
        // "AT-MDL-MVIW",
        // "AT-MDL-MEDT",
        // "AT-DVC-DONB",
        // "AT-DVC-DVIW",
        // "AT-DVC-DEDT",
        // "AT-ERC-EADD",
        // "AT-ERC-EVIW",
        // "AT-ERC-EEDT",
      ],
      serviceProvider: [
        "AS-ROL-RADD",
        "AS-ROL-RVIW",
        "AS-ROL-REDT",
        "AS-SIQ-CPVW",
        "AS-SIQ-CPED",
        "AS-SOW-CPAD",
        "AS-SOW-CPVW",
        "AS-SOW-CPED",
        "AS-SOW-DVAD",
        "AS-SOW-DVVW",
        "AS-SOW-DVED",
        "AS-SOW-STAD",
        "AS-SOW-STVW",
        "AS-SOW-STED",
        "AS-SOW-STUA",
        "AS-SPV-CPAD",
        "AT-MFG-MADD",
        "AT-MFG-MVIW",
        "AT-MFG-MEDT",
        "AT-MDL-MADD",
        "AT-MDL-MVIW",
        "AT-MDL-MEDT",
        "AT-DVC-DONB",
        "AT-DVC-DVIW",
        "AT-DVC-DEDT",
        "AT-ERC-EADD",
        "AT-ERC-EVIW",
        "AT-ERC-EEDT",
      ],
    },
  },
  organizations: {
    //organizationName: /^[A-Za-z0-9 .&-]{3,50}$/,
    organizationName: /^.{2,50}$/,
    zipCode: /^[0-9]{5}(-[0-9]{4})?/,
  },
  sites: {
    //siteName: /^[A-Za-z0-9 .&-]{3,50}$/,
    // siteName: /^[a-zA-Z][\w,.]*[a-zA-Z0-9]$/,
    siteName: /^.{2,50}$/,
    siteOwner: /^[a-zA-Z][\w,.]*[a-zA-Z0-9]$/,
    locationLatitude: /^-?[0-9]{1,3}(?:\.[0-9]{1,20})?$/,
    locationLongitude: /^-?[0-9]{1,3}(?:\.[0-9]{1,20})?$/,
    zipCode: /^[0-9]{5}(-[0-9]{4})?/,
    sitePhone: /^[0-9]{10}$/,
    siteId: /^[0-9]*$/
  },
  users: {
    username: /^[a-zA-Z][\w,.]*[a-zA-Z0-9]$/,
    //password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?"!@#%&/\\,><\':;|_~`])\S{8,30}$/,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,30}$/,
    //email: /^[a-z0-9]+[._]?[a-z0-9]+[@]\w+[.]\w{2,3}$/,
    firstName: /^[A-Za-z0-9 .]{2,50}$/,
    lastName: /^[A-Za-z0-9 .]{2,50}$/,
    zipCode: /^[0-9]{5}(-[0-9]{4})?/,
  },
};
