import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  FormGroup,
  Label,
  Row,
  Col,
  Input
} from "reactstrap";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import { OnboardingMsgResProps } from "../messages/onboarding-properties";
import {
  listSites,
  dispensersList,
  manufacturersList,
  modelsList,
  addDevice,
  meterTypes,
  gradeTypes
} from "../actions/onboarding-actions";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import PageUtils from "../../core/helpers/page-utils";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import { ConfigURLProps } from "../settings/config-urls";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import PageDisplay from "../../core/helpers/page-display";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import OnboardingUtils from "../helpers/onboarding-utils";
// import { ConfigDataProps } from "../settings/config-data";
import ConfirmModal from "../../core/helpers/confirm-modal";
import { ConfigValidations } from "../settings/config-validations";
import Select from 'react-select';

class Onboarding extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcViewSites = "CP-SIT-SVIW";
  _pcSiteDispenserView = "CP-DSP-DVIW";
  _pcViewConfigurationOnboarding = "AT-DVC-DONB";
  _pcViewModels = "AT-MDL-MVIW";
  _pcViewManufactures = "AT-MFG-MVIW";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isDispensersFetched: true,
      siteList: null,
      partPrivileges: this.initPartPrivileges(),
      dispensersList: null,
      siteId: null,
      formInputFields: this.initFormInputFields(),
      sideNumbers: null,
      manufacturersList: null,
      modelsList: null,
      models: null,
      isModelsFetched: true,
      isOpenConfirmModal: false,
      tempDeviceObj: null,
      meterTypes: null,
      gradeTypes: null,
      siteNumber: null,
      isChecked: false
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
    window.appHistory = this.props.history;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      PageUtils.scrollToTop();
      await this.loadPageData();
    }
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ siteList: null });
    await this.checkPartPrivilege();
    await this.setPartPrivileges();
    const queryData = "sortField=siteName&sortOrder=1";
    await listSites(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ siteList: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    if (this.state.partPrivileges[this._pcViewManufactures] === true) {
      await manufacturersList(this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            if (res.data.records.length > 0) {
              await this.setStateAsync({ manufacturersList: res.data.records });
            }
          } else {
            await manageError(res, this.props.history);
          }
        })
        .catch(async (err) => {
          await manageError(err, this.props.history);
        });
    }
    if (this.state.partPrivileges[this._pcViewModels] === true) {
      await modelsList(this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            if (res.data.records.length > 0) {
              await this.setStateAsync({ modelsList: res.data.records });
            }
          } else {
            await manageError(res, this.props.history);
          }
        })
        .catch(async (err) => {
          await manageError(err, this.props.history);
        });
    }
    await meterTypes(this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ meterTypes: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await gradeTypes(this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ gradeTypes: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await this.setStateAsync({ isPageDataFetched: true });
  };


  initPartPrivileges = () => {
    return {
      [this._pcViewSites]: false,
      [this._pcSiteDispenserView]: false,
      [this._pcViewConfigurationOnboarding]: false,
      [this._pcViewModels]: false,
      [this._pcViewManufactures]: false,
    };
  };

  checkPartPrivilege = async () => {
    const partCode = [
      this._pcViewSites,
      this._pcSiteDispenserView,
      this._pcViewConfigurationOnboarding,
      this._pcViewModels,
      this._pcViewManufactures,
    ];
    const partPrivilege = await getPagePartsPrivilege(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(ConfigURLProps.pageNotFound);
    }
  };

  setPartPrivileges = async () => {
    const partCodes = [
      this._pcViewSites,
      this._pcSiteDispenserView,
      this._pcViewConfigurationOnboarding,
      this._pcViewModels,
      this._pcViewManufactures,
    ];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  handleChange = async (id) => {
    // await this.setStateAsync({ siteId: null });
    await this.setStateAsync({ isChecked: false });
    await this.setStateAsync({ siteId: [id.siteId] });
    await this.setStateAsync({ siteNumber: [id.siteNumber] });
    if (this.state.siteId) {
      this.getDispensers();
    }
  };

  handleGetModels = async (manufacturerId) => {
    const keyValue = manufacturerId;
    await this.setStateAsync({ models: null });
    if (keyValue !== "" && keyValue !== null) {
      await this.setStateAsync({ isModelsFetched: false });
      if (
        keyValue &&
        keyValue.trim().length > 0 &&
        this.state.modelsList?.length > 0
      ) {
        const models = this.state.modelsList.filter((obj) => {
          return obj.manufacturerId === parseInt(keyValue);
        });
        await this.setStateAsync({ models: models });
        await this.setStateAsync({ isModelsFetched: true });
      } else {
        await this.setStateAsync({ models: true });
        await this.setStateAsync({ isModelsFetched: true });
      }
    }
  };

  getDispensers = async () => {
    await this.setStateAsync({ isDispensersFetched: false });
    await this.setStateAsync({ dispensersList: null });
    await dispensersList(this.state.siteId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ dispensersList: res.data.records });
            await this.setStateAsync({
              sideNumbers: OnboardingUtils.getSideNumbers(
                this.state.dispensersList
              ),
            });
          }
          if (res.data.records.length === 0) {
            await this.setStateAsync({
              sideNumbers: OnboardingUtils.getSideNumbers(
                this.state.dispensersList
              ),
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isDispensersFetched: true });
  };

  initFormInputFields = () => {
    return {
      // siteId: "",
      macAddress: "",
      dispenser: "",
      modelName: "",
      manufacturerName: "",
      fuelingSideA: "",
      fuelingSideB: "",
      warranty: "",
      twoWireSideA: "",
      twoWireSideB: "",
      meter1: "",
      meter2: "",
      meter3: "",
      meter4: "",
      meter5: "",
      meter6: "",
      meter7: "",
      meter8: "",
      grade1: "",
      grade2: "",
      grade3: "",
      grade4: "",
      grade5: "",
      grade6: "",
      grade7: "",
      grade8: ""
    };
  };

  formValidationSchema = () => {
    const schema = {
      macAddress: Yup.string()
        .trim()
        .required(OnboardingMsgResProps.body.notification.error.deviceId.empty)
        .matches(
          ConfigValidations.validations.macAddress,
          OnboardingMsgResProps.body.notification.error.deviceId.invalid
        ),
      manufacturerName: Yup.string()
        .trim()
        .required(
          OnboardingMsgResProps.body.notification.error.manufacturerName.empty
        ),
      modelName: Yup.string().required(
        OnboardingMsgResProps.body.notification.error.modelName.empty
      ),
      dispenser: Yup.string().required(
        OnboardingMsgResProps.body.notification.error.dispenser.empty
      ),
      fuelingSideA: Yup.string()
        .trim()
        .required(OnboardingMsgResProps.body.notification.error.fuelingSideA.empty)
        .matches(
          ConfigValidations.validations.fuelingSideA,
          OnboardingMsgResProps.body.notification.error.fuelingSideA.invalid
        ),
      fuelingSideB: Yup.string()
        .trim()
        .required(OnboardingMsgResProps.body.notification.error.fuelingSideB.empty)
        .matches(
          ConfigValidations.validations.fuelingSideB,
          OnboardingMsgResProps.body.notification.error.fuelingSideB.invalid
        ),
      twoWireSideA: Yup.string()
        .trim()
        .required(OnboardingMsgResProps.body.notification.error.twoWireSideA.empty)
        .matches(
          ConfigValidations.validations.twoWireSideA,
          OnboardingMsgResProps.body.notification.error.twoWireSideA.invalid
        ),
      twoWireSideB: Yup.string()
        .trim()
        .required(OnboardingMsgResProps.body.notification.error.twoWireSideB.empty)
        .matches(
          ConfigValidations.validations.twoWireSideB,
          OnboardingMsgResProps.body.notification.error.twoWireSideB.invalid
        ),
    };
    return schema;
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  handleConfirmation = async (values, helpers) => {
    this._helpers = helpers;
    let dispenserFlowType = null
    if (this.state.isChecked){
      dispenserFlowType = 1
    }
    else{
      dispenserFlowType = 0
    }
    const deviceObj = {
      // siteId: values.siteId,
      siteId: this.state.siteId[0],
      dispenserName: values.dispenser,
      manufacturerId: values.manufacturerName,
      modelId: values.modelName,
      dispenserImage: "",
      macAddress: values.macAddress,
      fuelingSideA: values.fuelingSideA,
      fuelingSideB: values.fuelingSideB,
      warranty: values.warranty,
      twoWireSideA: values.twoWireSideA,
      twoWireSideB: values.twoWireSideB,
      meter1: values.meter1,
      meter2: values.meter2,
      meter3: values.meter3,
      meter4: values.meter4,
      meter5: values.meter5,
      meter6: values.meter6,
      meter7: values.meter7,
      meter8: values.meter8,
      grade1: values.grade1,
      grade2: values.grade2,
      grade3: values.grade3,
      grade4: values.grade4,
      grade5: values.grade5,
      grade6: values.grade6,
      grade7: values.grade7,
      grade8: values.grade8,
      dispenserFlowType: dispenserFlowType
    };
    await this.setStateAsync({ tempDeviceObj: deviceObj });
    this.openConfirmModal();
  };

  handleSubmit = async () => {
    const deviceObj = this.state.tempDeviceObj;
    const helpers = this._helpers;
    this.closeConfirmModal();
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }
    await addDevice(deviceObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = [
            "S",
            OnboardingMsgResProps.body.notification.success.addDevice,
          ];
          if (this._isMounted) {
            await this.setStateAsync({ models: null })
            helpers.resetForm();
            await this.getDispensers()
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          OnboardingMsgResProps.body.notification.error.message,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);

          if (err.data && err.data.errors && err.data.errors.length > 0) {
            const errors = err.data.errors;
            errors.forEach((error) => {
              if (error.element && error.message && error.location === "body") {
                helpers.setFieldError(error.element, error.message);
              }
            });
          }
          PageUtils.scrollToTop();
        }
      });
    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  ckeckDispenserFlow = async (event) => {
    var checked = event.target.checked;
    await this.setStateAsync({isChecked: checked})
  }

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <Card className="sram-page-list">
              <CardTitle>
                <span>{OnboardingMsgResProps.body.content.onboarding}</span>
              </CardTitle>
              <CardBody>
                <div className="sram-list-main">
                  <Formik
                    initialValues={this.state.formInputFields}
                    validationSchema={Yup.object().shape(
                      this.formValidationSchema()
                    )}
                    onSubmit={(values, helpers) =>
                      this.handleConfirmation(values, helpers)
                    }
                  >
                    {(formik) => (
                      <Form id="dispenserForm" {...formEvent}>
                        <div className="sram-form-main">
                          <div>
                            {formik.status &&
                              Array.isArray(formik.status) &&
                              formik.status.length === 2 &&
                              (formik.status[0] === "E" ||
                                formik.status[0] === "S") ? (
                              <Row>
                                <Col xs={12}>
                                  {formik.status[0] === "E"
                                    ? PageDisplay.showErrorNotification(
                                      formik.status[1]
                                    )
                                    : PageDisplay.showSuccessNotification(
                                      formik.status[1]
                                    )}
                                </Col>
                              </Row>
                            ) : null}
                          </div>
                          <div>
                            <Row form>
                              <Col sm={12} md={6} lg={6} className="pb-2">
                                <>
                                  <Select
                                    placeholder=" Select Site"
                                    options={this.state.siteList}
                                    getOptionLabel={(option) => option?.siteName + ' (GVR ID : ' + option?.siteNumber + ')'}
                                    getOptionValue={(option) => option?.siteName + ' (GVR ID : ' + option?.siteNumber + ')'}
                                    theme={theme => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary25: '#ced4da',
                                        primary: '#ced4da',
                                      },
                                    })}
                                    styles={{
                                      option: base => ({
                                        ...base,
                                        color: '#000000',
                                      }),
                                    }}
                                    onChange={async (e) => {
                                      await this.handleChange(e);
                                      formik.setFieldValue("dispenser", "");
                                      formik.setFieldValue("manufacturerName", "");
                                      formik.setFieldValue("modelName", "");
                                      formik.setFieldValue("macAddress", "");
                                      formik.setFieldValue("fuelingSideA", "");
                                      formik.setFieldValue("fuelingSideB", "");
                                      formik.setFieldValue("twoWire", "");
                                      formik.setFieldValue("warranty", "");
                                      formik.setFieldValue("twoWireSideA", "");
                                      formik.setFieldValue("twoWireSideB", "");
                                      formik.setFieldValue("meter1", "");
                                      formik.setFieldValue("meter2", "");
                                      formik.setFieldValue("meter3", "");
                                      formik.setFieldValue("meter4", "");
                                      formik.setFieldValue("meter5", "");
                                      formik.setFieldValue("meter6", "");
                                      formik.setFieldValue("meter7", "");
                                      formik.setFieldValue("meter8", "");
                                      formik.setFieldValue("grade1", "");
                                      formik.setFieldValue("grade2", "");
                                      formik.setFieldValue("grade3", "");
                                      formik.setFieldValue("grade4", "");
                                      formik.setFieldValue("grade5", "");
                                      formik.setFieldValue("grade6", "");
                                      formik.setFieldValue("grade7", "");
                                      formik.setFieldValue("grade8", "");
                                    }}
                                  />
                                </>
                              </Col>
                            </Row>
                            {this.state.siteId &&
                              this.state.siteId.length > 0 ? (
                              <>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="dispenser">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .dispenser.label
                                        } *
                                      </Label>
                                      <WithLoading type="field" size="sm" isPageDataFetched={this.state.isDispensersFetched}>
                                        <Field
                                          name="dispenser"
                                          as="select"
                                          className={PageDisplay.getFormikFieldClassName(
                                            formik.errors.dispenser,
                                            formik.touched.dispenser
                                          )}
                                          value={formik.values.dispenser}
                                          // eslint-disable-next-line no-undef
                                          onChange={async (e) => {
                                            let dispenser = e.target.value;
                                            var sides = e.target.value.split('/');
                                            formik.setFieldValue("dispenser", dispenser);
                                            formik.setFieldValue("macAddress", "DS" + this.state.siteNumber.toString() + "L" + Math.ceil(sides[0]/2)).toString();
                                            formik.setFieldValue("fuelingSideA", sides[0]);
                                            formik.setFieldValue("fuelingSideB", sides[1]);
                                            formik.setFieldValue("twoWireSideA", sides[0]);
                                            formik.setFieldValue("twoWireSideB", sides[1]);
                                          }
                                          }
                                        >
                                          <option value=""></option>
                                          {this.state.sideNumbers &&
                                            this.state.sideNumbers.length > 0
                                            ? this.state.sideNumbers.map(
                                              (obj, index) => {
                                                return (
                                                  <option
                                                    value={obj}
                                                    key={index}
                                                  // onClick={this.handleClick.bind(this)}
                                                  >
                                                    Dispenser {obj}
                                                  </option>
                                                );
                                              }
                                            )
                                            : null}

                                        </Field>
                                        {PageDisplay.getFormikFieldErrorMessage(
                                          "dispenser"
                                        )}
                                      </WithLoading>
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="macAddress">
                                        {/* {
                                          OnboardingMsgResProps.body.form.imei
                                            .label
                                        } */}
                                        Device Id *
                                      </Label>
                                      <Field
                                        name="macAddress"
                                        type="text"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.macAddress,
                                          formik.touched.macAddress
                                        )}
                                        value={formik.values.macAddress}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "macAddress"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="manufacturerName">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .manufacturerName.label
                                        } *
                                      </Label>
                                      <Field
                                        name="manufacturerName"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.manufacturerName,
                                          formik.touched.manufacturerName
                                        )}
                                        // value={formik.values.manufacturerId}
                                        onChange={async (e) => {
                                          let manufacturerId = e.target.value;
                                          formik.setFieldValue(
                                            "manufacturerName",
                                            manufacturerId
                                          );
                                          this.handleGetModels(manufacturerId);
                                          formik.setFieldValue("modelName", "");
                                        }}
                                      // onChange={this.handleGetModels.bind(this)}
                                      >
                                        <option value=""></option>
                                        {this.state.manufacturersList &&
                                          this.state.manufacturersList.length > 0
                                          ? this.state.manufacturersList.map(
                                            (obj, index) => {
                                              return (
                                                <option
                                                  value={obj.manufacturerId}
                                                  key={index}
                                                >
                                                  {obj.manufacturerName}
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "manufacturerName"
                                      )}
                                    </FormGroup>
                                  </Col>

                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="modelName">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .modelName.label
                                        } *
                                      </Label>
                                      <WithLoading
                                        isPageDataFetched={
                                          this.state.isModelsFetched
                                        }
                                        type="page"
                                        size="sm"
                                      >
                                        <Field
                                          name="modelName"
                                          as="select"
                                          className={PageDisplay.getFormikFieldClassName(
                                            formik.errors.modelName,
                                            formik.touched.modelName
                                          )}
                                        // value={formik.values.modelId}
                                        >
                                          <option value=""></option>
                                          {this.state.models &&
                                            this.state.models.length > 0
                                            ? this.state.models.map(
                                              (obj, i) => {
                                                return (
                                                  <option
                                                    value={obj.modelId}
                                                    key={i}
                                                  >
                                                    {obj.modelName}
                                                  </option>
                                                );
                                              }
                                            )
                                            : null}
                                        </Field>
                                      </WithLoading>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "modelName"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="warranty">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .warranty.label
                                        } *
                                      </Label>
                                      <Field
                                        name="warranty"
                                        type="date"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.warranty,
                                          formik.touched.warranty
                                        )}
                                        value={formik.values.warranty}
                                      />

                                    </FormGroup>
                                  </Col>
                                  </Row>

                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="fuelingSideA">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .fuelingSideA.label
                                        } *
                                      </Label>
                                      <Field
                                        name="fuelingSideA"
                                        type="text"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.fuelingSideA,
                                          formik.touched.fuelingSideA
                                        )}
                                        value={formik.values.fuelingSideA}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "fuelingSideA"
                                      )}

                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="fuelingSideB">
                                        {
                                          OnboardingMsgResProps.body.form.fuelingSideB
                                            .label
                                        } *
                                      </Label>
                                      <Field
                                        name="fuelingSideB"
                                        type="text"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.fuelingSideB,
                                          formik.touched.fuelingSideB
                                        )}
                                        value={formik.values.fuelingSideB}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "fuelingSideB"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>

                                {/* <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="twoWire">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .twoWire.label
                                        }
                                      </Label>
                                      <Field
                                        name="twoWire"
                                        type="text"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.twoWire,
                                          formik.touched.twoWire
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "twoWire"
                                      )}
                                      
                                    </FormGroup>
                                  </Col>
                                </Row> */}

                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="twoWireSideA">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .twoWireSideA.label
                                        } *
                                      </Label>
                                      <Field
                                        name="twoWireSideA"
                                        type="text"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.twoWireSideA,
                                          formik.touched.twoWireSideA
                                        )}
                                        value={formik.values.twoWireSideA}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "twoWireSideA"
                                      )}

                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="twoWireSideB">
                                        {
                                          OnboardingMsgResProps.body.form.twoWireSideB
                                            .label
                                        } *
                                      </Label>
                                      <Field
                                        name="twoWireSideB"
                                        type="text"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.twoWireSideB,
                                          formik.touched.twoWireSideB
                                        )}
                                        value={formik.values.twoWireSideB}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "twoWireSideB"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="meter1">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .meter1Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="meter1"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.meter1,
                                          formik.touched.meter1
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.meterTypes &&
                                          this.state.meterTypes.length > 0
                                          ? this.state.meterTypes.map(
                                            (meter, index) => {
                                              return (
                                                <option
                                                  value= {meter? "Meter 1" + "(" + meter + ")":"Meter 1" }
                                                  key={index}
                                                >
                                                  Meter 1 { meter? "(" + meter + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "meter1Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="meter2">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .meter2Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="meter2"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.meter2,
                                          formik.touched.meter2
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.meterTypes &&
                                          this.state.meterTypes.length > 0
                                          ? this.state.meterTypes.map(
                                            (meter, index) => {
                                              return (
                                                <option
                                                  value= {meter? "Meter 2" + "(" + meter + ")":"Meter 2" }
                                                  key={index}
                                                >
                                                  Meter 2 { meter? "(" + meter + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "meter2Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="meter3">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .meter3Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="meter3"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.meter3,
                                          formik.touched.meter3
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.meterTypes &&
                                          this.state.meterTypes.length > 0
                                          ? this.state.meterTypes.map(
                                            (meter, index) => {
                                              return (
                                                <option
                                                  value= {meter? "Meter 3" + "(" + meter + ")":"Meter 3" }
                                                  key={index}
                                                >
                                                  Meter 3 { meter? "(" + meter + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "meter3Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="meter4">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .meter4Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="meter4"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.meter4,
                                          formik.touched.meter4
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.meterTypes &&
                                          this.state.meterTypes.length > 0
                                          ? this.state.meterTypes.map(
                                            (meter, index) => {
                                              return (
                                                <option
                                                  value= {meter? "Meter 4" + "(" + meter + ")":"Meter 4" }
                                                  key={index}
                                                >
                                                  Meter 4 { meter? "(" + meter + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "meter4Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="meter5">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .meter5Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="meter5"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.meter5,
                                          formik.touched.meter5
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.meterTypes &&
                                          this.state.meterTypes.length > 0
                                          ? this.state.meterTypes.map(
                                            (meter, index) => {
                                              return (
                                                <option
                                                  value= {meter? "Meter 5" + "(" + meter + ")":"Meter 5" }
                                                  key={index}
                                                >
                                                  Meter 5 { meter? "(" + meter + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "meter5Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="meter6">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .meter6Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="meter6"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.meter6,
                                          formik.touched.meter6
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.meterTypes &&
                                          this.state.meterTypes.length > 0
                                          ? this.state.meterTypes.map(
                                            (meter, index) => {
                                              return (
                                                <option
                                                  value= {meter? "Meter 6" + "(" + meter + ")":"Meter 6" }
                                                  key={index}
                                                >
                                                  Meter 6 { meter? "(" + meter + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "meter6Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="meter7">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .meter7Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="meter7"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.meter7,
                                          formik.touched.meter7
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.meterTypes &&
                                          this.state.meterTypes.length > 0
                                          ? this.state.meterTypes.map(
                                            (meter, index) => {
                                              return (
                                                <option
                                                  value= {meter? "Meter 7" + "(" + meter + ")":"Meter 7" }
                                                  key={index}
                                                >
                                                  Meter 7 { meter? "(" + meter + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "meter7Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="meter8">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .meter8Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="meter8"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.meter8,
                                          formik.touched.meter8
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.meterTypes &&
                                          this.state.meterTypes.length > 0
                                          ? this.state.meterTypes.map(
                                            (meter, index) => {
                                              return (
                                                <option
                                                  value= {meter? "Meter 8" + "(" + meter + ")":"Meter 8" }
                                                  key={index}
                                                >
                                                  Meter 8 { meter? "(" + meter + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "meter8Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="grade1">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .grade1Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="grade1"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.grade1,
                                          formik.touched.grade1
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.gradeTypes &&
                                          this.state.gradeTypes.length > 0
                                          ? this.state.gradeTypes.map(
                                            (grade, index) => {
                                              return (
                                                <option
                                                  value= {grade? "Grade 1" + "(" + grade + ")":"Grade 1" }
                                                  key={index}
                                                >
                                                  Grade 1 { grade? "(" + grade + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "grade1Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="grade2">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .grade2Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="grade2"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.grade2,
                                          formik.touched.grade2
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.gradeTypes &&
                                          this.state.gradeTypes.length > 0
                                          ? this.state.gradeTypes.map(
                                            (grade, index) => {
                                              return (
                                                <option
                                                  value= {grade? "Grade 2" + "(" + grade + ")":"Grade 2" }
                                                  key={index}
                                                >
                                                  Grade 2 { grade? "(" + grade + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "grade2Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="grade3">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .grade3Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="grade3"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.grade3,
                                          formik.touched.grade3
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.gradeTypes &&
                                          this.state.gradeTypes.length > 0
                                          ? this.state.gradeTypes.map(
                                            (grade, index) => {
                                              return (
                                                <option
                                                  value= {grade? "Grade 3" + "(" + grade + ")":"Grade 3" }
                                                  key={index}
                                                >
                                                  Grade 3 { grade? "(" + grade + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "grade3Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="grade4">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .grade4Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="grade4"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.grade4,
                                          formik.touched.grade4
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.gradeTypes &&
                                          this.state.gradeTypes.length > 0
                                          ? this.state.gradeTypes.map(
                                            (grade, index) => {
                                              return (
                                                <option
                                                  value= {grade? "Grade 4" + "(" + grade + ")":"Grade 4" }
                                                  key={index}
                                                >
                                                  Grade 4 { grade? "(" + grade + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "grade4Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="grade5">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .grade5Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="grade5"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.grade5,
                                          formik.touched.grade5
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.gradeTypes &&
                                          this.state.gradeTypes.length > 0
                                          ? this.state.gradeTypes.map(
                                            (grade, index) => {
                                              return (
                                                <option
                                                  value= {grade? "Grade 5" + "(" + grade + ")":"Grade 5" }
                                                  key={index}
                                                >
                                                  Grade 5 { grade? "(" + grade + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "grade5Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="grade6">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .grade6Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="grade6"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.grade6,
                                          formik.touched.grade6
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.gradeTypes &&
                                          this.state.gradeTypes.length > 0
                                          ? this.state.gradeTypes.map(
                                            (grade, index) => {
                                              return (
                                                <option
                                                  value= {grade? "Grade 6" + "(" + grade + ")":"Grade 6" }
                                                  key={index}
                                                >
                                                  Grade 6 { grade? "(" + grade + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "grade6Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="grade7">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .grade7Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="grade7"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.grade7,
                                          formik.touched.grade7
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.gradeTypes &&
                                          this.state.gradeTypes.length > 0
                                          ? this.state.gradeTypes.map(
                                            (grade, index) => {
                                              return (
                                                <option
                                                  value= {grade? "Grade 7" + "(" + grade + ")":"Grade 7" }
                                                  key={index}
                                                >
                                                  Grade 7 { grade? "(" + grade + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "grade7Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="grade8">
                                        {
                                          OnboardingMsgResProps.body.form
                                            .grade8Name.label
                                        }
                                      </Label>
                                      <Field
                                        name="grade8"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.grade8,
                                          formik.touched.grade8
                                        )}
                                      >
                                        <option value=""></option>
                                        {this.state.gradeTypes &&
                                          this.state.gradeTypes.length > 0
                                          ? this.state.gradeTypes.map(
                                            (grade, index) => {
                                              return (
                                                <option
                                                  value= {grade? "Grade 8" + "(" + grade + ")":"Grade 8" }
                                                  key={index}
                                                >
                                                  Grade 8 { grade? "(" + grade + ")":"" }
                                                </option>
                                              );
                                            }
                                          )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "grade8Name"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>                                  
                                  <Col sm={12} md={6} lg={6}>
                                  <FormGroup check>
                                    <Label for="dispenserFlowType" check>
                                      <Input type="checkbox" checked={this.state.isChecked} onChange={this.ckeckDispenserFlow}
                                       />{' '}
                                      High Flow Dispenser
                                      </Label>
                                  </FormGroup>
                                  </Col>
                                  </Row>
                                  <br/>
                              </>
                            ) : null}
                          </div>
                        </div>
                        {this.state.siteId &&
                          this.state.siteId.length > 0 ? (
                          <div className="sram-form-foot">
                            {PageDisplay.showSaveButton(formik.isSubmitting)}
                          </div>
                        ) : null}
                        <FormFieldFocusError />
                      </Form>
                    )}
                  </Formik>
                </div>
              </CardBody>
            </Card>
            {this.state.tempDeviceObj ? (
              <ConfirmModal
                isOpenConfirmModal={this.state.isOpenConfirmModal}
                closeConfirmModal={this.closeConfirmModal}
                handleSubmit={this.handleSubmit}
              >
                <div>
                  <div>
                    <Label>
                      {
                        OnboardingMsgResProps.body.notification.confirmation
                          .addDeviceTitle
                      }
                    </Label>
                  </div>
                </div>
              </ConfirmModal>
            ) : null}
          </div>
        </WithLoading>
      </>
    );
  }
}
export default Onboarding;
