import axios from "axios";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";
import { CurrentMessageSizeReportResProps } from "../messages/current-message-status-properties";

const actionFileName = "current-message-status-actions.js";

export function messageLogs(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/reports/message-log?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">messageLogs()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

//Get CurrentMessageSizeReportData Data
export function messageSizeReport(dateSelected, cancelToken) {
  let messageDate = dateSelected;
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "/reports/message-size?messageDate=" +
          messageDate,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">messageLogs()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

//  (Export CurrentMessageSizeReportData)
export function CurrentMessageSizeReportData(reportsData, sizeData) {
  let selectedSizeData = sizeData;
  let headerColumns = [];
  headerColumns.push(
    CurrentMessageSizeReportResProps.head.title.site.label.replace(
      new RegExp(" ", "g"),
      ""
    ) +
      "," +
      CurrentMessageSizeReportResProps.head.title.dispenser.label.replace(
        new RegExp(" ", "g"),
        ""
      ) +
      "," +
      CurrentMessageSizeReportResProps.head.title.imei.label +
      "," +
      CurrentMessageSizeReportResProps.head.title.topic.label.replace(
        new RegExp(" ", "g"),
        ""
      ) +
      "," +
      CurrentMessageSizeReportResProps.head.title.size.label +
      "\n"
  );

  let dataColumns = [];
  if (selectedSizeData === "total") {
    if (reportsData && reportsData.length > 0) {
      dataColumns = reportsData.map((user) => {
        let site = "";
        site = user.siteName;
        let Dispenser = "Dispenser";
        let dispenser1 = "";
        dispenser1 = user.sideANumber;
        let dispenser2 = "";
        dispenser2 = user.sideBNumber;
        let IMEINumber = "";
        IMEINumber = user.imeiNumber;
        let topicName = "All Topics";
        let topicSize = "";
        topicSize = user.messagesSize;
        return (
          site +
          "," +
          Dispenser +
          "-" +
          dispenser1 +
          "/" +
          dispenser2 +
          "," +
          IMEINumber +
          "," +
          topicName +
          "," +
          Math.round((topicSize / 1024) * 1000) / 1000
        );
      });
    }
  }
  if (selectedSizeData === "topic") {
    if (reportsData && reportsData.length > 0) {
      dataColumns = reportsData.map((user) => {
        return user.topics
          .map((topic) => {
            let site = "";
            if (user.siteName !== null && user.siteName.trim().length > 0)
              site = user.siteName.replace(new RegExp(",", "g"), "");
            let Dispenser = "Dispenser";
            let dispenser1 = "";
            if (user.sideANumber !== null) dispenser1 = user.sideANumber;
            let dispenser2 = "";
            if (user.sideBNumber !== null) dispenser2 = user.sideBNumber;
            let IMEINumber = "";
            if (user.imeiNumber !== null) IMEINumber = user.imeiNumber;
            let topicName = "";
            if (topic.topicName !== null && topic.topicName.trim().length > 0)
              topicName = topic.topicName.replace(new RegExp(",", "g"), "");
            let topicSize = "";
            if (topic.topicSize !== null) topicSize = topic.topicSize;
            return (
              site +
              "," +
              Dispenser +
              "-" +
              dispenser1 +
              "/" +
              dispenser2 +
              "," +
              IMEINumber +
              "," +
              topicName +
              "," +
              Math.round((topicSize / 1024) * 1000) / 1000
            );
          })
          .join("\n");
      });
    }
  }
  if (selectedSizeData === "both") {
    if (reportsData && reportsData.length > 0) {
      dataColumns = reportsData.map((user) => {
        let site = "";
        site = user.siteName;
        let Dispenser = "Dispenser";
        let dispenser1 = "";
        dispenser1 = user.sideANumber;
        let dispenser2 = "";
        dispenser2 = user.sideBNumber;
        let IMEINumber = "";
        IMEINumber = user.imeiNumber;
        let topicName = "All Topics";
        let topicSize = "";
        topicSize = user.messagesSize;
        let value = user.topics
          .map((topic) => {
            let site = "";
            if (user.siteName !== null && user.siteName.trim().length > 0)
              site = user.siteName.replace(new RegExp(",", "g"), "");
            let Dispenser = "Dispenser";
            let dispenser1 = "";
            if (user.sideANumber !== null) dispenser1 = user.sideANumber;
            let dispenser2 = "";
            if (user.sideBNumber !== null) dispenser2 = user.sideBNumber;
            let IMEINumber = "";
            if (user.imeiNumber !== null) IMEINumber = user.imeiNumber;
            let topicName = "";
            if (topic.topicName !== null && topic.topicName.trim().length > 0)
              topicName = topic.topicName.replace(new RegExp(",", "g"), "");
            let topicSize = "";
            if (topic.topicSize !== null) topicSize = topic.topicSize;
            return (
              site +
              "," +
              Dispenser +
              "-" +
              dispenser1 +
              "/" +
              dispenser2 +
              "," +
              IMEINumber +
              "," +
              topicName +
              "," +
              Math.round((topicSize / 1024) * 1000) / 1000
            );
          })
          .join("\n");
        return (
          value +
          "\n" +
          site +
          "," +
          Dispenser +
          "-" +
          dispenser1 +
          "/" +
          dispenser2 +
          "," +
          IMEINumber +
          "," +
          topicName +
          "," +
          Math.round((topicSize / 1024) * 1000) / 1000
        );
      });
    }
  }

  return headerColumns.join("\n").concat(dataColumns.join("\n"));
}

//Get Core Report Names and types Data
export function getCoreReports(cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/reports/report-types",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getCoreReports()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Send reports to User
export function sendReportsToUsers(reportsObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "reports/jupyter-reports",
        reportsObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">sendReportsToUsers()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

//Generate Schedule Reports
export function scheduleReports(reportsObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "reports/schedule-reports",
        reportsObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">scheduleReports()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// Get Schedule Reports
export function scheduledReportsHistory(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "reports/schedule-reports?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">scheduledReportsHistory()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// get reports History
export function reportsHistory(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "reports/jupyter-reports?" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">reportHistory()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Export reports History
export function exportReports(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "reports/report-export?s3Name=" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">exportReports()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Get user companies
export function userCompany(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/user-companies?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">userCompany()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Get user divisions
export function userOrgDivisions(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/user-companies/" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">userOrgDivisions()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// Get user sub-divisions
export function userOrgSubDivisions(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/user-companies/" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">userOrgSubDivisions()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// Get user sites by companies & divisions
export function userSitesByOrg(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/user-companies/" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">userSitesByOrg()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// schedule status change
export function scheduleStatus(
  tempScheduleEnableDisableObj,
  queryData,
  cancelToken
) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
          "/reports/schedule-status?" +
          queryData,
        tempScheduleEnableDisableObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">scheduleStatus()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}
