export default class AlertUtils {
  static getSiteAddress(site) {
    let address = "";
    if (site) {
      const addressArr = [];
      if (site.streetAddress1 && site.streetAddress1) {
        addressArr.push(site.streetAddress1);
      }
      if (site.streetAddress2 && site.streetAddress2.trim().length > 0) {
        addressArr.push(site.streetAddress2);
      }
      if (addressArr.length > 0) {
        address = addressArr.join(", ");
      }
    }
    return address.toString();
  }
  static getSiteAddress1(site) {
    let address1 = "";
    if (site) {
      const addressArr = [];
      if (site.city && site.city.trim().length > 0) {
        addressArr.push(site.city);
      }
      if (site.state && site.state.trim().length > 0) {
        addressArr.push(site.stateCode);
      }
      if (
        addressArr.length > 0 &&
        site.zipCode &&
        site.zipCode.trim().length > 0
      ) {
        if (site.countryCode) {
          address1 =
            addressArr.join(", ") + " " + site.zipCode + " " + site.countryCode;
        } else {
          address1 = addressArr.join(", ") + "  " + site.zipCode;
        }
      } else {
        if (site.countryCode) {
          address1 =
            addressArr.join(", ") + " " + site.zipCode + " " + site.countryCode;
        } else {
          address1 = addressArr.join(", ");
        }
      }
    }
    return address1.toString();
  }
}

const getAddress = (siteAddress, siteAddress1) => {
  let address = "";
  if (siteAddress && siteAddress1) {
    address = siteAddress + "," + siteAddress1;
  } else if (siteAddress) {
    address = siteAddress;
  } else {
    address = siteAddress1;
  }
  return address;
};

export const clientNotificationConstants = [
  {
    title: {
      key: "Customer Approval",
      options: [
        { key: "Warm Start Approval", value: "Warm Start Approval" },
        { key: "Dispatch Approval", value: "Dispatch Approval" },
      ],
    },
  },
  {
    title: {
      key: "Courtesy Email",
      options: [
        { key: "GFT Technicians", value: "GFT Technicians" },
        { key: "OOF Technicians", value: "OOF Technicians" },
        { key: "Warm Start Confirmation", value: "Warm Start Confirmation" },
      ],
    },
  },
  {
    title: {
      key: "Follow-up Email",
      options: [
        { key: "RRS Follow up", value: "RRS Follow up" },
        { key: "Dispatch Follow up", value: "Dispatch Follow up" },
      ],
    },
  },
  {
    title: {
      key: "Customer Experience Alert",
      options: [
        { key: "No Transactions", value: "No Transactions" },
        { key: "Low Flow", value: "Low Flow" },
        { key: "Disconnected Dispenser", value: "Disconnected Dispenser" },
        { key: "Receipt Paper Out", value: "Receipt Paper Out" },
      ],
    },
  },
  {
    title: {
      key: "Sub-Contractors",
      options: [{ key: "Sub-Contractors", value: "Sub-Contractors" }],
    },
  },
];

export const clientNotificationOptions = [
  {
    clientNotificationType: "Warm Start Approval",
    cientEmailType: "CA-WA",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Action Required: site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "This issue requires a remote repair to resolve. There may be a charge to you based upon your subscription. Please click Approve if you would like Guardian Connect to perform a Remote Response Service to address this issue. Click Reject if you do not want this issue addressed by Guardian Connect.",
  },
  {
    clientNotificationType: "Dispatch Approval",
    cientEmailType: "CA-DA",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Action Required: site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "This issue requires a technician visit to resolve. There will be a charge to you for this dispatch including travel, labor and any parts used to complete the repair. Please click Approve if you would like Guardian Connect to dispatch a technician to address this issue. Click Reject if you do not want this issue addressed by Guardian Connect.",
  },
  {
    clientNotificationType: "GFT Technicians",
    cientEmailType: "CE-GFT",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Notification: site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "A technician visit is required to complete the repair. This dispenser is under warranty. Guardian Connect will dispatch a technician to this site and there will be no charge to you. No further action is required.",
  },
  {
    clientNotificationType: "OOF Technicians",
    cientEmailType: "CE-OOF",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Notification: site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "This issue requires a technician visit to resolve. Please assign this issue to your preferred contractor.",
  },
  {
    clientNotificationType: "Warm Start Confirmation",
    cientEmailType: "CE-WSC",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Notification: site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "Guardian Connect is initiating a remote repair for the following location. There will be no charge to you. No further action is required.",
  },
  {
    clientNotificationType: "RRS Follow up",
    cientEmailType: "FR-RRS",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Action Required: site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "This is a follow up regarding the Remote Response Service approval request below. There may be a charge to you based on your subscription. Please Approve or Reject this request so that Guardian Connect can complete this remote repair. If no response is received within 48 hours, this ticket will be closed. No additional action will be taken to address this issue.",
  },
  {
    clientNotificationType: "Dispatch Follow up",
    cientEmailType: "FE-DFU",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Action Required: site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "This is a follow up regarding on technician dispatch approval request below. There will be a charge to you for this dispatch including travel, labor and any parts used to complete the repair. Please Approve or Reject this request so that a technician can be dispatched to resolve this issue. If a response is not received within 48 hours, this ticket will be closed. No additional action will be taken to address this issue.",
  },
  {
    clientNotificationType: "No Transactions",
    cientEmailType: "CA-NT",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Action Required: *alert_name* for site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "Guardian Connect has identified no transactions for 24 hours on one side of your dispenser. It is recommended that you contact the site to determine if a service technician needs to visit the site for additional troubleshooting and/or repairs. There will be a charge to you for this dispatch including travel, labor and any parts used to complete the repair. Please click Approve if you would like Guardian Connect to dispatch a service technician to address the issue. Click Reject if you do not want this issue addressed by Guardian Connect.",
  },
  {
    clientNotificationType: "Low Flow",
    cientEmailType: "CA-LF",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Action Required: *alert_name* for site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "Guardian Connect has identified low flow rate on one side of your dispenser. It is recommended that you contact the site to determine if a service technician needs to visit the site for additional troubleshooting and/or repairs. There will be a charge to you for this dispatch including travel, labor and any parts used to complete the repair. Please click Approve if you would like Guardian Connect to dispatch a service technician to address the issue. Click Reject if you do not want this issue addressed by Guardian Connect.",
  },
  {
    clientNotificationType: "Disconnected Dispenser",
    cientEmailType: "CA-DD",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Action Required: *alert_name* for site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "Guardian Connect has identified a fueling position at this location that has disconnected from the monitoring network. It is recommended that you contact the site to determine if a service technician needs to visit the site for additional troubleshooting and/or repairs. There will be a charge to you for this dispatch including travel, labor and any parts used to complete the repair. Please click Approve if you would like Guardian Connect to dispatch a service technician to address the issue. Click Reject if you do not want this issue addressed by Guardian Connect.",
  },
  {
    clientNotificationType: "Receipt Paper Out",
    cientEmailType: "CA-LP",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Action Required: *alert_name* for site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "Guardian Connect has identified empty receipt paper on one side of your dispenser. It is recommended that you contact the site to determine if a service technician needs to visit the site for additional troubleshooting and/or repairs. There will be a charge to you for this dispatch including travel, labor and any parts used to complete the repair. Please click Approve if you would like Guardian Connect to dispatch a service technician to address the issue. Click Reject if you do not want this issue addressed by Guardian Connect.",
  },
  {
    clientNotificationType: "Sub-Contractors",
    cientEmailType: "SU-CO",
    ticketSubject: function (siteName, siteAddress, siteAddress1) {
      return `Action Required: site ${siteName} located at ${getAddress(
        siteAddress,
        siteAddress1
      )}`;
    },
    ticketMessageBody:
      "This issue requires a technician to visit this site to resolve. Please click Accept if you will be dispatching a technician to address the issue. Click Reject if you will not be addressing the issue.",
  },
];
