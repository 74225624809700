// import { info } from "toastr";

export default class ChartSeries {
  static getPriceSeries(data) {
    const cash = data.map((grade) => {
      return grade.cashValue;
    });
    const credit = data.map((grade) => {
      return grade.creditValue;
    });
    const series = [
      {
        name: "Cash",
        data: cash,
      },
      {
        name: "Credit",
        data: credit,
      },
    ];
    return series;
  }
  static getMeterSeries(data) {
    const volume = data.map((meter) => {
      return meter.dayVolume;
    });
    const series = [
      {
        name: "Volume",
        data: volume,
      },
    ];
    return series;
  }
  static getTempSeries(data) {
    const temp = data.map((obj) => {
      return obj.temperature;
    });
    const series = [
      {
        name: "Temperature",
        data: temp,
      },
    ];
    return series;
  }
  static getHumiditySeries(data) {
    const humidity = data.map((obj) => {
      return obj.humidity;
    });
    const series = [
      {
        name: "Humidity",
        data: humidity,
      },
    ];
    return series;
  }
  // flow rates

  static getFlowSeries(data) {
    const grades = [];
    const gradeName = data.map((obj) => {
      return obj.gradeName;
    });
    var uniq = [...new Set(gradeName)];
    for (let val of uniq) {
      const flowRates = [];
      data.forEach((obj) => {
        if (val === obj.gradeName) {
          flowRates.push(obj.flowRate);
        }
      });
      grades.push({ name: val, data: flowRates });
    }
    const series = grades;
    return series;
  }

  static getFlowRateSeries(data) {
    const grades = [];
    const gradeName = data.map((obj) => {
      return obj.gradeName;
    });
    var uniq = [...new Set(gradeName)];
    for (let val of uniq) {
      const flowRates = [];
      data.forEach((obj) => {
        if (val === obj.gradeName) {
          flowRates.push(obj.flowRate);
        }
      });
      grades.push({ name: val, data: flowRates });
    }
    const series = grades;
    return series;
  }

  static getOverRunsSeries(data) {
    const side_a = data.sideA.map((obj) => {
      return obj.overrunCount;
    });
    const side_b = data.sideB.map((obj) => {
      return obj.overrunCount;
    });
    const series = [
      {
        name: "Side A",
        data: side_a,
      },
      {
        name: "Side B",
        data: side_b,
      },
    ];
    return series;
  }
  static getStandAloneSeries(data) {
    const side_a = data.sideA.map((obj) => {
      return obj.standaloneCount;
    });
    const side_b = data.sideB.map((obj) => {
      return obj.standaloneCount;
    });
    const series = [
      {
        name: "Side A",
        data: side_a,
      },
      {
        name: "Side B",
        data: side_b,
      },
    ];
    return series;
  }

  static getPriceChangeSeries(data) {
    const cash = data.map((grade) => {
      return grade.cashValue;
    });
    const credit = data.map((grade) => {
      return grade.creditValue;
    });
    const series = [
 
      {
        name: "Cash",
      data:cash,
      },
      {
        name: "Credit",
        data:credit
            },
    ];
    return series;

  }
}
