import axios from "axios";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";

const actionFileName = "sites-import-actions.js";

// Software Versions History
export function softwareVersionHistory(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/sitesimportautomationlogs?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">softwareVersionHistory()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Convert CSV to base64
export function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.replace('data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,', ''));
    reader.onerror = error => reject(error);
  })
};

// Add Software Versions
export function addSoftwareVersions(SofVerObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "/sitesimportautomation",
        SofVerObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">addSoftwareVersions()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Software Versions Items list
export function listSoftwareVersionItem(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/sitesimportautomation?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listSoftwareVersionItem()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

//  Software Versions Items Update
export function updateSoftwareVersionItem(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix + "/sitesimportautomation?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">updateSoftwareVersionItem()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

