export const DispenserMsgResProps = {
  head: {
    title: {
      sitesHome: "Sites",
      recentErrorLogs: "Recent Error Logs",
      rawLogs: "Raw Logs",
      errorLogsHistory: "Error Logs History",
      rebootHistory: "Reboot History",
      customerExpirience: "Customer Experience",
      security: "Security",
      volume: "Volume",
      technicalData: "Technical Data",
      prices: "Prices",
      sensorData: "Sensor Data",
      installDate: "Install Date",
      errorDetails: "Error Details",
      feedBack: "Feedback",
      reboot: "Reboot",
      alerts: "Alerts",
      failure: "Component Failure",
      price_volume: "Volume/Price",
      customerService: "Customer Service",
      errorLog: "Error Logs",
      volume1: "Volume (Meter 1)",
      volume2: "Volume (Meter 2)",
      volume3: "Volume (Meter 3)",
      volume4: "Volume (Meter 4)",
      price1: "Price (Grade 1)",
      price2: "Price (Grade 2)",
      price3: "Price (Grade 3)",
      price4: "Price (Grade 4)",
      amount: "Amount",
      priceLabel: "Price Changes",
      transactionLabel: "Transaction Data",
      transactionFlowLabel: "Transaction Peak Flow",
      gradeName: "Grade",
      typeValue: "Cash/Credit",
      type: "Type",
      price: "Price",
      pump: "Pump Number",
      standardReboot: "Standard Reboot",
      forceReboot: "Force Reboot",
      cancelReboot: "Cancel Reboot",
      transactionCount: "Transaction Count",
    },
  },
  body: {
    content: {
      live: "Live Critical Error Logs",
      closed: "Closed Critical Error Logs",
      critical: "Critical Error Logs",
      nonCritical: "Non Critical Error Logs",
      ecHistory: "EC History",
      rebootNow: "Reboot Now",
      scheduleReboot: "Schedule Reboot",
      doorAlerts: "Door Alerts",
      Grade1: "Grade 1 (Regular)",
      Grade2: "Grade 2 (Mid-grade)",
      Grade3: "Grade 3 (Premium)",
      Grade6: "Grade 6 (Diesel)",
      Meter1: "Meter 1 (Regular)",
      Meter2: "Meter 2 (Regular)",
      Meter3: "Meter 3 (Diesel)",
      Meter4: "Meter 4 (Diesel)",
      Meter5: "Meter 5 (Premium)",
      Meter6: "Meter 6 (Premium)",
      degreeFahrenheit: "°F",
      temperature: "Temperature",
      humidity: "Humidity",
      meter: "Meter",
      percentage: "%",
      maxFlowRateSideA: "Max Flow Rate - Side A",
      maxFlowRateSideB: "Max Flow Rate - Side B",
      flowRate: "Peak Flow Rate ",
      dflowRate: "Flow Rate Alerts",
      dNoTransaction: "No Transaction Alerts",
      flowSnapshotSideA: "Flow Rate Snapshot - Side A",
      flowSnapshotSideB: "Flow Rate Snapshot - Side B",
      peakFlowSideA: "Daily Average Flow Rate - Side A",
      peakFlowSideB: "Daily Average Flow Rate - Side B",
      pumpModel: "Pump Model",
      softwareVersion: "Software Version",
      bootVersion: "Boot Version",
      doorNodeVersion: "Door Node Version",
      mipVersion: "MIP Version",
      unitType: "Unit Type",
      siteiqSoftwareVersion: "SiteIQ Software Version",
      noDataFound: "No data found.",
      noIssuesFound: "No issues found.",
      overRun: "Overrun",
      standAlones: "Standalone",
      noStandAlones: "No Standalone",
      noOverRuns: "No Overruns",
      possibleFixes: "Possible Fixes",
      severityLevel: "Severity Level",
      description: "Description",
      fix: "Fix",
      possibleParts: "Possible Parts",
      feedBackProvide: "Provide feedback/Report new fix",
      clickToCheckStatus: "Click to check status",
      pump: "Pump",
      pumpStatus: "Pump Status",
      crind: "Crind",
      cardReader: "Card Reader",
      epp: "EPP",
      ppuDisplay: "PPU Display",
      printer: "Printer",
      fcb: "FCB",
      gsom: "GSOM",
      ssom: "SSOM",
      omnia: "Omnia",
      printerJammed: "Printer Jammed",
      paperOut: "Paper Out",
      alerts: "Alerts",
      pcnAlerts: "PCN Log (Beta)",
      templatePartNumber: "Template Part Number",
      templateDescription: "Template Description",
      igemVersion: "IGEM Version",
      all: "All",
      en_pump: "Encore Pump",
      // cardReader: "Card Reader",
      upm: "UPM",
      keyboard: "Keyboard",
      hardware: "Hardware",
      software: "Software",
      sidelabel: "Side ",
      display: "Display",
      // printer: "Printer",
      new_open: "New",
      dispatched: "Dispatched",
      // closed: "Closed",
      blendRatio: "Blend Ratio",
      calibration: "Calibration",
      meterId1: "Meter Id 1",
      meterId2: "Meter Id 2",
      meterFlow1: "Meter Peak Flow Rate 1",
      meterFlow2: "Meter Peak Flow Rate 2",
      model: "Model Number",
      serial: "Serial Number",
      activity: "Activity Monitor",
      core: "Core-FWR[Build]",
      media: "Media manager",
      gui: "omnia-gui",
      webui: "omnia-webui",
      nodejs: "NodeJs",
      wip: "WIP",
    },
    message: {
      selectDateTime: {
        label: "Select date and time",
      },
      selectRebootOption: {
        label: "Select one of the reboot option",
      },
      cancelMessage: {
        label: "Are you sure you want to cancel the scheduled reboot?",
      },
    },
    fp1: {
      label: "Receipt Paper - FP1",
    },
    fp2: {
      label: "Receipt Paper - FP2",
    },
    noTransSideA: {
      label: "No Transaction - Side A",
    },
    noTransSideB: {
      label: "No Transaction - Side B",
    },
    siteId: {
      label: "GVR ID",
    },
    all: {
      label: "All",
    },
    itemName: {
      label: "Item Name",
    },
    componentName: {
      label: "Component Name",
    },
    sideLabel: {
      label: "Side",
    },
    eventStatus: {
      label: "Event Status",
    },
    eventCause: {
      label: "Event Cause",
    },
    eventType: {
      label: "Event Type",
    },
    eventTimeStamp: {
      label: "Event Timestamp",
    },
    closedTimeStamp: {
      label: "Closed Timestamp",
    },
    open: {
      label: "Open",
    },
    closed: {
      label: "Closed",
    },
    close: {
      label: "Close",
    },
    doorType: {
      label: "Door Type",
    },
    doorStatus: {
      label: "Door Status",
    },
    side: {
      label: "Side",
    },
    errorCode: {
      label: "Error Code",
    },
    severity: {
      label: "Severity",
    },
    description: {
      label: "Description",
    },
    logicalNozzle: {
      label: "Logical Nozzle",
    },
    fillingCount: {
      label: "Filling Count",
    },
    deviceTime: {
      label: "Device Time",
    },
    dispenserTimestamp: {
      label: "Dispenser Timestamp",
    },
    dispenserCreateTimestamp: {
      label: "Dispenser Create Timestamp",
    },
    dispenserCloseTimestamp: {
      label: "Dispenser Close Timestamp",
    },
    deviceTimestamp: {
      label: "Device Timestamp",
    },
    seconds: {
      label: "Seconds",
    },
    // siteId: {
    //   label: "SIQ ID",
    // },
    // startTimestamp: {
    //   label: "Start Timestamp"
    // },
    // endTimestamp: {
    //   label: "End Timestamp"
    // },
    timeStamp: {
      label: "Timestamp",
    },
    rebootType: {
      label: "Reboot Type",
    },
    startTime: {
      label: "Start Time",
    },
    endTime: {
      label: "End Time",
    },
    status: {
      label: "Status",
    },
    comments: {
      label: "Comments",
    },
    yes: {
      label: "Yes",
    },
    no: {
      label: "No",
    },
    cancel: {
      label: "Cancel",
    },
    submit: {
      label: "Submit",
    },
    ticketId: {
      label: "Ticket Id",
    },
    ticketStatus: {
      label: "Ticket Status",
    },
    ticketPriority: {
      label: "Ticket Priority",
    },
    // eventStatus: {
    //   label: "Event Status"
    // },
    createTimestamp: {
      label: "Create Timestamp",
    },
    alertStatus: {
      label: "Alert Status",
    },
    alertMainCategory: {
      label: "Alert Main Category",
    },
    alertSubCategory: {
      label: "Alert Sub Category",
    },
    resolutionCategory: {
      label: "Resolution Category",
    },
    startTimestamp: {
      label: "Start Timestamp",
    },
    endTimestamp: {
      label: "End Timestamp",
    },
    alertEventId: {
      label: "Alert Event Id",
    },
    createAlerts: {
      label: "Create Alerts",
    },
    notification: {
      error: {
        feedBack: {
          empty: "Feedback is required",
        },
        dateTime: {
          empty: "Date and Time is required",
        },
        comments: {
          empty: "Comments is required",
        },
      },
    },
  },
};
