import React, { Component } from "react";
import { Card, CardTitle, CardBody, Button, Collapse, Table } from "reactstrap";
import { DispenserMsgResProps } from "../messages/dispenser-properties";
import Img from "../../../assets/images/reboot.png";
import WithLoading from "../../core/helpers/with-loading";
import PerfectScrollbar from "react-perfect-scrollbar";
// import PageUtils from "../../core/helpers/page-utils";
import { AppMsgResProps } from "../../core/messages/app-properties";
import axios from "axios";
import { checkToken } from "../actions/actions";
import AppUtils from "../../core/helpers/app-utils";
import WarmStartRebootModal from "./warm-start-reboot-modal";

class DispenserWarmStartReboot extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      isOpenRebootModal: false,
      isPageDataFetched: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    // PageUtils.scrollToTop();
    window.appHistory = this.props.history;
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  performReboot = async () => {
    await checkToken(this.props, this._cancelToken);
    await this.openRebootModal();
    await this.setStateAsync({ isPageDataFetched: true });
  };

  closeRebootModal = async () => {
    await this.setStateAsync({
      isOpenRebootModal: false,
    });
  };

  openRebootModal = async () => {
    await this.setStateAsync({
      isOpenRebootModal: true,
    });
  };

  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  warmSartRebootButton() {
    return (
      <Button
        className="btn btn-danger button-danger"
        onClick={() => this.performReboot()}
        // style={{ width: "45%" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <img src={Img} className="sram-reboot-img" alt="cable" />
        &nbsp;Warm Start Reboot &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </Button>
    );
  }

  render() {
    return (
      <>
        {this.props.showReboot === true ||
        this.props.showRebootHistory === true ? (
          <>
            {this.props.showRebootHistory === true ? (
              <Card className="sram-page-form sram-dispenser-card-bottom">
                <CardTitle
                  onClick={this.toggle.bind(this)}
                  className="sram-title-cursor"
                >
                  <span>{DispenserMsgResProps.head.title.rebootHistory}</span>
                </CardTitle>
                <Collapse isOpen={this.state.isOpen}>
                  <CardBody className="sram-dispenser-section-1 sram-dispenser-card-bdy1">
                    <WithLoading
                      isPageDataFetched={this.props.isPageDataFetched}
                      type="page"
                      size="sm"
                    >
                      {this.props.rebootHistory &&
                      this.props.rebootHistory.length > 0 ? (
                        <PerfectScrollbar className="sram-dispenser-card-body-height">
                          <Table>
                            <thead>
                              <tr>
                                <th className="sram-text-font sram-table-th sram-font-weight-600">
                                  {DispenserMsgResProps.body.rebootType.label}
                                </th>
                                <th className="sram-text-font sram-table-th sram-font-weight-600">
                                  {DispenserMsgResProps.body.startTime.label}
                                </th>
                                <th className="sram-text-font sram-table-th sram-font-weight-600">
                                  {DispenserMsgResProps.body.endTime.label}
                                </th>
                                <th className="sram-text-font sram-table-th sram-font-weight-600">
                                  {DispenserMsgResProps.body.status.label}
                                </th>
                                <th className="sram-text-font sram-table-th sram-font-weight-600">
                                  {DispenserMsgResProps.body.comments.label}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.rebootHistory.map((data, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="sram-text-font sram-table-td">
                                      {data.rebootTypeDescription}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {AppUtils.getDateTime(
                                        data.rebootStartTimestamp
                                      )}
                                      &nbsp; {data.rebootStartTimestampTimezone}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {AppUtils.getDateTime(
                                        data.rebootEndTimestamp
                                      )}
                                      &nbsp; {data.rebootEndTimestampTimezone}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {data.rebootStatusDescription}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {data.rebootComment}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </PerfectScrollbar>
                      ) : (
                        <div className="pt-3 py-3 sram-no-data">
                          {DispenserMsgResProps.body.content.noIssuesFound}
                        </div>
                      )}
                    </WithLoading>
                  </CardBody>
                </Collapse>
              </Card>
            ) : null}
            {this.props.showReboot === true ? (
              <div className="sram-background-white text-center sram-c-body-adj sram-dispenser-card-bottom">
                {this.warmSartRebootButton()}
              </div>
            ) : null}
          </>
        ) : null}
        {this.props.rebootHistory ? (
          <WarmStartRebootModal
            isOpenRebootModal={this.state.isOpenRebootModal}
            closeRebootModal={this.closeRebootModal}
            siteItem={this.props.siteItem}
            dispenserItem={this.props.dispenserItem}
            rebootHistory={this.props.getRebootHistory.bind(this)}
            isPageDataFetched={this.state.isPageDataFetched}
          />
        ) : null}
      </>
    );
  }
}

export default DispenserWarmStartReboot;
