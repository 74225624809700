import React, { Component } from "react";
import axios from "axios";
import { Card, CardTitle, CardBody, Table } from "reactstrap";
import WithLoading from "../../core/helpers/with-loading";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import PageUtils from "../../core/helpers/page-utils";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import PageDisplay from "../../core/helpers/page-display";
import { listDevices, searchDevices } from "../actions/device-actions";
import { DeviceMsgResProps } from "../messages/device-properties";
import { ConfigURLProps } from "../settings/config-urls";
import PageListPagination from "../../core/helpers/page-list-pagination";
import PageListLimitSelect from "../../core/helpers/page-list-limit-select";
import PageListHeaderColumn from "../../core/helpers/page-list-header-column";
import PageListSearchField from "../../core/helpers/page-list-search-field";
import DeleteDevice from "./device-delete-modal";

class Devices extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcViewDevice = "AT-DVC-DVIW";
  _pcDeviceEdit = "AT-DVC-DEDT";
  _pcDevicDelete = "AT-DVC-DDEL";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      partPrivileges: this.initPartPrivileges(),
      devicesAllRecords: null,
      devicesDisplayRecords: null,
      devicesPageRecords: null,
      searchValue: "",
      searchKeyword: "",
      pageOffset: AppConfigProps.listPage.pageOffset.defaultValue,
      pageLimit: AppConfigProps.listPage.pageLimit.defaultValue,
      sortedColumn: "siteNumber",
      sortedOrder: AppConfigProps.listPage.sortOrder.defaultValue,
      dispenserItem: null,
      DeviceModalStatus: false,
      isDevicesDataFetched: false
    };
    this.toggleDeviceModal = this.toggleDeviceModal.bind(this);
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async toggleDeviceModal(dispenserItem) {
    await this.setState({
      dispenserItem: dispenserItem,
      DeviceModalStatus: !this.state.DeviceModalStatus
    });
    if (this.state.DeviceModalStatus === false) {
      await this.getDeicesData();
      await this.handleSearchSubmit();
    }
    // this.getDeicesData()
  }
  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
    window.appHistory = this.props.history;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      PageUtils.scrollToTop();
      await this.loadPageData();
    }
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({
      devicesAllRecords: null,
      devicesDisplayRecords: null,
      devicesPageRecords: null,
    });
    await this.checkPartPrivilege();
    await this.setPartPrivileges();
    const queryData =
      "sortField=siteNumber&sortOrder=1";
    if (this.state.partPrivileges[this._pcViewDevice] === true) {
      await listDevices(queryData, this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            if (res.data.records.length > 0) {
              await this.setStateAsync({ devicesAllRecords: res.data.records });
              let sortedRecords = await PageUtils.sortListRecords(
                this.state.devicesAllRecords,
                this.state.sortedColumn,
                this.state.sortedOrder
              );
              await this.setStateAsync({ devicesDisplayRecords: sortedRecords });
              let slicedRecords = await PageUtils.sliceListRecords(
                this.state.devicesDisplayRecords,
                this.state.pageOffset,
                this.state.pageLimit
              );
              await this.setStateAsync({ devicesPageRecords: slicedRecords });
            }
          } else {
            await manageError(res, this.props.history);
          }
        })
        .catch(async (err) => {
          await manageError(err, this.props.history);
        });
    }
    await this.setStateAsync({ isPageDataFetched: true });
  };

  getDeicesData = async () => {
    // await this.setStateAsync({
    //   devicesAllRecords: null,
    //   devicesDisplayRecords: null,
    //   devicesPageRecords: null,
    // });
    const queryData =
      "sortField=siteNumber&sortOrder=1";

    await listDevices(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ devicesAllRecords: res.data.records });
            let sortedRecords = await PageUtils.sortListRecords(
              this.state.devicesAllRecords,
              this.state.sortedColumn,
              this.state.sortedOrder
            );
            await this.setStateAsync({ devicesDisplayRecords: sortedRecords });
            let slicedRecords = await PageUtils.sliceListRecords(
              this.state.devicesDisplayRecords,
              this.state.pageOffset,
              this.state.pageLimit
            );
            await this.setStateAsync({ devicesPageRecords: slicedRecords });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  initPartPrivileges = () => {
    return {
      [this._pcViewDevice]: false,
      [this._pcDeviceEdit]: false,
      [this._pcDevicDelete]: false
    };
  };

  checkPartPrivilege = async () => {
    const partCode = [this._pcViewDevice, this._pcDeviceEdit, this._pcDevicDelete];
    const partPrivilege = await getPagePartsPrivilege(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(ConfigURLProps.pageNotFound);
    }
  };

  setPartPrivileges = async () => {
    const partCodes = [this._pcViewDevice, this._pcDeviceEdit, this._pcDevicDelete];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  getTableColumnHeadings = () => {
    return [
      {
        columnId: "siteNumber",
        columnType: "integer",
        columnLabel: DeviceMsgResProps.body.form.gvrId.label,
        isSortable: true,
        columnWidth: "20%",
      },
      {
        columnId: "siteName",
        columnType: "string",
        columnLabel: DeviceMsgResProps.body.form.siteName.label,
        isSortable: true,
        columnWidth: "40%",
      },
      {
        columnId: "sideANumber",
        columnType: "integer",
        columnLabel: DeviceMsgResProps.body.form.dispenser.label,
        isSortable: true,
        columnWidth: "20%",
      },
      {
        columnId: "imeiNumber",
        columnType: "string",
        columnLabel: DeviceMsgResProps.body.form.deviceId.label,
        isSortable: true,
        columnWidth: "20%",
      },
    ];
  };

  handleSortChange = async (sortColumn, sortOrder, sortType) => {
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.devicesDisplayRecords,
      sortColumn,
      sortOrder,
      sortType
    );
    await this.setStateAsync({ devicesDisplayRecords: sortedRecords });
    await this.setStateAsync({
      sortedColumn: sortColumn,
      sortedOrder: sortOrder,
    });

    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.devicesDisplayRecords,
      pageOffset,
      this.state.pageLimit
    );
    await this.setStateAsync({ devicesPageRecords: slicedRecords });
    await this.setStateAsync({ pageOffset: pageOffset });
  };

  handlePageLimitChange = async (pageLimit) => {
    if (pageLimit > 0) {
      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.devicesDisplayRecords,
        pageOffset,
        pageLimit
      );
      await this.setStateAsync({ devicesPageRecords: slicedRecords });
      await this.setStateAsync({ pageLimit: pageLimit });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handlePageOffsetChange = async (pageOffset) => {
    if (pageOffset >= 0) {
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.devicesDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );
      await this.setStateAsync({ devicesPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchChange = async (e) => {
    await this.setStateAsync({ searchValue: e.target.value });
  };

  handleSearchSubmit = async (e) => {
    if (this.state.devicesAllRecords && this.state.devicesAllRecords.length > 0) {
      let searchKeyword = this.state.searchValue;
      await this.setStateAsync({ searchKeyword: searchKeyword });
      let searchedRecords = await searchDevices(
        this.state.devicesAllRecords,
        this.state.searchKeyword
      );

      let sortedRecords = await PageUtils.sortListRecords(
        searchedRecords,
        this.state.sortedColumn,
        this.state.sortedOrder
      );
      await this.setStateAsync({ devicesDisplayRecords: sortedRecords });

      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.devicesDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );

      await this.setStateAsync({ devicesPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchCancel = async (e) => {
    e.preventDefault();
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.devicesAllRecords,
      this.state.sortedColumn,
      this.state.sortedOrder
    );
    await this.setStateAsync({ devicesDisplayRecords: sortedRecords });
    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.devicesDisplayRecords,
      this.state.pageOffset,
      this.state.pageLimit
    );
    await this.setStateAsync({ devicesPageRecords: slicedRecords });

    await this.setStateAsync({ searchKeyword: "", searchValue: "" });
    await this.setStateAsync({ pageOffset: pageOffset });
  };


  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <Card className="sram-page-list">
              <CardTitle>
                <span>{DeviceMsgResProps.body.content.devices}</span>
              </CardTitle>
              <CardBody>
                <>
                  {this.state.devicesAllRecords &&
                    this.state.devicesAllRecords.length > 0 ?
                    <div className="sram-list-head">
                      <span className="float-left col-sm-6 col-md-4 col-lg-4 pad-left-0">
                        <PageListSearchField
                          searchValue={this.state.searchValue}
                          searchKeyword={this.state.searchKeyword}
                          handleSearchChange={this.handleSearchChange}
                          handleSearchSubmit={this.handleSearchSubmit}
                          handleSearchCancel={this.handleSearchCancel}
                        />
                      </span>
                      <span className="float-right">
                        {PageDisplay.getListPageRecordsDisplayInfo(
                          this.state.devicesDisplayRecords
                            ? this.state.devicesDisplayRecords.length
                            : 0,
                          this.state.pageOffset,
                          this.state.pageLimit
                        )}
                      </span>
                    </div>
                    : null}
                  <div className="sram-list-main">
                    {this.state.devicesPageRecords &&
                      this.state.devicesPageRecords.length > 0 ? (
                      <Table
                        responsive
                        hover
                        size="sm"
                        className="sram-table-main"
                      >
                        <thead>
                          <tr>
                            {this.getTableColumnHeadings().map((column) => {
                              return (
                                <PageListHeaderColumn
                                  {...column}
                                  key={column.columnId}
                                  sortedColumn={this.state.sortedColumn}
                                  sortedOrder={this.state.sortedOrder}
                                  handleSortChange={this.handleSortChange}
                                />
                              );
                            })}
                            <th width="70"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.devicesPageRecords &&
                            this.state.devicesPageRecords.map((device, index) => {
                              let keyString = { key: `key-sos-list-${index}` };
                              return (
                                <tr {...keyString}>
                                  <td>{device.siteNumber}</td>
                                  <td>{device.siteName}</td>
                                  <td>Dispenser {device.sideANumber + '/' + device.sideBNumber}</td>
                                  <td>{device.imeiNumber}</td>
                                  <td className="text-right">
                                    {this.state.partPrivileges[
                                      this._pcDevicDelete
                                    ] === true
                                      ?
                                      <i
                                        className=" d-inline fa fa-trash sram-page-trash-icon sram-page-action-icon sram-icon-view"
                                        aria-hidden="true"
                                        onClick={() => this.toggleDeviceModal(device)}
                                      ></i>
                                      : null}
                                    {this.state.partPrivileges[
                                      this._pcDeviceEdit
                                    ] === true
                                      ? PageDisplay.showEditLink(
                                        "S",
                                        ConfigURLProps.editDevice.replace(
                                          ":did",
                                          device.dispenserId
                                        ),
                                        DeviceMsgResProps.body.content.editDevice
                                      )
                                      : null}
                                    {this.state.partPrivileges[
                                      this._pcViewDevice
                                    ] === true
                                      ? PageDisplay.showViewLink(
                                        "S",
                                        ConfigURLProps.viewDevice.replace(
                                          ":did",
                                          device.dispenserId
                                        ),
                                        DeviceMsgResProps.body.content.viewDevice
                                      )
                                      : null}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    ) : (
                      <>
                        <div>
                          {DeviceMsgResProps.body.content.noDevicesFound}
                        </div>
                      </>
                    )}
                  </div>
                  {this.state.devicesPageRecords &&
                    this.state.devicesPageRecords.length ? (
                    <div className="sram-list-foot">
                      <div className="float-left">
                        <PageListLimitSelect
                          pageLimit={this.state.pageLimit}
                          pageOffset={this.state.pageOffset}
                          totalRecords={this.state.devicesDisplayRecords.length}
                          handlePageLimitChange={this.handlePageLimitChange}
                        />
                      </div>
                      <div className="float-right">
                        <PageListPagination
                          pageLimit={this.state.pageLimit}
                          pageOffset={this.state.pageOffset}
                          totalRecords={this.state.devicesDisplayRecords.length}
                          handlePageOffsetChange={this.handlePageOffsetChange}
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              </CardBody>
            </Card>
          </div>
        </WithLoading>
        <DeleteDevice
          dispenserItem={this.state.dispenserItem}
          isOpenDeviceModal={this.state.DeviceModalStatus}
          toggleDeviceModal={this.toggleDeviceModal}
          {...this.props}
        />
      </>
    );
  }
}
export default Devices;
