import React, { Component } from "react";
import axios from "axios";
import { Card, CardTitle, CardBody, FormGroup, Label, Row, Col, Button, Table, Alert } from "reactstrap";
import WithLoading from "../../core/helpers/with-loading";
import PageUtils from "../../core/helpers/page-utils";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import PageDisplay from "../../core/helpers/page-display";
import { CurrentMessageSizeReportResProps } from "../messages/current-message-status-properties";
import { messageSizeReport, CurrentMessageSizeReportData } from "../actions/current-message-status-actions";
import { Input } from "reactstrap";

class CurrentMessageSizeReport extends Component {
    _isMounted = false;
    _axiosSource = axios.CancelToken.source();
    _cancelToken = { cancelToken: this._axiosSource.token };
    _pcViewMesageLog = "AT-DVC-DVIW";
    _pcMessageLogView = "CP-SIT-SVIW";
    constructor(props) {
        super(props);
        this.state = {
            isPageDataFetched: false,
            partPrivileges: this.initPartPrivileges(),
            messageLogs: null,
            sd: null,
            selectedFormat: null,
            isFormDataFilled: false,
            isSearchSubmit: false,
            isTableDataFetched: false,
            CSVFileClicked: false,
            selectedSize: null
        };
    }

    setStateAsync = (state) => {
        if (this._isMounted) {
            return new Promise((resolve) => {
                this.setState(state, resolve);
            });
        }
    };

    async componentDidMount() {
        this._isMounted = true;
        PageUtils.scrollToTop();
        await this.loadPageData();
        window.appHistory = this.props.history;
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            PageUtils.scrollToTop();
            await this.loadPageData();
        }
    }

    loadPageData = async () => {
        await this.setStateAsync({ isPageDataFetched: false });
        await this.setStateAsync({ messageLogs: null });
        await this.setStateAsync({ isPageDataFetched: true });
    };

    initPartPrivileges = () => {
        return {
            [this._pcViewMesageLog]: false,
            [this._pcMessageLogView]: false,
        };
    };


    handleSubmit = async () => {
        if (this.state.sd !== null && this.state.selectedFormat > 0 && this.state.selectedSize !== null) {
            await this.setStateAsync({ isSearchSubmit: true })
            this.setStateAsync({ isTableDataFetched: false })
            let dateSelected = this.state.sd
            await this.messageReport(dateSelected, this._cancelToken)
            await this.setStateAsync({ isTableDataFetched: true });
        } else {
            console.log(PageDisplay.getFormikFieldErrorMessage(
                "manufacturerName"
            ))
        }
    };

    messageReport = async (dateSelected) => {
        await messageSizeReport(dateSelected, this._cancelToken)
            .then(async (res) => {
                if (
                    res &&
                    res.status === AppConfigProps.httpStatusCode.ok &&
                    res.data &&
                    res.data.records
                ) {
                    await this.setStateAsync({ messageLogs: res.data.records });
                    if (this.state.selectedFormat === "2") {
                        await this.exportReport()
                        await this.setStateAsync({ isSearchSubmit: false })
                        await this.setStateAsync({ CSVFileClicked: true })
                    } else {
                        await this.setStateAsync({ isSearchSubmit: true })
                        await this.setStateAsync({ CSVFileClicked: false })
                    }
                } else {
                    await manageError(res, this.props.history);
                }
            })
            .catch(async (err) => {
                await manageError(err, this.props.history);
            });
    }

    exportReport = async (e) => {
        if (this.state.messageLogs &&
            this.state.messageLogs.length > 0) {
            let reportsData = this.state.messageLogs
            let sizeData = this.state.selectedSize
            const csvFileData = CurrentMessageSizeReportData(reportsData, sizeData);
            const csvFileName = "SiteIQ_MessageSize_" + this.state.sd + ".csv";
            PageUtils.downloadCSVFile(csvFileName, csvFileData);
        }
    }

    handleSdChange = async (e) => {
        e.preventDefault();
        let sd = e.target.value;
        await this.setState({ sd: sd });
        await this.checkFormData();
    }

    handleOutputTypeChange = async (e, option) => {
        this.setStateAsync({ isSearchSubmit: false })
        this.setStateAsync({ CSVFileClicked: false })
        let outputValue = e.target.value
        await this.setStateAsync({ selectedFormat: outputValue });
        await this.checkFormData();
    };

    handleSizeValueChange = async (e) => {
        this.setStateAsync({ isSearchSubmit: false })
        this.setStateAsync({ CSVFileClicked: false })
        let sizeValue = e.target.value
        await this.setStateAsync({ selectedSize: sizeValue });
        await this.checkFormData();
    }

    checkFormData = async () => {
        if (
            this.state.sd !== null &&
            this.state.selectedFormat &&
            this.state.selectedFormat.length > 0 &&
            this.state.selectedSize !== null
        ) {
            await this.setStateAsync({ isFormDataFilled: true });
        } else {
            await this.setStateAsync({ isFormDataFilled: false });
        }
    };

    renderHtmlForm = () => {
        return (
            this.state.selectedSize === "total" ?
                this.renderTotalHtmlForm() :
                this.state.selectedSize === "topic" ?
                    this.renderTopicHtmlForm() :
                    this.state.selectedSize === "both" ?
                        this.renderAllHtmlForm() : null
        )
    }

    renderExportForm() {
        if (this.state.messageLogs &&
            this.state.messageLogs.length > 0) {
            this.renderExportForm.isCalled = true
            return (
                <div className="page-content-space">
                    <Alert color="secondary">
                        CSV file is downloaded. Please check the downloads folder of your browser.
                </Alert>
                </div>
            )
        } else {
            return (
                <div className="page-content-space">
                    <Alert color="secondary">
                        No data found.
            </Alert>
                </div>
            )
        }
    }


    renderTotalHtmlForm() {
        return (
            <div className="page-content-space">
                <WithLoading
                    isPageDataFetched={this.state.isTableDataFetched}
                    type="field"
                >
                    {this.state.messageLogs &&
                        this.state.messageLogs.length > 0 ?
                        <Table
                            responsive
                            hover
                            size="sm"
                            className="sram-table-main"
                        >
                            <thead>
                                <tr>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.site.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.dispenser.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.imei.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.topic.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.size.label}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.state.messageLogs &&
                                    this.state.messageLogs.length > 0
                                    ? this.state.messageLogs.map((obj, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td className="sram-text-font">{obj.siteName}</td>
                                                    <td className="sram-text-font">Dispenser {obj.sideANumber + "/" + obj.sideBNumber}</td>
                                                    <td className="sram-text-font">{obj.imeiNumber}</td>
                                                    <td className="sram-text-font">All Topics</td>
                                                    <td className="sram-text-font">{Math.round(obj.messagesSize / 1024 * 1000) / 1000}</td>
                                                </tr>
                                            </>
                                        )
                                    }) : null
                                }
                            </tbody>
                        </Table>
                        :
                        <div className="page-content-space">
                            <Alert color="secondary">
                                No data found.
                            </Alert>
                        </div>
                    }
                </WithLoading>
            </div>
        )
    }

    renderTopicHtmlForm() {
        return (
            <div className="page-content-space">
                <WithLoading
                    isPageDataFetched={this.state.isTableDataFetched}
                    type="field"
                >
                    {this.state.messageLogs &&
                        this.state.messageLogs.length > 0 ?
                        <Table
                            responsive
                            hover
                            size="sm"
                            className="sram-table-main"
                        >
                            <thead>
                                <tr>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.site.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.dispenser.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.imei.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.topic.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.size.label}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.state.messageLogs &&
                                    this.state.messageLogs.length > 0
                                    ? this.state.messageLogs.map((obj, index) => {
                                        return (
                                            <>
                                                {obj.topics && obj.topics.length > 0 ?
                                                    obj.topics.map((topic, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="sram-text-font">{obj.siteName}</td>
                                                                <td className="sram-text-font">Dispenser {obj.sideANumber + "/" + obj.sideBNumber}</td>
                                                                <td className="sram-text-font">{obj.imeiNumber}</td>
                                                                <td className="sram-text-font">{topic.topicName}</td>
                                                                <td className="sram-text-font">{Math.round(topic.topicSize / 1024 * 1000) / 1000}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : null}
                                            </>
                                        )
                                    }) : null
                                }
                            </tbody>
                        </Table>
                        :
                        <div className="page-content-space">
                            <Alert color="secondary">
                                No data found.
                            </Alert>
                        </div>
                    }
                </WithLoading>
            </div>
        )
    }

    renderAllHtmlForm() {
        return (
            <div className="page-content-space">
                <WithLoading
                    isPageDataFetched={this.state.isTableDataFetched}
                    type="field"
                >
                    {this.state.messageLogs &&
                        this.state.messageLogs.length > 0 ?
                        <Table
                            responsive
                            hover
                            size="sm"
                            className="sram-table-main "
                        >
                            <thead>
                                <tr>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.site.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.dispenser.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.imei.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.topic.label}
                                    </th>
                                    <th className="sram-text-font">
                                        {CurrentMessageSizeReportResProps.head.title.size.label}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.state.messageLogs &&
                                    this.state.messageLogs.length > 0
                                    ? this.state.messageLogs.map((obj, index) => {
                                        return (
                                            <>
                                                {obj.topics && obj.topics.length > 0 ?
                                                    obj.topics.map((topic, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td className="sram-text-font">{obj.siteName}</td>
                                                                <td className="sram-text-font">Dispenser {obj.sideANumber + "/" + obj.sideBNumber}</td>
                                                                <td className="sram-text-font">{obj.imeiNumber}</td>
                                                                <td className="sram-text-font">{topic.topicName}</td>
                                                                <td className="sram-text-font">{Math.round(topic.topicSize / 1024 * 1000) / 1000}</td>
                                                            </tr>
                                                        )
                                                    })
                                                    : null}
                                                <tr key={index}>
                                                    <td className="sram-text-font">{obj.siteName}</td>
                                                    <td className="sram-text-font">Dispenser {obj.sideANumber + "/" + obj.sideBNumber}</td>
                                                    <td className="sram-text-font">{obj.imeiNumber}</td>
                                                    <td className="sram-text-font">All Topics</td>
                                                    <td className="sram-text-font">{Math.round(obj.messagesSize / 1024 * 1000) / 1000}</td>
                                                </tr>
                                            </>
                                        )
                                    }) : null
                                }
                            </tbody>
                        </Table>
                        :
                        <div className="page-content-space">
                            <Alert color="secondary">
                                No data found.
                            </Alert>
                        </div>
                    }
                </WithLoading>
            </div>
        )
    }


    render() {
        return (
            <>
                <WithLoading
                    isPageDataFetched={this.state.isPageDataFetched}
                    type="page"
                >
                    <div className="page-content-space">
                        <Card className="sram-page-list">
                            <CardTitle>
                                <span className="sram-text-font">
                                    {
                                        CurrentMessageSizeReportResProps.body.content
                                            .CurrentMessageSizeReport
                                    }
                                </span>
                            </CardTitle>
                            <CardBody>
                                <div className="sram-list-main">
                                    <Row>
                                        <Col xl={2} lg={3} md={6} sm={12} >
                                            <FormGroup >
                                                <Label>
                                                    <span className="sram-text-font">
                                                        {
                                                            CurrentMessageSizeReportResProps.head.title
                                                                .date.label
                                                        }
                                                    </span>
                                                </Label>
                                                <Input
                                                    type="date"
                                                    value={this.state.sd}
                                                    className="form-control "
                                                    onChange={this.handleSdChange}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={2} lg={2} md={6} sm={12} xs={12}>
                                            <FormGroup onChange={this.handleOutputTypeChange}>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            <span className="sram-text-font">
                                                                {
                                                                    CurrentMessageSizeReportResProps.head.title
                                                                        .output.label
                                                                }
                                                            </span>
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row className='radioGroup'>
                                                    <Col xl={5} lg={6} md={4} sm={6} xs={4}>
                                                        <FormGroup >
                                                            <Label >
                                                                <Input value={1} type="radio" name="radio1" />{' '}
                                                                <span className="sram-text-font">
                                                                    {
                                                                        CurrentMessageSizeReportResProps.body.form
                                                                            .html.label
                                                                    }
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl={5} lg={6} md={4} sm={6} xs={4}>
                                                        <FormGroup >
                                                            <Label >
                                                                <Input value={2} type="radio" name="radio1" />{' '}
                                                                <span className="sram-text-font">
                                                                    {
                                                                        CurrentMessageSizeReportResProps.body.form
                                                                            .csv.label
                                                                    }
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                        <Col xl={3} lg={4} md={6} sm={12} xs={12}>
                                            <FormGroup onChange={this.handleSizeValueChange}>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            <span className="sram-text-font">
                                                                {
                                                                    CurrentMessageSizeReportResProps.head.title
                                                                        .sizevalues.label
                                                                }
                                                            </span>
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row className='radioGroup'>
                                                    <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <FormGroup >
                                                            <Label >
                                                                <Input value="total" type="radio" name="radio2" />{' '}
                                                                <span className="sram-text-font">
                                                                    {
                                                                        CurrentMessageSizeReportResProps.head.title
                                                                            .total.label
                                                                    }
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <FormGroup >
                                                            <Label >
                                                                <Input value="topic" type="radio" name="radio2" />{' '}
                                                                <span className="sram-text-font">
                                                                    {
                                                                        CurrentMessageSizeReportResProps.head.title
                                                                            .topicsize.label
                                                                    }
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xl={4} lg={4} md={4} sm={4} xs={4}>
                                                        <FormGroup >
                                                            <Label >
                                                                <Input value="both" type="radio" name="radio2" />{' '}
                                                                <span className="sram-text-font">
                                                                    {
                                                                        CurrentMessageSizeReportResProps.head.title
                                                                            .both.label
                                                                    }
                                                                </span>
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                        <Col xl={2} lg={2} md={6} sm={12} className="button-primary">
                                            <Button
                                                className="sram-btn-blue sram-form-btn mr-3 "
                                                disabled={!this.state.isFormDataFilled}
                                                onClick={this.handleSubmit}
                                            >Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    {this.state.isSearchSubmit === true ? this.renderHtmlForm() : null}
                                    {this.state.CSVFileClicked === true ? this.renderExportForm() : null}
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </WithLoading>
            </>
        );
    }
}
export default CurrentMessageSizeReport;
