import axios from "axios";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";

const actionFileName = "ribbon-actions.js";

// Sites info

export function sitesInfo(cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(AppConfigProps.serverRoutePrefix + "portal/sites-info", cancelToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">sitesInfo()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}
