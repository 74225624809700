import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import axios from "axios";
import WithLoading from "../helpers/with-loading";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import PageDisplay from "../helpers/page-display";
import PageUtils from "../helpers/page-utils";
import AppUtils from "../helpers/app-utils";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import { AppURLProps } from "../settings/app-urls";
import { AppConfigProps } from "../settings/app-config";
import { AppValidations } from "../settings/app-validations";
import { AppMsgResProps } from "../messages/app-properties";
import { IdentityMsgResProps } from "../messages/identity-properties";
import { manageError } from "../actions/common-actions";
import {
  getProfile,
  isPagePartPrivileged,
  changePassword,
} from "../actions/identity-actions";

class PasswordChange extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcChangePassword = "AC-UID-UCPW";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      profile: null,
      formInputFields: this.initFormInputFields(),
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ profile: null });
    await this.checkPartPrivilege();
    await getProfile(this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ profile: res.data.result });
        } else {
          this.props.history.push(AppURLProps.home);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcChangePassword}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  initFormInputFields = () => {
    return {
      currentPassword: "",
      newPassword: "",
      retypePassword: "",
    };
  };

  formValidationSchema = () => {
    const schema = {
      currentPassword: Yup.string()
        .trim()
        .required(
          IdentityMsgResProps.body.notification.error.currentPassword.empty
        ),
      newPassword: Yup.string()
        .required(IdentityMsgResProps.body.notification.error.newPassword.empty)
        .matches(
          AppValidations.identity.password,
          IdentityMsgResProps.body.notification.error.newPassword.invalid
        ),
      retypePassword: Yup.string()
        .trim()
        .required(
          IdentityMsgResProps.body.notification.error.retypePassword.empty
        )
        .oneOf(
          [Yup.ref("newPassword"), null],
          IdentityMsgResProps.body.notification.error.retypePassword.invalid
        ),
    };
    return schema;
  };

  handleSubmit = async (values, helpers) => {
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }

    const passwordObj = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      retypePassword: values.retypePassword,
      accessToken: AppUtils.getIdentityAccessToken(),
    };

    await changePassword(passwordObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = [
            "S",
            IdentityMsgResProps.body.notification.success.changePasswordPass,
          ];
          if (this._isMounted) {
            helpers.resetForm();
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          IdentityMsgResProps.body.notification.error.changePasswordFail,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);
          PageUtils.scrollToTop();
        }
      });
    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            {this.state.profile ? (
              <Card className="sram-page-form">
                <CardTitle>
                  <span>{IdentityMsgResProps.body.content.changePassword}</span>
                  <span className="float-right">
                    {PageDisplay.showGoBackLink(
                      "L",
                      AppURLProps.myAccount,
                      this.props.history
                    )}
                  </span>
                </CardTitle>
                <CardBody>
                  <Formik
                    initialValues={this.state.formInputFields}
                    validationSchema={Yup.object().shape(
                      this.formValidationSchema()
                    )}
                    onSubmit={(values, helpers) =>
                      this.handleSubmit(values, helpers)
                    }
                  >
                    {(formik) => (
                      <Form id="passwordForm" {...formEvent}>
                        <div className="sram-form-main">
                          <div>
                            {formik.status &&
                            Array.isArray(formik.status) &&
                            formik.status.length === 2 &&
                            (formik.status[0] === "E" ||
                              formik.status[0] === "S") ? (
                              <Row>
                                <Col xs={12}>
                                  {formik.status[0] === "E"
                                    ? PageDisplay.showErrorNotification(
                                        formik.status[1]
                                      )
                                    : PageDisplay.showSuccessNotification(
                                        formik.status[1]
                                      )}
                                </Col>
                              </Row>
                            ) : null}
                          </div>
                          <div>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="currentPassword">
                                    {
                                      IdentityMsgResProps.body.form
                                        .currentPassword.label
                                    }
                                  </Label>
                                  <Field
                                    name="currentPassword"
                                    type="password"
                                    maxLength="30"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.currentPassword,
                                      formik.touched.currentPassword
                                    )}
                                  />
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "currentPassword"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="newPassword">
                                    {
                                      IdentityMsgResProps.body.form.newPassword
                                        .label
                                    }
                                    <i
                                      className="fas fa-info-circle font-18 text-secondary ml-3"
                                      id="passwordInfo"
                                    ></i>
                                  </Label>
                                  <Field
                                    name="newPassword"
                                    type="password"
                                    maxLength="30"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.newPassword,
                                      formik.touched.newPassword
                                    )}
                                  />
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "newPassword"
                                  )}
                                  <UncontrolledPopover
                                    trigger="legacy"
                                    placement="auto"
                                    target="passwordInfo"
                                  >
                                    <PopoverHeader>
                                      {
                                        IdentityMsgResProps.body.form
                                          .newPassword.info.header
                                      }
                                    </PopoverHeader>
                                    <PopoverBody>
                                      <span style={{ whiteSpace: "pre-wrap" }}>
                                        {
                                          IdentityMsgResProps.body.form
                                            .newPassword.info.text
                                        }
                                      </span>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="retypePassword">
                                    {
                                      IdentityMsgResProps.body.form
                                        .retypePassword.label
                                    }
                                  </Label>
                                  <Field
                                    name="retypePassword"
                                    type="password"
                                    maxLength="30"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.retypePassword,
                                      formik.touched.retypePassword
                                    )}
                                  />
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "retypePassword"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </div>
                        <div className="sram-form-foot">
                          {PageDisplay.showSaveButton(formik.isSubmitting)}
                          {PageDisplay.showCancelButton(this.props.history)}
                        </div>
                        <FormFieldFocusError />
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  IdentityMsgResProps.body.content.noDataFound
                )}
              </>
            )}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default PasswordChange;
