export const SetupURLProps = {
  setup: "/setup",
  roles: {
    listRole: "/setup/roles",
    addRole: "/setup/roles/add",
    viewRole: "/setup/roles/:rid/view",
    editRole: "/setup/roles/:rid/edit",
  },
  siteOwners: {
    home: "/setup/site-owners",
    listCompany: "/setup/site-owners/companies",
    addCompany: "/setup/site-owners/companies/add",
    viewCompany: "/setup/site-owners/companies/:oid/view",
    editCompany: "/setup/site-owners/companies/:oid/edit",
    listDivision: "/setup/site-owners/divisions",
    addDivision: "/setup/site-owners/divisions/add",
    viewDivision: "/setup/site-owners/divisions/:oid/view",
    editDivision: "/setup/site-owners/divisions/:oid/edit",
    listSite: "/setup/site-owners/sites",
    addSite: "/setup/site-owners/sites/add",
    sitesImport: "/setup/site-owners/sites/sites-import",
    viewSite: "/setup/site-owners/sites/:sid/view",
    editSite: "/setup/site-owners/sites/:sid/edit",
    userAccess: "/setup/site-owners/sites/:sid/users",
  },
  serviceProviders: {
    home: "/setup/service-providers",
    listCompany: "/setup/service-providers/companies",
    addCompany: "/setup/service-providers/companies/add",
    viewCompany: "/setup/service-providers/companies/:oid/view",
    editCompany: "/setup/service-providers/companies/:oid/edit",
    listDivision: "/setup/service-providers/divisions",
    addDivision: "/setup/service-providers/divisions/add",
    viewDivision: "/setup/service-providers/divisions/:oid/view",
    editDivision: "/setup/service-providers/divisions/:oid/edit",
    listSite: "/setup/service-providers/sites",
    addSite: "/setup/service-providers/sites/add",
    sitesImport: "/setup/service-providers/sites/sites-import",
    viewSite: "/setup/service-providers/sites/:sid/view",
    editSite: "/setup/service-providers/sites/:sid/edit",
    userAccess: "/setup/service-providers/sites/:sid/users",
  },
  users: {
    listUser: "/setup/users",
    addUser: "/setup/users/add",
    viewUser: "/setup/users/:uid/view",
    editUser: "/setup/users/:uid/edit",
    siteAccess: "/setup/users/:uid/sites",
  },
};
