import React from "react";
import {
  Row,
  Col,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import classnames from "classnames";
import AppUtils from "../../core/helpers/app-utils";
// import Pagination from "../../core/helpers/pagination";
import WithLoading from "../../core/helpers/with-loading";
import {
  newAlerts,
  updateAlerts,
  createTicket,
  getTicket,
  searchAlertSiteNames,
  searchAlerts,
  listSites,
  listCompanies,
} from "../actions/alert-actions";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import { AlertMsgResProps } from "../messages/alert-properties";
import { AppMsgResProps } from "../../core/messages/app-properties";
import axios from "axios";
// import { includes } from "lodash";
import IgnoredAlerts from "./ignored-alerts";
import ConfirmModal from "../../core/helpers/confirm-modal";
import _ from "lodash";
import { getProfile } from "../../core/actions/identity-actions";
import DatePickerModal from "./date-picker-modal";
import Tickets from "./tickets";
import ClosedTickets from "./closed-tickets";
import PageListPagination from "../../core/helpers/page-list-pagination";
import PageListLimitSelect from "../../core/helpers/page-list-limit-select";
import PageUtils from "../../core/helpers/page-utils";
import DispatchTickets from "./dispatch-tickets";
import PendingCloseTickets from "./pending-close-tickets";
import SiteUtils from "../../portal/helpers/site-utils";
import PerfectScrollbar from "react-perfect-scrollbar";
import { AlertURLProps } from "../settings/alert-urls";
import Switch from "react-switch";
import { NavLink as RRNavLink } from "react-router-dom";
import Select from "react-select";
import moment from "moment";

class Alerts extends React.Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  _pcPortalSiteView = "CP-SIT-SVIW";
  _pcPortalViewAlerts = "HT-ALT-ALVW";
  _pcPortalViewTickets = "HT-TCT-TVIW";
  _pcPortalCreateIgnoreAlerts = "HT-ALT-ALIG";
  _pcPortalAlertsCreateTicket = "HT-TCT-ALCT";

  constructor() {
    super();
    this.state = {
      isPageDataFetched: false,
      isAlertsDataFetched: false,
      isIgnoredAlertsDataFetched: false,
      isclosedTicketsFetched: false,
      partPrivileges: this.initPartPrivileges(),
      isPrivilagesFetched: false,
      closedTickets: null,
      allClosedTickets: null,
      disabled: true,
      pageOfItems: [],
      groupedAlerts: null,
      ignoredAlerts: null,
      alerts: null,
      newAlerts: null,
      isOpen: true,
      sd: null,
      ed: null,
      sites: null,
      sitesList: null,
      siteName: null,
      sitesLoader: false,
      tickets: null,
      isTicketsDataFetched: true,
      sortedRecords: null,
      activeTab: "1",
      setActiveTab: "1",
      pageOffset: 0,
      pageLimit: 20,
      sortedColumn: "siteName",
      sortedOrder: "ASC",
      isAlertStatusUpdateModal: false,
      statusDataFetched: false,
      isTicketCreateConfirmModal: false,
      isTicketCreateReConfirmModal: false,
      selectedAlertConfirmation: null,
      reConfirmTicketId: null,
      reConfirmationTicketNo: null,
      isIgnoreConfirmModal: false,
      searchKeyword: "",
      searchValue: "",
      selectAll: false,
      startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
      endDate: new Date(new Date().setDate(new Date().getDate())),
      isSubmitting: false,
      stDate: "",
      edDate: "",
      showAllAlerts: "0",
      isChecked: false,
      sitesVerify: true,
      ticketObj: null,
      ignoredObj: null,
      alertTicketId: "",
      ticketPriority: "",
      ticketStatus: "",
      lockStatus: "",
      assignId: "",
      ticketsList: null,
      history: null,
      isHistoryDataFetched: false,
      statusData: null,
      profile: null,
      isDatePickerModal: false,
      filter: null,
      ignoredAlertsFilter: null,
      newAlertsDisplayRecords: null,
      newAlertsPageRecords: null,
      disabledTickets: false,
      usersList: null,
      ignoreAlertSites: null,
      dispatchTickets: null,
      dispatchTicketsList: null,
      isDispatchTicketsDataFetched: false,
      isPendingCloseTicketsDataFetched: false,
      pendingCloseTickets: null,
      pendingCloseTicketsList: null,
      wipPriority: [
        {
          id: 0,
          content: "None",
          ticketsCount: "",
          value: "No Priority",
          isChecked: false,
        },
        {
          id: 1,
          content: "High",
          ticketsCount: "",
          value: "High",
          isChecked: false,
        },
        {
          id: 2,
          content: "Medium",
          isChecked: false,
          ticketsCount: "",
          value: "Medium",
        },
        {
          id: 3,
          content: "Low",
          isChecked: false,
          ticketsCount: "",
          value: "Low",
        },
      ],
      wipClientApproval: [
        {
          id: 4,
          content: "Client Notification",
          ticketsCount: "",
          value: "Client Notification",
          isChecked: false,
        },
        {
          id: 5,
          content: "Pending Approval",
          ticketsCount: "",
          value: "Pending Approval",
          isChecked: false,
        },
        {
          id: 6,
          content: "Approved by Customer",
          ticketsCount: "",
          value: "Approved by Customer",
          isChecked: false,
        },
        {
          id: 7,
          content: "Rejected by Customer",
          ticketsCount: "",
          value: "Rejected by Customer",
          isChecked: false,
        },
        {
          id: 8,
          content: "No Response",
          isChecked: false,
          ticketsCount: "",
          value: "No Response",
        },
      ],
      workInProgress: [
        {
          id: 9,
          content: "Contact Site",
          ticketsCount: "",
          value: "Contact Site",
          isChecked: false,
        },
        {
          id: 10,
          content: "Reset/Reboot",
          ticketsCount: "",
          value: "Reset/Reboot",
          isChecked: false,
        },
        {
          id: 11,
          content: "Contact Customer",
          ticketsCount: "",
          value: "Contact Customer",
          isChecked: false,
        },
        {
          id: 12,
          content: "Dispatched to Branch",
          ticketsCount: "",
          value: "Dispatched to Branch",
          isChecked: false,
        },
        {
          id: 13,
          content: "Software Upgrades",
          ticketsCount: "",
          value: "Software Upgrades",
          isChecked: false,
        },
        {
          id: 14,
          content: "Software Bug Fix",
          ticketsCount: "",
          value: "Software Bug Fix",
          isChecked: false,
        },
      ],
      alertEventStatus: [
        {
          id: 15,
          content: "Open",
          ticketsCount: "",
          value: "O",
          isChecked: false,
        },
        {
          id: 16,
          content: "Close",
          ticketsCount: "",
          value: "C",
          isChecked: false,
        },
      ],
      wipTicketsIDFilter: [
        {
          id: 100,
          content: "",
          ticketsCount: "",
          value: "",
          isChecked: false,
        },
      ],
      dispatchTicketsPriority: [
        {
          id: 17,
          content: "None",
          ticketsCount: "",
          value: "No Priority",
          isChecked: false,
        },
        {
          id: 18,
          content: "High",
          ticketsCount: "",
          value: "High",
          isChecked: false,
        },
        {
          id: 19,
          content: "Medium",
          isChecked: false,
          ticketsCount: "",
          value: "Medium",
        },
        {
          id: 20,
          content: "Low",
          isChecked: false,
          ticketsCount: "",
          value: "Low",
        },
      ],
      dispatchTicketsApproval: [
        {
          id: 21,
          content: "Client Notification",
          ticketsCount: "",
          value: "Client Notification",
          isChecked: false,
        },
        {
          id: 22,
          content: "Pending Approval",
          ticketsCount: "",
          value: "Pending Approval",
          isChecked: false,
        },
        {
          id: 23,
          content: "Approved by Customer",
          isChecked: false,
          ticketsCount: "",
          value: "Approved by Customer",
        },
        {
          id: 24,
          content: "Rejected by Customer",
          isChecked: false,
          ticketsCount: "",
          value: "Rejected by Customer",
        },
        {
          id: 25,
          content: "No Response",
          isChecked: false,
          ticketsCount: "",
          value: "No Response",
        },
      ],
      dispatchWorkInProgress: [
        {
          id: 26,
          content: "Contact Site",
          ticketsCount: "",
          value: "Contact Site",
          isChecked: false,
        },
        {
          id: 27,
          content: "Reset/Reboot",
          ticketsCount: "",
          value: "Reset/Reboot",
          isChecked: false,
        },
        {
          id: 28,
          content: "Contact Customer",
          ticketsCount: "",
          value: "Contact Customer",
          isChecked: false,
        },
        {
          id: 29,
          content: "Dispatched to Branch",
          ticketsCount: "",
          value: "Dispatched to Branch",
          isChecked: false,
        },
        {
          id: 30,
          content: "Software Upgrades",
          ticketsCount: "",
          value: "Software Upgrades",
          isChecked: false,
        },
        {
          id: 31,
          content: "Software Bug Fix",
          ticketsCount: "",
          value: "Software Bug Fix",
          isChecked: false,
        },
      ],
      dispatchAlertEventStatus: [
        {
          id: 32,
          content: "Open",
          ticketsCount: "",
          value: "O",
          isChecked: false,
        },
        {
          id: 33,
          content: "Close",
          ticketsCount: "",
          value: "C",
          isChecked: false,
        },
      ],
      dispatchTicketsIDFilter: [
        {
          id: 200,
          content: "",
          ticketsCount: "",
          value: "",
          isChecked: false,
        },
      ],
      pendingCloseTicketsPriority: [
        {
          id: 34,
          content: "None",
          ticketsCount: "",
          value: "No Priority",
          isChecked: false,
        },
        {
          id: 35,
          content: "High",
          ticketsCount: "",
          value: "High",
          isChecked: false,
        },
        {
          id: 36,
          content: "Medium",
          isChecked: false,
          ticketsCount: "",
          value: "Medium",
        },
        {
          id: 37,
          content: "Low",
          isChecked: false,
          ticketsCount: "",
          value: "Low",
        },
      ],
      pendingCloseTicketsApproval: [
        {
          id: 38,
          content: "Client Notification",
          ticketsCount: "",
          value: "Client Notification",
          isChecked: false,
        },
        {
          id: 39,
          content: "Pending Approval",
          ticketsCount: "",
          value: "Pending Approval",
          isChecked: false,
        },
        {
          id: 40,
          content: "Approved by Customer",
          isChecked: false,
          ticketsCount: "",
          value: "Approved by Customer",
        },
        {
          id: 41,
          content: "Rejected by Customer",
          isChecked: false,
          ticketsCount: "",
          value: "Rejected by Customer",
        },
        {
          id: 42,
          content: "No Response",
          isChecked: false,
          ticketsCount: "",
          value: "No Response",
        },
      ],
      pendingCloseAlertEventStatus: [
        {
          id: 43,
          content: "Open",
          value: "O",
          isChecked: false,
        },
        {
          id: 44,
          content: "Close",
          value: "C",
          isChecked: false,
        },
      ],
      pendingCloseResolutionCategory: [
        {
          id: 45,
          content: "Customer Rejected",
          value: "Customer Rejected",
          isChecked: false,
        },
        {
          id: 46,
          content: "Credit Hold",
          value: "Credit Hold",
          isChecked: false,
        },
        {
          id: 47,
          content: "Dispatched to Vendor/3rd Party",
          value: "Dispatched to Vendor/3rd Party",
          isChecked: false,
        },
        {
          id: 48,
          content: "No Response from Customer",
          value: "No Response from Customer",
          isChecked: false,
        },
      ],
      pendingCloseTicketsIDFilter: [
        {
          id: 300,
          content: "",
          ticketsCount: "",
          value: "",
          isChecked: false,
        },
      ],
      closedTicketsPriority: [
        {
          id: 49,
          content: "None",
          ticketsCount: "",
          value: "No Priority",
          isChecked: false,
        },
        {
          id: 50,
          content: "High",
          ticketsCount: "",
          value: "High",
          isChecked: false,
        },
        {
          id: 51,
          content: "Medium",
          isChecked: false,
          ticketsCount: "",
          value: "Medium",
        },
        {
          id: 52,
          content: "Low",
          isChecked: false,
          ticketsCount: "",
          value: "Low",
        },
      ],
      closedAlertEventStatus: [
        {
          id: 53,
          content: "Open",
          value: "O",
          isChecked: false,
        },
        {
          id: 54,
          content: "Close",
          value: "C",
          isChecked: false,
        },
      ],
      closedResolutionCategory: [
        {
          id: 55,
          content: "Remote Fix",
          value: "Remote Fix",
          isChecked: false,
        },
        {
          id: 56,
          content: "Resolved by Branch",
          value: "Resolved by Branch",
          isChecked: false,
        },
        {
          id: 57,
          content: "Customer Resolved",
          value: "Customer Resolved",
          isChecked: false,
        },
        {
          id: 58,
          content: "Resolved by Vendor/3rd Party",
          value: "Resolved by Vendor/3rd Party",
          isChecked: false,
        },
        {
          id: 59,
          content: "Customer Rejected",
          value: "Customer Rejected",
          isChecked: false,
        },
        {
          id: 60,
          content: "Customer Ignored",
          value: "Customer Ignored",
          isChecked: false,
        },
        {
          id: 61,
          content: "Credit Hold",
          value: "Credit Hold",
          isChecked: false,
        },
        {
          id: 62,
          content: "Existing Branch Ticket",
          value: "Existing Branch Ticket",
          isChecked: false,
        },
      ],
      closedTicketsIDFilter: [
        {
          id: 400,
          content: "",
          ticketsCount: "",
          value: "",
          isChecked: false,
        },
      ],
      switchChecked: false,
      companies: [],
      companyNameValue: "",
      sitesAllOptions: null,
      sitesOptions: [],
      siteNameValue: "",
      alertStatusOptions: [
        { value: "O", content: "Open" },
        { value: "C", content: "Close" },
      ],
      showComponentOptions: [
        { value: "barcode scanner", content: "Barcode Scanner" },
        { value: "cardreader", content: "Cardreader" },
        { value: "display", content: "Display" },
        { value: "doorsensor", content: "Door Sensor" },
        { value: "edge", content: "Edge" },
        { value: "Flowrate", content: "Flowrate" },
        { value: "no transaction", content: "No Transaction" },
        { value: "printer", content: "Printer" },
        { value: "pumpcontrolnode", content: "Pump Control Node" },
        { value: "paymentterminal", content: "Payment Terminal" },
      ],
      showComponentValue: "",
      warrantyStatusOptions: [
        { value: "In", content: "In" },
        { value: "Out", content: "Out" },
      ],
      warrantyStatusValue: "",
      universalSearch: {},
      filterByTabs: {
        newAlertsTab: {},
        WIPTicketsTab: {},
        dispatchTicketsTab: {},
        ignoredAlertsTab: {},
        pendingCloseTicketsTab: {},
        closedTicketsTab: {
          startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
        },
      },
      alertsDate: {},
      disableFilters: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSitesFilter = this.handleSitesFilter.bind(this);
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    let tempDate = this.state.startDate;
    let sd =
      tempDate.getFullYear() +
      "-" +
      (tempDate.getMonth() + 1) +
      "-" +
      tempDate.getDate();
    sd = AppUtils.getDateFormat(sd);
    await this.setStateAsync({ sd: sd, stDate: sd });
    let tempDate1 = this.state.endDate;
    let ed =
      tempDate1.getFullYear() +
      "-" +
      (tempDate1.getMonth() + 1) +
      "-" +
      tempDate1.getDate();
    ed = AppUtils.getDateFormat(ed);
    await this.setStateAsync({ ed: ed, edDate: ed });
    const filterValue = {
      startDate: sd,
      endDate: ed,
    };
    Object.assign(this.state.universalSearch, filterValue);
    Object.assign(this.state.filterByTabs["newAlertsTab"], filterValue);
    Object.assign(this.state.filterByTabs["ignoredAlertsTab"], filterValue);
    await this.setStateAsync({ alertsDate: filterValue });
    await this.loadPageData();
    // this.interval = setInterval(() => {
    //   this.getAlert();
    // }, 300000);
  }
  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
    // clearInterval(this.interval);
  }
  loadPageData = async () => {
    await this.setStateAsync({
      isPageDataFetched: false,
    });
    await this.setStateAsync({ isPrivilagesFetched: false });
    await this.checkPartPrivilege();
    await this.setPartPrivileges();
    await this.setStateAsync({ isPrivilagesFetched: true });

    await getProfile(this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ profile: res.data.result });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    if (this.state.partPrivileges[this._pcPortalViewAlerts] === true) {
       this.getAlert();
       this.getUserCompanies();
      if (this.state.partPrivileges[this._pcPortalSiteView] === true) {
         this.getSites();
      }
    }
    await this.setStateAsync({ isPageDataFetched: true });
  };

  getAlert = async () => {
    if (this.state.isAlertsDataFetched === false) {
      await this.setStateAsync({
        isAlertsDataFetched: false,
        disabled: true,
        newAlerts: null,
        searchKeyword: "",
        groupedAlerts: null,
        pageOfItems: [],
        newAlertsDisplayRecords: null,
        newAlertsPageRecords: null,
        siteName: null,
      });
    }
    let queryData = "";
    if (this.state.stDate && this.state.edDate) {
      queryData = "alertStatus=N&showAllAlerts=" + this.state.showAllAlerts;
    } else {
      queryData = "alertStatus=N";
    }
    let querySearch;
    if (this.state.activeTab === "1") {
      querySearch = this.state.universalSearch;
    } else {
      querySearch = {
        startDate: this.state.stDate,
        endDate: this.state.edDate,
      };
    }
    await this.setStateAsync({ disableFilters: true });
    await newAlerts(queryData, querySearch, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            alerts: res.data.records,
            newAlerts: res.data.records,
            filter: res.data.filter,
          });
          let newAlertsDisplayRecords = this.state.newAlerts;

          await this.setStateAsync({
            newAlertsDisplayRecords: newAlertsDisplayRecords,
          });
          let slicedRecords = await PageUtils.sliceListRecords(
            this.state.newAlertsDisplayRecords,
            this.state.pageOffset,
            this.state.pageLimit
          );
          await this.setStateAsync({ newAlertsPageRecords: slicedRecords });
          var flags = [],
            output = [],
            l = res.data.records.length,
            i;
          for (i = 0; i < l; i++) {
            if (flags[res.data.records[i].siteName]) continue;
            flags[res.data.records[i].siteName] = true;
            output.push(res.data.records[i].siteName);
          }
          output.sort((a, b) => a?.localeCompare(b));
          if (this.state.partPrivileges[this._pcPortalSiteView] === false) {
            await this.setStateAsync({ sites: output });
          }
          await this.dateFilter();
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ disableFilters: false });
    await this.setStateAsync({ isAlertsDataFetched: true, disabled: false });
  };

  getUserCompanies = async () => {
    const queryData = "userType=" + this.state.profile?.userType;
    await listCompanies(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ companies: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  getSites = async () => {
    await this.setStateAsync({ sitesLoader: false });
    const queryData = "sortField=siteName&sortOrder=1";
    await listSites(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            var output = res.data.records.map((obj) => {
              return obj.siteName;
            });
            await this.setStateAsync({
              sites: output,
              sitesAllOptions: res.data.records,
              sitesOptions: res.data.records,
            });
            await this.setStateAsync({ ignoreAlertSites: output });
            // await this.setStateAsync({ siteList: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  updateAlert = async () => {
    await this.closeIgnoreConfirmModal();
    const queryData = "alertStatus=I";
    await this.setStateAsync({
      isSubmitting: !this.state.isSubmitting,
      isAlertsDataFetched: false,
      isIgnoredAlertsDataFetched: false,
      siteName: "",
    });
    await this.setStateAsync({ disabledTickets: false });
    await updateAlerts(queryData, this.state.ignoredObj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data
        ) {
          await this.getAlert();
          // await this.getIgnoredAlerts();
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isSubmitting: !this.state.isSubmitting });
  };

  getIgnoredAlerts = async () => {
    await this.setStateAsync({
      isIgnoredAlertsDataFetched: false,
    });
    let queryData = "alertStatus=I";
    let querySearch;
    if (this.state.activeTab === "3") {
      querySearch = this.state.universalSearch;
    } else {
      querySearch = {
        startDate: this.state.stDate,
        endDate: this.state.edDate,
      };
    }
    await newAlerts(queryData, querySearch, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            ignoredAlerts: res.data.records,
            ignoredAlertsFilter: res.data.filter,
            filter: res.data.filter,
          });
          var flags = [],
            output = [],
            l = res.data.records.length,
            i;
          for (i = 0; i < l; i++) {
            if (flags[res.data.records[i].siteName]) continue;
            flags[res.data.records[i].siteName] = true;
            output.push(res.data.records[i].siteName);
          }
          output.sort((a, b) => a?.localeCompare(b));
          if (this.state.partPrivileges[this._pcPortalSiteView] === false) {
            await this.setStateAsync({ ignoreAlertSites: output });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isIgnoredAlertsDataFetched: true });
  };
  getCloseddAlerts = async () => {
    await this.setStateAsync({
      isclosedTicketsFetched: false,
    });
    const queryData = "ticketStatus=C";
    let querySearch;
    if (this.state.activeTab === "4") {
      if (!_.isEmpty(this.state.universalSearch)) {
        querySearch = this.state.universalSearch;
      } else {
        querySearch = {
          startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
          endDate: moment(new Date()).format("YYYY-MM-DD"),
        };
        Object.assign(this.state.filterByTabs["closedTicketsTab"], querySearch);
      }
    } else {
      querySearch = {
        startDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
        endDate: moment(new Date()).format("YYYY-MM-DD"),
      };
    }
    await getTicket(queryData, querySearch, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let tickets = res.data.records;
          for (const data of tickets) {
            data["alertHistory"] = [];
            data["isOpen"] = false;
          }
          await this.setStateAsync({
            closedTickets: tickets,
            allClosedTickets: tickets,
            filter: res.data.filter,
          });
          var flags = [],
            output = [],
            l = res.data.records.length,
            i;
          for (i = 0; i < l; i++) {
            if (flags[res.data.records[i].siteName]) continue;
            flags[res.data.records[i].siteName] = true;
            output.push(res.data.records[i].siteName);
          }
          output.sort((a, b) => a?.localeCompare(b));
          await this.setStateAsync({ sitesList: output });

          var flags_name = [],
            output_names = [],
            length = res.data.records.length,
            index;
          for (index = 0; index < length; index++) {
            if (flags_name[res.data.records[index].userName]) continue;
            flags_name[res.data.records[index].userName] = true;
            output_names.push(res.data.records[index].userName);
          }
          output_names.sort((a, b) => a?.localeCompare(b));
          await this.setStateAsync({ usersList: output_names });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isclosedTicketsFetched: true });
  };
  createTicket = async () => {
    await this.closeTicketConfirmModal();
    await this.setStateAsync({
      isSubmitting: !this.state.isSubmitting,
      isAlertsDataFetched: false,
      isTicketsDataFetched: false,
      siteName: "",
    });
    await this.setStateAsync({
      disabledTickets: false,
      reConfirmTicketId: this.state.ticketObj?.alertEventId,
    });
    await createTicket(this.state.ticketObj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data
        ) {
          // await this.getAlert();
          // await this.getTickets();
          this.closeTicketReConfirmModel();
          this.setStateAsync({
            selectedAlertConfirmation: null,
            reConfirmTicketId: null,
            reConfirmationTicketNo: null,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        if (err && err.data && err.data.errors) {
          this.setStateAsync({ reConfirmationTicketNo: err.data.errors });
          this.openTicketReConfirmModel();
        }
        // await manageError(err, this.props.history);
      });
    await this.getAlert();
    await this.setStateAsync({ isSubmitting: !this.state.isSubmitting });
  };

  getTickets = async () => {
    await this.setStateAsync({
      isTicketsDataFetched: false,
    });
    const queryData = "ticketStatus=I";
    let querySearch;
    if (this.state.activeTab === "2") {
      querySearch = this.state.universalSearch;
    } else {
      querySearch = ``;
    }
    await getTicket(queryData, querySearch, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let tickets = res.data.records;
          for (const data of tickets) {
            data["alertHistory"] = [];
            data["isOpen"] = false;
          }
          await this.setStateAsync({
            tickets: tickets,
            ticketsList: tickets,
            filter: res.data.filter,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isTicketsDataFetched: true });
  };

  getAlertTickets = async () => {
    const queryData = "ticketStatus=I";
    let querySearch;
    if (this.state.activeTab === "2") {
      querySearch = this.state.universalSearch;
    } else {
      querySearch = ``;
    }
    await getTicket(queryData, querySearch, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let tickets = res.data.records;
          for (const data of tickets) {
            data["alertHistory"] = [];
            data["isOpen"] = false;
          }
          await this.setStateAsync({
            tickets: tickets,
            ticketsList: tickets,
            filter: res.data.filter,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  getDispatchTickets = async () => {
    await this.setStateAsync({
      isDispatchTicketsDataFetched: false,
    });
    const queryData = "ticketStatus=D";
    let querySearch;
    if (this.state.activeTab === "5") {
      querySearch = this.state.universalSearch;
    } else {
      querySearch = ``;
    }
    await getTicket(queryData, querySearch, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let tickets = res.data.records;
          for (const data of tickets) {
            data["alertHistory"] = [];
            data["isOpen"] = false;
          }
          await this.setStateAsync({
            dispatchTickets: tickets,
            dispatchTicketsList: tickets,
            filter: res.data.filter,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isDispatchTicketsDataFetched: true });
  };

  getDispatchAlertTickets = async () => {
    const queryData = "ticketStatus=D";
    let querySearch;
    if (this.state.activeTab === "5") {
      querySearch = this.state.universalSearch;
    } else {
      querySearch = ``;
    }
    await getTicket(queryData, querySearch, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let tickets = res.data.records;
          for (const data of tickets) {
            data["alertHistory"] = [];
            data["isOpen"] = false;
          }
          await this.setStateAsync({
            dispatchTickets: tickets,
            dispatchTicketsList: tickets,
            filter: res.data.filter,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  getDates = async (stDate, edDate) => {
    await this.setStateAsync({ stDate: stDate, sd: stDate });
    await this.setStateAsync({ edDate: edDate, ed: edDate });
    await this.setStateAsync({ isPageDataFetched: false });
    const filterValue = {
      startDate: stDate,
      endDate: edDate,
    };
    Object.assign(this.state.universalSearch, filterValue);
    await this.setDateFilters(filterValue);
    await this.getDataByFilters();
    await this.setStateAsync({ disabledTickets: false });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  setDateFilters = async (filterValue) => {
    const tab = this.state.activeTab;
    if (tab === "1") {
      Object.assign(this.state.filterByTabs.newAlertsTab, filterValue);
    }
    if (tab === "2") {
      Object.assign(this.state.filterByTabs.WIPTicketsTab, filterValue);
    }
    if (tab === "3") {
      Object.assign(this.state.filterByTabs.ignoredAlertsTab, filterValue);
    }
    if (tab === "4") {
      Object.assign(this.state.filterByTabs.closedTicketsTab, filterValue);
    }
    if (tab === "5") {
      Object.assign(this.state.filterByTabs.dispatchTicketsTab, filterValue);
    }
    if (tab === "6") {
      Object.assign(
        this.state.filterByTabs.pendingCloseTicketsTab,
        filterValue
      );
    }
  };

  handlePageLimitChange = async (pageLimit) => {
    if (pageLimit > 0) {
      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.newAlertsDisplayRecords,
        pageOffset,
        pageLimit
      );
      await this.setStateAsync({ newAlertsPageRecords: slicedRecords });
      await this.setStateAsync({ pageLimit: pageLimit });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };
  handlePageOffsetChange = async (pageOffset) => {
    if (pageOffset >= 0) {
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.newAlertsDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );
      await this.setStateAsync({ newAlertsPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  closeDatePickerModal = async () => {
    this.setState({
      isDatePickerModal: false,
    });
  };
  openDatePickerModal = async () => {
    this.setState({
      isDatePickerModal: true,
    });
  };

  toggle = async (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, setActiveTab: tab });
    }
    await this.checkticketsFilters(tab);
    if (tab === "1") {
      // await this.getAlert();
    }
    if (tab === "2") {
      await this.getTickets();
    }
    if (tab === "3") {
      await this.getIgnoredAlerts();
    }
    if (tab === "4") {
      await this.getCloseddAlerts();
    }
    if (tab === "5") {
      await this.getDispatchTickets();
    }
    if (tab === "6") {
      await this.getPendingCloseTickets();
    }
  };
  handleToggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  checkAlerts = async (event) => {
    let alerts = this.state.newAlerts;
    var collection = [];
    const sites = [];
    alerts.forEach((obj) => {
      if (obj.alertEventId === parseInt(event.target.value))
        obj["isChecked"] = event.target.checked;
    });
    for (const list of alerts) {
      if (list.isChecked === true) {
        collection.push(list.alertEventId);
        sites.push(list.siteName);
      }
    }
    await this.setStateAsync({
      groupedAlerts: collection,
      sitesList: sites,
      sitesVerify: true,
    });

    if (this.state.groupedAlerts.length > 0) {
      await this.setStateAsync({ disabledTickets: true });
    } else {
      await this.setStateAsync({ disabledTickets: false });
    }
  };

  handleSearchChange = async (e) => {
    e.preventDefault();
    let searchKeyword = e.target.value;
    await this.setStateAsync({ searchKeyword: searchKeyword, siteName: "" });
    let searchedRecords = await searchAlerts(
      this.state.newAlerts,
      searchKeyword
    );
    let sortedRecords = await PageUtils.sortListRecords(
      searchedRecords,
      this.state.sortedColumn,
      this.state.sortedOrder
    );
    await this.setStateAsync({ newAlertsDisplayRecords: sortedRecords });

    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.newAlertsDisplayRecords,
      pageOffset,
      this.state.pageLimit
    );

    await this.setStateAsync({ newAlertsPageRecords: slicedRecords });
    await this.setStateAsync({ pageOffset: pageOffset });
    // await this.setStateAsync({ newAlertsPageRecords: sortedRecords });
    // await this.unCheckAlerts();
  };

  dateFilter = async () => {
    var result = null;
    if (this.state.searchKeyword === "" && this.state.siteName === null) {
      result = this.state.newAlerts;
      await this.setStateAsync({ alerts: result });
    } else {
      let searchKeyword = "";
      if (this.state.searchKeyword) {
        searchKeyword = this.state.searchKeyword;
      }
      if (this.state.siteName) {
        searchKeyword = this.state.siteName;
      }
      let sortedRecords = await searchAlerts(
        this.state.newAlerts,
        searchKeyword
      );
      await this.setStateAsync({ alerts: sortedRecords });
    }
    await this.unCheckAlerts();
  };

  getSitesList = async (e) => {
    if (
      this.state.partPrivileges[this._pcPortalSiteView] === true &&
      this.state.sitesLoader === false
    ) {
      await this.getSites();
      await this.setStateAsync({ sitesLoader: true });
    }
  };

  handleSiteChange = async (e) => {
    if (e.target.value) {
      await this.setStateAsync({ siteName: e.target.value, searchKeyword: "" });
      let searchedRecords = await searchAlertSiteNames(
        this.state.newAlerts,
        this.state.siteName
      );
      let sortType = "";
      let sortedRecords = await PageUtils.sortListRecords(
        searchedRecords,
        this.state.sortedColumn,
        this.state.sortedOrder,
        sortType
      );
      await this.setStateAsync({ newAlertsDisplayRecords: sortedRecords });

      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.newAlertsDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );
      await this.setStateAsync({ pageOffset: pageOffset });

      await this.setStateAsync({ alerts: slicedRecords });
      await this.setStateAsync({ newAlertsPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    } else {
      await this.setStateAsync({ siteName: e.target.value, searchKeyword: "" });
      let sortedRecords = await PageUtils.sortListRecords(
        this.state.newAlerts,
        this.state.sortedColumn,
        this.state.sortedOrder
      );
      await this.setStateAsync({ newAlertsDisplayRecords: sortedRecords });
      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.newAlertsDisplayRecords,
        this.state.pageOffset,
        this.state.pageLimit
      );
      await this.setStateAsync({ newAlertsPageRecords: slicedRecords });

      await this.setStateAsync({ searchKeyword: "", searchValue: "" });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  checkAll = async (event) => {
    let alerts = this.state.alerts;
    let collection = [];
    let sites = [];
    // alerts.forEach((obj) => (obj.alertEventStatus === "C" ? obj["isChecked"] = false : obj["isChecked"] = event.target.checked));
    alerts.forEach((obj) => (obj["isChecked"] = event.target.checked));
    for (const list of alerts) {
      if (list.isChecked === true) {
        collection.push(list.alertEventId);
        sites.push(list.siteName);
      }
    }
    await this.setStateAsync({
      groupedAlerts: collection,
      isChecked: !this.state.isChecked,
      sitesList: sites,
      sitesVerify: true,
    });

    if (this.state.groupedAlerts.length > 0) {
      await this.setStateAsync({ disabledTickets: true });
    } else {
      await this.setStateAsync({ disabledTickets: false });
    }
  };
  unCheckAlerts = async () => {
    let alerts = this.state.alerts;
    alerts.forEach((obj) => {
      obj["isChecked"] = false;
    });
    await this.setStateAsync({
      alerts: alerts,
      groupedAlerts: null,
      isChecked: false,
    });
  };
  closeTicketConfirmModal = async () => {
    await this.setStateAsync({
      isTicketCreateConfirmModal: false,
    });
  };

  openTicketConfirmModal = async () => {
    await this.setStateAsync({
      isTicketCreateConfirmModal: true,
    });
  };

  closeTicketReConfirmModel = async () => {
    await this.setStateAsync({
      isTicketCreateReConfirmModal: false,
    });
  };

  openTicketReConfirmModel = async () => {
    await this.setStateAsync({
      isTicketCreateReConfirmModal: true,
    });
  };

  closeIgnoreConfirmModal = async () => {
    await this.setStateAsync({
      isIgnoreConfirmModal: false,
    });
  };

  openIgnoreConfirmModal = async () => {
    await this.setStateAsync({
      isIgnoreConfirmModal: true,
    });
  };
  handleTicketConfirmation = async () => {
    const ticketObj = {
      alertEventId: this.state.groupedAlerts,
    };
    this.setStateAsync({ ticketObj: ticketObj });
    if (this.state.groupedAlerts.length === this.state.alerts.length) {
      await this.setStateAsync({ selectAll: true });
    } else {
      await this.setStateAsync({ selectAll: false });
    }
    this.openTicketConfirmModal();
    const list = this.state.sitesList.every((val, i, arr) => val === arr[0]);
    await this.setStateAsync({ sitesVerify: list });
  };

  handleIgnoreConfirmation = async () => {
    const ignoredObj = {
      ignoreAlerts: this.state.groupedAlerts,
    };
    this.setStateAsync({ ignoredObj: ignoredObj });
    this.openIgnoreConfirmModal();
  };

  ticketDataFetch = async (non_duplidated_data) => {
    await this.setStateAsync({
      tickets: non_duplidated_data,
    });
  };
  DispatchTicketDataFetch = async (non_duplidated_data) => {
    await this.setStateAsync({
      dispatchTickets: non_duplidated_data,
    });
  };

  clearticketsFilters = async () => {
    delete this.state.universalSearch["startDate"];
    delete this.state.universalSearch["endDate"];
    delete this.state.universalSearch["priority"];
    delete this.state.universalSearch["clientNotification"];
    delete this.state.universalSearch["wip"];
    delete this.state.universalSearch["alertEventStatus"];
    delete this.state.universalSearch["resolutionCategory"];
    delete this.state.universalSearch["ticketNumber"];
  };

  checkticketsFilters = async (tab) => {
    await this.clearticketsFilters();
    let filterValue;
    if (tab === "1") {
      filterValue = this.state.filterByTabs.newAlertsTab;
    }
    if (tab === "2") {
      filterValue = this.state.filterByTabs.WIPTicketsTab;
    }
    if (tab === "3") {
      filterValue = this.state.filterByTabs.ignoredAlertsTab;
    }
    if (tab === "4") {
      filterValue = this.state.filterByTabs.closedTicketsTab;
    }
    if (tab === "5") {
      filterValue = this.state.filterByTabs.dispatchTicketsTab;
    }
    if (tab === "6") {
      filterValue = this.state.filterByTabs.pendingCloseTicketsTab;
    }
    Object.assign(this.state.universalSearch, filterValue);
  };

  clearWorkInProgressTicketsFilters = async () => {
    let wipPriority = this.state.wipPriority;
    let wipClientApproval = this.state.wipClientApproval;
    let workInProgress = this.state.workInProgress;
    let alertEventStatus = this.state.alertEventStatus;
    let wipTicketsIDFilter = this.state.wipTicketsIDFilter;

    wipPriority.forEach((obj) => {
      obj["isChecked"] = false;
    });
    wipClientApproval.forEach((obj) => {
      obj["isChecked"] = false;
    });
    workInProgress.forEach((obj) => {
      obj["isChecked"] = false;
    });
    alertEventStatus.forEach((obj) => {
      obj["isChecked"] = false;
    });
    wipTicketsIDFilter.forEach((obj) => {
      obj["isChecked"] = false;
      obj["content"] = "";
      obj["value"] = "";
    });

    await this.clearticketsFilters();
    Object.keys(this.state.filterByTabs.WIPTicketsTab).forEach(
      (key) => delete this.state.filterByTabs.WIPTicketsTab[key]
    );

    await this.setStateAsync({
      wipPriority: wipPriority,
      wipClientApproval: wipClientApproval,
      workInProgress: workInProgress,
      alertEventStatus: alertEventStatus,
      wipTicketsIDFilter: wipTicketsIDFilter,
    });
  };

  clearDispatchTicketsFilters = async () => {
    let dispatchTicketsPriority = this.state.dispatchTicketsPriority;
    let dispatchTicket = this.state.dispatchTicketsApproval;
    let dispatchWIP = this.state.dispatchWorkInProgress;
    let dispatchAEStatus = this.state.dispatchAlertEventStatus;
    let dispatchTicketsIDFilter = this.state.dispatchTicketsIDFilter;

    dispatchTicketsPriority.forEach((obj) => {
      obj["isChecked"] = false;
    });
    dispatchTicket.forEach((obj) => {
      obj["isChecked"] = false;
    });
    dispatchWIP.forEach((obj) => {
      obj["isChecked"] = false;
    });
    dispatchAEStatus.forEach((obj) => {
      obj["isChecked"] = false;
    });
    dispatchTicketsIDFilter.forEach((obj) => {
      obj["isChecked"] = false;
      obj["content"] = "";
      obj["value"] = "";
    });

    await this.clearticketsFilters();
    Object.keys(this.state.filterByTabs.dispatchTicketsTab).forEach(
      (key) => delete this.state.filterByTabs.dispatchTicketsTab[key]
    );

    await this.setStateAsync({
      dispatchTicketsPriority: dispatchTicketsPriority,
      dispatchTicketsApproval: dispatchTicket,
      dispatchWorkInProgress: dispatchWIP,
      dispatchAlertEventStatus: dispatchAEStatus,
      dispatchTicketsIDFilter: dispatchTicketsIDFilter,
    });
  };

  clearPendingCloseTicketsFilters = async () => {
    let pendingCloseTicketsPriority = this.state.pendingCloseTicketsPriority;
    let pendingCloseTicketsApproval = this.state.pendingCloseTicketsApproval;
    let pendingCloseAlertEventStatus = this.state.pendingCloseAlertEventStatus;
    let pendingCloseResolutionCategory =
      this.state.pendingCloseResolutionCategory;
    let pendingCloseTicketsIDFilter = this.state.pendingCloseTicketsIDFilter;

    pendingCloseTicketsPriority.forEach((obj) => {
      obj["isChecked"] = false;
    });
    pendingCloseTicketsApproval.forEach((obj) => {
      obj["isChecked"] = false;
    });
    pendingCloseAlertEventStatus.forEach((obj) => {
      obj["isChecked"] = false;
    });
    pendingCloseResolutionCategory.forEach((obj) => {
      obj["isChecked"] = false;
    });
    pendingCloseTicketsIDFilter.forEach((obj) => {
      obj["isChecked"] = false;
      obj["content"] = "";
      obj["value"] = "";
    });

    await this.clearticketsFilters();
    Object.keys(this.state.filterByTabs.pendingCloseTicketsTab).forEach(
      (key) => delete this.state.filterByTabs.pendingCloseTicketsTab[key]
    );

    await this.setStateAsync({
      pendingCloseTicketsPriority: pendingCloseTicketsPriority,
      pendingCloseTicketsApproval: pendingCloseTicketsApproval,
      pendingCloseAlertEventStatus: pendingCloseAlertEventStatus,
      pendingCloseResolutionCategory: pendingCloseResolutionCategory,
      pendingCloseTicketsIDFilter: pendingCloseTicketsIDFilter,
    });
  };

  clearClosedTicketsFilters = async () => {
    let closedTicketsPriority = this.state.closedTicketsPriority;
    let closedAlertEventStatus = this.state.closedAlertEventStatus;
    let closedResolutionCategory = this.state.closedResolutionCategory;
    let closedTicketsIDFilter = this.state.closedTicketsIDFilter;

    closedTicketsPriority.forEach((obj) => {
      obj["isChecked"] = false;
    });
    closedAlertEventStatus.forEach((obj) => {
      obj["isChecked"] = false;
    });
    closedResolutionCategory.forEach((obj) => {
      obj["isChecked"] = false;
    });
    closedTicketsIDFilter.forEach((obj) => {
      obj["isChecked"] = false;
      obj["content"] = "";
      obj["value"] = "";
    });

    await this.clearticketsFilters();
    Object.keys(this.state.filterByTabs.closedTicketsTab).forEach(
      (key) => delete this.state.filterByTabs.closedTicketsTab[key]
    );

    await this.setStateAsync({
      closedTicketsPriority: closedTicketsPriority,
      closedAlertEventStatus: closedAlertEventStatus,
      closedResolutionCategory: closedResolutionCategory,
      closedTicketsIDFilter: closedTicketsIDFilter,
    });
  };

  checkPriority = async (wipPriority) => {
    await this.setStateAsync({ wipPriority });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.wipPriority.every((v) => v.isChecked === false);
      if (allTrue === true) {
        const filterValue = {
          priority: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["WIPTicketsTab"], filterValue);
      } else {
        const filteredData = this.state.wipPriority.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value.charAt(0));
        const result = firstLetters.join(",");

        const filterValue = {
          priority: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["WIPTicketsTab"], filterValue);
      }
    }
  };

  checkApproval = async (wipClientApproval) => {
    await this.setStateAsync({ wipClientApproval });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.wipClientApproval.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          clientNotification: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["WIPTicketsTab"], filterValue);
      } else {
        const filteredData = this.state.wipClientApproval.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value);
        const result = firstLetters.join(",");

        const filterValue = {
          clientNotification: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["WIPTicketsTab"], filterValue);
      }
    }
  };

  checkWIP = async (workInProgress) => {
    await this.setStateAsync({ workInProgress });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.workInProgress.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          wip: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["WIPTicketsTab"], filterValue);
      } else {
        const filteredData = this.state.workInProgress.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value);
        const result = firstLetters.join(",");

        const filterValue = {
          wip: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["WIPTicketsTab"], filterValue);
      }
    }
  };

  checkAlertsStatus = async (alertEventStatus) => {
    await this.setStateAsync({ alertEventStatus });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.alertEventStatus.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          alertEventStatus: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["WIPTicketsTab"], filterValue);
      } else {
        const filteredData = this.state.alertEventStatus.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value);
        const result = firstLetters.join(",");

        const filterValue = {
          alertEventStatus: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["WIPTicketsTab"], filterValue);
      }
    }
  };

  checkWIPTicketIDs = async (wipTicketsIDFilter) => {
    await this.setStateAsync({ wipTicketsIDFilter });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.wipTicketsIDFilter.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          ticketNumber: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["WIPTicketsTab"], filterValue);
      } else {
        const filteredData = this.state.wipTicketsIDFilter.filter(
          (item) => item.isChecked
        );
        // const firstLetters = filteredData.map((item) => item.value);
        // const result = firstLetters.join(",");

        const filterValue = {
          ticketNumber: filteredData[0]?.value,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["WIPTicketsTab"], filterValue);
      }
    }
  };

  DispatchTicketcheckPriority = async (dispatchTicketsPriority) => {
    await this.setStateAsync({ dispatchTicketsPriority });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.dispatchTicketsPriority.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          priority: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["dispatchTicketsTab"],
          filterValue
        );
      } else {
        const filteredData = this.state.dispatchTicketsPriority.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value.charAt(0));
        const result = firstLetters.join(",");

        const filterValue = {
          priority: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["dispatchTicketsTab"],
          filterValue
        );
      }
    }
  };

  DispatchTicketcheckApproval = async (dispatchTicketsApproval) => {
    await this.setStateAsync({ dispatchTicketsApproval });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.dispatchTicketsApproval.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          clientNotification: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["dispatchTicketsTab"],
          filterValue
        );
      } else {
        const filteredData = this.state.dispatchTicketsApproval.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value);
        const result = firstLetters.join(",");

        const filterValue = {
          clientNotification: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["dispatchTicketsTab"],
          filterValue
        );
      }
    }
  };

  checkDispatchWIP = async (dispatchWorkInProgress) => {
    await this.setStateAsync({ dispatchWorkInProgress });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.dispatchWorkInProgress.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          wip: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["dispatchTicketsTab"],
          filterValue
        );
      } else {
        const filteredData = this.state.dispatchWorkInProgress.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value);
        const result = firstLetters.join(",");

        const filterValue = {
          wip: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["dispatchTicketsTab"],
          filterValue
        );
      }
    }
  };

  checkDispatchAlertsStatus = async (dispatchAlertEventStatus) => {
    await this.setStateAsync({ dispatchAlertEventStatus });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.dispatchAlertEventStatus.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          alertEventStatus: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["dispatchTicketsTab"],
          filterValue
        );
      } else {
        const filteredData = this.state.dispatchAlertEventStatus.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value);
        const result = firstLetters.join(",");

        const filterValue = {
          alertEventStatus: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["dispatchTicketsTab"],
          filterValue
        );
      }
    }
  };

  checkDispatchTicketIDs = async (dispatchTicketsIDFilter) => {
    await this.setStateAsync({ dispatchTicketsIDFilter });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.dispatchTicketsIDFilter.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          ticketNumber: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["dispatchTicketsTab"],
          filterValue
        );
      } else {
        const filteredData = this.state.dispatchTicketsIDFilter.filter(
          (item) => item.isChecked
        );
        // const firstLetters = filteredData.map((item) => item.value);
        // const result = firstLetters.join(",");

        const filterValue = {
          ticketNumber: filteredData[0]?.value,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["dispatchTicketsTab"],
          filterValue
        );
      }
    }
  };

  getClosedTickets = async (data) => {
    await this.setStateAsync({ closedTickets: data });
  };

  closedTicketcheckPriority = async (closedTicketsPriority) => {
    await this.setStateAsync({ closedTicketsPriority });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.closedTicketsPriority.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          priority: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["closedTicketsTab"], filterValue);
      } else {
        const filteredData = this.state.closedTicketsPriority.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value.charAt(0));
        const result = firstLetters.join(",");

        const filterValue = {
          priority: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["closedTicketsTab"], filterValue);
      }
    }
  };

  checkclosedAlertsStatus = async (closedAlertEventStatus) => {
    await this.setStateAsync({ closedAlertEventStatus });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.closedAlertEventStatus.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          alertEventStatus: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["closedTicketsTab"], filterValue);
      } else {
        const filteredData = this.state.closedAlertEventStatus.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value);
        const result = firstLetters.join(",");

        const filterValue = {
          alertEventStatus: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["closedTicketsTab"], filterValue);
      }
    }
  };

  checkClosedResolutionCategory = async (closedResolutionCategory) => {
    await this.setStateAsync({ closedResolutionCategory });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.closedResolutionCategory.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          resolutionCategory: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["closedTicketsTab"], filterValue);
      } else {
        const filteredData = this.state.closedResolutionCategory.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value);
        const result = firstLetters.join(",");

        const filterValue = {
          resolutionCategory: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["closedTicketsTab"], filterValue);
      }
    }
  };

  checkClosedTicketIDs = async (closedTicketsIDFilter) => {
    await this.setStateAsync({ closedTicketsIDFilter });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.closedTicketsIDFilter.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          ticketNumber: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["closedTicketsTab"], filterValue);
      } else {
        const filteredData = this.state.closedTicketsIDFilter.filter(
          (item) => item.isChecked
        );
        // const firstLetters = filteredData.map((item) => item.value);
        // const result = firstLetters.join(",");

        const filterValue = {
          ticketNumber: filteredData[0]?.value,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(this.state.filterByTabs["closedTicketsTab"], filterValue);
      }
    }
  };

  initPartPrivileges = () => {
    return {
      [this._pcPortalSiteView]: false,
      [this._pcPortalViewAlerts]: false,
      [this._pcPortalCreateIgnoreAlerts]: false,
      [this._pcPortalAlertsCreateTicket]: false,
      [this._pcPortalViewTickets]: false,
    };
  };

  checkPartPrivilege = async () => {
    const partCodes = [
      this._pcPortalSiteView,
      this._pcPortalViewAlerts,
      this._pcPortalCreateIgnoreAlerts,
      this._pcPortalAlertsCreateTicket,
      this._pcPortalViewTickets,
    ];
    const partPrivilege = await getPagePartsPrivilege(
      partCodes,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AlertURLProps.pageNotFound);
    }
  };

  setPartPrivileges = async () => {
    const partCodes = [
      this._pcPortalSiteView,
      this._pcPortalViewAlerts,
      this._pcPortalCreateIgnoreAlerts,
      this._pcPortalAlertsCreateTicket,
      this._pcPortalViewTickets,
    ];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  handleChange = async (checked) => {
    await this.setStateAsync({ searchKeyword: "", siteName: "" });

    if (checked) {
      await this.setStateAsync({ showAllAlerts: "1" });
    } else {
      await this.setStateAsync({ showAllAlerts: "0" });
    }
    await this.setStateAsync({ switchChecked: checked });
    await this.unCheckAlerts();
    await this.getAlert();
  };

  getDataByFilters = async () => {
    let tab = this.state.activeTab;
    if (tab === "1") {
      await this.getAlert();
    }
    if (tab === "2") {
      await this.getTickets();
    }
    if (tab === "3") {
      await this.getIgnoredAlerts();
    }
    if (tab === "4") {
      await this.getCloseddAlerts();
    }
    if (tab === "5") {
      await this.getDispatchTickets();
    }
    if (tab === "6") {
      await this.getPendingCloseTickets();
    }
  };

  handleCompaniesFilter = async (e) => {
    if (e === null) {
      this.setState({
        sitesOptions: this.state.sitesAllOptions,
        siteNameValue: "",
        companyNameValue: "",
      });
      const filterValue = {
        companyName: "",
        gvrId: "",
      };
      Object.assign(this.state.universalSearch, filterValue);
    } else {
      this.setState({ siteNameValue: "" });
      let organizationName = e.organizationName;
      let filterObj = [];
      await this.state.sitesAllOptions.forEach((element) => {
        element.companyDetails.forEach((obj) => {
          if (organizationName === obj.company_name) {
            filterObj.push(element);
          }
        });
      });
      const filterValue = {
        companyName: organizationName,
        gvrId: "",
      };
      this.setState({
        sitesOptions: filterObj,
        companyNameValue: e.companyName,
        siteNameValue: "",
      });
      Object.assign(this.state.universalSearch, filterValue);
    }
    await this.getDataByFilters();
  };

  handleSitesFilter = async (event) => {
    if (event === null) {
      const filterValue = {
        gvrId: "",
      };
      await this.setStateAsync({ siteNameValue: filterValue.gvrId });
      Object.assign(this.state.universalSearch, filterValue);
    } else {
      const filterValue = {
        gvrId: event.siteNumber,
      };
      await this.setStateAsync({ siteNameValue: event.gvrId });
      Object.assign(this.state.universalSearch, filterValue);
    }
    await this.getDataByFilters();
  };

  handleComponentsFilter = async (event) => {
    if (event === null) {
      const filterValue = {
        componentName: "",
      };
      await this.setStateAsync({
        showComponentValue: filterValue.componentName,
      });
      Object.assign(this.state.universalSearch, filterValue);
    } else {
      const filterValue = {
        componentName: event.value,
      };
      await this.setStateAsync({ showComponentValue: event.componentName });
      Object.assign(this.state.universalSearch, filterValue);
    }
    await this.getDataByFilters();
  };

  handleWarrantyFilter = async (event) => {
    if (event === null) {
      const filterValue = {
        warranty: "",
      };
      await this.setStateAsync({ warrantyStatusValue: filterValue.warranty });
      Object.assign(this.state.universalSearch, filterValue);
    } else {
      const filterValue = {
        warranty: event.value,
      };
      await this.setStateAsync({ warrantyStatusValue: event.warranty });
      Object.assign(this.state.universalSearch, filterValue);
    }
    await this.getDataByFilters();
  };

  clearAllFilters = async () => {
    await this.setStateAsync({
      companyNameValue: "",
      siteNameValue: "",
      showComponentValue: "",
      warrantyStatusValue: "",
    });

    delete this.state.universalSearch["startDate"];
    delete this.state.universalSearch["endDate"];
    delete this.state.universalSearch["companyName"];
    delete this.state.universalSearch["gvrId"];
    delete this.state.universalSearch["componentName"];
    delete this.state.universalSearch["warranty"];

    delete this.state.filterByTabs.WIPTicketsTab["startDate"];
    delete this.state.filterByTabs.WIPTicketsTab["endDate"];
    delete this.state.filterByTabs.dispatchTicketsTab["startDate"];
    delete this.state.filterByTabs.dispatchTicketsTab["endDate"];
    delete this.state.filterByTabs.pendingCloseTicketsTab["startDate"];
    delete this.state.filterByTabs.pendingCloseTicketsTab["endDate"];

    let filterValue = {
      startDate: this.state.alertsDate?.startDate,
      endDate: this.state.alertsDate?.endDate,
    };
    Object.assign(this.state.filterByTabs.newAlertsTab, filterValue);
    Object.assign(this.state.filterByTabs.ignoredAlertsTab, filterValue);

    await this.handleCompaniesFilter(null);
    await this.getDataByFilters();
  };

  getPendingCloseTickets = async () => {
    await this.setStateAsync({
      isPendingCloseTicketsDataFetched: false,
    });
    const queryData = "ticketStatus=P";
    let querySearch;
    if (this.state.activeTab === "6") {
      querySearch = this.state.universalSearch;
    } else {
      querySearch = ``;
    }
    await getTicket(queryData, querySearch, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let pendingCloseTickets = res.data.records;
          for (const data of pendingCloseTickets) {
            data["alertHistory"] = [];
            data["isOpen"] = false;
          }
          await this.setStateAsync({
            pendingCloseTickets: pendingCloseTickets,
            pendingCloseTicketsList: pendingCloseTickets,
            filter: res.data.filter,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPendingCloseTicketsDataFetched: true });
  };

  pendingClose = async () => {
    const queryData = "ticketStatus=P";
    let querySearch;
    if (this.state.activeTab === "6") {
      querySearch = this.state.universalSearch;
    } else {
      querySearch = ``;
    }
    await getTicket(queryData, querySearch, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let pendingCloseTickets = res.data.records;
          for (const data of pendingCloseTickets) {
            data["alertHistory"] = [];
            data["isOpen"] = false;
          }
          await this.setStateAsync({
            pendingCloseTickets: pendingCloseTickets,
            pendingCloseTicketsList: pendingCloseTickets,
            filter: res.data.filter,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  pendingCloseTicketsDataFetch = async (non_duplidated_data) => {
    await this.setStateAsync({
      pendingCloseTickets: non_duplidated_data,
    });
  };

  pendingCloseTicketcheckPriority = async (pendingCloseTicketsPriority) => {
    await this.setStateAsync({ pendingCloseTicketsPriority });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.pendingCloseTicketsPriority.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          priority: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["pendingCloseTicketsTab"],
          filterValue
        );
      } else {
        const filteredData = this.state.pendingCloseTicketsPriority.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value.charAt(0));
        const result = firstLetters.join(",");

        const filterValue = {
          priority: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["pendingCloseTicketsTab"],
          filterValue
        );
      }
    }
  };

  pendingCloseTicketcheckApproval = async (pendingCloseTicketsApproval) => {
    await this.setStateAsync({ pendingCloseTicketsApproval });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.pendingCloseTicketsApproval.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          clientNotification: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["pendingCloseTicketsTab"],
          filterValue
        );
      } else {
        const filteredData = this.state.pendingCloseTicketsApproval.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value);
        const result = firstLetters.join(",");

        const filterValue = {
          clientNotification: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["pendingCloseTicketsTab"],
          filterValue
        );
      }
    }
  };

  pendingCloseAlertsStatus = async (pendingCloseAlertEventStatus) => {
    await this.setStateAsync({ pendingCloseAlertEventStatus });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.pendingCloseAlertEventStatus.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          alertEventStatus: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["pendingCloseTicketsTab"],
          filterValue
        );
      } else {
        const filteredData = this.state.pendingCloseAlertEventStatus.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value);
        const result = firstLetters.join(",");

        const filterValue = {
          alertEventStatus: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["pendingCloseTicketsTab"],
          filterValue
        );
      }
    }
  };

  pendingClosedResolutionCategory = async (pendingCloseResolutionCategory) => {
    await this.setStateAsync({ pendingCloseResolutionCategory });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.pendingCloseResolutionCategory.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          resolutionCategory: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["pendingCloseTicketsTab"],
          filterValue
        );
      } else {
        const filteredData = this.state.pendingCloseResolutionCategory.filter(
          (item) => item.isChecked
        );
        const firstLetters = filteredData.map((item) => item.value);
        const result = firstLetters.join(",");

        const filterValue = {
          resolutionCategory: result,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["pendingCloseTicketsTab"],
          filterValue
        );
      }
    }
  };

  checkPendingCloseTicketIDs = async (pendingCloseTicketsIDFilter) => {
    await this.setStateAsync({ pendingCloseTicketsIDFilter });
    if (this.state.isPageDataFetched === true) {
      let allTrue = this.state.pendingCloseTicketsIDFilter.every(
        (v) => v.isChecked === false
      );
      if (allTrue === true) {
        const filterValue = {
          ticketNumber: "",
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["pendingCloseTicketsTab"],
          filterValue
        );
      } else {
        const filteredData = this.state.pendingCloseTicketsIDFilter.filter(
          (item) => item.isChecked
        );
        // const firstLetters = filteredData.map((item) => item.value);
        // const result = firstLetters.join(",");

        const filterValue = {
          ticketNumber: filteredData[0]?.value,
        };
        Object.assign(this.state.universalSearch, filterValue);
        Object.assign(
          this.state.filterByTabs["pendingCloseTicketsTab"],
          filterValue
        );
      }
    }
  };

  handleAlertConfirmationChange = (event) => {
    event.persist();
    this.setStateAsync({ selectedAlertConfirmation: event.target.value });
  };

  createTicketReConfirmation = async () => {
    const ticketObj = {
      alertEventId: this.state.reConfirmTicketId,
      ticketStatus: this.state.selectedAlertConfirmation,
    };
    await this.setStateAsync({ ticketObj: ticketObj });
    await this.createTicket();
  };

  render() {
    return (
      <div>
        <Row>
          <div className="breadcrumb sram-bread-crumb sram-page-content">
            <Col sm={12} md={12} lg={12} className="font-mild pt-1 pl-2">
              {AlertMsgResProps.head.title.alertManagement}
            </Col>
          </div>
        </Row>
        <br />
        <br />
        <br />
        <Row style={{ padding: "15px" }}>
          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
            <Select
              placeholder=" Companies"
              options={this.state.companies}
              getOptionLabel={(option) => option?.organizationName}
              getOptionValue={(option) => option?.organizationName}
              value={
                this.state.companies && this.state.companies.length === 1
                  ? this.state.companies && this.state.companies[0]
                  : this.state.companyNameValue
              }
              isDisabled={
                (this.state.companies && this.state.companies.length === 1) ||
                this.state.disableFilters
                  ? true
                  : false
              }
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ced4da",
                  primary: "#ced4da",
                },
              })}
              styles={{
                option: (base) => ({
                  ...base,
                  color: "#000000",
                }),
              }}
              isClearable
              onChange={async (e) => {
                await this.handleCompaniesFilter(e);
              }}
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
            <Select
              placeholder=" Sites"
              options={this.state.sitesOptions}
              getOptionLabel={(option) =>
                option?.siteName + " (GVR ID : " + option?.siteNumber + ")"
              }
              getOptionValue={(option) =>
                option?.siteName + " (GVR ID : " + option?.siteNumber + ")"
              }
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ced4da",
                  primary: "#ced4da",
                },
              })}
              styles={{
                option: (base) => ({
                  ...base,
                  color: "#000000",
                }),
              }}
              isDisabled={this.state.disableFilters ? true : false}
              isClearable
              value={this.state.siteNameValue}
              onChange={this.handleSitesFilter}
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
            <Select
              placeholder=" Components"
              options={this.state.showComponentOptions}
              getOptionLabel={(option) => option.content}
              getOptionValue={(option) => option.content}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ced4da",
                  primary: "#ced4da",
                },
              })}
              styles={{
                option: (base) => ({
                  ...base,
                  color: "#000000",
                }),
              }}
              isDisabled={this.state.disableFilters ? true : false}
              isClearable
              value={this.state.showComponentValue}
              onChange={(e) => this.handleComponentsFilter(e)}
            />
          </Col>
          <Col xs={12} sm={12} md={2} lg={2} xl={2}>
            <Select
              placeholder=" Warranty"
              options={this.state.warrantyStatusOptions}
              getOptionLabel={(option) => option.content}
              getOptionValue={(option) => option.content}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ced4da",
                  primary: "#ced4da",
                },
              })}
              styles={{
                option: (base) => ({
                  ...base,
                  color: "#000000",
                }),
              }}
              isDisabled={this.state.disableFilters ? true : false}
              isClearable
              value={this.state.warrantyStatusValue}
              onChange={(e) => this.handleWarrantyFilter(e)}
            />
          </Col>
          <Col xs={12} sm={12} md={3} lg={3} xl={3}>
            <WithLoading
              type="page"
              className="pt-2"
              size="sm"
              isPageDataFetched={this.state.isAlertsDataFetched}
            >
              {this.state.filter ? (
                <>
                  {this.state.filter?.startDate &&
                  this.state.filter?.endDate ? (
                    <>
                      <Label className="pt-2 mr-2">
                        From: {AppUtils.getDate(this.state.filter.startDate)}
                      </Label>
                      <Label className="pt-2">
                        To: {AppUtils.getDate(this.state.filter.endDate)}
                      </Label>
                    </>
                  ) : (
                    <Label className="pt-2">
                      Select dates to filter Tickets
                    </Label>
                  )}
                  <span
                    className="ml-2"
                    style={{
                      cursor: this.state.disableFilters ? "" : "pointer",
                    }}
                  >
                    <i
                      onClick={
                        this.state.disableFilters
                          ? ""
                          : this.openDatePickerModal
                      }
                      style={{
                        color: this.state.disableFilters
                          ? "#958989"
                          : "#000000",
                      }}
                      className="fa fa-edit pt-2"
                    ></i>
                  </span>
                </>
              ) : null}
            </WithLoading>
          </Col>
          {this.state.companyNameValue !== "" ||
          this.state.siteNameValue !== "" ||
          this.state.showComponentValue !== "" ||
          this.state.warrantyStatusValue !== "" ||
          this.state.filterByTabs.newAlertsTab["startDate"] !==
            this.state.alertsDate?.startDate ||
          this.state.filterByTabs.newAlertsTab["endDate"] !==
            this.state.alertsDate?.endDate ||
          this.state.filterByTabs.ignoredAlertsTab["startDate"] !==
            this.state.alertsDate?.startDate ||
          this.state.filterByTabs.ignoredAlertsTab["endDate"] !==
            this.state.alertsDate?.endDate ||
          (this.state.filterByTabs.WIPTicketsTab["startDate"] &&
            this.state.filterByTabs.WIPTicketsTab["endDate"]) ||
          (this.state.filterByTabs.dispatchTicketsTab["startDate"] &&
            this.state.filterByTabs.dispatchTicketsTab["endDate"]) ||
          (this.state.filterByTabs.pendingCloseTicketsTab["startDate"] &&
            this.state.filterByTabs.pendingCloseTicketsTab["endDate"]) ||
          this.state.filterByTabs.closedTicketsTab["startDate"] !==
            moment().subtract(7, "d").format("YYYY-MM-DD") ||
          this.state.filterByTabs.closedTicketsTab["endDate"] !==
            moment(new Date()).format("YYYY-MM-DD") ? (
            <Col
              xs={12}
              sm={12}
              md={1}
              lg={1}
              xl={1}
              style={{ display: "flex", alignItems: "center" }}
            >
              <span className="float-right sram-alert-clear">
                {/* <i class="fa fa-trash" aria-hidden="true"></i> */}
                <u onClick={() => this.clearAllFilters()}>Clear All</u>
              </span>
            </Col>
          ) : null}
          <Col sm={12} md={12} lg={12} className="pt-2">
            <WithLoading
              type="page"
              className="pt-2 mr-2 "
              isPageDataFetched={this.state.isPrivilagesFetched}
            >
              <Nav tabs>
                <NavItem className="sram-nav sram-title-cursor">
                  {this.state.partPrivileges[this._pcPortalViewAlerts] ===
                  true ? (
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={this.toggle.bind(this, "1")}
                    >
                      <span>{AlertMsgResProps.head.title.newAlerts}</span>
                    </NavLink>
                  ) : null}
                </NavItem>
                <NavItem className="sram-nav sram-title-cursor">
                  {this.state.partPrivileges[this._pcPortalViewTickets] ===
                  true ? (
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={this.toggle.bind(this, "2")}
                    >
                      <span>{AlertMsgResProps.head.title.wipTickets}</span>
                    </NavLink>
                  ) : null}
                </NavItem>

                <NavItem className="sram-nav sram-title-cursor">
                  {this.state.partPrivileges[this._pcPortalViewTickets] ===
                  true ? (
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "5",
                      })}
                      onClick={this.toggle.bind(this, "5")}
                    >
                      <span>{AlertMsgResProps.head.title.dispatchTickets}</span>
                    </NavLink>
                  ) : null}
                </NavItem>
                <NavItem className="sram-nav sram-title-cursor">
                  {this.state.partPrivileges[this._pcPortalViewTickets] ===
                  true ? (
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "6",
                      })}
                      onClick={this.toggle.bind(this, "6")}
                    >
                      <span>
                        {AlertMsgResProps.head.title.pendingClosedTickets}
                      </span>
                    </NavLink>
                  ) : null}
                </NavItem>
                <NavItem className="sram-nav sram-title-cursor">
                  {this.state.partPrivileges[this._pcPortalViewAlerts] ===
                  true ? (
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={this.toggle.bind(this, "3")}
                    >
                      <span>{AlertMsgResProps.head.title.ignoredalerts}</span>
                    </NavLink>
                  ) : null}
                </NavItem>
                <NavItem className="sram-nav sram-title-cursor">
                  {this.state.partPrivileges[this._pcPortalViewTickets] ===
                  true ? (
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={this.toggle.bind(this, "4")}
                    >
                      <span>{AlertMsgResProps.head.title.closedTickets}</span>
                    </NavLink>
                  ) : null}
                </NavItem>
              </Nav>

              <TabContent
                activeTab={this.state.activeTab}
                style={{ padding: "10px" }}
              >
                <TabPane tabId="1">
                  {this.state.partPrivileges[this._pcPortalViewAlerts] ===
                  true ? (
                    <WithLoading
                      type="page"
                      className="pt-2 mr-2 "
                      isPageDataFetched={this.state.isPageDataFetched}
                    >
                      <Row className="pt-3">
                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <Input
                            type="text"
                            value={this.state.searchKeyword}
                            placeholder="Search"
                            className="form-control"
                            onChange={this.handleSearchChange}
                          />
                        </Col>
                        {/* <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                          <select
                            id="exampleSelect"
                            className="form-control"
                            onClick={() => this.getSitesList()}
                            onChange={this.handleSiteChange}
                            value={this.state.siteName}
                          >
                            <option value="">Show All Sites</option>
                            {this.state.sites && this.state.sites.length > 0
                              ? this.state.sites.map((obj, i) => {
                                return (
                                  <option key={i} value={obj}>
                                    {obj}
                                  </option>
                                );
                              })
                              : null}
                          </select>
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                          <WithLoading
                            type="page"
                            className="pt-2"
                            size="sm"
                            isPageDataFetched={this.state.isAlertsDataFetched}
                          >
                            {this.state.filter ? (
                              <>
                                <Label className="pt-2 mr-2">
                                  From:{" "}
                                  {AppUtils.getDate(
                                    this.state.filter.startDate
                                  )}
                                </Label>
                                <Label className="pt-2">
                                  To:{" "}
                                  {AppUtils.getDate(this.state.filter.endDate)}
                                </Label>
                                <span
                                  className="ml-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  <i
                                    onClick={this.openDatePickerModal}
                                    className="fa fa-edit pt-2"
                                  ></i>
                                </span>
                              </>
                            ) : null}
                          </WithLoading>
                          </Col> */}
                        {this.state.newAlertsPageRecords &&
                        this.state.newAlertsPageRecords.length > 0 ? (
                          <>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                              {this.state.partPrivileges[
                                this._pcPortalAlertsCreateTicket
                              ] === true ? (
                                <Button
                                  type="button"
                                  className="sram-btn-blue font-mild"
                                  disabled={
                                    this.state.isSubmitting ||
                                    this.state.disabledTickets === false
                                  }
                                  onClick={this.handleTicketConfirmation.bind(
                                    this
                                  )}
                                >
                                  {AlertMsgResProps.head.title.createTicket}
                                </Button>
                              ) : null}
                              &nbsp; &nbsp;
                              {this.state.partPrivileges[
                                this._pcPortalCreateIgnoreAlerts
                              ] === true ? (
                                <Button
                                  type="button"
                                  className="sram-btn-blue font-mild"
                                  disabled={
                                    this.state.isSubmitting ||
                                    this.state.disabledTickets === false
                                  }
                                  onClick={this.handleIgnoreConfirmation.bind(
                                    this
                                  )}
                                >
                                  {AlertMsgResProps.head.title.ignoreAlert}
                                </Button>
                              ) : null}
                            </Col>
                          </>
                        ) : null}

                        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                          <div
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <span className="pr-2 pt-2">
                              <Label>Show All Alerts:</Label>
                            </span>
                            <span className="mt-2">
                              <Switch
                                onChange={this.handleChange}
                                checked={this.state.switchChecked}
                              />
                            </span>
                          </div>
                        </Col>
                      </Row>

                      <div className="page-content-space">
                        <div className="sram-list-main">
                          <PerfectScrollbar className="sram-table-wrapper">
                            <Table
                              // responsive
                              hover
                              size="sm"
                              className="sram-table-main sram-site-alerts-table-position"
                            >
                              <thead>
                                <tr>
                                  {this.state.newAlertsPageRecords &&
                                  this.state.newAlertsPageRecords.length > 0 ? (
                                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      <input
                                        type="checkbox"
                                        onChange={this.checkAll}
                                        value="checkedall"
                                        checked={this.state.isChecked}
                                      />
                                    </th>
                                  ) : null}
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.siteName}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.siteId}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.siteAddress}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.dispenserName}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.side}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.alertStatus}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.componentName}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.eventStatus}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.eventCause}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.warranty}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.warrantydate}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.eventTimeStamp}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {AlertMsgResProps.head.title.closeTimestamp}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <WithLoading
                                  type="page"
                                  className="pt-2"
                                  isPageDataFetched={
                                    this.state.isAlertsDataFetched
                                  }
                                >
                                  {this.state.newAlertsPageRecords &&
                                  this.state.newAlertsPageRecords.length > 0 ? (
                                    this.state.newAlertsPageRecords &&
                                    this.state.newAlertsPageRecords.map(
                                      (obj, i) => {
                                        const siteAddress =
                                          SiteUtils.getSiteAddress(obj);
                                        const siteAddress1 =
                                          SiteUtils.getSiteAddress1(obj);
                                        return (
                                          <tr key={i}>
                                            <td className="sram-text-font sram-table-td">
                                              <input
                                                type="checkbox"
                                                onChange={this.checkAlerts.bind(
                                                  this
                                                )}
                                                checked={obj.isChecked}
                                                value={obj.alertEventId}
                                                // disabled={obj.alertEventStatus === "C"}
                                              />
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              <RRNavLink
                                                target={"_blank"}
                                                to={
                                                  "/portal/sites/" +
                                                  obj.siteId +
                                                  "/dashboard"
                                                }
                                                title={"Site Dashboard View"}
                                                className="sram-page-action-icon"
                                              >
                                                {obj.siteName}
                                              </RRNavLink>
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {obj.siteNumber
                                                ? obj.siteNumber
                                                : "-"}
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {siteAddress},{siteAddress1}
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              <RRNavLink
                                                target={"_blank"}
                                                to={
                                                  "/portal/sites/" +
                                                  obj.siteId +
                                                  "/dispensers/" +
                                                  obj.dispenserId +
                                                  "/dashboard"
                                                }
                                                title={
                                                  "Dispenser Dashboard View"
                                                }
                                                className="sram-page-action-icon"
                                              >
                                                {obj.dispenserName
                                                  ? obj.dispenserName
                                                  : "-"}
                                              </RRNavLink>
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {obj.sideLabel
                                                ? obj.sideLabel
                                                : "-"}
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {obj.alertEventStatusDescription
                                                ? obj.alertEventStatusDescription
                                                : "-"}
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {obj.componentName
                                                ? obj.componentName
                                                : "-"}
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {obj.eventStatus
                                                ? obj.eventStatus
                                                : "-"}
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {obj.eventCause
                                                ? obj.eventCause ===
                                                    "paper out" ||
                                                  obj.eventCause ===
                                                    "paper jam" ||
                                                  obj.eventCause === "paper low"
                                                  ? "Receipt Paper Error"
                                                  : obj.eventCause
                                                : "-"}
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {obj.warrantyDescription
                                                ? obj.warrantyDescription
                                                : "-"}
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {obj.warrantyExpiryDate
                                                ? AppUtils.getDate(
                                                    obj.warrantyExpiryDate
                                                  )
                                                : "-"}
                                            </td>
                                            <td
                                              className="sram-text-font sram-table-td"
                                              title={
                                                obj.componentName ===
                                                "pumpcontrolnode"
                                                  ? `Dispenser Create Timestamp: ${
                                                      obj.dispenserCreateTimestamp
                                                        ? AppUtils.getDateTime(
                                                            obj.dispenserCreateTimestamp
                                                          )
                                                        : "-"
                                                    }`
                                                  : null
                                              }
                                            >
                                              {obj.eventTimestamp
                                                ? AppUtils.getDateTime(
                                                    obj.eventTimestamp
                                                  )
                                                : "-"}
                                            </td>
                                            <td
                                              className="sram-text-font sram-table-td"
                                              title={
                                                obj.componentName ===
                                                "pumpcontrolnode"
                                                  ? `Dispenser Close Timestamp: ${
                                                      obj.dispenserCloseTimestamp
                                                        ? AppUtils.getDateTime(
                                                            obj.dispenserCloseTimestamp
                                                          )
                                                        : "-"
                                                    }`
                                                  : null
                                              }
                                            >
                                              {obj.closeTimestamp
                                                ? AppUtils.getDateTime(
                                                    obj.closeTimestamp
                                                  )
                                                : "-"}
                                            </td>
                                          </tr>
                                        );
                                      }
                                    )
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="13"
                                        className="pt-2 sram-no-data"
                                      >
                                        {
                                          AlertMsgResProps.body.content
                                            .noIssuesFound
                                        }
                                      </td>
                                    </tr>
                                  )}
                                </WithLoading>
                              </tbody>
                            </Table>
                          </PerfectScrollbar>
                        </div>
                      </div>
                      <Row className="pt-2">
                        <Col>
                          {this.state.newAlertsPageRecords &&
                          this.state.newAlertsPageRecords.length ? (
                            <div className="sram-list-foot">
                              <div className="float-left">
                                <PageListLimitSelect
                                  pageLimit={this.state.pageLimit}
                                  pageOffset={this.state.pageOffset}
                                  totalRecords={
                                    this.state.newAlertsDisplayRecords.length
                                  }
                                  handlePageLimitChange={
                                    this.handlePageLimitChange
                                  }
                                />
                              </div>
                              <div className="float-right">
                                <PageListPagination
                                  pageLimit={this.state.pageLimit}
                                  pageOffset={this.state.pageOffset}
                                  totalRecords={
                                    this.state.newAlertsDisplayRecords.length
                                  }
                                  handlePageOffsetChange={
                                    this.handlePageOffsetChange
                                  }
                                />
                              </div>
                            </div>
                          ) : null}
                        </Col>
                      </Row>
                    </WithLoading>
                  ) : null}
                </TabPane>
                <TabPane tabId="2">
                  {this.state.partPrivileges[this._pcPortalViewTickets] ===
                  true ? (
                    <Tickets
                      isTicketsDataFetched={this.state.isTicketsDataFetched}
                      tickets={this.state.tickets}
                      todos={this.state.wipPriority}
                      todos1={this.state.wipClientApproval}
                      todos2={this.state.workInProgress}
                      todos3={this.state.alertEventStatus}
                      todos4={this.state.wipTicketsIDFilter}
                      ticketsList={this.state.ticketsList}
                      profile={this.state.profile}
                      assignId={this.state.assignId}
                      getAlertTickets={this.getAlertTickets}
                      reloadTicketsData={this.getDataByFilters}
                      ticketDataFetch={this.ticketDataFetch}
                      checkPriority={this.checkPriority}
                      checkApproval={this.checkApproval}
                      checkWIP={this.checkWIP}
                      checkAlertsStatus={this.checkAlertsStatus}
                      checkTicketIDs={this.checkWIPTicketIDs}
                      clearAllticketsFilters={
                        this.clearWorkInProgressTicketsFilters
                      }
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="3">
                  {this.state.partPrivileges[this._pcPortalViewAlerts] ===
                  true ? (
                    <IgnoredAlerts
                      ignoredAlerts={this.state.ignoredAlerts}
                      ignoredAlertsDataFetched={
                        this.state.isIgnoredAlertsDataFetched
                      }
                      ignoreAlertSites={this.state.ignoreAlertSites}
                      filter={this.state.ignoredAlertsFilter}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="4">
                  {this.state.partPrivileges[this._pcPortalViewTickets] ===
                  true ? (
                    <ClosedTickets
                      closedTickets={this.state.closedTickets}
                      allClosedTickets={this.state.allClosedTickets}
                      isclosedTicketsFetched={this.state.isclosedTicketsFetched}
                      sites={this.state.sitesList}
                      usersList={this.state.usersList}
                      todos={this.state.closedTicketsPriority}
                      todos1={this.state.closedAlertEventStatus}
                      todos2={this.state.closedResolutionCategory}
                      todos3={this.state.closedTicketsIDFilter}
                      getClosedTickets={this.getClosedTickets}
                      reloadTicketsData={this.getDataByFilters}
                      checkPriority={this.closedTicketcheckPriority}
                      checkAlertsStatus={this.checkclosedAlertsStatus}
                      checkResolutionCategory={
                        this.checkClosedResolutionCategory
                      }
                      checkTicketIDs={this.checkClosedTicketIDs}
                      clearAllticketsFilters={this.clearClosedTicketsFilters}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="5">
                  {this.state.partPrivileges[this._pcPortalViewTickets] ===
                  true ? (
                    <DispatchTickets
                      isTicketsDataFetched={
                        this.state.isDispatchTicketsDataFetched
                      }
                      tickets={this.state.dispatchTickets}
                      todos={this.state.dispatchTicketsPriority}
                      todos1={this.state.dispatchTicketsApproval}
                      todos2={this.state.dispatchWorkInProgress}
                      todos3={this.state.dispatchAlertEventStatus}
                      todos4={this.state.dispatchTicketsIDFilter}
                      ticketsList={this.state.dispatchTicketsList}
                      profile={this.state.profile}
                      assignId={this.state.assignId}
                      getAlertTickets={this.getDispatchAlertTickets}
                      reloadTicketsData={this.getDataByFilters}
                      ticketDataFetch={this.DispatchTicketDataFetch}
                      checkPriority={this.DispatchTicketcheckPriority}
                      checkApproval={this.DispatchTicketcheckApproval}
                      checkWIP={this.checkDispatchWIP}
                      checkAlertsStatus={this.checkDispatchAlertsStatus}
                      checkTicketIDs={this.checkDispatchTicketIDs}
                      clearAllticketsFilters={this.clearDispatchTicketsFilters}
                    />
                  ) : null}
                </TabPane>
                <TabPane tabId="6">
                  {this.state.partPrivileges[this._pcPortalViewTickets] ===
                  true ? (
                    <PendingCloseTickets
                      isTicketsDataFetched={
                        this.state.isPendingCloseTicketsDataFetched
                      }
                      tickets={this.state.pendingCloseTickets}
                      todos={this.state.pendingCloseTicketsPriority}
                      todos1={this.state.pendingCloseTicketsApproval}
                      todos2={this.state.pendingCloseAlertEventStatus}
                      todos3={this.state.pendingCloseResolutionCategory}
                      todos4={this.state.pendingCloseTicketsIDFilter}
                      ticketsList={this.state.pendingCloseTicketsList}
                      profile={this.state.profile}
                      assignId={this.state.assignId}
                      getAlertTickets={this.pendingClose}
                      reloadTicketsData={this.getDataByFilters}
                      ticketDataFetch={this.pendingCloseTicketsDataFetch}
                      checkPriority={this.pendingCloseTicketcheckPriority}
                      checkApproval={this.pendingCloseTicketcheckApproval}
                      checkAlertsStatus={this.pendingCloseAlertsStatus}
                      checkResolutionCategory={
                        this.pendingClosedResolutionCategory
                      }
                      checkTicketIDs={this.checkPendingCloseTicketIDs}
                      clearAllticketsFilters={
                        this.clearPendingCloseTicketsFilters
                      }
                    />
                  ) : null}
                </TabPane>
              </TabContent>
            </WithLoading>
          </Col>
          {/* </Col> */}
        </Row>
        {this.state.isDatePickerModal === true ? (
          <DatePickerModal
            isDatePickerModal={this.state.isDatePickerModal}
            closeDatePickerModal={this.closeDatePickerModal}
            getDates={this.getDates}
            filter={this.state.filter}
          ></DatePickerModal>
        ) : null}
        {this.state.ticketObj ? (
          <Modal
            isOpen={this.state.isTicketCreateConfirmModal}
            toggle={this.closeTicketConfirmModal}
            size="md"
            backdrop="static"
            centered={false}
            className="sram-modal-content"
          >
            <ModalHeader>
              {this.state.sitesVerify === false
                ? AppMsgResProps.body.content.error
                : AppMsgResProps.body.content.confirmation}
            </ModalHeader>
            <ModalBody className="mb-2">
              <Row>
                <Col sm={12} md={12} lg={12} className="pt-2">
                  {this.state.sitesVerify === false ? (
                    <>
                      <div>
                        {AlertMsgResProps.body.content.ticketsCantCreateMess}
                      </div>
                      <div>{AlertMsgResProps.body.content.singleTicket}</div>
                    </>
                  ) : null}
                </Col>
                <Col sm={12} md={12} lg={12} className="pt-2">
                  {this.state.sitesVerify === true &&
                  this.state.selectAll === false
                    ? "Are you sure you want to create ticket as all alerts from the site are not selected."
                    : null}
                </Col>
                <Col sm={12} md={12} lg={12} className="pt-2">
                  {this.state.sitesVerify === true &&
                  this.state.selectAll === true
                    ? "Are you sure you want to create the Ticket for the selected Alerts?"
                    : null}
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter className="m-1 p-2">
              {this.state.sitesVerify === true ? (
                <>
                  <Button
                    type="button"
                    className="sram-btn-blue mx-2"
                    onClick={this.createTicket}
                  >
                    {AppMsgResProps.body.form.yes.label}
                  </Button>
                  <Button
                    type="button"
                    className="sram-btn-white mx-2"
                    onClick={this.closeTicketConfirmModal}
                  >
                    {AppMsgResProps.body.form.no.label}
                  </Button>
                </>
              ) : (
                <Button
                  type="button"
                  className="sram-btn-white mx-2"
                  onClick={this.closeTicketConfirmModal}
                >
                  {AppMsgResProps.body.form.ok.label}
                </Button>
              )}
            </ModalFooter>
          </Modal>
        ) : null}
        {this.state.ignoredObj ? (
          <ConfirmModal
            isOpenConfirmModal={this.state.isIgnoreConfirmModal}
            closeConfirmModal={this.closeIgnoreConfirmModal}
            handleSubmit={this.updateAlert.bind(this)}
          >
            <div>
              <div>
                <Label>
                  {AlertMsgResProps.body.content.confirmModelMessage}
                </Label>
              </div>
            </div>
          </ConfirmModal>
        ) : null}
        {this.state.isTicketCreateReConfirmModal ? (
          <Modal
            isOpen={this.state.isTicketCreateReConfirmModal}
            toggle={this.closeTicketReConfirmModel}
            size="md"
            backdrop="static"
            centered={false}
            className="sram-modal-content"
          >
            <ModalHeader>
              {AppMsgResProps.body.content.confirmation}
            </ModalHeader>
            <ModalBody className="mb-2">
              <Row>
                <Col sm={12} md={12} lg={12} className="pt-2">
                  An open ticket for this dispenser and component already exists
                  on Ticket Id:{" "}
                  <span className="sram-font-weight-500 ">
                    {this.state.reConfirmationTicketNo?.ticketNumber}
                  </span>
                  . Would you like to add this alert to the open ticket?
                </Col>
              </Row>
              <FormGroup
                className="ml-2 pt-2"
                onChange={(e) => {
                  this.handleAlertConfirmationChange(e);
                }}
              >
                <Row className="ml-3">
                  <Label>
                    <Input value={"Add"} type="radio" name="radio1" />{" "}
                    <span>
                      {AlertMsgResProps.body.form.addTicket}{" "}
                      <span className="sram-font-weight-500 ">
                        {this.state.reConfirmationTicketNo?.ticketNumber}
                      </span>{" "}
                      (Status:{" "}
                      <span className="sram-font-weight-500 ">
                        {this.state.reConfirmationTicketNo?.Status}
                      </span>
                      )
                    </span>
                  </Label>
                </Row>
                <Row className="ml-3">
                  <Label>
                    <Input value={"New"} type="radio" name="radio1" />{" "}
                    <span>{AlertMsgResProps.body.form.newTicket}</span>
                  </Label>
                </Row>
              </FormGroup>
            </ModalBody>
            <ModalFooter className="m-1 p-2">
              <Button
                type="button"
                className="sram-btn-blue mx-2"
                onClick={this.createTicketReConfirmation}
              >
                {AlertMsgResProps.body.form.confirm}
              </Button>
              <Button
                type="button"
                className="sram-btn-white mx-2"
                onClick={this.closeTicketReConfirmModel}
              >
                {AlertMsgResProps.body.form.cancel}
              </Button>
            </ModalFooter>
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default Alerts;
