import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Row,
  Col,
  // UncontrolledCollapse,
  Table,
  Badge,
  Collapse,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import {
  updateTicket,
  getHistory,
  getTicketMails,
  requestApproval,
  approveEmail,
  seekEmailApproval,
  rejectApproval,
  getClientNotificationHistory,
} from "../actions/alert-actions";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import AppUtils from "../../core/helpers/app-utils";
import Select from "react-select";
import AlertStatusUpdateModal from "./alert-status-update-modal";
import WithLoading from "../../core/helpers/with-loading";
import { AlertMsgResProps } from "../messages/alert-properties";
import { AppMsgResProps } from "../../core/messages/app-properties";
import axios from "axios";
import AlertUtils from "../helpers/alert-utils";
import _ from "lodash";
// import moment from "moment";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import { AlertURLProps } from "../settings/alert-urls";
import ClientApprovalModal from "./client-approval-modal";
import { clientNotificationOptions } from "../helpers/alert-utils";

class TicketsView extends React.Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  _pcPortalTicketsClientApproval = "HT-TCT-TCAP";
  _pcPortalTicketsUpdateStatus = "HT-TCT-TUPS";
  _pcPortalTicketsLockUnlock = "HT-TCT-TLOC";
  _pcPortalTicketsPriority = "HT-TCT-TPRT";
  _pcPortalTicketsByCustomer = "HT-TCT-TART";

  constructor() {
    super();
    this.state = {
      prioritylist: [
        {
          label: "High",
          value: "High",
        },
        {
          label: "Medium",
          value: "Medium",
        },
        {
          label: "Low",
          value: "Low",
        },
      ],
      changePriority: { label: "Change Priority", value: "" },
      isAlertStatusUpdateModal: false,
      alertTicketId: null,
      ticketPriority: null,
      lockStatus: null,
      statusData: null,
      status: null,
      statusDataFetched: false,
      isHistoryDataFetched: false,
      historyDataFetched: true,
      history: null,
      ticketStatus: null,
      assignId: null,
      wip: null,
      changePriorityStatus: true,
      lockStatusUpdate: true,
      partPrivileges: this.initPartPrivileges(),
      /////// Email Test variables
      isEmailApprovalModel: false,
      userSelectedoption: null,
      modelData: null,
      ticketEmails: null,
      approvalComments: this.initFormCommentsFields(),
      formInputFields: null,
      isemailDataFetched: false,
      selectedEmail: [],
      emailValidation: false,
      emailTextField: true,
      spinner: false,
      submitspinner: false,
      responseMailData: null,
      hideSubmitButton: false,
      ticketAlertStatus: false,
      purchaseOrder: null,
      ticketMessageBody: "",
      ticketSubject: "",
      cientEmailType: "",
      clientNotificationHistory: null,
      ticketCurrentStatus: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    await this.checkPartPrivilege();
    await this.setPartPrivileges();
    /// To call service for every 5 minutes
    this.interval = setInterval(() => {
      if (this.props.pendingRequest === false) {
        if (
          this.state.isEmailApprovalModel === false &&
          this.state.isAlertStatusUpdateModal === false &&
          this.state.lockStatusUpdate === true &&
          this.state.changePriorityStatus === true
        ) {
          this.props.getAlertTickets();
        } else {
          console.log("work in progress");
        }
      }
    }, 5 * 60000);
  }
  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
    //To clear the set time interval
    clearInterval(this.interval);
  }

  initPartPrivileges = () => {
    return {
      [this._pcPortalTicketsClientApproval]: false,
      [this._pcPortalTicketsUpdateStatus]: false,
      [this._pcPortalTicketsLockUnlock]: false,
      [this._pcPortalTicketsPriority]: false,
      [this._pcPortalTicketsByCustomer]: false,
    };
  };

  checkPartPrivilege = async () => {
    const partCodes = [
      this._pcPortalTicketsClientApproval,
      this._pcPortalTicketsUpdateStatus,
      this._pcPortalTicketsLockUnlock,
      this._pcPortalTicketsPriority,
      this._pcPortalTicketsByCustomer,
    ];
    const partPrivilege = await getPagePartsPrivilege(
      partCodes,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AlertURLProps.pageNotFound);
    }
  };

  setPartPrivileges = async () => {
    const partCodes = [
      this._pcPortalTicketsClientApproval,
      this._pcPortalTicketsUpdateStatus,
      this._pcPortalTicketsLockUnlock,
      this._pcPortalTicketsPriority,
      this._pcPortalTicketsByCustomer,
    ];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  getTicketHistory = async (alertTicketId) => {
    await getClientNotificationHistory(alertTicketId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.response
        ) {
          if (res.data.response && res.data.response?.clientNotification) {
            await this.setStateAsync({
              clientNotificationHistory: res.data.response?.clientNotification,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  openApprovalModel = async (obj, alertTicketId) => {
    await this.setStateAsync({ spinner: true, alertTicketId: alertTicketId });
    await this.getTicketHistory(alertTicketId);
    // await this.setStateAsync({ alertTicketId: alertTicketId });
    await this.setStateAsync({
      // spinner: true,
      isEmailApprovalModel: true,
      emailTextField: true,
      userSelectedoption: null,
      selectedEmail: [],
      // isEmailApprovalModel: true,
      submitspinner: false,
      responseMailData: null,
    });
    // await this.setStateAsync({ isEmailApprovalModel: true });
    // await this.setStateAsync({ isemailDataFetched: false });
    await this.setStateAsync({ modelData: obj });
    if (
      this.state.partPrivileges[this._pcPortalTicketsClientApproval] === true
    ) {
      await this.getTicketMails();
      await this.setStateAsync({
        formInputFields: await this.initFormInputFields(),
        isemailDataFetched: false,
      });
    }
    await this.setStateAsync({
      isemailDataFetched: true,
    });
  };

  getTicketMails = async () => {
    await this.setStateAsync({
      ticketEmails: null,
    });
    const siteId = this.state.modelData?.siteId;
    const queryData = "O";
    await getTicketMails(siteId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ ticketEmails: res.data.result });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  getRequestApproval = async (commentsobj) => {
    this.setStateAsync({ spinner: true });
    const queryData = "approve";
    await requestApproval(queryData, commentsobj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.message
        ) {
          this.setStateAsync({ responseMailData: res.data.message });
          // this.setStateAsync({ spinner: true });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.props.getAlertTickets();
    // this.setStateAsync({ spinner: false });
  };

  getRejectApproval = async (commentsobj) => {
    this.setStateAsync({ spinner: true });
    const queryData = "rejected";
    await rejectApproval(queryData, commentsobj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.message
        ) {
          this.setStateAsync({ responseMailData: res.data.message });
          // this.setStateAsync({ spinner: true });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.props.getAlertTickets();
    // this.setStateAsync({ spinner: false });
  };

  getApproveEmail = async (mailDataObj) => {
    this.setStateAsync({ spinner: true });
    const queryData = "approveEmail";
    await approveEmail(queryData, mailDataObj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.message
        ) {
          this.setStateAsync({ responseMailData: res.data.message });
          // this.setStateAsync({ spinner: true });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.props.getAlertTickets();
    // this.setStateAsync({ spinner: false });
  };

  getSeekEmailApproval = async (queryData, mailDataObj) => {
    this.setStateAsync({ spinner: true });
    await seekEmailApproval(queryData, mailDataObj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.message
        ) {
          this.setStateAsync({ responseMailData: res.data.message });
          // this.setStateAsync({ spinner: true });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.props.getAlertTickets();
    // this.setStateAsync({ spinner: false });
  };

  handleSelectedEmail = async (e) => {
    // console.log(">>>>>>>>>>>>>EMAIL SELECTED FOR CHANGE>>>>>>>>>>>>>>>>>>>>")
    let mail = e?.map((email) => email.email);
    await this.setStateAsync({
      selectedEmail: mail,
      emailValidation: false,
    });
  };
  handleedittext = async () => {
    // console.log(">>>>>>>>MESSAGE EVENT CHANGE>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    this.setStateAsync({ emailTextField: !this.state.emailTextField });
  };

  handleOutputTypeChange = async (selectedOption) => {
    // console.log(">>>>>>>>>>>>>>>>Option change user >>>>>>>>>>>>>>>>>>>>>>>")
    await this.updateTicketBody(selectedOption);
    await this.setStateAsync({ userSelectedoption: selectedOption });
  };

  closeEmailModel = async () => {
    // console.log(">>>>>>>>>>>>>>>>>close modal Selected >>>>>>>>>>>>>>>>>>")
    this.setState({
      isEmailApprovalModel: false,
      userSelectedoption: null,
      spinner: false,
      emailValidation: false,
    });
  };

  goBacktoNotifications = async () => {
    await this.setState({ userSelectedoption: null });
  };

  initFormInputFields = () => {
    // let currentDate = moment().format('YYYY-MM-D');
    const siteAddress = AlertUtils.getSiteAddress(this.state.modelData);
    const siteAddress1 = AlertUtils.getSiteAddress1(this.state.modelData);

    return {
      ticketMail: this.state.selectedEmail ? this.state.selectedEmail : null,
      ticketSubject: this.state.ticketSubject,
      // "Issue identified at the site located at: " +
      // this.getAddress(siteAddress, siteAddress1),
      ticketMessageHead: `Hello, \n\nGuardian Connect has identified an issue with your fuel system at the site located at: ${this.getAddress(
        siteAddress,
        siteAddress1
      )}`,
      // +"\n" +
      ticketMessageBody: this.state.ticketMessageBody,
      ticketRegrds:
        "Thank you," +
        "\n" +
        "The Guardian Connect Team," +
        "\n" +
        "904-495-7480" +
        "\n" +
        "guardianconnectsupport@guardianfueltech.com",
    };
  };

  updateTicketBody = async (selectedOption) => {
    await this.setStateAsync({ ticketMessageBody: "", ticketSubject: "" });
    const siteAddress = AlertUtils.getSiteAddress(this.state.modelData);
    const siteAddress1 = AlertUtils.getSiteAddress1(this.state.modelData);

    clientNotificationOptions.forEach(async (obj) => {
      if (obj.clientNotificationType === selectedOption) {
        await this.setStateAsync({
          ticketMessageBody: obj.ticketMessageBody,
          ticketSubject:
            selectedOption === "Warm Start Confirmation"
              ? `Remote Resolution in Progress: ${this.getAddress(
                  siteAddress,
                  siteAddress1
                )}`
              : `Action Req’d: ${this.getAddress(siteAddress, siteAddress1)}`,
          cientEmailType: obj.cientEmailType,
        });
      }
    });
    await this.setStateAsync({ formInputFields: this.initFormInputFields() });
  };

  initFormCommentsFields = () => {
    return {
      approvalComments: "",
    };
  };

  formValidationSchema = () => {
    const schema = {
      approvalComments: Yup.string()
        .trim()
        .required(AlertMsgResProps.body.notification.approveComments.empty),
    };
    return schema;
  };

  formEmailValidationSchema = () => {
    const schema = {
      ticketSubject: Yup.string()
        .trim()
        .required(AlertMsgResProps.body.notification.subject.empty),
      ticketMessageHead: Yup.string()
        .trim()
        .required(AlertMsgResProps.body.notification.message.empty),
      ticketMessageBody: Yup.string()
        .trim()
        .required(AlertMsgResProps.body.notification.message.empty),
      ticketRegrds: Yup.string()
        .trim()
        .required(AlertMsgResProps.body.notification.regards.empty),
    };
    return schema;
  };

  handleConfirmation = async (values, helpers) => {
    // console.log(">>>>>>>>>>>>>> approve or reject confirmation")
    this.setStateAsync({ submitspinner: true });
    this._helpers = helpers;

    const commentsobj = {
      ticketNumber: this.state.modelData?.ticketNumber,
      alertTicketId: this.state.modelData?.alertTicketId,
      changeNotes: values.approvalComments,
    };
    // console.log(commentsobj)
    //conditon for approved comments and rejected comments service
    if (this.state.userSelectedoption === AlertMsgResProps.body.form.approve) {
      //Service to save the approval comments Data
      await this.getRequestApproval(commentsobj);
      //To display the message from server we added the model to close after 3 seconds
      setTimeout(
        await this.setStateAsync({
          isEmailApprovalModel: false,
          spinner: false,
          emailValidation: false,
          submitspinner: false,
        }),
        3000
      );
    } else {
      //Service to save the reject comments Data
      await this.getRejectApproval(commentsobj);
      //To display the message from server we added the model to close after 3 seconds
      setTimeout(
        await this.setStateAsync({
          isEmailApprovalModel: false,
          spinner: false,
          emailValidation: false,
          submitspinner: false,
        }),
        3000
      );
    }
  };

  handleEmailConfirmation = async (values, helpers) => {
    // console.log(">>>>>>>>>>>>>>submit for mail or seek >>>>>>>>>>>>>>>>>>>>")
    this.setStateAsync({ spinner: true });
    const siteAddress = AlertUtils.getSiteAddress(this.state.modelData);
    const siteAddress1 = AlertUtils.getSiteAddress1(this.state.modelData);
    this._helpers = helpers;
    let number = values.ticketRegrds.replace(/\s/g);
    const contactNumber = number.match(/\d+/g);

    const mailDataObj = {
      ticketNumber: this.state.modelData?.ticketNumber,
      alertTicketId: this.state.modelData?.alertTicketId,
      toEmail: this.state.selectedEmail,
      contactNumber: contactNumber.join(""),
      warrantyDescription: this.state.modelData?.warrantyDescription
        ? this.state.modelData?.warrantyDescription
        : "No warranty",
      siteAddress: siteAddress + siteAddress1,
      messageHeader: values.ticketMessageHead.replace(/\n/g, "<br>"),
      messageBody: values.ticketMessageBody.replace(/\n/g, "<br>"),
      messageFooter: values.ticketRegrds.replace(/\n/g, "<br>"),
      subject: values.ticketSubject.replace(/\n/g, "<br>"),
      clientEmailType: this.state.cientEmailType,
    };
    // console.log(mailDataObj);

    //Condition to check email validation
    if (this.state.selectedEmail?.length > 0) {
      this.setStateAsync({
        emailValidation: false,
        submitspinner: true,
      });

      //Condition to check seek or send email Service
      // await this.state.userSelectedoption === AlertMsgResProps.body.form.approveEmail ?
      //   await this.getApproveEmail(mailDataObj)
      //   :
      await this.getSeekEmailApproval(
        this.state.userSelectedoption,
        mailDataObj
      );
      //To display the message from server we added the model to close after 3 seconds
      setTimeout(
        await this.setStateAsync({
          emailValidation: false,
          isEmailApprovalModel: false,
          spinner: false,
          submitspinner: false,
        }),
        3000
      );
    } else {
      // If Email was not selected by user
      this.setStateAsync({ emailValidation: true });
    }
  };

  updateTicket = async () => {
    let assignId = this.props.assignId;
    await this.setStateAsync({ assignId });
    const ticketObj = {
      alertTicketId: this.state.alertTicketId,
      ticketPriority: this.state.ticketPriority,
      ticketStatus: this.state.ticketStatus,
      lockStatus: this.state.lockStatus,
      assignId: this.state.assignId,
    };
    await updateTicket(ticketObj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data
        ) {
          await this.props.getAlertTickets();
          await this.setStateAsync({
            ticketPriority: "",
            ticketStatus: "",
            lockStatus: "",
            assignId: "",
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  getHistory = async (alertTicketId) => {
    await this.setStateAsync({
      historyDataFetched: false,
      isHistoryDataFetched: false,
      history: null,
      alertTicketId: alertTicketId,
    });
    await getHistory(alertTicketId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let ticketsList = this.props.tickets;
          for (const data of ticketsList) {
            if (data.alertTicketId === alertTicketId) {
              data.alertHistory = res.data.records;
              data.isOpen = !data.isOpen;
            }
          }
          await this.props.ticketDataFetch(ticketsList);
          await this.setStateAsync({
            history: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({
      isHistoryDataFetched: true,
      historyDataFetched: true,
    });
  };

  checkTicket = async (event) => {
    let tickets = this.props.ticketsList;
    var collection = [];
    var value = event.target.value;
    var checked = event.target.checked;
    let todos = this.props.todos;
    let todos1 = this.props.todos1;
    let todos2 = this.props.todos2;

    todos.forEach((obj) => {
      if (obj.value === value) obj["isChecked"] = checked;
    });
    await this.props.checkPriority(todos);
    todos1.forEach((obj) => {
      if (obj.value === value) obj["isChecked"] = checked;
    });
    await this.props.checkStatus(todos1);
    todos2.forEach((obj) => {
      if (obj.value === value) obj["isChecked"] = checked;
    });
    await this.props.checkApproval(todos2);
    const filterList = [...todos, ...todos1, ...todos2];
    for (const data of filterList) {
      if (data.isChecked) {
        for (const list of tickets) {
          if (
            list.ticketPriority === data.value ||
            list.ticketStatus === data.value ||
            list.approveStatusDescription === data.value
          ) {
            collection.push(list);
          }
        }
      }
    }
    var non_duplidated_data = _.uniq(collection, "alertTicketId");
    await this.props.ticketDataFetch(non_duplidated_data);
  };

  getAddress(siteAddress, siteAddress1) {
    let address = "";
    if (siteAddress && siteAddress1) {
      address = siteAddress + ", " + siteAddress1;
    } else if (siteAddress) {
      address = siteAddress;
    } else {
      address = siteAddress1;
    }
    return address;
  }

  getEventStatus(status) {
    if (status !== " ") {
      if (
        status.toLowerCase() === "down" ||
        status.toLowerCase() === "critical" ||
        status.toLowerCase() === "in-error"
      ) {
        return (
          <div className="tooltip">
            {" "}
            <span
              className="sram-circle-red tooltip"
              style={{ height: "20px", width: "20px" }}
            >
              &nbsp;
            </span>
            <span className="tooltiptext">Status : {status}</span>
          </div>
        );
      } else if (status.toLowerCase() === "in-warning") {
        return (
          <div className="tooltip">
            {" "}
            <span
              className="sram-circle-yellow tooltip"
              style={{ height: "20px", width: "20px" }}
            >
              &nbsp;
            </span>
            <span className="tooltiptext">Status : {status}</span>
          </div>
        );
      } else {
        return (
          <div className="tooltip">
            {" "}
            <span
              className="sram-circle-orange tooltip"
              style={{ height: "20px", width: "20px" }}
            >
              &nbsp;
            </span>
            <span className="tooltiptext">Status : {status}</span>
          </div>
        );
      }
    } else {
      return (
        <div className="tooltip">
          {" "}
          <span
            className="sram-circle-gray tooltip"
            style={{ height: "20px", width: "20px" }}
          >
            &nbsp;
          </span>
          <span className="tooltiptext">Status : No status from dispenser</span>
        </div>
      );
    }
  }

  getSquare(status) {
    if (status) {
      if (status.toLowerCase() === "high") {
        return (
          <div className="tooltip">
            {" "}
            <Badge className="sram-badge-danger" color="danger">
              &nbsp;
            </Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      } else if (status.toLowerCase() === "medium") {
        return (
          <div className="tooltip">
            {" "}
            <Badge className="sram-badge-warning" color="warning">
              &nbsp;
            </Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      } else if (status.toLowerCase() === "low") {
        return (
          <div className="tooltip">
            {" "}
            <Badge className="sram-badge-success" color="success">
              &nbsp;
            </Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      } else {
        return (
          <div className="tooltip">
            {" "}
            <Badge color="secondary">&nbsp;</Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      }
    } else {
      return (
        <div className="tooltip">
          {" "}
          <Badge className="sram-badge-gray">&nbsp;</Badge>
          <span className="tooltiptext">Priority : None</span>
        </div>
      );
    }
  }

  priorityChange = async (alertTicketId, event) => {
    await this.setStateAsync({ changePriorityStatus: false });
    await this.setStateAsync({ alertTicketId: alertTicketId });

    await this.setStateAsync({
      ticketPriority: event.value,
    });
    await this.updateTicket();
    await this.hideCollapse(alertTicketId);
    await this.setStateAsync({ changePriorityStatus: true });
  };

  handleLockClick = async (alertTicketId, lockStatus) => {
    await this.setStateAsync({ lockStatusUpdate: false });
    var lock_status = "";
    if (lockStatus === "Open") {
      lock_status = "C";
    }
    if (lockStatus === "Close") {
      lock_status = "O";
    }
    await this.setStateAsync({ lockStatus: lock_status });
    await this.setStateAsync({ alertTicketId: alertTicketId });
    await this.updateTicket();
    await this.setStateAsync({ lockStatusUpdate: true });
  };

  closeAlertStatusUpdateModal = async () => {
    await this.setState({
      isAlertStatusUpdateModal: false,
    });
  };
  hideCollapse = async (alertTicketId) => {
    let ticketsList = this.props.tickets;
    for (const data of ticketsList) {
      if (data.alertTicketId === alertTicketId) {
        data.isOpen = false;
      }
    }
    await this.props.ticketDataFetch(ticketsList);
  };

  openAlertStatusUpdateModal = async (
    alertTicketId,
    ticketStatus,
    alertTickets,
    purchaseOrder,
    ticketsObj
  ) => {
    let ticketAlertEventStatus = alertTickets.every(
      (v) => v.alertEventStatus === "C"
    );
    await this.setStateAsync({
      ticketAlertStatus: ticketAlertEventStatus,
      ticketCurrentStatus: ticketsObj,
    });
    await this.setStateAsync({ statusDataFetched: false });
    await this.hideCollapse(alertTicketId);
    await this.setState({
      isAlertStatusUpdateModal: true,
      alertTicketId: alertTicketId,
      status: ticketStatus,
      purchaseOrder: purchaseOrder,
    });
    // var status = "";
    var statusData = "";
    var wip = [];
    if (this.state.history) {
      for (const data of this.state.history) {
        if (data.changeValue === "Pending Close") {
          statusData = data.changeCategory;
          break;
        } else if (data.changeValue === "In Progress") {
          statusData = data.changeCategory.split(", ");
          for (const val of statusData) {
            wip.push({
              label: val,
              value: val,
            });
          }
          break;
        }
      }
    }
    await this.setStateAsync({
      statusData: statusData,
      wip: wip,
      statusDataFetched: true,
    });
  };

  render() {
    return (
      <>
        <div className="padding-8" style={{ background: "#F2F2F2" }}>
          <WithLoading
            type="page"
            className="pt-2 mr-2"
            isPageDataFetched={this.props.isTicketsDataFetched}
          >
            {this.props.tickets && this.props.tickets.length > 0 ? (
              this.props.tickets.map((obj, i) => {
                const siteAddress = AlertUtils.getSiteAddress(obj);
                const siteAddress1 = AlertUtils.getSiteAddress1(obj);
                let createTimestamp = "";
                if (obj.createTimestamp) {
                  createTimestamp = obj.createTimestamp.split("-");
                  createTimestamp =
                    createTimestamp[0] +
                    "-" +
                    createTimestamp[1] +
                    "-" +
                    createTimestamp[2];
                }
                let changePriority = "";
                if (obj.ticketPriority) {
                  if (obj.ticketPriority.toLowerCase() === "high") {
                    changePriority = {
                      label: "High",
                      value: "high",
                    };
                  }
                  if (obj.ticketPriority.toLowerCase() === "medium") {
                    changePriority = {
                      label: "Medium",
                      value: "medium",
                    };
                  }
                  if (obj.ticketPriority.toLowerCase() === "low") {
                    changePriority = {
                      label: "Low",
                      value: "low",
                    };
                  }
                } else {
                  changePriority = this.state.changePriority;
                }

                return (
                  <Card className="sram-dispenser-card-bottom" key={i}>
                    <CardBody>
                      <div className="d-md-flex align-items-center"></div>

                      <Row className="mb-2">
                        <Col sm={12} md={2} lg={2}>
                          <Badge
                            color="secondary"
                            className="sram-text-font sram-status"
                          >
                            {obj.ticketStatus}
                          </Badge>
                        </Col>
                        <Col
                          sm={12}
                          md={obj.serviceCallId && obj.statusOfCall ? 3 : 4}
                          lg={obj.serviceCallId && obj.statusOfCall ? 3 : 4}
                        >
                          <span className="sram-error-modal-font sram-text-font font-mild">
                            {obj.siteName} (GVR ID : {obj.siteNumber})
                          </span>{" "}
                          <div>
                            <span className="fas fa-map-marker-alt"></span>{" "}
                            &nbsp;
                            <span className="sram-error-modal-font sram-text-font font-mild">
                              {this.getAddress(siteAddress, siteAddress1)}
                            </span>
                          </div>
                        </Col>
                        <Col sm={12} md={3} lg={3}>
                          <span className="sram-error-modal-font sram-text-font font-mild">
                            Ticket Id:{" "}
                            {obj.ticketNumber ? obj.ticketNumber : "-"}
                          </span>
                          {/* <span className="ml-3 sram-error-modal-font sram-text-font font-mild">
                            Purchase Order:{" "}
                            {obj.customerPurchaseOrder
                              ? obj.customerPurchaseOrder
                              : "-"}
                          </span> */}
                          <div>
                            <span className="sram-error-modal-font sram-text-font font-mild">
                              Warranty:{" "}
                              {obj.warrantyDescription && obj.warrantyExpiryDate
                                ? obj.warrantyDescription +
                                  "(" +
                                  AppUtils.getDate(obj.warrantyExpiryDate) +
                                  ")"
                                : "-"}
                            </span>
                          </div>
                        </Col>
                        {obj.serviceCallId && obj.statusOfCall ? (
                          <Col sm={12} md={2} lg={2} className="pl-0 pr-0">
                            {obj.serviceCallId && obj.statusOfCall ? (
                              <>
                                <span className="sram-error-modal-font sram-text-font font-mild">
                                  Service Call Id:{" "}
                                  {obj.serviceCallId ? obj.serviceCallId : "-"}
                                </span>
                                <div>
                                  <span className="sram-error-modal-font sram-text-font font-mild">
                                    Status:{" "}
                                    {obj.statusOfCall ? obj.statusOfCall : "-"}
                                  </span>
                                </div>
                              </>
                            ) : null}
                          </Col>
                        ) : null}
                        <Col
                          sm={12}
                          md={obj.serviceCallId && obj.statusOfCall ? 2 : 3}
                          lg={obj.serviceCallId && obj.statusOfCall ? 2 : 3}
                        >
                          <span
                            className="sram-error-modal-font sram-text-font font-mild pull-right"
                            title={
                              "Ticket Created TimeStamp: " +
                              AppUtils.getDateTime(createTimestamp) +
                              " " +
                              obj.createTimestampTimezone
                            }
                          >
                            {AppUtils.getDateTime(createTimestamp)}{" "}
                            {obj.createTimestampTimezone}
                          </span>
                        </Col>
                      </Row>
                      <div className="sram-border-top">
                        <div className="sram-list-main">
                          <Table
                            responsive
                            hover
                            size="sm"
                            className="sram-table-main"
                          >
                            <thead>
                              <th className="sram-th-0 sram-no-border"></th>
                              <th className="sram-th-1 sram-no-border"></th>
                              <th className="sram-th-2 sram-no-border"></th>
                              <th className="sram-th-3 sram-no-border"></th>
                              <th className="sram-th-4 sram-no-border"></th>
                              <th className="sram-no-border"></th>
                            </thead>
                            <tbody>
                              {obj.alertTickets && obj.alertTickets.length > 0
                                ? obj.alertTickets.map((ticket, index) => {
                                    return (
                                      <tr key={"ticket" + index}>
                                        <td className="sram-text-font sram-table-td-1 sram-no-border">
                                          {this.getEventStatus(
                                            ticket.eventStatus
                                          )}
                                        </td>

                                        <td
                                          className="sram-text-font sram-table-td sram-no-border"
                                          title={
                                            ticket.componentName ===
                                            "pumpcontrolnode"
                                              ? "Alert Created TimeStamp: " +
                                                AppUtils.getDateTime(
                                                  ticket.alertCreateTimestamp
                                                ) +
                                                " "
                                              : // obj.createTimestampTimezone
                                                "Alert Event TimeStamp: " +
                                                AppUtils.getDateTime(
                                                  ticket.eventTimestamp
                                                ) +
                                                " "
                                            // obj.createTimestampTimezone
                                          }
                                        >
                                          {ticket.componentName ===
                                          "pumpcontrolnode"
                                            ? AppUtils.getDateTime(
                                                ticket.alertCreateTimestamp
                                              )
                                            : AppUtils.getDateTime(
                                                ticket.eventTimestamp
                                              )}{" "}
                                          {/* {obj.createTimestampTimezone} */}
                                        </td>

                                        <td className="sram-text-font sram-table-td sram-no-border">
                                          {ticket.dispenserName}
                                        </td>

                                        <td className="sram-text-font sram-table-td sram-no-border">
                                          {ticket.sideLabel}
                                        </td>

                                        <td className="sram-text-font sram-table-td sram-no-border">
                                          <div>
                                            {ticket.componentName !== "" &&
                                            ticket.componentName !== null
                                              ? // ticket.itemName +
                                                // " - " +
                                                ticket.componentName
                                              : ticket.itemName}
                                          </div>
                                          {ticket.componentName ===
                                          "pumpcontrolnode" ? (
                                            <div>
                                              {`Dispenser Create Timestamp: ${
                                                ticket.dispenserCreateTimestamp
                                                  ? AppUtils.getDateTime(
                                                      ticket.dispenserCreateTimestamp
                                                    )
                                                  : "-"
                                              }`}
                                            </div>
                                          ) : null}
                                        </td>

                                        <td className="sram-text-font sram-table-td sram-no-border">
                                          {ticket.eventCause === "paper out" ||
                                          ticket.eventCause === "paper jam" ||
                                          ticket.eventCause === "paper low"
                                            ? "Receipt Paper Error"
                                            : ticket.eventCause}
                                        </td>

                                        <td className="sram-text-font sram-table-td sram-no-border">
                                          {ticket.alertEventStatusDescription
                                            ? ticket.alertEventStatusDescription
                                            : "-"}
                                        </td>
                                      </tr>
                                    );
                                  })
                                : null}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="sram-border-top">
                        <Row className="mt-2 mb-2 pl-1">
                          <>
                            <Col
                              sm={12}
                              md={1}
                              lg={1}
                              className="mt-3 sram-max-width-5"
                            >
                              {/* {this.state.partPrivileges[this._pcPortalTicketsPriority] === true ?
                                <> */}
                              {this.getSquare(obj.ticketPriority)}
                              {/* </>
                                : null} */}
                            </Col>
                            <Col
                              xs={12}
                              sm={8}
                              md={4}
                              lg={3}
                              xl={2}
                              className={
                                this.props.profile &&
                                obj.lockStatus === "Close" &&
                                obj.assignId !== this.props.profile.userId
                                  ? "disabled-link mt-2"
                                  : "sram-links mt-2"
                              }
                            >
                              {this.state.partPrivileges[
                                this._pcPortalTicketsPriority
                              ] === true ? (
                                <>
                                  {this.state.changePriorityStatus === false &&
                                  this.state.alertTicketId ===
                                    obj.alertTicketId ? (
                                    <div className="mt-2">
                                      <WithLoading
                                        type="page"
                                        size="sm"
                                        isPageDataFetched={
                                          this.state.changePriorityStatus
                                        }
                                      ></WithLoading>
                                    </div>
                                  ) : (
                                    <Select
                                      className="sram-select-priority"
                                      value={changePriority}
                                      options={this.state.prioritylist}
                                      onChange={this.priorityChange.bind(
                                        this,
                                        obj.alertTicketId
                                      )}
                                    ></Select>
                                  )}
                                </>
                              ) : null}
                            </Col>
                          </>
                          {/* : null} */}

                          {obj.lockStatus === "Open" ? (
                            <Col sm={6} md={2} lg={2} xl={2}>
                              {this.state.partPrivileges[
                                this._pcPortalTicketsLockUnlock
                              ] === true ? (
                                <>
                                  {this.state.lockStatusUpdate === false &&
                                  this.state.alertTicketId ===
                                    obj.alertTicketId ? (
                                    <div className="mt-3">
                                      <WithLoading
                                        type="page"
                                        size="sm"
                                        isPageDataFetched={
                                          this.state.lockStatusUpdate
                                        }
                                      ></WithLoading>
                                    </div>
                                  ) : (
                                    <div
                                      className="mt-3"
                                      onClick={this.handleLockClick.bind(
                                        this,
                                        obj.alertTicketId,
                                        obj.lockStatus
                                      )}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span className="mr-1"> Lock: </span>{" "}
                                      <span className="fas fa-lock-open"></span>
                                    </div>
                                  )}
                                </>
                              ) : null}
                            </Col>
                          ) : (
                            <Col sm={6} md={2} lg={2} xl={2}>
                              {this.state.partPrivileges[
                                this._pcPortalTicketsLockUnlock
                              ] === true ? (
                                <>
                                  {this.props.profile &&
                                  obj.assignId === this.props.profile.userId ? (
                                    this.state.lockStatusUpdate === false &&
                                    this.state.alertTicketId ===
                                      obj.alertTicketId ? (
                                      <div className="mt-3">
                                        <WithLoading
                                          type="page"
                                          size="sm"
                                          isPageDataFetched={
                                            this.state.lockStatusUpdate
                                          }
                                        ></WithLoading>
                                      </div>
                                    ) : (
                                      <div
                                        className="mt-3"
                                        style={{ cursor: "pointer" }}
                                        onClick={this.handleLockClick.bind(
                                          this,
                                          obj.alertTicketId,
                                          obj.lockStatus
                                        )}
                                      >
                                        <span className="mr-1"> Unlock: </span>{" "}
                                        <span className="fas fa-lock"></span>
                                      </div>
                                    )
                                  ) : (
                                    <div className="mt-3 tooltip-1 tooltip">
                                      <span className="fas fa-lock"></span>{" "}
                                      <span className="tooltiptext ml-1">
                                        {" "}
                                        Locked by{" "}
                                        {obj.firstName + "  " + obj.lastName}
                                      </span>
                                    </div>
                                  )}
                                </>
                              ) : null}
                            </Col>
                          )}
                          {/* <Col sm={6} md={3} lg={3}></Col> */}
                          <Col xs={0} sm={0} md={0} lg={1}></Col>

                          <Col
                            xs={12}
                            sm={8}
                            md={4}
                            lg={3}
                            xl={2}
                            className="mt-2"
                          >
                            {this.state.partPrivileges[
                              this._pcPortalTicketsClientApproval
                            ] ||
                            this.state.partPrivileges[
                              this._pcPortalTicketsByCustomer
                            ] === true ? (
                              <>
                                <Badge
                                  className={
                                    obj.approveStatusDescription &&
                                    obj.approveStatusDescription ===
                                      "Approved by Customer"
                                      ? // "sram-bg-blue-6 pull-right disabled-link sram-alert-client-approved  sram-disable-badge sram-ticket-view-button "
                                        "sram-bg-blue-6 pull-right sram-alert-client-approved sram-ticket-view-button"
                                      : obj.approveStatusDescription &&
                                        obj.approveStatusDescription ===
                                          "Rejected by Customer"
                                      ? // "sram-bg-blue-6 pull-right sram-alert-client-rejected sram-ticket-view-button disabled-link sram-disable-badge"
                                        "sram-bg-blue-6 pull-right sram-alert-client-rejected sram-ticket-view-button"
                                      : obj.approveStatusDescription &&
                                        obj.approveStatusDescription ===
                                          "No Response"
                                      ? "sram-bg-blue-6 pull-right sram-alert-client-noResponse sram-ticket-view-button"
                                      : obj.approveStatusDescription &&
                                        obj.approveStatusDescription ===
                                          "Pending Approval"
                                      ? this.props.profile &&
                                        obj.lockStatus === "Close" &&
                                        obj.assignId !==
                                          this.props.profile.userId
                                        ? "pull-right sram-links  disabled-link sram-disable-badge font-mild sram-alert-client-pendingApprroval-locked sram-ticket-view-button"
                                        : "pull-right sram-links font-mild sram-alert-client-pendingApprroval sram-ticket-view-button"
                                      : this.props.profile &&
                                        obj.lockStatus === "Close" &&
                                        obj.assignId !==
                                          this.props.profile.userId
                                      ? "pull-right sram-links  disabled-link sram-disable-badge font-mild sram-alert-update sram-ticket-view-button"
                                      : "pull-right sram-links font-mild sram-alert-update sram-ticket-view-button"
                                  }
                                  onClick={() =>
                                    this.openApprovalModel(
                                      obj,
                                      obj.alertTicketId
                                    )
                                  }
                                >
                                  <span className={""}>
                                    {obj.approveStatusDescription}
                                  </span>
                                  <span className="ml-0 fas fa-envelope pull-right"></span>
                                  {this.state.spinner === true &&
                                  this.state.alertTicketId ===
                                    obj.alertTicketId ? (
                                    <Spinner
                                      size="sm"
                                      color="light"
                                      className={
                                        obj.approveStatusDescription &&
                                        obj.approveStatusDescription ===
                                          "No Response"
                                          ? "pull-right"
                                          : ""
                                      }
                                      // style={{ marginBottom: "2px" }}
                                    />
                                  ) : null}
                                </Badge>
                              </>
                            ) : null}
                          </Col>
                          {/* 
                          <Col
                            xs={12}
                            sm={8}
                            md={4}
                            lg={3}
                            xl={2}
                            className="mt-2"
                          >
                            {this.state.partPrivileges[
                              this._pcPortalTicketsClientApproval
                            ] ||
                              this.state.partPrivileges[
                              this._pcPortalTicketsByCustomer
                              ] === true ? (
                              <>
                                <Badge
                                  className={this.props.profile &&
                                    obj.lockStatus === "Close" &&
                                    obj.assignId !== this.props.profile.userId
                                    ? "pull-right sram-links  disabled-link sram-disable-badge font-mild sram-alert-update sram-ticket-view-button"
                                    : "pull-right sram-links font-mild sram-alert-update sram-ticket-view-button"
                                  }
                                  onClick={() =>
                                    this.openApprovalModel(
                                      obj,
                                      obj.alertTicketId
                                    )
                                  }
                                >
                                  <span>{AlertMsgResProps.head.title.clientNotification}</span>
                                  <span className={"ml-0 fas fa-envelope pull-right"}></span>
                                  {this.state.spinner === true &&
                                    this.state.alertTicketId ===
                                    obj.alertTicketId ? (
                                    <Spinner
                                      size="sm"
                                      color="light"
                                    />
                                  ) : null}
                                </Badge>
                              </>
                            ) : null}
                          </Col> */}

                          <Col
                            xs={12}
                            sm={8}
                            md={4}
                            lg={3}
                            xl={2}
                            className="mt-2"
                          >
                            {this.state.partPrivileges[
                              this._pcPortalTicketsUpdateStatus
                            ] === true ? (
                              <Badge
                                className={
                                  this.props.profile &&
                                  obj.lockStatus === "Close" &&
                                  obj.assignId !== this.props.profile.userId
                                    ? "sram-bg-blue-6 pull-right disabled-link font-mild sram-disable-badge sram-ticket-view-button"
                                    : "pull-right sram-links font-mild sram-alert-update sram-ticket-view-button"
                                }
                                onClick={this.openAlertStatusUpdateModal.bind(
                                  this,
                                  obj.alertTicketId,
                                  obj.ticketStatus,
                                  obj.alertTickets,
                                  obj.customerPurchaseOrder,
                                  obj
                                )}
                              >
                                <span className="pl-2">Update Status</span>
                                &nbsp;&nbsp;{" "}
                                <span className="fas fa-external-link-alt pr-2  pull-right"></span>
                              </Badge>
                            ) : null}
                          </Col>

                          <Col
                            xs={12}
                            sm={8}
                            md={4}
                            lg={3}
                            xl={2}
                            className="mt-2"
                            // className="mt-2 sram-chev sidebar-nav"
                          >
                            {this.state.historyDataFetched === false &&
                            this.state.alertTicketId === obj.alertTicketId ? (
                              <Badge
                                id={"toggler" + i}
                                aria-expanded={obj.isOpen}
                                onClick={() =>
                                  this.getHistory(obj.alertTicketId)
                                }
                                data-toggle="collapse"
                                className="sram-bg-blue-6 pull-right font-mild sram-mrb-8 sram-links sidebar-link has-arrow sram-has-arrow sram-disable-badge disabled-link sram-ticket-view-button"
                              >
                                <span className="pl-2"> Alert History </span>
                                &nbsp;&nbsp;{" "}
                                {obj.isOpen === false ? (
                                  <span className="fas fa-chevron-right"></span>
                                ) : (
                                  <span className="fas fa-chevron-down"></span>
                                )}
                                <Spinner
                                  size="sm"
                                  color="light"
                                  // style={{ marginBottom: "2px" }}
                                />
                              </Badge>
                            ) : (
                              <Badge
                                disabled={obj.lockStatus ? "disabled" : ""}
                                id={"toggler" + i}
                                aria-expanded={obj.isOpen}
                                onClick={() =>
                                  this.getHistory(obj.alertTicketId)
                                }
                                data-toggle="collapse"
                                className="sram-bg-blue-6 pull-right font-mild sram-mrb-8 sram-links sidebar-link has-arrow sram-has-arrow sram-alert-update sram-ticket-view-button"
                              >
                                <span className="pl-2"> Alert History </span>

                                {obj.isOpen === false ? (
                                  <span className="fas fa-chevron-right pull-right"></span>
                                ) : (
                                  <span className="fas fa-chevron-down pull-right"></span>
                                )}
                              </Badge>
                            )}
                          </Col>
                        </Row>
                      </div>

                      <Collapse
                        isOpen={obj.isOpen}
                        // toggler={"#toggler" + i}
                        className=" sram-border-top"
                      >
                        <Col sm={12} md={12} lg={12} className="pad-left-0">
                          <Table>
                            <thead>
                              <tr>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  Timestamp
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  Name
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  Description
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  Notes
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.isHistoryDataFetched === false &&
                              this.state.alertTicketId === obj.alertTicketId ? (
                                <WithLoading
                                  type="page"
                                  size="sm"
                                  className="pt-3"
                                  isPageDataFetched={
                                    this.state.isHistoryDataFetched
                                  }
                                ></WithLoading>
                              ) : obj.alertHistory &&
                                obj.alertHistory.length > 0 ? (
                                obj.alertHistory.map((h, i) => {
                                  var changeType = "";
                                  if (h.changeType === "A") {
                                    changeType = "Approval";
                                  }
                                  if (h.changeType === "P") {
                                    changeType = "Priority";
                                  }
                                  if (h.changeType === "S") {
                                    changeType = "Status";
                                  }
                                  if (h.changeType === "C") {
                                    changeType = "Closed";
                                  }
                                  let modifyTimestamp = "";
                                  if (h.modifyTimestamp) {
                                    modifyTimestamp =
                                      h.modifyTimestamp.split("-");
                                    modifyTimestamp =
                                      modifyTimestamp[0] +
                                      "-" +
                                      modifyTimestamp[1] +
                                      "-" +
                                      modifyTimestamp[2];
                                  }
                                  return (
                                    <tr key={"history" + i}>
                                      <td className="sram-text-font sram-table-td">
                                        {AppUtils.getDateTime(modifyTimestamp)}{" "}
                                        {h.appointmentId
                                          ? `(GP Server time)`
                                          : h.modifyTimestampTimezone}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {h.appointmentId
                                          ? "Service Branch"
                                          : h.firstName + " " + h.lastName}{" "}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {h.appointmentId
                                          ? "Status: " +
                                            h.changeCategory +
                                            "\n" +
                                            "Appointment Id: " +
                                            h.appointmentId
                                          : changeType !== "Closed" &&
                                            changeType !== "Priority" &&
                                            changeType !== "Approval"
                                          ? changeType +
                                            " changed to " +
                                            // "Client Approval"+
                                            // " - " +
                                            h.changeValue +
                                            " - " +
                                            h.changeCategory
                                          : changeType !== "Closed" &&
                                            changeType !== "Priority" &&
                                            changeType !== "status"
                                          ? // changeType +
                                            // " changed to " +
                                            "Client Approval" +
                                            " - " +
                                            // h.changeValue +
                                            // " - " +
                                            h.changeCategory
                                          : changeType !== "Priority"
                                          ? changeType +
                                            " - " +
                                            h.changeCategory
                                          : changeType +
                                            " changed to " +
                                            h.changeValue}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {h.changeNotes}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td className="sram-no-data">
                                    {AlertMsgResProps.body.content.noDataFound}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </Col>
                      </Collapse>
                    </CardBody>
                  </Card>
                );
              })
            ) : (
              <div className="sram-no-data">
                {AlertMsgResProps.body.content.noDataFound}
              </div>
            )}
          </WithLoading>
        </div>
        {this.state.isAlertStatusUpdateModal === true ? (
          <AlertStatusUpdateModal
            isAlertStatusUpdateModal={this.state.isAlertStatusUpdateModal}
            statusDataFetched={this.state.statusDataFetched}
            closeAlertStatusUpdateModal={this.closeAlertStatusUpdateModal}
            alertTicketId={this.state.alertTicketId}
            getAlertTickets={this.props.getAlertTickets}
            statusData={this.state.statusData}
            status={this.state.status}
            wip={this.state.wip}
            getHistory={this.getHistory}
            ticketAlertEventStatus={this.state.ticketAlertStatus}
            purchaseOrder={this.state.purchaseOrder}
            ticketCurrentStatus={this.state.ticketCurrentStatus}
          ></AlertStatusUpdateModal>
        ) : null}

        {this.state.isEmailApprovalModel ? (
          <ClientApprovalModal
            clientNotificationHistory={this.state.clientNotificationHistory}
            isEmailApprovalModel={this.state.isEmailApprovalModel}
            closeEmailModel={this.closeEmailModel}
            goBacktoNotifications={this.goBacktoNotifications}
            isemailDataFetched={this.state.isemailDataFetched}
            showClientApproval={
              this.state.partPrivileges[this._pcPortalTicketsClientApproval]
            }
            showCustomerApproval={
              this.state.partPrivileges[this._pcPortalTicketsByCustomer]
            }
            tickets={this.props.tickets}
            userSelectedoption={this.state.userSelectedoption}
            modelData={this.state.modelData}
            handleOutputTypeChange={this.handleOutputTypeChange}
            approvalComments={this.state.approvalComments}
            handleConfirmation={this.handleConfirmation}
            responseMailData={this.state.responseMailData}
            submitspinner={this.state.submitspinner}
            formInputFields={this.state.formInputFields}
            handleEmailConfirmation={this.handleEmailConfirmation}
            emailValidation={this.state.emailValidation}
            handleSelectedEmail={this.handleSelectedEmail}
            emailTextField={this.state.emailTextField}
            ticketEmails={this.state.ticketEmails}
            handleedittext={this.handleedittext}
          ></ClientApprovalModal>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pendingRequest: state.layoutSettings.pendingRequest,
});

export default connect(mapStateToProps)(TicketsView);
