export default class DeviceUtils {
    static getGradeObjects(data) {
        let a = [            
            { gradeNumber: 1, gradeName: "" },
            { gradeNumber: 2, gradeName: "" },
            { gradeNumber: 3, gradeName: "" },
            { gradeNumber: 4, gradeName: "" },
            { gradeNumber: 5, gradeName: "" },
            { gradeNumber: 6, gradeName: "" },
            { gradeNumber: 7, gradeName: "" },
            { gradeNumber: 8, gradeName: "" },
          ];
          let b = data;
          function comparer(otherArray) {
            return function (current) {
              return (
                otherArray.filter(function (other) {
                  return other.gradeNumber === current.gradeNumber;
                }).length === 0
              );
            };
          }
          var onlyInA = a.filter(comparer(b));
          var onlyInB = b.filter(comparer(a));

          let result = onlyInA.concat(onlyInB);
          let array = data.concat(result);
          const gradeObj = Object.fromEntries(array.map(item => ["gradeNumber"+item.gradeNumber, item.gradeName]));          
          return gradeObj
    }

    static getMeterObjects(data) {
        let a = [
            { meterNumber: 1, meterName: "" },
            { meterNumber: 2, meterName: "" },
            { meterNumber: 3, meterName: "" },
            { meterNumber: 4, meterName: "" },
            { meterNumber: 5, meterName: "" },
            { meterNumber: 6, meterName: "" },
            { meterNumber: 7, meterName: "" },
            { meterNumber: 8, meterName: "" },
          ];
          let b = data;
          function comparer(otherArray) {
            return function (current) {
              return (
                otherArray.filter(function (other) {
                  return other.meterNumber === current.meterNumber;
                }).length === 0
              );
            };
          }
          var onlyInA = a.filter(comparer(b));
          var onlyInB = b.filter(comparer(a));

          let result = onlyInA.concat(onlyInB);
          let array = data.concat(result);
          const meterObj = Object.fromEntries(array.map(item => ["meterNumber"+item.meterNumber, item.meterName]));          
          return meterObj
    }
  }
  