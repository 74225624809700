import React, { Component } from "react";
import { Col, Table, Badge, Modal } from "reactstrap";
import { DispenserMsgResProps } from "../messages/dispenser-properties";
// import PerfectScrollbar from "react-perfect-scrollbar";
import WithLoading from "../../core/helpers/with-loading";
import AppUtils from "../../core/helpers/app-utils";
import { SiteMsgResProps } from "../messages/site-properties";
class SiteDashboardAlertsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      activeTab: "1",
      setActiveTab: "1",
      allAlerts: [],
      showTickets: true,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };
  async componentDidMount() {
    this._isMounted = true;
    // PageUtils.scrollToTop();
    window.appHistory = this.props.history;
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, setActiveTab: tab });
    }
  }
  handleCollapse() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  getAlertPriority(str) {
    if (str) {
      if (str === "Green") {
        return (
          <Badge className="sram-badge-success" color="success">
            {str}
          </Badge>
        );
        // <div className="sram-badge-success">{str}</div>
      }
      if (str === "Red") {
        return (
          <Badge className="sram-badge-danger" color="danger">
            {str}
          </Badge>
        );
        // <div className="sram-badge-danger">{str}</div>
      }
      if (str === "Yellow") {
        return (
          <Badge className="sram-badge-warning" color="warning">
            {str}
          </Badge>
        );
        // <div className="sram-yellow">{str}</div>
      }
    }
  }

  componentWillReceiveProps = async () => {
    if (this.props.allAlerts) {
      if (this.state.showTickets) {
        for (const data of this.props.allAlerts) {
          data.isOpen = false;
          this.state.allAlerts.push(data);
        }
        await this.setStateAsync({ value: this.props.possibleFixes });
        await this.setStateAsync({ allAlerts: this.state.allAlerts });
        await this.setStateAsync({ showTickets: false });
      }
    }
  };

  closeModal = async () => {
    await this.setStateAsync({
      allAlerts: [],
      showTickets: true,
    });
    this.props.closeAlertTicketModal();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isAlertTicketModal}
        toggle={() => this.props.closeAlertTicketModal}
        size="xl"
        backdrop="static"
        // centered={true}
        // className="sram-modal-content"
      >
        {/* <ModalHeader> */}
        <div className="modal-header">
          <span>
            <h5 className="modal-title">Alerts</h5>
          </span>
          <span
            className="pull-right sram-times"
            onClick={() => this.closeModal()}
          >
            <i className="fa fa-times-circle"></i>
          </span>
        </div>
        <div>
          <WithLoading
            isPageDataFetched={this.props.isAllAlertsDataFetched}
            type="page"
            size="md"
          >
            {/* <Row> */}
            <Col sm="12" className="pb-2">
              <Table responsive hover>
                <thead>
                  <tr>
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.componentName.label}
                    </th>
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.sideLabel.label}
                    </th>
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.eventStatus.label}
                    </th>
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.eventCause.label}
                    </th>
                    {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.itemName.label}
                    </th> */}
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.eventTimeStamp.label}
                    </th>
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.createTimestamp.label}
                    </th>
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.ticketId.label}
                    </th>
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.ticketPriority.label}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.allAlerts && this.props.allAlerts.length > 0 ? (
                    this.props.allAlerts.map((alertsData, index) => {
                      // let createTimestamp = "";
                      // if (alertsData.createTimestamp) {
                      //   createTimestamp =
                      //     alertsData.createTimestamp.split("-");
                      //   createTimestamp =
                      //     createTimestamp[0] +
                      //     "-" +
                      //     createTimestamp[1] +
                      //     "-" +
                      //     createTimestamp[2];
                      // }
                      return (
                        <tr key={index}>
                          <td className="sram-text-font sram-table-td">
                            {alertsData.componentName
                              ? alertsData.componentName
                              : "-"}
                          </td>
                          <td className="sram-text-font sram-table-td">
                            {alertsData.sideLabel ? alertsData.sideLabel : "-"}
                          </td>
                          <td className="sram-text-font sram-table-td">
                            {alertsData.eventStatus
                              ? alertsData.eventStatus
                              : "-"}
                          </td>
                          <td className="sram-text-font sram-table-td">
                            {alertsData.eventCause
                              ? alertsData.eventCause === "paper out"
                                ? "Receipt Paper Error"
                                : alertsData.eventCause
                              : "-"}
                          </td>
                          {/* <td className="sram-text-font sram-table-td">
                            {alertsData.itemName ? alertsData.itemName : "-"}
                          </td> */}
                          <td className="sram-text-font sram-table-td">
                            {alertsData.eventTimestamp
                              ? AppUtils.getDateTime(alertsData.eventTimestamp)
                              : "-"}
                          </td>
                          <td
                            className="sram-text-font sram-table-td"
                            title={
                              alertsData.componentName === "pumpcontrolnode"
                                ? `Dispenser Create Timestamp: ${
                                    alertsData.dispenserCreateTimestamp
                                      ? AppUtils.getDateTime(
                                          alertsData.dispenserCreateTimestamp
                                        )
                                      : "-"
                                  }`
                                : null
                            }
                          >
                            {alertsData.createTimestamp
                              ? `${AppUtils.getDateTime(
                                  alertsData.createTimestamp
                                )} ${alertsData.createTimestampTimezone}`
                              : "-"}
                          </td>
                          <td className="sram-text-font sram-table-td">
                            {alertsData.ticketNumber
                              ? alertsData.ticketNumber
                              : "-"}
                          </td>
                          <td className="sram-text-font sram-table-td">
                            {alertsData.ticketPriority &&
                            alertsData.ticketPriority !== null ? (
                              alertsData.ticketPriority
                            ) : (
                              <span> - </span>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <div className="sram-no-data">
                      {SiteMsgResProps.body.content.noIssuesFound}
                    </div>
                  )}
                </tbody>
              </Table>
            </Col>
            {/* </Row> */}
          </WithLoading>
        </div>
      </Modal>
    );
  }
}

export default SiteDashboardAlertsModal;
