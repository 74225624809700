export const DeviceMsgResProps = {
  head: {
    title: {},
  },
  body: {
    content: {
      devices: "Devices",
      viewDevice: "View Device Details",
      editDevice: "Edit Device",
      deleteDevice: "Delete Device",
      noDevicesFound: "No Devices found",
      deviceView: "Device Details",
      noDataFound: "No issues found"
    },
    form: {
      dispenser: {
        label: "Dispenser",
      },
      sideANumber: {
        label: "SideA Number",
      },
      sideBNumber: {
        label: "SideB Number",
      },
      manufacturerName: {
        label: "Manufacturer Name",
        optionNone: "None"
      },
      modelName: {
        label: "Model Name",
        optionNone: "None"
      },
      imei: {
        label: "IMEI",
      },
      deviceId: {
        label: "Device Id"
      },
      macAddress: {
        label: "MAC Address",
      },
      meter: {
        label: "Meter"
      },
      grade: {
        label: "Grade"
      },
      gvrId: {
        label: "GVR ID"
      },
      site: {
        label: "Site"
      },
      siteName: {
        label: "Site Name"
      },
      fuelingSideA: {
        label: "Fueling SideA Number"
      },
      fuelingSideB: {
        label: "Fueling SideB Number"
      },
      twoWire: {
        label: "Two Wire"
      },
      warranty: {
        label: "Warranty"
      },
      dispenserFlowType: {
        label: "Dispenser Flow Type"
      },
      twoWireSideA: {
        label: "Two Wire SideA Number"
      },
      twoWireSideB: {
        label: "Two Wire SideB Number"
      },
      meter1Name: {
        label: "Meter 1 Name",
      },
      meter2Name: {
        label: "Meter 2 Name",
      },
      meter3Name: {
        label: "Meter 3 Name",
      },
      meter4Name: {
        label: "Meter 4 Name",
      },
      meter5Name: {
        label: "Meter 5 Name",
      },
      meter6Name: {
        label: "Meter 6 Name",
      },
      meter7Name: {
        label: "Meter 7 Name",
      },
      meter8Name: {
        label: "Meter 8 Name",
      },
      grade1Name: {
        label: "Grade 1 Name",
      },
      grade2Name: {
        label: "Grade 2 Name",
      },
      grade3Name: {
        label: "Grade 3 Name",
      },
      grade4Name: {
        label: "Grade 4 Name",
      },
      grade5Name: {
        label: "Grade 5 Name",
      },
      grade6Name: {
        label: "Grade 6 Name",
      },
      grade7Name: {
        label: "Grade 7 Name",
      },
      grade8Name: {
        label: "Grade 8 Name",
      },
      yes: { label: "Yes" },
      no: { label: "No" },
      close: { label: "Close" },
      retry: { label: "Retry" },
    },
    notification: {
      error: {
        message: "An error has occured.",
        imei: {
          empty: "IMEI is required",
          invalid: "IMEI is not valid.",
        },
        macAddress: {
          empty: "MAC Address is required",
          invalid: "MAC Address is not valid.",
        },
        deviceId: {
          empty: "Device Id is required",
          invalid: "Device Id is not valid.",
        },
        manufacturerName: {
          empty: "Manufacturer Name is required",
        },
        modelName: {
          empty: "Model Name is required",
        },
        dispenser: {
          empty: "Dispenser is required",
        },
        warranty: {
          empty: "Warranty is required",
          invalid: "Warranty is not valid.",
        },
      },
      confirmation: {
        addDeviceTitle: "Are you sure you want to add the Device ?",
        editDeviceTitle: "Are you sure you to update the Device details ?",
        deleteDeviceTitle: "Are you sure you want to delete the Device ?"
      },
      success: {
        addDevice: "Device is added successfully.",
        editDevice: "Device is modified successfully.",
        deleteDevice: "Device is deleted successfully."
      },
    },
  },
};
