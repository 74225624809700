import axios from "axios";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";

const actionFileName = "site-actions.js";

// List of  Sites
export function listSites(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "";
    if (queryData) {
      queryParams = "?" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listSites()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// List of  Companies
export function listCompanies(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/user-companies?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listCompanies()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// site recent alerts
export function recentAlerts(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId +"/alerts?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">recentAlerts()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

//site dispensers list
export function dispensersList(siteId,cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">dispensersList()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Get Site
export function siteView(siteId, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">siteView()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

//site dashboard list
export function componentStatus(siteId,cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/component-status",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">dashboardStatus()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// site Dispensers Door Alerts
export function doorAlerts(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/door-events?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">doorAlerts()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// site Dispensers pump Errors
export function pumpErrors(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/pump-errors?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">pumpErrors()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// site Dispensers performance data over runs
export function overRuns(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/performance-data/overrun?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">overRuns()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// site Dispensers performance data stand alones
export function standAlones(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/performance-data/standalone?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">standAlones()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// site Dispensers pump Status
export function pumpStatus(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/pump-status?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">pumpStatus()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// // site Dispensers crind Status
// export function componentStatus(siteId,queryData, cancelToken) {
//   return new Promise(function (resolve, reject) {    
//     axios
//       .get(
//         AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/crind-status?" + queryData,
//         cancelToken
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         if (axios.isCancel(err)) {
//           logMessage("warn", actionFileName + ">componentStatus()", err.message);
//         } else {
//           reject(err.response);
//         }
//       });
//   });
// }

// site Dispensers connectivity Status
export function connectivityStatus(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) { 
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/connectivity-status?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">connectivityStatus()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// site dispensers alert-tickets

export function tickets(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/tickets?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">tickets()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// site dispensers warranty status

export function warranty(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/warranty-status?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">tickets()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// site dispensers softwareVersion status

export function softwareVersion(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/software-items?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">tickets()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// site Dispensers paper-out/jam Status
export function paperOutJam(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/printer-paper-out-jam?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">paperOutJam()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// site Dispensers paper-out/jam Status
export function flowRate(siteId,queryData, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/flow-rate-status?" + queryData,
        // flow-status?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">flowRate()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// 
export function transactionStatus(siteId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {    
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites/" + siteId + "/dispensers/no-transaction?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">transactionStatus()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}