import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import axios from "axios";
import WithLoading from "../core/helpers/with-loading";
import { AppMsgResProps } from "../core/messages/app-properties";
import { AppConfigProps } from "../core/settings/app-config";
import { refreshToken } from "../core/actions/identity-actions";
import { AppURLProps } from "../core/settings/app-urls";
import AppUtils from "../core/helpers/app-utils";
import AppHeader from "./app-header";
import AppSidebar from "./app-sidebar";
import AppFooter from "./app-footer";
import AppRoutes from "../routes/app-routes";
import ErrorPageNotFound from "../views/error-page-not-found";
import { manageError } from "../core/actions/common-actions";
import {
  getProfile,
  listUserRoleAppParts,
} from "../core/actions/identity-actions";
import { PENDING_REQUEST } from "../store/constants";

const mapStateToProps = (state) => ({
  ...state,
  pendingRequest: state.layoutSettings.pendingRequest,
});


export const pendingRequest = (payload) => {
  return async function (dispatch) {
    try {
      dispatch({
        type: PENDING_REQUEST,
        payload: payload,
      });
    } catch (err) {
      throw Error(err);
    }
  };
}

class FullLayout extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.state = {
      isOpen: false,
      width: window.innerWidth,
      isPageDataFetched: false,
      identityProfile: null,
      userRoleAppParts: null,
      userRoleAppRoutes: null,
    };

    this.props.history.listen((location, action) => {
      if (this.state.isPageDataFetched) {
        if (
          window.innerWidth < 767 &&
          document.getElementById("main-wrapper") &&
          document
            .getElementById("main-wrapper")
            .className.indexOf("show-sidebar") !== -1
        ) {
          document
            .getElementById("main-wrapper")
            .classList.toggle("show-sidebar");
        }
      }
    });
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook, Applies when loading or resizing App                           */
  /*--------------------------------------------------------------------------------*/
  async componentDidMount() {
    this._isMounted = true;
    await this.loadPageData();
    // const tokenData = AppUtils.getIdentityTokenData();
    this.interval = setInterval(() =>
      this.CheckTimeIntervals(),
      (5 * 60000))
  }
  /*--------------------------------------------------------------------------------*/
  /*Function that handles sidebar, changes when resizing App                        */
  /*--------------------------------------------------------------------------------*/
  updateDimensions() {
    let element = document.getElementById("main-wrapper");
    this.setState({
      width: window.innerWidth,
    });
    switch (this.props.layoutSettings.activeSidebarType) {
      case "full":
      case "iconbar":
        if (this.state.width < 1170) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
          element.classList.add("mini-sidebar");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.layoutSettings.activeSidebarType
          );
          element.classList.remove("mini-sidebar");
        }
        break;

      case "overlay":
        if (this.state.width < 767) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.layoutSettings.activeSidebarType
          );
        }
        break;

      default:
    }
  }
  /*--------------------------------------------------------------------------------*/
  /*Life Cycle Hook                                                                 */
  /*--------------------------------------------------------------------------------*/
  componentWillUnmount() {
    window.removeEventListener("load", this.updateDimensions);
    window.removeEventListener("resize", this.updateDimensions);
    clearInterval(this.interval);
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }
  /*--------------------------------------------------------------------------------*/
  /*Time Interval For refresh Token                                                             */
  /*--------------------------------------------------------------------------------*/
  CheckTimeIntervals = async () => {
    const tokenData = AppUtils.getIdentityTokenData();
    if (
      tokenData &&
      tokenData.userUUID &&
      tokenData.username &&
      tokenData.email &&
      tokenData.tokenExpiry >= Date.now() / 1000
    ) {
      AppUtils.setRequestHeaders();
      if (
        tokenData.tokenExpiry - parseInt(Date.now() / 1000) <=
        AppConfigProps.identitySession.expiryCheckRemainingSeconds
      ) {
        await this.props.pendingRequest(true)
        await refreshToken(this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.result &&
            res.data.result.AuthenticationResult
          ) {
            await this.props.pendingRequest(false)
          } else {
            await this.props.pendingRequest(false)
          }
        })
        .catch(async (err) => {
          await this.props.pendingRequest(false)
        });
      } else {
        await this.props.pendingRequest(false)
      }
      await this.props.pendingRequest(false)
    }
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ identityProfile: null });
    await this.setStateAsync({ userRoleAppParts: null });
    await this.setStateAsync({ userRoleAppRoutes: null });

    await getProfile(this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ identityProfile: res.data.result });
        } else {
          this.props.history.push(AppURLProps.login);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await listUserRoleAppParts(this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.populateParts(res.data.records);
            await this.populateRoutes();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
    window.addEventListener("load", this.updateDimensions);
    window.addEventListener("resize", this.updateDimensions);
  };

  populateParts = async (appParts) => {
    if (appParts && appParts.length > 0) {
      let userRoleAppParts = [];
      await appParts.forEach((part) => {
        let actionCode =
          part.moduleCode + "-" + part.featureCode + "-" + part.actionCode;
        if (userRoleAppParts.indexOf(actionCode) === -1)
          userRoleAppParts.push(actionCode);
      });
      await this.setStateAsync({ userRoleAppParts: userRoleAppParts });
    }
  };

  populateRoutes = async () => {
    if (AppRoutes && AppRoutes.length > 0) {
      let routes = [];

      await AppRoutes.forEach(async (route1) => {
        const route1Obj = { ...route1 };
        if (route1.child && route1.child.length > 0) {
          route1Obj.child = [];
          let route2Items = [];
          await route1.child.forEach(async (route2) => {
            const route2Obj = { ...route2 };

            if (route2.subchild && route2.subchild.length > 0) {
              route2Obj.subchild = [];
              let route3Items = [];
              await route2.subchild.forEach(async (route3) => {
                const route3Obj = {
                  ...route3,
                };
                if (
                  route3.partCode &&
                  (await this.isPartAccess(route3.partCode))
                ) {
                  route3Items.push(route3Obj);
                }
              });

              if (route3Items.length > 0) {
                route2Obj.subchild = route3Items;
                route2Items.push(route2Obj);
              }
            } else {
              if (
                route2.partCode &&
                (await this.isPartAccess(route2.partCode))
              ) {
                route2Items.push(route2Obj);
              }
            }
          });
          if (route2Items.length > 0) {
            route1Obj.child = route2Items;
            routes.push(route1Obj);
          }
        } else {
          if (route1.partCode && (await this.isPartAccess(route1.partCode))) {
            routes.push(route1Obj);
          }
        }
      });
      this.setStateAsync({ userRoleAppRoutes: routes });
    }
  };

  isPartAccess = async (routePartCodes, userPartCodes) => {
    let accessFlag = false;
    if (routePartCodes && this.state.userRoleAppParts) {
      for (let i = 0; i < routePartCodes.length; i++) {
        if (this.state.userRoleAppParts.indexOf(routePartCodes[i]) !== -1) {
          accessFlag = true;
          break;
        }
      }
    }
    return accessFlag;
  };

  render() {
    /*--------------------------------------------------------------------------------*/
    /* Theme Setting && Layout Options wiil be Change From Here                       */
    /*--------------------------------------------------------------------------------*/
    return (
      <WithLoading
        isPageDataFetched={this.state.isPageDataFetched}
        type="layout"
      >
        <div
          id="main-wrapper"
          dir={this.props.layoutSettings.activeDir}
          data-theme={this.props.layoutSettings.activeTheme}
          data-layout={this.props.layoutSettings.activeThemeLayout}
          data-sidebartype={this.props.layoutSettings.activeSidebarType}
          data-sidebar-position={this.props.layoutSettings.activeSidebarPos}
          data-header-position={this.props.layoutSettings.activeHeaderPos}
          data-boxed-layout={this.props.layoutSettings.activeLayout}
        >
          {/*--------------------------------------------------------------------------------*/}
          {/* Header                                                                         */}
          {/*--------------------------------------------------------------------------------*/}
          <AppHeader
            {...this.props}
            identityProfile={this.state.identityProfile}
          />
          {/*--------------------------------------------------------------------------------*/}
          {/* Sidebar                                                                        */}
          {/*--------------------------------------------------------------------------------*/}
          <AppSidebar {...this.props} routes={this.state.userRoleAppRoutes} />

          {/*--------------------------------------------------------------------------------*/}
          {/* Page Main-Content                                                              */}
          {/*--------------------------------------------------------------------------------*/}
          <div className="page-wrapper d-block">
            <div className="container-fluid">
              {this.state.userRoleAppRoutes ? (
                <Switch>
                  {this.state.userRoleAppRoutes.map((route, key) => {
                    if (route.navlabel) {
                      return null;
                    } else if (route.collapse) {
                      return route.child.map((route2, key2) => {
                        if (route2.collapse) {
                          return route2.subchild.map((route3, key3) => {
                            return (
                              <Route
                                path={route3.path}
                                component={route3.component}
                                key={key3}
                                exact={route3.exactPath}
                              />
                            );
                          });
                        }
                        return (
                          <Route
                            path={route2.path}
                            component={route2.component}
                            key={key2}
                            exact={route2.exactPath}
                          />
                        );
                      });
                    } else if (route.redirect) {
                      return (
                        <Redirect
                          from={route.path}
                          to={route.pathTo}
                          key={key}
                        />
                      );
                    } else {
                      return (
                        <Route
                          path={route.path}
                          component={route.component}
                          key={key}
                          exact={route.exactPath}
                        />
                      );
                    }
                  })}
                  <Route path="*" component={ErrorPageNotFound} />
                </Switch>
              ) : null}
            </div>
            <AppFooter />
          </div>
          {/*--------------------------------------------------------------------------------*/}
          {/* Customizer from which you can set all the Layout Settings                      */}
          {/*--------------------------------------------------------------------------------*/}
          {/** 
        <Customizer
          boxedTheme={this.boxedTheme}
          rtl={this.rtl}
          headerPosition={this.headerPosition}
          sidebarPosition={this.sidebarPosition}
        />
        */}
        </div>
      </WithLoading>
    );
  }
}

const mapActionToProps = {
  pendingRequest
};

export default connect(mapStateToProps, mapActionToProps)(FullLayout);
