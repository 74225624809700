import React, { Component } from "react";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import PageUtils from "../../core/helpers/page-utils";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { isPagePartPrivileged } from "../../core/actions/identity-actions";

class Home extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcPortalSiteView = "CP-SIT-SVIW";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      PageUtils.scrollToTop();
      await this.loadPageData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });

    const pcSiteView = `${this._pcPortalSiteView}`;
    const pcSiteViewPriv = await isPagePartPrivileged(
      pcSiteView,
      this._cancelToken
    );
    if (pcSiteViewPriv === true) {
      this.props.history.push("/portal/sites");
    }

    await this.setStateAsync({ isPageDataFetched: true });
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcPortalSiteView}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space"></div>
        </WithLoading>
      </>
    );
  }
}

export default Home;
