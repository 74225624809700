import React, { Component } from "react";
import { NavLink as RRNavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Nav, Collapse } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

const mapStateToProps = (state) => ({
  ...state,
});

class AppSidebar extends Component {
  constructor(props) {
    super(props);
    this.expandLogo = this.expandLogo.bind(this);
    this.activeRoute.bind(this);
    this.state = {
      // TODO: need to fix
      /*
      authentication: this.activeRoute("/authentication") !== "" ? true : false,
      uicomponents: this.activeRoute("/ui-components") !== "" ? true : false,
      samplepages: this.activeRoute("/sample-pages") !== "" ? true : false,
      dashboardpages: this.activeRoute("/dahboards") !== "" ? true : false,
      iconsPages: this.activeRoute("/icons") !== "" ? true : false,
      formlayoutPages: this.activeRoute("/form-layouts") !== "" ? true : false,
      formpickerPages: this.activeRoute("/form-pickers") !== "" ? true : false,
      */
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*To Expand SITE_LOGO With Sidebar-Menu on Hover                                  */
  /*--------------------------------------------------------------------------------*/
  expandLogo() {
    document.getElementById("logobg").classList.toggle("expand-logo");
  }
  /*--------------------------------------------------------------------------------*/
  /*Verifies if routeName is the one active (in browser input)                      */
  /*--------------------------------------------------------------------------------*/
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1
      ? "selected"
      : "";
  }

  render() {
    return (
      <aside
        className="left-sidebar"
        id="sidebarbg"
        data-sidebarbg={this.props.layoutSettings.activeSidebarBg}
        onMouseEnter={this.expandLogo}
        onMouseLeave={this.expandLogo}
      >
        <div className="scroll-sidebar">
          {/*--------------------------------------------------------------------------------*/}
          {/* Sidebar Menus will go here                                                */}
          {/*--------------------------------------------------------------------------------*/}
          {this.props.routes ? (
            <PerfectScrollbar className="sidebar-nav">
              <Nav id="sidebarnav">
                {this.props.routes.map((route, key) => {
                  if (route.redirect) {
                    return null;
                  } else if (route.navlabel) {
                    return (
                      <li className="nav-small-cap" key={key}>
                        <i className={route.icon}></i>
                        <span className="hide-menu">{route.name}</span>
                      </li>
                    );
                  } else if (route.collapse && route.navDisplay) {
                    // 
                    let firstdd = {};
                    firstdd[route["state"]] = !this.state[route.state];
                    return (
                      /*--------------------------------------------------------------------------------*/
                      /* Menus wiil be goes here                                                        */
                      /*--------------------------------------------------------------------------------*/
                      <li
                        className={
                          this.activeRoute(route.path) + " sidebar-item"
                        }
                        key={key}
                      >
                        <span
                          data-toggle="collapse"
                          className="sidebar-link has-arrow"
                          aria-expanded={this.state[route.state]}
                          onClick={() => this.setState(firstdd)}
                        >
                          <i className={route.icon} />
                          <span className="hide-menu">{route.name}</span>
                        </span>
                        {/*--------------------------------------------------------------------------------*/}
                        {/* Sub-Menus wiil be goes here                                                    */}
                        {/*--------------------------------------------------------------------------------*/}
                        <Collapse isOpen={this.state[route.state]}>
                          <ul className="first-level">
                            {route.child.map((route, key) => {
                              if (route.redirect) return null;
                              if (route.collapse && route.navDisplay) {
                                let seconddd = {};
                                seconddd[route["state"]] = !this.state[
                                  route.state
                                ];
                                return (
                                  <li
                                    className={
                                      this.activeRoute(route.path) +
                                      " sidebar-item"
                                    }
                                    key={key}
                                  >
                                    <span
                                      data-toggle="collapse"
                                      className="sidebar-link has-arrow"
                                      aria-expanded={this.state[route.state]}
                                      onClick={() => this.setState(seconddd)}
                                    >
                                      <i className={route.icon} />
                                      <span className="hide-menu">
                                        {route.name}
                                      </span>
                                    </span>
                                    {/*--------------------------------------------------------------------------------*/}
                                    {/* Sub-Menus wiil be goes here                                                    */}
                                    {/*--------------------------------------------------------------------------------*/}
                                    <Collapse isOpen={this.state[route.state]}>
                                      <ul className="second-level">
                                        {route.subchild.map((route, key) => {
                                          if (route.redirect) return null;
                                          if (route.navDisplay) {
                                            return (
                                              <li
                                                className={
                                                  this.activeRoute(route.path) +
                                                  " sidebar-item pl-0"
                                                }
                                                key={key}
                                              >
                                                <RRNavLink
                                                  to={route.path}
                                                  activeClassName="active"
                                                  className="sidebar-link"
                                                >
                                                  <i className={route.icon} />
                                                  <span className="hide-menu pl-3">
                                                    {route.name}
                                                  </span>
                                                </RRNavLink>
                                              </li>
                                            );
                                          } else {
                                            return null;
                                          }
                                        })}
                                      </ul>
                                    </Collapse>
                                  </li>
                                );
                              }
                              if (route.navDisplay) {
                                return (
                                  /*--------------------------------------------------------------------------------*/
                                  /* Adding Sidebar Item                                                            */
                                  /*--------------------------------------------------------------------------------*/
                                  <li
                                    className={
                                      this.activeRoute(route.path) +
                                      (route.pro ? " active active-pro" : "") +
                                      " sidebar-item"
                                    }
                                    key={key}
                                  >
                                    <RRNavLink
                                      to={route.path}
                                      className="sidebar-link"
                                      activeClassName="active"
                                    >
                                      <i className={route.icon} />
                                      <span className="hide-menu">
                                        {route.name}
                                      </span>
                                    </RRNavLink>
                                  </li>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </ul>
                        </Collapse>
                      </li>
                    );
                  } else {
                    if (route.navDisplay) {
                      return (
                        /*--------------------------------------------------------------------------------*/
                        /* Adding Sidebar Item                                                            */
                        /*--------------------------------------------------------------------------------*/
                        <li
                          className={
                            this.activeRoute(route.path) +
                            (route.pro ? " active active-pro" : "") +
                            " sidebar-item"
                          }
                          key={key}
                        >
                          <RRNavLink
                            to={route.path}
                            className="sidebar-link"
                            activeClassName="active"
                          >
                            <i className={route.icon} />
                            <span className="hide-menu">{route.name}</span>
                          </RRNavLink>
                        </li>
                      );
                    } else {
                      return null;
                    }
                  }
                })}
              </Nav>
            </PerfectScrollbar>
          ) : null}
        </div>
      </aside>
    );
  }
}
export default connect(mapStateToProps)(AppSidebar);
