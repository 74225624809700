import React, { Component } from "react";
import {
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
} from "reactstrap";
import axios from "axios";
import { AppMsgResProps } from "../messages/app-properties";
import { IdentityMsgResProps } from "../messages/identity-properties";
import { AppURLProps } from "../settings/app-urls";
import { logoutUser } from "../actions/identity-actions";

class Logout extends Component {
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  constructor(props) {
    super(props);
    this.handleFormLogout = this.handleFormLogout.bind(this);
  }

  async handleFormLogout(e) {
    e.preventDefault();

    await logoutUser(this._cancelToken);

    this.props.history.push(AppURLProps.home);
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.isOpenLogoutModal}
          toggle={this.props.toggleLogoutModal}
          size="md"
          backdrop="static"
          centered={false}
          className="sram-modal-content"
        >
          <ModalHeader>{IdentityMsgResProps.body.content.logout}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => e.preventDefault()} noValidate>
              <Label>
                {IdentityMsgResProps.body.content.logoutConfirmation}
              </Label>
            </Form>
          </ModalBody>
          <ModalFooter className="m-1 p-2">
            <Button
              type="button"
              className="sram-btn-blue mx-2"
              onClick={this.handleFormLogout}
            >
              {AppMsgResProps.body.form.yes.label}
            </Button>
            <Button
              type="button"
              className="sram-btn-white mx-2"
              onClick={this.props.toggleLogoutModal}
            >
              {AppMsgResProps.body.form.no.label}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default Logout;
