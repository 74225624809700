import React, { Component } from "react";
//import ReactDOMServer from "react-dom/server";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  Row,
  Col,
  Input,
  // NavLink,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { connect } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import SitesMap from "./sites-map";
import PageUtils from "../../core/helpers/page-utils";
import SiteUtils from "../helpers/site-utils";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { SiteMsgResProps } from "../messages/site-properties";
import { PortalURLProps } from "../settings/portal-urls";
import { manageError } from "../../core/actions/common-actions";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import {
  listSites,
  recentAlerts,
  listCompanies,
} from "../actions/site-actions";
import { getProfile } from "../../core/actions/identity-actions";
import markerBlue from "../../../assets/images/map/marker_blue.png";
import markerRed from "../../../assets/images/marker_red.png";
import markerYellow from "../../../assets/images/marker_yellow.png";
import markerGray from "../../../assets/images/marker_gray.png";
import DispenserUtils from "../helpers/dispenser-utils";
import ErrorDetailsModal from "./error-details-modal";
import { possibleFixes, checkToken } from "../actions/actions";
import AppUtils from "../../core/helpers/app-utils";
import { SITES_INFO } from "../../store/constants";
import Select from "react-select";

const mapStateToProps = (state) => ({
  ...state,
  sitesInfo: state.layoutSettings.sitesInfo,
});

export const sitesInfo = (payload) => {
  return async function (dispatch) {
    try {
      dispatch({
        type: SITES_INFO,
        payload: payload,
      });
    } catch (err) {
      throw Error(err);
    }
  };
};

class SitesHome extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcPortalSiteView = "CP-SIT-SVIW";
  _pcPortalViewAlerts = "CP-DAL-AVIW";
  _pcPortalViewDispensers = "CP-DSP-DVIW";
  _pcSiteViewErrorModal = "CP-ERC-EVIW";

  mapObj = null;
  siteMarkers = [];
  siteInfoWins = [];
  activeSiteInfoWin = null;
  siteBounds = null;

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isOpenSiteSumaryModal: false,
      userProfile: null,
      sites: null,
      siteList: null,
      searchSites: null,
      searchSitesKeyword: "",
      site: null,
      companies: null,
      recentAlerts: null,
      isrecentAlertsDataFetched: false,
      showSidePanel: false,
      lg: 9,
      isOpenErrorDetailsModal: false,
      isPossibleFixesDataFetched: false,
      possibleFixes: null,
      fixes: null,
      siteItem: null,
      partPrivileges: this.initPartPrivileges(),
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
    window.appHistory = this.props.history;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      PageUtils.scrollToTop();
      await this.loadPageData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ sites: null });
    await this.checkPartPrivilege();
    await this.setPartPrivileges();
    await this.setStateAsync({ userProfile: null });
    await getProfile(this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ userProfile: res.data.result });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    const queryData = "sortField=siteName&sortOrder=1";
    await listSites(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ sites: res.data.records });
            await this.setStateAsync({ siteList: res.data.records });
            await this.setStateAsync({ searchSites: res.data.records });
            await this.props.sitesInfo(res.data.records);
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.getUserCompanies();

    await this.setStateAsync({ isPageDataFetched: true });
  };

  getUserCompanies = async () => {
    const queryData = "userType=" + this.state.userProfile?.userType;
    await listCompanies(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            // const filteredCompanies = res.data.records?.filter(
            //   (record) => record.entityType === "C"
            // );
            await this.setStateAsync({ companies: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  initPartPrivileges = () => {
    return {
      [this._pcPortalViewAlerts]: false,
      [this._pcPortalSiteView]: false,
      [this._pcPortalViewDispensers]: false,
      [this._pcSiteViewErrorModal]: false,
    };
  };

  checkPartPrivilege = async () => {
    const partCode = [
      this._pcPortalSiteView,
      this._pcPortalViewAlerts,
      this._pcPortalViewDispensers,
      this._pcSiteViewErrorModal,
    ];
    const partPrivilege = await getPagePartsPrivilege(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(PortalURLProps.pageNotFound);
    }
  };

  setPartPrivileges = async () => {
    const partCodes = [
      this._pcPortalSiteView,
      this._pcPortalViewAlerts,
      this._pcPortalViewDispensers,
      this._pcSiteViewErrorModal,
    ];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  handleSearchSites = (e) => {
    const keyword = e.target.value;
    this.setState({ searchSitesKeyword: keyword });
    let sites = this.state.searchSites;
    if (keyword && keyword.trim().length > 0 && sites.length > 0) {
      const siteList = sites.filter((site) => {
        return (
          (site.siteName &&
            site.siteName.toLowerCase().search(keyword.toLowerCase()) !== -1) ||
          (site.siteNumber &&
            site.siteNumber.toString().search(keyword.toString()) !== -1) ||
          (site.streetAddress1 &&
            site.streetAddress1.toLowerCase().search(keyword.toLowerCase()) !==
              -1) ||
          (site.streetAddress2 &&
            site.streetAddress2.toLowerCase().search(keyword.toLowerCase()) !==
              -1) ||
          (site.city &&
            site.city.toLowerCase().search(keyword.toLowerCase()) !== -1) ||
          (site.state &&
            site.state.toLowerCase().search(keyword.toLowerCase()) !== -1) ||
          (site.zipCode &&
            site.zipCode.toLowerCase().search(keyword.toLowerCase()) !== -1)
        );
      });
      this.setState({ siteList: siteList });
    } else {
      this.setState({ siteList: sites });
    }
  };
  sidePannelClose = async () => {
    await this.setStateAsync({ showSidePanel: false, lg: 9 });
  };
  handleShowSiteSummary = async (siteId, site) => {
    await this.setStateAsync({ siteItem: site });
    // await this.setStateAsync({ site: site });
    // await this.handleShowSiteInfo(siteId, site);
    if (this.state.partPrivileges[this._pcPortalViewDispensers] === true) {
      await this.setStateAsync({ showSidePanel: true, lg: 6 });
      this.handleShowSiteInfo(siteId, site);
      if (this.state.partPrivileges[this._pcPortalViewAlerts] === true) {
        this.getRecentAlerts(siteId);
      }
    }
    // this.openSiteSummaryModal();
  };
  handleShowSiteInfo = async (siteId, site) => {
    this.setStateAsync({ site: site });
    if (siteId) {
      const siteInfoWin = await this.siteInfoWins.find((item) => {
        return item.siteId === siteId;
      });

      const siteMarker = await this.siteMarkers.find((item) => {
        return item.siteId === siteId;
      });

      if (siteInfoWin && siteMarker) {
        if (this.activeSiteInfoWin) this.activeSiteInfoWin.close();
        siteInfoWin.infoWin.open(this.mapObj, siteMarker.marker);
        this.activeSiteInfoWin = siteInfoWin.infoWin;
        this.mapObj.panTo(siteMarker.marker.getPosition());

        //this.mapObj.setCenter(siteMarker.marker.position);
      }
    }
  };

  handleMarkerBounce = async (siteId) => {
    const siteMarker = await this.siteMarkers.find((item) => {
      return item.siteId === siteId;
    });
    if (siteMarker) {
      siteMarker.marker.setAnimation(window.google.maps.Animation.BOUNCE);
      setTimeout(() => {
        siteMarker.marker.setAnimation(null);
      }, 400);
    }
  };

  getRecentAlerts = async (siteId) => {
    await checkToken(this.props, this._cancelToken);
    await this.setStateAsync({ isrecentAlertsDataFetched: false });
    await this.setStateAsync({
      recentAlerts: null,
    });
    await this.checkPartPrivilege();
    const queryData = "extractType=list&pageOffset=0&pageLimit=10";
    await recentAlerts(siteId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            recentAlerts: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isrecentAlertsDataFetched: true });
  };

  getSiteInfoWinContent = (site) => {
    let url = "";
    if (this.state.partPrivileges[this._pcPortalViewDispensers] === true) {
      url = PortalURLProps.siteDashboard.replace(":sid", site.siteId);

      // onClick="window.appHistory.push('${url}')"
      return `<div class="gm-info-window-content">
        <div class="font-medium pb-2 font-14 sram-black-clr">
          <a
            href="${url}"
            class="p-0 gm-info-window-link sram-black-clr"
            title="${SiteMsgResProps.body.content.viewSiteDashboard}"
          >
            ${site.siteName}
          </a>
        </div>
        <div className="font-mild sram-text-font">
          ${SiteMsgResProps.body.form.siteId.label} : ${site.siteNumber}
        </div>        
        <div className="font-mild sram-text-font">
          ${SiteUtils.getSiteAddress(site)}
        </div>
        <div className="font-mild sram-text-font">
          ${SiteUtils.getSiteAddress1(site)}
        </div>
      </div>`;
    } else {
      return `<div className="gm-info-window-content">
        <div class="font-medium pb-2 font-14">
          <div
            className="p-0 gm-info-window-link"
            title="${SiteMsgResProps.body.content.viewSiteDashboard}"
          >
            ${site.siteName}
          </div>
        </div>
        <div className="font-mild sram-text-font">
          ${SiteMsgResProps.body.form.siteId.label} : ${site.siteNumber}
        </div>
        <div className="font-mild sram-text-font">
          ${SiteUtils.getSiteAddress(site)}
        </div>
        <div className="font-mild sram-text-font">
          ${SiteUtils.getSiteAddress1(site)}
        </div>
      </div>`;
    }
  };

  closeSiteSummaryModal = async () => {
    await this.setStateAsync({
      isOpenSiteSumaryModal: false,
    });
  };

  openSiteSummaryModal = async () => {
    await this.setStateAsync({
      isOpenSiteSumaryModal: true,
    });
  };

  getAlert(obj) {
    if (obj.alertType === "PumpError") {
      return this.state.partPrivileges[this._pcSiteViewErrorModal] === true ? (
        <div
          className="sram-text-font sram-links font-mild sram-active"
          onClick={() => {
            this.handleShowErrorDeatils(obj.dispenserId, obj.dataId);
          }}
        >
          {obj.alertName.toUpperCase()}
        </div>
      ) : (
        <div className="sram-text-font font-mild">
          {obj.alertName.toUpperCase()}
        </div>
      );
    } else if (obj.alertType === "DoorEvent") {
      return <div className="sram-text-font">{obj.alertName}</div>;
    } else if (obj.alertType === "Alert") {
      return (
        <div className="sram-text-font">
          {DispenserUtils.capitalize(obj.alertName)}
        </div>
      );
    }
  }

  getDoorStatus(obj) {
    if (obj.alertType === "DoorEvent") {
      if (obj.alertDescription === "Open") {
        return (
          <span className="sram-site-door-status-open sram-text-font">
            {obj.alertDescription}
          </span>
        );
      } else {
        return (
          <span className="sram-site-door-status-close sram-text-font">
            {obj.alertDescription}
          </span>
        );
      }
    } else if (obj.alertType === "PumpError") {
      return <span className="sram-text-font">{obj.siteName}</span>;
    } else if (obj.alertType === "Alert") {
      return (
        <span className="sram-text-font">
          {DispenserUtils.capitalize(obj.severity)}
        </span>
      );
    }
  }

  getDescription(obj) {
    if (obj.alertType === "PumpError" || obj.alertType === "Alert") {
      return (
        <span className="sram-text-font">
          {obj.alertDescription === "paper out" ||
          obj.alertDescription === "paper jam" ||
          obj.alertDescription === "jammed" ||
          obj.alertDescription === "paper low"
            ? "Receipt Paper Error"
            : DispenserUtils.capitalize(obj.alertDescription)}
        </span>
      );
    }
  }

  handleShowErrorDeatils = async (dispenserId, pumpErrorLogId) => {
    this.getPossibleFixes(dispenserId, pumpErrorLogId);
    this.openErrorDetailsModal();
  };

  getPossibleFixes = async (dispenserId, pumpErrorLogId) => {
    await checkToken(this.props, this._cancelToken);
    await this.setStateAsync({ isPossibleFixesDataFetched: false });
    await this.setStateAsync({
      possibleFixes: null,
      fixes: null,
    });
    await possibleFixes(
      this.state.siteItem.siteId,
      dispenserId,
      pumpErrorLogId,
      this._cancelToken
    )
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({
            possibleFixes: res.data.result,
            fixes: [res.data.result],
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPossibleFixesDataFetched: true });
  };

  closeErrorDetailsModal = async () => {
    await this.setStateAsync({
      isOpenErrorDetailsModal: false,
    });
  };

  openErrorDetailsModal = async () => {
    await this.setStateAsync({
      isOpenErrorDetailsModal: true,
    });
  };
  possibleFixesCheck = async () => {
    await this.setStateAsync({ fixes: null });
    await this.setStateAsync({ possibleFixes: null });
  };
  handleChange = async (e) => {
    await this.setStateAsync({ showSidePanel: false, lg: 9 });
    if (e === null) {
      await this.setState({
        siteList: this.state.sites,
        searchSitesKeyword: "",
      });
      await this.setStateAsync({ searchSites: this.state.sites });
    } else {
      await this.setState({ searchSitesKeyword: "" });
      let organizationName = e.organizationName;
      let filterObj = [];
      await this.state.sites.forEach((element) => {
        element.companyDetails.forEach((obj) => {
          if (organizationName === obj.company_name) {
            filterObj.push(element);
          }
        });
      });
      await this.setState({ siteList: filterObj });
      await this.setStateAsync({ searchSites: filterObj });
    }
  };
  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-full">
            <Row noGutters={true}>
              <Col sm={12} md={4} lg={3}>
                <PerfectScrollbar className="sram-sites-home-panel">
                  <div className="sram-sites-home-panel-search">
                    <Select
                      placeholder=" Select Companies"
                      options={this.state.companies}
                      getOptionLabel={(option) => option?.organizationName}
                      getOptionValue={(option) => option?.organizationName}
                      defaultValue={
                        this.state.companies &&
                        this.state.companies.length === 1
                          ? this.state.companies && this.state.companies[0]
                          : ""
                      }
                      isDisabled={
                        this.state.companies &&
                        this.state.companies.length === 1
                          ? true
                          : false
                      }
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ced4da",
                          primary: "#ced4da",
                        },
                      })}
                      styles={{
                        option: (base) => ({
                          ...base,
                          color: "#000000",
                        }),
                      }}
                      isClearable
                      onChange={async (e) => {
                        await this.handleChange(e);
                      }}
                    />
                  </div>
                  <div className="sram-sites-home-panel-search">
                    <Input
                      type="text"
                      className="form-control"
                      placeholder={SiteMsgResProps.body.form.searchSites.label}
                      value={this.state.searchSitesKeyword}
                      onChange={this.handleSearchSites}
                    />
                  </div>
                  {this.state.siteList && this.state.siteList.length > 0 ? (
                    <div>
                      {this.state.siteList.map((site, index) => {
                        const siteAddress = SiteUtils.getSiteAddress(site);
                        const siteAddress1 = SiteUtils.getSiteAddress1(site);
                        return (
                          <div
                            key={`site-panel-item-${index}`}
                            onClick={() =>
                              this.handleShowSiteSummary(site.siteId, site)
                            }
                            onMouseOver={() =>
                              this.handleShowSiteInfo(site.siteId, site)
                            }
                          >
                            <div
                              className={
                                site === this.state.siteItem
                                  ? "sram-sites-home-panel-item-active"
                                  : "sram-sites-home-panel-item"
                              }
                              id={`site-item-${site.siteId}`}
                            >
                              <div className="font-mild pb-1">
                                {site.siteName}
                              </div>
                              <div className="sram-text-font">
                                {SiteMsgResProps.body.form.siteId.label} :{" "}
                                {site.siteNumber}
                              </div>

                              <div className="sram-text-font">
                                {siteAddress}
                              </div>
                              <div className="sram-text-font">
                                {siteAddress1}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="sram-sites-home-panel-item sram-text-font">
                      {SiteMsgResProps.body.content.noIssuesFound}
                    </div>
                  )}
                </PerfectScrollbar>
              </Col>
              {this.state.showSidePanel === true &&
              this.state.partPrivileges[this._pcPortalViewDispensers] ===
                true ? (
                <Col sm={12} md={4} lg={3}>
                  <Row className="sram-padding-7">
                    <Col sm={12} md={10} lg={10}>
                      <PopoverHeader className="sram-bg-blue-2">
                        {this.state.siteItem.siteName}
                      </PopoverHeader>
                    </Col>
                    <Col sm={12} md={2} lg={2}>
                      <span
                        className="pull-right sram-times-adj"
                        onClick={() => this.sidePannelClose()}
                      >
                        <i className="fa fa-times"></i>
                      </span>
                    </Col>
                  </Row>
                  <PerfectScrollbar className="sram-site-item-home-panel sram-bg-white">
                    <Row>
                      <Col sm={12} md={12} lg={12} className="sram-text-font">
                        {SiteMsgResProps.body.form.siteId.label} :{" "}
                        {this.state.siteItem.siteNumber}
                      </Col>

                      <Col sm={12} md={12} lg={12} className="sram-text-font">
                        {SiteUtils.getSiteAddress(this.state.siteItem)}
                      </Col>
                      <Col sm={12} md={12} lg={12} className="sram-text-font">
                        {SiteUtils.getSiteAddress1(this.state.siteItem)}
                      </Col>
                      {this.state.siteItem.sitePhone !== "" &&
                      this.state.siteItem.sitePhone !== null &&
                      this.state.siteItem.sitePhone.trim().length === 10 ? (
                        <Col sm={12} md={12} lg={12} className="sram-text-font">
                          {/* {this.state.siteItem.sitePhone} */}(
                          {this.state.siteItem.sitePhone.slice(0, 3)}){" "}
                          {this.state.siteItem.sitePhone.slice(3, 6)}-
                          {this.state.siteItem.sitePhone.slice(6, 10)}
                        </Col>
                      ) : null}
                      {this.state.siteItem.siteNote !== "" &&
                      this.state.siteItem.siteNote !== null &&
                      this.state.siteItem.siteNote.trim().length > 0 ? (
                        <Col
                          sm={12}
                          md={12}
                          lg={12}
                          className="sram-text-font font-mild pt-2"
                          style={{ cursor: "pointer" }}
                        >
                          <span
                            id={`site-item-${this.state.siteItem.siteId}`}
                            className="sram-active"
                            style={{ textDecoration: "none" }}
                          >
                            <i className="fa fa-file" aria-hidden="true"></i>
                            &nbsp;<span>Note</span>
                          </span>
                          <UncontrolledPopover
                            trigger="legacy"
                            placement="right"
                            target={`site-item-${this.state.siteItem.siteId}`}
                            // popperClassName="sram-sites-home-panel-popover"
                          >
                            {/* <PopoverHeader className="sram-bg-blue-2">
                                {site.siteName}
                              </PopoverHeader> */}
                            <PopoverBody>
                              <div>
                                {DispenserUtils.capitalize(
                                  this.state.siteItem.siteNote
                                )}
                              </div>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </Col>
                      ) : null}
                    </Row>
                    <div>
                      <RRNavLink
                        to={PortalURLProps.siteDashboard.replace(
                          ":sid",
                          this.state.siteItem.siteId
                        )}
                        className="sram-sites-home-panel-link w-100"
                      >
                        {SiteMsgResProps.body.content.viewSiteDashboard}
                      </RRNavLink>
                    </div>
                    {this.state.partPrivileges[this._pcPortalViewAlerts] ===
                    true ? (
                      <>
                        <h5 className="font-mild pt-2">
                          {SiteMsgResProps.body.content.recentAlerts}
                        </h5>
                        <WithLoading
                          isPageDataFetched={
                            this.state.isrecentAlertsDataFetched
                          }
                          type="page"
                          size="sm"
                        >
                          {this.state.recentAlerts &&
                          this.state.recentAlerts.length > 0 ? (
                            this.state.recentAlerts.map((obj, index) => {
                              return (
                                <Col
                                  className="pb-2"
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  key={index}
                                >
                                  <Row className="sram-bg-blue-2">
                                    <Col
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      className="pb-1 pt-2"
                                    >
                                      <div className="sram-text-font">
                                        Dispenser {obj.dispenserName}
                                      </div>
                                    </Col>
                                    <Col
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      className="pb-1 pt-2"
                                    >
                                      <div
                                        className="sram-text-font"
                                        title={
                                          obj.alertName === "pumpcontrolnode"
                                            ? `Dispenser Create Timestamp: ${
                                                obj.dispenserCreateTimestamp
                                                  ? AppUtils.getDateTime(
                                                      obj.dispenserCreateTimestamp
                                                    )
                                                  : "-"
                                              }`
                                            : null
                                        }
                                      >
                                        {AppUtils.getDateTime(
                                          obj.deviceTimestamp
                                        )}
                                        {/* {obj.deviceTimestampTimezone} */}
                                      </div>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} className="pb-1">
                                      {this.getAlert(obj)}
                                    </Col>
                                    <Col sm={12} md={6} lg={6} className="pb-1">
                                      <div className="sram-text-font">
                                        {this.getDoorStatus(obj)}
                                      </div>
                                    </Col>
                                    <Col sm={12} md={6} lg={6} className="pb-1">
                                      <div className="sram-text-font">
                                        Side - {obj.sideLabel}
                                      </div>
                                    </Col>
                                    <Col
                                      sm={12}
                                      md={12}
                                      lg={12}
                                      className="pb-1"
                                    >
                                      {this.getDescription(obj)}
                                    </Col>
                                    {/* <Col sm={12} md={12} lg={12} className="pb-1">
                                  <div className="sram-text-font">
                                    {obj.alertDescription}
                                  </div>
                                </Col> */}
                                  </Row>
                                </Col>
                              );
                            })
                          ) : (
                            <div className="sram-no-data">
                              {SiteMsgResProps.body.content.noIssuesFound}
                            </div>
                          )}
                        </WithLoading>
                      </>
                    ) : null}
                  </PerfectScrollbar>
                </Col>
              ) : null}
              <Col sm={12} md={8} lg={this.state.lg}>
                <SitesMap
                  sitesLength={this.state.siteList}
                  onMapLoad={(map) => {
                    this.mapObj = map;
                    this.siteMarkers = [];
                    this.siteInfoWins = [];
                    this.activeSiteInfoWin = null;
                    this.siteBounds = null;
                    var bounds = new window.google.maps.LatLngBounds();

                    if (this.state.sites && this.state.sites.length > 0) {
                      this.state.siteList &&
                        this.state.siteList.map((site) => {
                          let mapIcon = markerBlue;
                          if (
                            site.siteStatus &&
                            site.siteStatus.toLowerCase() === "red"
                          ) {
                            mapIcon = markerRed;
                          }
                          if (
                            site.siteStatus &&
                            site.siteStatus.toLowerCase() === "yellow"
                          ) {
                            mapIcon = markerYellow;
                          }
                          if (
                            site.siteStatus &&
                            site.siteStatus.toLowerCase() === "gray"
                          ) {
                            mapIcon = markerGray;
                          }
                          if (site.locationLatitude && site.locationLongitude) {
                            let position = {
                              lat: parseFloat(site.locationLatitude),
                              lng: parseFloat(site.locationLongitude),
                            };
                            let siteMarker = new window.google.maps.Marker({
                              position: position,
                              icon: mapIcon,
                              title: site.siteName,
                            });
                            this.siteMarkers.push({
                              siteId: site.siteId,
                              marker: siteMarker,
                            });
                            siteMarker.setMap(map);

                            bounds.extend(position);

                            let siteInfoWin = new window.google.maps.InfoWindow(
                              {
                                content: this.getSiteInfoWinContent(site),
                              }
                            );
                            this.siteInfoWins.push({
                              siteId: site.siteId,
                              infoWin: siteInfoWin,
                            });

                            siteMarker.addListener("click", () => {
                              this.handleShowSiteInfo(site.siteId);
                            });
                            siteMarker.addListener("mouseover", () => {
                              this.handleShowSiteInfo(site.siteId);
                            });
                          }

                          return null;
                        });
                      this.placeBounds = bounds;
                      map.fitBounds(bounds);
                      map.panToBounds(bounds);
                    }
                  }}
                ></SitesMap>
              </Col>
            </Row>
            {this.state.partPrivileges[this._pcSiteViewErrorModal] === true &&
            this.state.recentAlerts ? (
              <ErrorDetailsModal
                isOpenErrorDetailsModal={this.state.isOpenErrorDetailsModal}
                closeErrorDetailsModal={this.closeErrorDetailsModal}
                possibleFixes={this.state.possibleFixes}
                fixes={this.state.fixes}
                isPageDataFetched={this.state.isPossibleFixesDataFetched}
                possibleFixesCheck={this.possibleFixesCheck}
              ></ErrorDetailsModal>
            ) : null}
            {/* {this.state.site ? (
              <SiteSummaryModal
                isOpenSiteSumaryModal={this.state.isOpenSiteSumaryModal}
                closeSiteSummaryModal={this.closeSiteSummaryModal}
                site={this.state.site}
                recentAlerts={this.state.recentAlerts}
                isPageDataFetched={this.state.isrecentAlertsDataFetched}
              ></SiteSummaryModal>
            ) : null} */}
          </div>
        </WithLoading>
      </>
    );
  }
}

const mapActionToProps = {
  sitesInfo,
};

export default connect(mapStateToProps, mapActionToProps)(SitesHome);
