export default class OnboardingUtils {
    static getSideNumbers(data) {
        // let sideNumbers = Array.from(sn.split(','),Number);
        let numbers = ["1/2","3/4","5/6","7/8","9/10","11/12","13/14","15/16","17/18","19/20","21/22","23/24","25/26","27/28","29/30","31/32","33/34","35/36","37/38","39/40","41/42","43/44","45/46","47/48","49/50"];
        if (data) {
            let sideNumbers= data.map((obj)=>{
                return obj.dispenserName
            })
            let arr = numbers.filter( function(n) { return !this.has(n) }, new Set(sideNumbers) );
            return arr
        }
        else{
            return numbers
        }
    }
  }
  