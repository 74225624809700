export const AlertMsgResProps = {
  head: {
    title: {
      alertManagement: "Alert Management",
      newAlerts: "New Alerts",
      wipTickets: "WIP Tickets",
      ignoredalerts: "Ignored Alerts",
      dispatchTickets: "Dispatch Tickets",
      closedTickets: "Closed Tickets",
      pendingClosedTickets: "Pending Close Tickets",
      createTicket: "Create Ticket",
      ignoreAlert: "Ignore Alert",
      siteAddress: "Site Address",
      siteName: "Site Name",
      siteId: " GVR ID",
      ticketid: "Ticket Id",
      itemName: "Item Name",
      alertStatus: "Alert Status",
      componentName: "Component Name",
      dispenserName: "Dispenser Name",
      eventStatus: "Event Status",
      eventCause: "Event Cause",
      eventTimeStamp: "Event Timestamp",
      closeTimestamp: "Close Timestamp",
      itemStatus: "Item Status",
      cause: "Cause",
      timestamp: "Timestamp",
      warranty: "Warranty",
      warrantystatus: "Warranty Status",
      purchaseOrder: "Purchase Order",
      priority: "Priority",
      warrantydate: "Warranty-Expiry Date",
      clientApproval: "Client Approval",
      clientNotification: "Client Notification",
      ignoredBy: "Ignored By",
      ignoredTimeStamp: "Ignored Timestamp",
      side: "Side",
    },
  },
  body: {
    content: {
      issueidentified: "Issues identified at this site are provided below.",
      noIssuesFound: "No issues found.",
      noDataFound: "No issues found.",
      confirmModelMessage:
        " Are you sure you want to ignore the selected Alerts ?",
      ticketsCantCreateMess:
        "Ticket cannot be created as the selected alerts are from multiple sites.",
      singleTicket:
        "Please select the alerts of a single site to create a ticket.",
    },
    form: {
      commets: "Comments",
      submit: "Submit",
      confirm: "Confirm",
      cancel: "Cancel",
      approve: "Approve",
      reject: "Reject",
      approveEmail: "Approve and send email",
      seekEmailApproval: "Send email and Seek approval",
      newTicket: "Create New Ticket",
      addTicket: "Add to Ticket:",
      sno: "S. No",
      emailSentTO: "Email Sent To",
      emailType: "Email Type",
      createTimeStamp: "Create Timestamp",
      emailStatus: "Email Sent Status",
      emailConfirmation: "Email Confirm Status",
      noDataFound: "No Data found.",
    },
    notification: {
      approveComments: {
        empty: "Comments is required.",
        invalid: "Comments is not valid.",
        duplicate: "Comments already exists.",
      },
      email: {
        empty: "Email is required.",
        invalid: "Email is not valid.",
        duplicate: "Email already exists.",
      },
      subject: {
        empty: "Subject is required.",
        invalid: "Subject is not valid.",
        duplicate: "Subject already exists.",
      },
      message: {
        empty: "Message is required.",
        invalid: "Message is not valid.",
        duplicate: "Message already exists.",
      },
      regards: {
        empty: "Regards is required.",
        invalid: "Regards is not valid.",
        duplicate: "Regards already exists.",
      },
      error: {
        message: "An error has occured.",
        purchaseOrder: "Purchase Order already exists.",
      },
      ticket: {},
      datePicker: {
        fromDate: "From Date is required.",
        toDate: "To Date is required.",
      },
    },
  },
  footer: {
    close: {
      label: "Close",
    },
  },
};
