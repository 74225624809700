import React, { Component } from "react";
import { Card, CardTitle, CardBody, Table } from "reactstrap";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import PageListSearchField from "../../core/helpers/page-list-search-field";
import { SetupURLProps } from "../settings/setup-urls";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { RoleMsgResProps } from "../messages/role-properties";
import { manageError } from "../../core/actions/common-actions";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import { listRoles, searchRoles } from "../actions/role-actions";
import PageListPagination from "../../core/helpers/page-list-pagination";
import PageListLimitSelect from "../../core/helpers/page-list-limit-select";
import PageListHeaderColumn from "../../core/helpers/page-list-header-column";

class RoleList extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcRoleView = "AS-ROL-RVIW";
  _pcRoleAdd = "AS-ROL-RADD";
  _pcRoleEdit = "AS-ROL-REDT";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      partPrivileges: this.initPartPrivileges(),
      rolesAllRecords: null,
      rolesDisplayRecords: null,
      rolesPageRecords: null,
      searchValue: "",
      searchKeyword: "",
      pageOffset: AppConfigProps.listPage.pageOffset.defaultValue,
      pageLimit: AppConfigProps.listPage.pageLimit.defaultValue,
      sortedColumn: "roleName",
      sortedOrder: AppConfigProps.listPage.sortOrder.defaultValue,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      PageUtils.scrollToTop();
      await this.loadPageData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({
      rolesAllRecords: null,
      rolesDisplayRecords: null,
      rolesPageRecords: null,
    });
    await this.setPartPrivileges();
    if (this.state.partPrivileges[this._pcRoleView] === true) {
      const queryData = "sortField=roleName&sortOrder=1";
      await listRoles(queryData, this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            if (res.data.records.length > 0) {
              await this.setStateAsync({ rolesAllRecords: res.data.records });
              let sortedRecords = await PageUtils.sortListRecords(
                this.state.rolesAllRecords,
                this.state.sortedColumn,
                this.state.sortedOrder
              );
              await this.setStateAsync({ rolesDisplayRecords: sortedRecords });
              let slicedRecords = await PageUtils.sliceListRecords(
                this.state.rolesDisplayRecords,
                this.state.pageOffset,
                this.state.pageLimit
              );
              await this.setStateAsync({ rolesPageRecords: slicedRecords });
            }
          } else {
            await manageError(res, this.props.history);
          }
        })
        .catch(async (err) => {
          await manageError(err, this.props.history);
        });
    }
    await this.setStateAsync({ isPageDataFetched: true });
  };

  initPartPrivileges = () => {
    return {
      [this._pcRoleView]: false,
      [this._pcRoleAdd]: false,
      [this._pcRoleEdit]: false,
    };
  };

  setPartPrivileges = async () => {
    const partCodes = [this._pcRoleView, this._pcRoleAdd, this._pcRoleEdit];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  getTableColumnHeadings = () => {
    return [
      {
        columnId: "roleName",
        columnType: "string",
        columnLabel: RoleMsgResProps.body.form.roleName.label,
        isSortable: true,
        columnWidth: "70%",
      },
      {
        columnId: "roleType",
        columnType: "string",
        columnLabel: RoleMsgResProps.body.form.roleType.label,
        isSortable: true,
        columnWidth: "30%",
      },
    ];
  };

  handleSortChange = async (sortColumn, sortOrder, sortType) => {
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.rolesDisplayRecords,
      sortColumn,
      sortOrder,
      sortType
    );
    await this.setStateAsync({ rolesDisplayRecords: sortedRecords });
    await this.setStateAsync({
      sortedColumn: sortColumn,
      sortedOrder: sortOrder,
    });

    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.rolesDisplayRecords,
      pageOffset,
      this.state.pageLimit
    );
    await this.setStateAsync({ rolesPageRecords: slicedRecords });
    await this.setStateAsync({ pageOffset: pageOffset });
  };

  handlePageLimitChange = async (pageLimit) => {
    if (pageLimit > 0) {
      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.rolesDisplayRecords,
        pageOffset,
        pageLimit
      );
      await this.setStateAsync({ rolesPageRecords: slicedRecords });
      await this.setStateAsync({ pageLimit: pageLimit });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handlePageOffsetChange = async (pageOffset) => {
    if (pageOffset >= 0) {
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.rolesDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );
      await this.setStateAsync({ rolesPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchChange = async (e) => {
    await this.setStateAsync({ searchValue: e.target.value });
  };

  handleSearchSubmit = async (e) => {
    if (this.state.rolesAllRecords && this.state.rolesAllRecords.length > 0) {
      let searchKeyword = this.state.searchValue;
      await this.setStateAsync({ searchKeyword: searchKeyword });
      let searchedRecords = await searchRoles(
        this.state.rolesAllRecords,
        this.state.searchKeyword
      );

      let sortedRecords = await PageUtils.sortListRecords(
        searchedRecords,
        this.state.sortedColumn,
        this.state.sortedOrder
      );
      await this.setStateAsync({ rolesDisplayRecords: sortedRecords });

      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.rolesDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );

      await this.setStateAsync({ rolesPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchCancel = async (e) => {
    e.preventDefault();
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.rolesAllRecords,
      this.state.sortedColumn,
      this.state.sortedOrder
    );
    await this.setStateAsync({ rolesDisplayRecords: sortedRecords });
    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.rolesDisplayRecords,
      this.state.pageOffset,
      this.state.pageLimit
    );
    await this.setStateAsync({ rolesPageRecords: slicedRecords });

    await this.setStateAsync({ searchKeyword: "", searchValue: "" });
    await this.setStateAsync({ pageOffset: pageOffset });
  };

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <Card className="sram-page-list">
              <CardTitle>
                <span>{RoleMsgResProps.body.content.roles}</span>
                <span className="float-right">
                  {this.state.partPrivileges[this._pcRoleAdd] === true
                    ? PageDisplay.showAddLink(
                      "L",
                      SetupURLProps.roles.addRole,
                      RoleMsgResProps.body.content.addRole
                    )
                    : null}
                </span>
              </CardTitle>
              <CardBody>
                <>
                  {this.state.rolesAllRecords &&
                    this.state.rolesAllRecords.length > 0 ? (
                    <div className="sram-list-head">
                      <span className="float-left col-sm-6 col-md-4 col-lg-4 pad-left-0">
                        <PageListSearchField
                          searchValue={this.state.searchValue}
                          searchKeyword={this.state.searchKeyword}
                          handleSearchChange={this.handleSearchChange}
                          handleSearchSubmit={this.handleSearchSubmit}
                          handleSearchCancel={this.handleSearchCancel}
                        />
                      </span>
                      <span className="float-right">
                        {PageDisplay.getListPageRecordsDisplayInfo(
                          this.state.rolesDisplayRecords
                            ? this.state.rolesDisplayRecords.length
                            : 0,
                          this.state.pageOffset,
                          this.state.pageLimit
                        )}
                      </span>
                    </div>
                  ) : null}
                  <div className="sram-list-main">
                    {this.state.rolesPageRecords &&
                      this.state.rolesPageRecords.length > 0 ? (
                      <Table
                        responsive
                        hover
                        size="sm"
                        className="sram-table-main"
                      >
                        <thead>
                          <tr>
                            {this.getTableColumnHeadings().map((column) => {
                              return (
                                <PageListHeaderColumn
                                  {...column}
                                  key={column.columnId}
                                  sortedColumn={this.state.sortedColumn}
                                  sortedOrder={this.state.sortedOrder}
                                  handleSortChange={this.handleSortChange}
                                />
                              );
                            })}
                            <th width="100"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.rolesPageRecords &&
                            this.state.rolesPageRecords.map((role, index) => {
                              let keyString = { key: `key-rolelist-${index}` };
                              return (
                                <tr {...keyString}>
                                  <td>{role.roleName}</td>
                                  <td>
                                    {
                                      RoleMsgResProps.body.form.roleType
                                        .options[role.roleType]
                                    }
                                  </td>
                                  <td className="text-right">
                                    {role.mainStatus !== 1 ? (
                                      <>
                                        {this.state.partPrivileges[
                                          this._pcRoleEdit
                                        ] === true
                                          ? PageDisplay.showEditLink(
                                            "S",
                                            SetupURLProps.roles.editRole.replace(
                                              ":rid",
                                              role.roleId
                                            ),
                                            RoleMsgResProps.body.content
                                              .editRole
                                          )
                                          : null}
                                      </>
                                    ) : null}

                                    {this.state.partPrivileges[
                                      this._pcRoleView
                                    ] === true
                                      ? PageDisplay.showViewLink(
                                        "S",
                                        SetupURLProps.roles.viewRole.replace(
                                          ":rid",
                                          role.roleId
                                        ),
                                        RoleMsgResProps.body.content.viewRole
                                      )
                                      : null}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    ) : (
                      <>
                        <div>{RoleMsgResProps.body.content.noRolesFound}</div>
                      </>
                    )}
                  </div>
                  {this.state.rolesPageRecords &&
                    this.state.rolesPageRecords.length ? (
                    <div className="sram-list-foot">
                      <div className="float-left">
                        <PageListLimitSelect
                          pageLimit={this.state.pageLimit}
                          pageOffset={this.state.pageOffset}
                          totalRecords={this.state.rolesDisplayRecords.length}
                          handlePageLimitChange={this.handlePageLimitChange}
                        />
                      </div>
                      <div className="float-right">
                        <PageListPagination
                          pageLimit={this.state.pageLimit}
                          pageOffset={this.state.pageOffset}
                          totalRecords={this.state.rolesDisplayRecords.length}
                          handlePageOffsetChange={this.handlePageOffsetChange}
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              </CardBody>
            </Card>
          </div>
        </WithLoading>
      </>
    );
  }
}

export default RoleList;
