import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Label,
  FormGroup,
  UncontrolledCollapse,
  Input
} from "reactstrap";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import WithLoading from "../../core/helpers/with-loading";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import PageUtils from "../../core/helpers/page-utils";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import PageDisplay from "../../core/helpers/page-display";
import { deviceItem, updateDevice } from "../actions/device-actions";
import { DeviceMsgResProps } from "../messages/device-properties";
import { ConfigURLProps } from "../settings/config-urls";
import DeviceUtils from "../helpers/device-utils";
import {
  manufacturersList,
  modelsList,
  meterTypes,
  gradeTypes,
} from "../actions/onboarding-actions";
// import { ConfigDataProps } from "../settings/config-data";
import ConfirmModal from "../../core/helpers/confirm-modal";
import { ConfigValidations } from "../settings/config-validations";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import { OnboardingMsgResProps } from "../messages/onboarding-properties";

class DeviceEdit extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcDeviceEdit = "AT-DVC-DEDT";
  _pcViewModels = "AT-MDL-MVIW";
  _pcViewManufactures = "AT-MFG-MVIW";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      partPrivileges: this.initPartPrivileges(),
      deviceItem: null,
      formInputFields: null,
      manufacturersList: null,
      modelsList: null,
      models: null,
      isModelsFetched: true,
      tempDeviceObj: null,
      gradeObj: null,
      meterObj: null,
      meterTypes: null,
      gradeTypes: null,
      collapse: false,
      isChecked: false
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    const dispenserId = this.props.match.params.did;
    await this.loadPageData(dispenserId);
    window.appHistory = this.props.history;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const dispenserId = this.props.match.params.did;
    if (dispenserId !== prevProps.match.params.did) {
      PageUtils.scrollToTop();
      await this.loadPageData(dispenserId);
    }
  }

  loadPageData = async (dispenserId) => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ deviceItem: null });
    await this.checkPartPrivilege();
    await this.setPartPrivileges();
    await deviceItem(dispenserId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          if (res.data.result) {
            await this.setStateAsync({
              gradeObj: DeviceUtils.getGradeObjects(res.data.result.grades),
            });
            await this.setStateAsync({
              meterObj: DeviceUtils.getMeterObjects(res.data.result.meters),
            });
            await this.setStateAsync({ deviceItem: res.data.result });
            await this.setStateAsync({
              formInputFields: await this.initFormInputFields(),
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    if (this.state.partPrivileges[this._pcViewManufactures] === true) {
      await manufacturersList(this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            if (res.data.records.length > 0) {
              await this.setStateAsync({ manufacturersList: res.data.records });
            }
          } else {
            await manageError(res, this.props.history);
          }
        })
        .catch(async (err) => {
          await manageError(err, this.props.history);
        });
    }
    if (this.state.partPrivileges[this._pcViewModels] === true) {
      await modelsList(this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            if (res.data.records.length > 0) {
              await this.setStateAsync({ modelsList: res.data.records });
              await this.handleGetModels(this.state.deviceItem.manufacturerId);
              if (this.state.deviceItem.dispenserFlowType === 1){
                await this.setStateAsync({ isChecked: true })
              }
              else{
                await this.setStateAsync({ isChecked: false })
              }
            }
          } else {
            await manageError(res, this.props.history);
          }
        })
        .catch(async (err) => {
          await manageError(err, this.props.history);
        });
    }
    await meterTypes(this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ meterTypes: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await gradeTypes(this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ gradeTypes: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await this.setStateAsync({ isPageDataFetched: true });
  };

  handleGetModels = async (manufacturerId) => {
    await this.setStateAsync({ models: null });
    const keyValue = manufacturerId;
    if (keyValue !== "" && keyValue !== null) {
      await this.setStateAsync({ isModelsFetched: false });
      if (keyValue && this.state.modelsList.length > 0) {
        const models = this.state.modelsList.filter((obj) => {
          return obj.manufacturerId === parseInt(keyValue);
        });
        await this.setStateAsync({ models: models });
      }
      await this.setStateAsync({ isModelsFetched: true });
    }
  };

  initFormInputFields = () => {
        
    return {
      macAddress: this.state.deviceItem.macAddress
        ? this.state.deviceItem.macAddress
        : "",
      manufacturerId: this.state.deviceItem.manufacturerId
        ? this.state.deviceItem.manufacturerId
        : "",
      manufacturerName: this.state.deviceItem.manufacturerName
        ? this.state.deviceItem.manufacturerName
        : "",
      modelId: this.state.deviceItem.modelId
        ? this.state.deviceItem.modelId
        : "",
      modelName: this.state.deviceItem.modelName
        ? this.state.deviceItem.modelName
        : "",
      warranty: this.state.deviceItem.warranty
        ? this.state.deviceItem.warranty
        : "",
      fuelingSideA: this.state.deviceItem.fuelingSideA
        ? this.state.deviceItem.fuelingSideA
        : "",
      fuelingSideB: this.state.deviceItem.fuelingSideB
        ? this.state.deviceItem.fuelingSideB
        : "",
      twoWire: this.state.deviceItem.twoWire
        ? this.state.deviceItem.twoWire
        : "",
      twoWireSideA: this.state.deviceItem.twoWireSideA
        ? this.state.deviceItem.twoWireSideA
        : "",
      twoWireSideB: this.state.deviceItem.twoWireSideB
        ? this.state.deviceItem.twoWireSideB
        : "",
      meter1: this.state.meterObj.meterNumber1,
      meter2: this.state.meterObj.meterNumber2,
      meter3: this.state.meterObj.meterNumber3,
      meter4: this.state.meterObj.meterNumber4,
      meter5: this.state.meterObj.meterNumber5,
      meter6: this.state.meterObj.meterNumber6,
      meter7: this.state.meterObj.meterNumber7,
      meter8: this.state.meterObj.meterNumber8,
      grade1: this.state.gradeObj.gradeNumber1,
      grade2: this.state.gradeObj.gradeNumber2,
      grade3: this.state.gradeObj.gradeNumber3,
      grade4: this.state.gradeObj.gradeNumber4,
      grade5: this.state.gradeObj.gradeNumber5,
      grade6: this.state.gradeObj.gradeNumber6,
      grade7: this.state.gradeObj.gradeNumber7,
      grade8: this.state.gradeObj.gradeNumber8
    };
  };

  initPartPrivileges = () => {
    return {
      [this._pcDeviceEdit]: false,
      [this._pcViewModels]: false,
      [this._pcViewManufactures]: false,
    };
  };

  checkPartPrivilege = async () => {
    const partCode = [
      this._pcDeviceEdit,
      this._pcViewModels,
      this._pcViewManufactures,
    ];
    const partPrivilege = await getPagePartsPrivilege(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(ConfigURLProps.pageNotFound);
    }
  };

  setPartPrivileges = async () => {
    const partCodes = [
      this._pcDeviceEdit,
      this._pcViewModels,
      this._pcViewManufactures,
    ];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  formValidationSchema = () => {
    const schema = {
      macAddress: Yup.string()
        .trim()
        .required(DeviceMsgResProps.body.notification.error.deviceId.empty)
        .matches(
          ConfigValidations.validations.macAddress,
          DeviceMsgResProps.body.notification.error.deviceId.invalid
        ),
      manufacturerId: Yup.string()
        .trim()
        .required(
          DeviceMsgResProps.body.notification.error.manufacturerName.empty
        ),
      modelId: Yup.string().required(
        DeviceMsgResProps.body.notification.error.modelName.empty
      ),
      fuelingSideA: Yup.string()
        .trim()
        .required(OnboardingMsgResProps.body.notification.error.fuelingSideA.empty)
        .matches(
          ConfigValidations.validations.fuelingSideA,
          OnboardingMsgResProps.body.notification.error.fuelingSideA.invalid
        ),
      fuelingSideB: Yup.string()
        .trim()
        .required(OnboardingMsgResProps.body.notification.error.fuelingSideB.empty)
        .matches(
          ConfigValidations.validations.fuelingSideB,
          OnboardingMsgResProps.body.notification.error.fuelingSideB.invalid
        ),
      // twoWire: Yup.string()
      // .trim()
      // .required(OnboardingMsgResProps.body.notification.error.twoWire.empty)
      // .matches(
      //   ConfigValidations.validations.twoWire,
      //   OnboardingMsgResProps.body.notification.error.twoWire.invalid
      // ),
      twoWireSideA: Yup.string()
        .trim()
        .required(OnboardingMsgResProps.body.notification.error.twoWireSideA.empty)
        .matches(
          ConfigValidations.validations.twoWireSideA,
          OnboardingMsgResProps.body.notification.error.twoWireSideA.invalid
        ),
      twoWireSideB: Yup.string()
        .trim()
        .required(OnboardingMsgResProps.body.notification.error.twoWireSideB.empty)
        .matches(
          ConfigValidations.validations.twoWireSideB,
          OnboardingMsgResProps.body.notification.error.twoWireSideB.invalid
        ),
    };
    return schema;
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  handleConfirmation = async (values, helpers) => {
    this._helpers = helpers;
    let dispenserFlowType = null
    if (this.state.isChecked){
      dispenserFlowType = 1
    }
    else{
      dispenserFlowType = 0
    }
    const deviceObj = {
      manufacturerId: values.manufacturerId,
      modelId: values.modelId,
      dispenserImage: "",
      macAddress: values.macAddress,
      fuelingSideA: values.fuelingSideA,
      fuelingSideB: values.fuelingSideB,
      warranty: values.warranty,
      twoWireSideA: values.twoWireSideA,
      twoWireSideB: values.twoWireSideB,
      meter1: values.meter1,
      meter2: values.meter2,
      meter3: values.meter3,
      meter4: values.meter4,
      meter5: values.meter5,
      meter6: values.meter6,
      meter7: values.meter7,
      meter8: values.meter8,
      grade1: values.grade1,
      grade2: values.grade2,
      grade3: values.grade3,
      grade4: values.grade4,
      grade5: values.grade5,
      grade6: values.grade6,
      grade7: values.grade7,
      grade8: values.grade8,
      dispenserFlowType: dispenserFlowType
    };
    await this.setStateAsync({ tempDeviceObj: deviceObj });
    this.openConfirmModal();
  };

  handleSubmit = async () => {
    const deviceObj = this.state.tempDeviceObj;
    const helpers = this._helpers;
    this.closeConfirmModal();
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }
    const dispenserId = this.props.match.params.did;
    await updateDevice(dispenserId, deviceObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = [
            "S",
            DeviceMsgResProps.body.notification.success.editDevice,
          ];
          if (res.data.result) {
            await this.setStateAsync({ deviceItem: res.data.result });
            await this.setStateAsync({
              formInputFields: await this.initFormInputFields(),
            });
          }
          if (this._isMounted) {
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          DeviceMsgResProps.body.notification.error.message,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);

          if (err.data && err.data.errors && err.data.errors.length > 0) {
            const errors = err.data.errors;
            errors.forEach((error) => {
              if (error.element && error.message && error.location === "body") {
                helpers.setFieldError(error.element, error.message);
              }
            });
          }
          PageUtils.scrollToTop();
        }
      });
    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  ckeckDispenserFlow = async (event) => {
    var checked = event.target.checked;
    await this.setStateAsync({isChecked: checked})
  }

  showCollapse() {
    this.setStateAsync({ collapse: !this.state.collapse });
  }

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <Card className="sram-page-list">
              <CardTitle>
                <span>{DeviceMsgResProps.body.content.editDevice}</span>
                <span className="float-right">
                  {PageDisplay.showGoBackLink(
                    "L",
                    ConfigURLProps.devices,
                    this.props.history
                  )}
                </span>
              </CardTitle>
              <CardBody>
                <div className="sram-list-main">
                  <Formik
                    initialValues={this.state.formInputFields}
                    validationSchema={Yup.object().shape(
                      this.formValidationSchema()
                    )}
                    onSubmit={(values, helpers) =>
                      this.handleConfirmation(values, helpers)
                    }
                  >
                    {(formik) => (
                      <Form id="dispenserForm" {...formEvent}>
                        <div className="sram-form-main">
                          <div>
                            {formik.status &&
                              Array.isArray(formik.status) &&
                              formik.status.length === 2 &&
                              (formik.status[0] === "E" ||
                                formik.status[0] === "S") ? (
                              <Row>
                                <Col xs={12}>
                                  {formik.status[0] === "E"
                                    ? PageDisplay.showErrorNotification(
                                      formik.status[1]
                                    )
                                    : PageDisplay.showSuccessNotification(
                                      formik.status[1]
                                    )}
                                </Col>
                              </Row>
                            ) : null}
                          </div>
                          <div>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                {PageDisplay.getFormFieldLabelValue(
                                  DeviceMsgResProps.body.form.gvrId.label,
                                  this.state.deviceItem.siteNumber
                                )}
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                {PageDisplay.getFormFieldLabelValue(
                                  DeviceMsgResProps.body.form.siteName.label,
                                  this.state.deviceItem.siteName
                                )}
                              </Col>
                            </Row>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                {PageDisplay.getFormFieldLabelValue(
                                  DeviceMsgResProps.body.form.dispenser.label,
                                  "Dispenser " +
                                  this.state.deviceItem.dispenserName
                                )}
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="macAddress">
                                    {/* {DeviceMsgResProps.body.form.macAddress.label} */}
                                    Device Id
                                  </Label>
                                  <Field
                                    name="macAddress"
                                    type="text"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.macAddress,
                                      formik.touched.macAddress
                                    )}
                                  />
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "macAddress"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="manufacturerId">
                                    {
                                      DeviceMsgResProps.body.form
                                        .manufacturerName.label
                                    }
                                  </Label>
                                  <Field
                                    name="manufacturerId"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.manufacturerId,
                                      formik.touched.manufacturerId
                                    )}
                                    value={formik.values.manufacturerId}
                                    onChange={async (e) => {
                                      let manufacturerId = e.target.value;
                                      formik.setFieldValue(
                                        "manufacturerId",
                                        manufacturerId
                                      );
                                      this.handleGetModels(manufacturerId);
                                      formik.setFieldValue("modelName", "");
                                    }}
                                  // onChange={this.handleGetModels.bind(this)}
                                  >
                                    <option value=""></option>
                                    {this.state.manufacturersList &&
                                      this.state.manufacturersList.length > 0
                                      ? this.state.manufacturersList.map(
                                        (obj, index) => {
                                          return (
                                            <option
                                              value={obj.manufacturerId}
                                              key={index}
                                            >
                                              {obj.manufacturerName}
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "manufacturerId"
                                  )}
                                </FormGroup>
                              </Col>

                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="modelId">
                                    {
                                      DeviceMsgResProps.body.form.modelName
                                        .label
                                    }
                                  </Label>
                                  <WithLoading
                                    isPageDataFetched={
                                      this.state.isModelsFetched
                                    }
                                    type="page"
                                    size="sm"
                                  >
                                    <Field
                                      name="modelId"
                                      as="select"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.modelId,
                                        formik.touched.modelId
                                      )}
                                    // value={formik.values.modelId}
                                    >
                                      <option value=""></option>
                                      {this.state.models &&
                                        this.state.models.length > 0
                                        ? this.state.models.map((obj, i) => {
                                          return (
                                            <option
                                              value={obj.modelId}
                                              key={i}
                                            >
                                              {obj.modelName}
                                            </option>
                                          );
                                        })
                                        : null}
                                    </Field>
                                  </WithLoading>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "modelId"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="warranty">
                                    {
                                      OnboardingMsgResProps.body.form
                                        .warranty.label
                                    }
                                  </Label>
                                  <Field
                                    name="warranty"
                                    type="date"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.warranty,
                                      formik.touched.warranty
                                    )}
                                  />                                  
                                  
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="fuelingSideA">
                                    {
                                      OnboardingMsgResProps.body.form
                                        .fuelingSideA.label
                                    }
                                  </Label>
                                  <Field
                                    name="fuelingSideA"
                                    type="text"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.fuelingSideA,
                                      formik.touched.fuelingSideA
                                    )}
                                  />
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "fuelingSideA"
                                  )}

                                </FormGroup>
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="fuelingSideB">
                                    {
                                      OnboardingMsgResProps.body.form.fuelingSideB
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="fuelingSideB"
                                    type="text"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.fuelingSideB,
                                      formik.touched.fuelingSideB
                                    )}
                                  />
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "fuelingSideB"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>                            

                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="twoWireSideA">
                                    {
                                      OnboardingMsgResProps.body.form
                                        .twoWireSideA.label
                                    }
                                  </Label>
                                  <Field
                                    name="twoWireSideA"
                                    type="text"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.twoWireSideA,
                                      formik.touched.twoWireSideA
                                    )}
                                  />
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "twoWireSideA"
                                  )}

                                </FormGroup>
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="twoWireSideB">
                                    {
                                      OnboardingMsgResProps.body.form.twoWireSideB
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="twoWireSideB"
                                    type="text"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.twoWireSideB,
                                      formik.touched.twoWireSideB
                                    )}
                                  />
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "twoWireSideB"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="meter1">
                                    {
                                      DeviceMsgResProps.body.form.meter1Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="meter1"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.meter1,
                                      formik.touched.meter1
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.meterTypes &&
                                      this.state.meterTypes.length > 0
                                      ? this.state.meterTypes.map(
                                        (meter, index) => {
                                          return (
                                            <option
                                              value= {meter? "Meter 1" + "(" + meter + ")":"Meter 1" }
                                              key={index}
                                            >
                                               Meter 1 { meter? "(" + meter + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "meter1Name"
                                  )}
                                </FormGroup>
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="meter2">
                                    {
                                      DeviceMsgResProps.body.form.meter2Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="meter2"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.meter2,
                                      formik.touched.meter2
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.meterTypes &&
                                      this.state.meterTypes.length > 0
                                      ? this.state.meterTypes.map(
                                        (meter, index) => {
                                          return (
                                            <option
                                              value=  {meter? "Meter 2" + "(" + meter + ")":"Meter 2" }
                                              key={index}
                                            >
                                               Meter 2 { meter? "(" + meter + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "meter2Name"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="meter3">
                                    {
                                      DeviceMsgResProps.body.form.meter3Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="meter3"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.meter3,
                                      formik.touched.meter3
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.meterTypes &&
                                      this.state.meterTypes.length > 0
                                      ? this.state.meterTypes.map(
                                        (meter, index) => {
                                          return (
                                            <option
                                              value=  {meter? "Meter 3" + "(" + meter + ")":"Meter 3" }
                                              key={index}
                                            >
                                               Meter 3 { meter? "(" + meter + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "meter3Name"
                                  )}
                                </FormGroup>
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="meter4">
                                    {
                                      DeviceMsgResProps.body.form.meter4Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="meter4"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.meter4,
                                      formik.touched.meter4
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.meterTypes &&
                                      this.state.meterTypes.length > 0
                                      ? this.state.meterTypes.map(
                                        (meter, index) => {
                                          return (
                                            <option
                                              value=  {meter? "Meter 4" + "(" + meter + ")":"Meter 4" }
                                              key={index}
                                            >
                                               Meter 4 { meter? "(" + meter + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "meter4Name"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="meter5">
                                    {
                                      DeviceMsgResProps.body.form.meter5Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="meter5"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.meter5,
                                      formik.touched.meter5
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.meterTypes &&
                                      this.state.meterTypes.length > 0
                                      ? this.state.meterTypes.map(
                                        (meter, index) => {
                                          return (
                                            <option
                                              value=  {meter? "Meter 5" + "(" + meter + ")":"Meter 5" }
                                              key={index}
                                            >
                                               Meter 5 { meter? "(" + meter + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "meter5Name"
                                  )}
                                </FormGroup>
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="meter6">
                                    {
                                      DeviceMsgResProps.body.form.meter6Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="meter6"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.meter6,
                                      formik.touched.meter6
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.meterTypes &&
                                      this.state.meterTypes.length > 0
                                      ? this.state.meterTypes.map(
                                        (meter, index) => {
                                          return (
                                            <option
                                              value=  {meter? "Meter 6" + "(" + meter + ")":"Meter 6" }
                                              key={index}
                                            >
                                               Meter 6 { meter? "(" + meter + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "meter6Name"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="meter7">
                                    {
                                      DeviceMsgResProps.body.form.meter7Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="meter7"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.meter7,
                                      formik.touched.meter7
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.meterTypes &&
                                      this.state.meterTypes.length > 0
                                      ? this.state.meterTypes.map(
                                        (meter, index) => {
                                          return (
                                            <option
                                              value=  {meter? "Meter 7" + "(" + meter + ")":"Meter 7" }
                                              key={index}
                                            >
                                               Meter 7{ meter? "(" + meter + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "meter7Name"
                                  )}
                                </FormGroup>
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="meter8">
                                    {
                                      DeviceMsgResProps.body.form.meter8Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="meter8"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.meter8,
                                      formik.touched.meter8
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.meterTypes &&
                                      this.state.meterTypes.length > 0
                                      ? this.state.meterTypes.map(
                                        (meter, index) => {
                                          return (
                                            <option
                                              value= {meter? "Meter 8" + "(" + meter + ")":"Meter 8" }
                                              key={index}
                                            >
                                              Meter 8 { meter? "(" + meter + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "meter8Name"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="grade1">
                                    {
                                      DeviceMsgResProps.body.form.grade1Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="grade1"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.grade1,
                                      formik.touched.grade1
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.gradeTypes &&
                                      this.state.gradeTypes.length > 0
                                      ? this.state.gradeTypes.map(
                                        (grade, index) => {
                                          return (
                                            <option
                                              value= {grade? "Grade 1" + "(" + grade + ")":"Grade 1" }
                                              key={index}
                                            >
                                               Grade 1 { grade? "(" + grade + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "grade1Name"
                                  )}
                                </FormGroup>
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="grade2">
                                    {
                                      DeviceMsgResProps.body.form.grade2Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="grade2"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.grade2,
                                      formik.touched.grade2
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.gradeTypes &&
                                      this.state.gradeTypes.length > 0
                                      ? this.state.gradeTypes.map(
                                        (grade, index) => {
                                          return (
                                            <option
                                              value= {grade? "Grade 2" + "(" + grade + ")":"Grade 2" }
                                              key={index}
                                            >
                                              Grade 2 { grade? "(" + grade + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "grade2Name"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="grade3">
                                    {
                                      DeviceMsgResProps.body.form.grade3Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="grade3"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.grade3,
                                      formik.touched.grade3
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.gradeTypes &&
                                      this.state.gradeTypes.length > 0
                                      ? this.state.gradeTypes.map(
                                        (grade, index) => {
                                          return (
                                            <option
                                              value= {grade? "Grade 3" + "(" + grade + ")":"Grade 3" }
                                              key={index}
                                            >
                                              Grade 3 { grade? "(" + grade + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "grade3Name"
                                  )}
                                </FormGroup>
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="grade4">
                                    {
                                      DeviceMsgResProps.body.form.grade4Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="grade4"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.grade4,
                                      formik.touched.grade4
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.gradeTypes &&
                                      this.state.gradeTypes.length > 0
                                      ? this.state.gradeTypes.map(
                                        (grade, index) => {
                                          return (
                                            <option
                                              value= {grade? "Grade 4" + "(" + grade + ")":"Grade 4" }
                                              key={index}
                                            >
                                              Grade 4 { grade? "(" + grade + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "grade4Name"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="grade5">
                                    {
                                      DeviceMsgResProps.body.form.grade5Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="grade5"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.grade5,
                                      formik.touched.grade5
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.gradeTypes &&
                                      this.state.gradeTypes.length > 0
                                      ? this.state.gradeTypes.map(
                                        (grade, index) => {
                                          return (
                                            <option
                                              value= {grade? "Grade 5" + "(" + grade + ")":"Grade 5" }
                                              key={index}
                                            >
                                              Grade 5 { grade? "(" + grade + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "grade5Name"
                                  )}
                                </FormGroup>
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="grade6">
                                    {
                                      DeviceMsgResProps.body.form.grade6Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="grade6"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.grade6,
                                      formik.touched.grade6
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.gradeTypes &&
                                      this.state.gradeTypes.length > 0
                                      ? this.state.gradeTypes.map(
                                        (grade, index) => {
                                          return (
                                            <option
                                              value= {grade? "Grade 6" + "(" + grade + ")":"Grade 6" }
                                              key={index}
                                            >
                                              Grade 6 { grade? "(" + grade + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "grade6Name"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="grade7">
                                    {
                                      DeviceMsgResProps.body.form.grade7Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="grade7"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.grade7,
                                      formik.touched.grade7
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.gradeTypes &&
                                      this.state.gradeTypes.length > 0
                                      ? this.state.gradeTypes.map(
                                        (grade, index) => {
                                          return (
                                            <option
                                              value= {grade? "Grade 7" + "(" + grade + ")":"Grade 7" }
                                              key={index}
                                            >
                                              Grade 7 { grade? "(" + grade + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "grade7Name"
                                  )}
                                </FormGroup>
                              </Col>
                              <Col sm={12} md={6} lg={6}>
                                <FormGroup>
                                  <Label for="grade8">
                                    {
                                      DeviceMsgResProps.body.form.grade8Name
                                        .label
                                    }
                                  </Label>
                                  <Field
                                    name="grade8"
                                    as="select"
                                    className={PageDisplay.getFormikFieldClassName(
                                      formik.errors.grade8,
                                      formik.touched.grade8
                                    )}
                                  >
                                    <option value=""></option>
                                    {this.state.gradeTypes &&
                                      this.state.gradeTypes.length > 0
                                      ? this.state.gradeTypes.map(
                                        (grade, index) => {
                                          return (
                                            <option
                                              value= {grade? "Grade 8" + "(" + grade + ")":"Grade 8" }
                                              key={index}
                                            >
                                              Grade 8 { grade? "(" + grade + ")":"" }
                                            </option>
                                          );
                                        }
                                      )
                                      : null}
                                  </Field>
                                  {PageDisplay.getFormikFieldErrorMessage(
                                    "grade8Name"
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>                                  
                                  <Col sm={12} md={6} lg={6}>
                                  <FormGroup check>
                                    <Label for="dispenserFlowType" check>
                                      <Input type="checkbox" checked={this.state.isChecked} onChange={this.ckeckDispenserFlow}
                                       />{' '}
                                      High Flow Dispenser
                                      </Label>
                                  </FormGroup>
                                  </Col>
                                  </Row>
                                  <br/>
                            {this.state.deviceItem &&
                              this.state.deviceItem.dispenserImageUrl !== null &&
                              this.state.deviceItem.dispenserImageUrl !== "" ? (
                              <Row form className="pb-2">
                                <Col
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  className="sram-chev sidebar-nav"
                                >
                                  <span
                                    id={"toggler"}
                                    aria-expanded={this.state.collapse}
                                    onClick={() => this.showCollapse()}
                                    data-toggle="collapse"
                                    className="font-mild sram-mrb-8 sram-links sidebar-link has-arrow sram-has-arrow"
                                  >
                                    Dispenser PPU Image
                                    {this.state.collapse === false ? (
                                      <span className="fa fa-chevron-right ml-2"></span>
                                    ) : (
                                      <span className="fa fa-chevron-down ml-2"></span>
                                    )}
                                  </span>
                                  <UncontrolledCollapse
                                    toggler={"toggler"}
                                    className="pt-2"
                                  >
                                    <img
                                      className="sram-dispenser-ppu"
                                      src={
                                        this.state.deviceItem.dispenserImageUrl
                                      }
                                      alt="dispenser-ppu"
                                    />
                                  </UncontrolledCollapse>
                                </Col>
                              </Row>
                            ) : null}
                          </div>
                        </div>
                        <div className="sram-form-foot">
                          {PageDisplay.showSaveButton(formik.isSubmitting)}
                          {PageDisplay.showCancelButton(this.props.history)}
                        </div>
                        <FormFieldFocusError />
                      </Form>
                    )}
                  </Formik>
                </div>
              </CardBody>
            </Card>
            {this.state.tempDeviceObj ? (
              <ConfirmModal
                isOpenConfirmModal={this.state.isOpenConfirmModal}
                closeConfirmModal={this.closeConfirmModal}
                handleSubmit={this.handleSubmit}
              >
                <div>
                  <div>
                    <Label>
                      {
                        DeviceMsgResProps.body.notification.confirmation
                          .editDeviceTitle
                      }
                    </Label>
                  </div>
                </div>
              </ConfirmModal>
            ) : null}
          </div>
        </WithLoading>
      </>
    );
  }
}
export default DeviceEdit;
