import axios from "axios";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";
import { AppURLProps } from "../../core/settings/app-urls";
import AppUtils from "../../core/helpers/app-utils";
import { refreshToken } from "../../core/actions/identity-actions";
// import { Redirect } from "react-router-dom";
import { includes } from "lodash";
import SiteUtils from "../../portal/helpers/site-utils";

const actionFileName = "alert-actions.js";

// token expiry check
export function checkToken(props, cancelToken) {
  const tokenData = AppUtils.getIdentityTokenData();
  if (
    tokenData &&
    tokenData.userUUID &&
    tokenData.username &&
    tokenData.email &&
    tokenData.tokenExpiry >= Date.now() / 1000
  ) {
    AppUtils.setRequestHeaders();

    if (
      tokenData.tokenExpiry - parseInt(Date.now() / 1000) <=
      AppConfigProps.identitySession.expiryCheckRemainingSeconds
    ) {
      refreshToken(cancelToken);
    }

    // return props
  } else {
    AppUtils.removeIdentityToken();
    props.history.push(AppURLProps.login);
    // return (
    //   <Redirect
    //     to={{
    //       pathname: AppURLProps.login,
    //       state: { from: props.location },
    //     }}
    //   />
    // );
  }
}

// List of  Companies
export function listCompanies(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/user-companies?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listCompanies()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// List of  Sites
export function listSites(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "";
    if (queryData) {
      queryParams = "?" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "portal/sites" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listSites()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// New Alerts
export function newAlerts(queryData, search, cancelToken) {
  return new Promise(function (resolve, reject) {
    let searchString = "";
    const filterData = search;
    Object.keys(filterData).forEach((key, index) => {
      searchString += `${
        filterData[key] ? "&" + key + "=" + filterData[key] : ""
      }`;
    });
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "help-desk/alerts?" +
          queryData +
          searchString,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">newAlerts()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

export function updateAlerts(queryData, alertObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix + "help-desk/alerts?" + queryData,
        alertObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">newAlerts()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

export function createTicket(alertObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "help-desk/tickets",
        alertObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">createTicket()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

export function getTicket(queryData, search, cancelToken) {
  return new Promise(function (resolve, reject) {
    let searchString = "";
    const filterData = search;
    Object.keys(filterData).forEach((key, index) => {
      searchString += `${
        filterData[key] ? "&" + key + "=" + filterData[key] : ""
      }`;
    });
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "help-desk/" +
          "tickets?" +
          queryData +
          searchString,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getTicket()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

export function getTicketMails(siteId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "sites/" +
          siteId +
          "/users?userType=" +
          queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getTicketMails()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

export function getClientNotificationHistory(alertTicketId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "help-desk/" +
          "tickets-client-approval?alertTicketId=" +
          alertTicketId,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">getClientNotificationHistory()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function requestApproval(queryData, commentsobj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
          "help-desk" +
          "/tickets-client-approval?approveStatus=" +
          queryData,
        commentsobj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">requestApproval()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function approveEmail(queryData, mailDataObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
          "help-desk" +
          "/tickets-client-approval?approveStatus=" +
          queryData,
        mailDataObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">approveEmail()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

export function seekEmailApproval(queryData, mailDataObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
          "help-desk" +
          "/tickets-client-approval?approveStatus=" +
          queryData,
        mailDataObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">seekEmailApproval()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

export function rejectApproval(queryData, commentsobj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
          "help-desk" +
          "/tickets-client-approval?approveStatus=" +
          queryData,
        commentsobj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">rejectApproval()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

export function updateTicket(ticketObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix + "help-desk/tickets",
        ticketObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">updateTicket()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

export function updateStatus(ticketObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "help-desk/ticket-status",
        ticketObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">updateStatus()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

export function getHistory(alertTicketId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "help-desk/" +
          "ticket-status?alertTicketId=" +
          alertTicketId,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getHistory()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

//Search Ignored Alerts

export function searchIgnoredALerts(list, searchKeyword) {
  var result = null;
  if (
    list &&
    list.length > 0 &&
    searchKeyword &&
    searchKeyword.trim().length > 0
  ) {
    let searchRecords = list.filter((obj) => {
      if (
        obj.siteName != null &&
        obj.componentName != null &&
        obj.eventStatus != null &&
        obj.eventCause != null
      ) {
        const siteAddress = SiteUtils.getSiteAddress(obj);
        const siteAddress1 = SiteUtils.getSiteAddress1(obj);
        return (
          includes(
            obj.siteName.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.siteNumber.toString(),
            searchKeyword.trim().toString()
          ) ||
          includes(
            siteAddress.toString().toLowerCase() +
              "," +
              siteAddress1.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.dispenserName.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.sideLabel?.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.alertEventStatusDescription?.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.componentName.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.eventStatus.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.eventCause.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            AppUtils.getDateTime(obj.eventTimestamp),
            AppUtils.getDateTime(searchKeyword.trim().toString())
          ) ||
          includes(
            AppUtils.getDate(obj.ignoredTimestamp),
            AppUtils.getDate(searchKeyword.trim().toString())
          ) ||
          includes(
            obj.username?.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          )
        );
      }
    });
    return searchRecords;
  } else {
    result = list;
  }
  return result;
}

//Search Alerts SiteName

export function searchAlertSiteNames(list, searchKeyword) {
  var result = null;
  if (
    list &&
    list.length > 0 &&
    searchKeyword &&
    searchKeyword.trim().length > 0
  ) {
    let searchRecords = list.filter((obj) => {
      if (obj.siteName != null) {
        return (
          obj.siteName.toString().toLowerCase() ===
          searchKeyword.trim().toLowerCase()
        );
      }
    });
    result = searchRecords;
    return result;
  } else {
    result = list;
    return result;
  }
}

//Search Alerts

export function searchAlerts(list, searchKeyword) {
  var result = null;
  if (
    list &&
    list.length > 0 &&
    searchKeyword &&
    searchKeyword.trim().length > 0
  ) {
    let searchRecords = list.filter((obj) => {
      if (
        obj.siteName !== null &&
        obj.componentName !== null &&
        obj.eventStatus !== null &&
        obj.eventCause !== null &&
        obj.alertEventStatusDescription !== null
      ) {
        const siteAddress = SiteUtils.getSiteAddress(obj);
        const siteAddress1 = SiteUtils.getSiteAddress1(obj);
        return (
          includes(
            obj.siteName?.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.siteNumber?.toString(),
            searchKeyword.trim().toString()
          ) ||
          includes(
            siteAddress.toString().toLowerCase() +
              "," +
              siteAddress1.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.dispenserName?.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.sideLabel?.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.alertEventStatusDescription?.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.componentName?.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.eventStatus?.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.eventCause?.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            obj.warrantyDescription?.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            AppUtils.getDate(obj?.warrantyExpiryDate),
            AppUtils.getDate(searchKeyword.trim().toString())
          ) ||
          includes(
            AppUtils.getDateTime(obj?.eventTimestamp),
            AppUtils.getDateTime(searchKeyword.trim().toString())
          )
        );
      }
    });
    result = searchRecords;
    return result;
  } else {
    result = list;
    return result;
  }
}

//Search Alert Event Status

export function searchAlertEventStatus(list, searchKeyword) {
  var result = null;
  if (
    list &&
    list.length > 0 &&
    searchKeyword &&
    searchKeyword.trim().length > 0 &&
    searchKeyword !== "Close"
  ) {
    let searchRecords = list.filter((obj) => {
      if (obj.alertEventStatusDescription != null) {
        return (
          obj.alertEventStatusDescription.toString().toLowerCase() ===
          searchKeyword.trim().toLowerCase()
        );
      }
    });
    result = searchRecords;
    return result;
  } else {
    result = list;
    return result;
  }
}
