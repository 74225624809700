import axios from "axios";
import { includes } from "lodash";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";

const actionFileName = "device-actions.js";

// List Devices
export function listDevices(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/devices?" + queryData,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listDevices()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}


// Device Item
export function deviceItem(dispenserId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "/devices/" + dispenserId,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">deviceItem()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Update Device
export function updateDevice(dispenserId, deviceObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix + "/devices/" + dispenserId,
        deviceObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">addDevice()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Delete Devices
export function deleteDevices(dispenserId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .delete(
        AppConfigProps.serverRoutePrefix + "/devices/" + dispenserId,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">deleteDevices()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

//Search Devices
export function searchDevices(list, searchKeyword) {
  return new Promise(async function (resolve, reject) {
    if (
      list &&
      list.length > 0 &&
      searchKeyword &&
      searchKeyword.trim().length > 0
    ) {
      let searchRecords = list.filter((device) => {
        return (
          includes(
            device.siteNumber.toString(),
            searchKeyword.trim().toString()
          ) ||
          includes(
            device.siteName.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            "Dispenser " + device.sideANumber.toString() + '/' + device.sideBNumber.toString(),
            searchKeyword.trim()
          ) ||
          includes(
            device.imeiNumber.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          )
        );
      });

      resolve(searchRecords);
    } else {
      resolve(list);
    }
  });
}
