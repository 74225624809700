import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Table,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import ConfirmModal from "../../core/helpers/confirm-modal";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import PageDisplay from "../../core/helpers/page-display";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import PageUtils from "../../core/helpers/page-utils";
import { SetupURLProps } from "../settings/setup-urls";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { manageError } from "../../core/actions/common-actions";
import { isPagePartPrivileged } from "../../core/actions/identity-actions";
import { listUsers } from "../actions/user-actions";
import {
  getSite,
  getSiteUsers,
  editSiteUsers,
  searchUsersSites
} from "../actions/service-provider-actions";
import { SiteOwnerMsgResProps } from "../messages/site-owner-properties";
import PageListPagination from "../../core/helpers/page-list-pagination";
import PageListLimitSelect from "../../core/helpers/page-list-limit-select";
import PageListSearchField from "../../core/helpers/page-list-search-field";
import PageListHeaderColumn from "../../core/helpers/page-list-header-column";

class serviceProviderUserAccess extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _helpers = null;
  _pcUserServiceProviderAccess = "AS-SPV-STUA";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isOpenConfirmModal: false,
      formInputFields: null,
      site: null,
      users: null,
      siteUsers: null,
      tempSiteUsersObj: null,
      SiteAccessPrivileges: false,
      siteAllRecords: null,
      siteDisplayRecords: null,
      sitePageRecords: null,
      searchValue: "",
      searchKeyword: "",
      pageOffset: AppConfigProps.listPage.pageOffset.defaultValue,
      pageLimit: AppConfigProps.listPage.pageLimit.defaultValue,
      siteUsersLength: null,
      loadUsersLength: null,
      selectedOptions: null,
      ConfirmModalData: true,
      sortedColumn: "username",
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    const siteId = this.props.match.params.sid;
    await this.loadPageData(siteId);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const siteId = this.props.match.params.sid;
    if (siteId !== prevProps.match.params.sid) {
      PageUtils.scrollToTop();
      await this.loadPageData(siteId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async (siteId) => {
    if (
      siteId === null ||
      siteId === undefined ||
      siteId !== parseInt(siteId).toString()
    )
      this.props.history.push(SetupURLProps.users.listUser);

    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ user: null });
    await this.checkPartPrivilege();
    await getSite(siteId, this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ site: res.data.result });
          if (this.state.site && this.state.site.siteId) {
            await this.loadUsers(this.state.site.siteId);
            await this.loadSiteUsers(this.state.site.siteId);
          }
          await this.setStateAsync({
            formInputFields: await this.initFormInputFields(),
          });
        } else {
          this.props.history.push(SetupURLProps.users.listUser);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await this.setStateAsync({ isPageDataFetched: true });
  };

  loadUsers = async (siteId) => {
    await this.setStateAsync({ users: null });
    await this.setStateAsync({
      siteAllRecords: null,
      siteDisplayRecords: null,
      sitePageRecords: null
    });
    const queryData = "sortField=username&sortOrder=1";
    await listUsers(queryData, this._cancelToken)
      .then(async (res) => {
        await this.setStateAsync({ loadUsersLength: res.data.records.length });
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ users: res.data.records });
            await this.setStateAsync({ siteAllRecords: res.data.records });
            let sortedRecords = await PageUtils.sortListRecords(
              this.state.siteAllRecords,
              this.state.sortedColumn,
              this.state.sortedOrder
            );
            await this.setStateAsync({ siteDisplayRecords: sortedRecords });
            let slicedRecords = await PageUtils.sliceListRecords(
              this.state.siteDisplayRecords,
              this.state.pageOffset,
              this.state.pageLimit
            );
            await this.setStateAsync({ sitePageRecords: slicedRecords });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  loadSiteUsers = async (siteId) => {
    await this.setStateAsync({ siteUsers: null });
    await getSiteUsers(siteId, this._cancelToken)
      .then(async (res) => {
        await this.setStateAsync({ siteUsersLength: res.data.result.users.length });

        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          if (res.data.result.users && res.data.result.users.length > 0) {
            await this.setStateAsync({ siteUsers: res.data.result.users });
            await this.radioCheckBoxInitiate();

          }
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  radioCheckBoxInitiate = async () => {
    if (this.state.loadUsersLength === this.state.siteUsersLength) {
      const value = this.state.users.map((user) => {
        return user.userId
      })
      const userSitesObj = {
        userId: this.state.site.siteId,
        siteId: value,
      };
      await this.setStateAsync({ tempSiteUsersObj: userSitesObj });

      this.setStateAsync({ selectedOptions: "Access to all Users" })
      this.setStateAsync({ SiteAccessPrivileges: false });

    } else {
      this.setStateAsync({ selectedOptions: "Choose Users below" })
      this.setStateAsync({ SiteAccessPrivileges: true })
    }
  }

  checkPartPrivilege = async () => {
    const partCode = `${this._pcUserServiceProviderAccess}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  initFormInputFields = () => {
    let selUserId = [];
    if (this.state.siteUsers && this.state.siteUsers.length > 0) {
      this.state.siteUsers.forEach((userId) => {
        selUserId.push(userId.userId);
      });
    }
    return {
      userId: selUserId,
    };
  };

  formValidationSchema = () => {
    const schema = {};
    return schema;
  };

  handleConfirmation = async (values, helpers) => {
    if (this.state.ConfirmModalData === true) {
      if (this.state.selectedOptions !== "Access to all Users") {
        this._helpers = helpers;
        let active_status = 0
        const usersData = []
        for (const data of this.state.users) {
          if (values.userId.includes(data.userId)) {
            active_status = 1
          }
          else {
            active_status = 0
          }
          usersData.push({ userId: data.userId, activeStatus: active_status })
        }
        const siteUsersObj = {
          siteId: this.state.site.siteId,
          users: usersData,
        };
        await this.setStateAsync({ tempSiteUsersObj: siteUsersObj });
        this.openConfirmModal();
      } else {
        this._helpers = helpers;
        let active_status = 0
        const usersData = []
        for (const data of this.state.users) {
          if (data.userId) {
            active_status = 1
          }
          else {
            active_status = 0
          }
          usersData.push({ userId: data.userId, activeStatus: active_status })
        }
        const siteUsersObj = {
          siteId: this.state.site.siteId,
          users: usersData,
        };
        await this.setStateAsync({ tempSiteUsersObj: siteUsersObj });
        await this.state.tempSiteUsersObj;
        this.openConfirmModal();

      }
    } else {
      await this.setStateAsync({ ConfirmModalData: true });

    }
  };

  handleSubmit = async () => {
    const siteUsersObj = this.state.tempSiteUsersObj;
    const helpers = this._helpers;
    this.closeConfirmModal();
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }
    await editSiteUsers(siteUsersObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = [
            "S",
            SiteOwnerMsgResProps.body.notification.success.editSiteAccess,
          ];
          if (res.data.result) {
            await this.setStateAsync({ siteUsers: res.data.result.sites });
            await this.setStateAsync({
              formInputFields: await this.initFormInputFields(),
            });
            if (
              document.getElementsByName("selectAll") &&
              document.getElementsByName("selectAll").length > 0
            ) {
              for (
                let i = 0;
                i < document.getElementsByName("selectAll").length;
                i++
              ) {
                let elem = document.getElementsByName("selectAll")[i];
                elem.checked = false;
              }
            }
          }
          if (this._isMounted) {
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          SiteOwnerMsgResProps.body.notification.error.message,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);

          if (err.data && err.data.errors && err.data.errors.length > 0) {
            const errors = err.data.errors;
            errors.forEach((error) => {
              if (error.element && error.message && error.location === "body") {
                helpers.setFieldError(error.element, error.message);
              }
            });
          }
          PageUtils.scrollToTop();
        }
      });
    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  toggleMultipleActions = async (e, users, arrayHelpers, formik) => {
    if (users && users.length > 0) {
      if (e && e.target.checked && e.target.checked !== null) {
        //     Remove previous Selected
        const idx = formik.values.userId
        arrayHelpers.remove(idx);

        const value = users.map((user) => {
          return { 'userId': user.userId, activeStatus: 1 }

        })

        users.map((user) => {
          return arrayHelpers.push(user.userId)

        })

        const userSitesObj = {
          siteId: this.state.site.siteId,
          users: value,
        };
        await this.setStateAsync({ tempSiteUsersObj: userSitesObj });
      }
    }
  };

  toggleSingleAction = async (e, user, formik, arrayHelpers) => {
    if (e.target.checked) arrayHelpers.push(user.userId);
    else {
      const idx = formik.values.userId.indexOf(user.userId);
      arrayHelpers.remove(idx);
    }
  };

  handleOutputChange = async (e, arrayHelpers, formik) => {
    let outputValue = e.target.value
    this.setStateAsync({ selectedOptions: outputValue })
    if (outputValue === "Access to all Users") {
      this.setStateAsync({ SiteAccessPrivileges: false })
      await this.toggleMultipleActions(
        e,
        this.state.users,
        arrayHelpers,
        formik
      );
    } else {
      await this.setStateAsync({ SiteAccessPrivileges: true })
    }
  };

  handlePageLimitChange = async (pageLimit) => {
    if (pageLimit > 0) {
      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.siteDisplayRecords,
        pageOffset,
        pageLimit
      );
      await this.setStateAsync({ sitePageRecords: slicedRecords });
      await this.setStateAsync({ pageLimit: pageLimit });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handlePageOffsetChange = async (pageOffset) => {
    await this.setStateAsync({ ConfirmModalData: false });
    if (pageOffset >= 0) {
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.siteDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );
      await this.setStateAsync({ sitePageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchChange = async (e) => {
    await this.setStateAsync({ searchValue: e.target.value });
  };

  handleSearchSubmit = async (e) => {
    if (this.state.siteAllRecords && this.state.siteAllRecords.length > 0) {
      let searchKeyword = this.state.searchValue;
      await this.setStateAsync({ searchKeyword: searchKeyword });
      let searchedRecords = await searchUsersSites(
        this.state.siteAllRecords,
        this.state.searchKeyword
      );

      let sortedRecords = await PageUtils.sortListRecords(
        searchedRecords,
        this.state.sortedColumn,
        this.state.sortedOrder
      );
      await this.setStateAsync({ siteDisplayRecords: sortedRecords });

      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.siteDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );

      await this.setStateAsync({ sitePageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchCancel = async (e) => {
    e.preventDefault();
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.siteAllRecords,
      this.state.sortedColumn,
      this.state.sortedOrder
    );
    await this.setStateAsync({ siteDisplayRecords: sortedRecords });
    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.siteDisplayRecords,
      this.state.pageOffset,
      this.state.pageLimit
    );
    await this.setStateAsync({ sitePageRecords: slicedRecords });

    await this.setStateAsync({ searchKeyword: "", searchValue: "" });
    await this.setStateAsync({ pageOffset: pageOffset });
  };

  getTableColumnHeadings = () => {
    return [
      {
        columnId: "username",
        columnType: "string",
        columnLabel: SiteOwnerMsgResProps.body.form.userName.label,
        isSortable: false,
        columnWidth: "20%",
      },
      {
        columnId: "email",
        columnType: "string",
        columnLabel: SiteOwnerMsgResProps.body.form.email.label,
        isSortable: false,
        columnWidth: "40%",
      },
      {
        columnId: "firstName",
        columnType: "string",
        columnLabel: SiteOwnerMsgResProps.body.form.firstName.label,
        isSortable: false,
        columnWidth: "20%",
      },
      {
        columnId: "lastName",
        columnType: "string",
        columnLabel: SiteOwnerMsgResProps.body.form.lastName.label,
        isSortable: false,
        columnWidth: "20%",
      },
    ];
  };

  renderSiteAccessPrivilegesForm(formik) {
    return (
      <>
        {this.state.siteAllRecords &&
          this.state.siteAllRecords.length > 0 ? (
          <div className="sram-list-head">
            <span className="float-left">
              <PageListSearchField
                searchValue={this.state.searchValue}
                searchKeyword={this.state.searchKeyword}
                handleSearchChange={this.handleSearchChange}
                handleSearchSubmit={this.handleSearchSubmit}
                handleSearchCancel={this.handleSearchCancel}
              />
            </span>
            <span className="float-right">
              {PageDisplay.getListPageRecordsDisplayInfo(
                this.state.siteDisplayRecords
                  ? this.state.siteDisplayRecords.length
                  : 0,
                this.state.pageOffset,
                this.state.pageLimit
              )}
            </span>
          </div>
        ) : null}
        {this.state.sitePageRecords &&
          this.state.sitePageRecords.length > 0 ? (
          <>
            <FieldArray
              name="userId"
              render={(arrayHelpers) => (
                <Table
                  responsive
                  size="sm"
                  className="sram-table-def"
                >
                  <thead>
                    <tr>
                      {this.getTableColumnHeadings().map((column) => {
                        return (
                          <PageListHeaderColumn
                            {...column}
                            key={column.columnId}
                          // sortedColumn={this.state.sortedColumn}
                          // sortedOrder={this.state.sortedOrder}
                          // handleSortChange={this.handleSortChange}
                          />
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sitePageRecords.map(
                      (user, index) => {
                        let keyString = {
                          key: `key-useraccess-${index}`,
                        };
                        return (
                          <tr {...keyString}>
                            <td>
                              <FormGroup check inline>
                                <Label check>
                                  <Input
                                    name="userId"
                                    id={`check_${user.userId}`}
                                    type="checkbox"
                                    value={user.userId}
                                    checked={formik.values.userId.includes(
                                      user.userId
                                    )}
                                    onChange={async (
                                      e
                                    ) => {
                                      await this.toggleSingleAction(
                                        e,
                                        user,
                                        formik,
                                        arrayHelpers
                                      );
                                    }}
                                  />
                                  &nbsp;
                                  {user.username}
                                </Label>
                              </FormGroup>
                            </td>
                            <td>{user.email}</td>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              )}
            ></FieldArray>
          </>
        ) : (
          <div className="pt-4">
            <div className="pt-4">
              {PageDisplay.showWarningNotification(
                SiteOwnerMsgResProps.body.content
                  .noUsersFound
              )}
            </div>
          </div>
        )}

        {this.state.sitePageRecords &&
          this.state.sitePageRecords.length ? (
          <div className="pb-4">
            <div className="float-left">
              <PageListLimitSelect
                pageLimit={this.state.pageLimit}
                pageOffset={this.state.pageOffset}
                totalRecords={this.state.siteDisplayRecords.length}
                handlePageLimitChange={this.handlePageLimitChange}
              />
            </div>
            <div className="float-right">
              <PageListPagination
                pageLimit={this.state.pageLimit}
                pageOffset={this.state.pageOffset}
                totalRecords={this.state.siteDisplayRecords.length}
                handlePageOffsetChange={this.handlePageOffsetChange}
              />
            </div>
          </div>
        ) : null}
      </>
    )
  }

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            {this.state.site ? (
              <>
                <Card className="sram-page-form">
                  <CardTitle>
                    <span>{SiteOwnerMsgResProps.body.content.siteAccess}</span>
                    <span className="float-right">
                      {PageDisplay.showGoBackLink(
                        "L",
                        SetupURLProps.serviceProviders.listSite,
                        this.props.history
                      )}
                    </span>
                  </CardTitle>
                  <CardBody>
                    <Formik
                      initialValues={this.state.formInputFields}
                      validationSchema={Yup.object().shape(
                        this.formValidationSchema()
                      )}
                      onSubmit={(values, helpers) =>
                        this.handleConfirmation(values, helpers)
                      }
                    >
                      {(formik) => (
                        <Form id="userForm" {...formEvent}>
                          <div className="sram-form-main">
                            <div>
                              {formik.status &&
                                Array.isArray(formik.status) &&
                                formik.status.length === 2 &&
                                (formik.status[0] === "E" ||
                                  formik.status[0] === "S") ? (
                                <Row>
                                  <Col xs={12}>
                                    {formik.status[0] === "E"
                                      ? PageDisplay.showErrorNotification(
                                        formik.status[1]
                                      )
                                      : PageDisplay.showSuccessNotification(
                                        formik.status[1]
                                      )}
                                  </Col>
                                </Row>
                              ) : null}
                            </div>
                            <div className="sram-view-data">
                              <Row form>
                                <Col sm={12} md={4} lg={4}>
                                  {PageDisplay.getFormFieldLabelValue(
                                    SiteOwnerMsgResProps.body.form.siteName
                                      .label,
                                    this.state.site.siteName
                                  )}
                                </Col>
                                <Col sm={12} md={3} lg={3}>
                                  {PageDisplay.getFormFieldLabelValue(
                                    SiteOwnerMsgResProps.body.form.siteId.label,
                                    this.state.site?.siteNumber.toString()
                                  )}
                                </Col>
                                <Col sm={12} md={4} lg={4}>
                                  {PageDisplay.getFormFieldLabelValue(
                                    SiteOwnerMsgResProps.body.form
                                      .organizationName.label,
                                    this.state.site.siteOwnerOrganization
                                      ?.organizationName
                                  )}
                                </Col>
                              </Row>
                              {/* <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  {PageDisplay.getFormFieldLabelValue(
                                    UserMsgResProps.body.form.organization
                                      .label,
                                    this.state.user.organizationTreepath
                                  )}
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  {PageDisplay.getFormFieldLabelValue(
                                    UserMsgResProps.body.form.role.label,
                                    this.state.user.roleName
                                  )}
                                </Col>
                              </Row> */}
                            </div>
                            <div>
                              <FieldArray
                                name="userId"
                                render={(arrayHelpers) => (
                                  <FormGroup tag="fieldset" onChange={e => this.handleOutputChange(e, arrayHelpers, formik)} >
                                    <Row>
                                      <Col>
                                        <FormGroup check >
                                          <Label check className="font-medium">
                                            <Input type="radio" name="radio1" value="Access to all Users" defaultChecked={this.state.selectedOptions === "Access to all Users"} />{' '}
                                            Access to all {this.state.users.length} Users
                                          </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                          <Label check className="font-medium">
                                            <Input type="radio" name="radio1" value="Choose Users below" defaultChecked={this.state.selectedOptions === "Choose Users below"} />{' '}
                                            Choose Users below
                                          </Label>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                )}
                              ></FieldArray>
                              {this.state.SiteAccessPrivileges === true ?
                                this.renderSiteAccessPrivilegesForm(formik)
                                : null}
                            </div>
                          </div>
                          <div className="sram-form-foot">
                            {this.state.users && this.state.users.length > 0
                              ? PageDisplay.showSaveButton(formik.isSubmitting)
                              : null}
                            {PageDisplay.showCancelButton(this.props.history)}
                          </div>
                          <FormFieldFocusError />
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
                {this.state.tempSiteUsersObj && this.state.ConfirmModalData === true ? (
                  <ConfirmModal
                    isOpenConfirmModal={this.state.isOpenConfirmModal}
                    closeConfirmModal={this.closeConfirmModal}
                    handleSubmit={this.handleSubmit}
                  >
                    <div>
                      <div>
                        <Label>
                          {
                            SiteOwnerMsgResProps.body.notification.confirmation
                              .editSiteAccess
                          }
                        </Label>
                      </div>
                    </div>
                  </ConfirmModal>
                ) : null}
              </>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  SiteOwnerMsgResProps.body.content.noSitesFound
                )}
              </>
            )}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default serviceProviderUserAccess;
