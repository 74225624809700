import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../core/components/login";
import PasswordRecover from "../core/components/password-recover";
// import JoinNow from "../core/components/join-now";
import ErrorPage from "../views/error-page";
import siteVisitor from "../core/components/site-visitor";

class BlankLayout extends Component {
  render() {
    return (
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/recover-password" exact component={PasswordRecover} />
        {/* <Route path="/join-now" exact component={JoinNow} /> */}
        <Route path="/client-approval" exact component={siteVisitor} />
        <Route path="/error" exact component={ErrorPage} />
      </Switch>
    );
  }
}

export default BlankLayout;
