export const SiteMsgResProps = {
  head: {
    title: {
      sitesHome: "Sites",
      manufacturerModel: "Manufacturer & Model",
      recentErrorCodes: "Recent Error Codes",
      doorAlerts: "Door Alerts",
      overRun: "Overrun",
      standAlone: "Standalone",
      siteSummaryDetails: "Site Summary",
      pumpStatus: "Pump Status",
      openAlerts: "Open Alerts (In error or down)",
      pump: "Pump",
      crind: "Crind",
      customerExperience: "Customer Experience",
      connectivityStatus: "Connectivity Status",
      warranty: "Warranty",
      software: "Software Versions"
    },
  },
  body: {
    content: {
      viewSiteDashboard: "View Site Dashboard",
      viewAlertDetails: "View Alert details",
      sideA: "Side A",
      sideB: "Side B",
      recentPumpErrors: "Recent Pump Errors",
      recentDoorAlerts: "Recent Door Alerts",
      recentAlerts: "Recent Alerts",
      noDataFound: "No data found.",
      noIssuesFound: "No issues found.",
      noPossibleFixesFound: "There are no fixes found for Error Code",
      errorOccured: "An error occurred."
    },
    form: {
      searchSites: {
        label: "Search Sites",
      },
      siteId: {
        label: "GVR ID",
      },
      new: {
        label: "New"
      },
      inProcess: {
        label: "In Process"
      },
      inProgress: {
        label: "In Progress"
      },
      twoWireStatus: {
        label: "Two Wire Status"
      },
      serialInterface: {
        label: "Serial Interface"
      },
      pumpStatus: {
        label: "Pump Status"
      },
      doorAlerts: {
        label: "Door Alerts"
      },
      securityModule: {
        label: "Security Module"
      },
      // serialinterface:{
      //   label: "Serial Interface"
      // },

      standAlone: {
        label: "Standalone"
      },
      cardReader: {
        label: "Card Reader"
      },
      epp: {
        label: "EPP"
      },
      upm: {
        label: "UPM"
      },
      display: {
        label: "Display"
      },
      ppuDisplay: {
        label: "PPU Display"
      },
      host: {
        label: "Host"
      },
      keyboard: {
        label: "Keyboard"
      },
      EPP: {
        label: "EPP"
      },
      warrantyStatus: {
        label: "Warranty Status"
      },
      contactLessCardReader: {
        label: "Contact Less Card Reader"
      },
      printer: {
        label: "Printer"
      },
      fcb: {
        label: "FCB"
      },
      gsom: {
        label: "GSOM"
      },
      applause: {
        label: "Applause/GSOM"
      },
      omnia: {
        label: "Omnia"
      },
      omniassom: {
        label: "Omnia/SSOM"
      },
      ssom: {
        label: "SSOM"
      },
      applausegsom: {
        label: "Applause/GSOM"
      },
      flowRate: {
        label: "Flow Rate"
      },
      printerJammed: {
        label: "Printer Jammed"
      },
      paperOut: {
        label: "Receipt Paper"
      },
      badCardReads: {
        label: "Bad Card Reads"
      },
      overruns: {
        label: "Overruns"
      },
      noTransaction: {
        label: "No Transaction"
      }
    },
  },
  footer: {
    close: {
      label: "Close"
    }
  }
};
