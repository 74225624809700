import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import WithLoading from "../../core/helpers/with-loading";
import ConfirmModal from "../../core/helpers/confirm-modal";
import GeoAddressSearchField from "../../core/helpers/geo-address-search-field";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import { SetupURLProps } from "../settings/setup-urls";
import { SetupValidations } from "..//settings/setup-validations";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { AppDataProps } from "../../core/settings/app-data";
import { SiteOwnerMsgResProps } from "../messages/site-owner-properties";
import { SetupConstants } from "../settings/setup-constants";
import { manageError } from "../../core/actions/common-actions";
import { isPagePartPrivileged } from "../../core/actions/identity-actions";
import {
  getSiteOwnerOrganizations,
  getServiceProviderCompanies,
} from "../actions/organization-actions";
import { getSite, editSite } from "../actions/site-owner-actions";
import { getPlaceTimezone } from "../../core/actions/geo-acitons";
import moment from "moment/moment";

class SiteOwnerSiteEdit extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _helpers = null;
  _pcSiteOwnerSiteEdit = "AS-SOW-STED";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isOpenConfirmModal: false,
      site: null,
      siteOwnerOrganizations: null,
      serviceProviderCompanies: null,
      formInputFields: null,
      tempSiteObj: null,
      previousSiteOwnerOrganizationId: null,
      currentSiteOwnerOrganizationId: null,
      isOpenFlowRateModal: false,
      configureFlowRate: {
        previousValue: null,
        currentValue: null,
        selectedFlow: "",
      },
      highFlowDispenserFlowrate: SetupConstants.highFlowDispenserFlowrate,
      lowFlowDispenserFlowrate: SetupConstants.lowFlowDispenserFlowrate,
      siteContractedStatus: [
        { key: 1, value: "Contracted" },
        { key: 0, value: "Not Contracted" },
      ],
      previousOrganizationId: null,
      previousOrganizationName: null,
      currentOrganizationName: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    const siteId = this.props.match.params.sid;
    await this.loadPageData(siteId);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const siteId = this.props.match.params.sid;
    if (siteId !== prevProps.match.params.sid) {
      PageUtils.scrollToTop();
      await this.loadPageData(siteId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async (siteId) => {
    if (
      siteId === null ||
      siteId === undefined ||
      siteId !== parseInt(siteId).toString()
    )
      this.props.history.push(SetupURLProps.siteOwners.listSite);

    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({
      site: null,
      previousOrganizationId: null,
      previousOrganizationName: null,
      currentOrganizationName: null,
    });
    await this.setStateAsync({ siteOwnerOrganizations: null });
    await this.setStateAsync({ serviceProviderCompanies: null });
    await this.checkPartPrivilege();
    await getSite(siteId, this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ site: res.data.result });
          await this.setStateAsync({
            previousSiteOwnerOrganizationId:
              res.data.result?.siteOwnerOrganization?.organizationId,
            previousOrganizationId:
              this.state.site?.siteOwnerOrganization?.organizationId,
            previousOrganizationName:
              this.state.site?.siteOwnerOrganization?.organizationTreepath,
            currentOrganizationName:
              this.state.site?.siteOwnerOrganization?.organizationTreepath,
          });
          await this.setStateAsync({
            formInputFields: await this.initFormInputFields(),
          });
        } else {
          this.props.history.push(SetupURLProps.siteOwners.listSite);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.loadSiteOwnerOrganizations();
    await this.loadServiceProviderCompanies();
    await this.setStateAsync({ isPageDataFetched: true });
  };

  loadSiteOwnerOrganizations = async () => {
    const queryData = "";

    await getSiteOwnerOrganizations(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({
              siteOwnerOrganizations: res.data.records,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  loadServiceProviderCompanies = async () => {
    const queryData = "";

    await getServiceProviderCompanies(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({
              serviceProviderCompanies: res.data.records,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcSiteOwnerSiteEdit}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  initFormInputFields = () => {
    return {
      siteOwnerOrganizationId:
        this.state.site.siteOwnerOrganization.organizationId,
      serviceProviderOrganizationId: this.state.site.serviceProviderOrganization
        ? this.state.site.serviceProviderOrganization.organizationId
          ? this.state.site.serviceProviderOrganization.organizationId
          : ""
        : "",
      siteName: this.state.site.siteName,
      siteNumber: this.state.site.siteNumber.toString(),
      addressCode: this.state.site.addressCode
        ? this.state.site.addressCode
        : "",
      customerNumber: this.state.site.customerNumber
        ? this.state.site.customerNumber
        : "",
      highFlowDispenserFlowrate: this.state.site.highFlowDispenserFlowrate
        ? this.state.site.highFlowDispenserFlowrate
        : "",
      lowFlowDispenserFlowrate: this.state.site.lowFlowDispenserFlowrate
        ? this.state.site.lowFlowDispenserFlowrate
        : "",
      siteContractedStatus: this.state.site.siteContractedStatus
        ? this.state.site.siteContractedStatus
        : 0,
      addressAutocomplete: "",
      streetAddress1: this.state.site.streetAddress1
        ? this.state.site.streetAddress1
        : "",
      streetAddress2: this.state.site.streetAddress2
        ? this.state.site.streetAddress2
        : "",
      city: this.state.site.city ? this.state.site.city : "",
      state: this.state.site.state ? this.state.site.state : "",
      country: this.state.site.country ? this.state.site.country : "",
      zipCode: this.state.site.zipCode ? this.state.site.zipCode : "",
      sitePhone: this.state.site.sitePhone ? this.state.site.sitePhone : "",
      locationLatitude: this.state.site.locationLatitude
        ? this.state.site.locationLatitude
        : "",
      locationLongitude: this.state.site.locationLongitude
        ? this.state.site.locationLongitude
        : "",
      siteNote: this.state.site.siteNote ? this.state.site.siteNote : "",
      connectivityDate: this.state.site.connectivityDate
        ? moment(this.state.site.connectivityDate).format("YYYY-MM-DD")
        : "",
    };
  };

  populateAddressFields = async (place, formik) => {
    if (place && formik) {
      const address = PageUtils.getGeoAddressFieldsData(place);
      if (address) {
        formik.setFieldValue("streetAddress1", address.streetAddress1);
        formik.setFieldValue("streetAddress2", address.streetAddress2);
        formik.setFieldValue("city", address.city);
        formik.setFieldValue("state", address.state);
        formik.setFieldValue("country", address.country);
        formik.setFieldValue("zipCode", address.zipCode);
        formik.setFieldValue("locationLatitude", address.locationLatitude);
        formik.setFieldValue("locationLongitude", address.locationLongitude);
      }
      this.setStateAsync({ isAddressFieldsPopulated: true });
    }
  };

  formValidationSchema = () => {
    const schema = {
      siteOwnerOrganizationId: Yup.string().required(
        SiteOwnerMsgResProps.body.notification.error.companyDivision.empty
      ),
      siteName: Yup.string()
        .trim()
        .required(SiteOwnerMsgResProps.body.notification.error.siteName.empty)
        .matches(
          SetupValidations.sites.siteName,
          SiteOwnerMsgResProps.body.notification.error.siteName.invalid
        ),
      siteNumber: Yup.string()
        .trim()
        .required(SiteOwnerMsgResProps.body.notification.error.siteId.empty)
        .matches(
          SetupValidations.sites.siteId,
          SiteOwnerMsgResProps.body.notification.error.siteId.invalid
        ),
      serviceProviderOrganizationId: Yup.string().required(
        SiteOwnerMsgResProps.body.notification.error
          .serviceProviderOrganizationName.empty
      ),
      addressCode: Yup.string().required(
        SiteOwnerMsgResProps.body.notification.error.addressCode.empty
      ),
      // .matches(
      //   SetupValidations.sites.siteId,
      //   SiteOwnerMsgResProps.body.notification.error.addressCode.invalid
      // ),
      customerNumber: Yup.string().required(
        SiteOwnerMsgResProps.body.notification.error.customerNumber.empty
      ),
      // .matches(
      //   SetupValidations.sites.siteId,
      //   SiteOwnerMsgResProps.body.notification.error.customerNumber.invalid
      // ),
      city: Yup.string()
        .trim()
        .required(SiteOwnerMsgResProps.body.notification.error.city.empty),
      state: Yup.string()
        .trim()
        .required(SiteOwnerMsgResProps.body.notification.error.state.empty),
      country: Yup.string()
        .trim()
        .required(SiteOwnerMsgResProps.body.notification.error.country.empty),
      zipCode: Yup.string()
        .trim()
        .required(SiteOwnerMsgResProps.body.notification.error.zipCode.empty)
        .matches(
          SetupValidations.sites.zipCode,
          SiteOwnerMsgResProps.body.notification.error.zipCode.invalid
        ),
      locationLatitude: Yup.string()
        .trim()
        .required(
          SiteOwnerMsgResProps.body.notification.error.locationLatitude.empty
        )
        .matches(
          SetupValidations.sites.locationLatitude,
          SiteOwnerMsgResProps.body.notification.error.locationLatitude.invalid
        ),
      locationLongitude: Yup.string()
        .trim()
        .required(
          SiteOwnerMsgResProps.body.notification.error.locationLongitude.empty
        )
        .matches(
          SetupValidations.sites.locationLongitude,
          SiteOwnerMsgResProps.body.notification.error.locationLongitude.invalid
        ),
      sitePhone: Yup.string().matches(
        SetupValidations.sites.sitePhone,
        SiteOwnerMsgResProps.body.notification.error.sitePhone.invalid
      ),
    };
    return schema;
  };

  handleConfirmation = async (values, helpers) => {
    await this.setStateAsync({
      currentSiteOwnerOrganizationId: values.siteOwnerOrganizationId,
    });
    this._helpers = helpers;
    const siteObj = {
      siteId: this.state.site.siteId,
      siteName: values.siteName,
      siteNumber: values.siteNumber,
      addressCode: values.addressCode,
      customerNumber: values.customerNumber,
      highFlowDispenserFlowrate: parseInt(values.highFlowDispenserFlowrate),
      lowFlowDispenserFlowrate: parseInt(values.lowFlowDispenserFlowrate),
      siteContractedStatus: parseInt(values.siteContractedStatus),
      streetAddress1: values.streetAddress1,
      streetAddress2: values.streetAddress2,
      city: values.city,
      state: values.state,
      country: values.country,
      zipCode: values.zipCode,
      sitePhone: values.sitePhone,
      locationLatitude: values.locationLatitude,
      locationLongitude: values.locationLongitude,
      siteNote: values.siteNote,
      connectivityDate:
        values.connectivityDate !== "Invalid date"
          ? values.connectivityDate
          : "",
      siteOwnerOrganizationId: values.siteOwnerOrganizationId,
      serviceProviderOrganizationId: values.serviceProviderOrganizationId
        ? values.serviceProviderOrganizationId
        : null,
      lastModifyTimestamp: this.state.site.modifyTimestamp,
      previousOrganizationId: this.state.previousOrganizationId,
      previousOrganizationName: this.state.previousOrganizationName,
      currentOrganizationName: this.state.currentOrganizationName,
    };

    await this.setStateAsync({ tempSiteObj: siteObj });
    this.openConfirmModal();
  };

  handleSubmit = async () => {
    const siteObj = this.state.tempSiteObj;
    const helpers = this._helpers;
    this.closeConfirmModal();
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }
    let timezone = null;
    if (
      siteObj.locationLatitude.toString().length > 0 &&
      siteObj.locationLongitude.toString().length > 0
    ) {
      timezone = await getPlaceTimezone(
        siteObj.locationLatitude,
        siteObj.locationLongitude,
        this._cancelToken
      );
    }
    if (timezone && timezone.timeZoneId) {
      siteObj.siteTimezone = timezone.timeZoneId;
    } else {
      siteObj.siteTimezone = null;
    }
    await editSite(siteObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = [
            "S",
            SiteOwnerMsgResProps.body.notification.success.editSite,
          ];
          if (res.data.result) {
            this.setStateAsync({ site: res.data.result });
            await this.setStateAsync({
              previousSiteOwnerOrganizationId:
                res.data.result.siteOwnerOrganization.organizationId,
            });
          }
          if (this._isMounted) {
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          SiteOwnerMsgResProps.body.notification.error.message,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);

          if (err.data && err.data.errors && err.data.errors.length > 0) {
            const errors = err.data.errors;
            errors.forEach((error) => {
              if (error.element && error.message && error.location === "body") {
                helpers.setFieldError(error.element, error.message);
              }
            });
          }
          PageUtils.scrollToTop();
        }
      });
    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  handleChangeFlowRate = async (e, formik) => {
    e.persist();
    await this.setStateAsync({
      configureFlowRate: {
        previousValue: formik.values[e.target.name],
        currentValue: e.target.value,
        selectedFlow: e.target.name,
      },
    });
    this.updateFlowRateModal();
  };

  updateFlowRateModal = async () => {
    await this.setStateAsync({
      isOpenFlowRateModal: !this.state.isOpenFlowRateModal,
    });
  };

  updateFlowRateStatus = async (formik) => {
    await formik.setFieldValue(
      this.state.configureFlowRate.selectedFlow,
      this.state.configureFlowRate.currentValue
    );
    this.updateFlowRateModal();
  };

  updateOrganisation = async (formik, organizationId) => {
    await formik.setFieldValue("siteOwnerOrganizationId", organizationId);
    const filterCustomerNumber = [];
    await this.state.siteOwnerOrganizations?.forEach((obj) => {
      if (obj.organizationId === parseInt(organizationId)) {
        return filterCustomerNumber.push(obj);
      }
    });
    await this.setStateAsync({
      currentOrganizationName: filterCustomerNumber[0]?.organizationTreepath,
    });
    await formik.setFieldValue(
      "customerNumber",
      filterCustomerNumber[0]?.customerNumber
        ? filterCustomerNumber[0]?.customerNumber
        : ""
    );
  };

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            {this.state.site ? (
              <>
                <Card className="sram-page-form">
                  <CardTitle>
                    <span>
                      {SiteOwnerMsgResProps.body.content.siteOwnerEditSite}
                    </span>
                    <span className="float-right">
                      {PageDisplay.showGoBackLink(
                        "L",
                        SetupURLProps.siteOwners.listSite,
                        this.props.history
                      )}
                    </span>
                  </CardTitle>
                  <CardBody>
                    <Formik
                      initialValues={this.state.formInputFields}
                      validationSchema={Yup.object().shape(
                        this.formValidationSchema()
                      )}
                      onSubmit={(values, helpers) =>
                        this.handleConfirmation(values, helpers)
                      }
                    >
                      {(formik) => (
                        <Form id="siteOwnerSiteForm" {...formEvent}>
                          <div className="sram-form-main">
                            <div>
                              {formik.status &&
                              Array.isArray(formik.status) &&
                              formik.status.length === 2 &&
                              (formik.status[0] === "E" ||
                                formik.status[0] === "S") ? (
                                <Row>
                                  <Col xs={12}>
                                    {formik.status[0] === "E"
                                      ? PageDisplay.showErrorNotification(
                                          formik.status[1]
                                        )
                                      : PageDisplay.showSuccessNotification(
                                          formik.status[1]
                                        )}
                                  </Col>
                                </Row>
                              ) : null}
                            </div>
                            <div>
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="siteOwnerOrganizationId">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .companyDivision.label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="siteOwnerOrganizationId"
                                      as="select"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.siteOwnerOrganizationId,
                                        formik.touched.siteOwnerOrganizationId
                                      )}
                                      onChange={(e) =>
                                        this.updateOrganisation(
                                          formik,
                                          e.target.value
                                        )
                                      }
                                      value={
                                        formik.values.siteOwnerOrganizationId
                                      }
                                    >
                                      <option value=""></option>
                                      {this.state.siteOwnerOrganizations &&
                                      this.state.siteOwnerOrganizations.length >
                                        0
                                        ? this.state.siteOwnerOrganizations.map(
                                            (owner, index) => {
                                              return (
                                                <option
                                                  value={owner.organizationId}
                                                  key={`key-soso-option-${index}`}
                                                >
                                                  {owner.organizationTreepath}
                                                </option>
                                              );
                                            }
                                          )
                                        : null}
                                    </Field>
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "siteOwnerOrganizationId"
                                    )}
                                  </FormGroup>
                                </Col>

                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="siteNumber">
                                      {
                                        SiteOwnerMsgResProps.body.form.siteId
                                          .label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="siteNumber"
                                      type="text"
                                      maxLength="50"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.siteNumber,
                                        formik.touched.siteNumber
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "siteNumber"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>

                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="siteName">
                                      {
                                        SiteOwnerMsgResProps.body.form.siteName
                                          .label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="siteName"
                                      type="text"
                                      maxLength="50"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.siteName,
                                        formik.touched.siteName
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "siteName"
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="serviceProviderOrganizationId">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .serviceProvider.label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="serviceProviderOrganizationId"
                                      type="text"
                                      as="select"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors
                                          .serviceProviderOrganizationId,
                                        formik.touched
                                          .serviceProviderOrganizationId
                                      )}
                                      disabled={
                                        this.state.site
                                          .serviceProviderOrganization
                                          ? true
                                          : false
                                      }
                                    >
                                      <option value="">
                                        {/* {
                                          SiteOwnerMsgResProps.body.form
                                            .serviceProvider.optionNone
                                        } */}
                                      </option>
                                      {this.state.serviceProviderCompanies &&
                                      this.state.serviceProviderCompanies
                                        .length > 0
                                        ? this.state.serviceProviderCompanies.map(
                                            (provider, index) => {
                                              return (
                                                <option
                                                  value={
                                                    provider.organizationId
                                                  }
                                                  key={`key-sosp-option-${index}`}
                                                >
                                                  {provider.organizationName}
                                                </option>
                                              );
                                            }
                                          )
                                        : null}
                                    </Field>
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "serviceProviderOrganizationId"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="addressCode">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .addressCode.label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="addressCode"
                                      type="text"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.addressCode,
                                        formik.touched.addressCode
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "addressCode"
                                    )}
                                  </FormGroup>
                                </Col>

                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="customerNumber">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .customerNumber.label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="customerNumber"
                                      type="text"
                                      disabled={
                                        formik.values.customerNumber &&
                                        formik.values.customerNumber.length > 6
                                      }
                                      value={formik.values.customerNumber}
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.customerNumber,
                                        formik.touched.customerNumber
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "customerNumber"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="lowFlowDispenserFlowrate">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .lowFlowDispenserFlowrate.label
                                      }
                                    </Label>
                                    <Field
                                      name="lowFlowDispenserFlowrate"
                                      type="text"
                                      as="select"
                                      onChange={(e) =>
                                        this.handleChangeFlowRate(e, formik)
                                      }
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.lowFlowDispenserFlowrate,
                                        formik.touched.lowFlowDispenserFlowrate
                                      )}
                                      value={
                                        formik.values.lowFlowDispenserFlowrate
                                      }
                                    >
                                      {this.state.lowFlowDispenserFlowrate &&
                                      this.state.lowFlowDispenserFlowrate
                                        .length > 0
                                        ? this.state.lowFlowDispenserFlowrate.map(
                                            (provider, index) => {
                                              return (
                                                <option
                                                  value={provider.key}
                                                  key={`key-sosp-option-${index}`}
                                                >
                                                  {provider.value}
                                                </option>
                                              );
                                            }
                                          )
                                        : null}
                                    </Field>
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "lowFlowDispenserFlowrate"
                                    )}
                                  </FormGroup>
                                </Col>

                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="highFlowDispenserFlowrate">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .highFlowDispenserFlowrate.label
                                      }
                                    </Label>
                                    <Field
                                      name="highFlowDispenserFlowrate"
                                      type="text"
                                      as="select"
                                      onChange={(e) =>
                                        this.handleChangeFlowRate(e, formik)
                                      }
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.highFlowDispenserFlowrate,
                                        formik.touched.highFlowDispenserFlowrate
                                      )}
                                      value={
                                        formik.values.highFlowDispenserFlowrate
                                      }
                                    >
                                      {this.state.highFlowDispenserFlowrate &&
                                      this.state.highFlowDispenserFlowrate
                                        .length > 0
                                        ? this.state.highFlowDispenserFlowrate.map(
                                            (provider, index) => {
                                              return (
                                                <option
                                                  value={provider.key}
                                                  key={`key-sosp-option-${index}`}
                                                >
                                                  {provider.value}
                                                </option>
                                              );
                                            }
                                          )
                                        : null}
                                    </Field>
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "highFlowDispenserFlowrate"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row form>
                                <Col sm={12} md={12} lg={12} className="pt-3">
                                  <GeoAddressSearchField
                                    callback={this.populateAddressFields}
                                    formik={formik}
                                  />
                                </Col>
                              </Row>
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="streetAddress1">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .streetAddress1.label
                                      }
                                    </Label>
                                    <Field
                                      name="streetAddress1"
                                      type="text"
                                      maxLength="100"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.streetAddress1,
                                        formik.touched.streetAddress1
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "streetAddress1"
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="streetAddress2">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .streetAddress2.label
                                      }
                                    </Label>
                                    <Field
                                      name="streetAddress2"
                                      type="text"
                                      maxLength="100"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.streetAddress2,
                                        formik.touched.streetAddress2
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "streetAddress2"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="city">
                                      {
                                        SiteOwnerMsgResProps.body.form.city
                                          .label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="city"
                                      type="text"
                                      maxLength="50"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.city,
                                        formik.touched.city
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "city"
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="state">
                                      {
                                        SiteOwnerMsgResProps.body.form.state
                                          .label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="state"
                                      as="select"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.state,
                                        formik.touched.state
                                      )}
                                    >
                                      <option value=""></option>
                                      {AppDataProps.address.states &&
                                      AppDataProps.address.states.length > 0
                                        ? AppDataProps.address.states.map(
                                            (state, index) => {
                                              return (
                                                <option
                                                  value={state}
                                                  key={`key-addr-state-${index}`}
                                                >
                                                  {state}
                                                </option>
                                              );
                                            }
                                          )
                                        : null}
                                    </Field>
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "state"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="country">
                                      {
                                        SiteOwnerMsgResProps.body.form.country
                                          .label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="country"
                                      as="select"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.country,
                                        formik.touched.country
                                      )}
                                    >
                                      <option value=""></option>
                                      {AppDataProps.address.countries &&
                                      AppDataProps.address.countries.length > 0
                                        ? AppDataProps.address.countries.map(
                                            (country, index) => {
                                              return (
                                                <option
                                                  value={country}
                                                  key={`key-addr-country-${index}`}
                                                >
                                                  {country}
                                                </option>
                                              );
                                            }
                                          )
                                        : null}
                                    </Field>
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "country"
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="zipCode">
                                      {
                                        SiteOwnerMsgResProps.body.form.zipCode
                                          .label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="zipCode"
                                      type="text"
                                      maxLength="5"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.zipCode,
                                        formik.touched.zipCode
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "zipCode"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="locationLatitude">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .locationLatitude.label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="locationLatitude"
                                      type="text"
                                      maxLength="20"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.locationLatitude,
                                        formik.touched.locationLatitude
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "locationLatitude"
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="locationLongitude">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .locationLongitude.label
                                      }{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="locationLongitude"
                                      type="text"
                                      maxLength="20"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.locationLongitude,
                                        formik.touched.locationLongitude
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "locationLongitude"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="sitePhone">
                                      {
                                        SiteOwnerMsgResProps.body.form.sitePhone
                                          .label
                                      }
                                    </Label>
                                    <Field
                                      name="sitePhone"
                                      type="text"
                                      maxLength="10"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.sitePhone,
                                        formik.touched.sitePhone
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "sitePhone"
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="siteNote">
                                      {
                                        SiteOwnerMsgResProps.body.form.siteNote
                                          .label
                                      }
                                    </Label>
                                    <Field
                                      name="siteNote"
                                      as="textarea"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.siteNote,
                                        formik.touched.siteNote
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "siteNote"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="connectivityDate">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .connectivityDate.label
                                      }
                                    </Label>
                                    <Field
                                      name="connectivityDate"
                                      type="date"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.connectivityDate,
                                        formik.touched.connectivityDate
                                      )}
                                      disabled={true}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          "connectivityDate",
                                          e.target.value
                                        )
                                      }
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "connectivityDate"
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="siteContractedStatus">
                                      {
                                        SiteOwnerMsgResProps.body.form
                                          .siteContractedStatus.label
                                      }
                                    </Label>
                                    <Field
                                      name="siteContractedStatus"
                                      type="text"
                                      as="select"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.siteContractedStatus,
                                        formik.touched.siteContractedStatus
                                      )}
                                      value={formik.values.siteContractedStatus}
                                    >
                                      {this.state.siteContractedStatus &&
                                      this.state.siteContractedStatus.length > 0
                                        ? this.state.siteContractedStatus.map(
                                            (site, index) => {
                                              return (
                                                <option
                                                  value={site.key}
                                                  key={`key-sosp-option-${index}`}
                                                >
                                                  {site.value}
                                                </option>
                                              );
                                            }
                                          )
                                        : null}
                                    </Field>
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "lowFlowDispenserFlowrate"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div className="sram-form-foot">
                            {PageDisplay.showSaveButton(formik.isSubmitting)}
                            {PageDisplay.showCancelButton(this.props.history)}
                          </div>
                          <FormFieldFocusError />
                          {this.state.isOpenFlowRateModal ? (
                            <Modal
                              isOpen={this.state.isOpenFlowRateModal}
                              toggle={() => this.updateFlowRateModal()}
                              size="md"
                              backdrop="static"
                              centered={false}
                              className="sram-modal-content"
                            >
                              <ModalHeader>
                                {AppMsgResProps.body.content.confirmation}
                              </ModalHeader>
                              <ModalBody className="mb-2">{`Are you sure you want to update ${
                                this.state.configureFlowRate.selectedFlow ===
                                "lowFlowDispenserFlowrate"
                                  ? "Standard Dispenser Flow Rate"
                                  : "High Flow Dispenser Flow Rate"
                              } from ${
                                this.state.configureFlowRate.previousValue
                              } to ${
                                this.state.configureFlowRate.currentValue
                              } ?  This change will be applicable for data moving forward only.`}</ModalBody>
                              <ModalFooter className="m-1 p-2">
                                <Button
                                  type="button"
                                  className="sram-btn-blue mx-2"
                                  onClick={() =>
                                    this.updateFlowRateStatus(formik)
                                  }
                                >
                                  {AppMsgResProps.body.form.yes.label}
                                </Button>
                                <Button
                                  type="button"
                                  className="sram-btn-white mx-2"
                                  onClick={() => this.updateFlowRateModal()}
                                >
                                  {AppMsgResProps.body.form.no.label}
                                </Button>
                              </ModalFooter>
                            </Modal>
                          ) : null}
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
                {this.state.tempSiteObj ? (
                  <ConfirmModal
                    isOpenConfirmModal={this.state.isOpenConfirmModal}
                    closeConfirmModal={this.closeConfirmModal}
                    handleSubmit={this.handleSubmit}
                  >
                    <div>
                      <div>
                        <Label>
                          {this.state.currentSiteOwnerOrganizationId ===
                          this.state.previousSiteOwnerOrganizationId ? (
                            <span>
                              {
                                SiteOwnerMsgResProps.body.notification
                                  .confirmation.editSiteTitle
                              }
                            </span>
                          ) : (
                            <span>
                              {
                                SiteOwnerMsgResProps.body.notification
                                  .confirmation.editSiteOwner
                              }
                            </span>
                          )}
                        </Label>
                      </div>
                    </div>
                  </ConfirmModal>
                ) : null}
              </>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  SiteOwnerMsgResProps.body.content.noDataFound
                )}
              </>
            )}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default SiteOwnerSiteEdit;
