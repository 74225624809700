export const SiteOwnerMsgResProps = {
  head: {
    title: {
      siteOwnerList: "Setup - Site Owners - List",
      siteOwnerAdd: "Setup - Site Owners - Add",
      siteOwnerView: "Setup - Site Owners - View",
      siteOwnerEdit: "Setup - Site Owners - Edit",
    },
  },
  body: {
    content: {
      siteOwners: "Site Owners",
      siteOwnerCompanies: "Site Owner - Companies",
      siteOwnerDivisions: "Site Owner - Divisions",
      siteOwnerSites: "Sites",
      siteOwnerAddCompany: "Site Owner - New Company",
      siteOwnerViewCompany: "Site Owner - Company Details",
      siteOwnerEditCompany: "Site Owner - Edit Company",
      siteOwnerAddDivision: "Site Owner - New Division",
      siteOwnerViewDivision: "Site Owner - Division Details",
      siteOwnerEditDivision: "Site Owner - Edit Division",
      siteOwnerAddSite: "New Site",
      siteOwnerViewSite: "Site Details",
      siteOwnerEditSite: "Edit Site",
      addCompany: "Add Company",
      viewCompany: "View Company Details",
      editCompany: "Edit Company",
      addDivision: "Add Division",
      viewDivision: "View Division Details",
      editDivision: "Edit Division",
      addSite: "Add Site",
      uploadSoftwareVersions: "Upload Software Versions",
      viewSite: "View Site Details",
      editSite: "Edit Site",
      noDataFound: "No issues found.",
      noCompaniesFound: "No Companies found",
      noCompanyFound: "Company information not found",
      noDivisionsFound: "No Divisons found",
      noDivisionFound: "Division information not found",
      noSitesFound: "No Sites found",
      noSiteFound: "Site information not found",
      siteAccess: "Site Access Privileges",
      userAccess: "User Access Privileges",
      noUsersFound: "No Users found",
      siteView: "View Sites",
    },
    form: {
      companyType: {
        label: "Company Type",
      },
      entityType: {
        label: "Entity Type",
      },
      organizationName: {
        label: "Organization Name",
      },
      companyName: {
        label: "Company Name",
      },
      company: {
        label: "Company",
      },
      divisionName: {
        label: "Division Name",
      },
      division: {
        label: "Division",
      },
      siteName: {
        label: "Site Name",
      },
      siteAddress: {
        label: "Site Address",
      },
      siteId: {
        label: "GVR ID",
      },
      addressCode: {
        label: "Address Code",
      },
      customerNumber: {
        label: "Customer Number",
      },
      highFlowDispenserFlowrate: {
        label: "High Flow Dispenser Flow Rate",
      },
      lowFlowDispenserFlowrate: {
        label: "Standard Dispenser Flow Rate",
      },
      siteContractedStatus: {
        label: "Contracted Status",
      },
      contracted: {
        label: "Contracted",
      },
      standardFlow: {
        label: "Standard Flow",
      },
      highFlow: {
        label: "High Flow",
      },
      site: {
        label: "Site",
      },
      companyDivision: {
        label: "Company / Division",
      },
      serviceProvider: {
        label: "Service Provider",
        optionNone: "None",
      },
      parent: {
        label: "Parent",
      },
      parentDivision: {
        label: "Parent Division",
        optionNone: "None",
      },
      parentCompanyDivision: {
        label: "Parent Company / Division",
      },
      businessName: {
        label: "Business Name",
      },
      streetAddress1: {
        label: "Street Address 1",
      },
      streetAddress2: {
        label: "Street Address 2",
      },
      city: {
        label: "City",
      },
      state: {
        label: "State",
      },
      country: {
        label: "Country",
      },
      zipCode: {
        label: "ZIP Code",
      },
      locationLatitude: {
        label: "Latitude",
      },
      locationLongitude: {
        label: "Longitude",
      },
      siteTimezone: {
        label: "Time Zone",
      },
      sitePhone: {
        label: "Phone",
      },
      siteNote: {
        label: "Notes",
      },
      connectivityDate: {
        label: "Connectivity Date",
      },
      user: {
        label: "User",
      },
      userName: {
        label: "User Name",
      },
      firstName: {
        label: "First Name",
      },
      lastName: {
        label: "Last Name",
      },
      email: {
        label: "Email",
      },
    },
    notification: {
      confirmation: {
        addCompanyTitle: "Are you sure you want to add the Company details ?",
        editCompanyTitle:
          "Are you sure you want to update the Company details ?",
        addDivisionTitle: "Are you sure you want to add the Division details ?",
        addDivisionNote:
          "Parent Company / Division data cannot be modified later.",
        editDivisionTitle:
          "Are you sure you want to update the Division details ?",
        addSiteTitle: "Are you sure you want to add the Site details ?",
        editSiteTitle: "Are you sure you want to update the Site details ?",
        editSiteOwner:
          "Are you sure you want to update the Site Owner & Site details ?",
        editSiteAccess:
          "Are you sure you to update the Site Access Privileges ?",
      },
      success: {
        addCompany: "Company is added successfully.",
        editCompany: "Company is modified successfully.",
        addDivision: "Division is added successfully.",
        editDivision: "Division is modified successfully.",
        addSite: "Site is added successfully.",
        editSite: "Site is modified successfully.",
        editSiteAccess: "Site Access Privileges are modified successfully.",
      },
      error: {
        message: "An error has occured.",
        companyType: {
          empty: "Company Type is required.",
        },
        entityType: {
          empty: "Entity Type is required.",
        },
        companyName: {
          empty: "Company Name is required.",
          invalid: "Company Name is not valid.",
          minLength: "Company Name should be at least 2 characters.",
          duplicate: "Company Name already exists.",
        },
        serviceProviderOrganizationName: {
          empty: "Service Provider is required.",
          invalid: "Service Provider is not valid.",
          minLength: "Service Provider should be at least 2 characters.",
          duplicate: "Service Provider already exists.",
        },
        company: {
          empty: "Company is required.",
        },
        parentCompanyDivision: {
          empty: "Parent Company / Division is required.",
        },
        companyDivision: {
          empty: "Company / Division is required.",
        },
        divisionName: {
          empty: "Division Name is required.",
          invalid: "Division Name is not valid.",
          minLength: "Division Name should be at least 2 characters.",
          duplicate: "Division Name already exists.",
        },
        siteName: {
          empty: "Site Name is required.",
          invalid: "Site Name is not valid.",
          minLength: "Site Name should be at least 2 characters.",
          duplicate: "Site Name already exists.",
        },
        siteId: {
          empty: "GVR ID is required.",
          invalid: "GVR ID is not valid.",
          minLength: "GVR ID should be at least 1 characters.",
          duplicate: "GVR ID already exists.",
        },
        addressCode: {
          empty: "Address Code is required.",
          invalid: "Address Code is not valid.",
          minLength: "Address Code should be at least 1 characters.",
          duplicate: "Address Code already exists.",
        },
        customerNumber: {
          empty: "Customer Number is required.",
          invalid: "Customer Number is not valid.",
          minLength: "Customer Number should be at least 1 characters.",
          duplicate: "Customer Number already exists.",
        },
        highFlowDispenserFlowrate: {
          empty: "High Flow Dispenser Flow Rate is required.",
          invalid: "High Flow Dispenser Flow Rate is not valid.",
          minLength:
            "High Flow Dispenser Flow Rate should be at least 1 characters.",
          duplicate: "High Flow Dispenser Flow Rate already exists.",
        },
        lowFlowDispenserFlowrate: {
          empty: "Standard Dispenser Flow Rate is required.",
          invalid: "Standard Dispenser Flow Rate is not valid.",
          minLength:
            "Standard Dispenser Flow Rate should be at least 1 characters.",
          duplicate: "Standard Dispenser Flow Rate already exists.",
        },
        city: {
          empty: "City is required.",
        },
        state: {
          empty: "State is required.",
        },
        country: {
          empty: "Country is required.",
        },
        zipCode: {
          empty: "ZIP Code is required.",
          invalid: "ZIP Code is not valid.",
        },
        locationLatitude: {
          empty: "Latitude is required.",
          invalid: "Latitude is not valid.",
        },
        locationLongitude: {
          empty: "Longitude is required.",
          invalid: "Longitude is not valid.",
        },
        sitePhone: {
          invalid: "Phone is not valid.",
        },
      },
    },
  },
};
