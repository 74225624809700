export const AppURLProps = {
  home: "/",
  login: "/login",
  error: "/error",
  pageNotFound: "/page-not-found",
  logout: "/logout",
  recoverPassword: "/recover-password",
  myAccount: "/my-account",
  changePassword: "/my-account/change-password",
  updateProfile: "/my-account/update-profile",
  joinNow: "/join-now",
};
