import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { siteImportSteps } from '../messages/site-import-properties';

const MultiStepProgressBar = props => {
  var stepPercentage = 0;

  if (props.currentStep === 1) {
    stepPercentage = 0;
  } else if (props.currentStep === 2) {
    stepPercentage = 16.67;
  } else if (props.currentStep === 3) {
    stepPercentage = 33.34;
  } else if (props.currentStep === 4) {
    stepPercentage = 50;
  } else if (props.currentStep === 5) {
    stepPercentage = 66.67;
  } else if (props.currentStep === 6) {
    stepPercentage = 83.35;
  } else if (props.currentStep === 7) {
    stepPercentage = 100;
  } else {
    stepPercentage = 0;
  }

  return (
    <ProgressBar percent={stepPercentage}>
      {siteImportSteps.map((obj, index) => {
        return (
          <Step key={index}>
            {({ accomplished, index }) => (
              <div
                className={`sram-sites-import-indexedStep ${accomplished ? "accomplished" : null}`}
                key={index}
              >
                {obj.value}
              </div>
            )}
          </Step>
        )
      })}
    </ProgressBar>
  );
};

export default MultiStepProgressBar;
