import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import _ from "lodash";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import ConfirmModal from "../../core/helpers/confirm-modal";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import { SetupURLProps } from "../settings/setup-urls";
import { SetupValidations } from "../settings/setup-validations";
import { SetupConstants } from "../settings/setup-constants";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { RoleMsgResProps } from "../messages/role-properties";
import { manageError } from "../../core/actions/common-actions";
import { isPagePartPrivileged } from "../../core/actions/identity-actions";
import { addRole } from "../actions/role-actions";
import { listParts } from "../actions/app-part-actions";

class RoleAdd extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _helpers = null;
  _pcRoleAdd = "AS-ROL-RADD";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isOpenConfirmModal: false,
      appPartsAll: null,
      appPartsAvailable: null,
      formInputFields: this.initFormInputFields(),
      tempRoleObj: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ appPartsAll: null });
    await this.checkPartPrivilege();
    const queryData = null;
    await listParts(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ appPartsAll: res.data.records });
            await this.loadParts(SetupConstants.roles.ROLE_TYPE_SITEIQ);
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  loadParts = async (roleType, formik) => {
    await this.setStateAsync({ appPartsAvailable: null });
    let appParts = _.cloneDeep(this.state.appPartsAll); //JSON.parse(JSON.stringify(this.state.appPartsAll));
    let selActionId = [];
    let partModules = [];

    await appParts.forEach(async (module) => {
      if (module && module.features && module.features.length > 0) {
        let partFeatures = [];
        await module.features.forEach(async (feature) => {
          if (feature && feature.actions && feature.actions.length > 0) {
            let partActions = [];
            await feature.actions.forEach(async (action) => {
              if (action) {
                let actionAvailable = false;
                if (roleType === SetupConstants.roles.ROLE_TYPE_SITEIQ) {
                  actionAvailable = true;
                } else if (
                  roleType === SetupConstants.roles.ROLE_TYPE_SITEOWNER
                ) {
                  if (
                    SetupValidations.roles.PartCodesNotApplicable.siteOwner.includes(
                      module.moduleCode +
                        "-" +
                        feature.featureCode +
                        "-" +
                        action.actionCode
                    ) === false
                  ) {
                    actionAvailable = true;
                  }
                } else if (
                  roleType === SetupConstants.roles.ROLE_TYPE_SERVICEPROVIDER
                ) {
                  if (
                    SetupValidations.roles.PartCodesNotApplicable.serviceProvider.includes(
                      module.moduleCode +
                        "-" +
                        feature.featureCode +
                        "-" +
                        action.actionCode
                    ) === false
                  ) {
                    actionAvailable = true;
                  }
                }
                if (actionAvailable === true) {
                  let partAction = action;
                  partActions.push(partAction);
                  if (feature.defaultStatus === 1)
                    selActionId.push(action.actionId);
                }
              }
            });
            if (partActions.length > 0) {
              let partFeature = feature;
              partFeature.actions = [...partActions];
              partFeatures.push(partFeature);
            }
          }
        });
        if (partFeatures.length > 0) {
          let partModule = module;
          partModule.features = [...partFeatures];
          partModules.push(partModule);
        }
      }
    });
    await this.setStateAsync({ appPartsAvailable: [...partModules] });

    if (selActionId && selActionId.length > 0)
      if (formik) {
        formik.setFieldValue("actionId", selActionId);
      } else {
        await this.setStateAsync({
          formInputFields: {
            ...this.state.formInputFields,
            actionId: selActionId,
          },
        });
      }
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcRoleAdd}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  initFormInputFields = () => {
    return {
      roleType: SetupConstants.roles.ROLE_TYPE_SITEIQ,
      roleName: "",
      actionId: [],
    };
  };

  formValidationSchema = () => {
    const schema = {
      roleName: Yup.string()
        .trim()
        .required(RoleMsgResProps.body.notification.error.roleName.empty)
        .matches(
          SetupValidations.roles.roleName,
          RoleMsgResProps.body.notification.error.roleName.invalid
        ),
      roleType: Yup.string().required(
        RoleMsgResProps.body.notification.error.roleType.empty
      ),
    };
    return schema;
  };

  handleConfirmation = async (values, helpers) => {
    this._helpers = helpers;
    const roleObj = {
      roleType: values.roleType,
      roleName: values.roleName,
      actionId: values.actionId,
    };
    await this.setStateAsync({ tempRoleObj: roleObj });
    this.openConfirmModal();
  };

  handleSubmit = async () => {
    const roleObj = this.state.tempRoleObj;
    const helpers = this._helpers;
    this.closeConfirmModal();
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }

    await addRole(roleObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = [
            "S",
            RoleMsgResProps.body.notification.success.addRole,
          ];
          if (this._isMounted) {
            helpers.resetForm();
            if (
              document.getElementsByName("selectAll") &&
              document.getElementsByName("selectAll").length > 0
            ) {
              for (
                let i = 0;
                i < document.getElementsByName("selectAll").length;
                i++
              ) {
                let elem = document.getElementsByName("selectAll")[i];
                elem.checked = false;
              }
            }
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          RoleMsgResProps.body.notification.error.message,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);

          if (err.data && err.data.errors && err.data.errors.length > 0) {
            const errors = err.data.errors;
            errors.forEach((error) => {
              if (error.element && error.message && error.location === "body") {
                helpers.setFieldError(error.element, error.message);
              }
            });
          }
          PageUtils.scrollToTop();
        }
      });

    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  toggleMultipleActions = async (e, feature) => {
    if (feature.actions && feature.actions.length > 0) {
      if (e.target.checked) {
        for (let i = 0; i < feature.actions.length; i++) {
          let action = feature.actions[i];
          if (
            document.getElementById("check_" + action.actionId).disabled ===
            false
          ) {
            if (
              document.getElementById("check_" + action.actionId).checked ===
              false
            )
              await document.getElementById("check_" + action.actionId).click();
          }
        }
      } else {
        for (let i = 0; i < feature.actions.length; i++) {
          let action = feature.actions[i];
          if (
            document.getElementById("check_" + action.actionId).disabled ===
            false
          ) {
            if (
              document.getElementById("check_" + action.actionId).checked ===
              true
            )
              await document.getElementById("check_" + action.actionId).click();
          }
        }
      }
    }
  };

  toggleSingleAction = async (e, action, formik, arrayHelpers) => {
    if (e.target.checked) await arrayHelpers.push(action.actionId);
    else {
      const idx = formik.values.actionId.indexOf(action.actionId);
      await arrayHelpers.remove(idx);
    }
  };

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <Card className="sram-page-form">
              <CardTitle>
                <span>{RoleMsgResProps.body.content.addRole}</span>
                <span className="float-right">
                  {PageDisplay.showGoBackLink(
                    "L",
                    SetupURLProps.roles.listRole,
                    this.props.history
                  )}
                </span>
              </CardTitle>
              <CardBody>
                <Formik
                  initialValues={this.state.formInputFields}
                  validationSchema={Yup.object().shape(
                    this.formValidationSchema()
                  )}
                  onSubmit={(values, helpers) =>
                    this.handleConfirmation(values, helpers)
                  }
                >
                  {(formik) => (
                    <Form id="roleForm" {...formEvent}>
                      <div className="sram-form-main">
                        <div>
                          {formik.status &&
                          Array.isArray(formik.status) &&
                          formik.status.length === 2 &&
                          (formik.status[0] === "E" ||
                            formik.status[0] === "S") ? (
                            <Row>
                              <Col xs={12}>
                                {formik.status[0] === "E"
                                  ? PageDisplay.showErrorNotification(
                                      formik.status[1]
                                    )
                                  : PageDisplay.showSuccessNotification(
                                      formik.status[1]
                                    )}
                              </Col>
                            </Row>
                          ) : null}
                        </div>
                        <div>
                          <Row form>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="roleName">
                                  {RoleMsgResProps.body.form.roleName.label} *
                                </Label>
                                <Field
                                  name="roleName"
                                  type="text"
                                  maxLength="50"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.roleName,
                                    formik.touched.roleName
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "roleName"
                                )}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="roleType">
                                  {RoleMsgResProps.body.form.roleType.label}
                                </Label>
                                <Field
                                  name="roleType"
                                  type="text"
                                  as="select"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.roleType,
                                    formik.touched.roleType
                                  )}
                                  value={formik.values.roleType}
                                  onChange={async (e) => {
                                    formik.setFieldValue(
                                      "roleType",
                                      e.target.value
                                    );
                                    await this.loadParts(
                                      e.target.value,
                                      formik
                                    );
                                  }}
                                >
                                  <option
                                    value={
                                      SetupConstants.roles.ROLE_TYPE_SITEIQ
                                    }
                                  >
                                    {
                                      RoleMsgResProps.body.form.roleType
                                        .options[
                                        SetupConstants.roles.ROLE_TYPE_SITEIQ
                                      ]
                                    }
                                  </option>
                                  <option
                                    value={
                                      SetupConstants.roles.ROLE_TYPE_SITEOWNER
                                    }
                                  >
                                    {
                                      RoleMsgResProps.body.form.roleType
                                        .options[
                                        SetupConstants.roles.ROLE_TYPE_SITEOWNER
                                      ]
                                    }
                                  </option>
                                  <option
                                    value={
                                      SetupConstants.roles
                                        .ROLE_TYPE_SERVICEPROVIDER
                                    }
                                  >
                                    {
                                      RoleMsgResProps.body.form.roleType
                                        .options[
                                        SetupConstants.roles
                                          .ROLE_TYPE_SERVICEPROVIDER
                                      ]
                                    }
                                  </option>
                                </Field>
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "roleType"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <div className="mt-3">
                          {this.state.appPartsAvailable &&
                          this.state.appPartsAvailable.length > 0 ? (
                            <FieldArray
                              name="actionId"
                              render={(arrayHelpers) => (
                                <>
                                  <div className="pb-2">
                                    {
                                      RoleMsgResProps.body.content
                                        .accessPrivileges
                                    }
                                  </div>
                                  {this.state.appPartsAvailable.map(
                                    (module, index1) => {
                                      return (
                                        <div
                                          className="mt-1 mb-4 sram-border-blue-3"
                                          key={`key-role-m-${index1}`}
                                        >
                                          <div className="p-2 pl-3 sram-bg-blue-2 font-medium">
                                            {module.moduleName}
                                          </div>
                                          <Row form className="p-2">
                                            {module.features &&
                                            module.features.length > 0 ? (
                                              <>
                                                {module.features.map(
                                                  (feature, index2) => {
                                                    return (
                                                      <Col
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                        key={`key-role-f-${index2}`}
                                                        className="mt-1 mb-3 "
                                                      >
                                                        <div className="sram-bg-blue-1 px-2 py-1">
                                                          <FormGroup
                                                            check
                                                            inline
                                                          >
                                                            <Label check>
                                                              <Input
                                                                name="selectAll"
                                                                type="checkbox"
                                                                value={
                                                                  feature.featureId
                                                                }
                                                                onChange={async (
                                                                  e
                                                                ) => {
                                                                  await this.toggleMultipleActions(
                                                                    e,
                                                                    feature
                                                                  );
                                                                }}
                                                              />
                                                              &nbsp;
                                                              <span className="font-mild">
                                                                {
                                                                  feature.featureName
                                                                }
                                                              </span>
                                                            </Label>
                                                          </FormGroup>
                                                        </div>
                                                        <Row
                                                          form
                                                          className="p-2"
                                                        >
                                                          {feature.actions &&
                                                          feature.actions
                                                            .length > 0 ? (
                                                            <>
                                                              {feature.actions.map(
                                                                (
                                                                  action,
                                                                  index3
                                                                ) => {
                                                                  return (
                                                                    <Col
                                                                      sm={12}
                                                                      md={12}
                                                                      lg={12}
                                                                      key={`key-role-a-${index3}`}
                                                                    >
                                                                      <FormGroup
                                                                        check
                                                                        inline
                                                                      >
                                                                        <Label
                                                                          check
                                                                        >
                                                                          <Input
                                                                            name="actionId"
                                                                            id={`check_${action.actionId}`}
                                                                            type="checkbox"
                                                                            value={
                                                                              action.actionId
                                                                            }
                                                                            disabled={
                                                                              feature.defaultStatus
                                                                            }
                                                                            checked={formik.values.actionId.includes(
                                                                              action.actionId
                                                                            )}
                                                                            onChange={async (
                                                                              e
                                                                            ) => {
                                                                              await this.toggleSingleAction(
                                                                                e,
                                                                                action,
                                                                                formik,
                                                                                arrayHelpers
                                                                              );
                                                                            }}
                                                                          />
                                                                          &nbsp;
                                                                          {
                                                                            action.actionName
                                                                          }
                                                                        </Label>
                                                                      </FormGroup>
                                                                    </Col>
                                                                  );
                                                                }
                                                              )}
                                                            </>
                                                          ) : null}
                                                        </Row>
                                                      </Col>
                                                    );
                                                  }
                                                )}
                                              </>
                                            ) : null}
                                          </Row>
                                        </div>
                                      );
                                    }
                                  )}
                                </>
                              )}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="sram-form-foot">
                        {PageDisplay.showSaveButton(formik.isSubmitting)}
                        {PageDisplay.showCancelButton(this.props.history)}
                      </div>
                      <FormFieldFocusError />
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
            {this.state.tempRoleObj && this.state.appPartsAvailable ? (
              <ConfirmModal
                isOpenConfirmModal={this.state.isOpenConfirmModal}
                closeConfirmModal={this.closeConfirmModal}
                handleSubmit={this.handleSubmit}
              >
                <div>
                  <div>
                    <Label>
                      {
                        RoleMsgResProps.body.notification.confirmation
                          .addRoleTitle
                      }
                    </Label>
                  </div>
                  <div>
                    <Table
                      size="sm"
                      responsive={true}
                      className="border-bottom"
                    >
                      <tbody>
                        <tr>
                          <td width="100">
                            {RoleMsgResProps.body.form.roleName.label}
                          </td>
                          <td className="font-medium">
                            {this.state.tempRoleObj.roleName}
                          </td>
                        </tr>

                        <tr>
                          <td>{RoleMsgResProps.body.form.roleType.label}</td>
                          <td className="font-medium">
                            {
                              RoleMsgResProps.body.form.roleType.options[
                                this.state.tempRoleObj.roleType
                              ]
                            }
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div className="sram-confirm-warn-text">
                    {RoleMsgResProps.body.notification.confirmation.addRoleNote}
                  </div>
                </div>
              </ConfirmModal>
            ) : null}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default RoleAdd;
