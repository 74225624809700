import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";
/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from "../../assets/images/logo-icon.png";
import logolighticon from "../../assets/images/logo-light-icon.png";
import logodarktext from "../../assets/images/logo-text.png";
import logolighttext from "../../assets/images/logo-light-text.png";
import profilephoto from "../../assets/images/users/user.png";
import { AppURLProps } from "../core/settings/app-urls";
import { AppMsgResProps } from "../core/messages/app-properties";
import { IdentityMsgResProps } from "../core/messages/identity-properties";
import Logout from "../core/components/logout";
import { PortalURLProps } from "../portal/settings/portal-urls";
import NavRibbonMenu from './ribbon-menu';

const mapStateToProps = (state) => ({
  ...state,
});

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.showMobilemenu = this.showMobilemenu.bind(this);
    this.sidebarHandler = this.sidebarHandler.bind(this);
    this.toggleLogoutModal = this.toggleLogoutModal.bind(this);

    this.state = {
      isOpen: false,
      logoutModalStatus: false,
      //Static data for the mega menu
      sitePage: null,
      sitesInfo: null,
    };
  }
  /*--------------------------------------------------------------------------------*/
  /*To open NAVBAR in MOBILE VIEW                                                   */
  /*--------------------------------------------------------------------------------*/
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  /*--------------------------------------------------------------------------------*/
  /*To open SIDEBAR-MENU in MOBILE VIEW                                             */
  /*--------------------------------------------------------------------------------*/
  showMobilemenu() {
    document.getElementById("main-wrapper").classList.toggle("show-sidebar");
  }
  sidebarHandler = () => {
    let element = document.getElementById("main-wrapper");
    switch (this.props.layoutSettings.activeSidebarType) {
      case "full":
      case "iconbar":
        element.classList.toggle("mini-sidebar");
        if (element.classList.contains("mini-sidebar")) {
          element.setAttribute("data-sidebartype", "mini-sidebar");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.layoutSettings.activeSidebarType
          );
        }
        break;

      case "overlay":
      case "mini-sidebar":
        element.classList.toggle("full");
        if (element.classList.contains("full")) {
          element.setAttribute("data-sidebartype", "full");
        } else {
          element.setAttribute(
            "data-sidebartype",
            this.props.layoutSettings.activeSidebarType
          );
        }
        break;
      default:
    }
  };

  toggleLogoutModal() {
    this.setState({
      logoutModalStatus: !this.state.logoutModalStatus,
    });
  }
  async componentDidMount() {
    this.setState({ sitesInfo: this.props.layoutSettings.sitesInfo })
    const page = this.props.history?.location?.pathname;
    await this.setState({ sitePage: page });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const page = this.props.history.location.pathname;
      this.setState({ sitePage: page });
    }
  }

  render() {
    return (
      <header
        className="topbar navbarbg"
        data-navbarbg={this.props.layoutSettings.activeNavbarBg}
      >
        <Navbar
          className={
            "top-navbar " +
            (this.props.layoutSettings.activeNavbarBg === "skin6"
              ? "navbar-light"
              : "navbar-dark")
          }
          expand="md"
        >
          <div
            className="navbar-header"
            id="logobg"
            data-logobg={this.props.layoutSettings.activeLogoBg}
          >
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}
            <span
              className="nav-toggler d-block d-md-none"
              onClick={this.showMobilemenu}
            >
              <i className="ti-menu ti-close" />
            </span>
            {/*--------------------------------------------------------------------------------*/}
            {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
            {/*--------------------------------------------------------------------------------*/}
            <NavbarBrand href={AppURLProps.home} className="ml-2">
              <b className="logo-icon">
                <img
                  src={logodarkicon}
                  alt={AppMsgResProps.body.content.siteiq}
                  className="dark-logo"
                />
                <img
                  src={logolighticon}
                  alt={AppMsgResProps.body.content.siteiq}
                  className="light-logo"
                />
              </b>
              <span className="logo-text">
                <img
                  src={logodarktext}
                  alt={AppMsgResProps.body.content.siteiq}
                  className="dark-logo"
                />
                <img
                  src={logolighttext}
                  className="light-logo"
                  alt={AppMsgResProps.body.content.siteiq}
                />
              </span>
            </NavbarBrand>
            {/*--------------------------------------------------------------------------------*/}
            {/* Mobile View Toggler  [visible only after 768px screen]                         */}
            {/*--------------------------------------------------------------------------------*/}
            <span
              className="topbartoggler d-block d-md-none"
              onClick={this.toggle}
            >
              <i className="ti-more" />
            </span>
          </div>
          <Collapse
            className="navbarbg"
            isOpen={this.state.isOpen}
            navbar
            data-navbarbg={this.props.layoutSettings.activeNavbarBg}
          >
            <Nav className="float-left" navbar>
              <NavItem>
                <NavLink
                  href="#"
                  className="d-none d-md-block"
                  onClick={this.sidebarHandler}
                >
                  <i className="ti-menu" />
                </NavLink>
              </NavItem>
            </Nav>
            <h5 className="mt-2" style={{ alignSelf: "center", color:"white"}}>
                    GUARDIAN CONNECT
                  </h5>
            {/*--------------------------------------------------------------------------------*/}
            {/*                         Start Mega Menu Dropdown                               */}
            {/*--------------------------------------------------------------------------------*/}
            {this.state.sitePage === PortalURLProps.sitesHome ?
              < NavRibbonMenu />
              : null}
            {/*--------------------------------------------------------------------------------*/}
            {/*                         End Mega Menu Dropdown                                 */}
            {/*--------------------------------------------------------------------------------*/}
            <Nav className="ml-auto float-right" navbar>
              {/*--------------------------------------------------------------------------------*/}
              {/* Start Profile Dropdown                                                         */}
              {/*--------------------------------------------------------------------------------*/}
              {this.props.identityProfile ? (
                <>
                  <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret className="pro-pic">
                    <img
                      src={profilephoto}
                      alt={IdentityMsgResProps.body.navigation.user}
                      className="rounded-circle"
                      width="31"
                    />
                  </DropdownToggle>
                  <DropdownMenu right className="user-dd">
                    <span className="with-arrow">
                      <span className="bg-primary" />
                    </span>
                    <div className="d-flex no-block align-items-center p-3 mb-2 ">
                      <div className="ml-2">
                        <h5 className="mb-0 pb-2">
                          {this.props.identityProfile.username}
                        </h5>
                        <p className="mb-1">
                          {this.props.identityProfile.firstName}{" "}
                          {this.props.identityProfile.lastName}
                        </p>
                        <p className="mb-0">
                          {this.props.identityProfile.email}
                        </p>
                      </div>
                    </div>
                    <DropdownItem divider />
                    <DropdownItem
                      className="sram-topnav-item"
                      tag={RRNavLink}
                      to={AppURLProps.myAccount}
                    >
                      <i className="ti-user mr-2 ml-1" />
                      {IdentityMsgResProps.body.navigation.myAccount}
                    </DropdownItem>

                    {/* <DropdownItem
                      className="sram-topnav-item"
                      tag={RRNavLink}
                      to={AppURLProps.notification}
                    >
                      <i className="fa fa-comment-dots mr-2 ml-1" />
                      {IdentityMsgResProps.body.navigation.notification}
                    </DropdownItem> */}

                    <DropdownItem
                      className="sram-topnav-item"
                      onClick={this.toggleLogoutModal}
                    >
                      <i className="fa fa-power-off mr-2 ml-1" />
                      {IdentityMsgResProps.body.navigation.logout}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                </>
              ) : null}

              {/*--------------------------------------------------------------------------------*/}
              {/* End Profile Dropdown                                                           */}
              {/*--------------------------------------------------------------------------------*/}
            </Nav>
          </Collapse>
        </Navbar>
        <Logout
          isOpenLogoutModal={this.state.logoutModalStatus}
          toggleLogoutModal={this.toggleLogoutModal}
          {...this.props}
        />
      </header>
    );
  }
}

export default connect(mapStateToProps)(AppHeader);
