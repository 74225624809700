import axios from "axios";
import { includes } from "lodash";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";
import { RoleMsgResProps } from "../messages/role-properties";

const actionFileName = "role-actions.js";

// List Roles
export function listRoles(queryData, cancelToken) {
  let queryParams = "";
  if (queryData) {
    queryParams = "?" + queryData;
  }
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "roles" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listRoles()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Add Role
export function addRole(roleObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(AppConfigProps.serverRoutePrefix + "roles", roleObj, cancelToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">addRole()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Get Role
export function getRole(roleId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(AppConfigProps.serverRoutePrefix + "roles/" + roleId, cancelToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getRole()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Update Role
export function editRole(roleObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix + "roles/" + roleObj.roleId,
        roleObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">editRole()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Get Roles based on role type
export function getRoles(queryData, cancelToken) {
  let queryParams = "?filterType=list";
  if (queryData) {
    queryParams += "&" + queryData;
  }
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "users/roles" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getRoles()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Get Roles access privileges (parts) based on role id
export function getRoleParts(queryData, cancelToken) {
  let queryParams = "?filterType=role-parts";
  if (queryData) {
    queryParams += "&" + queryData;
  }
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
        "users/roles/app-parts" +
        queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getRoleParts()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

///Search Roles
export function searchRoles(list, searchKeyword) {
  return new Promise(async function (resolve, reject) {
    if (
      list &&
      list.length > 0 &&
      searchKeyword &&
      searchKeyword.trim().length > 0
    ) {
      let searchRecords = list.filter((role) => {
        return (
          includes(
            role.roleName.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            RoleMsgResProps.body.form.roleType.options[role.roleType].toLowerCase(),
            searchKeyword.trim().toLowerCase()
          )
        );
      });

      resolve(searchRecords);
    } else {
      resolve(list);
    }
  });
}