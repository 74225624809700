import React from "react";
import { Row, Col, Table, Input } from "reactstrap";
import AppUtils from "../../core/helpers/app-utils";
import WithLoading from "../../core/helpers/with-loading";
import { AlertMsgResProps } from "../messages/alert-properties";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import axios from "axios";
import PageUtils from "../../core/helpers/page-utils";
import { searchIgnoredALerts, newAlerts } from "../actions/alert-actions";
import PageListPagination from "../../core/helpers/page-list-pagination";
import PageListLimitSelect from "../../core/helpers/page-list-limit-select";
import DatePickerModal from "./date-picker-modal";
import SiteUtils from "../../portal/helpers/site-utils";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink as RRNavLink } from "react-router-dom";

class IgnoredAlerts extends React.Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isAlertsDataFetched: false,
      isIgnoredAlertsDataFetched: false,
      isChecked: false,
      disabled: true,
      pageOfItems: [],
      groupedAlerts: null,
      ignoredAlerts: null,
      alerts: null,
      isOpen: true,
      sortedRecords: null,
      activeTab: "1",
      setActiveTab: "1",
      pageOffset: 0,
      pageLimit: 20,
      sortedColumn: "siteName",
      sortedOrder: "ASC",
      isAlertStatusUpdateModal: false,
      searchKeyword: "",
      ignoredAlertsAllRecords: null,
      ignoredAlertsDisplayRecords: null,
      ignoredAlertsPageRecords: null,
      siteName: null,
      isDatePickerModal: false,
      stDate: "",
      edDate: "",
      filter: {
        startDate: "",
        endDate: "",
      },
    };
    // this.onChangePage = this.onChangePage.bind(this);
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentWillReceiveProps() {
    //this is called to before render method
    await this.setStateAsync({
      filter: {
        startDate: this.props.filter?.startDate,
        endDate: this.props.filter?.endDate,
      },
      siteName: null,
      searchKeyword: "",
    });
    await this.setStateAsync({
      ignoredAlertsAllRecords: this.props.ignoredAlerts,
    });
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.ignoredAlertsAllRecords,
      this.state.sortedColumn,
      this.state.sortedOrder
    );
    await this.setStateAsync({ ignoredAlertsDisplayRecords: sortedRecords });
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.ignoredAlertsDisplayRecords,
      this.state.pageOffset,
      this.state.pageLimit
    );
    await this.setStateAsync({ ignoredAlertsPageRecords: slicedRecords });
    await this.setStateAsync({
      isIgnoredAlertsDataFetched: this.props.ignoredAlertsDataFetched,
    });
  }

  async componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  closeDatePickerModal = async () => {
    await this.setState({
      isDatePickerModal: false,
    });
  };

  openDatePickerModal = async () => {
    await this.setState({
      isDatePickerModal: true,
    });
  };

  getDates = async (stDate, edDate) => {
    await this.setStateAsync({ stDate: stDate });
    await this.setStateAsync({ edDate: edDate });
    await this.setStateAsync({
      filter: {
        startDate: stDate,
        endDate: edDate,
      },
    });
    await this.setStateAsync({ isIgnoredAlertsDataFetched: false });
    await this.getIgnoredAlerts();
    await this.setStateAsync({ isIgnoredAlertsDataFetched: true });
  };

  getIgnoredAlerts = async () => {
    await this.setStateAsync({
      isIgnoredAlertsDataFetched: false,
    });
    await this.setStateAsync({
      siteName: null,
      searchKeyword: "",
    });
    let queryData = "";
    if (this.state.stDate && this.state.edDate) {
      queryData =
        "alertStatus=I&startDate=" +
        this.state.stDate +
        "&endDate=" +
        this.state.edDate;
    } else {
      queryData = "alertStatus=I";
    }
    await newAlerts(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            ignoredAlertsAllRecords: res.data.records,
          });
          await this.setStateAsync({ filter: res.data.filter });
          let sortedRecords = await PageUtils.sortListRecords(
            this.state.ignoredAlertsAllRecords,
            this.state.sortedColumn,
            this.state.sortedOrder
          );
          await this.setStateAsync({
            ignoredAlertsDisplayRecords: sortedRecords,
          });
          let slicedRecords = await PageUtils.sliceListRecords(
            this.state.ignoredAlertsDisplayRecords,
            this.state.pageOffset,
            this.state.pageLimit
          );
          await this.setStateAsync({ ignoredAlertsPageRecords: slicedRecords });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isIgnoredAlertsDataFetched: true });
  };

  handlePageLimitChange = async (pageLimit) => {
    if (pageLimit > 0) {
      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.ignoredAlertsDisplayRecords,
        pageOffset,
        pageLimit
      );
      await this.setStateAsync({ ignoredAlertsPageRecords: slicedRecords });
      await this.setStateAsync({ pageLimit: pageLimit });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handlePageOffsetChange = async (pageOffset) => {
    if (pageOffset >= 0) {
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.ignoredAlertsDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );
      await this.setStateAsync({ ignoredAlertsPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSiteChange = async (e) => {
    if (e.target.value) {
      await this.setStateAsync({ siteName: e.target.value, searchKeyword: "" });
      let searchedRecords = await searchIgnoredALerts(
        this.state.ignoredAlertsAllRecords,
        this.state.siteName
      );

      let sortedRecords = await PageUtils.sortListRecords(
        searchedRecords,
        this.state.sortedColumn,
        this.state.sortedOrder
      );
      await this.setStateAsync({ ignoredAlertsDisplayRecords: sortedRecords });

      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.ignoredAlertsDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );

      await this.setStateAsync({ ignoredAlertsPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    } else {
      await this.setStateAsync({ siteName: e.target.value, searchKeyword: "" });
      let sortedRecords = await PageUtils.sortListRecords(
        this.state.ignoredAlertsAllRecords,
        this.state.sortedColumn,
        this.state.sortedOrder
      );
      await this.setStateAsync({ ignoredAlertsDisplayRecords: sortedRecords });
      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.ignoredAlertsDisplayRecords,
        this.state.pageOffset,
        this.state.pageLimit
      );
      await this.setStateAsync({ ignoredAlertsPageRecords: slicedRecords });

      await this.setStateAsync({ searchKeyword: "", searchValue: "" });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchChange = async (e) => {
    e.preventDefault();
    let searchKeyword = e.target.value;
    await this.setStateAsync({ searchKeyword: searchKeyword, siteName: "" });
    let searchedRecords = await searchIgnoredALerts(
      this.state.ignoredAlertsAllRecords,
      this.state.searchKeyword
    );

    let sortedRecords = await PageUtils.sortListRecords(
      searchedRecords,
      this.state.sortedColumn,
      this.state.sortedOrder
    );
    await this.setStateAsync({ ignoredAlertsDisplayRecords: sortedRecords });

    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.ignoredAlertsDisplayRecords,
      pageOffset,
      this.state.pageLimit
    );

    await this.setStateAsync({ ignoredAlertsPageRecords: slicedRecords });
    await this.setStateAsync({ pageOffset: pageOffset });
  };

  handleSiteClick = async (siteName) => {
    await this.setStateAsync({ siteName: siteName, searchKeyword: "" });
    let selectedRecords = await searchIgnoredALerts(
      this.state.ignoredAlertsAllRecords,
      this.state.siteName
    );
    let sortedRecords = await PageUtils.sortListRecords(
      selectedRecords,
      this.state.sortedColumn,
      this.state.sortedOrder
    );
    await this.setStateAsync({ ignoredAlertsDisplayRecords: sortedRecords });

    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.ignoredAlertsDisplayRecords,
      pageOffset,
      this.state.pageLimit
    );

    await this.setStateAsync({ ignoredAlertsPageRecords: slicedRecords });
    await this.setStateAsync({ pageOffset: pageOffset });
  };

  render() {
    return (
      <Row style={{ padding: "15px" }}>
        <Col sm={12} md={12} lg={12}>
          <WithLoading
            type="page"
            className="pt-2"
            isPageDataFetched={this.state.isIgnoredAlertsDataFetched}
          >
            <Row className="pt-2 pb-2">
              <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                <Input
                  type="text"
                  value={this.state.searchKeyword}
                  placeholder="Search"
                  className="form-control"
                  onChange={this.handleSearchChange}
                />
              </Col>
              {/* <Col xs={12} sm={12} md={5} lg={3} xl={3}>
                <select
                  id="exampleSelect"
                  className="form-control"
                  onChange={this.handleSiteChange}
                  value={this.state.siteName}
                >
                  <option value="">Show All Sites</option>
                  {this.props.ignoreAlertSites &&
                    this.props.ignoreAlertSites.map((obj, i) => {
                      return (
                        <option key={i} value={obj}>{obj}</option>
                      )
                    })}
                </select>
              </Col> */}
              {/* <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                {this.props.filter ? (
                  <>
                    <Label className="pt-2 mr-2">
                      From:{" "}
                      {AppUtils.getDate(this.state.filter.startDate)}
                    </Label>
                    <Label className="pt-2">
                      To: {AppUtils.getDate(this.state.filter.endDate)}
                    </Label>
                    <i
                      onClick={this.openDatePickerModal}
                      className="ml-2 fa fa-edit pt-2"
                    ></i>
                  </>
                ) : null}
              </Col> */}
            </Row>
            {/* <Row> */}
            {/* <Col> */}

            <div className="page-content-space">
              <div className="sram-list-main">
                <PerfectScrollbar className="sram-table-wrapper-ignored-alerts">
                  <Table
                    // responsive
                    hover
                    size="sm"
                    className="sram-table-main sram-site-alerts-table-position"
                  >
                    <thead>
                      <tr>
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.siteName}
                        </th>
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.siteId}
                        </th>
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.siteAddress}
                        </th>
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.dispenserName}
                        </th>
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.side}
                        </th>
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.alertStatus}
                        </th>
                        {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.itemName}
                        </th> */}
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.componentName}
                        </th>
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.itemStatus}
                        </th>
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.eventCause}
                        </th>
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.eventTimeStamp}
                        </th>
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.ignoredTimeStamp}
                        </th>
                        <th className="sram-text-font sram-font-weight-600 sram-table-th">
                          {AlertMsgResProps.head.title.ignoredBy}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <WithLoading
                        type="page"
                        size="sm"
                        className="pt-3"
                        isPageDataFetched={this.props.ignoredAlertsDataFetched}
                      >
                        {
                          // this.props.ignoredAlerts &&
                          //   this.props.ignoredAlerts.length > 0 &&
                          this.state.ignoredAlertsPageRecords &&
                          this.state.ignoredAlertsPageRecords.length > 0 ? (
                            this.state.ignoredAlertsPageRecords &&
                            this.state.ignoredAlertsPageRecords.map(
                              (obj, i) => {
                                const siteAddress =
                                  SiteUtils.getSiteAddress(obj);
                                const siteAddress1 =
                                  SiteUtils.getSiteAddress1(obj);
                                return (
                                  <tr key={i}>
                                    <td className="sram-text-font sram-table-td">
                                      {/* <span
                                      className="sram-links"
                                      onClick={this.handleSiteClick.bind(
                                        this,
                                        obj.siteName
                                      )}
                                    >
                                      {obj.siteName}
                                    </span> */}
                                      <RRNavLink
                                        target={"_blank"}
                                        to={
                                          "/portal/sites/" +
                                          obj.siteId +
                                          "/dashboard"
                                        }
                                        title={"Site Dashboard View"}
                                        className="sram-page-action-icon"
                                      >
                                        {obj.siteName}
                                      </RRNavLink>
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.siteNumber ? obj.siteNumber : "-"}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {siteAddress},{siteAddress1}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      <RRNavLink
                                        target={"_blank"}
                                        to={
                                          "/portal/sites/" +
                                          obj.siteId +
                                          "/dispensers/" +
                                          obj.dispenserId +
                                          "/dashboard"
                                        }
                                        title={"Dispenser Dashboard View"}
                                        className="sram-page-action-icon"
                                      >
                                        {obj.dispenserName
                                          ? obj.dispenserName
                                          : "-"}
                                      </RRNavLink>
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.sideLabel ? obj.sideLabel : "-"}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.alertEventStatusDescription
                                        ? obj.alertEventStatusDescription
                                        : "-"}
                                    </td>
                                    {/* <td className="sram-text-font sram-table-td">
                                  {obj.itemName}
                                </td> */}
                                    <td className="sram-text-font sram-table-td">
                                      {obj.componentName
                                        ? obj.componentName
                                        : "-"}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.eventStatus ? obj.eventStatus : "-"}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.eventCause
                                        ? obj.eventCause === "paper out" ||
                                          obj.eventCause === "paper jam" ||
                                          obj.eventCause === "paper low"
                                          ? "Receipt Paper Error"
                                          : obj.eventCause
                                        : "-"}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.eventTimestamp
                                        ? AppUtils.getDateTime(
                                            obj.eventTimestamp
                                          )
                                        : "-"}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.ignoredTimestamp
                                        ? AppUtils.getDateTime(
                                            obj.ignoredTimestamp
                                          )
                                        : "-"}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.userName ? obj.userName : "-"}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td className="sram-no-data pt-2">
                                {AlertMsgResProps.body.content.noDataFound}
                              </td>
                            </tr>
                          )
                        }
                      </WithLoading>
                    </tbody>
                  </Table>
                </PerfectScrollbar>
              </div>
            </div>
            {/* </Col> */}
            {/* </Row> */}
            <Row>
              <Col>
                {this.state.ignoredAlertsPageRecords &&
                this.state.ignoredAlertsPageRecords.length ? (
                  <div className="sram-list-foot">
                    <div className="float-left">
                      <PageListLimitSelect
                        pageLimit={this.state.pageLimit}
                        pageOffset={this.state.pageOffset}
                        totalRecords={
                          this.state.ignoredAlertsDisplayRecords.length
                        }
                        handlePageLimitChange={this.handlePageLimitChange}
                      />
                    </div>
                    <div className="float-right">
                      <PageListPagination
                        pageLimit={this.state.pageLimit}
                        pageOffset={this.state.pageOffset}
                        totalRecords={
                          this.state.ignoredAlertsDisplayRecords.length
                        }
                        handlePageOffsetChange={this.handlePageOffsetChange}
                      />
                    </div>
                  </div>
                ) : null}
              </Col>
            </Row>
          </WithLoading>
        </Col>
        {this.state.isDatePickerModal === true ? (
          <DatePickerModal
            isDatePickerModal={this.state.isDatePickerModal}
            closeDatePickerModal={this.closeDatePickerModal}
            getDates={this.getDates}
            filter={this.props.filter}
          ></DatePickerModal>
        ) : null}
      </Row>
    );
  }
}

export default IgnoredAlerts;
