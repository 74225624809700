export default class CurrentMessageStatusUtils {
  static getDays(data,obj) {
    const date1 = new Date(obj);
    const date2 = new Date(data.currentTimestamp);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  static getMaxdate(obj){
    const dates =[]
    if(obj.event.deviceTimestamp){
      dates.push(obj.event.deviceTimestamp)
    }
    if(obj.pumpErrorLog.deviceTimestamp){
      dates.push(obj.pumpErrorLog.deviceTimestamp)
    }
    if(obj.meterData.deviceTimestamp){
      dates.push(obj.meterData.deviceTimestamp)
    }
    if(obj.configStatus.deviceTimestamp){
      dates.push(obj.configStatus.deviceTimestamp)
    }
    if(obj.performance.deviceTimestamp){
      dates.push(obj.performance.deviceTimestamp)
    }
    let max = ''
    if(dates.length > 0)
    max = dates.reduce(function (a, b) { return a > b ? a : b; });
    return max;
  }
}
