export default class SiteUtils {
  static getSiteAddress(site) {
    let address = "";
    if (site) {
      const addressArr = [];
      if (site.streetAddress1 && site.streetAddress1.trim().length > 0) {
        addressArr.push(site.streetAddress1);
      }
      if (site.streetAddress2 && site.streetAddress2.trim().length > 0) {
        addressArr.push(site.streetAddress2);
      }
      if (addressArr.length > 0) {
        address = addressArr.join(", ");
      }
    }
    return address.toString();
  }
  static getSiteAddress1(site) {
    let address1 = "";
    if (site) {
      const addressArr = [];

      if (site.city && site.city.trim().length > 0) {
        addressArr.push(site.city);
      }
      if (site.state && site.state.trim().length > 0) {
        addressArr.push(site.stateCode);
      }
      if (
        (addressArr.length > 0) & site.zipCode &&
        site.zipCode.trim().length > 0
      ){
        if (site.countryCode) {
          address1 =
            addressArr.join(", ") + " " + site.zipCode + " " + site.countryCode;
        } else {
          address1 = addressArr.join(", ") + "  " + site.zipCode;
        }
      }
      else{
        if (site.countryCode) {
          address1 =
            addressArr.join(", ") + " " + site.zipCode + " " + site.countryCode;
        } else {
          address1 = addressArr.join(", ") ;
        }
      }
    }
    return address1.toString();
  }
}
