export const ConfigValidations = {
    validations: {
        // imei : /^[0-9]+$/
        imei: /^[0-9a-zA-Z]+$/,
        macAddress: /^[0-9a-zA-Z]+$/,
        fuelingSideA: /^[0-9]+$/,
        fuelingSideB: /^[0-9]+$/,
        twoWire: /^[0-9]+$/,
        twoWireSideA: /^[0-9]+$/,
        twoWireSideB: /^[0-9]+$/
    }
}