import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { UserMsgResProps } from "../../setup/messages/user-properties";
import { listSites } from "../../portal/actions/site-actions";
import {
  getCoreReports,
  sendReportsToUsers,
  reportsHistory,
  exportReports,
  userCompany,
  userOrgDivisions,
  userOrgSubDivisions,
  userSitesByOrg,
  scheduleReports,
  scheduledReportsHistory,
  scheduleStatus,
} from "../actions/current-message-status-actions";
import { getProfile } from "../../core/actions/identity-actions";
import { getUser } from "../../setup/actions/user-actions";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import { CoreReportResProps } from "../messages/current-message-status-properties";
import { PortalURLProps } from "../../portal/settings/portal-urls";
import moment from "moment";
import CoreReportsForm from "./core-reports-form";

class CoreReports extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcReportView = "JR-RPT-RPVW";
  _pcScheduleReportsView = "JR-SRT-SRTV";
  _pcScheduleEnableDisable = "JR-SRT-SRED";
  _pcScheduleReportsDelete = "JR-SRT-SRTD";
  constructor(props) {
    super(props);
    this.state = {
      partPrivileges: this.initPartPrivileges(),
      isPageDataFetched: false,
      userProfile: null,
      user: null,
      reports: null,
      queryValue: "",
      selectedReportName: "",
      selectedReportType: "",
      isReportFormDataFetched: false,
      defaultOrganisationName: null,
      organisationName: null,
      organizationTreepath: null,
      userOrgName: null,
      isCompaniesDataFetched: false,
      userCompanies: null,
      companyOrganisationId: null,
      companiesList: null,
      companiesAllList: null,
      isDivisionsDataFetched: false,
      userDivisions: null,
      isSubDivisionsDataFetched: false,
      userSubDivisions: null,
      divisionOrganisationId: null,
      isSitesDataFetched: false,
      siteList: [],
      sitesAllList: [],
      selectedSites: "",
      siteOptions: null,
      sd: "",
      ed: "",
      error: "",
      reportTypes: null,
      selectedFileTypes: "",
      statusMessage: null,
      isReportsHistoryDataFetched: false,
      reportsHistory: null,
      isScheduledReportsHistoryDataFetched: false,
      scheduledReportsHistory: null,
      activeTab: "1",
      setActiveTab: "1",
      submmitReport: false,
      datePicker: [
        { name: "This Week", value: "This Week" },
        { name: "Last Week", value: "Last Week" },
        { name: "Month To Date", value: "Month To Date" },
        { name: "Last Month", value: "Last Month" },
        { name: "Custom Date Picker", value: "Custom Date Picker" },
      ],
      selectCustomDatePicker: null,
      selectedCompany: "",
      scheduler: [
        { name: "Daily", value: "Daily" },
        { name: "Weekly", value: "Weekly" },
        { name: "Monthly", value: "Monthly" },
      ],
      selectedScheduler: "",
      scheduleStatusMessage: null,
      reportNames: null,
      scheduledReportSites: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
    window.appHistory = this.props.history;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      PageUtils.scrollToTop();
      await this.loadPageData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
    clearInterval(this.interval);
  }

  async toggle(tab) {
    if (this.state.activeTab !== tab) {
      await this.setState({ activeTab: tab, setActiveTab: tab });
    }
    await this.clearFields();
    this.updateDatePickerState();
    this.getUserCompanies();
    let organisationId = this.state.divisionOrganisationId;
    await this.getUserDivisions(organisationId);
    await this.getSitesData();
    await this.setStateAsync({ selectedFileTypes: "" });
    // if (this.state.selectedReportName) {
    //   await this.getReportsHistory();
    // }
  }

  updateDatePickerState = async () => {
    if (this.state.activeTab === "1") {
      await this.setState({
        selectedReportType: "adhoc_report",
        datePicker: [
          { name: "This Week", value: "This Week" },
          { name: "Last Week", value: "Last Week" },
          { name: "Month To Date", value: "Month To Date" },
          { name: "Last Month", value: "Last Month" },
          { name: "Custom Date Picker", value: "Custom Date Picker" },
        ],
        selectCustomDatePicker: "",
      });
      if (this.state.selectedReportName) {
        await this.getReportsHistory();
      }
    } else if (this.state.activeTab === "2") {
      await this.setState({
        selectedReportType: "schedule_report",
        datePicker: [
          { name: "yesterday", value: "Yesterday" },
          { name: "last_week", value: "Last Week" },
          { name: "month_to_date", value: "Month To Date" },
          { name: "last_month", value: "Last Month" },
        ],
        selectCustomDatePicker: "",
      });
      await this.getScheduledReportsHistory();
    }
  };

  loadPageData = async () => {
    await this.setPartPrivileges();
    await this.setStateAsync({ userProfile: null });
    await getProfile(this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ userProfile: res.data.result });
          const userId = res.data.result?.userId;
          await this.getUserData(userId);
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await this.getReports();
  };

  initPartPrivileges = () => {
    return {
      [this._pcReportView]: false,
      [this._pcScheduleReportsView]: false,
      [this._pcScheduleEnableDisable]: false,
      [this._pcScheduleReportsDelete]: false,
    };
  };

  setPartPrivileges = async () => {
    const partCodes = [
      this._pcReportView,
      this._pcScheduleReportsView,
      this._pcScheduleEnableDisable,
      this._pcScheduleReportsDelete,
    ];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  getUserData = async (userId) => {
    await this.setStateAsync({
      user: null,
    });
    await getUser(userId, this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({
            user: res.data.result,
            divisionOrganisationId: res.data.result?.organizationId,
            userOrgName: res.data.result?.organizationName,
            defaultOrganisationName: res.data.result?.organizationName,
            organisationName: res.data.result?.organizationName,
            organizationTreepath: res.data.result?.organizationTreepath,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    // await this.setStateAsync({ isPageDataFetched: true });
  };

  getReports = async () => {
    await this.setStateAsync({
      reports: null,
      isPageDataFetched: false,
    });
    await getCoreReports(this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setState({ reports: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  getReportsHistory = async () => {
    if (this.state.isReportsHistoryDataFetched === true) {
    } else {
      await this.setStateAsync({
        reportsHistory: null,
        isReportsHistoryDataFetched: false,
      });
    }
    const queryData =
      "reportName=" + this.state.selectedReportName + "&dataPeriod=10";
    await reportsHistory(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({ reportsHistory: res.data.records });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isReportsHistoryDataFetched: true });
  };

  getScheduledReportsHistory = async () => {
    if (this.state.isScheduledReportsHistoryDataFetched === true) {
    } else {
      await this.setStateAsync({
        scheduledReportsHistory: null,
        isScheduledReportsHistoryDataFetched: false,
      });
    }
    const queryData =
      "reportName=" + this.state.selectedReportName + "&dataPeriod=10";
    await scheduledReportsHistory(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            scheduledReportsHistory: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isScheduledReportsHistoryDataFetched: true });
  };

  getUserCompanies = async () => {
    await this.setStateAsync({ isCompaniesDataFetched: false });
    await this.setStateAsync({
      userCompanies: null,
      companiesList: [],
      companiesAllList: [],
    });
    const queryData = "userType=" + this.state.userProfile?.userType;
    await userCompany(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          const companyData = await res.data?.records?.filter((obj) => {
            if (obj.entityType === "C") {
              return obj;
            }
          });
          await this.setStateAsync({ userCompanies: companyData });
          await this.setStateAsync({
            companiesList: companyData,
            companiesAllList: companyData,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isCompaniesDataFetched: true });
  };

  getUserDivisions = async (organisationId) => {
    await this.setStateAsync({
      userDivisions: null,
      isDivisionsDataFetched: false,
    });
    const queryData = organisationId + "/divisions";
    await userOrgDivisions(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          const divisionData = await res.data?.records?.filter((obj) => {
            if (obj.entityType === "D") {
              return obj;
            }
          });
          await this.setStateAsync({
            userDivisions: divisionData,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({
      isDivisionsDataFetched: true,
    });
  };

  getUserSubDivisions = async (organisationId, divisionId) => {
    await this.setStateAsync({
      userSubDivisions: null,
      isSubDivisionsDataFetched: false,
    });
    const queryData = `${organisationId}/divisions/${divisionId}/subdivisions`;
    await userOrgSubDivisions(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          const subDivisionsData = [];
          await res.data?.records?.filter((obj) => {
            if (obj.rootId !== obj.parentId) {
              return subDivisionsData.push(obj);
            }
          });
          await this.setStateAsync({
            userSubDivisions: subDivisionsData,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({
      isSubDivisionsDataFetched: true,
    });
  };

  getUserSitesByOrg = async (organizationId) => {
    await this.setStateAsync({
      siteList: [],
      sitesAllList: [],
      selectedSites: "",
      isSitesDataFetched: false,
    });
    const queryData = organizationId + "/sites";
    await userSitesByOrg(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            let sortedRecords = await PageUtils.sortListRecords(
              res.data.records,
              this.state.sortedColumn,
              this.state.sortedOrder
            );
            let slicedRecords = await PageUtils.sliceListRecords(
              sortedRecords,
              this.state.pageOffset,
              this.state.pageLimit
            );
            await this.setStateAsync({
              siteList: slicedRecords,
              sitesAllList: slicedRecords,
            });
            await this.setStateAsync({
              siteOptions:
                (this.state.selectedSites === "" ||
                  this.state.selectedSites === undefined ||
                  this.state.selectedSites === null) &&
                this.state.siteList.length > 0
                  ? [
                      {
                        siteName: "Select all Sites",
                        siteId: "all",
                      },
                      ...this.state.siteList,
                    ]
                  : this.state.siteList,
            });
          } else {
            this.setStateAsync({
              siteList: [],
              sitesAllList: [],
              selectedSites: "",
              siteOptions: [],
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isSitesDataFetched: true });
  };

  getSitesData = async () => {
    await this.setStateAsync({
      siteList: [],
      sitesAllList: [],
      selectedSites: "",
      isSitesDataFetched: false,
    });
    const queryData = "sortField=siteName&sortOrder=1";
    await listSites(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            let sortedRecords = await PageUtils.sortListRecords(
              res.data.records,
              this.state.sortedColumn,
              this.state.sortedOrder
            );
            let slicedRecords = await PageUtils.sliceListRecords(
              sortedRecords,
              this.state.pageOffset,
              this.state.pageLimit
            );
            await this.setStateAsync({
              siteList: slicedRecords,
              sitesAllList: slicedRecords,
            });
            await this.setStateAsync({
              siteOptions:
                (this.state.selectedSites === "" ||
                  this.state.selectedSites === undefined ||
                  this.state.selectedSites === null) &&
                this.state.siteList.length > 0
                  ? [
                      {
                        siteName: "Select all Sites",
                        siteId: "all",
                      },
                      ...this.state.siteList,
                    ]
                  : this.state.siteList,
            });
          } else {
            this.setStateAsync({
              siteList: [],
              sitesAllList: [],
              selectedSites: "",
              siteOptions: [],
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isSitesDataFetched: true });
  };

  getExportReports = async (s3Name) => {
    const queryData = s3Name;
    await exportReports(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            window.open(res.data.records);
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  clearFields = async () => {
    await this.setStateAsync({
      selectedSites: "",
      sd: "",
      ed: "",
      error: "",
      selectedFileTypes: "",
      statusMessage: null,
      submmitReport: false,
      selectCustomDatePicker: null,
      selectedCompany: "",
      selectedScheduler: "",
    });
  };

  pushBack = async () => {
    this.setStateAsync({ queryValue: "" });
    await this.setStateAsync({
      selectedReportName: "",
      selectedReportType: "",
      isReportFormDataFetched: false,
      isSitesDataFetched: false,
      siteList: [],
      sitesAllList: [],
      selectedSites: "",
      sd: "",
      ed: "",
      error: "",
      reportTypes: null,
      selectedFileTypes: "",
      statusMessage: null,
      isReportsHistoryDataFetched: false,
      reportsHistory: null,
      activeTab: "1",
      setActiveTab: "1",
      submmitReport: false,
      selectCustomDatePicker: null,
    });
    await this.updateDatePickerState();
    await this.props.history.push(PortalURLProps.reports);
  };

  selectReportName = async (name, type, finalreportNames) => {
    await this.setStateAsync({
      selectedReportName: name,
      selectedReportType: type,
      queryValue: finalreportNames,
      isReportFormDataFetched: false,
    });
    if (
      name === "sites_interaction" ||
      name === "supr_report" ||
      name === "sites_interaction_closed_tickets"
    ) {
      await this.setStateAsync({
        reportTypes: [{ name: "Excel", value: "excel" }],
      });
    } else {
      await this.setStateAsync({
        reportTypes: [
          { name: "Excel", value: "excel" },
          { name: "PDF", value: "pdf" },
        ],
      });
    }
    await this.getSitesData();
    await this.getUserCompanies();
    let organisationId = this.state.divisionOrganisationId;
    await this.getUserDivisions(organisationId);
    if (
      this.state.user?.rootId === null &&
      this.state.user?.parentId === null
    ) {
      await this.setStateAsync({
        isSubDivisionsDataFetched: true,
        userSubDivisions: [],
        isReportFormDataFetched: true,
      });
    } else if (this.state.user?.rootId === this.state.user?.parentId) {
      this.getUserSubDivisions(
        this.state.user?.parentId,
        this.state.user?.organizationId
      );
      await this.setStateAsync({
        isDivisionsDataFetched: true,
        userDivisions: [],
        isReportFormDataFetched: true,
      });
    } else if (this.state.user?.rootId !== this.state.user?.parentId) {
      await this.getUserSubDivisions(
        this.state.user?.rootId,
        this.state.user?.parentId
      );
      await this.setStateAsync({
        isReportFormDataFetched: true,
      });
    } else {
      await this.setStateAsync({
        isSubDivisionsDataFetched: true,
        userSubDivisions: [],
        isReportFormDataFetched: true,
      });
    }

    if (this.state.selectedReportName) {
      await this.getReportsHistory();
    }
    this.interval = setInterval(() => {
      if (this.state.selectedReportName && this.state.activeTab === "1") {
        this.getReportsHistory();
      }
      if (this.state.selectedReportName && this.state.activeTab === "2") {
        this.getScheduledReportsHistory();
      }
    }, 100000);
  };

  handleSelectScheduler = async (e) => {
    this.setStateAsync({ selectedScheduler: e?.value });
  };

  handleSelectSites = async (e) => {
    if (e === null) {
      await this.setStateAsync({
        siteOptions: [
          {
            siteName: "Select all Sites",
            siteId: "all",
          },
          ...this.state.sitesAllList,
        ],
      });
    } else if (e && e !== null && e[0].siteName === "Select all Sites") {
      let selectedSites = this.state.siteList.map((obj) => {
        return obj.siteId;
      });
      await this.setStateAsync({
        selectedSites: selectedSites,
        siteList: [],
        submmitReport: false,
        scheduledReportSites: "All Sites",
        siteOptions: [
          {
            siteName: "Select all Sites",
            siteId: "all",
          },
        ],
      });
    } else {
      await this.setStateAsync({
        siteOptions: [...this.state.sitesAllList],
      });
      let selectedSites = e?.map((selectedSites) => selectedSites?.siteId);
      await this.setStateAsync({
        selectedSites: selectedSites,
        siteList: this.state.sitesAllList,
        submmitReport: false,
        scheduledReportSites: "Few Sites",
      });
    }
  };
  handleSelectSubDivision = async (e) => {
    if (e !== null) {
      await this.getUserSitesByOrg(e.organizationId);
      this.setStateAsync({ organisationName: e.organizationTreepath });
    } else {
      let organisationId = this.state.companyOrganisationId;
      await this.getUserSitesByOrg(organisationId);
      await this.setStateAsync({
        organisationName: this.state.userOrgName,
      });
    }
  };
  handleSelectDivision = async (e) => {
    if (e !== null) {
      await this.getUserSubDivisions(e.parentId, e.organizationId);
      await this.getUserSitesByOrg(e.organizationId);
      this.setStateAsync({
        organisationName: e.organizationTreepath,
      });
    } else {
      await this.setStateAsync({
        isSubDivisionsDataFetched: false,
      });
      let organisationId = this.state.companyOrganisationId;
      await this.getUserSitesByOrg(organisationId);
      await this.setStateAsync({
        userSubDivisions: [],
        isSubDivisionsDataFetched: true,
        organisationName: this.state.userOrgName,
      });
    }
  };
  handleSelectCompanies = async (e) => {
    if (e && e !== null && e.organizationName === "Select all Companies") {
      let organisationId = this.state.divisionOrganisationId;
      await this.setStateAsync({
        isSubDivisionsDataFetched: false,
      });
      await this.getUserDivisions(organisationId);
      await this.getSitesData();
      await this.setStateAsync({
        siteOptions: [
          {
            siteName: "Select all Sites",
            siteId: "all",
          },
        ],
        organisationName: this.state.defaultOrganisationName,
        userSubDivisions: [],
        isSubDivisionsDataFetched: true,
      });
    } else {
      if (e !== null) {
        await this.setStateAsync({
          companyOrganisationId: e.organizationId,
          organisationName: e.organizationName,
          isSubDivisionsDataFetched: false,
        });
        await this.getUserDivisions(e.organizationId);
        await this.setStateAsync({
          userSubDivisions: [],
          isSubDivisionsDataFetched: true,
        });
        await this.getUserSitesByOrg(e.organizationId);
      } else {
        await this.setStateAsync({
          organisationName: this.state.userOrgName,
          isSubDivisionsDataFetched: false,
        });
        let organisationId = this.state.divisionOrganisationId;
        await this.getUserDivisions(organisationId);
        await this.setStateAsync({
          userSubDivisions: [],
          isSubDivisionsDataFetched: true,
        });
        await this.getSitesData();
      }
    }
  };

  handleDateChange = async (e) => {
    e.preventDefault();
    let ed = e.target.value;
    // let sd = moment().subtract(180, 'days').calendar(); // last 6 months date from current date
    await this.setStateAsync({
      sd: ed,
      ed: ed,
      error: "",
      submmitReport: false,
    });
  };

  handleSdChange = async (e) => {
    e.preventDefault();
    let sd = e.target.value;
    await this.setStateAsync({ sd: sd, error: "", submmitReport: false });
  };

  handleEdChange = async (e) => {
    e.preventDefault();
    let ed = e.target.value;
    await this.setStateAsync({ ed: ed, error: "", submmitReport: false });
  };

  handleReportChange = async (e) => {
    let reportType = e?.map((reportType) => reportType?.value);
    await this.setStateAsync({
      selectedFileTypes: reportType,
      submmitReport: false,
    });
  };

  downloadReport = async (s3Name) => {
    this.getExportReports(s3Name);
  };

  handleSubmit = async () => {
    this.setStateAsync({ submmitReport: true, statusMessage: null });
    if (this.state.activeTab === "1") {
      if (this.state.sd > this.state.ed) {
        await this.setStateAsync({
          error: "Start Date should not be greater than End Date",
        });
      }
      if (
        this.state.selectedSites !== "" &&
        this.state.selectedSites !== undefined &&
        this.state.sd !== "" &&
        this.state.ed !== "" &&
        this.state.error === "" &&
        this.state.selectedFileTypes !== "" &&
        this.state.selectedFileTypes !== undefined
      ) {
        await this.addAdhocReport();
      }
    } else {
      if (
        this.state.selectedSites !== "" &&
        this.state.selectedSites !== undefined &&
        this.state.selectedFileTypes !== "" &&
        this.state.selectedFileTypes !== undefined &&
        // this.state.selectCustomDatePicker !== "" &&
        // this.state.selectCustomDatePicker !== undefined &&
        this.state.selectedScheduler !== "" &&
        this.state.selectedScheduler !== undefined
      ) {
        await this.addScheduleReports();
      }
    }
  };

  addAdhocReport = async () => {
    let reportsObj = {
      reportName: this.state.selectedReportName,
      reportType: this.state.selectedReportType,
      fileType: this.state.selectedFileTypes,
      fromDate: this.state.sd,
      toDate: this.state.ed,
      id: this.state.userProfile?.userId,
      siteId: this.state.selectedSites,
      organizationName: this.state.organisationName,
      organizationTreepath: this.state.organizationTreepath,
      scheduledReportSites: this.state.scheduledReportSites,
    };
    // console.log("reportsObj", reportsObj);
    await sendReportsToUsers(reportsObj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.message
        ) {
          const statusMsg = [
            "S",
            res.data.message +
              ". It will take couple of minutes to generate reports",
          ];
          await this.setStateAsync({
            statusMessage: statusMsg,
            submmitReport: false,
          });
          await PageUtils.scrollToTop();
          if (this.state.selectedReportName) {
            await this.getReportsHistory();
          }

          // await setTimeout(async () => {
          //   await this.clearFields();
          // }, 5000);
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          UserMsgResProps.body.notification.error.message,
        ];
        await this.setStateAsync({
          submmitReport: false,
        });
        await this.setStateAsync({ statusMessage: statusMsg });
        if (err.data && err.data.errors && err.data.errors.length > 0) {
          const errors = err.data.errors;
          errors.forEach((error) => {
            if (error.element && error.message && error.location === "body") {
              // await this.setStateAsync({statusMessage: statusMsg});
            }
          });
          PageUtils.scrollToTop();
        }
        // await manageError(err, this.props.history);
      });
  };

  addScheduleReports = async () => {
    let reportsObj = {
      reportName: this.state.selectedReportName,
      reportType: this.state.selectedReportType,
      fileType: this.state.selectedFileTypes,
      // dateRange: this.state.selectCustomDatePicker,
      userId: [this.state.userProfile?.userId],
      siteId: this.state.selectedSites,
      organizationName: this.state.organisationName,
      organizationTreepath: this.state.organizationTreepath,
      scheduleExpression: this.state.selectedScheduler,
      scheduledReportSites: this.state.scheduledReportSites,
    };
    // console.log("reportsObj", reportsObj);
    await scheduleReports(reportsObj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.message
        ) {
          const statusMsg = ["S", res.data.message];
          await this.setStateAsync({
            statusMessage: statusMsg,
            submmitReport: false,
          });
          await PageUtils.scrollToTop();
          if (this.state.selectedReportName) {
            await this.getScheduledReportsHistory();
          }

          // await setTimeout(async () => {
          //   await this.clearFields();
          // }, 5000);
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          UserMsgResProps.body.notification.error.message,
        ];
        await this.setStateAsync({
          submmitReport: false,
        });
        await this.setStateAsync({ statusMessage: statusMsg });
        PageUtils.scrollToTop();
        // if (err.data && err.data.errors && err.data.errors.length > 0) {
        //   const errors = err.data.errors;
        //   errors.forEach((error) => {
        //     if (error.element && error.message && error.location === "body") {
        //       // await this.setStateAsync({statusMessage: statusMsg});
        //     }
        //   });
        //   PageUtils.scrollToTop();
        // }
        // await manageError(err, this.props.history);
      });
  };

  handleScheduleStatusSubmit = async (tempScheduleStatusObj, status) => {
    const queryData = "status=" + status;
    await scheduleStatus(tempScheduleStatusObj, queryData, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = ["S", res.data.message];
          await this.setStateAsync({ statusMessage: statusMsg });
          PageUtils.scrollToTop();
          await this.getScheduledReportsHistory();
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          UserMsgResProps.body.notification.error.message,
        ];
        await this.setStateAsync({ statusMessage: statusMsg });
        if (err.data && err.data.errors && err.data.errors.length > 0) {
          const errors = err.data.errors;
          errors.forEach((error) => {
            if (error.element && error.message && error.location === "body") {
              // await this.setStateAsync({statusMessage: statusMsg});
            }
          });
          PageUtils.scrollToTop();
        }
      });
  };

  render() {
    const companiesOptions =
      (this.state.selectedCompany === "" ||
        this.state.selectedCompany === undefined ||
        this.state.selectedCompany === null) &&
      this.state.companiesList &&
      this.state.companiesList.length > 1
        ? [
            {
              organizationName: "Select all Companies",
              organizationId: "all",
            },
            ...this.state.companiesList,
          ]
        : this.state.companiesList;
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <div>
              {this.state.statusMessage ? (
                <Row>
                  <Col xs={12}>
                    {this.state.statusMessage[0] === "E"
                      ? PageDisplay.showErrorNotification(
                          this.state.statusMessage[1]
                        )
                      : PageDisplay.showSuccessNotification(
                          this.state.statusMessage[1]
                        )}
                  </Col>
                </Row>
              ) : null}
            </div>
            <div></div>
            {this.state.partPrivileges[this._pcReportView] === true ||
            this.state.partPrivileges[this._pcScheduleReportsView] === true ? (
              <Card className="sram-page-list">
                <CardTitle>
                  <span
                    className="sram-text-font sram-page-action-icon"
                    onClick={this.pushBack}
                  >
                    {CoreReportResProps.body.content.coreReports}
                  </span>
                  {this.state.queryValue !== "" ? (
                    <span className="sram-text-font">
                      {" "}
                      {">"}{" "}
                      {this.state.queryValue === "Paper Out"
                        ? "Receipt Paper"
                        : this.state.queryValue}
                    </span>
                  ) : null}
                </CardTitle>
                <CardBody>
                  <div className="sram-list-main">
                    {this.state.queryValue === "" ||
                    this.state.queryValue === null ? (
                      <>
                        {this.state.reports &&
                          this.state.reports.map((obj, index) => {
                            var match =
                              obj.reportAccess && obj.reportAccess.split(",");
                            if (
                              match &&
                              match.includes(this.state.userProfile.userType)
                            ) {
                              this.state.reportNames =
                                obj.reportName.replaceAll("_", " ");
                            } else {
                              this.state.reportNames = null;
                            }
                            if (this.state.reportNames) {
                              let finalreportNames =
                                this.state.reportNames &&
                                this.state.reportNames.replace(
                                  /(^\w|\s\w)/g,
                                  (m) => m.toUpperCase()
                                );
                              return (
                                <Row key={index}>
                                  <Col className="pb-2 mb-2">
                                    <span
                                      title={
                                        finalreportNames === "Paper Out"
                                          ? "Receipt Paper"
                                          : finalreportNames
                                      }
                                      className="sram-page-action-icon"
                                      style={{ color: "#20A0D1" }}
                                      onClick={() =>
                                        this.selectReportName(
                                          obj.reportName,
                                          obj.reportType,
                                          finalreportNames
                                        )
                                      }
                                    >
                                      <span>
                                        {finalreportNames === "Paper Out"
                                          ? "Receipt Paper"
                                          : finalreportNames}
                                      </span>
                                    </span>
                                    <div className="mt-1">
                                      {obj.reportDescription ===
                                      "Paper out report "
                                        ? "Receipt Paper Report"
                                        : obj.reportDescription}
                                    </div>
                                  </Col>
                                </Row>
                              );
                            }
                          })}
                      </>
                    ) : (
                      <div className="mt-1">
                        <Nav tabs>
                          {this.state.partPrivileges[this._pcReportView] ===
                          true ? (
                            <NavItem className="sram-nav-active sram-title-cursor">
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === "1",
                                })}
                                onClick={this.toggle.bind(this, "1")}
                              >
                                <span>
                                  {CoreReportResProps.body.content.coreReports}
                                </span>
                              </NavLink>
                            </NavItem>
                          ) : null}
                          {this.state.partPrivileges[
                            this._pcScheduleReportsView
                          ] === true ? (
                            <NavItem className="sram-nav-active sram-title-cursor">
                              <NavLink
                                className={classnames({
                                  active: this.state.activeTab === "2",
                                })}
                                onClick={this.toggle.bind(this, "2")}
                              >
                                <span>
                                  {CoreReportResProps.body.content.schedule}
                                </span>
                              </NavLink>
                            </NavItem>
                          ) : null}
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          {this.state.partPrivileges[this._pcReportView] ===
                          true ? (
                            <TabPane tabId="1">
                              <CoreReportsForm
                                isReportFormDataFetched={
                                  this.state.isReportFormDataFetched
                                }
                                selectedReportName={
                                  this.state.selectedReportName
                                }
                                userProfile={this.state.userProfile}
                                user={this.state.user}
                                userCompanies={companiesOptions}
                                isDivisionsDataFetched={
                                  this.state.isDivisionsDataFetched
                                }
                                userDivisions={this.state.userDivisions}
                                userSubDivisions={this.state.userSubDivisions}
                                isSubDivisionsDataFetched={
                                  this.state.isSubDivisionsDataFetched
                                }
                                isSitesDataFetched={
                                  this.state.isSitesDataFetched
                                }
                                siteOptions={this.state.siteOptions}
                                selectedSites={this.state.selectedSites}
                                submmitReport={this.state.submmitReport}
                                sd={this.state.sd}
                                ed={this.state.ed}
                                selectCustomDatePicker={
                                  this.state.selectCustomDatePicker
                                }
                                datePicker={this.state.datePicker}
                                error={this.state.error}
                                reportTypes={this.state.reportTypes}
                                selectedFileTypes={this.state.selectedFileTypes}
                                activeTab={this.state.activeTab}
                                isReportsHistoryDataFetched={
                                  this.state.isReportsHistoryDataFetched
                                }
                                reportsHistory={this.state.reportsHistory}
                                isCompaniesDataFetched={
                                  this.state.isCompaniesDataFetched
                                }
                                handleSubmit={this.handleSubmit}
                                handleSelectCompanies={
                                  this.handleSelectCompanies
                                }
                                handleSelectDivision={this.handleSelectDivision}
                                handleSelectSubDivision={
                                  this.handleSelectSubDivision
                                }
                                handleSelectSites={this.handleSelectSites}
                                handleDateChange={this.handleDateChange}
                                handleSdChange={this.handleSdChange}
                                handleEdChange={this.handleEdChange}
                                handleReportChange={this.handleReportChange}
                                pushBack={this.pushBack}
                              />
                            </TabPane>
                          ) : null}
                          {this.state.partPrivileges[
                            this._pcScheduleReportsView
                          ] === true ? (
                            <TabPane tabId="2">
                              <CoreReportsForm
                                isReportFormDataFetched={
                                  this.state.isReportFormDataFetched
                                }
                                selectedReportName={
                                  this.state.selectedReportName
                                }
                                scheduleEnableDisable={
                                  this.state.partPrivileges[
                                    this._pcScheduleEnableDisable
                                  ]
                                }
                                scheduleDelete={
                                  this.state.partPrivileges[
                                    this._pcScheduleReportsDelete
                                  ]
                                }
                                userProfile={this.state.userProfile}
                                user={this.state.user}
                                userCompanies={companiesOptions}
                                isDivisionsDataFetched={
                                  this.state.isDivisionsDataFetched
                                }
                                userDivisions={this.state.userDivisions}
                                userSubDivisions={this.state.userSubDivisions}
                                isSubDivisionsDataFetched={
                                  this.state.isSubDivisionsDataFetched
                                }
                                isSitesDataFetched={
                                  this.state.isSitesDataFetched
                                }
                                siteOptions={this.state.siteOptions}
                                selectedSites={this.state.selectedSites}
                                submmitReport={this.state.submmitReport}
                                sd={this.state.sd}
                                ed={this.state.ed}
                                selectCustomDatePicker={
                                  this.state.selectCustomDatePicker
                                }
                                datePicker={this.state.datePicker}
                                error={this.state.error}
                                reportTypes={this.state.reportTypes}
                                selectedFileTypes={this.state.selectedFileTypes}
                                activeTab={this.state.activeTab}
                                isReportsHistoryDataFetched={
                                  this.state.isReportsHistoryDataFetched
                                }
                                reportsHistory={this.state.reportsHistory}
                                isCompaniesDataFetched={
                                  this.state.isCompaniesDataFetched
                                }
                                handleSubmit={this.handleSubmit}
                                handleSelectCompanies={
                                  this.handleSelectCompanies
                                }
                                handleSelectDivision={this.handleSelectDivision}
                                handleSelectSubDivision={
                                  this.handleSelectSubDivision
                                }
                                handleSelectSites={this.handleSelectSites}
                                handleDateChange={this.handleDateChange}
                                handleSdChange={this.handleSdChange}
                                handleEdChange={this.handleEdChange}
                                handleReportChange={this.handleReportChange}
                                pushBack={this.pushBack}
                                scheduler={this.state.scheduler}
                                selectedScheduler={this.state.selectedScheduler}
                                handleSelectScheduler={
                                  this.handleSelectScheduler
                                }
                                scheduledReportsHistory={
                                  this.state.scheduledReportsHistory
                                }
                                isScheduledReportsHistoryDataFetched={
                                  this.state
                                    .isScheduledReportsHistoryDataFetched
                                }
                                handleScheduleStatusSubmit={
                                  this.handleScheduleStatusSubmit
                                }
                              />
                            </TabPane>
                          ) : null}
                        </TabContent>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            ) : null}
          </div>
        </WithLoading>
      </>
    );
  }
}
export default CoreReports;
