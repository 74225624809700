import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import _ from "lodash";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import ConfirmModal from "../../core/helpers/confirm-modal";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import { SetupURLProps } from "../settings/setup-urls";
import { SetupValidations } from "../settings/setup-validations";
import { SetupConstants } from "../settings/setup-constants";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { RoleMsgResProps } from "../messages/role-properties";
import { manageError } from "../../core/actions/common-actions";
import {
  isPagePartPrivileged,
  getUserRole,
} from "../../core/actions/identity-actions";
import { getRole, editRole } from "../actions/role-actions";
import { listParts } from "../actions/app-part-actions";

class RoleEdit extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _helpers = null;
  _pcRoleEdit = "AS-ROL-REDT";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isOpenConfirmModal: false,
      formInputFields: null,
      role: null,
      selActionId: null,
      appPartsAll: null,
      appPartsAvailable: null,
      userRole: null,
      tempRoleObj: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    const roleId = this.props.match.params.rid;
    await this.loadPageData(roleId);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const roleId = this.props.match.params.rid;
    if (roleId !== prevProps.match.params.rid) {
      PageUtils.scrollToTop();
      await this.loadPageData(roleId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async (roleId) => {
    if (
      roleId === null ||
      roleId === undefined ||
      roleId !== parseInt(roleId).toString()
    )
      this.props.history.push(SetupURLProps.roles.listRole);

    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ role: null });
    await this.checkPartPrivilege();
    await getUserRole(this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ userRole: res.data.result });
        } else {
          this.props.history.push(SetupURLProps.siteOwners.listCompany);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await getRole(roleId, this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ role: res.data.result });
          if (this.state.role) {
            await this.setStateAsync({
              formInputFields: await this.initFormInputFields(),
            });

            const queryData = null;
            await listParts(queryData, this._cancelToken)
              .then(async (res) => {
                if (
                  res &&
                  res.status === AppConfigProps.httpStatusCode.ok &&
                  res.data &&
                  res.data.records
                ) {
                  if (res.data.records.length > 0) {
                    await this.setStateAsync({ appPartsAll: res.data.records });
                    await this.loadParts(this.state.role.roleType);
                  }
                } else {
                  await manageError(res, this.props.history);
                }
              })
              .catch(async (err) => {
                await manageError(err, this.props.history);
              });
          }
        } else {
          this.props.history.push(SetupURLProps.roles.listRole);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  loadParts = async (roleType) => {
    await this.setStateAsync({ appPartsAvailable: null });
    let appParts = _.cloneDeep(this.state.appPartsAll); //JSON.parse(JSON.stringify(this.state.appPartsAll));
    let selActionId = [];
    let partModules = [];

    await appParts.forEach(async (module) => {
      if (module && module.features && module.features.length > 0) {
        let partFeatures = [];
        await module.features.forEach(async (feature) => {
          if (feature && feature.actions && feature.actions.length > 0) {
            let partActions = [];
            await feature.actions.forEach(async (action) => {
              if (action) {
                let actionAvailable = false;
                if (roleType === SetupConstants.roles.ROLE_TYPE_SITEIQ) {
                  actionAvailable = true;
                } else if (
                  roleType === SetupConstants.roles.ROLE_TYPE_SITEOWNER
                ) {
                  if (
                    SetupValidations.roles.PartCodesNotApplicable.siteOwner.includes(
                      module.moduleCode +
                        "-" +
                        feature.featureCode +
                        "-" +
                        action.actionCode
                    ) === false
                  ) {
                    actionAvailable = true;
                  }
                } else if (
                  roleType === SetupConstants.roles.ROLE_TYPE_SERVICEPROVIDER
                ) {
                  if (
                    SetupValidations.roles.PartCodesNotApplicable.serviceProvider.includes(
                      module.moduleCode +
                        "-" +
                        feature.featureCode +
                        "-" +
                        action.actionCode
                    ) === false
                  ) {
                    actionAvailable = true;
                  }
                }
                if (actionAvailable === true) {
                  let partAction = action;
                  partActions.push(partAction);
                  if (feature.defaultStatus === 1)
                    selActionId.push(action.actionId);
                }
              }
            });
            if (partActions.length > 0) {
              let partFeature = feature;
              partFeature.actions = [...partActions];
              partFeatures.push(partFeature);
            }
          }
        });
        if (partFeatures.length > 0) {
          let partModule = module;
          partModule.features = [...partFeatures];
          partModules.push(partModule);
        }
      }
    });
    await this.setStateAsync({ appPartsAvailable: [...partModules] });
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcRoleEdit}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  initFormInputFields = () => {
    let selActionId = [];
    if (this.state.role.actions && this.state.role.actions.length > 0) {
      this.state.role.actions.forEach((action) => {
        selActionId.push(action.actionId);
      });
    }
    return {
      roleType: this.state.role.roleType,
      roleName: this.state.role.roleName,
      actionId: selActionId,
    };
  };

  formValidationSchema = () => {
    const schema = {
      roleName: Yup.string()
        .trim()
        .required(RoleMsgResProps.body.notification.error.roleName.empty)
        .matches(
          SetupValidations.roles.roleName,
          RoleMsgResProps.body.notification.error.roleName.invalid
        ),
    };
    return schema;
  };

  handleConfirmation = async (values, helpers) => {
    this._helpers = helpers;
    const roleObj = {
      roleId: this.state.role.roleId,
      roleType: this.state.role.roleType,
      roleName: values.roleName,
      lastModifyTimestamp: this.state.role.modifyTimestamp,
      actionId: values.actionId,
    };
    await this.setStateAsync({ tempRoleObj: roleObj });
    this.openConfirmModal();
  };

  handleSubmit = async () => {
    const roleObj = this.state.tempRoleObj;
    const helpers = this._helpers;
    this.closeConfirmModal();
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }

    await editRole(roleObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = [
            "S",
            RoleMsgResProps.body.notification.success.editRole,
          ];
          if (res.data.result) {
            await this.setStateAsync({ role: res.data.result });
            await this.setStateAsync({
              formInputFields: await this.initFormInputFields(),
            });
            if (
              document.getElementsByName("selectAll") &&
              document.getElementsByName("selectAll").length > 0
            ) {
              for (
                let i = 0;
                i < document.getElementsByName("selectAll").length;
                i++
              ) {
                let elem = document.getElementsByName("selectAll")[i];
                elem.checked = false;
              }
            }
          }
          if (this._isMounted) {
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          RoleMsgResProps.body.notification.error.message,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);

          if (err.data && err.data.errors && err.data.errors.length > 0) {
            const errors = err.data.errors;
            errors.forEach((error) => {
              if (error.element && error.message && error.location === "body") {
                helpers.setFieldError(error.element, error.message);
              }
            });
          }
          PageUtils.scrollToTop();
        }
      });
    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  toggleMultipleActions = async (e, feature) => {
    if (feature.actions && feature.actions.length > 0) {
      if (e.target.checked) {
        for (let i = 0; i < feature.actions.length; i++) {
          let action = feature.actions[i];
          if (
            document.getElementById("check_" + action.actionId).disabled ===
            false
          ) {
            if (
              document.getElementById("check_" + action.actionId).checked ===
              false
            )
              await document.getElementById("check_" + action.actionId).click();
          }
        }
      } else {
        for (let i = 0; i < feature.actions.length; i++) {
          let action = feature.actions[i];
          if (
            document.getElementById("check_" + action.actionId).disabled ===
            false
          ) {
            if (
              document.getElementById("check_" + action.actionId).checked ===
              true
            )
              await document.getElementById("check_" + action.actionId).click();
          }
        }
      }
    }
  };

  toggleSingleAction = async (e, action, formik, arrayHelpers) => {
    if (e.target.checked) arrayHelpers.push(action.actionId);
    else {
      const idx = formik.values.actionId.indexOf(action.actionId);
      arrayHelpers.remove(idx);
    }
  };

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            {this.state.role ? (
              <>
                <Card className="sram-page-form">
                  <CardTitle>
                    <span>{RoleMsgResProps.body.content.editRole}</span>
                    <span className="float-right">
                      {PageDisplay.showGoBackLink(
                        "L",
                        SetupURLProps.roles.listRole,
                        this.props.history
                      )}
                    </span>
                  </CardTitle>
                  <CardBody>
                    <Formik
                      initialValues={this.state.formInputFields}
                      validationSchema={Yup.object().shape(
                        this.formValidationSchema()
                      )}
                      onSubmit={(values, helpers) =>
                        this.handleConfirmation(values, helpers)
                      }
                    >
                      {(formik) => (
                        <Form id="roleForm" {...formEvent}>
                          <div className="sram-form-main">
                            <div>
                              {formik.status &&
                              Array.isArray(formik.status) &&
                              formik.status.length === 2 &&
                              (formik.status[0] === "E" ||
                                formik.status[0] === "S") ? (
                                <Row>
                                  <Col xs={12}>
                                    {formik.status[0] === "E"
                                      ? PageDisplay.showErrorNotification(
                                          formik.status[1]
                                        )
                                      : PageDisplay.showSuccessNotification(
                                          formik.status[1]
                                        )}
                                  </Col>
                                </Row>
                              ) : null}
                            </div>
                            {this.state.userRole.roleId ===
                            this.state.role.roleId ? (
                              <div>
                                <Row>
                                  <Col xs={12}>
                                    {PageDisplay.showWarningNotification(
                                      RoleMsgResProps.body.notification.warning
                                        .roleNoEditLoginUser
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            ) : this.state.role.mainStatus === 1 ? (
                              <div>
                                <Row>
                                  <Col xs={12}>
                                    {PageDisplay.showWarningNotification(
                                      RoleMsgResProps.body.notification.warning
                                        .roleMain
                                    )}
                                  </Col>
                                </Row>
                              </div>
                            ) : null}

                            <div>
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="roleName">
                                      {RoleMsgResProps.body.form.roleName.label}{" "}
                                      *
                                    </Label>
                                    <Field
                                      name="roleName"
                                      type="text"
                                      maxLength="50"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.roleName,
                                        formik.touched.roleName
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "roleName"
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="roleType">
                                      {RoleMsgResProps.body.form.roleType.label}
                                    </Label>
                                    <Field
                                      name="roleType"
                                      type="text"
                                      as="select"
                                      disabled={true}
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.roleType,
                                        formik.touched.roleType
                                      )}
                                    >
                                      <option value=""></option>
                                      <option value="S">
                                        {
                                          RoleMsgResProps.body.form.roleType
                                            .options["S"]
                                        }
                                      </option>
                                      <option value="O">
                                        {
                                          RoleMsgResProps.body.form.roleType
                                            .options["O"]
                                        }
                                      </option>
                                      <option value="P">
                                        {
                                          RoleMsgResProps.body.form.roleType
                                            .options["P"]
                                        }
                                      </option>
                                    </Field>
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "roleType"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                            <div className="mt-3">
                              {this.state.appPartsAvailable &&
                              this.state.appPartsAvailable.length > 0 ? (
                                <FieldArray
                                  name="actionId"
                                  render={(arrayHelpers) => (
                                    <>
                                      <div className="pb-2">
                                        {
                                          RoleMsgResProps.body.content
                                            .accessPrivileges
                                        }
                                      </div>
                                      {this.state.appPartsAvailable.map(
                                        (module, index1) => {
                                          return (
                                            <div
                                              className="mt-1 mb-4 sram-border-blue-3"
                                              key={`key-role-m-${index1}`}
                                            >
                                              <div className="p-2 pl-3 sram-bg-blue-2 font-medium">
                                                {module.moduleName}
                                              </div>
                                              <Row form className="p-2">
                                                {module.features &&
                                                module.features.length > 0 ? (
                                                  <>
                                                    {module.features.map(
                                                      (feature, index2) => {
                                                        return (
                                                          <Col
                                                            sm={12}
                                                            md={6}
                                                            lg={6}
                                                            key={`key-role-f-${index2}`}
                                                            className="mt-1 mb-3 "
                                                          >
                                                            <div className="sram-bg-blue-1 px-2 py-1">
                                                              <FormGroup
                                                                check
                                                                inline
                                                              >
                                                                <Label check>
                                                                  <Input
                                                                    name="selectAll"
                                                                    type="checkbox"
                                                                    value={
                                                                      feature.featureId
                                                                    }
                                                                    onChange={async (
                                                                      e
                                                                    ) => {
                                                                      await this.toggleMultipleActions(
                                                                        e,
                                                                        feature
                                                                      );
                                                                    }}
                                                                  />
                                                                  &nbsp;
                                                                  <span className="font-mild">
                                                                    {
                                                                      feature.featureName
                                                                    }
                                                                  </span>
                                                                </Label>
                                                              </FormGroup>
                                                            </div>
                                                            <Row
                                                              form
                                                              className="p-2"
                                                            >
                                                              {feature.actions &&
                                                              feature.actions
                                                                .length > 0 ? (
                                                                <>
                                                                  {feature.actions.map(
                                                                    (
                                                                      action,
                                                                      index3
                                                                    ) => {
                                                                      return (
                                                                        <Col
                                                                          sm={
                                                                            12
                                                                          }
                                                                          md={
                                                                            12
                                                                          }
                                                                          lg={
                                                                            12
                                                                          }
                                                                          key={`key-role-a-${index3}`}
                                                                        >
                                                                          <FormGroup
                                                                            check
                                                                            inline
                                                                          >
                                                                            <Label
                                                                              check
                                                                            >
                                                                              <Input
                                                                                name="actionId"
                                                                                id={`check_${action.actionId}`}
                                                                                type="checkbox"
                                                                                value={
                                                                                  action.actionId
                                                                                }
                                                                                disabled={
                                                                                  feature.defaultStatus
                                                                                }
                                                                                checked={formik.values.actionId.includes(
                                                                                  action.actionId
                                                                                )}
                                                                                onChange={async (
                                                                                  e
                                                                                ) => {
                                                                                  await this.toggleSingleAction(
                                                                                    e,
                                                                                    action,
                                                                                    formik,
                                                                                    arrayHelpers
                                                                                  );
                                                                                }}
                                                                              />
                                                                              &nbsp;
                                                                              {
                                                                                action.actionName
                                                                              }
                                                                            </Label>
                                                                          </FormGroup>
                                                                        </Col>
                                                                      );
                                                                    }
                                                                  )}
                                                                </>
                                                              ) : null}
                                                            </Row>
                                                          </Col>
                                                        );
                                                      }
                                                    )}
                                                  </>
                                                ) : null}
                                              </Row>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  )}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="sram-form-foot">
                            {this.state.userRole.roleId !==
                              this.state.role.roleId &&
                            this.state.role.mainStatus !== 1
                              ? PageDisplay.showSaveButton(formik.isSubmitting)
                              : null}
                            {PageDisplay.showCancelButton(this.props.history)}
                          </div>
                          <FormFieldFocusError />
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
                {this.state.tempRoleObj && this.state.appPartsAvailable ? (
                  <ConfirmModal
                    isOpenConfirmModal={this.state.isOpenConfirmModal}
                    closeConfirmModal={this.closeConfirmModal}
                    handleSubmit={this.handleSubmit}
                  >
                    <div>
                      <div>
                        <Label>
                          {
                            RoleMsgResProps.body.notification.confirmation
                              .editRoleTitle
                          }
                        </Label>
                      </div>
                    </div>
                  </ConfirmModal>
                ) : null}
              </>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  RoleMsgResProps.body.content.noRoleFound
                )}
              </>
            )}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default RoleEdit;
