import React, { Component } from "react";
import { Card } from "reactstrap";
import { ErrorMsgResProps } from "./messages/error-properties";

class ErrorPageNotFound extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <>
        <Card className="sram-page-view">
          <div className="p-4">
            <div className="pb-3 font-medium border-bottom font-18 text-uppercase text-danger">
            {ErrorMsgResProps.head.title.pageNotFound}
            </div>
            <div className="pt-4 pb-3 text-dark">
              <div className="mt-4 mb-5 font-16">
              {ErrorMsgResProps.body.content.noAccess}
              </div>
            </div>
          </div>
        </Card>
      </>
    );
  }
}

export default ErrorPageNotFound;
