import React, { Component } from "react";
import {
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Col,
  Row,
  Alert,
  Badge
} from "reactstrap";
import { AppConfigProps } from "../core/settings/app-config";
import { manageError } from "../core/actions/common-actions";
import { sitesInfo } from "./actions/ribbon-actions";
import ReactApexChart from "react-apexcharts";
import { connect } from "react-redux";
import WithLoading from "../core/helpers/with-loading";

const mapStateToProps = (state) => ({
  ...state,
});

class NavRibbonMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleOption: false,
      isChartDataFetched: false,
      options: {
        labels: ['Sites with No Alerts', 'Troubled Sites'],
        chart: {
          width: 350,
          type: 'donut',
        },
        colors: ['#45955e', '#f1f36e'],
        dataLabels: {
          style: {
            colors: ['#45955e', '#f1f36e']
          },
          enabled: false
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              show: false
            }
          }
        }],
        legend: {
          position: 'right',
          offsetY: 0,
          height: 230,
        }
      },
      sitesInfoData: null,
    }
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    this.loadPageData();
  }

  loadPageData = async () => {
    // this.setStateAsync({ isPageDataFetched: false })
    await sitesInfo(this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          this.setStateAsync({ sitesInfoData: res.data.records })
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    // this.setStateAsync({ isPageDataFetched: true })
  };

  async toggleOptionChange() {
    await this.setState({ isChartDataFetched: false });
    await this.setState({ toggleOption: !this.state.toggleOption });
    await this.setState({ isChartDataFetched: true });
  }

  render() {
    return (

      <Nav className="ml-auto float-right" navbar>
        {/*--------------------------------------------------------------------------------*/}
        {/* Start Mega Menu Dropdown                                                       */}
        {/*--------------------------------------------------------------------------------*/}
        <UncontrolledDropdown nav inNavbar className="mega-dropdown">
          <DropdownToggle onClick={() => this.toggleOptionChange()} style={{ display: "inline-flex", background: "none", border: "none", boxShadow: "none" }}>
            <span>
              <div style={{ fontSize: "13px", color: "#fff" }}>Contracted Sites</div>
              <div ><Badge style={{ alignItems: "center", fontSize: "16px", fontWeight: "600" }} color="light">&nbsp;&nbsp;{this.state.sitesInfoData?.sitesCount ? this.state.sitesInfoData?.sitesCount : "0"}&nbsp;&nbsp;</Badge></div>
            </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>
              <div style={{ fontSize: "13px", color: "#fff" }}>Sites with No Alerts</div>
              <div ><Badge style={{ alignItems: "center", fontSize: "16px", fontWeight: "600" }} color="success">&nbsp;&nbsp;{this.state.sitesInfoData?.sitesWithNoAlertsCount ? this.state.sitesInfoData?.sitesWithNoAlertsCount : "0"}&nbsp;&nbsp;</Badge></div>
            </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span>
              <div style={{ fontSize: "13px", color: "#fff" }}>Troubled Sites</div>
              <div ><Badge style={{ alignItems: "center", fontSize: "16px", fontWeight: "600" }} color="danger">&nbsp;&nbsp;{this.state.sitesInfoData?.troubleCount ? this.state.sitesInfoData?.troubleCount : "0"}&nbsp;&nbsp;</Badge></div>
            </span> &nbsp;&nbsp;&nbsp;&nbsp;
            <span>
              <div> </div>
              <div style={{ color: "#fff" }}>
                {this.state.toggleOption === false ?
                  <i className="mt-3 fa fa-angle-double-up" aria-hidden="true"></i>
                  : <i className=" mt-3 fa fa-angle-double-down" aria-hidden="true" />}
              </div>
            </span>
          </DropdownToggle>
          <DropdownMenu>
            <Row style={{ background: "#FFFFFF" }} className="pb-2 pt-2">
              {/*--------------------------------------------------------------------------------*/}
              {/* Progress [Item-2]                                                              */}
              {/*--------------------------------------------------------------------------------*/}
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h5 className="color-black">Site Status</h5>
              </Col>
              {/* <Col></Col> */}
              <Col className="pl-4 ml-4" style={{ textAlign: "center" }}>
                <i className="fa fa-circle" style={{ color: "blue" }} aria-hidden="true"></i>
                <h4 className="color-black">{this.state.sitesInfoData?.sitesCount ? this.state.sitesInfoData?.sitesCount : "0"}</h4>
                <div className="color-black">
                  Contracted Sites
                </div>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <i className="fa fa-circle" style={{ color: "green" }} aria-hidden="true"></i>
                <h4 className="color-black">{this.state.sitesInfoData?.sitesWithNoAlertsCount ? this.state.sitesInfoData?.sitesWithNoAlertsCount : "0"}</h4>
                <div className="color-black">
                  Sites with No Alerts
                </div>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <i className="fa fa-exclamation-triangle" style={{ color: "yellow" }} aria-hidden="true"></i>
                <h4 className="color-black">{this.state.sitesInfoData?.troubleCount ? this.state.sitesInfoData?.troubleCount : "0"}</h4>
                <div className="color-black">
                  Troubled Sites
                </div>
              </Col>
            </Row>
            <Row className="mt-2 pt-2">
              {/*--------------------------------------------------------------------------------*/}
              {/* Carousel [Item-1]                                                              */}
              {/*--------------------------------------------------------------------------------*/}
              <Col style={{ background: "#FFFFFF" }}>
                {/* <h5 className="mt-2 color-black">Site Status</h5> */}
                <WithLoading
                  isPageDataFetched={this.state.isChartDataFetched}
                  type="page"
                  size="sm"
                >
                  <ReactApexChart options={this.state.options} series={[this.state.sitesInfoData?.sitesWithNoAlertsCount, this.state.sitesInfoData?.troubleCount]} type="donut" width={400} />
                </WithLoading>
              </Col>
              {/*--------------------------------------------------------------------------------*/}
              {/* Contact Us [Item-3]                                                            */}
              {/*--------------------------------------------------------------------------------*/}
              <Col style={{ background: "#FFFFFF" }} className="ml-2">
                <h5 className="mt-2 color-black">Alerts/Tickets</h5>
                <div className="text-align-centre">
                  <Alert color="danger">
                    {this.state.sitesInfoData?.alertsCount} New Alerts
                  </Alert>
                  <Alert style={{ color: '#856404', backgroundColor: '#fff3cd', borderColor: '#ffeeba' }}>
                    {this.state.sitesInfoData?.ticketsCount} Work In Progress Tickets
                  </Alert>
                  <Alert color="success">
                    {this.state.sitesInfoData?.dispatchTicketsCount} Dispatched Tickets
                  </Alert>
                </div>
              </Col>
            </Row>
          </DropdownMenu>
        </UncontrolledDropdown>
        {/*--------------------------------------------------------------------------------*/}
        {/* End Mega Menu Dropdown                                                         */}
        {/*--------------------------------------------------------------------------------*/}
      </Nav>
    )
  }
}

export default connect(mapStateToProps)(NavRibbonMenu);
