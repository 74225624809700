export default class DispenserUtils {
  static getSeverity(level) {
    let severity = "";
    if (level) {
      if (level === 1) {
        severity = "Super Major";
      }
      if (level === 2) {
        severity = "Major";
      }
      if (level === 3) {
        severity = "Medium";
      }
      if (level === 4) {
        severity = "Minor";
      }
      if (level === 5) {
        severity = "Info";
      }
    }
    return severity;
  }

  static capitalize(str) {
    if (str) {
      if (str.charAt(0) === " ") {
        return str.charAt(1).toUpperCase() + str.slice(2);
      } else {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    }
  }
  static getSide(str){
      let side = ''
    if (str){
        if(str.toLowerCase() === "a"){
            side = "Side A"
        }
        if(str.toLowerCase() === "b"){
            side = "Side B"
        }
        if(str.toLowerCase() === "ab"){
            side = "All Pump"
        }
    }
    return side
  }
}
