import React, { Component } from "react";
import { Card, CardTitle, CardBody, Table } from "reactstrap";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import { SetupURLProps } from "../settings/setup-urls";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { ServiceProviderMsgResProps } from "../messages/service-provider-properties";
import { manageError } from "../../core/actions/common-actions";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import { listCompanies, searchOrgCompanyList } from "../actions/service-provider-actions";
import PageListPagination from "../../core/helpers/page-list-pagination";
import PageListLimitSelect from "../../core/helpers/page-list-limit-select";
import PageListHeaderColumn from "../../core/helpers/page-list-header-column";
import PageListSearchField from "../../core/helpers/page-list-search-field";

class ServiceProviderCompanyList extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcServiceProviderCompanyView = "AS-SPV-CPVW";
  _pcServiceProviderCompanyAdd = "AS-SPV-CPAD";
  _pcServiceProviderCompanyEdit = "AS-SPV-CPED";
  _pcServiceProviderSiteView = "AS-SPV-STVW";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      partPrivileges: this.initPartPrivileges(),
      orgCompaniesAllRecords: null,
      orgCompaniesDisplayRecords: null,
      orgCompaniesPageRecords: null,
      searchValue: "",
      searchKeyword: "",
      pageOffset: AppConfigProps.listPage.pageOffset.defaultValue,
      pageLimit: AppConfigProps.listPage.pageLimit.defaultValue,
      sortedColumn: "organizationName",
      sortedOrder: AppConfigProps.listPage.sortOrder.defaultValue,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      PageUtils.scrollToTop();
      await this.loadPageData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ orgCompanies: null });
    await this.setPartPrivileges();
    if (
      this.state.partPrivileges[this._pcServiceProviderCompanyView] === true
    ) {
      const queryData = "sortField=organizationName&sortOrder=1";

      await listCompanies(queryData, this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            if (res.data.records.length > 0) {
              await this.setStateAsync({ orgCompaniesAllRecords: res.data.records });
              let sortedRecords = await PageUtils.sortListRecords(
                this.state.orgCompaniesAllRecords,
                this.state.sortedColumn,
                this.state.sortedOrder
              );
              await this.setStateAsync({ orgCompaniesDisplayRecords: sortedRecords });
              let slicedRecords = await PageUtils.sliceListRecords(
                this.state.orgCompaniesDisplayRecords,
                this.state.pageOffset,
                this.state.pageLimit
              );
              await this.setStateAsync({ orgCompaniesPageRecords: slicedRecords });
            }
          } else {
            await manageError(res, this.props.history);
          }
        })
        .catch(async (err) => {
          await manageError(err, this.props.history);
        });
    }
    await this.setStateAsync({ isPageDataFetched: true });
  };

  initPartPrivileges = () => {
    return {
      [this._pcServiceProviderCompanyView]: false,
      [this._pcServiceProviderCompanyAdd]: false,
      [this._pcServiceProviderCompanyEdit]: false,
      [this._pcServiceProviderSiteView]: false,
    };
  };

  setPartPrivileges = async () => {
    const partCodes = [
      this._pcServiceProviderCompanyView,
      this._pcServiceProviderCompanyAdd,
      this._pcServiceProviderCompanyEdit,
      this._pcServiceProviderSiteView,
    ];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  getTableColumnHeadings = () => {
    return [
      {
        columnId: "organizationName",
        columnType: "string",
        columnLabel: ServiceProviderMsgResProps.body.form.company.label,
        isSortable: true,
        columnWidth: "100%",
      },
    ];
  };

  handleSortChange = async (sortColumn, sortOrder, sortType) => {
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.orgCompaniesDisplayRecords,
      sortColumn,
      sortOrder,
      sortType
    );
    await this.setStateAsync({ orgCompaniesDisplayRecords: sortedRecords });
    await this.setStateAsync({
      sortedColumn: sortColumn,
      sortedOrder: sortOrder,
    });

    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.orgCompaniesDisplayRecords,
      pageOffset,
      this.state.pageLimit
    );
    await this.setStateAsync({ orgCompaniesPageRecords: slicedRecords });
    await this.setStateAsync({ pageOffset: pageOffset });
  };

  handlePageLimitChange = async (pageLimit) => {
    if (pageLimit > 0) {
      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.orgCompaniesDisplayRecords,
        pageOffset,
        pageLimit
      );
      await this.setStateAsync({ orgCompaniesPageRecords: slicedRecords });
      await this.setStateAsync({ pageLimit: pageLimit });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handlePageOffsetChange = async (pageOffset) => {
    if (pageOffset >= 0) {
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.orgCompaniesDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );
      await this.setStateAsync({ orgCompaniesPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchChange = async (e) => {
    await this.setStateAsync({ searchValue: e.target.value });
  };

  handleSearchSubmit = async (e) => {
    if (this.state.orgCompaniesAllRecords && this.state.orgCompaniesAllRecords.length > 0) {
      let searchKeyword = this.state.searchValue;
      await this.setStateAsync({ searchKeyword: searchKeyword });
      let searchedRecords = await searchOrgCompanyList(
        this.state.orgCompaniesAllRecords,
        this.state.searchKeyword
      );

      let sortedRecords = await PageUtils.sortListRecords(
        searchedRecords,
        this.state.sortedColumn,
        this.state.sortedOrder
      );
      await this.setStateAsync({ orgCompaniesDisplayRecords: sortedRecords });

      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.orgCompaniesDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );

      await this.setStateAsync({ orgCompaniesPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchCancel = async (e) => {
    e.preventDefault();
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.orgCompaniesAllRecords,
      this.state.sortedColumn,
      this.state.sortedOrder
    );
    await this.setStateAsync({ orgCompaniesDisplayRecords: sortedRecords });
    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.orgCompaniesDisplayRecords,
      this.state.pageOffset,
      this.state.pageLimit
    );
    await this.setStateAsync({ orgCompaniesPageRecords: slicedRecords });

    await this.setStateAsync({ searchKeyword: "", searchValue: "" });
    await this.setStateAsync({ pageOffset: pageOffset });
  };

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <Card className="sram-page-list">
              <CardTitle>
                <span>
                  {
                    ServiceProviderMsgResProps.body.content
                      .serviceProviderCompanies
                  }
                </span>
                <span className="float-right">
                  {this.state.partPrivileges[
                    this._pcServiceProviderCompanyAdd
                  ] === true
                    ? PageDisplay.showAddLink(
                      "L",
                      SetupURLProps.serviceProviders.addCompany,
                      ServiceProviderMsgResProps.body.content.addCompany
                    )
                    : null}
                </span>
              </CardTitle>
              <CardBody>
                <>
                  {this.state.orgCompaniesAllRecords &&
                    this.state.orgCompaniesAllRecords.length > 0 ? (
                    <div className="sram-list-head">
                      <span className="float-left col-sm-6 col-md-4 col-lg-4 pad-left-0">
                        <PageListSearchField
                          searchValue={this.state.searchValue}
                          searchKeyword={this.state.searchKeyword}
                          handleSearchChange={this.handleSearchChange}
                          handleSearchSubmit={this.handleSearchSubmit}
                          handleSearchCancel={this.handleSearchCancel}
                        />
                      </span>
                      <span className="float-right">
                        {PageDisplay.getListPageRecordsDisplayInfo(
                          this.state.orgCompaniesDisplayRecords
                            ? this.state.orgCompaniesDisplayRecords.length
                            : 0,
                          this.state.pageOffset,
                          this.state.pageLimit
                        )}
                      </span>
                    </div>
                  ) : null}
                  <div className="sram-list-main">
                    {this.state.orgCompaniesPageRecords &&
                      this.state.orgCompaniesPageRecords.length > 0 ? (
                      <Table
                        responsive
                        hover
                        size="sm"
                        className="sram-table-main"
                      >
                        <thead>
                          <tr>
                            {this.getTableColumnHeadings().map((column) => {
                              return (
                                <PageListHeaderColumn
                                  {...column}
                                  key={column.columnId}
                                  sortedColumn={this.state.sortedColumn}
                                  sortedOrder={this.state.sortedOrder}
                                  handleSortChange={this.handleSortChange}
                                />
                              );
                            })}
                            <th width="70"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.orgCompaniesPageRecords.map((organization, index) => {
                            let keyString = { key: `key-spc-list-${index}` };
                            return (
                              <tr {...keyString}>
                                <td>{organization.organizationName}</td>
                                <td className="text-right">
                                  {this.state.partPrivileges[
                                    this._pcServiceProviderSiteView
                                  ] === true
                                    ? PageDisplay.showActionLink(
                                      "S",
                                      "A",
                                      SetupURLProps.serviceProviders.listSite.replace(
                                        SetupURLProps.serviceProviders.listSite,
                                        SetupURLProps.serviceProviders.listSite + "?search=" + organization.organizationName
                                      ),
                                      ServiceProviderMsgResProps.body.content
                                        .siteView
                                    )
                                    : null}
                                  {this.state.partPrivileges[
                                    this._pcServiceProviderCompanyEdit
                                  ] === true
                                    ? PageDisplay.showEditLink(
                                      "S",
                                      SetupURLProps.serviceProviders.editCompany.replace(
                                        ":oid",
                                        organization.organizationId
                                      ),
                                      ServiceProviderMsgResProps.body.content
                                        .editCompany
                                    )
                                    : null}
                                  {this.state.partPrivileges[
                                    this._pcServiceProviderCompanyView
                                  ] === true
                                    ? PageDisplay.showViewLink(
                                      "S",
                                      SetupURLProps.serviceProviders.viewCompany.replace(
                                        ":oid",
                                        organization.organizationId
                                      ),
                                      ServiceProviderMsgResProps.body.content
                                        .viewCompany
                                    )
                                    : null}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      <>
                        <div>
                          {
                            ServiceProviderMsgResProps.body.content
                              .noCompaniesFound
                          }
                        </div>
                      </>
                    )}
                  </div>
                  {this.state.orgCompaniesPageRecords &&
                    this.state.orgCompaniesPageRecords.length ? (
                    <div className="sram-list-foot">
                      <div className="float-left">
                        <PageListLimitSelect
                          pageLimit={this.state.pageLimit}
                          pageOffset={this.state.pageOffset}
                          totalRecords={this.state.orgCompaniesDisplayRecords.length}
                          handlePageLimitChange={this.handlePageLimitChange}
                        />
                      </div>
                      <div className="float-right">
                        <PageListPagination
                          pageLimit={this.state.pageLimit}
                          pageOffset={this.state.pageOffset}
                          totalRecords={this.state.orgCompaniesDisplayRecords.length}
                          handlePageOffsetChange={this.handlePageOffsetChange}
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              </CardBody>
            </Card>
          </div>
        </WithLoading>
      </>
    );
  }
}

export default ServiceProviderCompanyList;
