import React, { Component } from "react";
import imgLogo from "../../assets/images/guardianConnect.png";
import { ErrorMsgResProps } from "./messages/error-properties";

class ErrorBoundary extends Component {
  state = {
    errorMessage: "",
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }
  componentDidCatch(error, info) {
    //console.error(error.toString(), info.componentStack);
  }

  render() {
    if (this.state.errorMessage) {
      return (
        <>
          <div className="">
            <div className="error-box">
              <div className="error-body text-center">
                <img src={imgLogo} alt="Guardian Connect" />
                <div className="mt-4 py-2 border-danger border-bottom border-top font-medium font-18 text-uppercase">
                {ErrorMsgResProps.head.title.error}
                </div>
                <div className="pt-4 pb-3 text-dark">
                  <div className="mt-4 mb-5 font-14">
                    {this.state.errorMessage}
                  </div>
                  <div className="mb-4">
                    <div className="d-inline font-14 font-medium">
                      <a
                        href="/"
                        className="text-dark border border-info rounded p-2 bg-light m-2"
                      >
                      {ErrorMsgResProps.body.form.homePage.label}
                      </a>

                      <a
                        href="/login"
                        className="text-dark border border-info rounded p-2 bg-light m-2"
                      >
                      {ErrorMsgResProps.body.form.loginPage.label}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
