import React from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Table,
  Badge,
  Collapse,
  Spinner,
} from "reactstrap";
import { getHistory } from "../actions/alert-actions";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import AppUtils from "../../core/helpers/app-utils";
import WithLoading from "../../core/helpers/with-loading";
import { AlertMsgResProps } from "../messages/alert-properties";
import { AppMsgResProps } from "../../core/messages/app-properties";
import axios from "axios";
import AlertUtils from "../helpers/alert-utils";
// import _ from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from "react-select";

class ClosedTickets extends React.Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  constructor() {
    super();
    this.state = {
      prioritylist: [
        {
          label: "High",
          value: "High",
        },
        {
          label: "Medium",
          value: "Medium",
        },
        {
          label: "Low",
          value: "Low",
        },
      ],
      changePriority: { label: "Change Priority", value: "" },
      isAlertStatusUpdateModal: false,
      alertTicketId: null,
      ticketPriority: null,
      lockStatus: null,
      statusData: null,
      statusDataFetched: false,
      isHistoryDataFetched: false,
      history: null,
      ticketStatus: null,
      assignId: null,
      historyDataFetched: true,
      collapsePriority: false,
      collapseStatus: false,
      collapseCategory: false,
      collapseTicketId: false,
      showSelectedFilters: [],
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  async componentWillReceiveProps() {
    await this.setStateAsync({ siteName: null, user: null });
  }

  getHistory = async (alertTicketId) => {
    await this.setStateAsync({
      historyDataFetched: false,
      isHistoryDataFetched: false,
      history: null,
      alertTicketId: alertTicketId,
    });
    await getHistory(alertTicketId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let ticketsList = this.props.closedTickets;
          for (const data of ticketsList) {
            if (data.alertTicketId === alertTicketId) {
              data.alertHistory = res.data.records;
              data.isOpen = !data.isOpen;
            }
          }
          this.props.getClosedTickets(ticketsList);
          await this.setStateAsync({
            history: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({
      isHistoryDataFetched: true,
      historyDataFetched: true,
    });
  };

  getAddress(siteAddress, siteAddress1) {
    let address = "";
    if (siteAddress && siteAddress1) {
      address = siteAddress + "," + siteAddress1;
    } else if (siteAddress) {
      address = siteAddress;
    } else {
      address = siteAddress1;
    }
    return address;
  }

  getSquare(status) {
    if (status) {
      if (status.toLowerCase() === "high") {
        return (
          <div className="tooltip">
            {" "}
            <Badge className="sram-badge-danger" color="danger">
              &nbsp;
            </Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      } else if (status.toLowerCase() === "medium") {
        return (
          <div className="tooltip">
            {" "}
            <Badge className="sram-badge-warning" color="warning">
              &nbsp;
            </Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      } else if (status.toLowerCase() === "low") {
        return (
          <div className="tooltip">
            {" "}
            <Badge className="sram-badge-success" color="success">
              &nbsp;
            </Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      } else {
        return (
          <div className="tooltip">
            {" "}
            <Badge color="secondary">&nbsp;</Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      }
    } else {
      return (
        <div className="tooltip">
          {" "}
          <Badge className="sram-badge-gray">&nbsp;</Badge>
          <span className="tooltiptext">Priority : None</span>
        </div>
      );
    }
  }

  getEventStatus(status) {
    if (status !== " ") {
      if (
        status.toLowerCase() === "down" ||
        status.toLowerCase() === "critical" ||
        status.toLowerCase() === "in-error"
      ) {
        return (
          <div className="tooltip">
            {" "}
            <span
              className="sram-circle-red tooltip"
              style={{ height: "20px", width: "20px" }}
            >
              &nbsp;
            </span>
            <span className="tooltiptext">Status : {status}</span>
          </div>
        );
      } else if (status.toLowerCase() === "in-warning") {
        return (
          <div className="tooltip">
            {" "}
            <span
              className="sram-circle-yellow tooltip"
              style={{ height: "20px", width: "20px" }}
            >
              &nbsp;
            </span>
            <span className="tooltiptext">Status : {status}</span>
          </div>
        );
      } else {
        return (
          <div className="tooltip">
            {" "}
            <span
              className="sram-circle-orange tooltip"
              style={{ height: "20px", width: "20px" }}
            >
              &nbsp;
            </span>
            <span className="tooltiptext">Status : {status}</span>
          </div>
        );
      }
    } else {
      return (
        <div className="tooltip">
          {" "}
          <span
            className="sram-circle-gray tooltip"
            style={{ height: "20px", width: "20px" }}
          >
            &nbsp;
          </span>
          <span className="tooltiptext">Status : No status from dispenser</span>
        </div>
      );
    }
  }

  checkTicket = async (event) => {
    var value = event.target.value;
    var checked = event.target.checked;
    let todos = this.props.todos;
    let todos1 = this.props.todos1;
    let todos2 = this.props.todos2;
    let todos3 = this.props.todos3;
    todos.forEach((obj) => {
      if (obj.value === value) obj["isChecked"] = checked;
    });
    await this.props.checkPriority(todos);
    todos1.forEach((obj) => {
      if (obj.value === value) obj["isChecked"] = checked;
    });
    await this.props.checkAlertsStatus(todos1);
    todos2.forEach((obj) => {
      if (obj.value === value) obj["isChecked"] = checked;
    });
    await this.props.checkResolutionCategory(todos2);
    todos3.forEach((obj) => {
      if (obj.value === value) {
        obj["isChecked"] = checked;
        obj["content"] = value;
        obj["value"] = value;
      }
    });
    await this.props.checkTicketIDs(todos3);
    const filterList = [...todos, ...todos1, ...todos2, ...todos3];
    this.setState({ showSelectedFilters: filterList });
    await this.props.reloadTicketsData();
  };

  handleSearchTickets = async (e) => {
    let isChecked;
    let searchKeyword;
    if (e === null) {
      isChecked = false;
      searchKeyword = "";
    } else {
      isChecked = true;
      searchKeyword = e.ticketNumber;
    }

    let event = {
      target: {
        content: searchKeyword,
        value: searchKeyword,
        checked: isChecked,
      },
    };

    let updateArray = [
      {
        id: 100,
        content: searchKeyword,
        value: searchKeyword,
        isChecked: isChecked,
      },
    ];
    await this.props.checkTicketIDs(updateArray);
    await this.checkTicket(event);
  };

  handleRemoveClick = async (obj) => {
    if (obj && obj.id === 100) {
      let updateArray = [
        {
          id: 100,
          content: "",
          value: "",
          checked: false,
        },
      ];
      let event = {
        target: {
          content: "",
          value: "",
          checked: false,
        },
      };
      await this.props.checkTicketIDs(updateArray);
      await this.checkTicket(event);
    } else {
      let event = {
        target: {
          value: obj.value,
          checked: false,
        },
      };
      await this.checkTicket(event);
    }
  };

  toggleOptions = (key) => {
    const selectedOption = key;
    if (selectedOption === "priority") {
      this.setStateAsync({ collapsePriority: !this.state.collapsePriority });
    } else if (selectedOption === "alertStatus") {
      this.setStateAsync({ collapseStatus: !this.state.collapseStatus });
    } else if (selectedOption === "resolutionCategory") {
      this.setStateAsync({ collapseCategory: !this.state.collapseCategory });
    } else if (selectedOption === "ticketId") {
      this.setStateAsync({ collapseTicketId: !this.state.collapseTicketId });
    }
  };

  clearAllticketsFilters = async () => {
    await this.props.clearAllticketsFilters();
    await this.setStateAsync({
      collapsePriority: false,
      collapseStatus: false,
      collapseCategory: false,
    });
    await this.props.reloadTicketsData();
  };

  render() {
    const filteredData = this.state.showSelectedFilters.filter(
      (item) => item.isChecked
    );
    return (
      <>
        <Row
          style={{ padding: "15px" }}
          className="sram-table-wrapper-ticketsView-height"
        >
          <Col
            sm={12}
            md={3}
            lg={2}
            className="pad-right-0 pad-left-0"
            style={{ borderRight: "2px solid #ddd" }}
          >
            <div className="todo-widget sram-todo-widget">
              <PerfectScrollbar className="sram-table-wrapper-ticket-filters">
                <ListGroup
                  className="list-task todo-list list-group m-b-0"
                  data-role="tasklist"
                >
                  {/* <Col sm={12} md={12} lg={12}> */}
                  <ListGroupItem
                    className={
                      "list-group-item todo-item sram-todo-item border-0"
                    }
                  >
                    <span className="sram-text-font">FilterBy:</span>
                    {filteredData && filteredData.length > 0 ? (
                      <span
                        className="sram-text-font sram-alert-clear mr-3 float-right"
                        onClick={() => this.clearAllticketsFilters()}
                      >
                        <u>Clear All</u>
                      </span>
                    ) : null}
                  </ListGroupItem>

                  {this.state.showSelectedFilters &&
                    this.state.showSelectedFilters.map((obj, i) => {
                      return obj.isChecked === true ? (
                        <ListGroupItem
                          className={
                            "list-group-item todo-item sram-todo-item  sram-text-font border-0 sram-font-weight-500"
                          }
                        >
                          <>
                            {obj.content}{" "}
                            <i
                              className="fa fa-times mr-3 sram-title-cursor"
                              aria-hidden="true"
                              onClick={() => this.handleRemoveClick(obj)}
                            ></i>{" "}
                          </>
                        </ListGroupItem>
                      ) : null;
                    })}

                  <div
                    className="sram-tickets-filters sram-title-cursor sram-text-font pb-1"
                    onClick={() => this.toggleOptions("priority")}
                  >
                    <b>Priority</b>
                    <span className="mr-3 float-right">
                      {this.state.collapsePriority === false ? (
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                  <Collapse isOpen={this.state.collapsePriority}>
                    {this.props.todos.map((todo, i) => {
                      return (
                        <ListGroupItem
                          className={
                            "list-group-item todo-item sram-todo-item border-0"
                          }
                          key={i}
                        >
                          <div className="sram-custom-checkbox-pad ">
                            <input
                              type="checkbox"
                              value={todo.value}
                              id={todo.id}
                              checked={todo.isChecked}
                              onChange={this.checkTicket}
                              className="form-check-input"
                              data-toggle="checkbox"
                            />
                            <label
                              className="sram-custom-control-label todo-label"
                              htmlFor={todo.id}
                            >
                              <span className="todo-desc sram-text-font">
                                {todo.content}
                              </span>
                            </label>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </Collapse>

                  <div
                    className="sram-tickets-filters-1 sram-title-cursor sram-text-font pb-1"
                    onClick={() => this.toggleOptions("alertStatus")}
                  >
                    <b>Alert Status</b>
                    <span className="mr-3 float-right">
                      {this.state.collapseStatus === false ? (
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                  <Collapse isOpen={this.state.collapseStatus}>
                    {this.props.todos1.map((todo, i) => {
                      return (
                        <ListGroupItem
                          className={
                            "list-group-item todo-item sram-todo-item border-0"
                          }
                          key={i}
                        >
                          <div className=" sram-custom-checkbox-pad">
                            <input
                              type="checkbox"
                              value={todo.value}
                              id={todo.id}
                              checked={todo.isChecked}
                              onChange={this.checkTicket}
                              className="form-check-input"
                              data-toggle="checkbox"
                            />
                            <label
                              className="sram-custom-control-label todo-label"
                              htmlFor={todo.id}
                            >
                              <span className="todo-desc sram-text-font">
                                {todo.content}
                              </span>
                            </label>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </Collapse>

                  <div
                    className="sram-tickets-filters-1 sram-title-cursor sram-text-font pb-1"
                    onClick={() => this.toggleOptions("resolutionCategory")}
                  >
                    <b>Resolution Category</b>
                    <span className="mr-3 float-right">
                      {this.state.collapseCategory === false ? (
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                  <Collapse isOpen={this.state.collapseCategory}>
                    {this.props.todos2.map((todo, i) => {
                      return (
                        <ListGroupItem
                          className={
                            "list-group-item todo-item sram-todo-item border-0"
                          }
                          key={i}
                        >
                          <div className=" sram-custom-checkbox-pad">
                            <input
                              type="checkbox"
                              value={todo.value}
                              id={todo.id}
                              checked={todo.isChecked}
                              onChange={this.checkTicket}
                              className="form-check-input"
                              data-toggle="checkbox"
                            />
                            <label
                              className="sram-custom-control-label todo-label"
                              htmlFor={todo.id}
                            >
                              <span className="todo-desc sram-text-font">
                                {todo.content}
                              </span>
                            </label>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </Collapse>

                  <div
                    className="sram-tickets-filters-1 sram-title-cursor sram-text-font pb-1"
                    onClick={() => this.toggleOptions("ticketId")}
                  >
                    <b>Ticket ID</b>
                    <span className="mr-3 float-right">
                      {this.state.collapseTicketId === false ? (
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>

                  <Collapse isOpen={this.state.collapseTicketId}>
                    {this.props.todos3.map((todo, i) => {
                      return (
                        <div className={"pt-2 mr-2"} key={i}>
                          <Select
                            placeholder="Search Tickets"
                            options={this.props.closedTickets}
                            getOptionLabel={(option) => option?.ticketNumber}
                            getOptionValue={(option) => option?.ticketNumber}
                            value={
                              this.props.closedTickets &&
                              this.props.closedTickets?.filter(function (
                                option
                              ) {
                                return option.ticketNumber === todo.value;
                              })
                            }
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#ced4da",
                                primary: "#ced4da",
                              },
                            })}
                            styles={{
                              option: (base) => ({
                                ...base,
                                color: "#000000",
                              }),
                            }}
                            isClearable
                            onChange={(e) => this.handleSearchTickets(e)}
                          ></Select>
                        </div>
                      );
                    })}
                  </Collapse>
                </ListGroup>
              </PerfectScrollbar>
            </div>
          </Col>

          <Col sm={12} md={9} lg={10} className="pad-left-0 pad-right-0">
            <PerfectScrollbar className="sram-table-wrapper-ticketsView">
              <div className="padding-8" style={{ background: "#F2F2F2" }}>
                <WithLoading
                  type="page"
                  className="pt-2"
                  isPageDataFetched={this.props.isclosedTicketsFetched}
                >
                  {this.props.closedTickets &&
                  this.props.closedTickets.length > 0 ? (
                    this.props.closedTickets.map((obj, i) => {
                      const siteAddress = AlertUtils.getSiteAddress(obj);
                      const siteAddress1 = AlertUtils.getSiteAddress1(obj);
                      let createTimestamp = "";
                      if (obj.createTimestamp) {
                        createTimestamp = obj.createTimestamp.split("-");
                        createTimestamp =
                          createTimestamp[0] +
                          "-" +
                          createTimestamp[1] +
                          "-" +
                          createTimestamp[2];
                      }

                      return (
                        <Card className="sram-dispenser-card-bottom" key={i}>
                          <CardBody>
                            <div className="d-md-flex align-items-center"></div>

                            <Row className="mb-2">
                              <Col sm={12} md={2} lg={2}>
                                <Badge
                                  color="secondary"
                                  className="sram-text-font sram-status"
                                >
                                  {obj.ticketStatus}
                                </Badge>
                              </Col>
                              <Col
                                sm={12}
                                md={
                                  obj.serviceCallId && obj.statusOfCall ? 3 : 4
                                }
                                lg={
                                  obj.serviceCallId && obj.statusOfCall ? 3 : 4
                                }
                              >
                                <span className="sram-error-modal-font sram-text-font font-mild">
                                  {obj.siteName} (GVR ID : {obj.siteNumber})
                                </span>
                                <div>
                                  <span className="fas fa-map-marker-alt"></span>{" "}
                                  &nbsp;
                                  <span className="sram-error-modal-font sram-text-font font-mild">
                                    {this.getAddress(siteAddress, siteAddress1)}
                                  </span>
                                </div>
                              </Col>
                              <Col sm={12} md={3} lg={3}>
                                <span className="sram-error-modal-font sram-text-font font-mild">
                                  Ticket Id: {obj.ticketNumber}
                                </span>
                                {/* <span className="ml-3 sram-error-modal-font sram-text-font font-mild">
                                  Purchase Order:{" "}
                                  {obj.customerPurchaseOrder
                                    ? obj.customerPurchaseOrder
                                    : "-"}
                                </span> */}
                                <div>
                                  <span className="sram-error-modal-font sram-text-font font-mild">
                                    Warranty:{" "}
                                    {obj.warrantyDescription &&
                                    obj.warrantyExpiryDate
                                      ? obj.warrantyDescription +
                                        "(" +
                                        AppUtils.getDate(
                                          obj.warrantyExpiryDate
                                        ) +
                                        ")"
                                      : "-"}
                                  </span>
                                </div>
                              </Col>
                              {obj.serviceCallId && obj.statusOfCall ? (
                                <Col
                                  sm={12}
                                  md={2}
                                  lg={2}
                                  className="pl-0 pr-0"
                                >
                                  {obj.serviceCallId && obj.statusOfCall ? (
                                    <>
                                      <span className="sram-error-modal-font sram-text-font font-mild">
                                        Service Call Id:{" "}
                                        {obj.serviceCallId
                                          ? obj.serviceCallId
                                          : "-"}
                                      </span>
                                      <div>
                                        <span className="sram-error-modal-font sram-text-font font-mild">
                                          Status:{" "}
                                          {obj.statusOfCall
                                            ? obj.statusOfCall
                                            : "-"}
                                        </span>
                                      </div>
                                    </>
                                  ) : null}
                                </Col>
                              ) : null}

                              <Col
                                sm={12}
                                md={
                                  obj.serviceCallId && obj.statusOfCall ? 2 : 3
                                }
                                lg={
                                  obj.serviceCallId && obj.statusOfCall ? 2 : 3
                                }
                              >
                                <span
                                  className="sram-error-modal-font sram-text-font font-mild pull-right"
                                  title={
                                    "Ticket Created TimeStamp: " +
                                    AppUtils.getDateTime(createTimestamp) +
                                    " " +
                                    obj.createTimestampTimezone
                                  }
                                >
                                  {AppUtils.getDateTime(createTimestamp)}{" "}
                                  {obj.createTimestampTimezone}
                                </span>
                              </Col>
                            </Row>
                            <div className="sram-border-top">
                              <div className="sram-list-main">
                                <Table
                                  responsive
                                  hover
                                  size="sm"
                                  className="sram-table-main"
                                >
                                  <thead>
                                    <th className="sram-th-0 sram-no-border"></th>
                                    <th className="sram-th-1 sram-no-border"></th>
                                    <th className="sram-th-2 sram-no-border"></th>
                                    <th className="sram-th-3 sram-no-border"></th>
                                    <th className="sram-th-4 sram-no-border"></th>
                                    <th className="sram-no-border"></th>
                                  </thead>
                                  <tbody>
                                    {obj.alertTickets &&
                                    obj.alertTickets.length > 0
                                      ? obj.alertTickets.map(
                                          (ticket, index) => {
                                            return (
                                              <tr key={"ticket" + index}>
                                                <td className="sram-text-font sram-table-td-1 sram-no-border">
                                                  {this.getEventStatus(
                                                    ticket.eventStatus
                                                  )}
                                                </td>

                                                <td
                                                  className="sram-text-font sram-table-td sram-no-border"
                                                  title={
                                                    ticket.componentName ===
                                                    "pumpcontrolnode"
                                                      ? "Alert Created TimeStamp: " +
                                                        AppUtils.getDateTime(
                                                          ticket.alertCreateTimestamp
                                                        ) +
                                                        " "
                                                      : // obj.createTimestampTimezone
                                                        "Alert Event TimeStamp: " +
                                                        AppUtils.getDateTime(
                                                          ticket.eventTimestamp
                                                        ) +
                                                        " "
                                                    // obj.createTimestampTimezone
                                                  }
                                                >
                                                  {ticket.componentName ===
                                                  "pumpcontrolnode"
                                                    ? AppUtils.getDateTime(
                                                        ticket.alertCreateTimestamp
                                                      )
                                                    : AppUtils.getDateTime(
                                                        ticket.eventTimestamp
                                                      )}{" "}
                                                  {/* {obj.createTimestampTimezone} */}
                                                </td>

                                                <td className="sram-text-font sram-table-td sram-no-border">
                                                  {ticket.dispenserName}
                                                </td>

                                                <td className="sram-text-font sram-table-td sram-no-border">
                                                  {ticket.sideLabel}
                                                </td>

                                                <td className="sram-text-font sram-table-td sram-no-border">
                                                  <div>
                                                    {ticket.componentName !==
                                                      "" &&
                                                    ticket.componentName !==
                                                      null
                                                      ? // ticket.itemName +
                                                        //   " - " +
                                                        ticket.componentName
                                                      : ticket.itemName}
                                                  </div>
                                                  {ticket.componentName ===
                                                  "pumpcontrolnode" ? (
                                                    <div>
                                                      {`Dispenser Create Timestamp: ${
                                                        ticket.dispenserCreateTimestamp
                                                          ? AppUtils.getDateTime(
                                                              ticket.dispenserCreateTimestamp
                                                            )
                                                          : "-"
                                                      }`}
                                                    </div>
                                                  ) : null}
                                                </td>

                                                <td className="sram-text-font sram-table-td sram-no-border">
                                                  {ticket.eventCause ===
                                                    "paper out" ||
                                                  ticket.eventCause ===
                                                    "paper jam" ||
                                                  ticket.eventCause ===
                                                    "paper low"
                                                    ? "Receipt Paper Error"
                                                    : ticket.eventCause}
                                                </td>

                                                <td className="sram-text-font sram-table-td sram-no-border">
                                                  {ticket.alertEventStatusDescription
                                                    ? ticket.alertEventStatusDescription
                                                    : "-"}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      : null}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                            <div className="sram-border-top">
                              <Row className="mt-2 mb-2">
                                <Col
                                  sm={12}
                                  md={1}
                                  lg={1}
                                  className="mt-3 sram-max-width-5"
                                >
                                  {this.getSquare(obj.ticketPriority)}
                                </Col>
                                <Col
                                  sm={12}
                                  md={11}
                                  lg={11}
                                  className="mt-2 sram-chev sidebar-nav"
                                >
                                  {this.state.historyDataFetched === false &&
                                  this.state.alertTicketId ===
                                    obj.alertTicketId ? (
                                    <Badge
                                      id={"toggler" + i}
                                      aria-expanded={obj.isOpen}
                                      onClick={() =>
                                        this.getHistory(obj.alertTicketId)
                                      }
                                      data-toggle="collapse"
                                      className="sram-bg-blue-6 pull-right font-mild sram-mrb-8 sram-links sidebar-link has-arrow sram-has-arrow sram-disable-badge disabled-link"
                                    >
                                      <span className="pl-2">
                                        {" "}
                                        Alert History{" "}
                                      </span>
                                      {obj.isOpen === false ? (
                                        <span className="fa fa-chevron-right ml-2 pr-2"></span>
                                      ) : (
                                        <span className="fa fa-chevron-down ml-2 pr-2"></span>
                                      )}
                                      <Spinner
                                        size="sm"
                                        color="light"
                                        style={{ marginBottom: "2px" }}
                                      />
                                    </Badge>
                                  ) : (
                                    <Badge
                                      disabled={
                                        obj.lockStatus ? "disabled" : ""
                                      }
                                      id={"toggler" + i}
                                      aria-expanded={obj.isOpen}
                                      onClick={() =>
                                        this.getHistory(obj.alertTicketId)
                                      }
                                      data-toggle="collapse"
                                      className="pull-right font-mild sram-mrb-8 sram-links sidebar-link has-arrow sram-has-arrow sram-alert-update"
                                    >
                                      <span className="pl-2">
                                        {" "}
                                        Alert History{" "}
                                      </span>
                                      {obj.isOpen === false ? (
                                        <span className="fa fa-chevron-right ml-2 pr-2"></span>
                                      ) : (
                                        <span className="fa fa-chevron-down ml-2 pr-2"></span>
                                      )}
                                    </Badge>
                                  )}
                                </Col>
                              </Row>
                            </div>
                            <Collapse
                              // toggler={"#toggler" + i}
                              isOpen={obj.isOpen}
                              className=" sram-border-top"
                            >
                              <Col
                                sm={12}
                                md={12}
                                lg={12}
                                className="pad-left-0 "
                              >
                                <Table>
                                  <thead>
                                    <tr>
                                      <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                        Timestamp
                                      </th>
                                      <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                        Name
                                      </th>
                                      <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                        Description
                                      </th>
                                      <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                        Notes
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.isHistoryDataFetched ===
                                      false &&
                                    this.state.alertTicketId ===
                                      obj.alertTicketId ? (
                                      <WithLoading
                                        type="page"
                                        size="sm"
                                        className="pt-3"
                                        isPageDataFetched={
                                          this.state.isHistoryDataFetched
                                        }
                                      ></WithLoading>
                                    ) : obj.alertHistory &&
                                      obj.alertHistory.length > 0 ? (
                                      obj.alertHistory.map((h, i) => {
                                        var changeType = "";
                                        if (h.changeType === "P") {
                                          changeType = "Priority";
                                        }
                                        if (h.changeType === "S") {
                                          changeType = "Status";
                                        }
                                        if (h.changeType === "C") {
                                          changeType = "Closed";
                                        }
                                        let modifyTimestamp = "";
                                        if (h.modifyTimestamp) {
                                          modifyTimestamp =
                                            h.modifyTimestamp.split("-");
                                          modifyTimestamp =
                                            modifyTimestamp[0] +
                                            "-" +
                                            modifyTimestamp[1] +
                                            "-" +
                                            modifyTimestamp[2];
                                        }
                                        return (
                                          <tr key={"history" + i}>
                                            <td className="sram-text-font sram-table-td">
                                              {AppUtils.getDateTime(
                                                modifyTimestamp
                                              )}{" "}
                                              {h.appointmentId
                                                ? `(GP Server time)`
                                                : h.modifyTimestampTimezone}
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {h.appointmentId
                                                ? "Service Branch"
                                                : h.firstName +
                                                  " " +
                                                  h.lastName}{" "}
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {h.appointmentId
                                                ? "Status: " +
                                                  h.changeCategory +
                                                  "\n" +
                                                  "Appointment Id: " +
                                                  h.appointmentId
                                                : changeType !== "Closed" &&
                                                  changeType !== "Priority"
                                                ? changeType +
                                                  " changed to " +
                                                  h.changeValue +
                                                  " - " +
                                                  h.changeCategory
                                                : changeType !== "Priority"
                                                ? changeType +
                                                  " - " +
                                                  h.changeCategory
                                                : changeType +
                                                  " changed to " +
                                                  h.changeValue}
                                            </td>
                                            <td className="sram-text-font sram-table-td">
                                              {h.changeNotes}
                                            </td>
                                          </tr>
                                        );
                                      })
                                    ) : (
                                      <tr>
                                        <td className="sram-no-data">
                                          {
                                            AlertMsgResProps.body.content
                                              .noDataFound
                                          }
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </Col>
                            </Collapse>
                          </CardBody>
                        </Card>
                      );
                    })
                  ) : (
                    <div className="sram-no-data">
                      {AlertMsgResProps.body.content.noDataFound}
                    </div>
                  )}
                </WithLoading>
              </div>
            </PerfectScrollbar>
          </Col>
        </Row>
      </>
    );
  }
}

export default ClosedTickets;
