import React, { Component } from "react";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Input,
  Progress,
  Row,
  Col,
  Spinner,
  List,
  Alert,
  Table
} from "reactstrap";
import AppUtils from "../../core/helpers/app-utils";
import WithLoading from "../../core/helpers/with-loading";
import { AppConfigProps } from "../../core/settings/app-config";
import PerfectScrollbar from "react-perfect-scrollbar";
import { manageError } from "../../core/actions/common-actions";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import PageUtils from "../../core/helpers/page-utils";
import PageDisplay from "../../core/helpers/page-display";
import { Modal, ModalBody } from "reactstrap";
import { SiteImportMsgResProps, siteImportSteps } from '../messages/site-import-properties'
import {
  softwareVersionHistory,
  convertToBase64,
  addSoftwareVersions,
  listSoftwareVersionItem,
  updateSoftwareVersionItem
} from '../actions/sites-import-actions';
import MultiStepProgressBar from "../helpers/MultiStepProgressBar";
import SiteUtils from "../../portal/helpers/site-utils";
import PageListPagination from "../../core/helpers/page-list-pagination";
import PageListLimitSelect from "../../core/helpers/page-list-limit-select";

class SitesImport extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcSiteOwnerSoftwareVersions = "AS-SOW-SSVA";
  _pcServiceProviderSoftwareVersions = "AS-SPV-SSVA";

  constructor(props) {
    super(props);
    this.state = {
      partPrivileges: this.initPartPrivileges(),
      isPageDataFetched: false,
      isVersionsHistoryDataFetched: false,
      VersionsHistory: null,
      isOpenSitesModel: false,
      sitesModelData: null,
      pageOffset: 0,
      pageLimit: 20,
      currentStep: 1,
      setProgressValue: 0,
      selectedExcelFile: null,
      uploadVersionsError: null,
      statusMessage: null,
      spinner: false,
      viewVersionsData: null,
      selectedFileName: null
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
    window.appHistory = this.props.history;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      PageUtils.scrollToTop();
      await this.loadPageData();
    }
  }

  initPartPrivileges = () => {
    return {
      [this._pcSiteOwnerSoftwareVersions]: false,
      [this._pcServiceProviderSoftwareVersions]: false,
    };
  };

  setPartPrivileges = async () => {
    const partCodes = [
      this._pcSiteOwnerSoftwareVersions,
      this._pcServiceProviderSoftwareVersions,
    ];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  loadPageData = async () => {
    await this.setPartPrivileges();
    await this.setStateAsync({ isPageDataFetched: false });
    if (this.state.partPrivileges[this._pcSiteOwnerSoftwareVersions] === true ||
      this.state.partPrivileges[this._pcServiceProviderSoftwareVersions] === true) {
      await this.getVersionsHistory();
    }
    await this.setStateAsync({ isPageDataFetched: true });
  };

  getVersionsHistory = async () => {
    await this.setStateAsync({ isVersionsHistoryDataFetched: false });
    const queryData = `sortField=uploadedTimestamp&sortOrder=-1&pageOffset=${this.state.pageOffset}&pageLimit=${this.state.pageLimit}`;
    await softwareVersionHistory(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data
        ) {
          // if (res.data.result.length > 0) {
          this.setStateAsync({ VersionsHistory: res.data })
          //   }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isVersionsHistoryDataFetched: true });
  };

  addVersions = async () => {
    await this.setStateAsync({ statusMessage: null, spinner: true, uploadVersionsError: null });
    let softVersionObj = {
      softwareVersion: this.state.selectedExcelFile
    }
    await addSoftwareVersions(softVersionObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = [
            "S",
            res.data?.result[0]?.uploadStatus,
          ];
          await this.setStateAsync({
            statusMessage: statusMsg,
            uploadedTimestamp: res.data?.result[0]?.uploadedTimestamp,
            spinner: false
          });
          PageUtils.scrollToTop();
          setTimeout(async () => {
            await this.checkNextStep();
          }, 2000);
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          err.data?.errors?.message,
        ];
        await this.setStateAsync({
          statusMessage: statusMsg,
          uploadVersionsError: err.data?.errors?.result,
          spinner: false
        });
        PageUtils.scrollToTop();
        await this.getVersionsHistory();
      });
    // setTimeout(async () => {
    //   await this.setStateAsync({ statusMessage: null });
    // }, 2000);
  }

  viewVersionItems = async () => {
    await this.setStateAsync({ statusMessage: null });
    const queryData = `fileName=${this.state.selectedFileName}&uploadedTimestamp=${this.state.uploadedTimestamp}`;
    await listSoftwareVersionItem(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          if (res.data.result.length > 0) {
            this.setStateAsync({ viewVersionsData: res.data.result[0]?.gvrIdsFromCsvFile })
          } else {
            this.setStateAsync({ viewVersionsData: [] })
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  }

  updateVersionItems = async () => {
    await this.setStateAsync({ statusMessage: null, spinner: true });
    const queryData = `fileName=${this.state.viewVersionsData?.length > 0 ? this.state.selectedFileName : ""}&uploadedTimestamp=${this.state.uploadedTimestamp}`;
    await updateSoftwareVersionItem(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data
        ) {
          const statusMsg = [
            "S",
            res.data?.message,
          ];
          await this.setStateAsync({ statusMessage: statusMsg, spinner: false });
          PageUtils.scrollToTop();
          setTimeout(async () => {
            await this.checkNextStep();
          }, 2000);
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    setTimeout(async () => {
      await this.setStateAsync({ statusMessage: null });
    }, 2000);
  }

  openSitesModel = async (data) => {
    await this.setStateAsync({ isOpenSitesModel: true });
    await this.setStateAsync({ sitesModelData: data });
  };
  closeSitesModel = async () => {
    await this.setStateAsync({ isOpenSitesModel: false });
    await this.setStateAsync({ sitesModelData: null });
  };

  checkNextStep = async () => {
    let currentStep = this.state.currentStep + 1;
    this.setState({ currentStep: currentStep });
    await this.progressValue();
    if (this.state.currentStep > 1) {
      await this.viewVersionItems();
    }
    if (this.state.currentStep > 7) {
      await this.goToFirstStep();
      await this.getVersionsHistory();
    }
  }

  fileUpload = async (event) => {
    await convertToBase64(event.target.files[0])
      .then(async (res) => {
        await this.setStateAsync({ selectedExcelFile: res, uploadVersionsError: null })
      })
      .catch(async (err) => {
        await this.setStateAsync({ selectedExcelFile: err, uploadVersionsError: null })
      })
  }

  progressValue = async () => {
    if (this.state.currentStep === 1) {
      this.setStateAsync({ setProgressValue: 0 });
    } else if (this.state.currentStep === 2) {
      this.setStateAsync({ setProgressValue: 16.65, selectedFileName: 'ssom' });
    } else if (this.state.currentStep === 3) {
      this.setStateAsync({ setProgressValue: 33.25, selectedFileName: 'encore-pump' });
    } else if (this.state.currentStep === 4) {
      this.setStateAsync({ setProgressValue: 50, selectedFileName: 'upm5' });
    } else if (this.state.currentStep === 5) {
      this.setStateAsync({ setProgressValue: 66.55, selectedFileName: 'upm7' });
    } else if (this.state.currentStep === 6) {
      this.setStateAsync({ setProgressValue: 83.2, selectedFileName: 'gsom' });
    } else if (this.state.currentStep === 7) {
      this.setStateAsync({ setProgressValue: 100, selectedFileName: 'omnia' });
    } else {
      this.setStateAsync({ setProgressValue: 0 });
    }
  }

  goToFirstStep = async () => {
    await this.setStateAsync({
      currentStep: 1,
      setProgressValue: 0,
      selectedExcelFile: null,
      uploadVersionsError: null,
      statusMessage: null,
      viewVersionsData: null
    });
  }

  handlePageLimitChange = async (pageLimit) => {
    if (pageLimit > 0) {
      let pageOffset = 0;
      await this.setStateAsync({ pageLimit: pageLimit });
      await this.setStateAsync({ pageOffset: pageOffset });
      await this.getVersionsHistory();
    }
  };
  handlePageOffsetChange = async (pageOffset) => {
    if (pageOffset >= 0) {
      await this.setStateAsync({ pageOffset: pageOffset });
      await this.getVersionsHistory();
    }
  };

  UploadSoftwareVersions() {
    return (
      <>
        <Label className="d-flex font-medium">{SiteImportMsgResProps.body.form.uploadFile.label}</Label>
        <FormGroup>
          <Input
            type="file"
            accept=".csv, .xlsx"
            name="softwareVersions"
            id="softwareVersions"
            onChange={(event) => this.fileUpload(event)}
          />
        </FormGroup>
        {this.state.uploadVersionsError !== null && this.state.uploadVersionsError?.length > 0 ? (
          <>
            <Alert color="primary">
              {this.state.statusMessage && this.state.statusMessage[1] === "The Dispensers for the following Sites are either Missing or Deactivated." ?
                "Following sites Dispensers are either Missing or Deactivated"
                : SiteImportMsgResProps.body.notification.error.uploadError}
            </Alert>
            <Card style={{ width: '15rem' }}>
              <CardTitle>
                {SiteImportMsgResProps.body.content.sites}
              </CardTitle>
              <CardBody>
                {this.state.statusMessage && this.state.statusMessage[1] === "The Dispensers for the following Sites are either Missing or Deactivated." ?
                  <List type="unstyled">
                    {this.state.uploadVersionsError?.length > 0 &&
                      this.state.uploadVersionsError[0]?.sitesWithNoDispensers?.map((obj, index) => {
                        return (
                          <li className="ml-4" key={index}>
                            <span>{obj}</span>
                          </li>
                        )
                      })}
                  </List>
                  :
                  <List type="unstyled">
                    {this.state.uploadVersionsError?.length > 0 &&
                      this.state.uploadVersionsError[0]?.sitesNeedToAddInUI?.map((obj, index) => {
                        return (
                          <li className="ml-4" key={index}>
                            <span>{obj}</span>
                          </li>
                        )
                      })}
                  </List>
                }
              </CardBody>
            </Card>
          </>
        ) : null}
      </>
    )
  }

  viewVersionsList() {
    const alertData = siteImportSteps.filter((element) => {
      if (element.key === this.state.selectedFileName) {
        return element.value
      }
    })
    return (
      <>
        {this.state.viewVersionsData && this.state.viewVersionsData?.length > 0 ? (
          <>
            <Alert color="primary">
              {alertData && SiteImportMsgResProps.body.notification.confirmation.versionDetailTitle(alertData[0]?.value)}
            </Alert>
            <Card style={{ width: '15rem' }}>
              <CardTitle>
                {SiteImportMsgResProps.body.content.sites}
              </CardTitle>
              <CardBody>
                <List type="unstyled">
                  {this.state.viewVersionsData?.map((obj, index) => {
                    return (
                      <li className="ml-4" key={index}>
                        <span>{obj}</span>
                      </li>
                    )
                  })}
                </List>
              </CardBody>
            </Card>
          </>
        ) : (
          <Alert color="primary">
            {alertData && SiteImportMsgResProps.body.notification.confirmation.versionNoTitle(alertData[0]?.value)}
          </Alert>
        )}
      </>
    )
  }

  showSitesCount(obj, sitesInfo) {
    if (sitesInfo && sitesInfo?.length <= 0) {
      return <div className="tooltip ml-1">
      {" "}<span>{sitesInfo?.length}</span>
      </div>;
    } else {
      return (
        <div className="tooltip ml-1">
          {" "}
          <span
            className="sram-color-blue"
            onClick={() => this.openSitesModel(obj.sitesInfo)}
          >
            {sitesInfo?.length}
          </span>
          <span className="tooltiptext-1">View Sites details</span>
        </div>
      );
    }
  }

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <Card className="sram-page-list">
              <CardTitle>
                <span>{SiteImportMsgResProps.head.title.softwareVersions}</span>
              </CardTitle>
              <CardBody className="mr-4">
                <div>
                  <div className="sram-list-main ml-4" >
                    <div>
                      {this.state.statusMessage ? (
                        <Row>
                          <Col xs={12}>
                            {this.state.statusMessage[0] === "E"
                              ? PageDisplay.showErrorNotification(
                                this.state.statusMessage[1]
                              )
                              : PageDisplay.showSuccessNotification(
                                this.state.statusMessage[1]
                              )}
                          </Col>
                        </Row>
                      ) : null}
                    </div>
                    <div></div>

                    <div className="mr-4 mt-4 mb-4">
                      <MultiStepProgressBar currentStep={this.state.currentStep} />
                    </div>
                    <div className="pt-4 mt-4 ">
                      <Progress
                        animated
                        className="sram-sites-import-progressLength"
                        value={this.state.setProgressValue}
                      />
                    </div>
                  </div>
                  <div className="sram-list-main">
                    {this.state.currentStep === 1 ?
                      this.UploadSoftwareVersions()
                      : this.viewVersionsList()
                    }
                  </div>
                </div>
                <div className="mb-4 pb-4">
                  <Button
                    disabled={this.state.selectedExcelFile === null}
                    color="primary float-right"
                    className="mb-4"
                    onClick={() =>
                      this.state.currentStep === 1 ?
                        this.addVersions()
                        :
                        this.updateVersionItems()
                    }>
                    {this.state.currentStep === 1 ? 'Upload & Continue' :
                      this.state.currentStep < 7 ?
                        this.state.viewVersionsData?.length > 0 ?
                          "Update & Continue" : "Continue"
                        :
                        this.state.viewVersionsData?.length > 0 ?
                          "Update & Finish" : "Continue & Finish"}
                    {this.state.spinner === true ? (
                      <Spinner
                        size="sm"
                        color="light"
                        className="ml-2"
                      />
                    ) : null}
                  </Button>
                </div>
                <div className="ml-4 mt-4">
                  {/* <Row>
                    <Col > */}
                  <span className="sram-dispenser-section-sub-heading">
                    {SiteImportMsgResProps.head.title.importHistory}
                  </span>
                  <Card body className="">
                    <WithLoading
                      isPageDataFetched={this.state.isVersionsHistoryDataFetched}
                      type="page"
                      size="sm"
                    >
                      <PerfectScrollbar className="sram-dispenser-card-body-height">
                        {/* {this.state.VersionsHistory &&
                          this.state.VersionsHistory.result?.length > 0 ? ( */}
                        <Table>
                          <thead>
                            <tr>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {SiteImportMsgResProps.body.content.uploadTimestamp}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {SiteImportMsgResProps.body.content.sitesCount}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {SiteImportMsgResProps.body.content.userName}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {SiteImportMsgResProps.body.content.comment}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {SiteImportMsgResProps.body.content.status}
                              </th>
                              {/* <th className="sram-text-font sram-font-weight-600 sram-table-th"></th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.VersionsHistory &&
                              this.state.VersionsHistory?.result?.map((obj, index) => {
                                let data = []
                                obj.sitesInfo.map((site) => {
                                  return data.push(site.siteNumber)
                                })
                                return (
                                  <tr key={index}>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.uploadedTimestamp
                                        ? AppUtils.getDateTime(obj.uploadedTimestamp)
                                        : "-"}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {/* {data && data?.length > 0 ? (
                                        <p>
                                          {data?.toString()?.length > 10 ? (
                                            <span>
                                              {data?.toString()?.substring(0, 30)}...
                                            </span>
                                          ) : (
                                            data
                                          )}
                                        </p>
                                      ) : (
                                        "-"
                                      )} */}
                                      {this.showSitesCount(obj, obj.sitesInfo)}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.userName ? obj.userName : "-"}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.comment ? obj.comment : "-"}
                                    </td>
                                    <td className="sram-text-font sram-table-td">
                                      {obj.status ? obj.status : "-"}
                                    </td>
                                    {/* <td>
                                      <span
                                        onClick={() =>
                                          this.openSitesModel(obj.sitesInfo)
                                        }
                                        title="View More"
                                        className="sram-page-action-icon ml-3"
                                      >
                                        <i
                                          className={`d-inline far fa-dot-circle sram-icon-view sram-icon-small}`}
                                        ></i>
                                      </span>
                                    </td> */}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                        {/* ) : (
                          <div className="pt-3 py-3 ml-3 sram-no-data">
                            {
                              SiteImportMsgResProps.body.notification
                                .noDataFound
                            }
                          </div>
                        )} */}
                      </PerfectScrollbar>
                    </WithLoading>
                    {this.state.VersionsHistory &&
                      this.state.VersionsHistory?.result?.length ? (
                      <div className="">
                        <div className="float-left">
                          <PageListLimitSelect
                            pageLimit={this.state.pageLimit}
                            pageOffset={this.state.pageOffset}
                            totalRecords={
                              this.state.VersionsHistory?.info?.totalRecords
                            }
                            handlePageLimitChange={
                              this.handlePageLimitChange
                            }
                          />
                        </div>
                        <div className="float-right">
                          <PageListPagination
                            pageLimit={this.state.pageLimit}
                            pageOffset={this.state.pageOffset}
                            totalRecords={
                              this.state.VersionsHistory?.info?.totalRecords
                            }
                            handlePageOffsetChange={
                              this.handlePageOffsetChange
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </Card>
                  {/* </Col>
                  </Row> */}
                </div>
              </CardBody>
            </Card>
          </div>
          {this.state.isOpenSitesModel === true ? (
            <Modal
              isOpen={this.state.isOpenSitesModel}
              toggle={this.closeSitesModel}
              size="xl"
              backdrop="static"
              centered={false}
              className="sram-modal-content"
            >
              <div className="modal-header">
                <span>
                  <h5 className="modal-title">
                    {SiteImportMsgResProps.body.content.site}
                  </h5>
                </span>
                <span
                  className="pull-right sram-times"
                  onClick={() => {
                    this.closeSitesModel();
                  }}
                >
                  <i className="fa fa-times-circle"></i>
                </span>
              </div>
              <ModalBody className="mb-2">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="sram-text-font sram-font-weight-600 sram-table-th" width="30">
                        {SiteImportMsgResProps.body.content.siteId}
                      </th>
                      <th className="sram-text-font sram-font-weight-600 sram-table-th" width="40">
                        {SiteImportMsgResProps.body.content.siteName}
                      </th>
                      <th className="sram-text-font sram-font-weight-600 sram-table-th" width="30">
                        {SiteImportMsgResProps.body.content.address}
                      </th>
                      <th className="sram-text-font sram-font-weight-600 sram-table-th" width="30">
                        {SiteImportMsgResProps.body.content.dispenserId}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sitesModelData &&
                      this.state.sitesModelData.map((obj, index) => {
                        const siteAddress = SiteUtils.getSiteAddress(obj);
                        const siteAddress1 = SiteUtils.getSiteAddress1(obj);
                        return (
                          <tr key={index}>
                            <td className="sram-text-font sram-table-td">
                              {obj.siteNumber
                                ? obj.siteNumber
                                : "-"}
                            </td>
                            <td className="sram-text-font sram-table-td">
                              {obj.siteName
                                ? obj.siteName
                                : "-"}
                            </td>
                            <td className="sram-text-font sram-table-td">
                              {siteAddress},{siteAddress1}
                            </td>
                            <td className="sram-text-font sram-table-td">
                              {obj.dispensers
                                ? obj.dispensers
                                : "-"}
                            </td>
                            {/* <td className="sram-text-font sram-table-td">
                            {this.state.reportData &&
                              this.state.reportData.sites ? (
                              <p className="sram-text-font">
                                {this.state.reportData.sites.length > 50 ? (
                                  <span>
                                    {this.state.collapse === false ? (
                                      <>
                                        <span>
                                          {this.state.reportData.sites.substring(
                                            0,
                                            50
                                          )}
                                          ...
                                        </span>
                                        <span
                                          onClick={this.toggle}
                                          className="sram-links"
                                        >
                                          Read More
                                        </span>
                                      </>
                                    ) : null}
                                    <Collapse isOpen={this.state.collapse}>
                                      <div>{this.state.reportData.sites}</div>
                                      <div>
                                        <span
                                          onClick={this.toggle}
                                          className="sram-links"
                                        >
                                          Read Less
                                        </span>
                                      </div>
                                    </Collapse>
                                  </span>
                                ) : (
                                  this.state.reportData.sites
                                )}
                              </p>
                            ) : (
                              "-"
                            )}
                          </td> */}
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          ) : null}
        </WithLoading>
      </>
    );
  }
}
export default SitesImport;
