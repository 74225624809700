import React, { Component } from "react";
import axios from "axios";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";
import WithLoading from "../../core/helpers/with-loading";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import PageUtils from "../../core/helpers/page-utils";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import PageDisplay from "../../core/helpers/page-display";
import { deviceItem } from "../actions/device-actions";
import { DeviceMsgResProps } from "../messages/device-properties";
import { ConfigURLProps } from "../settings/config-urls";
import AppUtils from "../../core/helpers/app-utils";
// import Label from "reactstrap/lib/Label";

class DeviceView extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcViewDevice = "AT-DVC-DVIW";
  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      partPrivileges: this.initPartPrivileges(),
      deviceItem: null,
      collapse: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    const dispenserId = this.props.match.params.did;
    await this.loadPageData(dispenserId);
    window.appHistory = this.props.history;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const dispenserId = this.props.match.params.did;
    if (dispenserId !== prevProps.match.params.did) {
      PageUtils.scrollToTop();
      await this.loadPageData(dispenserId);
    }
  }

  loadPageData = async (dispenserId) => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ deviceItem: null });
    await this.checkPartPrivilege();
    await this.setPartPrivileges();
    await deviceItem(dispenserId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          if (res.data.result) {
            await this.setStateAsync({ deviceItem: res.data.result });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  initPartPrivileges = () => {
    return {
      [this._pcViewDevice]: false,
    };
  };

  checkPartPrivilege = async () => {
    const partCode = [this._pcViewDevice];
    const partPrivilege = await getPagePartsPrivilege(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(ConfigURLProps.pageNotFound);
    }
  };

  setPartPrivileges = async () => {
    const partCodes = [this._pcViewDevice];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };
  showCollapse() {
    this.setStateAsync({ collapse: !this.state.collapse });
  }

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            {this.state.deviceItem ? (
              <Card className="sram-page-view">
                <CardTitle>
                  <span>{DeviceMsgResProps.body.content.deviceView}</span>
                  <span className="float-right">
                    {PageDisplay.showGoBackLink(
                      "L",
                      ConfigURLProps.devices,
                      this.props.history
                    )}
                  </span>
                </CardTitle>
                <CardBody>
                  <div className="sram-view-main">
                    <div className="sram-view-data">
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            DeviceMsgResProps.body.form.gvrId.label,
                            this.state.deviceItem.siteNumber
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            DeviceMsgResProps.body.form.siteName.label,
                            this.state.deviceItem.siteName
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            DeviceMsgResProps.body.form.dispenser.label,
                            "Dispenser " + this.state.deviceItem.dispenserName
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            DeviceMsgResProps.body.form.deviceId.label,
                            this.state.deviceItem.macAddress
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            DeviceMsgResProps.body.form.fuelingSideA.label,
                            this.state.deviceItem.fuelingSideA
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            DeviceMsgResProps.body.form.fuelingSideB.label,
                            this.state.deviceItem.fuelingSideB
                          )}
                        </Col>
                      </Row>
                      <Row form>
                      <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            DeviceMsgResProps.body.form.warranty.label,
                            AppUtils.getDate(this.state.deviceItem.warranty)
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            DeviceMsgResProps.body.form.dispenserFlowType.label,
                            this.state.deviceItem.dispenserFlowType == 1 ?(
                              "High Flow Dispenser"
                            ):"Low Flow Dispenser"
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            DeviceMsgResProps.body.form.twoWireSideA.label,
                            this.state.deviceItem.twoWireSideA
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            DeviceMsgResProps.body.form.twoWireSideB.label,
                            this.state.deviceItem.twoWireSideB
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {this.state.deviceItem.manufacturerName
                            ? PageDisplay.getFormFieldLabelValue(
                              DeviceMsgResProps.body.form.manufacturerName
                                .label,
                              this.state.deviceItem.manufacturerName
                            )
                            : PageDisplay.getFormFieldLabelValue(
                              DeviceMsgResProps.body.form.manufacturerName
                                .label,
                              DeviceMsgResProps.body.form.manufacturerName
                                .optionNone
                            )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {this.state.deviceItem.modelName
                            ? PageDisplay.getFormFieldLabelValue(
                              DeviceMsgResProps.body.form.modelName.label,
                              this.state.deviceItem.modelName
                            )
                            : PageDisplay.getFormFieldLabelValue(
                              DeviceMsgResProps.body.form.modelName.label,
                              DeviceMsgResProps.body.form.modelName.optionNone
                            )}
                        </Col>
                      </Row>
                      <Row form>
                        {this.state.deviceItem.meters &&
                          this.state.deviceItem.meters.length > 0
                          ? this.state.deviceItem.meters.map((obj, index) => {
                            return (
                              <Col sm={12} md={6} lg={6} key={index}>
                                {PageDisplay.getFormFieldLabelValue(
                                  DeviceMsgResProps.body.form.meter.label +
                                  " " +
                                  obj.meterNumber,
                                  obj.meterName
                                )}
                              </Col>
                            );
                          })
                          : null}
                      </Row>
                      <Row form>
                        {this.state.deviceItem.grades &&
                          this.state.deviceItem.grades.length > 0
                          ? this.state.deviceItem.grades.map((obj, index) => {
                            return (
                              <Col sm={12} md={6} lg={6} key={index}>
                                {PageDisplay.getFormFieldLabelValue(
                                  DeviceMsgResProps.body.form.grade.label +
                                  " " +
                                  obj.gradeNumber,
                                  obj.gradeName
                                )}
                              </Col>
                            );
                          })
                          : null}
                      </Row>
                      {this.state.deviceItem &&
                        this.state.deviceItem.dispenserImageUrl !== null &&
                        this.state.deviceItem.dispenserImageUrl !== "" ? (
                        <Row form>
                          <Col
                            sm={12}
                            md={12}
                            lg={12}
                            className="sram-chev sidebar-nav"
                          >
                            <span
                              id={"toggler"}
                              aria-expanded={this.state.collapse}
                              onClick={() => this.showCollapse()}
                              data-toggle="collapse"
                              className="font-mild sram-mrb-8 sram-links sidebar-link has-arrow sram-has-arrow"
                            >
                              Dispenser PPU Image
                              {this.state.collapse === false ? (
                                <span className="fa fa-chevron-right ml-2"></span>
                              ) : (
                                <span className="fa fa-chevron-down ml-2"></span>
                              )}
                            </span>
                            <UncontrolledCollapse
                              toggler={"toggler"}
                              className="pt-2"
                            >
                              <img
                                className="sram-dispenser-ppu"
                                src={this.state.deviceItem.dispenserImageUrl}
                                alt="dispenser-ppu"
                              />
                            </UncontrolledCollapse>
                          </Col>
                        </Row>
                      ) : null}
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  DeviceMsgResProps.body.content.noDataFound
                )}
              </>
            )}
          </div>
        </WithLoading>
      </>
    );
  }
}
export default DeviceView;
