import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  Collapse,
  CardBody,
  Row,
  Col,
  Table,
  Badge,
} from "reactstrap";
import classnames from "classnames";
import { DispenserMsgResProps } from "../messages/dispenser-properties";
import PerfectScrollbar from "react-perfect-scrollbar";
import WithLoading from "../../core/helpers/with-loading";
import AppUtils from "../../core/helpers/app-utils";

class DispenserAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      activeTab: "1",
      setActiveTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, setActiveTab: tab });
    }
  }
  handleCollapse() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  getAlertPriority(str) {
    if (str) {
      if (str === "Green") {
        return (
          <Badge className="sram-badge-success" color="success">
            {str}
          </Badge>
        );
        // <div className="sram-badge-success">{str}</div>
      }
      if (str === "Red") {
        return (
          <Badge className="sram-badge-danger" color="danger">
            {str}
          </Badge>
        );
        // <div className="sram-badge-danger">{str}</div>
      }
      if (str === "Yellow") {
        return (
          <Badge className="sram-badge-warning" color="warning">
            {str}
          </Badge>
        );
        // <div className="sram-yellow">{str}</div>
      }
    }
  }

  render() {
    return (
      <Card className="sram-page-form sram-dispenser-card-bottom">
        <CardTitle
          onClick={this.handleCollapse.bind(this)}
          className="sram-title-cursor"
        >
          <span>{DispenserMsgResProps.body.content.alerts}</span>
        </CardTitle>
        <Collapse isOpen={this.state.isOpen}>
          <CardBody className="sram-dispenser-section-1-errorlogs sram-dispenser-card-bdy">
            <div>
              <Nav tabs>
                <NavItem className="sram-nav-active sram-title-cursor">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1",
                    })}
                    onClick={this.toggle.bind(this, "1")}
                  >
                    <span>{DispenserMsgResProps.body.content.new_open}</span>
                  </NavLink>
                </NavItem>
                <NavItem className="sram-nav-active sram-title-cursor">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2",
                    })}
                    onClick={this.toggle.bind(this, "2")}
                  >
                    <span>{DispenserMsgResProps.body.content.wip}</span>
                  </NavLink>
                </NavItem>
                <NavItem className="sram-nav-active sram-title-cursor">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "3",
                    })}
                    onClick={this.toggle.bind(this, "3")}
                  >
                    <span>{DispenserMsgResProps.body.content.dispatched}</span>
                  </NavLink>
                </NavItem>
                <NavItem className="sram-nav-active sram-title-cursor">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "4",
                    })}
                    onClick={this.toggle.bind(this, "4")}
                  >
                    <span>{DispenserMsgResProps.body.closed.label}</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <WithLoading
                    isPageDataFetched={this.props.isAlertEventDataFetched}
                    type="page"
                    size="sm"
                  >
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <PerfectScrollbar className="sram-dispenser-card-body-height">
                            <Table>
                              <thead>
                                <tr>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    Component Name
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.side.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.errorCode.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    Event Cause
                                  </th>
                                  {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      Item Name
                                    </th> */}
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventTimeStamp
                                        .label
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.alertEventData &&
                                this.props.alertEventData.length > 0 ? (
                                  this.props.alertEventData.map(
                                    (obj, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.componentName
                                              ? obj.componentName
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.sideLabel
                                              ? obj.sideLabel
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.errorCode
                                              ? obj.errorCode
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.eventStatus
                                              ? obj.eventStatus
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.eventCause
                                              ? obj.eventCause
                                              : "-"}
                                          </td>
                                          {/* <td className="sram-text-font sram-table-td">
                                          {obj.itemName ? obj.itemName : "-"}
                                        </td> */}
                                          <td
                                            className="sram-text-font sram-table-td"
                                            title={
                                              obj.componentName ===
                                              "pumpcontrolnode"
                                                ? `Dispenser Create Timestamp: ${
                                                    obj.dispenserCreateTimestamp
                                                      ? AppUtils.getDateTime(
                                                          obj.dispenserCreateTimestamp
                                                        )
                                                      : "-"
                                                  }`
                                                : null
                                            }
                                          >
                                            {obj.eventTimestamp
                                              ? AppUtils.getDateTime(
                                                  obj.eventTimestamp
                                                )
                                              : "-"}{" "}
                                            {/* {obj.createTimestampTimezone} */}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="5"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </PerfectScrollbar>
                        </Card>
                      </Col>
                    </Row>
                  </WithLoading>
                </TabPane>

                <TabPane tabId="2">
                  <WithLoading
                    isPageDataFetched={this.props.isAllAlertsDataFetched}
                    type="page"
                    size="sm"
                  >
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <PerfectScrollbar className="sram-dispenser-card-body-height">
                            <Table>
                              <thead>
                                <tr>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.ticketId.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    Component Name
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.side.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.ticketStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.ticketPriority
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.createTimestamp
                                        .label
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.openTickets &&
                                this.props.openTickets.length > 0 ? (
                                  this.props.openTickets.map((obj, index) => {
                                    let createTimestamp = "";
                                    if (obj.createTimestamp) {
                                      createTimestamp =
                                        obj.createTimestamp.split("-");
                                      createTimestamp =
                                        createTimestamp[0] +
                                        "-" +
                                        createTimestamp[1] +
                                        "-" +
                                        createTimestamp[2];
                                    }
                                    return (
                                      <tr key={index}>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.ticketNumber
                                            ? obj.ticketNumber
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.componentName
                                            ? obj.componentName
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.sideLabel ? obj.sideLabel : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.ticketStatus
                                            ? obj.ticketStatus
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.ticketPriority &&
                                          obj.ticketPriority !== null ? (
                                            obj.ticketPriority
                                          ) : (
                                            <span> - </span>
                                          )}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventStatus
                                            ? obj.eventStatus
                                            : "-"}
                                        </td>
                                        <td
                                          className="sram-text-font sram-table-td"
                                          title={
                                            obj.componentName ===
                                            "pumpcontrolnode"
                                              ? `Dispenser Create Timestamp: ${
                                                  obj.dispenserCreateTimestamp
                                                    ? AppUtils.getDateTime(
                                                        obj.dispenserCreateTimestamp
                                                      )
                                                    : "-"
                                                }`
                                              : null
                                          }
                                        >
                                          {AppUtils.getDateTime(
                                            createTimestamp
                                          )}{" "}
                                          {obj.createTimestampTimezone}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="7"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </PerfectScrollbar>
                        </Card>
                      </Col>
                    </Row>
                  </WithLoading>
                </TabPane>

                <TabPane tabId="3">
                  <WithLoading
                    isPageDataFetched={this.props.isAllAlertsDataFetched}
                    type="page"
                    size="sm"
                  >
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <PerfectScrollbar className="sram-dispenser-card-body-height">
                            <Table>
                              <thead>
                                <tr>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.ticketId.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    Component Name
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.side.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.ticketStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.ticketPriority
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.createTimestamp
                                        .label
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.dispatchTickets &&
                                this.props.dispatchTickets.length > 0 ? (
                                  this.props.dispatchTickets.map(
                                    (obj, index) => {
                                      let createTimestamp = "";
                                      if (obj.createTimestamp) {
                                        createTimestamp =
                                          obj.createTimestamp.split("-");
                                        createTimestamp =
                                          createTimestamp[0] +
                                          "-" +
                                          createTimestamp[1] +
                                          "-" +
                                          createTimestamp[2];
                                      }
                                      return (
                                        <tr key={index}>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.ticketNumber
                                              ? obj.ticketNumber
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.componentName
                                              ? obj.componentName
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.sideLabel
                                              ? obj.sideLabel
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.ticketStatus
                                              ? obj.ticketStatus
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.ticketPriority &&
                                            obj.ticketPriority !== null ? (
                                              obj.ticketPriority
                                            ) : (
                                              <span> - </span>
                                            )}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.eventStatus
                                              ? obj.eventStatus
                                              : "-"}
                                          </td>
                                          <td
                                            className="sram-text-font sram-table-td"
                                            title={
                                              obj.componentName ===
                                              "pumpcontrolnode"
                                                ? `Dispenser Create Timestamp: ${
                                                    obj.dispenserCreateTimestamp
                                                      ? AppUtils.getDateTime(
                                                          obj.dispenserCreateTimestamp
                                                        )
                                                      : "-"
                                                  }`
                                                : null
                                            }
                                          >
                                            {AppUtils.getDateTime(
                                              createTimestamp
                                            )}{" "}
                                            {obj.createTimestampTimezone}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="7"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </PerfectScrollbar>
                        </Card>
                      </Col>
                    </Row>
                  </WithLoading>
                </TabPane>

                <TabPane tabId="4">
                  <WithLoading
                    isPageDataFetched={this.props.isAllAlertsDataFetched}
                    type="page"
                    size="sm"
                  >
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <PerfectScrollbar className="sram-dispenser-card-body-height">
                            <Table>
                              <thead>
                                <tr>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.ticketId.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    Component Name
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.side.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.ticketStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.ticketPriority
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.createTimestamp
                                        .label
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.closedTickets &&
                                this.props.closedTickets.length > 0 ? (
                                  this.props.closedTickets.map((obj, index) => {
                                    let createTimestamp = "";
                                    if (obj.createTimestamp) {
                                      createTimestamp =
                                        obj.createTimestamp.split("-");
                                      createTimestamp =
                                        createTimestamp[0] +
                                        "-" +
                                        createTimestamp[1] +
                                        "-" +
                                        createTimestamp[2];
                                    }
                                    return (
                                      <tr key={index}>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.ticketNumber
                                            ? obj.ticketNumber
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.componentName
                                            ? obj.componentName
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.sideLabel ? obj.sideLabel : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.ticketStatus
                                            ? obj.ticketStatus
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.ticketPriority &&
                                          obj.ticketPriority !== null ? (
                                            obj.ticketPriority
                                          ) : (
                                            <span> - </span>
                                          )}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventStatus
                                            ? obj.eventStatus
                                            : "-"}
                                        </td>
                                        <td
                                          className="sram-text-font sram-table-td"
                                          title={
                                            obj.componentName ===
                                            "pumpcontrolnode"
                                              ? `Dispenser Create Timestamp: ${
                                                  obj.dispenserCreateTimestamp
                                                    ? AppUtils.getDateTime(
                                                        obj.dispenserCreateTimestamp
                                                      )
                                                    : "-"
                                                }`
                                              : null
                                          }
                                        >
                                          {AppUtils.getDateTime(
                                            createTimestamp
                                          )}{" "}
                                          {obj.createTimestampTimezone}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="7"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </PerfectScrollbar>
                        </Card>
                      </Col>
                    </Row>
                  </WithLoading>
                </TabPane>
              </TabContent>
            </div>
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

export default DispenserAlerts;
