import React, { Component } from "react";
import { Card, CardTitle, CardBody, Table, Label, Row, Col } from "reactstrap";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import { SetupURLProps } from "../settings/setup-urls";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { UserMsgResProps } from "../messages/user-properties";
import { manageError } from "../../core/actions/common-actions";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import { listUsers, searchUsers, userStatus } from "../actions/user-actions";
import PageListPagination from "../../core/helpers/page-list-pagination";
import PageListLimitSelect from "../../core/helpers/page-list-limit-select";
import PageListHeaderColumn from "../../core/helpers/page-list-header-column";
import PageListSearchField from "../../core/helpers/page-list-search-field";
import { NavLink as RRNavLink } from "react-router-dom";
import Switch from "react-switch";
import ConfirmModal from "../../core/helpers/confirm-modal";

class UserList extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcUserView = "AS-USR-UVIW";
  _pcUserAdd = "AS-USR-UADD";
  _pcUserEdit = "AS-USR-UEDT";
  _pcUserEnableDisable = "AS-USR-USED";
  _pcUserSiteAccess = "AS-USR-USTA";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      partPrivileges: this.initPartPrivileges(),
      usersAllRecords: null,
      usersDisplayRecords: null,
      usersPageRecords: null,
      searchValue: "",
      searchKeyword: "",
      pageOffset: AppConfigProps.listPage.pageOffset.defaultValue,
      pageLimit: AppConfigProps.listPage.pageLimit.defaultValue,
      sortedColumn: "username",
      sortedOrder: AppConfigProps.listPage.sortOrder.defaultValue,
      switchChecked: false,
      userInActive: 0,
      userActive: 1,
      userStatus: null,
      isOpenConfirmModal: false,
      tempUserObj: null,
      statusMessage: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      PageUtils.scrollToTop();
      await this.loadPageData();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({
      usersAllRecords: null,
      usersDisplayRecords: null,
      usersPageRecords: null,
    });
    await this.setPartPrivileges();
    if (this.state.partPrivileges[this._pcUserView] === true) {
      await this.getUsersList();
    }
    await this.setStateAsync({ isPageDataFetched: true });
  };

  getUsersList = async () => {
    const queryData = "sortField=username&sortOrder=1";
    await listUsers(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ usersAllRecords: res.data.records });
            let sortedRecords = await PageUtils.sortListRecords(
              this.state.usersAllRecords,
              this.state.sortedColumn,
              this.state.sortedOrder
            );
            await this.setStateAsync({ usersDisplayRecords: sortedRecords });
            let slicedRecords = await PageUtils.sliceListRecords(
              this.state.usersDisplayRecords,
              this.state.pageOffset,
              this.state.pageLimit
            );
            await this.setStateAsync({ usersPageRecords: slicedRecords });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  initPartPrivileges = () => {
    return {
      [this._pcUserView]: false,
      [this._pcUserAdd]: false,
      [this._pcUserEdit]: false,
      [this._pcUserEnableDisable]: false,
      [this._pcUserSiteAccess]: false,
    };
  };

  setPartPrivileges = async () => {
    const partCodes = [
      this._pcUserView,
      this._pcUserAdd,
      this._pcUserEdit,
      this._pcUserEnableDisable,
      this._pcUserSiteAccess,
    ];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  getTableColumnHeadings = () => {
    return [
      {
        columnId: "username",
        columnType: "string",
        columnLabel: UserMsgResProps.body.form.username.label,
        isSortable: true,
        columnWidth: "15%",
      },
      {
        columnId: "email",
        columnType: "string",
        columnLabel: UserMsgResProps.body.form.email.label,
        isSortable: true,
        columnWidth: "30%",
      },
      {
        columnId: "firstName",
        columnType: "string",
        columnLabel: UserMsgResProps.body.form.firstName.label,
        isSortable: true,
        columnWidth: "20%",
      },
      {
        columnId: "lastName",
        columnType: "string",
        columnLabel: UserMsgResProps.body.form.lastName.label,
        isSortable: true,
        columnWidth: "20%",
      },
    ];
  };

  handleSortChange = async (sortColumn, sortOrder, sortType) => {
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.usersDisplayRecords,
      sortColumn,
      sortOrder,
      sortType
    );
    await this.setStateAsync({ usersDisplayRecords: sortedRecords });
    await this.setStateAsync({
      sortedColumn: sortColumn,
      sortedOrder: sortOrder,
    });

    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.usersDisplayRecords,
      pageOffset,
      this.state.pageLimit
    );
    await this.setStateAsync({ usersPageRecords: slicedRecords });
    await this.setStateAsync({ pageOffset: pageOffset });
  };

  handlePageLimitChange = async (pageLimit) => {
    if (pageLimit > 0) {
      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.usersDisplayRecords,
        pageOffset,
        pageLimit
      );
      await this.setStateAsync({ usersPageRecords: slicedRecords });
      await this.setStateAsync({ pageLimit: pageLimit });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handlePageOffsetChange = async (pageOffset) => {
    if (pageOffset >= 0) {
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.usersDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );
      await this.setStateAsync({ usersPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchChange = async (e) => {
    await this.setStateAsync({ searchValue: e.target.value });
  };

  handleSearchSubmit = async (e) => {
    if (this.state.usersAllRecords && this.state.usersAllRecords.length > 0) {
      let searchKeyword = this.state.searchValue;
      await this.setStateAsync({ searchKeyword: searchKeyword });
      let searchedRecords = await searchUsers(
        this.state.usersAllRecords,
        this.state.searchKeyword
      );

      let sortedRecords = await PageUtils.sortListRecords(
        searchedRecords,
        this.state.sortedColumn,
        this.state.sortedOrder
      );
      await this.setStateAsync({ usersDisplayRecords: sortedRecords });

      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.usersDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );

      await this.setStateAsync({ usersPageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchCancel = async (e) => {
    e.preventDefault();
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.usersAllRecords,
      this.state.sortedColumn,
      this.state.sortedOrder
    );
    await this.setStateAsync({ usersDisplayRecords: sortedRecords });
    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.usersDisplayRecords,
      this.state.pageOffset,
      this.state.pageLimit
    );
    await this.setStateAsync({ usersPageRecords: slicedRecords });

    await this.setStateAsync({ searchKeyword: "", searchValue: "" });
    await this.setStateAsync({ pageOffset: pageOffset });
  };

  handleChange = async (name, checked) => {
    let username = name;
    if (checked) {
      await this.setStateAsync({ userStatus: this.state.userActive });
    } else {
      await this.setStateAsync({ userStatus: this.state.userInActive });
    }
    const userObj = {
      username: username,
      userStatus: this.state.userStatus,
    };
    await this.setStateAsync({ tempUserObj: userObj });
    this.openConfirmModal();
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  handleSubmit = async () => {
    const userObj = this.state.tempUserObj;
    await this.setStateAsync({ statusMessage: null });
    this.closeConfirmModal();
    await userStatus(userObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = ["S", res.data.message];
          await this.setStateAsync({ searchKeyword: "", searchValue: "" });
          await this.setStateAsync({ statusMessage: statusMsg });
          await this.getUsersList();
          PageUtils.scrollToTop();
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          UserMsgResProps.body.notification.error.message,
        ];
        await this.setStateAsync({ statusMessage: statusMsg });
        if (err.data && err.data.errors && err.data.errors.length > 0) {
          const errors = err.data.errors;
          errors.forEach((error) => {
            if (error.element && error.message && error.location === "body") {
              // await this.setStateAsync({statusMessage: statusMsg});
            }
          });
          PageUtils.scrollToTop();
        }
      });
  };

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <div>
              {this.state.statusMessage ? (
                <Row>
                  <Col xs={12}>
                    {this.state.statusMessage[0] === "E"
                      ? PageDisplay.showErrorNotification(
                          this.state.statusMessage[1]
                        )
                      : PageDisplay.showSuccessNotification(
                          this.state.statusMessage[1]
                        )}
                  </Col>
                </Row>
              ) : null}
            </div>
            <Card className="sram-page-list">
              <CardTitle>
                <span>{UserMsgResProps.body.content.users}</span>
                <span className="float-right">
                  {this.state.partPrivileges[this._pcUserAdd] === true
                    ? PageDisplay.showAddLink(
                        "L",
                        SetupURLProps.users.addUser,
                        UserMsgResProps.body.content.addUser
                      )
                    : null}
                </span>
              </CardTitle>
              <CardBody>
                <>
                  {this.state.usersAllRecords &&
                  this.state.usersAllRecords.length > 0 ? (
                    <div className="sram-list-head">
                      <span className="float-left col-sm-6 col-md-4 col-lg-4 pad-left-0">
                        <PageListSearchField
                          searchValue={this.state.searchValue}
                          searchKeyword={this.state.searchKeyword}
                          handleSearchChange={this.handleSearchChange}
                          handleSearchSubmit={this.handleSearchSubmit}
                          handleSearchCancel={this.handleSearchCancel}
                        />
                      </span>
                      <span className="float-right">
                        {PageDisplay.getListPageRecordsDisplayInfo(
                          this.state.usersDisplayRecords
                            ? this.state.usersDisplayRecords.length
                            : 0,
                          this.state.pageOffset,
                          this.state.pageLimit
                        )}
                      </span>
                    </div>
                  ) : null}
                  <div className="sram-list-main">
                    {this.state.usersPageRecords &&
                    this.state.usersPageRecords.length > 0 ? (
                      <Table
                        responsive
                        hover
                        size="sm"
                        className="sram-table-main"
                      >
                        <thead>
                          <tr>
                            {this.getTableColumnHeadings().map((column) => {
                              return (
                                <PageListHeaderColumn
                                  {...column}
                                  key={column.columnId}
                                  sortedColumn={this.state.sortedColumn}
                                  sortedOrder={this.state.sortedOrder}
                                  handleSortChange={this.handleSortChange}
                                />
                              );
                            })}
                            <th width="100"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.usersPageRecords.map((user, index) => {
                            let keyString = { key: `key-userlist-${index}` };
                            return (
                              <tr {...keyString}>
                                <td>
                                  {this.state.partPrivileges[
                                    this._pcUserView
                                  ] === true ? (
                                    <RRNavLink
                                      title={
                                        UserMsgResProps.body.content.viewUser
                                      }
                                      className="sram-page-action-icon"
                                      to={SetupURLProps.users.viewUser.replace(
                                        ":uid",
                                        user.userId
                                      )}
                                    >
                                      <span>{user.username}</span>
                                    </RRNavLink>
                                  ) : (
                                    user.username
                                  )}
                                </td>
                                <td>{user.email}</td>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td
                                // className="text-right"
                                >
                                  {this.state.partPrivileges[
                                    this._pcUserSiteAccess
                                  ] === true
                                    ? PageDisplay.showActionLink(
                                        "S",
                                        "A",
                                        SetupURLProps.users.siteAccess.replace(
                                          ":uid",
                                          user.userId
                                        ),
                                        UserMsgResProps.body.content.userAccess
                                      )
                                    : null}
                                  {this.state.partPrivileges[
                                    this._pcUserEdit
                                  ] === true && user.userStatus === 1
                                    ? PageDisplay.showEditLink(
                                        "S",
                                        SetupURLProps.users.editUser.replace(
                                          ":uid",
                                          user.userId
                                        ),
                                        UserMsgResProps.body.content.editUser
                                      )
                                    : null}
                                  {this.state.partPrivileges[
                                    this._pcUserEnableDisable
                                  ] === true ? (
                                    // <a href="/" className="ml-3">
                                    <Switch
                                      onChange={this.handleChange.bind(
                                        this,
                                        user.username
                                      )}
                                      checked={user.userStatus}
                                      height={18}
                                      width={40}
                                      className="sram-react-switch ml-3"
                                    />
                                  ) : // </a>
                                  null}
                                  {/* {this.state.partPrivileges[
                                    this._pcUserView
                                  ] === true
                                    ? PageDisplay.showViewLink(
                                        "S",
                                        SetupURLProps.users.viewUser.replace(
                                          ":uid",
                                          user.userId
                                        ),
                                        UserMsgResProps.body.content.viewUser
                                      )
                                    : null} */}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    ) : (
                      <>
                        <div>{UserMsgResProps.body.content.noUsersFound}</div>
                      </>
                    )}
                  </div>
                  {this.state.usersPageRecords &&
                  this.state.usersPageRecords.length ? (
                    <div className="sram-list-foot">
                      <div className="float-left">
                        <PageListLimitSelect
                          pageLimit={this.state.pageLimit}
                          pageOffset={this.state.pageOffset}
                          totalRecords={this.state.usersDisplayRecords.length}
                          handlePageLimitChange={this.handlePageLimitChange}
                        />
                      </div>
                      <div className="float-right">
                        <PageListPagination
                          pageLimit={this.state.pageLimit}
                          pageOffset={this.state.pageOffset}
                          totalRecords={this.state.usersDisplayRecords.length}
                          handlePageOffsetChange={this.handlePageOffsetChange}
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              </CardBody>
            </Card>
            {this.state.tempUserObj ? (
              <ConfirmModal
                isOpenConfirmModal={this.state.isOpenConfirmModal}
                closeConfirmModal={this.closeConfirmModal}
                handleSubmit={this.handleSubmit}
              >
                <div>
                  <div>
                    <Label>
                      {this.state.userStatus === 0
                        ? UserMsgResProps.body.notification.confirmation
                            .disableUserTitle
                        : UserMsgResProps.body.notification.confirmation
                            .enableUserTitle}
                    </Label>
                  </div>
                </div>
              </ConfirmModal>
            ) : null}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default UserList;
