import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Collapse,
  Row,
  Col,
  // TabContent,
  // TabPane,
  // Nav,
  // NavItem,
  // NavLink,
  Table,
  Badge,
} from "reactstrap";
// import PageUtils from "../../core/helpers/page-utils";
// import { AppConfigProps } from "../../core/settings/app-config";
import { DispenserMsgResProps } from "../messages/dispenser-properties";
import ChartOptions from "../helpers/chart-options";
import ChartSeries from "../helpers/chart-series";
import WithLoading from "../../core/helpers/with-loading";
import Chart from "react-apexcharts";
// import classnames from "classnames";
import PerfectScrollbar from "react-perfect-scrollbar";
import AppUtils from "../../core/helpers/app-utils";
//import { customerExperienceData } from "../actions/customer-experience-data";
import PageListHeaderColumn from "../helpers/page-list-noTransaction-column";

class DispenserCustomerExperience extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      activeTab: "1",
      setActiveTab: "1",
    };
  }

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    // console.log("prevProps:", prevProps, 'prevState:', prevState, "snapshot:", snapshot)
  };

  handleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, setActiveTab: tab });
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  getEventStatus(str) {
    if (str) {
      if (str === "OFF") {
        return (
          <Badge className="sram-badge-success" color="success">
            Off
          </Badge>
        );
        // <div className="sram-badge-success">Off</div>
      }
      if (str === "ON") {
        return (
          <Badge className="sram-badge-danger" color="danger">
            On
          </Badge>
        );
        // <div className="sram-badge-danger">On</div>
      }
    }
  }

  getDoorStatus(doorStatus) {
    if (doorStatus === "Open") {
      return <span className="sram-site-door-status-open">{doorStatus}</span>;
    } else {
      return <span className="sram-site-door-status-close">{doorStatus}</span>;
    }
  }

  getTableColumnHeadings = () => {
    return [
      {
        columnId: "gradeNumber",
        columnType: "integer",
        columnLabel: DispenserMsgResProps.head.title.gradeName,
        isSortable: true,
        columnWidth: "30%",
      },
      {
        columnId: "dataDate",
        columnType: "string",
        columnLabel: DispenserMsgResProps.body.timeStamp.label,
        isSortable: true,
        columnWidth: "30%",
      },
      {
        columnId: "transactionCount",
        columnType: "string",
        columnLabel: DispenserMsgResProps.head.title.transactionCount,
        isSortable: false,
        columnWidth: "40%",
      },
    ];
  };

  render() {
    return (
      <>
        {this.props.showOverRuns === true ||
        this.props.showFlowRates === true ||
        this.props.showPaperOut === true ||
        this.props.showPrinterJammed === true ||
        this.props.showDoorAlerts === true ||
        this.props.showStandAlone === true ? (
          <Card className="sram-page-form sram-dispenser-card-bottom">
            <CardTitle
              onClick={this.toggle.bind(this)}
              className="sram-title-cursor"
            >
              <span>{DispenserMsgResProps.head.title.customerExpirience}</span>
            </CardTitle>
            <Collapse isOpen={this.state.isOpen}>
              <CardBody className="sram-dispenser-section-2 sram-dispenser-card-bdy">
                {this.props.showPeakFlowRates === true &&
                this.props.dispenserItem &&
                this.props.dispenserItem.manufacturerName === "Gilbarco" ? (
                  <WithLoading
                    isPageDataFetched={this.props.isPageDataFetched}
                    type="page"
                    size="sm"
                  >
                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <span className="sram-dispenser-section-sub-heading">
                          {DispenserMsgResProps.body.content.peakFlowSideA}
                        </span>
                        {this.props.transactionPeakFlowA &&
                        this.props.transactionPeakFlowA.length > 0 ? (
                          <div className="chart-wrapper">
                            <Chart
                              options={ChartOptions.getFlowDataOptions(
                                this.props.transactionPeakFlowA,
                                DispenserMsgResProps.body.content.peakFlowSideA,
                                this.props.dispenserItem,
                                this.props.transactionFlowAxis
                                  ? this.props.transactionFlowAxis[0]
                                      ?.side_a_axis
                                  : null
                              )}
                              series={ChartSeries.getFlowSeries(
                                this.props.transactionPeakFlowA
                              )}
                              type="line"
                              height="300"
                            />
                          </div>
                        ) : (
                          <div className="pt-3 py-3 sram-no-data">
                            {DispenserMsgResProps.body.content.noIssuesFound}
                          </div>
                        )}
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <span className="sram-dispenser-section-sub-heading">
                          {DispenserMsgResProps.body.content.peakFlowSideB}
                        </span>
                        {this.props.transactionPeakFlowB &&
                        this.props.transactionPeakFlowB.length > 0 ? (
                          <div className="chart-wrapper">
                            <Chart
                              options={ChartOptions.getFlowDataOptions(
                                this.props.transactionPeakFlowB,
                                DispenserMsgResProps.body.content.peakFlowSideB,
                                this.props.dispenserItem,
                                this.props.transactionFlowAxis
                                  ? this.props.transactionFlowAxis[1]
                                      ?.side_b_axis
                                  : null
                              )}
                              series={ChartSeries.getFlowSeries(
                                this.props.transactionPeakFlowB
                              )}
                              type="line"
                              height="300"
                            />
                          </div>
                        ) : (
                          <div className="pt-3 py-3 sram-no-data">
                            {DispenserMsgResProps.body.content.noIssuesFound}
                          </div>
                        )}
                      </Col>
                      {/* <Col sm={12} md={6} lg={6}>
                        <span className="sram-dispenser-section-sub-heading">
                          {DispenserMsgResProps.body.content.flowSnapshotSideA}
                        </span>
                        {this.props.sideAFlowRates &&
                          this.props.sideAFlowRates.length > 0 ? (
                          <div className="chart-wrapper">
                            <Chart
                              options={ChartOptions.getFlowDataOptions(
                                this.props.sideAFlowRates,
                                DispenserMsgResProps.body.content
                                  .flowSnapshotSideA
                              )}
                              series={ChartSeries.getFlowSeries(
                                this.props.sideAFlowRates
                              )}
                              type="line"
                              height="300"
                            />
                          </div>
                        ) : (
                          <div className="pt-3 py-3 sram-no-data">
                            {DispenserMsgResProps.body.content.noIssuesFound}
                          </div>
                        )}
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <span className="sram-dispenser-section-sub-heading">
                          {DispenserMsgResProps.body.content.flowSnapshotSideB}
                        </span>
                        {this.props.sideBFlowRates &&
                          this.props.sideBFlowRates.length > 0 ? (
                          <div className="chart-wrapper">
                            <Chart
                              options={ChartOptions.getFlowDataOptions(
                                this.props.sideBFlowRates,
                                DispenserMsgResProps.body.content
                                  .flowSnapshotSideB
                              )}
                              series={ChartSeries.getFlowSeries(
                                this.props.sideBFlowRates
                              )}
                              type="line"
                              height="300"
                            />
                          </div>
                        ) : (
                          <div className="pt-3 py-3 sram-no-data">
                            {DispenserMsgResProps.body.content.noIssuesFound}
                          </div>
                        )}
                      </Col> */}
                    </Row>
                    <hr />
                  </WithLoading>
                ) : null}

                {/* {this.props.showFlowRates &&
                this.props.dispenserItem &&
                (this.props.dispenserItem.manufacturerName === "Gilbarco") ===
                  true ? (
                  <WithLoading
                    isPageDataFetched={this.props.isPageDataFetched}
                    type="page"
                    size="sm"
                  >
                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <span className="sram-dispenser-section-sub-heading">
                          {DispenserMsgResProps.body.content.flowSnapshotSideA}
                        </span>
                        {this.props.sideAFlowRates &&
                        this.props.sideAFlowRates.length > 0 ? (
                          <div className="chart-wrapper">
                            <Chart
                              options={ChartOptions.getFlowRateOptions(
                                this.props.sideAFlowRates,
                                DispenserMsgResProps.body.content
                                  .flowSnapshotSideA
                              )}
                              series={ChartSeries.getFlowSeries(
                                this.props.sideAFlowRates
                              )}
                              type="line"
                              height="300"
                            />
                          </div>
                        ) : (
                          <div className="pt-3 py-3 sram-no-data">
                            {DispenserMsgResProps.body.content.noIssuesFound}
                          </div>
                        )}
                      </Col>
                      <Col sm={12} md={6} lg={6}>
                        <span className="sram-dispenser-section-sub-heading">
                          {DispenserMsgResProps.body.content.flowSnapshotSideB}
                        </span>
                        {this.props.sideBFlowRates &&
                        this.props.sideBFlowRates.length > 0 ? (
                          <div className="chart-wrapper">
                            <Chart
                              options={ChartOptions.getFlowRateOptions(
                                this.props.sideBFlowRates,
                                DispenserMsgResProps.body.content
                                  .flowSnapshotSideB
                              )}
                              series={ChartSeries.getFlowSeries(
                                this.props.sideBFlowRates
                              )}
                              type="line"
                              height="300"
                            />
                          </div>
                        ) : (
                          <div className="pt-3 py-3 sram-no-data">
                            {DispenserMsgResProps.body.content.noIssuesFound}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <hr />
                    </WithLoading>
                ) : null} */}
                <WithLoading
                  isPageDataFetched={this.props.isFlowRatesAlertsDataFetched}
                  type="page"
                  size="sm"
                >
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <span className="sram-dispenser-section-sub-heading">
                        {DispenserMsgResProps.body.content.dflowRate}
                      </span>
                      <PerfectScrollbar className="sram-dispenser-card-body-height">
                        <Table>
                          <thead>
                            <tr>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {DispenserMsgResProps.body.componentName.label}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {DispenserMsgResProps.body.sideLabel.label}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {DispenserMsgResProps.body.eventStatus.label}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {DispenserMsgResProps.body.eventCause.label}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {DispenserMsgResProps.body.eventTimeStamp.label}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {
                                  DispenserMsgResProps.body.closedTimeStamp
                                    .label
                                }
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                No Of Days Open
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.flowRatesAlertsData &&
                            this.props.flowRatesAlertsData.length > 0 ? (
                              this.props.flowRatesAlertsData.map(
                                (obj, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.componentName
                                          ? obj.componentName
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.sideLabel ? obj.sideLabel : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventStatus
                                          ? obj.eventStatus
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventCause
                                          ? obj.eventCause === "paper out" ||
                                            obj.eventCause === "jammed" ||
                                            obj.eventCause === "paper jam" ||
                                            obj.eventCause === "paper low"
                                            ? "Receipt Paper Error"
                                            : obj.eventCause
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventTimestamp
                                          ? AppUtils.getDateTime(
                                              obj.eventTimestamp
                                            )
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.closedTimestamp
                                          ? AppUtils.getDateTime(
                                              obj.closedTimestamp
                                            )
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.noOfDaysOpen
                                          ? obj.noOfDaysOpen
                                          : "-"}
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td
                                  colSpan="7"
                                  className="pt-3 py-3 sram-no-data"
                                >
                                  {
                                    DispenserMsgResProps.body.content
                                      .noIssuesFound
                                  }
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </PerfectScrollbar>
                    </Col>
                  </Row>
                </WithLoading>
                <hr />
                <WithLoading
                  isPageDataFetched={this.props.isFlowRatesAlertsDataFetched}
                  type="page"
                  size="sm"
                >
                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <span className="sram-dispenser-section-sub-heading">
                        {DispenserMsgResProps.body.content.dNoTransaction}
                      </span>
                      <PerfectScrollbar className="sram-dispenser-card-body-height">
                        <Table>
                          <thead>
                            <tr>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {DispenserMsgResProps.body.componentName.label}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {DispenserMsgResProps.body.sideLabel.label}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {DispenserMsgResProps.body.eventStatus.label}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {DispenserMsgResProps.body.eventCause.label}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {DispenserMsgResProps.body.eventTimeStamp.label}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {
                                  DispenserMsgResProps.body.closedTimeStamp
                                    .label
                                }
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                No Of Days Open
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.noTransactionAlertsData &&
                            this.props.noTransactionAlertsData.length > 0 ? (
                              this.props.noTransactionAlertsData.map(
                                (obj, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.componentName
                                          ? obj.componentName
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.sideLabel ? obj.sideLabel : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventStatus
                                          ? obj.eventStatus
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventCause
                                          ? obj.eventCause === "paper out" ||
                                            obj.eventCause === "jammed" ||
                                            obj.eventCause === "paper jam" ||
                                            obj.eventCause === "paper low"
                                            ? "Receipt Paper Error"
                                            : obj.eventCause
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventTimestamp
                                          ? AppUtils.getDateTime(
                                              obj.eventTimestamp
                                            )
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.closedTimestamp
                                          ? AppUtils.getDateTime(
                                              obj.closedTimestamp
                                            )
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.noOfDaysOpen
                                          ? obj.noOfDaysOpen
                                          : "-"}
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td
                                  colSpan="7"
                                  className="pt-3 py-3 sram-no-data"
                                >
                                  {
                                    DispenserMsgResProps.body.content
                                      .noIssuesFound
                                  }
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </PerfectScrollbar>
                    </Col>
                  </Row>
                </WithLoading>
                <hr />
                <Row>
                  {/* {this.props.showOverRuns &&
                    this.props.dispenserItem &&
                    (this.props.dispenserItem.manufacturerName === "Gilbarco") ===
                    true ? (
                    // <Row>
                    <Col sm={12} md={6} lg={6}>
                      <WithLoading
                        isPageDataFetched={this.props.isOverRunsFetched}
                        type="page"
                        size="sm"
                      >
                        <>
                          <span className="sram-dispenser-section-sub-heading">
                            {DispenserMsgResProps.body.content.overRun}
                          </span>
                          {this.props.overRuns &&
                            (this.props.overRuns.sideA.length > 0 ||
                              this.props.overRuns.sideB.length > 0) ? (
                            <div className="chart-wrapper">
                              <Chart
                                options={ChartOptions.getOverRunsOptions(
                                  this.props.overRuns
                                )}
                                series={ChartSeries.getOverRunsSeries(
                                  this.props.overRuns
                                )}
                                type="line"
                                height="300"
                              />
                            </div>
                          ) : (
                            <div className="pt-3 py-3 sram-no-data">
                              {DispenserMsgResProps.body.content.noIssuesFound}
                            </div>
                          )}
                        </>
                      </WithLoading>
                    </Col>
                  ) : // </Row>
                    null}
                  {this.props.showStandAlone === true ?
                    <Col sm={12} md={6} lg={6}>
                      {
                        this.props.dispenserItem &&
                          this.props.dispenserItem.manufacturerName === "Gilbarco" ? (
                          <WithLoading
                            isPageDataFetched={this.props.isStandAlonesFetched}
                            type="page"
                            size="sm"
                          >
                            <div>
                              <span className="sram-dispenser-section-sub-heading">
                                {DispenserMsgResProps.body.content.standAlones}
                              </span>
                              {this.props.standAlones &&
                                (this.props.standAlones.sideA.length > 0 ||
                                  this.props.standAlones.sideB.length > 0) ? (
                                // this.props.standAloneA > 0 ||
                                // this.props.standAloneB > 0 ? (
                                <div className="chart-wrapper">
                                  <Chart
                                    options={ChartOptions.getStandAloneOptions(
                                      this.props.standAlones
                                    )}
                                    series={ChartSeries.getStandAloneSeries(
                                      this.props.standAlones
                                    )}
                                    type="line"
                                    height="300"
                                  />
                                </div>
                              ) : (
                                // ) : (
                                //   <div className="pt-3 py-3 sram-no-data">
                                //     {DispenserMsgResProps.body.content.noStandAlones}
                                //   </div>
                                // )
                                <div className="pt-3 py-3 sram-no-data">
                                  {DispenserMsgResProps.body.content.noIssuesFound}
                                </div>
                              )}
                            </div>
                          </WithLoading>
                        ) : null}
                    </Col>
                    : null} */}

                  {/* {this.props.showOverRuns === true ||
                    this.props.showFlowRates === true ||
                    this.props.showPaperOut === true ||
                    this.props.showPrinterJammed === true ||
                    this.props.showDoorAlerts === true ||
                    this.props.showStandAlone === true ?
                    <Col sm={12} md={6} lg={6}></Col>
                    : null} */}
                  {this.props.showPaperOut === true ||
                  this.props.showPrinterJammed === true ? (
                    <Col sm={12} md={6} lg={6}>
                      <div className="pt-3 pb-1">
                        <span className="sram-dispenser-section-sub-heading">
                          {DispenserMsgResProps.body.fp1.label}
                        </span>
                        <PerfectScrollbar className="sram-dispenser-card-height">
                          <Table>
                            <thead>
                              <tr>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    DispenserMsgResProps.body.componentName
                                      .label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {DispenserMsgResProps.body.side.label}
                                </th>
                                {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.itemName.label}
                                  </th> */}
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {DispenserMsgResProps.body.eventStatus.label}
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {DispenserMsgResProps.body.eventCause.label}
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    DispenserMsgResProps.body.eventTimeStamp
                                      .label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    DispenserMsgResProps.body.closedTimeStamp
                                      .label
                                  }
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.paperOutA &&
                              this.props.paperOutA.length > 0 ? (
                                this.props.paperOutA.map((obj, index) => {
                                  // const description = DispenserUtils.capitalize(
                                  //   obj.errorDescription
                                  // );
                                  return (
                                    <tr key={index}>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.componentName
                                          ? obj.componentName
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.sideLabel ? obj.sideLabel : "-"}
                                      </td>
                                      {/* <td className="sram-text-font sram-table-td">
                                          {obj.itemName ? obj.itemName : "-"}
                                        </td> */}
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventStatus
                                          ? obj.eventStatus
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventCause
                                          ? obj.eventCause === "paper out" ||
                                            obj.eventCause === "jammed" ||
                                            obj.eventCause === "paper jam" ||
                                            obj.eventCause === "paper low"
                                            ? "Receipt Paper Error"
                                            : obj.eventCause
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventTimestamp
                                          ? AppUtils.getDateTime(
                                              obj.eventTimestamp
                                            )
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.closedTimestamp
                                          ? AppUtils.getDateTime(
                                              obj.closedTimestamp
                                            )
                                          : "-"}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td
                                    colSpan="6"
                                    className="pt-3 py-3 sram-no-data"
                                  >
                                    {
                                      DispenserMsgResProps.body.content
                                        .noIssuesFound
                                    }
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </PerfectScrollbar>
                      </div>
                    </Col>
                  ) : null}

                  {this.props.showPrinterJammed === true ||
                  this.props.showPaperOut === true ? (
                    <Col sm={12} md={6} lg={6}>
                      <div className="pt-3 pb-1">
                        <span className="sram-dispenser-section-sub-heading">
                          {DispenserMsgResProps.body.fp2.label}
                        </span>
                        <PerfectScrollbar className="sram-dispenser-card-height">
                          <Table>
                            <thead>
                              <tr>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    DispenserMsgResProps.body.componentName
                                      .label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {DispenserMsgResProps.body.side.label}
                                </th>
                                {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.itemName.label}
                                  </th> */}
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {DispenserMsgResProps.body.eventStatus.label}
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {DispenserMsgResProps.body.eventCause.label}
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    DispenserMsgResProps.body.eventTimeStamp
                                      .label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    DispenserMsgResProps.body.closedTimeStamp
                                      .label
                                  }
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.paperOutB &&
                              this.props.paperOutB.length > 0 ? (
                                this.props.paperOutB.map((obj, index) => {
                                  // const description = DispenserUtils.capitalize(
                                  //   obj.errorDescription
                                  // );
                                  return (
                                    <tr key={index}>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.componentName
                                          ? obj.componentName
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.sideLabel ? obj.sideLabel : "-"}
                                      </td>
                                      {/* <td className="sram-text-font sram-table-td">
                                          {obj.itemName ? obj.itemName : "-"}
                                        </td> */}
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventStatus
                                          ? obj.eventStatus
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventCause
                                          ? obj.eventCause === "paper out" ||
                                            obj.eventCause === "jammed" ||
                                            obj.eventCause === "paper jam" ||
                                            obj.eventCause === "paper low"
                                            ? "Receipt Paper Error"
                                            : obj.eventCause
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventTimestamp
                                          ? AppUtils.getDateTime(
                                              obj.eventTimestamp
                                            )
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.closedTimestamp
                                          ? AppUtils.getDateTime(
                                              obj.closedTimestamp
                                            )
                                          : "-"}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td
                                    colSpan="6"
                                    className="pt-3 py-3 sram-no-data"
                                  >
                                    {
                                      DispenserMsgResProps.body.content
                                        .noIssuesFound
                                    }
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </PerfectScrollbar>
                      </div>
                    </Col>
                  ) : null}

                  {this.props.showNoTransaction === true ? (
                    <>
                      <Col sm={12} md={6} lg={6}>
                        <div className="pt-3 pb-1">
                          <span className="sram-dispenser-section-sub-heading">
                            {DispenserMsgResProps.body.noTransSideA.label}
                          </span>
                          <div className="sram-dispenser-card-height">
                            <Table>
                              <thead>
                                <tr>
                                  {this.getTableColumnHeadings().map(
                                    (column) => {
                                      return (
                                        <PageListHeaderColumn
                                          {...column}
                                          key={column.columnId}
                                          sortedColumn={
                                            this.props.sortedColumnA
                                          }
                                          sortedOrder={this.props.sortedOrderA}
                                          handleSortChange={this.props.handleSortChangeSideA()}
                                        />
                                      );
                                    }
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.noTransactionSideAData &&
                                this.props.noTransactionSideAData.length > 0 ? (
                                  this.props.noTransactionSideAData &&
                                  this.props.noTransactionSideAData.map(
                                    (obj, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.gradeNumber
                                              ? obj.gradeNumber
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.dataDate
                                              ? AppUtils.getDate(obj.dataDate)
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {"0"}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="3"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Col>

                      <Col sm={12} md={6} lg={6}>
                        <div className="pt-3 pb-1">
                          <span className="sram-dispenser-section-sub-heading">
                            {DispenserMsgResProps.body.noTransSideB.label}
                          </span>
                          <div className="sram-dispenser-card-height">
                            <Table>
                              <thead>
                                <tr>
                                  {this.getTableColumnHeadings().map(
                                    (column) => {
                                      return (
                                        <PageListHeaderColumn
                                          {...column}
                                          key={column.columnId}
                                          sortedColumn={
                                            this.props.sortedColumnB
                                          }
                                          sortedOrder={this.props.sortedOrderB}
                                          handleSortChange={this.props.handleSortChangeSideB()}
                                        />
                                      );
                                    }
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.noTransactionSideBData &&
                                this.props.noTransactionSideBData.length > 0 ? (
                                  this.props.noTransactionSideBData &&
                                  this.props.noTransactionSideBData.map(
                                    (obj, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.gradeNumber
                                              ? obj.gradeNumber
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.dataDate
                                              ? AppUtils.getDate(obj.dataDate)
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {"0"}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="3"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : null}

                  {/* {this.props.showDoorAlerts === true ? (
                    <Col sm={12} md={6} lg={6}>
                      <WithLoading
                        isPageDataFetched={this.props.isDoorAlertsPageDataFetched}
                        type="page"
                        size="sm"
                      >
                        <div>
                          <span className="sram-dispenser-section-sub-heading">
                            {DispenserMsgResProps.body.content.doorAlerts}
                          </span>
                          {this.props.doorAlerts &&
                            this.props.doorAlerts.length > 0 ? (
                            <PerfectScrollbar className="sram-dispenser-card-height">
                              <Table>
                                <thead>
                                  <tr>
                                    <th className="sram-text-font sram-table-th sram-font-weight-600">
                                      {DispenserMsgResProps.body.doorType.label}
                                    </th>
                                    <th className="sram-text-font sram-table-th sram-font-weight-600">
                                      {
                                        DispenserMsgResProps.body.doorStatus
                                          .label
                                      }
                                    </th>
                                    <th className="sram-text-font sram-table-th sram-font-weight-600">
                                      {
                                        DispenserMsgResProps.body
                                          .deviceTimestamp.label
                                      }
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.props.doorAlerts.map(
                                    (dooralerts, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className="sram-text-font sram-table-td">
                                            {dooralerts.doorTypeDescription ? dooralerts.doorTypeDescription : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {dooralerts.doorStatusDescription ? this.getDoorStatus(
                                              dooralerts.doorStatusDescription
                                            ) : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {AppUtils.getDateTime(
                                              dooralerts.deviceTimestamp
                                            )} {dooralerts.deviceTimestampTimezone}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </Table>
                            </PerfectScrollbar>
                          ) : (
                            <div className="pt-3 py-3 sram-no-data">
                              {DispenserMsgResProps.body.content.noIssuesFound}
                            </div>
                          )}
                        </div>
                      </WithLoading>
                    </Col>
                  ) : null} */}
                </Row>
              </CardBody>
            </Collapse>
          </Card>
        ) : null}
      </>
    );
  }
}

export default DispenserCustomerExperience;
