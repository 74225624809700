import React, { Component } from "react";
import {
  Card,
  CardTitle,
  Collapse,
  CardBody,
  Row,
  Col,
  Table,
  Label,
  Badge,
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import axios from "axios";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import PageDisplay from "../../core/helpers/page-display";
import { DispenserMsgResProps } from "../messages/dispenser-properties";
import PerfectScrollbar from "react-perfect-scrollbar";
import WithLoading from "../../core/helpers/with-loading";
import AppUtils from "../../core/helpers/app-utils";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { pcnEventAlerts } from "../actions/dispenser-actions";

class DispenserPCNAlerts extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      formInputFields: this.initFormInputFields(),
      selectedPCNEvents: [],
      isOpenPCNModal: false,
      isPCNEventConfirmation: false,
      statusMessage: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  handleCollapse() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  initFormInputFields = () => {
    return {
      pcnEventId: [],
    };
  };

  formValidationSchema = () => {
    const schema = {};
    return schema;
  };

  togglePCNEvents = () => {
    this.setState({
      isOpenPCNModal: !this.state.isOpenPCNModal,
      statusMessage: null,
      isPCNEventConfirmation: false,
    });
  };

  toggleSingleAction = async (e, obj, formik, arrayHelpers) => {
    if (e.target.checked) {
      arrayHelpers.push(obj.pcnEventId);
    } else {
      const idx = formik.values.pcnEventId.indexOf(obj.pcnEventId);
      arrayHelpers.remove(idx);
    }
    this.setState((prevState) => ({
      selectedPCNEvents: [...prevState.selectedPCNEvents, obj],
    }));
  };

  handleConfirmation = async (values, helpers) => {
    // Remove Duplicated from Array
    let uniqueArr = values.pcnEventId.filter((c, index) => {
      return values.pcnEventId.indexOf(c) === index;
    });
    // Filter from Array
    const filteredObjects = this.state.selectedPCNEvents.filter((obj) =>
      uniqueArr.includes(obj.pcnEventId)
    );

    const finalObj = filteredObjects.map((obj) => {
      return {
        pcnEventId: obj.pcnEventId,
        siteId: this.props.siteId,
        dispenserId: this.props.dispenserId,
        sideLabel: obj.sideLabel,
        componentName: obj.componentName,
        eventTimestamp: obj.eventTimestamp,
        receiveTimestamp: obj.receiveTimestamp,
        createTimestamp: obj.createTimestamp,
        eventCause: obj.eventCause,
        dispenserCreateTimestamp: obj.dispenserCreateTimestamp,
        errorCode: obj.errorCode,
      };
    });

    this.setState({ isPCNEventConfirmation: true });
    const queryData =
      "extractType=period&alertEventStatus=O&dataPeriod=" +
      this.state.days +
      "&sortField=createTimestamp&sortOrder=-1&componentName=pumpcontrolnode";
    const siteId = this.props.siteId;
    const dispenserId = this.props.dispenserId;
    const bodyObj = finalObj;
    await pcnEventAlerts(
      siteId,
      dispenserId,
      queryData,
      bodyObj,
      this._cancelToken
    )
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data
        ) {
          const statusMsg = [
            "S",
            res.data?.message,
            res.data?.createdEventTypesWithAlertIds,
          ];
          this.setState({ statusMessage: statusMsg });
          this.setState({
            formInputFields: this.initFormInputFields(),
            selectedPCNEvents: [],
          });
          setTimeout(async () => {
            this.togglePCNEvents();
            await this.props.reloadAlertsData();
          }, 3000);
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        if (err && err.data && err.data.errors) {
          const errors = err.data.errors;
          if (errors && errors?.message === "Alert(s) Already Exists.") {
            const statusMsg = [
              "E",
              errors?.message,
              errors?.existEventTypesWithAlertIds,
            ];
            this.setState({ statusMessage: statusMsg });
          } else if (
            errors &&
            errors?.message ===
              "Few Log(s) Converted into an alert, while another Log(s) already exists as an alert."
          ) {
            const statusMsg = [
              "W",
              errors?.message,
              errors?.createdEventTypesWithAlertIds,
              errors?.existEventTypesWithAlertIds,
            ];
            this.setState({ statusMessage: statusMsg });
            setTimeout(async () => {
              this.togglePCNEvents();
              await this.props.reloadAlertsData();
            }, 3000);
          }
        }
      });
  };

  getTablerData(status, data) {
    return (
      <>
        <div className="sram-text-font sram-font-weight-600">
          {status === "S" ? "Created Alerts :" : "Existing Alerts :"}
        </div>
        <div>
          {data && data?.length > 0 ? (
            <PerfectScrollbar className="sram-dispenser-card-body-height">
              <Table>
                <thead>
                  <tr>
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.alertEventId.label}
                    </th>
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.side.label}
                    </th>
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {DispenserMsgResProps.body.eventType.label}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((obj, index) => {
                    return (
                      <tr key={index}>
                        <td className="sram-text-font sram-table-td">
                          {obj.alert_event_id ? obj.alert_event_id : "-"}
                        </td>
                        <td className="sram-text-font sram-table-td">
                          {obj.side_label ? obj.side_label : "-"}
                        </td>
                        <td className="sram-text-font sram-table-td">
                          {obj.event_type ? obj.event_type : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </PerfectScrollbar>
          ) : null}
        </div>
      </>
    );
  }

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <Card className="sram-page-form sram-dispenser-card-bottom">
        <CardTitle
          onClick={this.handleCollapse.bind(this)}
          className="sram-title-cursor"
        >
          <span>{DispenserMsgResProps.body.content.pcnAlerts}</span>
        </CardTitle>
        <Collapse isOpen={this.state.isOpen}>
          <CardBody className="sram-dispenser-section-1-errorlogs sram-dispenser-card-bdy">
            <div>
              <WithLoading
                isPageDataFetched={this.props.isAlertEventDataFetched}
                type="page"
                size="sm"
              >
                <Row>
                  <Col sm="12">
                    <Formik
                      initialValues={this.state.formInputFields}
                      validationSchema={Yup.object().shape(
                        this.formValidationSchema()
                      )}
                      onSubmit={(values, helpers) =>
                        this.handleConfirmation(values, helpers)
                      }
                    >
                      {(formik) => (
                        <>
                          <Form id="pcnEventId" {...formEvent}>
                            <Card body className="sram-dispenser-card-bdy1">
                              <div className="pt-0 mt-0 mb-2">
                                {this.props.alertEventData &&
                                this.props.alertEventData.length > 0 ? (
                                  <span className="float-right pt-0">
                                    {this.props.createAlertsView === true ? (
                                      <Badge
                                        className={
                                          this.state.selectedPCNEvents &&
                                          this.state.selectedPCNEvents?.length >
                                            0
                                            ? "sram-links font-mild sram-alert-update sram-text-align-centre"
                                            : "sram-links font-mild sram-alert-update disabled-link sram-disable-badge sram-text-align-centre"
                                        }
                                        onClick={() => this.togglePCNEvents()}
                                      >
                                        {
                                          DispenserMsgResProps.body.createAlerts
                                            .label
                                        }
                                      </Badge>
                                    ) : null}
                                  </span>
                                ) : null}
                              </div>
                              <PerfectScrollbar className="sram-dispenser-card-body-height">
                                <FieldArray
                                  name="pcnEventId"
                                  render={(arrayHelpers) => (
                                    <Table>
                                      <thead>
                                        <tr>
                                          <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                            {
                                              DispenserMsgResProps.body
                                                .componentName.label
                                            }
                                          </th>
                                          <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                            {
                                              DispenserMsgResProps.body.side
                                                .label
                                            }
                                          </th>
                                          <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                            {
                                              DispenserMsgResProps.body
                                                .errorCode.label
                                            }
                                          </th>
                                          <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                            {
                                              DispenserMsgResProps.body
                                                .eventStatus.label
                                            }
                                          </th>
                                          <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                            {
                                              DispenserMsgResProps.body
                                                .eventCause.label
                                            }
                                          </th>
                                          <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                            {
                                              DispenserMsgResProps.body
                                                .eventTimeStamp.label
                                            }
                                          </th>
                                          <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                            {
                                              DispenserMsgResProps.body
                                                .closedTimeStamp.label
                                            }
                                          </th>
                                          <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                            {
                                              DispenserMsgResProps.body
                                                .dispenserCreateTimestamp.label
                                            }
                                          </th>
                                          <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                            {
                                              DispenserMsgResProps.body
                                                .dispenserCloseTimestamp.label
                                            }
                                          </th>
                                          <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                            {
                                              DispenserMsgResProps.body
                                                .alertEventId.label
                                            }
                                          </th>
                                        </tr>
                                      </thead>

                                      {this.props.alertEventData &&
                                      this.props.alertEventData.length > 0 ? (
                                        <tbody>
                                          {this.props.alertEventData.map(
                                            (obj, index) => {
                                              let keyString = {
                                                key: `key-pcnEventId-${index}`,
                                              };
                                              return (
                                                <tr {...keyString}>
                                                  <td
                                                    className="sram-text-font sram-table-td"
                                                    style={{ display: "flex" }}
                                                  >
                                                    {this.props
                                                      .createAlertsView ===
                                                    true ? (
                                                      <input
                                                        name="pcnEventId"
                                                        id={`check_${obj.pcnEventId}`}
                                                        type="checkbox"
                                                        value={obj.pcnEventId}
                                                        checked={formik.values.pcnEventId.includes(
                                                          obj.pcnEventId
                                                        )}
                                                        disabled={
                                                          obj.alertEventId !==
                                                          null
                                                        }
                                                        onChange={async (e) => {
                                                          await this.toggleSingleAction(
                                                            e,
                                                            obj,
                                                            formik,
                                                            arrayHelpers
                                                          );
                                                        }}
                                                      />
                                                    ) : null}
                                                    &nbsp;
                                                    {obj.componentName
                                                      ? obj.componentName
                                                      : "-"}
                                                  </td>
                                                  <td className="sram-text-font sram-table-td">
                                                    {obj.sideLabel
                                                      ? obj.sideLabel
                                                      : "-"}
                                                  </td>
                                                  <td className="sram-text-font sram-table-td">
                                                    {obj.errorCode
                                                      ? obj.errorCode
                                                      : "-"}
                                                  </td>
                                                  <td className="sram-text-font sram-table-td">
                                                    {obj.eventStatus
                                                      ? obj.eventStatus
                                                      : "-"}
                                                  </td>
                                                  <td className="sram-text-font sram-table-td">
                                                    {obj.eventCause
                                                      ? obj.eventCause
                                                      : "-"}
                                                  </td>
                                                  <td className="sram-text-font sram-table-td">
                                                    {obj.eventTimestamp
                                                      ? AppUtils.getDateTime(
                                                          obj.eventTimestamp
                                                        )
                                                      : "-"}
                                                  </td>
                                                  <td className="sram-text-font sram-table-td">
                                                    {obj.closeTimestamp
                                                      ? AppUtils.getDateTime(
                                                          obj.closeTimestamp
                                                        )
                                                      : "-"}
                                                  </td>
                                                  <td className="sram-text-font sram-table-td">
                                                    {obj.dispenserCreateTimestamp
                                                      ? AppUtils.getDateTime(
                                                          obj.dispenserCreateTimestamp
                                                        )
                                                      : "-"}
                                                  </td>
                                                  <td className="sram-text-font sram-table-td">
                                                    {obj.dispenserCloseTimestamp
                                                      ? AppUtils.getDateTime(
                                                          obj.dispenserCloseTimestamp
                                                        )
                                                      : "-"}
                                                  </td>
                                                  <td className="sram-text-font sram-table-td">
                                                    {obj.alertEventId
                                                      ? obj.alertEventId
                                                      : "-"}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}
                                        </tbody>
                                      ) : (
                                        <tbody>
                                          <tr>
                                            <td
                                              colSpan="10"
                                              className="pt-3 py-3 sram-no-data"
                                            >
                                              {
                                                DispenserMsgResProps.body
                                                  .content.noIssuesFound
                                              }
                                            </td>
                                          </tr>
                                        </tbody>
                                      )}
                                    </Table>
                                  )}
                                ></FieldArray>
                              </PerfectScrollbar>
                            </Card>
                          </Form>

                          {this.state.isOpenPCNModal ? (
                            <Modal
                              isOpen={this.state.isOpenPCNModal}
                              toggle={this.togglePCNEvents}
                              size="lg"
                              backdrop="static"
                              centered={false}
                              className="sram-modal-content"
                            >
                              <div className="modal-header">
                                <span>
                                  <h5 className="modal-title">Confirmation</h5>
                                </span>
                                <span
                                  className="pull-right sram-times"
                                  onClick={() => this.togglePCNEvents()}
                                >
                                  <i className="fa fa-times-circle"></i>
                                </span>
                              </div>
                              <ModalBody>
                                <Label>
                                  Are you sure you want to convert this PCN Logs
                                  as New alert?
                                </Label>
                                {this.state.statusMessage &&
                                this.state.statusMessage[0] === "S" ? (
                                  <>
                                    <div className="pt-4">
                                      {PageDisplay.showSuccessNotification(
                                        this.state.statusMessage[0] === "S"
                                          ? this.state.statusMessage[1]
                                          : null
                                      )}
                                    </div>
                                    <div>
                                      {this.state.statusMessage[0] === "S"
                                        ? this.getTablerData(
                                            "S",
                                            this.state.statusMessage[2]
                                          )
                                        : null}
                                    </div>
                                  </>
                                ) : this.state.statusMessage &&
                                  this.state.statusMessage[0] === "W" ? (
                                  <>
                                    <div className="pt-4">
                                      {PageDisplay.showWarningNotification(
                                        this.state.statusMessage[0] === "W"
                                          ? this.state.statusMessage[1]
                                          : null
                                      )}
                                    </div>
                                    <div>
                                      {this.state.statusMessage[0] === "W"
                                        ? this.getTablerData(
                                            "S",
                                            this.state.statusMessage[2]
                                          )
                                        : null}
                                    </div>
                                    <div>
                                      {this.state.statusMessage[0] === "W"
                                        ? this.getTablerData(
                                            "E",
                                            this.state.statusMessage[3]
                                          )
                                        : null}
                                    </div>
                                  </>
                                ) : this.state.statusMessage &&
                                  this.state.statusMessage[0] === "E" ? (
                                  <>
                                    <div className="pt-4">
                                      {PageDisplay.showErrorNotification(
                                        this.state.statusMessage[0] === "E"
                                          ? this.state.statusMessage[1]
                                          : null
                                      )}
                                    </div>
                                    <div>
                                      {this.state.statusMessage[0] === "E"
                                        ? this.getTablerData(
                                            "E",
                                            this.state.statusMessage[2]
                                          )
                                        : null}
                                    </div>
                                  </>
                                ) : null}
                              </ModalBody>
                              <ModalFooter className="m-1 p-2">
                                <Button
                                  type="button"
                                  className="sram-btn-blue mx-2"
                                  onClick={() => formik.handleSubmit()}
                                  disabled={this.state.isPCNEventConfirmation}
                                >
                                  {AppMsgResProps.body.form.confirm.label}
                                  {this.state.isPCNEventConfirmation === true &&
                                  this.state.statusMessage === null ? (
                                    <Spinner
                                      size="sm"
                                      color="light"
                                      className="pb-1 ml-2"
                                    />
                                  ) : null}
                                </Button>
                                <Button
                                  type="button"
                                  className="sram-btn-white mx-2"
                                  onClick={() => this.togglePCNEvents()}
                                >
                                  {AppMsgResProps.body.form.cancel.label}
                                </Button>
                              </ModalFooter>
                            </Modal>
                          ) : null}
                        </>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </WithLoading>
            </div>
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

export default DispenserPCNAlerts;
