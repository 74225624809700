export const OnboardingMsgResProps = {
  head: {
    title: {},
  },
  body: {
    content: {
      onboarding: "Onboarding",
    },
    form: {
      dispenser: {
        label: "Dispenser Name",
      },
      sideANumber: {
        label: "SideA Number",
      },
      sideBNumber: {
        label: "SideB Number",
      },
      manufacturerName: {
        label: "Manufacturer Name",
      },
      modelName: {
        label: "Model Name",
      },
      imei: {
        label: "IMEI",
      },
      site: {
        label: "Site"
      },
      meter1Name: {
        label: "Meter 1 Name",
      },
      meter2Name: {
        label: "Meter 2 Name",
      },
      meter3Name: {
        label: "Meter 3 Name",
      },
      meter4Name: {
        label: "Meter 4 Name",
      },
      meter5Name: {
        label: "Meter 5 Name",
      },
      meter6Name: {
        label: "Meter 6 Name",
      },
      meter7Name: {
        label: "Meter 7 Name",
      },
      meter8Name: {
        label: "Meter 8 Name",
      },
      grade1Name: {
        label: "Grade 1 Name",
      },
      grade2Name: {
        label: "Grade 2 Name",
      },
      grade3Name: {
        label: "Grade 3 Name",
      },
      grade4Name: {
        label: "Grade 4 Name",
      },
      grade5Name: {
        label: "Grade 5 Name",
      },
      grade6Name: {
        label: "Grade 6 Name",
      },
      grade7Name: {
        label: "Grade 7 Name",
      },
      grade8Name: {
        label: "Grade 8 Name",
      },
      fuelingSideA: {
        label: "Fueling SideA Number"
      },
      fuelingSideB: {
        label: "Fueling SideB Number"
      },
      twoWire: {
        label: "Two Wire"
      },
      warranty: {
        label: "Warranty"
      },
      twoWireSideA: {
        label: "Two Wire SideA Number"
      },
      twoWireSideB: {
        label: "Two Wire SideB Number"
      },
    },
    notification: {
      error: {
        message: "An error has occured.",
        imei: {
          empty: "IMEI is required",
          invalid: "IMEI is not valid.",
        },
        macAddress: {
          empty: "MAC Address is required",
          invalid: "MAC Address is not valid.",
        },
        deviceId: {
          empty: "Device Id is required",
          invalid: "Device Id is not valid.",
        },
        manufacturerName: {
          empty: "Manufacturer Name is required",
        },
        modelName: {
          empty: "Model Name is required",
        },
        dispenser: {
          empty: "Dispenser is required",
        },
        warranty: {
          empty: "Warranty is required",
        },
        fuelingSideA: {
          empty: "Fueling Side A is required",
          invalid: "Fueling Side A is not valid.",
        },
        fuelingSideB: {
          empty: "Fueling Side B is required",
          invalid: "Fueling Side B not valid.",
        },
        twoWire: {
          empty: "Two Wire is required",
          invalid: "Two Wire is not valid.",
        },
        twoWireSideA: {
          empty: "Two Wire Side A is required",
          invalid: "Two Wire Side A is not valid.",
        },
        twoWireSideB: {
          empty: "Two Wire Side B is required",
          invalid: "Two Wire Side B is not valid.",
        },
      },
      confirmation: {
        addDeviceTitle: "Are you sure you want to add the Device ?",
      },
      success: {
        addDevice: "Device is added successfully.",
      },
    },
  },
};
