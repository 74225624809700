import { AppURLProps } from "../core/settings/app-urls";
import { SetupURLProps } from "../setup/settings/setup-urls";
import { PortalURLProps } from "../portal/settings/portal-urls";
import Home from "../core/components/home";
import MyAccount from "../core/components/my-account";
import PasswordChange from "../core/components/password-change";
import ProfileUpdate from "../core/components/profile-update";
import SitesHome from "../portal/components/sites-home";
import SiteDashboard from '../portal/components/site-dashboard'
import DispenserDashboard from '../portal/components/dispenser-dashboard';
import RoleList from "../setup/components/role-list";
import RoleAdd from "../setup/components/role-add";
import RoleView from "../setup/components/role-view";
import RoleEdit from "../setup/components/role-edit";
import SiteOwnerCompanyList from "../setup/components/site-owner-company-list";
import SiteOwnerCompanyAdd from "../setup/components/site-owner-company-add";
import SiteOwnerCompanyView from "../setup/components/site-owner-company-view";
import SiteOwnerCompanyEdit from "../setup/components/site-owner-company-edit";
import SiteOwnerDivisionList from "../setup/components/site-owner-division-list";
import SiteOwnerDivisionAdd from "../setup/components/site-owner-division-add";
import SiteOwnerDivisionView from "../setup/components/site-owner-division-view";
import SiteOwnerDivisionEdit from "../setup/components/site-owner-division-edit";
import SiteOwnerSiteList from "../setup/components/site-owner-site-list";
import SiteOwnerSiteAdd from "../setup/components/site-owner-site-add";
import SiteOwnerSiteView from "../setup/components/site-owner-site-view";
import SiteOwnerSiteEdit from "../setup/components/site-owner-site-edit";
import ServiceProviderCompanyList from "../setup/components/service-provider-company-list";
import ServiceProviderCompanyAdd from "../setup/components/service-provider-company-add";
import ServiceProviderCompanyView from "../setup/components/service-provider-company-view";
import ServiceProviderCompanyEdit from "../setup/components/service-provider-company-edit";
import ServiceProviderDivisionList from "../setup/components/service-provider-division-list";
import ServiceProviderDivisionAdd from "../setup/components/service-provider-division-add";
import ServiceProviderDivisionView from "../setup/components/service-provider-division-view";
import ServiceProviderDivisionEdit from "../setup/components/service-provider-division-edit";
import ServiceProviderSiteList from "../setup/components/service-provider-site-list";
import serviceProviderSiteAdd from "../setup/components/service-provider-site-add";
import ServiceProviderSiteView from "../setup/components/service-provider-site-view";
import ServiceProviderSiteEdit from "../setup/components/service-provider-site-edit";
import serviceProviderUserAccess from "../setup/components/service-provider-user-access";
import UserList from "../setup/components/user-list";
import UserAdd from "../setup/components/user-add";
import UserView from "../setup/components/user-view";
import UserEdit from "../setup/components/user-edit";
import SiteUserAccess from "../setup/components/site-user-access";
import UserSiteAccess from "../setup/components/user-site-access";
import Onboarding from "../configuration/components/onboarding";
import ErrorCodes from "../configuration/components/error-codes";
import Devices from "../configuration/components/devices-list";
import DeviceView from "../configuration/components/device-view";
import DeviceEdit from "../configuration/components/device-edit";
import Alerts from "../alerts/components/alerts";
import CurrentMessageStatus from '../reports/components/current-message-status';
import CurrentMessageSizeReport from '../reports/components/current-messagesize-report';
import CoreReports from "../reports/components/core-reports";
import { ConfigURLProps } from "../configuration/settings/config-urls";
import { ReportsURLProps } from "../reports/settings/report-urls";
import { AlertURLProps } from "..//alerts/settings/alert-urls";
// import AnalyticsHome from '../analytics/components/analytics-home';
import SitesImport from '../setup/components/sites-import';

const AppRoutes = [
  {
    collapse: true,
    partCode: null,
    component: null,
    path: AppURLProps.home,
    name: "Home",
    state: "nav-default",
    icon: "ti-user",
    navDisplay: false,
    exactPath: true,
    child: [
      {
        partCode: ["AC-UID-UACT"],
        component: Home,
        path: AppURLProps.home,
        name: "Home",
        mini: "B",
        icon: "mdi mdi-stop-circle",
        navDisplay: false,
        exactPath: true,
      },
      {
        partCode: ["AC-UID-UACT"],
        component: MyAccount,
        path: AppURLProps.myAccount,
        name: "My Account",
        mini: "B",
        icon: "mdi mdi-stop-circle",
        navDisplay: false,
        exactPath: true,
      },
      {
        partCode: ["AC-UID-UCPW"],
        component: PasswordChange,
        path: AppURLProps.changePassword,
        name: "Change Password",
        mini: "B",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
      {
        partCode: ["AC-UID-UUPF"],
        component: ProfileUpdate,
        path: AppURLProps.updateProfile,
        name: "Update Profile",
        mini: "B",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
    ],
  },
  {
    collapse: true,
    partCode: null,
    component: null,
    path: "/portal",
    name: "Dashboard",
    state: "nav-dashboard",
    icon: "mdi mdi-view-dashboard",
    navDisplay: true,
    exactPath: true,
    child: [
      {
        partCode: ["CP-SIT-SVIW"],
        component: SitesHome,
        path: PortalURLProps.sitesHome,
        name: "Sites",
        mini: "B",
        icon: "mdi mdi-stop-circle",
        navDisplay: true,
        exactPath: true,
      },
      {
        partCode: ["CP-DSP-DVIW"],
        component: SiteDashboard,
        path: PortalURLProps.siteDashboard,
        name: "Site Dispensers",
        mini: "B",
        icon: "mdi mdi-stop-circle",
        navDisplay: false,
        exactPath: true,
      },
      {
        partCode: ["CP-DSP-DVIW"],
        component: DispenserDashboard,
        path: PortalURLProps.dispenserDashboard,
        name: "Dispenser Dashboard",
        mini: "B",
        icon: "mdi mdi-stop-circle",
        navDisplay: false,
        exactPath: true,
      },
      {
        partCode: ["HT-ALT-ALVW"],
        component: Alerts,
        path: AlertURLProps.alerts,
        name: "Alerts",
        mini: "B",
        icon: "mdi mdi-stop-circle",
        navDisplay: true,
        exactPath: true,
      },
      // {
      //   partCode: ["CP-ANT-DSBD"],
      //   component: AnalyticsHome,
      //   path: PortalURLProps.analytics,
      //   name: "Analytics",
      //   mini: "B",
      //   icon: "mdi mdi-stop-circle",
      //   navDisplay: true,
      //   exactPath: true,
      // },
      {
        partCode: ["JR-RPT-RPVW","JR-SRT-SRTV"],
        component: CoreReports,
        path: PortalURLProps.reports,
        name: "Reports",
        mini: "B",
        icon: "mdi mdi-stop-circle",
        navDisplay: true,
        exactPath: true,
      }
    ],
  },
  {
    collapse: true,
    partCode: null,
    component: null,
    path: SetupURLProps.setup,
    name: "Setup",
    state: "nav-setup",
    icon: "mdi mdi-settings",
    navDisplay: true,
    exactPath: true,
    child: [
      {
        partCode: ["AS-ROL-RADD", "AS-ROL-RVIW", "AS-ROL-REDT"],
        component: RoleList,
        path: SetupURLProps.roles.listRole,
        name: "Roles",
        mini: "B",
        icon: "mdi mdi-stop-circle",
        navDisplay: true,
        exactPath: true,
      },
      {
        partCode: ["AS-ROL-RADD"],
        component: RoleAdd,
        path: SetupURLProps.roles.addRole,
        name: "New Role",
        mini: "B",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
      {
        partCode: ["AS-ROL-RVIW"],
        component: RoleView,
        path: SetupURLProps.roles.viewRole,
        name: "View Role",
        mini: "B",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
      {
        partCode: ["AS-ROL-REDT"],
        component: RoleEdit,
        path: SetupURLProps.roles.editRole,
        name: "Edit Role",
        mini: "B",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
      {
        collapse: true,
        partCode: null,
        component: null,
        path: SetupURLProps.siteOwners.home,
        name: "Site Owners",
        state: "nav-setup-sowner",
        mini: "B",
        icon: "mdi mdi-stop-circle",
        navDisplay: true,
        exactPath: true,
        subchild: [
          {
            partCode: ["AS-SOW-CPAD", "AS-SOW-CPVW", "AS-SOW-CPED"],
            component: SiteOwnerCompanyList,
            path: SetupURLProps.siteOwners.listCompany,
            name: "Companies",
            icon: "mdi mdi-checkbox-blank-outline",
            navDisplay: true,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-CPAD"],
            component: SiteOwnerCompanyAdd,
            path: SetupURLProps.siteOwners.addCompany,
            name: "Add Company",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-CPVW"],
            component: SiteOwnerCompanyView,
            path: SetupURLProps.siteOwners.viewCompany,
            name: "View Company",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-CPED"],
            component: SiteOwnerCompanyEdit,
            path: SetupURLProps.siteOwners.editCompany,
            name: "Edit Company",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-DVAD", "AS-SOW-DVVW", "AS-SOW-DVED"],
            component: SiteOwnerDivisionList,
            path: SetupURLProps.siteOwners.listDivision,
            name: "Divisions",
            icon: "mdi mdi-checkbox-blank-outline",
            navDisplay: true,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-DVAD"],
            component: SiteOwnerDivisionAdd,
            path: SetupURLProps.siteOwners.addDivision,
            name: "Add Division",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-DVVW"],
            component: SiteOwnerDivisionView,
            path: SetupURLProps.siteOwners.viewDivision,
            name: "View Division",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-DVED"],
            component: SiteOwnerDivisionEdit,
            path: SetupURLProps.siteOwners.editDivision,
            name: "Edit Division",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },

          {
            partCode: ["AS-SOW-STAD", "AS-SOW-STVW", "AS-SOW-STED"],
            component: SiteOwnerSiteList,
            path: SetupURLProps.siteOwners.listSite,
            name: "Sites",
            icon: "mdi mdi-checkbox-blank-outline",
            navDisplay: true,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-STAD"],
            component: SiteOwnerSiteAdd,
            path: SetupURLProps.siteOwners.addSite,
            name: "Add Site",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-SSVA"],
            component: SitesImport,
            path: SetupURLProps.siteOwners.sitesImport,
            name: "Sites Import",
            icon: "mdi mdi-stop-circle",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-STVW"],
            component: SiteOwnerSiteView,
            path: SetupURLProps.siteOwners.viewSite,
            name: "View Site",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-STED"],
            component: SiteOwnerSiteEdit,
            path: SetupURLProps.siteOwners.editSite,
            name: "Edit Site",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SOW-STUA"],
            component: SiteUserAccess,
            path: SetupURLProps.siteOwners.userAccess,
            name: "Site User Access",
            mini: "B",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
        ],
      },
      {
        collapse: true,
        partCode: null,
        component: null,
        path: SetupURLProps.serviceProviders.home,
        name: "Service Providers",
        state: "nav-setup-sprovider",
        mini: "B",
        icon: "mdi mdi-stop-circle",
        navDisplay: true,
        exactPath: true,
        subchild: [
          {
            partCode: ["AS-SPV-CPAD", "AS-SPV-CPVW", "AS-SPV-CPED"],
            component: ServiceProviderCompanyList,
            path: SetupURLProps.serviceProviders.listCompany,
            name: "Companies",
            icon: "mdi mdi-checkbox-blank-outline",
            navDisplay: true,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-CPAD"],
            component: ServiceProviderCompanyAdd,
            path: SetupURLProps.serviceProviders.addCompany,
            name: "Add Company",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-CPVW"],
            component: ServiceProviderCompanyView,
            path: SetupURLProps.serviceProviders.viewCompany,
            name: "View Company",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-CPED"],
            component: ServiceProviderCompanyEdit,
            path: SetupURLProps.serviceProviders.editCompany,
            name: "Edit Company",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-DVAD", "AS-SPV-DVVW", "AS-SPV-DVED"],
            component: ServiceProviderDivisionList,
            path: SetupURLProps.serviceProviders.listDivision,
            name: "Divisions",
            icon: "mdi mdi-checkbox-blank-outline",
            navDisplay: true,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-DVAD"],
            component: ServiceProviderDivisionAdd,
            path: SetupURLProps.serviceProviders.addDivision,
            name: "Add Division",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-DVVW"],
            component: ServiceProviderDivisionView,
            path: SetupURLProps.serviceProviders.viewDivision,
            name: "View Division",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-DVED"],
            component: ServiceProviderDivisionEdit,
            path: SetupURLProps.serviceProviders.editDivision,
            name: "Edit Division",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },

          {
            partCode: ["AS-SPV-STAD", "AS-SPV-STVW", "AS-SPV-STED"],
            component: ServiceProviderSiteList,
            path: SetupURLProps.serviceProviders.listSite,
            name: "Sites",
            icon: "mdi mdi-checkbox-blank-outline",
            navDisplay: true,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-STAD"],
            component: serviceProviderSiteAdd,
            path: SetupURLProps.serviceProviders.addSite,
            name: "Add Site",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-SSVA"],
            component: SitesImport,
            path: SetupURLProps.serviceProviders.sitesImport,
            name: "Sites Import",
            icon: "mdi mdi-stop-circle",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-STVW"],
            component: ServiceProviderSiteView,
            path: SetupURLProps.serviceProviders.viewSite,
            name: "View Site",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-STED"],
            component: ServiceProviderSiteEdit,
            path: SetupURLProps.serviceProviders.editSite,
            name: "Edit Site",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
          {
            partCode: ["AS-SPV-STUA"],
            component: serviceProviderUserAccess,
            path: SetupURLProps.serviceProviders.userAccess,
            name: "Site User Access",
            mini: "B",
            icon: "mdi mdi-ray-start-arrow",
            navDisplay: false,
            exactPath: true,
          },
        ],
      },
      {
        partCode: ["AS-USR-UADD", "AS-USR-UVIW", "AS-USR-UEDT", "AS-USR-USTA"],
        component: UserList,
        path: SetupURLProps.users.listUser,
        name: "Users",
        mini: "B",
        icon: "mdi mdi-stop-circle",
        navDisplay: true,
        exactPath: true,
      },
      {
        partCode: ["AS-USR-UADD"],
        component: UserAdd,
        path: SetupURLProps.users.addUser,
        name: "New User",
        mini: "B",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
      {
        partCode: ["AS-USR-UVIW"],
        component: UserView,
        path: SetupURLProps.users.viewUser,
        name: "View User",
        mini: "B",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
      {
        partCode: ["AS-USR-UEDT"],
        component: UserEdit,
        path: SetupURLProps.users.editUser,
        name: "Edit User",
        mini: "B",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
      {
        partCode: ["AS-USR-USTA"],
        component: UserSiteAccess,
        path: SetupURLProps.users.siteAccess,
        name: "User Site Access",
        mini: "B",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
    ],
  },
  {
    collapse: true,
    partCode: null,
    component: null,
    path: "/config",
    name: "Configuration",
    state: "nav-config",
    icon: "mdi mdi-wrench",
    navDisplay: true,
    exactPath: true,
    child: [
      {
        partCode: ["AT-DVC-DONB"],
        component: Onboarding,
        path: ConfigURLProps.onboarding,
        name: "Onboarding",
        icon: "mdi mdi-stop-circle",
        navDisplay: true,
        exactPath: true,
      },
      {
        partCode: ["AT-DVC-DVIW", "AT-DVC-DEDT"],
        component: Devices,
        path: ConfigURLProps.devices,
        mini: "B",
        name: "Devices",
        icon: "mdi mdi-stop-circle",
        navDisplay: true,
        exactPath: true,
      },
      {
        partCode: ["AT-DVC-DVIW"],
        component: DeviceView,
        path: ConfigURLProps.viewDevice,
        name: "View Device",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
      {
        partCode: ["AT-DVC-DEDT"],
        component: DeviceEdit,
        path: ConfigURLProps.editDevice,
        name: "Edit Device",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
      {
        // partCode: ["AS-ROL-RADD"],
        component: ErrorCodes,
        path: ConfigURLProps.errorCodes,
        name: "ErrorCodes",
        mini: "B",
        icon: "mdi mdi-ray-start-arrow",
        navDisplay: false,
        exactPath: true,
      },
    ]
  },
  {
    collapse: true,
    partCode: null,
    component: null,
    name: "Reports",
    state: "nav-reports",
    icon: "mdi mdi-wrench",
    navDisplay: false,
    exactPath: true,
    child: [
      {
        partCode: ["AT-DVC-DVIW", "CP-SIT-SVIW"],
        component: CurrentMessageStatus,
        path: ReportsURLProps.currentMessageStatus,
        name: "CurrentMessageStatus",
        icon: "mdi mdi-stop-circle",
        navDisplay: true,
        exactPath: true,
      },
      {
        partCode: ["AT-DVC-DVIW", "CP-SIT-SVIW"],
        component: CurrentMessageSizeReport,
        path: ReportsURLProps.CurrentMessageSizeReport,
        name: "CurrentMessageSizeReport",
        icon: "mdi mdi-stop-circle",
        navDisplay: true,
        exactPath: true,
      }
    ]
  },
];
export default AppRoutes;
