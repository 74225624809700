import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Label,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Table,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import WithLoading from "../../core/helpers/with-loading";
import ConfirmModal from "../../core/helpers/confirm-modal";
import GeoAddressSearchField from "../../core/helpers/geo-address-search-field";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import { SetupURLProps } from "../settings/setup-urls";
import { SetupConstants } from "../settings/setup-constants";
import { SetupValidations } from "../settings/setup-validations";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { AppDataProps } from "../../core/settings/app-data";
import { UserMsgResProps } from "../messages/user-properties";
import { manageError } from "../../core/actions/common-actions";
import {
  isPagePartPrivileged,
  getProfile,
} from "../../core/actions/identity-actions";
import {
  getSiteIQOrganizations,
  getSiteOwnerOrganizations,
  getServiceProviderOrganizations,
} from "../actions/organization-actions";
import { getRoles, getRoleParts } from "../actions/role-actions";
import { getUser, editUser, checkAvailability } from "../actions/user-actions";
import Switch from "react-switch";

class UserEdit extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _helpers = null;
  _pcUserEdit = "AS-USR-UEDT";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isRolePartsDataFetched: true,
      isOpenConfirmModal: false,
      isOpenRoleViewModal: false,
      formInputFields: null,
      user: null,
      organizations: null,
      roles: null,
      roleData: null,
      profile: null,
      tempUserObj: null,
      isOpenDisableModal: false,
      switchChecked: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    const userId = this.props.match.params.uid;
    await this.loadPageData(userId);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const userId = this.props.match.params.uid;
    if (userId !== prevProps.match.params.uid) {
      PageUtils.scrollToTop();
      await this.loadPageData(userId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async (userId) => {
    if (
      userId === null ||
      userId === undefined ||
      userId !== parseInt(userId).toString()
    )
      this.props.history.push(SetupURLProps.users.listUser);

    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ user: null });
    await this.setStateAsync({ profile: null });
    await this.checkPartPrivilege();
    await getUser(userId, this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ user: res.data.result });

          await this.setStateAsync({
            formInputFields: await this.initFormInputFields(),
          });
        } else {
          this.props.history.push(SetupURLProps.users.listUser);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await getProfile(this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ profile: res.data.result });
        } else {
          this.props.history.push(AppURLProps.home);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await this.loadOrganizations(this.state.user.userType);
    await this.loadRoles(this.state.user.userType);
    await this.setStateAsync({ isPageDataFetched: true });
  };

  loadOrganizations = async (userType) => {
    const queryData = "";

    if (userType === SetupConstants.organizations.COMPANY_TYPE_SITEIQ) {
      await getSiteIQOrganizations(queryData, this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            if (res.data.records.length > 0) {
              await this.setStateAsync({ organizations: res.data.records });
            }
          } else {
            await manageError(res, this.props.history);
          }
        })
        .catch(async (err) => {
          await manageError(err, this.props.history);
        });
    } else if (
      userType === SetupConstants.organizations.COMPANY_TYPE_SITEOWNER
    ) {
      await getSiteOwnerOrganizations(queryData, this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            if (res.data.records.length > 0) {
              await this.setStateAsync({ organizations: res.data.records });
            }
          } else {
            await manageError(res, this.props.history);
          }
        })
        .catch(async (err) => {
          await manageError(err, this.props.history);
        });
    } else if (
      userType === SetupConstants.organizations.COMPANY_TYPE_SERVICEPROVIDER
    ) {
      await getServiceProviderOrganizations(queryData, this._cancelToken)
        .then(async (res) => {
          if (
            res &&
            res.status === AppConfigProps.httpStatusCode.ok &&
            res.data &&
            res.data.records
          ) {
            if (res.data.records.length > 0) {
              await this.setStateAsync({ organizations: res.data.records });
            }
          } else {
            await manageError(res, this.props.history);
          }
        })
        .catch(async (err) => {
          await manageError(err, this.props.history);
        });
    }
  };

  loadRoles = async (roleType) => {
    await this.setStateAsync({ roles: null });
    const queryData = "roleType=" + roleType;
    await getRoles(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ roles: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  loadRoleParts = async (roleId) => {
    await this.setStateAsync({ isRolePartsDataFetched: false });
    await this.setStateAsync({ roleData: null });
    const queryData = "roleId=" + roleId;
    await getRoleParts(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records &&
          res.data.result
        ) {
          let roleData = res.data.result;
          if (res.data.records.length > 0) {
            roleData.parts = res.data.records;
            await this.setStateAsync({ roleData: roleData });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await this.setStateAsync({ isRolePartsDataFetched: true });
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcUserEdit}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  initFormInputFields = () => {
    if (this.state?.user?.emailNotificationStatus === 1) {
      this.setStateAsync({ switchChecked: true });
    } else if (this.state?.user?.emailNotificationStatus === 0) {
      this.setStateAsync({ switchChecked: false });
    }
    return {
      organizationId: this.state.user.organizationId,
      roleId: this.state.user.roleId,
      username: this.state.user.username,
      firstName: this.state.user.firstName,
      lastName: this.state.user.lastName,
      email: this.state.user.email,
      mobile: this.state.user.mobile ? this.state.user.mobile : "",
      addressAutocomplete: "",
      streetAddress1: this.state.user.streetAddress1
        ? this.state.user.streetAddress1
        : "",
      streetAddress2: this.state.user.streetAddress2
        ? this.state.user.streetAddress2
        : "",
      city: this.state.user.city ? this.state.user.city : "",
      state: this.state.user.state ? this.state.user.state : "",
      country: this.state.user.country ? this.state.user.country : "",
      zipCode: this.state.user.zipCode ? this.state.user.zipCode : "",
    };
  };

  populateAddressFields = async (place, formik) => {
    if (place && formik) {
      const address = PageUtils.getGeoAddressFieldsData(place);
      if (address) {
        formik.setFieldValue("streetAddress1", address.streetAddress1);
        formik.setFieldValue("streetAddress2", address.streetAddress2);
        formik.setFieldValue("city", address.city);
        formik.setFieldValue("state", address.state);
        formik.setFieldValue("country", address.country);
        formik.setFieldValue("zipCode", address.zipCode);
      }
      this.setStateAsync({ isAddressFieldsPopulated: true });
    }
  };

  formValidationSchema = () => {
    const schema = {
      firstName: Yup.string()
        .trim()
        .required(UserMsgResProps.body.notification.error.firstName.empty)
        .matches(
          SetupValidations.users.firstName,
          UserMsgResProps.body.notification.error.firstName.invalid
        ),
      lastName: Yup.string()
        .trim()
        .required(UserMsgResProps.body.notification.error.lastName.empty)
        .matches(
          SetupValidations.users.lastName,
          UserMsgResProps.body.notification.error.lastName.invalid
        ),
      email: Yup.string()
        .trim()
        .required(UserMsgResProps.body.notification.error.email.empty)
        .email(UserMsgResProps.body.notification.error.email.invalid)
        .test(
          "isEmailAvailable",
          UserMsgResProps.body.notification.error.email.duplicate,
          async (value) => {
            if (
              this.state.user &&
              value &&
              this.state.user.email.toLowerCase() !== value.toLowerCase()
            ) {
              const queryData = "?email=" + value;
              return await checkAvailability(queryData, this._cancelToken);
            } else return true;
          }
        ),
      organizationId: Yup.string().required(
        UserMsgResProps.body.notification.error.organization.empty
      ),
      roleId: Yup.string().required(
        UserMsgResProps.body.notification.error.role.empty
      ),
      zipCode: Yup.string().matches(
        SetupValidations.users.zipCode,
        UserMsgResProps.body.notification.error.zipCode.invalid
      ),
      mobile: Yup.string().matches(
        SetupValidations.sites.sitePhone,
        UserMsgResProps.body.notification.error.sitePhone.invalid
      ),
    };
    return schema;
  };

  handleConfirmation = async (values, helpers) => {
    this._helpers = helpers;
    let selOrganization = null;
    if (this.state.organizations) {
      selOrganization = await this.state.organizations.find((org) => {
        return org.organizationId === parseInt(values.organizationId);
      });
    }
    let selRole = null;
    if (this.state.roles) {
      selRole = await this.state.roles.find((role) => {
        return role.roleId === parseInt(values.roleId);
      });
    }
    const userObj = {
      userId: this.state.user.userId,
      organizationId: parseInt(values.organizationId),
      organizationName: selOrganization
        ? selOrganization.organizationTreepath
        : null,
      roleId: parseInt(values.roleId),
      roleName: selRole ? selRole.roleName : null,
      username: this.state.user.username,
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      emailNotificationStatus: this.state.switchChecked ? 1 : 0,
      mobile: values.mobile,
      streetAddress1: values.streetAddress1,
      streetAddress2: values.streetAddress2,
      city: values.city,
      state: values.state,
      country: values.country,
      zipCode: values.zipCode,
      lastModifyTimestamp: this.state.user.modifyTimestamp,
    };

    await this.setStateAsync({ tempUserObj: userObj });
    await this.openConfirmModal();
  };

  handleSubmit = async () => {
    const userObj = this.state.tempUserObj;
    const helpers = this._helpers;
    this.closeConfirmModal();

    if (this._isMounted) {
      await helpers.setSubmitting(true);
    }
    await editUser(userObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = [
            "S",
            UserMsgResProps.body.notification.success.editUser,
          ];
          if (res.data.result) {
            await this.setStateAsync({ user: res.data.result });
            await this.setStateAsync({
              formInputFields: await this.initFormInputFields(),
            });
            helpers.setFieldValue(
              "organizationId",
              this.state.user.organizationId
            );
            helpers.setFieldValue("roleId", this.state.user.roleId);
          }
          if (this._isMounted) {
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          UserMsgResProps.body.notification.error.message,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);

          if (err.data && err.data.errors && err.data.errors.length > 0) {
            const errors = err.data.errors;
            errors.forEach((error) => {
              if (error.element && error.message && error.location === "body") {
                helpers.setFieldError(error.element, error.message);
              }
            });
          }
          PageUtils.scrollToTop();
        }
      });
    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  closeRoleViewModal = async () => {
    await this.setStateAsync({
      isOpenRoleViewModal: false,
    });
  };

  openRoleViewModal = async () => {
    await this.setStateAsync({
      isOpenRoleViewModal: true,
    });
  };

  handleNotificationChange = async () => {
    await this.setStateAsync({ switchChecked: !this.state.switchChecked });
    await this.closeNotificationModal();
  };

  closeNotificationModal = async () => {
    await this.setStateAsync({
      isOpenDisableModal: false,
    });
  };

  openNotificationModal = async () => {
    await this.setStateAsync({
      isOpenDisableModal: true,
    });
  };

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            {this.state.user ? (
              <>
                <Card className="sram-page-form">
                  <CardTitle>
                    <span>{UserMsgResProps.body.content.editUser}</span>
                    <span className="float-right">
                      {PageDisplay.showGoBackLink(
                        "L",
                        SetupURLProps.users.listUser,
                        this.props.history
                      )}
                    </span>
                  </CardTitle>
                  <CardBody>
                    <Formik
                      initialValues={this.state.formInputFields}
                      validationSchema={Yup.object().shape(
                        this.formValidationSchema()
                      )}
                      onSubmit={(values, helpers) =>
                        this.handleConfirmation(values, helpers)
                      }
                    >
                      {(formik) => (
                        <Form id="userForm" {...formEvent}>
                          <div className="sram-form-main">
                            <div>
                              {formik.status &&
                              Array.isArray(formik.status) &&
                              formik.status.length === 2 &&
                              (formik.status[0] === "E" ||
                                formik.status[0] === "S") ? (
                                <Row>
                                  <Col xs={12}>
                                    {formik.status[0] === "E"
                                      ? PageDisplay.showErrorNotification(
                                          formik.status[1]
                                        )
                                      : PageDisplay.showSuccessNotification(
                                          formik.status[1]
                                        )}
                                  </Col>
                                </Row>
                              ) : null}
                            </div>
                            <div>
                              <div className="sram-view-data">
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    {PageDisplay.getFormFieldLabelValue(
                                      UserMsgResProps.body.form.username.label,
                                      this.state.user.username
                                    )}
                                  </Col>

                                  <Col sm={12} md={6} lg={6}>
                                    <Label className="d-block font-light">
                                      {
                                        UserMsgResProps.body.form.username
                                          .notification
                                      }
                                    </Label>
                                    <Label className="d-flex font-medium">
                                      <Switch
                                        onChange={() =>
                                          this.openNotificationModal()
                                        }
                                        checked={this.state.switchChecked}
                                        height={18}
                                        width={40}
                                        className="sram-react-switch ml-3"
                                      />
                                    </Label>
                                  </Col>
                                </Row>
                              </div>
                              <div>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="firstName">
                                        {
                                          UserMsgResProps.body.form.firstName
                                            .label
                                        }{" "}
                                        *
                                      </Label>
                                      <Field
                                        name="firstName"
                                        type="text"
                                        maxLength="50"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.firstName,
                                          formik.touched.firstName
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "firstName"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="lastName">
                                        {
                                          UserMsgResProps.body.form.lastName
                                            .label
                                        }{" "}
                                        *
                                      </Label>
                                      <Field
                                        name="lastName"
                                        type="text"
                                        maxLength="50"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.lastName,
                                          formik.touched.lastName
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "lastName"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="email">
                                        {UserMsgResProps.body.form.email.label}{" "}
                                        *
                                      </Label>
                                      <Field
                                        name="email"
                                        type="text"
                                        maxLength="100"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.email,
                                          formik.touched.email
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "email"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="mobile">
                                        {UserMsgResProps.body.form.mobile.label}
                                      </Label>
                                      <Field
                                        name="mobile"
                                        type="text"
                                        maxLength="12"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.mobile,
                                          formik.touched.mobile
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "mobile"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12}>
                                    <FormGroup>
                                      <Label for="organizationId">
                                        {
                                          UserMsgResProps.body.form.organization
                                            .label
                                        }{" "}
                                        *
                                      </Label>
                                      <Field
                                        name="organizationId"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.organizationId,
                                          formik.touched.organizationId
                                        )}
                                        disabled={
                                          this.state.profile.userId ===
                                          this.state.user.userId
                                        }
                                      >
                                        <option value=""></option>
                                        {this.state.organizations &&
                                        this.state.organizations.length > 0
                                          ? this.state.organizations.map(
                                              (organization, index) => {
                                                if (
                                                  organization.entityType ===
                                                    SetupConstants.organizations
                                                      .ENTITY_TYPE_COMPANY ||
                                                  organization.entityType ===
                                                    SetupConstants.organizations
                                                      .ENTITY_TYPE_DIVISION
                                                ) {
                                                  return (
                                                    <option
                                                      value={
                                                        organization.organizationId
                                                      }
                                                      key={`key-org-option-${index}`}
                                                    >
                                                      {
                                                        organization.organizationTreepath
                                                      }
                                                    </option>
                                                  );
                                                } else {
                                                  return null;
                                                }
                                              }
                                            )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "organizationId"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={6}>
                                    <FormGroup>
                                      <Label for="roleId">
                                        {UserMsgResProps.body.form.role.label} *
                                      </Label>
                                      <InputGroup>
                                        <Field
                                          name="roleId"
                                          as="select"
                                          className={PageDisplay.getFormikFieldClassName(
                                            formik.errors.roleId,
                                            formik.touched.roleId
                                          )}
                                          disabled={
                                            this.state.profile.userId ===
                                            this.state.user.userId
                                          }
                                        >
                                          <option value=""></option>
                                          {this.state.roles &&
                                          this.state.roles.length > 0
                                            ? this.state.roles.map(
                                                (role, index) => {
                                                  return (
                                                    <option
                                                      value={role.roleId}
                                                      key={`key-role-option-${index}`}
                                                    >
                                                      {role.roleName}
                                                    </option>
                                                  );
                                                }
                                              )
                                            : null}
                                        </Field>
                                        <InputGroupAddon
                                          addonType="append"
                                          style={{ cursor: "pointer" }}
                                          onClick={async () => {
                                            if (
                                              formik.values.roleId &&
                                              formik.values.roleId !== ""
                                            ) {
                                              this.openRoleViewModal();
                                              await this.loadRoleParts(
                                                formik.values.roleId
                                              );
                                            }
                                          }}
                                          title={
                                            UserMsgResProps.body.content
                                              .viewRoleAccessPrivilege
                                          }
                                        >
                                          <InputGroupText>
                                            <i
                                              className={`d-inline far fa-dot-circle sram-icon-view sram-icon-small`}
                                            ></i>
                                          </InputGroupText>
                                        </InputGroupAddon>
                                      </InputGroup>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "roleId"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={12} lg={12} className="pt-3">
                                    <GeoAddressSearchField
                                      callback={this.populateAddressFields}
                                      formik={formik}
                                    />
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="streetAddress1">
                                        {
                                          UserMsgResProps.body.form
                                            .streetAddress1.label
                                        }
                                      </Label>
                                      <Field
                                        name="streetAddress1"
                                        type="text"
                                        maxLength="100"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.streetAddress1,
                                          formik.touched.streetAddress1
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "streetAddress1"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="streetAddress2">
                                        {
                                          UserMsgResProps.body.form
                                            .streetAddress2.label
                                        }
                                      </Label>
                                      <Field
                                        name="streetAddress2"
                                        type="text"
                                        maxLength="100"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.streetAddress2,
                                          formik.touched.streetAddress2
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "streetAddress2"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="city">
                                        {UserMsgResProps.body.form.city.label}
                                      </Label>
                                      <Field
                                        name="city"
                                        type="text"
                                        maxLength="50"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.city,
                                          formik.touched.city
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "city"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="state">
                                        {UserMsgResProps.body.form.state.label}
                                      </Label>
                                      <Field
                                        name="state"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.state,
                                          formik.touched.state
                                        )}
                                      >
                                        {" "}
                                        <option value=""></option>
                                        {AppDataProps.address.states &&
                                        AppDataProps.address.states.length > 0
                                          ? AppDataProps.address.states.map(
                                              (state, index) => {
                                                return (
                                                  <option
                                                    value={state}
                                                    key={`key-addr-state-${index}`}
                                                  >
                                                    {state}
                                                  </option>
                                                );
                                              }
                                            )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "state"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="country">
                                        {
                                          UserMsgResProps.body.form.country
                                            .label
                                        }
                                      </Label>
                                      <Field
                                        name="country"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.country,
                                          formik.touched.country
                                        )}
                                      >
                                        <option value=""></option>
                                        {AppDataProps.address.countries &&
                                        AppDataProps.address.countries.length >
                                          0
                                          ? AppDataProps.address.countries.map(
                                              (country, index) => {
                                                return (
                                                  <option
                                                    value={country}
                                                    key={`key-addr-country-${index}`}
                                                  >
                                                    {country}
                                                  </option>
                                                );
                                              }
                                            )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "country"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="zipCode">
                                        {
                                          UserMsgResProps.body.form.zipCode
                                            .label
                                        }
                                      </Label>
                                      <Field
                                        name="zipCode"
                                        type="text"
                                        maxLength="5"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.zipCode,
                                          formik.touched.zipCode
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "zipCode"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </div>
                          <div className="sram-form-foot">
                            {PageDisplay.showSaveButton(formik.isSubmitting)}
                            {PageDisplay.showCancelButton(this.props.history)}
                          </div>
                          <FormFieldFocusError />
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
                <>
                  {this.state.tempUserObj &&
                  this.state.organizations &&
                  this.state.roles ? (
                    <ConfirmModal
                      isOpenConfirmModal={this.state.isOpenConfirmModal}
                      closeConfirmModal={this.closeConfirmModal}
                      handleSubmit={this.handleSubmit}
                    >
                      <div>
                        {this.state.user.email !==
                          this.state.tempUserObj.email ||
                        this.state.user.organizationId !==
                          this.state.tempUserObj.organizationId ||
                        this.state.user.roleId !==
                          this.state.tempUserObj.roleId ? (
                          <>
                            <div>
                              <Label>
                                {
                                  UserMsgResProps.body.notification.confirmation
                                    .editUserTitle1
                                }
                              </Label>
                            </div>
                            <div>
                              <Table size="sm" className="border-bottom">
                                <tbody>
                                  {this.state.user.email !==
                                  this.state.tempUserObj.email ? (
                                    <tr>
                                      <td>
                                        {UserMsgResProps.body.form.email.label}
                                      </td>
                                      <td className="font-medium">
                                        {this.state.tempUserObj.email}
                                      </td>
                                    </tr>
                                  ) : null}
                                  {this.state.user.organizationId !==
                                  this.state.tempUserObj.organizationId ? (
                                    <tr>
                                      <td>
                                        {
                                          UserMsgResProps.body.form.organization
                                            .label
                                        }
                                      </td>
                                      <td className="font-medium">
                                        {
                                          this.state.tempUserObj
                                            .organizationName
                                        }
                                      </td>
                                    </tr>
                                  ) : null}
                                  {this.state.user.roleId !==
                                  this.state.tempUserObj.roleId ? (
                                    <tr>
                                      <td>
                                        {UserMsgResProps.body.form.role.label}
                                      </td>
                                      <td className="font-medium">
                                        {this.state.tempUserObj.roleName}
                                      </td>
                                    </tr>
                                  ) : null}
                                </tbody>
                              </Table>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <Label>
                                {
                                  UserMsgResProps.body.notification.confirmation
                                    .editUserTitle2
                                }
                              </Label>
                            </div>
                          </>
                        )}
                      </div>
                    </ConfirmModal>
                  ) : null}
                </>
                <Modal
                  isOpen={this.state.isOpenRoleViewModal}
                  toggle={this.closeRoleViewModal}
                  size="lg"
                  backdrop="static"
                  centered={false}
                  className="sram-modal-content"
                >
                  <ModalHeader toggle={this.closeRoleViewModal}>
                    {UserMsgResProps.body.content.roleAccessPrivilegeDetails}
                  </ModalHeader>
                  <ModalBody>
                    <WithLoading
                      isPageDataFetched={this.state.isRolePartsDataFetched}
                      type="page"
                      size="md"
                    >
                      <div>
                        {this.state.roleData ? (
                          <>
                            <div className="pb-2 font-medium">
                              {this.state.roleData.roleName}
                            </div>
                            {this.state.roleData.parts &&
                            this.state.roleData.parts.length > 0 ? (
                              <>
                                {this.state.roleData.parts.map(
                                  (module, index1) => {
                                    if (module.moduleCode !== "AC") {
                                      return (
                                        <div
                                          className="mt-1 mb-4 sram-border-blue-3"
                                          key={`key-role-m-${index1}`}
                                        >
                                          <div className="p-2 pl-3 sram-bg-blue-2 font-medium">
                                            {module.moduleName}
                                          </div>
                                          <Row form className="p-2">
                                            {module.features &&
                                            module.features.length > 0 ? (
                                              <>
                                                {module.features.map(
                                                  (feature, index2) => {
                                                    return (
                                                      <Col
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                        key={`key-role-f-${index2}`}
                                                        className="mt-1 mb-3 "
                                                      >
                                                        <div className="sram-bg-blue-1 px-2 py-1">
                                                          {feature.featureName}
                                                        </div>
                                                        <Row
                                                          form
                                                          className="p-2"
                                                        >
                                                          {feature.actions &&
                                                          feature.actions
                                                            .length > 0 ? (
                                                            <>
                                                              {feature.actions.map(
                                                                (
                                                                  action,
                                                                  index3
                                                                ) => {
                                                                  return (
                                                                    <Col
                                                                      sm={12}
                                                                      md={12}
                                                                      lg={12}
                                                                      key={`key-role-a-${index3}`}
                                                                    >
                                                                      <Label>
                                                                        {
                                                                          action.actionName
                                                                        }
                                                                      </Label>
                                                                    </Col>
                                                                  );
                                                                }
                                                              )}
                                                            </>
                                                          ) : null}
                                                        </Row>
                                                      </Col>
                                                    );
                                                  }
                                                )}
                                              </>
                                            ) : null}
                                          </Row>
                                        </div>
                                      );
                                    } else {
                                      return null;
                                    }
                                  }
                                )}
                              </>
                            ) : null}
                          </>
                        ) : (
                          <>
                            {PageDisplay.showWarningNotification(
                              UserMsgResProps.body.content.noDataFound
                            )}
                          </>
                        )}
                      </div>
                    </WithLoading>
                  </ModalBody>
                  <ModalFooter className="m-1 p-2">
                    <Button
                      type="button"
                      className="sram-btn-white mx-2"
                      onClick={this.closeRoleViewModal}
                    >
                      {AppMsgResProps.body.form.close.label}
                    </Button>
                  </ModalFooter>
                </Modal>
              </>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  UserMsgResProps.body.content.noUserFound
                )}
              </>
            )}

            {this.state.isOpenDisableModal ? (
              <ConfirmModal
                isOpenConfirmModal={this.state.isOpenDisableModal}
                closeConfirmModal={this.closeNotificationModal}
                handleSubmit={this.handleNotificationChange}
              >
                <div>
                  <div>
                    <Label>
                      {this.state?.switchChecked
                        ? UserMsgResProps.body.form.username.disableNotification
                        : UserMsgResProps.body.form.username.enableNotification}
                    </Label>
                  </div>
                </div>
              </ConfirmModal>
            ) : null}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default UserEdit;
