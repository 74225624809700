import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Label,
  InputGroup,
} from "reactstrap";
import _ from "lodash";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import { SetupURLProps } from "../settings/setup-urls";
import { SetupValidations } from "../settings/setup-validations";
import { SetupConstants } from "../settings/setup-constants";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { RoleMsgResProps } from "../messages/role-properties";
import { manageError } from "../../core/actions/common-actions";
import { isPagePartPrivileged } from "../../core/actions/identity-actions";
import { getRole } from "../actions/role-actions";
import { listParts } from "../actions/app-part-actions";

class RoleView extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcRoleView = "AS-ROL-RVIW";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      role: null,
      selActionId: null,
      appPartsAll: null,
      appPartsAvailable: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    const roleId = this.props.match.params.rid;
    await this.loadPageData(roleId);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const roleId = this.props.match.params.rid;
    if (roleId !== prevProps.match.params.rid) {
      PageUtils.scrollToTop();
      await this.loadPageData(roleId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async (roleId) => {
    if (
      roleId === null ||
      roleId === undefined ||
      roleId !== parseInt(roleId).toString()
    )
      this.props.history.push(SetupURLProps.roles.listRole);

    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ role: null });
    await this.checkPartPrivilege();
    await getRole(roleId, this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ role: res.data.result });
          if (this.state.role) {
            const queryData = null;
            await listParts(queryData, this._cancelToken)
              .then(async (res) => {
                if (
                  res &&
                  res.status === AppConfigProps.httpStatusCode.ok &&
                  res.data &&
                  res.data.records
                ) {
                  if (res.data.records.length > 0) {
                    await this.setStateAsync({ appPartsAll: res.data.records });
                    await this.loadParts(this.state.role.roleType);
                  }
                } else {
                  await manageError(res, this.props.history);
                }
              })
              .catch(async (err) => {
                await manageError(err, this.props.history);
              });
          }
          if (
            this.state.role &&
            this.state.role.actions &&
            this.state.role.actions.length > 0
          ) {
            let selActs = [];
            this.state.role.actions.forEach((action) => {
              selActs.push(action.actionId);
            });
            if (selActs.length > 0)
              await this.setStateAsync({ selActionId: selActs });
          }
        } else {
          this.props.history.push(SetupURLProps.roles.listRole);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  loadParts = async (roleType) => {
    await this.setStateAsync({ appPartsAvailable: null });
    let appParts = _.cloneDeep(this.state.appPartsAll); //JSON.parse(JSON.stringify(this.state.appPartsAll));
    let selActionId = [];
    let partModules = [];

    await appParts.forEach(async (module) => {
      if (module && module.features && module.features.length > 0) {
        let partFeatures = [];
        await module.features.forEach(async (feature) => {
          if (feature && feature.actions && feature.actions.length > 0) {
            let partActions = [];
            await feature.actions.forEach(async (action) => {
              if (action) {
                let actionAvailable = false;
                if (roleType === SetupConstants.roles.ROLE_TYPE_SITEIQ) {
                  actionAvailable = true;
                } else if (
                  roleType === SetupConstants.roles.ROLE_TYPE_SITEOWNER
                ) {
                  if (
                    SetupValidations.roles.PartCodesNotApplicable.siteOwner.includes(
                      module.moduleCode +
                        "-" +
                        feature.featureCode +
                        "-" +
                        action.actionCode
                    ) === false
                  ) {
                    actionAvailable = true;
                  }
                } else if (
                  roleType === SetupConstants.roles.ROLE_TYPE_SERVICEPROVIDER
                ) {
                  if (
                    SetupValidations.roles.PartCodesNotApplicable.serviceProvider.includes(
                      module.moduleCode +
                        "-" +
                        feature.featureCode +
                        "-" +
                        action.actionCode
                    ) === false
                  ) {
                    actionAvailable = true;
                  }
                }
                if (actionAvailable === true) {
                  let partAction = action;
                  partActions.push(partAction);
                  if (feature.defaultStatus === 1)
                    selActionId.push(action.actionId);
                }
              }
            });
            if (partActions.length > 0) {
              let partFeature = feature;
              partFeature.actions = [...partActions];
              partFeatures.push(partFeature);
            }
          }
        });
        if (partFeatures.length > 0) {
          let partModule = module;
          partModule.features = [...partFeatures];
          partModules.push(partModule);
        }
      }
    });
    await this.setStateAsync({ appPartsAvailable: [...partModules] });
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcRoleView}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            {this.state.role ? (
              <Card className="sram-page-view">
                <CardTitle>
                  <span>{RoleMsgResProps.body.content.roleDetails}</span>
                  <span className="float-right">
                    {PageDisplay.showGoBackLink(
                      "L",
                      SetupURLProps.roles.listRole,
                      this.props.history
                    )}
                  </span>
                </CardTitle>
                <CardBody>
                  <div className="sram-view-main">
                    <div className="sram-view-data">
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            RoleMsgResProps.body.form.roleName.label,
                            this.state.role.roleName
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            RoleMsgResProps.body.form.roleType.label,
                            RoleMsgResProps.body.form.roleType.options[
                              this.state.role.roleType
                            ]
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className="mt-3">
                      {this.state.appPartsAvailable &&
                      this.state.appPartsAvailable.length > 0 ? (
                        <>
                          <div className="pb-2">
                            {RoleMsgResProps.body.content.accessPrivileges}
                          </div>
                          {this.state.appPartsAvailable.map(
                            (module, index1) => {
                              return (
                                <div
                                  className="mt-1 mb-4 sram-border-blue-3"
                                  key={`key-role-m-${index1}`}
                                >
                                  <div className="p-2 pl-3 sram-bg-blue-2 font-medium">
                                    {module.moduleName}
                                  </div>
                                  <Row form className="p-2">
                                    {module.features &&
                                    module.features.length > 0 ? (
                                      <>
                                        {module.features.map(
                                          (feature, index2) => {
                                            return (
                                              <Col
                                                sm={12}
                                                md={6}
                                                lg={6}
                                                key={`key-role-f-${index2}`}
                                                className="mt-1 mb-3 "
                                              >
                                                <div className="sram-bg-blue-1 px-2 py-1">
                                                  {feature.featureName}
                                                </div>
                                                <Row form className="p-2">
                                                  {feature.actions &&
                                                  feature.actions.length > 0 ? (
                                                    <>
                                                      {feature.actions.map(
                                                        (action, index3) => {
                                                          return (
                                                            <Col
                                                              sm={12}
                                                              md={12}
                                                              lg={12}
                                                              key={`key-role-a-${index3}`}
                                                            >
                                                              <InputGroup>
                                                                {this.state
                                                                  .selActionId &&
                                                                this.state.selActionId.indexOf(
                                                                  action.actionId
                                                                ) >= 0 ? (
                                                                  <span
                                                                    className="d-inline"
                                                                    style={{
                                                                      width:
                                                                        "25px",
                                                                    }}
                                                                  >
                                                                    <i className="fas fa-check text-primary"></i>
                                                                  </span>
                                                                ) : (
                                                                  <span
                                                                    className="d-inline"
                                                                    style={{
                                                                      width:
                                                                        "25px",
                                                                    }}
                                                                  >
                                                                    <i className="fas fa-times text-secondary"></i>
                                                                  </span>
                                                                )}

                                                                <Label>
                                                                  {
                                                                    action.actionName
                                                                  }
                                                                </Label>
                                                              </InputGroup>
                                                            </Col>
                                                          );
                                                        }
                                                      )}
                                                    </>
                                                  ) : null}
                                                </Row>
                                              </Col>
                                            );
                                          }
                                        )}
                                      </>
                                    ) : null}
                                  </Row>
                                </div>
                              );
                            }
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  RoleMsgResProps.body.content.noRoleFound
                )}
              </>
            )}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default RoleView;
