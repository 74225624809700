export const UserMsgResProps = {
  head: {
    title: {
      userList: "Setup - Users - List",
      userAdd: "Setup - Users - Add",
      userView: "Setup - Users - View",
      userEdit: "Setup - Users - Edit",
      siteName: "Site Name",
      siteId: " GVR ID",
      contracted: "Contracted",
    },
  },
  body: {
    content: {
      users: "Users",
      addUser: "Add User",
      viewUser: "View User Details",
      deleteUser: "Delete User",
      editUser: "Edit User",
      siteAccess: "Site Access Privileges",
      userAccess: "User Access Privileges",
      userDetails: "User Details",
      noUsersFound: "No Users found",
      noUserFound: "User information not found",
      noSitesFound: "No Sites found",
      viewRoleAccessPrivilege: "View Role Access Privilege Details",
      roleAccessPrivilegeDetails: "Role Access Privilege Details",
      noDataFound: "No issues found.",
    },
    form: {
      radio: {
        label: "Access to all sites",
      },
      radio1: {
        label: "Choose sites below",
      },
      userType: {
        label: "User Type",
        options: {
          S: "Admin",
          O: "Site Owner",
          P: "Service Provider",
        },
      },
      organization: {
        label: "Company / Division",
      },
      role: {
        label: "Role",
      },
      username: {
        label: "Username",
        notification: "Email Notification",
        enableNotification:
          "Are you sure you want to enable Email Notifications ?",
        disableNotification:
          "Are you sure you want to disable Email Notifications ?",
        info: {
          header: "Username requirement",
          text:
            "Minimum of 3 characters.\n" +
            "Can contain alphabets, numbers, under score or dot.\n" +
            "Should start with alphabet.\n" +
            "Should end with alphabet or number.\n",
        },
      },
      password: {
        label: "Password",
        info: {
          header: "Password requirement",
          text:
            "Minimum of 8 characters.\n" +
            "Must contain a lower case letter.\n" +
            "Must contain an upper case letter.\n" +
            "Must contain a number.\n" +
            "Must contain a special character form this set:\n" +
            "^ $ * . [ ] { } ( ) ? \" ! @ # % & /  , > < ' : ; | _ ~ `\n\n" +
            "The plus '+', minus '-', equals '=' sign do not meet special character requirement.",
        },
      },
      retypePassword: {
        label: "Retype Password",
      },
      firstName: {
        label: "First Name",
      },
      lastName: {
        label: "Last Name",
      },
      email: {
        label: "Email",
      },
      mobile: {
        label: "Mobile",
      },
      streetAddress1: {
        label: "Street Address 1",
      },
      streetAddress2: {
        label: "Street Address 2",
      },
      city: {
        label: "City",
      },
      state: {
        label: "State",
      },
      country: {
        label: "Country",
      },
      zipCode: {
        label: "ZIP Code",
      },
      site: {
        label: "Site",
      },
    },
    notification: {
      confirmation: {
        addUserTitle:
          "Are you sure you to add the User with following details:",
        addUserNote1: "User Type & Username data cannot be modified later.",
        addUserNote2: "Username data cannot be modified later.",
        editUserTitle1:
          "Are you sure you to update the User with following details:",
        editUserTitle2: "Are you sure you to update the User details ?",
        editUserAccess:
          "Are you sure you to update the User Access Privileges ?",
        disableUserTitle: "Are you sure you want to disable user ?",
        enableUserTitle: "Are you sure you want to enable user ?",
        deleteUserTitle: "Are you sure you want to delete user ?",
      },
      success: {
        addUser: "User is added successfully.",
        editUser: "User is modified successfully.",
        editUserAccess: "User Access Privileges are modified successfully.",
        deleteUser: "User is deleted successfully.",
      },
      error: {
        message: "An error has occured.",
        deleteUser: "An error has occured while deleting the User.",
        organization: {
          empty: "Company / Division is required.",
        },
        role: {
          empty: "Role is required.",
        },
        username: {
          empty: "Username is required.",
          invalid: "Username is not valid.",
          duplicate: "Username already exists.",
        },
        password: {
          empty: "Password is required.",
          invalid: "Password is not valid.",
        },
        retypePassword: {
          empty: "Retype Password is required.",
          invalid: "Password and Retype Password should be same.",
        },
        firstName: {
          empty: "First Name is required.",
          invalid: "First Name is not valid.",
        },
        lastName: {
          empty: "Last Name is required.",
          invalid: "Last Name is not valid.",
        },
        email: {
          empty: "Email Address is required.",
          invalid: "Email Address is not valid.",
          duplicate: "Email Address already exists.",
        },
        mobile: {
          empty: "Mobile Number is required.",
          invalid: "Mobile Number is not valid.",
        },
        zipCode: {
          invalid: "ZIP Code is not valid.",
        },
        sitePhone: {
          invalid: "Phone is not valid.",
        },
      },
    },
  },
};
