import React, { Component } from "react";
import {
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
  FormGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PageUtils from "../../core/helpers/page-utils";
import { AppConfigProps } from "../settings/app-config";
import { AppURLProps } from "../settings/app-urls";
import { AppValidations } from "../settings/app-validations";
import { AppMsgResProps } from "../messages/app-properties";
import { LoginMsgResProps } from "../messages/login-properties";
import { manageError } from "../actions/common-actions";
import {
  loginUser,
  setPassword,
} from "../actions/identity-actions";
import imgLogo from "../../../assets/images/guardianConnect.png";
import AppUtils from "../helpers/app-utils";

class Login extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  constructor(props) {
    super(props);
    this.state = this.initState();
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
  }

  initState = () => {
    return {
      formType: "login",
      authenticationResult: null,
      challengeResult: null,
      challengeUpdateStatus: null,
      loginFormInputFields: this.initLoginFormInputFields(),
      passwordFormInputFields: this.initPasswordFormInputFields(),
    };
  };

  initLoginFormInputFields = () => {
    return {
      loginId: "",
      password: "",
    };
  };

  loginFormValidationSchema = () => {
    const schema = {
      loginId: Yup.string()
        .trim()
        .required(LoginMsgResProps.body.notification.error.loginId.empty),
      password: Yup.string().required(
        LoginMsgResProps.body.notification.error.password.empty
      ),
    };
    return schema;
  };

  handleLoginSubmit = async (values, helpers) => {
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }

    await loginUser(values, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {          
          if (res.data.result.AuthenticationResult) {
            await this.setStateAsync({
              authenticationResult: res.data.result.AuthenticationResult,
            });
            const statusMsg = [
              "S",
              LoginMsgResProps.body.notification.success.loginPass,
            ];
            if (this._isMounted) {
              helpers.setStatus(statusMsg);
            }
            await this.redirectToAppHome();
          } else {
            if (res.data.result.ChallengeName === "NEW_PASSWORD_REQUIRED") {
              if (this._isMounted) {
                helpers.setSubmitting(false);
              }
              await this.setStateAsync({ challengeResult: res.data.result });
              await this.setStateAsync({ formType: "password" });
            }
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch((err) => {
        if (
          err.data &&
          err.data.errors &&
          err.data.errors[0] &&
          err.data.errors[0].message
        ) {
          const statusMsg = ["E", err.data.errors[0].message];
          if (this._isMounted) {
            helpers.setStatus(statusMsg);
          }
        } else {
          if (this._isMounted) {
            const statusMsg = [
              "E",
              LoginMsgResProps.body.notification.error.loginFail,
            ];
            if (this._isMounted) {
              helpers.setStatus(statusMsg);
            }
          }
        }
        if (this._isMounted) {
          helpers.setSubmitting(false);
        }
      });
  };

  redirectToAppHome = async () => {
    if (this.state.authenticationResult) {
      await AppUtils.setIdentityToken(
        this.state.authenticationResult.IdToken,
        this.state.authenticationResult.RefreshToken,
        this.state.authenticationResult.AccessToken
      );
      // TODO: Check if this.props.location is working
      const { from } = this.props.location.state || {
        from: { pathname: AppURLProps.home },
      };
      this.props.history.push(from);
    }
  };

  initPasswordFormInputFields = () => {
    return {
      newPassword: "",
      retypePassword: "",
    };
  };

  passwordFormValidationSchema = () => {
    const schema = {
      newPassword: Yup.string()
        .trim()
        .required(LoginMsgResProps.body.notification.error.newPassword.empty)
        .matches(
          AppValidations.identity.password,
          LoginMsgResProps.body.notification.error.newPassword.invalid
        ),
      retypePassword: Yup.string()
        .trim()
        .required(LoginMsgResProps.body.notification.error.retypePassword.empty)
        .oneOf(
          [Yup.ref("newPassword"), null],
          LoginMsgResProps.body.notification.error.retypePassword.invalid
        ),
    };
    return schema;
  };

  handlePasswordSubmit = async (values, helpers) => {
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }
    const passwordObj = {
      challengeName: this.state.challengeResult.ChallengeName,
      username: this.state.challengeResult.ChallengeParameters.USER_ID_FOR_SRP,
      newPassword: values.newPassword,
      retypePassword: values.retypePassword,
      session: this.state.challengeResult.Session,
    };
    await setPassword(passwordObj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result &&
          res.data.result.AuthenticationResult
        ) {
          await this.setStateAsync({ challengeUpdateStatus: "S" });
          await this.setStateAsync({
            authenticationResult: res.data.result.AuthenticationResult,
          });
        } else {
          await this.setStateAsync({ challengeUpdateStatus: "E" });
        }
      })
      .catch(async (err) => {
        await this.setStateAsync({ challengeUpdateStatus: "E" });
      });
  };

  render() {
    return (
      <>
        <div className="">
          <div className="auth-wrapper d-flex no-block justify-content-center align-items-center">
            <div className="auth-box">
              <div id="loginform">
                <div className="logo">
                  <span className="db">
                    <a href={AppURLProps.home}>
                      <img
                        src={imgLogo}
                        alt={AppMsgResProps.body.content.siteIQ}
                      />
                    </a>
                  </span>
                </div>

                {this.state.formType === "login" ? (
                  <div>
                    <div className="auth-title">
                      {LoginMsgResProps.body.content.login}
                    </div>

                    <div className="auth-form">
                      <Formik
                        initialValues={this.state.loginFormInputFields}
                        validationSchema={Yup.object().shape(
                          this.loginFormValidationSchema()
                        )}
                        onSubmit={(values, helpers) =>
                          this.handleLoginSubmit(values, helpers)
                        }
                      >
                        {(formik) => (
                          <Form id="loginform">
                            <Row
                              form
                              className="mb-2"
                              style={{ height: "35px" }}
                            >
                              <Col xs="12">
                                {formik.status &&
                                Array.isArray(formik.status) &&
                                formik.status.length === 2 &&
                                (formik.status[0] === "E" ||
                                  formik.status[0] === "S") ? (
                                  <Row>
                                    <Col xs={12}>
                                      {formik.status[0] === "E" ? (
                                        <div className={"text-danger"}>
                                          {formik.status[1]}
                                        </div>
                                      ) : (
                                        <div className={"text-success"}>
                                          {formik.status[1]}
                                        </div>
                                      )}
                                    </Col>
                                  </Row>
                                ) : null}
                              </Col>
                            </Row>
                            <Row form>
                              <Col xs="12">
                                <FormGroup style={{ height: "50px" }}>
                                  <InputGroup className="mb-3">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ti-user"></i>
                                      </InputGroupText>
                                    </InputGroupAddon>

                                    <Field
                                      name="loginId"
                                      type="text"
                                      placeholder={
                                        LoginMsgResProps.body.form.loginId.label
                                      }
                                      className={
                                        "form-control" +
                                        (formik.errors.loginId &&
                                        formik.touched.loginId
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="loginId"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col xs="12">
                                <FormGroup style={{ height: "50px" }}>
                                  <InputGroup className="mb-3">
                                    <InputGroupAddon addonType="prepend">
                                      <InputGroupText>
                                        <i className="ti-key"></i>
                                      </InputGroupText>
                                    </InputGroupAddon>
                                    <Field
                                      name="password"
                                      type="password"
                                      placeholder={
                                        LoginMsgResProps.body.form.password
                                          .label
                                      }
                                      className={
                                        "form-control" +
                                        (formik.errors.password &&
                                        formik.touched.password
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name="password"
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </InputGroup>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form className="mt-2 mb-3">
                              <Col xs="12">
                                <button
                                  type="submit"
                                  className="btn btn-block btn-primary auth-btn"
                                  disabled={formik.isSubmitting}
                                >
                                  {LoginMsgResProps.body.form.login.label}
                                  {formik.isSubmitting ? (
                                    <span className="float-right">
                                      <Spinner
                                        size="sm"
                                        color="light"
                                        style={{ marginBottom: "2px" }}
                                      />
                                    </span>
                                  ) : null}
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </Formik>
                    </div>
                    <div className="auth-foot">
                      <RRNavLink to={AppURLProps.recoverPassword}>
                        {LoginMsgResProps.body.content.forgotPassword}
                      </RRNavLink>
                    </div>
                    {/* <div className="auth-foot">
                      {LoginMsgResProps.body.content.newToSiteIQ}&nbsp;
                      <RRNavLink to={AppURLProps.joinNow}>
                        {LoginMsgResProps.body.content.joinNowOrLearnMore}
                      </RRNavLink>
                    </div> */}
                  </div>
                ) : null}

                {this.state.formType === "password" ? (
                  <div>
                    <div className="auth-title">
                      {LoginMsgResProps.body.content.setPassword}
                    </div>
                    <div className="auth-form">
                      <div className="mt-3 font-medium font-14">
                        {LoginMsgResProps.body.content.setPasswordPrefix}{" "}
                        {
                          this.state.challengeResult.ChallengeParameters
                            .USER_ID_FOR_SRP
                        }
                        ,
                      </div>
                      {this.state.challengeUpdateStatus === null ? (
                        <>
                          <div className="mt-2 font-14">
                            {LoginMsgResProps.body.content.setPasswordInfo}
                          </div>
                          <div
                            className="mt-3 font-12 text-info"
                            id="passwordInfo"
                            style={{ cursor: "pointer" }}
                          >
                            {LoginMsgResProps.body.content.passwordRequirement}
                            <i className="fas fa-info-circle font-18 text-secondary ml-2"></i>
                          </div>
                          <UncontrolledPopover
                            trigger="legacy"
                            placement="auto"
                            target="passwordInfo"
                          >
                            <PopoverHeader>
                              {
                                LoginMsgResProps.body.content
                                  .passwordRequirementInfo.header
                              }
                            </PopoverHeader>
                            <PopoverBody>
                              <span
                                style={{
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {
                                  LoginMsgResProps.body.content
                                    .passwordRequirementInfo.text
                                }
                              </span>
                            </PopoverBody>
                          </UncontrolledPopover>

                          <div className="mt-2 pt-2">
                            <Formik
                              initialValues={this.state.passwordFormInputFields}
                              validationSchema={Yup.object().shape(
                                this.passwordFormValidationSchema()
                              )}
                              onSubmit={(values, helpers) =>
                                this.handlePasswordSubmit(values, helpers)
                              }
                            >
                              {(formik) => (
                                <Form className="mt-0" id="passwordform">
                                  <Row form>
                                    <Col xs="12">
                                      <FormGroup style={{ height: "50px" }}>
                                        <Field
                                          name="newPassword"
                                          type="password"
                                          placeholder={
                                            LoginMsgResProps.body.form
                                              .newPassword.label
                                          }
                                          className={
                                            "form-control" +
                                            (formik.errors.newPassword &&
                                            formik.touched.newPassword
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="newPassword"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row form>
                                    <Col xs="12">
                                      <FormGroup style={{ height: "50px" }}>
                                        <Field
                                          name="retypePassword"
                                          type="password"
                                          placeholder={
                                            LoginMsgResProps.body.form
                                              .retypePassword.label
                                          }
                                          className={
                                            "form-control" +
                                            (formik.errors.retypePassword &&
                                            formik.touched.retypePassword
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name="retypePassword"
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row form className="mt-2 mb-3">
                                    <Col xs="12">
                                      <button
                                        type="submit"
                                        className="btn btn-block btn-primary auth-btn"
                                        disabled={formik.isSubmitting}
                                      >
                                        {
                                          LoginMsgResProps.body.form.submit
                                            .label
                                        }
                                        {formik.isSubmitting ? (
                                          <span className="float-right">
                                            <Spinner
                                              size="sm"
                                              color="light"
                                              style={{ marginBottom: "2px" }}
                                            />
                                          </span>
                                        ) : null}
                                      </button>
                                    </Col>
                                  </Row>
                                </Form>
                              )}
                            </Formik>
                          </div>
                        </>
                      ) : this.state.challengeUpdateStatus === "S" ? (
                        <>
                          <div className="mt-4 text-success">
                            {
                              LoginMsgResProps.body.notification.success
                                .setPasswordPass
                            }
                          </div>
                          <div>
                            <Row form className="mt-4 mb-3">
                              <Col xs="12">
                                <button
                                  type="button"
                                  className="btn btn-block btn-primary auth-btn"
                                  onClick={() => this.redirectToAppHome()}
                                >
                                  {LoginMsgResProps.body.form.ok.label}
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mt-4 text-danger">
                            {
                              LoginMsgResProps.body.notification.error
                                .defaultError
                            }
                          </div>
                          <div>
                            <Row form className="mt-4 mb-3">
                              <Col xs="12">
                                <button
                                  type="button"
                                  className="btn btn-block btn-primary auth-btn"
                                  onClick={async () =>
                                    await this.setStateAsync(this.initState())
                                  }
                                >
                                  {LoginMsgResProps.body.form.ok.label}
                                </button>
                              </Col>
                            </Row>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
