import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import WithLoading from "../../core/helpers/with-loading";
import ConfirmModal from "../../core/helpers/confirm-modal";
import GeoAddressSearchField from "../../core/helpers/geo-address-search-field";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import { SetupURLProps } from "../settings/setup-urls";
import { SetupValidations } from "..//settings/setup-validations";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { AppDataProps } from "../../core/settings/app-data";
import { ServiceProviderMsgResProps } from "../messages/service-provider-properties";
import { manageError } from "../../core/actions/common-actions";
import { isPagePartPrivileged } from "../../core/actions/identity-actions";
import { getDivision, editDivision } from "../actions/service-provider-actions";

class ServiceProviderDivisionEdit extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _helpers = null;
  _pcServiceProviderDivisionEdit = "AS-SPV-DVED";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isOpenConfirmModal: false,
      orgDivision: null,
      formInputFields: null,
      tempDivisionObj: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    const organizationId = this.props.match.params.oid;
    await this.loadPageData(organizationId);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const organizationId = this.props.match.params.oid;
    if (organizationId !== prevProps.match.params.oid) {
      PageUtils.scrollToTop();
      await this.loadPageData(organizationId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async (organizationId) => {
    if (
      organizationId === null ||
      organizationId === undefined ||
      organizationId !== parseInt(organizationId).toString()
    )
      this.props.history.push(SetupURLProps.serviceProviders.listDivision);

    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ orgDivision: null });
    await this.checkPartPrivilege();
    await getDivision(organizationId, this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ orgDivision: res.data.result });
          await this.setStateAsync({
            formInputFields: await this.initFormInputFields(),
          });
        } else {
          this.props.history.push(SetupURLProps.serviceProviders.listCompany);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcServiceProviderDivisionEdit}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  initFormInputFields = () => {
    return {
      organizationName: this.state.orgDivision.organizationName,
      addressAutocomplete: "",
      streetAddress1: this.state.orgDivision.streetAddress1
        ? this.state.orgDivision.streetAddress1
        : "",
      streetAddress2: this.state.orgDivision.streetAddress2
        ? this.state.orgDivision.streetAddress2
        : "",
      city: this.state.orgDivision.city ? this.state.orgDivision.city : "",
      state: this.state.orgDivision.state ? this.state.orgDivision.state : "",
      country: this.state.orgDivision.country
        ? this.state.orgDivision.country
        : "",
      zipCode: this.state.orgDivision.zipCode
        ? this.state.orgDivision.zipCode
        : "",
      // locationLatitude: this.state.orgDivision.locationLatitude ? this.state.orgDivision.locationLatitude : "",
      // locationLongitude: this.state.orgDivision.locationLongitude ? this.state.orgDivision.locationLongitude : "",
    };
  };

  populateAddressFields = (place, formik) => {
    if (place && formik) {
      const address = PageUtils.getGeoAddressFieldsData(place);
      if (address) {
        formik.setFieldValue("streetAddress1", address.streetAddress1);
        formik.setFieldValue("streetAddress2", address.streetAddress2);
        formik.setFieldValue("city", address.city);
        formik.setFieldValue("state", address.state);
        formik.setFieldValue("country", address.country);
        formik.setFieldValue("zipCode", address.zipCode);
      }
    }
  };

  formValidationSchema = () => {
    const schema = {
      organizationName: Yup.string()
        .trim()
        .required(
          ServiceProviderMsgResProps.body.notification.error.divisionName.empty
        )
        .matches(
          SetupValidations.organizations.organizationName,
          ServiceProviderMsgResProps.body.notification.error.divisionName
            .minLength
        ),
      zipCode: Yup.string().matches(
        SetupValidations.organizations.zipCode,
        ServiceProviderMsgResProps.body.notification.error.zipCode.invalid
      ),
    };
    return schema;
  };

  handleConfirmation = async (values, helpers) => {
    this._helpers = helpers;
    const divisionObj = {
      organizationId: this.state.orgDivision.organizationId,
      organizationName: values.organizationName,
      businessName: null,
      streetAddress1: values.streetAddress1,
      streetAddress2: values.streetAddress2,
      city: values.city,
      state: values.state,
      country: values.country,
      zipCode: values.zipCode,
      locationLatitude: null, //values.locationLatitude,
      locationLongitude: null, //values.locationLongitude,
      lastModifyTimestamp: this.state.orgDivision.modifyTimestamp,
    };

    await this.setStateAsync({ tempDivisionObj: divisionObj });
    this.openConfirmModal();
  };

  handleSubmit = async () => {
    const divisionObj = this.state.tempDivisionObj;
    const helpers = this._helpers;
    this.closeConfirmModal();
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }

    await editDivision(divisionObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = [
            "S",
            ServiceProviderMsgResProps.body.notification.success.editDivision,
          ];
          if (res.data.result) {
            await this.setStateAsync({ orgDivision: res.data.result });
          }
          if (this._isMounted) {
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          ServiceProviderMsgResProps.body.notification.error.message,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);

          if (err.data && err.data.errors && err.data.errors.length > 0) {
            const errors = err.data.errors;
            errors.forEach((error) => {
              if (error.element && error.message && error.location === "body") {
                helpers.setFieldError(error.element, error.message);
              }
            });
          }
          PageUtils.scrollToTop();
        }
      });
    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            {this.state.orgDivision ? (
              <>
                <Card className="sram-page-form">
                  <CardTitle>
                    <span>
                      {
                        ServiceProviderMsgResProps.body.content
                          .serviceProviderEditDivision
                      }
                    </span>
                    <span className="float-right">
                      {PageDisplay.showGoBackLink(
                        "L",
                        SetupURLProps.serviceProviders.listDivision,
                        this.props.history
                      )}
                    </span>
                  </CardTitle>
                  <CardBody>
                    <Formik
                      initialValues={this.state.formInputFields}
                      validationSchema={Yup.object().shape(
                        this.formValidationSchema()
                      )}
                      onSubmit={(values, helpers) =>
                        this.handleConfirmation(values, helpers)
                      }
                    >
                      {(formik) => (
                        <Form id="serviceProviderDivisionForm" {...formEvent}>
                          <div className="sram-form-main">
                            <div>
                              {formik.status &&
                              Array.isArray(formik.status) &&
                              formik.status.length === 2 &&
                              (formik.status[0] === "E" ||
                                formik.status[0] === "S") ? (
                                <Row>
                                  <Col xs={12}>
                                    {formik.status[0] === "E"
                                      ? PageDisplay.showErrorNotification(
                                          formik.status[1]
                                        )
                                      : PageDisplay.showSuccessNotification(
                                          formik.status[1]
                                        )}
                                  </Col>
                                </Row>
                              ) : null}
                            </div>
                            <div>
                              <div className="sram-view-data">
                                <Row form>
                                  {this.state.orgDivision.parentOrganization ? (
                                    <Col sm={12} md={12} lg={12}>
                                      {PageDisplay.getFormFieldLabelValue(
                                        ServiceProviderMsgResProps.body.form
                                          .parentCompanyDivision.label,
                                        this.state.orgDivision
                                          .parentOrganization
                                          .organizationTreepath
                                      )}
                                    </Col>
                                  ) : null}
                                </Row>
                              </div>
                              <div>
                                <Row form>
                                  <Col sm={12} md={12} lg={12}>
                                    <FormGroup>
                                      <Label for="organizationName">
                                        {
                                          ServiceProviderMsgResProps.body.form
                                            .divisionName.label
                                        }{" "}
                                        *
                                      </Label>
                                      <Field
                                        name="organizationName"
                                        type="text"
                                        maxLength="50"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.organizationName,
                                          formik.touched.organizationName
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "organizationName"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={12} lg={12} className="pt-3">
                                    <GeoAddressSearchField
                                      callback={this.populateAddressFields}
                                      formik={formik}
                                    />
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="streetAddress1">
                                        {
                                          ServiceProviderMsgResProps.body.form
                                            .streetAddress1.label
                                        }
                                      </Label>
                                      <Field
                                        name="streetAddress1"
                                        type="text"
                                        maxLength="100"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.streetAddress1,
                                          formik.touched.streetAddress1
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "streetAddress1"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="streetAddress2">
                                        {
                                          ServiceProviderMsgResProps.body.form
                                            .streetAddress2.label
                                        }
                                      </Label>
                                      <Field
                                        name="streetAddress2"
                                        type="text"
                                        maxLength="100"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.streetAddress2,
                                          formik.touched.streetAddress2
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "streetAddress2"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="city">
                                        {
                                          ServiceProviderMsgResProps.body.form
                                            .city.label
                                        }
                                      </Label>
                                      <Field
                                        name="city"
                                        type="text"
                                        maxLength="50"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.city,
                                          formik.touched.city
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "city"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="state">
                                        {
                                          ServiceProviderMsgResProps.body.form
                                            .state.label
                                        }
                                      </Label>
                                      <Field
                                        name="state"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.state,
                                          formik.touched.state
                                        )}
                                      >
                                        <option value=""></option>
                                        {AppDataProps.address.states &&
                                        AppDataProps.address.states.length > 0
                                          ? AppDataProps.address.states.map(
                                              (state, index) => {
                                                return (
                                                  <option
                                                    value={state}
                                                    key={`key-addr-state-${index}`}
                                                  >
                                                    {state}
                                                  </option>
                                                );
                                              }
                                            )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "state"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row form>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="country">
                                        {
                                          ServiceProviderMsgResProps.body.form
                                            .country.label
                                        }
                                      </Label>
                                      <Field
                                        name="country"
                                        as="select"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.country,
                                          formik.touched.country
                                        )}
                                      >
                                        <option value=""></option>
                                        {AppDataProps.address.countries &&
                                        AppDataProps.address.countries.length >
                                          0
                                          ? AppDataProps.address.countries.map(
                                              (country, index) => {
                                                return (
                                                  <option
                                                    value={country}
                                                    key={`key-addr-country-${index}`}
                                                  >
                                                    {country}
                                                  </option>
                                                );
                                              }
                                            )
                                          : null}
                                      </Field>
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "country"
                                      )}
                                    </FormGroup>
                                  </Col>
                                  <Col sm={12} md={6} lg={6}>
                                    <FormGroup>
                                      <Label for="zipCode">
                                        {
                                          ServiceProviderMsgResProps.body.form
                                            .zipCode.label
                                        }
                                      </Label>
                                      <Field
                                        name="zipCode"
                                        type="text"
                                        maxLength="5"
                                        className={PageDisplay.getFormikFieldClassName(
                                          formik.errors.zipCode,
                                          formik.touched.zipCode
                                        )}
                                      />
                                      {PageDisplay.getFormikFieldErrorMessage(
                                        "zipCode"
                                      )}
                                    </FormGroup>
                                  </Col>
                                </Row>
                                {/**  
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="locationLatitude">
                                      {
                                        ServiceProviderMsgResProps.body.form
                                          .locationLatitude.label
                                      }
                                    </Label>
                                    <Field
                                      name="locationLatitude"
                                      type="text"
                                      maxLength="30"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.locationLatitude,
                                        formik.touched.locationLatitude
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "locationLatitude"
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  <FormGroup>
                                    <Label for="locationLongitude">
                                      {
                                        ServiceProviderMsgResProps.body.form
                                          .locationLongitude.label
                                      }
                                    </Label>
                                    <Field
                                      name="locationLongitude"
                                      type="text"
                                      maxLength="30"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.locationLongitude,
                                        formik.touched.locationLongitude
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "locationLongitude"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              */}
                              </div>
                            </div>
                          </div>
                          <div className="sram-form-foot">
                            {PageDisplay.showSaveButton(formik.isSubmitting)}
                            {PageDisplay.showCancelButton(this.props.history)}
                          </div>
                          <FormFieldFocusError />
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
                {this.state.tempDivisionObj ? (
                  <ConfirmModal
                    isOpenConfirmModal={this.state.isOpenConfirmModal}
                    closeConfirmModal={this.closeConfirmModal}
                    handleSubmit={this.handleSubmit}
                  >
                    <div>
                      <div>
                        <Label>
                          {
                            ServiceProviderMsgResProps.body.notification
                              .confirmation.editDivisionTitle
                          }
                        </Label>
                      </div>
                    </div>
                  </ConfirmModal>
                ) : null}
              </>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  ServiceProviderMsgResProps.body.content.noDataFound
                )}
              </>
            )}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default ServiceProviderDivisionEdit;
