import React, { Component } from "react";
import {
  Modal,
  // ModalHeader,
  ModalBody,
  // ModalFooter,
  Button,
  Row,
  Col,
  FormGroup,
  UncontrolledCollapse,
  UncontrolledPopover,
  PopoverBody,
  // Collapse
  //   Table,
} from "reactstrap";
import WithLoading from "../../core/helpers/with-loading";
import AppUtils from "../../core/helpers/app-utils";
import { DispenserMsgResProps } from "../messages/dispenser-properties";
import { SiteMsgResProps } from "../messages/site-properties";
import { feedBack } from "../actions/actions";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { manageError } from "../../core/actions/common-actions";
// import PageUtils from "../../core/helpers/page-utils";
import PageDisplay from "../../core/helpers/page-display";
import axios from "axios";
import DispenserUtils from "../helpers/dispenser-utils";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

class ErrorDetailsModal extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _helpers = null;

  constructor(props) {
    super(props);
    this.state = {
      feedBack: "",
      error: "",
      loading: false,
      feedBackResponse: null,
      fixes: [],
      possibleFixes: true,
      fixId: null,
      value: "",
      copied: false,
      copyText: "Copy to clipboard",
      textCopied: "Copied to clipboard",
      formInputFields: this.initFormInputFields(),
    };
  }

  initFormInputFields = () => {
    return {
      feedBack: "",
    };
  };

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    // PageUtils.scrollToTop();
    window.appHistory = this.props.history;
  }

  componentWillReceiveProps() {
    if (this.props.possibleFixes) {
      if (this.state.possibleFixes) {
        for (const list of this.props.possibleFixes.errorCodes) {
          for (const data of list.fixes) {
            data.isOpen = false;
            this.state.fixes.push(data);
          }
          this.setStateAsync({ value: this.props.possibleFixes });
          this.setStateAsync({ fixes: this.state.fixes });
          this.setStateAsync({ possibleFixes: false });
        }
        let fixesShow =
          this.props.fixes && this.props.fixes.length > 0
            ? this.props.fixes.map((obj) => {
                return {
                  "Site Name": " " + obj.siteName,
                  "Dispenser Name":
                    " " + obj.sideANumber + "/" + obj.sideBNumber,
                  "Side Label": " " + obj.sideLabelDescription,
                  Timestamp:
                    " " +
                    obj.deviceTimestamp +
                    " " +
                    obj.deviceTimestampTimezone,
                  ErrorCode: " " + obj.errorCode,
                  Manufacturer: " " + obj.manufacturerName,
                  "Model Name": " " + obj.modelName,
                  "Severity Level": " " + obj.severityLevelDescription,
                  Description: " " + obj.errorDescription,
                };
              })
            : null;
        let fixes =
          this.props.possibleFixes.errorCodes &&
          this.props.possibleFixes.errorCodes.length > 0
            ? this.state.fixes.map((val) => {
                return {
                  Fix: " " + val.fixSequence,
                  "Fix Description": " " + val.fixDescription,
                  Parts: val.parts.map((key) => {
                    return {
                      "Part Number": " " + key.partNumber,
                      "Part Description": " " + key.partDescription,
                    };
                  }),
                };
              })
            : SiteMsgResProps.body.content.noPossibleFixesFound +
              " " +
              this.props.possibleFixes.errorCode.toUpperCase();
        let val =
          JSON.stringify(fixesShow, null) + "Fixes:" + JSON.stringify(fixes);
        let value = val
          .replace(/{|},|}/g, "\n")
          .replace(/\[|\]|"/g, "")
          .replace(/,/g, "\n");
        this.setStateAsync({ value: value });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  handleChange(key, event) {
    if (key === "feedBack") {
      this.setStateAsync({ feedBack: event.currentTarget.value, error: "" });
    }
  }
  handleSubmit = async () => {
    if (this.state.feedBack === "") {
      await this.setStateAsync({ error: "Feedback Required" });
    }
    if (this.state.feedBack !== "") {
      this.handleFeedBack();
    }
  };

  formValidationSchema = () => {
    const schema = {
      feedBack: Yup.string().required(
        DispenserMsgResProps.body.notification.error.feedBack.empty
      ),
    };
    return schema;
  };

  handleFeedBack = async (values, helpers) => {
    this._helpers = helpers;
    await this.setStateAsync({ loading: true });
    await this.setStateAsync({
      feedBackResponse: null,
    });
    // const userEmail = AppUtils.getIdentityTokenData();
    const obj = {
      errorCodeName: this.props.possibleFixes.errorCode,
      errorCodeFeedback: values.feedBack,
      // userEmail: userEmail.email,
    };
    await feedBack(obj, this._cancelToken)
      .then(async (res) => {
        if (res) {
          await this.setStateAsync({
            feedBackResponse: res.data.message,
            feedBack: "",
          });
          this._helpers.resetForm();
          toastr.options = {
            positionClass: "toast-top-right",
            hideDuration: 300,
            timeOut: 60000,
          };
          toastr.clear();
          setTimeout(() => toastr.success(res.data.message), 300);
          this.closeModal();
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPriceDataFetched: true });
  };
  closeModal = async () => {
    await this.props.possibleFixesCheck();
    await this.setStateAsync({
      feedBack: "",
      fixes: [],
      possibleFixes: true,
      copied: false,
      value: "",
      error: "",
    });
    this.props.closeErrorDetailsModal();
  };
  showParts = async (val) => {
    await this.setStateAsync({ fixId: val.errorCodeFixId });
    for (const data of this.state.fixes) {
      if (data.errorCodeFixId === val.errorCodeFixId) {
        data.isOpen = !data.isOpen;
        await this.setStateAsync({ expand: data.isOpen });
      }
    }
  };

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <Modal
        isOpen={this.props.isOpenErrorDetailsModal}
        toggle={this.props.closeErrorDetailsModal}
        size="lg"
        backdrop="static"
        centered={false}
        className="sram-modal-content"
      >
        <div className="modal-header">
          <span>
            <h5 className="modal-title">
              {DispenserMsgResProps.head.title.errorDetails}
            </h5>
          </span>
          <span
            className="pull-right sram-times"
            onClick={() => this.closeModal()}
          >
            <i className="fa fa-times-circle"></i>
          </span>
        </div>

        <ModalBody className="mb-2 sram-modal-body">
          {/* {this.state.copied === true ? (
            <>{PageDisplay.showSuccessNotification(this.state.textCopied)}</>
          ) : null} */}

          <WithLoading
            isPageDataFetched={this.props.isPageDataFetched}
            type="page"
          >
            {this.props.fixes && this.props.fixes.length > 0 ? (
              <Row className="sram-error-modal-font">
                <Col sm={12} md={12} lg={12}>
                  <CopyToClipboard
                    text={this.state.value}
                    onCopy={() => this.setState({ copied: true })}
                    id="pop"
                  >
                    <i
                      className="fa fa-copy pull-right"
                      style={{ cursor: "pointer" }}
                      title={this.state.copyText}
                    ></i>
                  </CopyToClipboard>
                  <UncontrolledPopover
                    trigger="legacy"
                    placement="top"
                    target="pop"
                  >
                    <PopoverBody>
                      <div style={{ color: "#000" }}>
                        {this.state.textCopied}
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </Col>
                <Col sm={12} md={12} lg={12} className="font-mild pb-2 font-16">
                  {this.props.possibleFixes.siteName}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Dispenser{" "}
                  {this.props.possibleFixes.sideANumber} /{" "}
                  {this.props.possibleFixes.sideBNumber}{" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                  {this.props.possibleFixes.sideLabelDescription}
                </Col>
                {/* <Col sm={12} md={12} lg={12} className="font-mild pb-2">
                  Dispenser {this.props.possibleFixes.sideANumber} /{" "}
                  {this.props.possibleFixes.sideBNumber}
                </Col>
                <Col sm={12} md={12} lg={12} className="font-mild pb-2">
                  {this.props.possibleFixes.sideLabelDescription}
                </Col> */}
                <Col sm={12} md={12} lg={12} className="font-mild">
                  {AppUtils.getDateTime(
                    this.props.possibleFixes.deviceTimestamp
                  )}{" "}
                  &nbsp; {this.props.possibleFixes.deviceTimestampTimezone}
                </Col>

                <hr />
                <span className="sram-hr" style={{ width: "100%" }}></span>
                <br />
                <Col sm={12} md={12} lg={12} className="sram-error-modal-font">
                  {this.props.possibleFixes.errorCode.toUpperCase()} (
                  {this.props.possibleFixes.manufacturerName} &nbsp;
                  {this.props.possibleFixes.modelName})
                </Col>

                <Col sm={12} md={12} lg={12} className="sram-error-modal-font">
                  <br />
                  <div className="sram-bg-blue-3 sram-error-info-section ">
                    <span className="font-mild">
                      {DispenserMsgResProps.body.content.severityLevel}
                    </span>
                    :{" "}
                    <span className="font-mild">
                      {this.props.possibleFixes.severityLevelDescription}
                    </span>
                    <Row>
                      <Col sm={12} md={12} lg={12}>
                        <span>
                          <div className="font-mild">
                            {DispenserMsgResProps.body.content.description}:
                          </div>
                          <p className="sram-mrb-8">
                            {DispenserUtils.capitalize(
                              this.props.possibleFixes.errorDescription
                            )}
                          </p>
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
                {this.props.possibleFixes.errorCodes &&
                this.props.possibleFixes.errorCodes.length > 0 ? (
                  this.state.fixes.map((d, index) => {
                    return (
                      <Col sm={12} md={12} lg={12} key={index}>
                        <br />
                        <h6 className="font-mild">
                          {DispenserMsgResProps.body.content.possibleFixes}
                        </h6>
                        <div>
                          <Col sm={12} md={12} lg={12}>
                            <Row>
                              <Col sm={2} md={1} lg={1}>
                                <div className="sram-sequence-adj sram-bg-blue-8 ">
                                  <span className="sram-vertical-align-middle">
                                    {d.fixSequence}
                                  </span>
                                </div>
                              </Col>
                              <Col
                                sm={10}
                                md={11}
                                lg={11}
                                className="pad-left-0"
                              >
                                <p className="sram-mrb-8 font-mild">
                                  {DispenserMsgResProps.body.content.fix}{" "}
                                  <span>{d.fixSequence}</span>
                                </p>
                                <p className="sram-mrb-8">{d.fixDescription}</p>
                              </Col>
                              <Col sm={2} md={1} lg={1}></Col>
                              <Col
                                sm={10}
                                md={11}
                                lg={11}
                                className="pb-2 sram-chev sidebar-nav pad-left-0"
                              >
                                <span
                                  id={"toggler" + index}
                                  aria-expanded={d.isOpen}
                                  onClick={() => this.showParts(d)}
                                  data-toggle="collapse"
                                  className="font-mild sram-mrb-8 sram-links sidebar-link has-arrow sram-has-arrow"
                                >
                                  {
                                    DispenserMsgResProps.body.content
                                      .possibleParts
                                  }
                                </span>
                                <UncontrolledCollapse
                                  toggler={"toggler" + index}
                                  className="pt-2"
                                >
                                  {d.parts.length > 0 ? (
                                    d.parts.map((parts, ind) => {
                                      return (
                                        <Col
                                          sm={12}
                                          md={12}
                                          lg={12}
                                          key={ind}
                                          className="pb-1"
                                        >
                                          <span className="font-mild">
                                            {parts.partNumber}
                                          </span>{" "}
                                          - <span>{parts.partDescription}</span>
                                        </Col>
                                      );
                                    })
                                  ) : (
                                    <div className="sram-no-data">
                                      {SiteMsgResProps.body.content.noIssuesFound}
                                    </div>
                                  )}
                                </UncontrolledCollapse>
                              </Col>
                            </Row>
                          </Col>
                        </div>
                      </Col>
                    );
                  })
                ) : (
                  <Col
                    sm={12}
                    md={12}
                    lg={12}
                    className="sram-no-data sram-error-info-section"
                    style={{ color: "#000" }}
                  >
                    <br />
                    {SiteMsgResProps.body.content.noPossibleFixesFound}{" "}
                    {this.props.possibleFixes.errorCode.toUpperCase()}.
                  </Col>
                )}
                <Col sm={12} md={12} lg={12}>
                  <br />
                  <div className="sram-bg-blue-3 sram-error-info-section ">
                    <span>
                      {/* <img src={Toe} alt="toe" className="toe-icon" /> */}
                    </span>
                    <span className="font-mild sram-feedback-provider-text">
                      {DispenserMsgResProps.body.content.feedBackProvide}
                    </span>

                    <Formik
                      initialValues={this.state.formInputFields}
                      validationSchema={Yup.object().shape(
                        this.formValidationSchema()
                      )}
                      onSubmit={(values, helpers) =>
                        this.handleFeedBack(values, helpers)
                      }
                    >
                      {(formik) => (
                        <Form id="feedBackForm" {...formEvent}>
                          <div className="sram-form-main">
                            <div>
                              <Row form>
                                <Col sm={12} md={12} lg={12}>
                                  <FormGroup>
                                    <Field
                                      name="feedBack"
                                      as="textarea"
                                      className={PageDisplay.getFormikFieldClassName(
                                        formik.errors.feedBack,
                                        formik.touched.feedBack
                                      )}
                                    />
                                    {PageDisplay.getFormikFieldErrorMessage(
                                      "feedBack"
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          {PageDisplay.showSaveButton(formik.isSubmitting)}
                          <Button
                            type="button"
                            className="sram-btn-white mx-2"
                            onClick={() => this.closeModal()}
                          >
                            {SiteMsgResProps.footer.close.label}
                          </Button>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </Col>
              </Row>
            ) : null}
          </WithLoading>
        </ModalBody>
      </Modal>
    );
  }
}

export default ErrorDetailsModal;
