export const RoleMsgResProps = {
  head: {
    title: {
      roleList: "Setup - Roles - List",
      roleAdd: "Setup - Roles - Add",
      roleView: "Setup - Roles - View",
      roleEdit: "Setup - Roles - Edit",
    },
  },
  body: {
    content: {
      roles: "Roles",
      addRole: "Add Role",
      viewRole: "View Role Details",
      editRole: "Edit Role",
      roleDetails: "Role Details",
      noRolesFound: "No Roles found",
      noRoleFound: "Role information not found",
      accessPrivileges: "Access Privileges",
    },
    form: {
      roleType: {
        label: "Role For",
        options: {
          S: "Admin",
          O: "Site Owner",
          P: "Service Provider",
        },
      },
      roleName: {
        label: "Role Name",
      },
    },
    notification: {
      confirmation: {
        addRoleTitle:
          "Are you sure you to add the Role with following details:",
        addRoleNote: "Role For data cannot be modified later.",
        editRoleTitle: "Are you sure you to update the Role details ?",
      },
      success: {
        addRole: "Role is added successfully.",
        editRole: "Role is modified successfully.",
      },
      error: {
        message: "An error has occured.",
        roleType: {
          empty: "Role Type is required.",
        },
        roleName: {
          empty: "Role Name is required.",
          invalid: "Role Name is not valid.",
          duplicate: "Role Name already exists.",
        },
      },
      warning: {
        roleNoEditLoginUser:
          "This Role information can not be modified as your account is assigned with this Role.",
        roleMain: "This Role information can not be modified.",
      },
    },
  },
};
