export const ErrorMsgResProps = {
    head: {
      title: {
        error: "Error",
        pageNotFound: "Page Not Found"
      },
    },
    body: {
      content: {
        noDataFound: "No issues found.",
        noAccess: "The page you requested was either not found or unauthorized access."
      },
      form: {
        homePage: {
          label: "Go to Home Page"
        },  
        loginPage: {
          label: "Go to Login Page"
        },     
      },
      notification: {
        error: {
          message: "An error occurred, please try again later.",       
        },
      },
    },
  };
  