import React, { Component } from "react";
import {
  Modal,
  // ModalHeader,
  ModalBody,
  Button,
  Col,
  Row,
  ModalFooter,
} from "reactstrap";
import { DispenserMsgResProps } from "../messages/dispenser-properties";
// import AppUtils from "../../core/helpers/app-utils";
import axios from "axios";
import {
  postWarmStartData,
  generatewarmStartJWTToken,
  warmStartDryRun,
  warmStartCommands,
  getWSOperationTarget,
} from "../actions/dispenser-actions";
import { manageError } from "../../core/actions/common-actions";
// import { AppConfigProps } from "../../core/settings/app-config";
import WithLoading from "../../core/helpers/with-loading";

class WarmStartRebootModal extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  constructor(props) {
    super(props);
    // this.interval = setInterval(() => this.rebootCheckProcess(), 5000);
    this.state = {
      showReboot: true,
      rebootOptionsShow: false,
      warmStartShow: false,
      warmStartStatus: null,
      comments: "",
      noButtonShow: true,
      style: "rs-steps-item rs-steps-item-status-wait",
      style1: "rs-steps-item rs-steps-item-status-wait",
      style2: "rs-steps-item rs-steps-item-status-wait",
      style3: "rs-steps-item rs-steps-item-status-wait",
      style4: "rs-steps-item rs-steps-item-status-wait",
      style5: "rs-steps-item rs-steps-item-status-wait",
      style6: "rs-steps-item rs-steps-item-status-wait",
      rebootStartedCheck: false,
      rebootStartedCheckMessage: "",
      rebootProgressCheck: false,
      rebootProgressCheckMessage: "",
      rebootResultCheck: false,
      rebootResultCheckMessage: "",
      rebootType: "",
      dataRebootId: "",
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    // PageUtils.scrollToTop();
    window.appHistory = this.props.history;
  }

  componentWillReceiveProps = async () => {};

  showRebootOptions = async () => {
    this.setState({ rebootOptionsShow: true });
    this.setState({ noButtonShow: false });
  };

  warmStartAPI = async () => {
    this.setState({ rebootStartedCheck: true });
    generatewarmStartJWTToken()
      .then(async (res) => {
        await this.setState({
          rebootStartedCheck: false,
          warmStartStatus: "Warm Start started.",
          rebootStartedCheckMessage: "Warm Start started.",
        });
        await this.sendWarmStartDryRun(
          this.props.siteItem?.siteNumber,
          this.props.dispenserItem?.sideANumber,
          res?.access_token
        );
        return await res;
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  sendWarmStartDryRun = async (siteNumber, fuelingPointId, access_token) => {
    await this.setState({ rebootStartedCheck: true });
    warmStartDryRun(siteNumber, fuelingPointId, access_token)
      .then(async (res) => {
        await this.setState({
          rebootStartedCheck: false,
          warmStartStatus: "Warm Start Dry Run completed.",
          rebootStartedCheckMessage: "Warm Start Dry Run completed.",
        });
        await this.setState({
          style1: "rs-steps-item rs-steps-item-status-finish",
        });
        await this.sendWarmStartCommands(
          siteNumber,
          fuelingPointId,
          access_token
        );
        return await res;
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  sendWarmStartCommands = async (siteNumber, fuelingPointId, access_token) => {
    await this.setState({ rebootProgressCheck: true });
    warmStartCommands(siteNumber, fuelingPointId, access_token)
      .then(async (res) => {
        await this.setState({
          rebootProgressCheck: false,
          warmStartStatus: "Warm Start sending commands.",
          rebootProgressCheckMessage: "Warm Start sending commands.",
        });
        await this.setState({
          style2: "rs-steps-item rs-steps-item-status-finish",
        });
        this.getwarmStartData(
          res?.data?.operationId,
          res?.data?.targetSpec?.targetList[0],
          access_token
        );
        return await res;
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  getwarmStartData = async (operationId, targetList, access_token) => {
    await this.setState({ rebootResultCheck: true });
    getWSOperationTarget(operationId, access_token)
      .then(async (res) => {
        const completedHistory = await res?.data?.targetStatus?.[
          `${targetList}`
        ]?.success;
        if (completedHistory === true) {
          const bodyObj = {
            rebootType: this.state.rebootType,
            rebootComment: this.state.comments,
            rebootStatus: 3,
            overallStatus: 2,
            dataRebootId: this.state.dataRebootId,
          };
          await this.storeWarmStartReboot(bodyObj);
          await this.setState({
            rebootResultCheck: false,
            warmStartStatus: "Warm Start successfully completed.",
            rebootResultCheckMessage: "Warm Start successfully completed.",
          });
          await this.setState({
            style3: "rs-steps-item rs-steps-item-status-finish",
          });
        } else {
          const bodyObj = {
            rebootType: this.state.rebootType,
            rebootComment: this.state.comments,
            rebootStatus: 4,
            overallStatus: 3,
            dataRebootId: this.state.dataRebootId,
          };
          await this.storeWarmStartReboot(bodyObj);
          await this.setState({
            rebootResultCheck: false,
            warmStartStatus: "Warm Start failed.",
            rebootResultCheckMessage: "Warm Start failed.",
          });
          await this.setState({
            style3: "rs-steps-item rs-steps-item-status-error",
          });
        }
        return await res;
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  handleReboot = async (rebootType) => {
    const bodyObj = {
      rebootComment: this.state.comments,
      fuelingPosition: this.props.dispenserItem?.sideANumber,
      siteNumber: this.props.siteItem?.siteNumber,
    };
    await this.setState({
      rebootStartedCheck: true,
      warmStartStatus: "Warm Start started.",
      rebootStartedCheckMessage: "Warm Start started.",
    });
    await this.setState({
      style1: "rs-steps-item rs-steps-item-status-wait",
    });
    await this.storeWarmStartReboot(bodyObj);
    // await this.warmStartAPI();
  };

  storeWarmStartReboot = async (bodyObj) => {
    await this.setState({
      rebootStartedCheck: false,
      style1: "rs-steps-item rs-steps-item-status-finish",
    });
    await this.setState({
      rebootResultCheck: true,
      style2: "rs-steps-item rs-steps-item-status-wait",
    });
    await postWarmStartData(
      this.props.siteItem?.siteId,
      this.props.dispenserItem?.dispenserId,
      bodyObj,
      this._cancelToken
    )
      .then(async (res) => {
        if (res.data.result) {
          this.setStateAsync({ dataRebootId: res.data.result.dataRebootId });
        }
        await this.setState({
          rebootResultCheck: false,
          warmStartStatus: "Warm Start successfully completed.",
          rebootResultCheckMessage: "Warm Start successfully completed.",
        });
        await this.setState({
          style2: "rs-steps-item rs-steps-item-status-finish",
        });
        return res;
      })
      .catch(async (err) => {
        await this.setState({
          rebootResultCheck: false,
          warmStartStatus: "Warm Start failed.",
          rebootResultCheckMessage: "Warm Start failed.",
        });
        await this.setState({
          style2: "rs-steps-item rs-steps-item-status-error",
        });
        await manageError(err, this.props.history);
      });
  };

  handleChange = async (key, event) => {
    if (key === "warmStart") {
      await this.setState({ rebootType: 3, warmStartShow: true });
      await this.setState({ showReboot: false });
      await this.setState({ rebootOptionsShow: false });
      await this.handleReboot(3);
    }
    if (key === "comments") {
      await this.setState({ comments: event.target.value });
    }
    if (key === "cancel") {
      this.closeModal();
    }
  };

  showReboot() {
    if (this.state.showReboot) {
      return (
        <>
          <Col sm={12} md={12} lg={12} className="pb-2">
            <h6 className="font-mild">Are you sure you want to Reboot ?</h6>
          </Col>
          <Col sm={12} md={12} lg={12} className="pb-3">
            <Button
              type="button"
              className="sram-btn-blue"
              onClick={() => this.showRebootOptions()}
            >
              {DispenserMsgResProps.body.yes.label}
            </Button>
            {this.state.noButtonShow === true ? (
              <Button
                type="button"
                className="sram-btn-white mx-2"
                onClick={() => this.closeModal()}
              >
                {DispenserMsgResProps.body.no.label}
              </Button>
            ) : null}
          </Col>
        </>
      );
    }
  }

  showWarmStartOptions() {
    if (this.state.rebootOptionsShow) {
      return (
        <>
          <Col sm={12} md={12} lg={12} className="pb-2">
            <textarea
              type="text"
              className="form-control"
              placeholder="Enter Comments"
              onChange={this.handleChange.bind(this, "comments")}
            />
          </Col>
          <Col sm={12} md={12} lg={12} className="pb-2 font-mild">
            <span>Check one of the reboot option</span>
          </Col>
          <Col sm={12} md={12} lg={12} className=" pb-2">
            <div className="form-check form-check-inline font-mild">
              <label className="form-check-label">
                <input
                  type="radio"
                  className="form-check-input"
                  onChange={this.handleChange.bind(this, "warmStart")}
                />
                &nbsp;Warm Start API
              </label>
            </div>
          </Col>
        </>
      );
    }
  }

  showWarmStartReboot() {
    const styles = {
      // width: '200px',
      display: "inline-table",
      verticalAlign: "top",
    };
    if (this.state.warmStartShow) {
      return (
        <div className="steps">
          <h5 className="font-mild">Warm Start API</h5>
          <div className="rs-steps rs-steps-vertical" style={styles}>
            <div className={this.state.style1}>
              <div className="rs-steps-item-tail"></div>
              <div className="rs-steps-item-icon-wrapper">
                <span className="rs-steps-item-icon rs-steps-item-icon-wait">
                  {this.state.rebootStartedCheck === true ? (
                    <WithLoading size="sm" type="page"></WithLoading>
                  ) : null}
                </span>
              </div>
              <div className="rs-steps-item-content">
                <div className="rs-steps-item-title">Warm Start started</div>
              </div>
              <p className="fnt-13">{this.state.rebootStartedCheckMessage}</p>
            </div>
            <div className={this.state.style2}>
              <div className="rs-steps-item-tail"></div>
              <div className="rs-steps-item-icon-wrapper">
                <span className="rs-steps-item-icon rs-steps-item-icon-error">
                  {this.state.rebootResultCheck === true ? (
                    <WithLoading size="sm" type="page"></WithLoading>
                  ) : null}
                </span>
              </div>
              <div className="rs-steps-item-content">
                <div className="rs-steps-item-title">Warm Start completed</div>
              </div>
              {this.state.rebootResultCheckMessage ? (
                <p className="fnt-13">{this.state.rebootResultCheckMessage}</p>
              ) : null}{" "}
            </div>
            {/* <div className={this.state.style3}>
              <div className="rs-steps-item-tail"></div>
              <div className="rs-steps-item-icon-wrapper">
                <span className="rs-steps-item-icon rs-steps-item-icon-wait">
                  {this.state.rebootResultCheck === true ? (
                    <WithLoading size="sm" type="page"></WithLoading>
                  ) : null}
                </span>
              </div>
              <div className="rs-steps-item-content">
                <div className="rs-steps-item-title">Warm Start Result</div>
              </div>
              {this.state.rebootResultCheckMessage ? (
                <p className="fnt-13">{this.state.rebootResultCheckMessage}</p>
              ) : null}
            </div> */}
          </div>
        </div>
      );
    }
  }

  closeModal = async () => {
    await this.setStateAsync({ showReboot: true });
    await this.setStateAsync({ rebootOptionsShow: false });
    await this.setStateAsync({ noButtonShow: true });
    await this.setStateAsync({ warmStartShow: false });
    await this.setStateAsync({ warmStartStatus: null });
    await this.setStateAsync({ comments: null });
    await this.setStateAsync({
      rebootStartedCheck: false,
      rebootProgressCheck: false,
      rebootResultCheck: false,
    });
    await this.setStateAsync({
      rebootStartedCheckMessage: "",
      rebootProgressCheckMessage: "",
      rebootResultCheckMessage: "",
    });
    await this.setStateAsync({
      style: "rs-steps-item rs-steps-item-status-wait",
      style1: "rs-steps-item rs-steps-item-status-wait",
      style2: "rs-steps-item rs-steps-item-status-wait",
      style3: "rs-steps-item rs-steps-item-status-wait",
      style4: "rs-steps-item rs-steps-item-status-wait",
      style5: "rs-steps-item rs-steps-item-status-wait",
      style6: "rs-steps-item rs-steps-item-status-wait",
    });
    this.setStateAsync({ comments: "" });
    // }
    // }
    setTimeout(() => {
      this.props.closeRebootModal();
      this.props.rebootHistory();
    }, 300);
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpenRebootModal}
        toggle={this.props.closeRebootModal}
        size="md"
        backdrop="static"
        centered={false}
        className="sram-modal-content"
      >
        <div className="modal-header">
          <span>
            <h5 className="modal-title">
              {DispenserMsgResProps.head.title.reboot}
            </h5>
          </span>
          <span
            className="pull-right sram-times"
            onClick={() => {
              this.closeModal();
            }}
          >
            <i className="fa fa-times-circle"></i>
          </span>
        </div>
        <ModalBody className="mb-2">
          <WithLoading
            isPageDataFetched={this.props.isPageDataFetched}
            type="page"
          >
            <Row>
              <Col sm={12} md={12} lg={12}>
                <h5 className="font-mild">
                  {this.props.siteItem?.siteName} &nbsp; Dispenser &nbsp;
                  {this.props.dispenserItem?.sideANumber +
                    "/" +
                    this.props.dispenserItem?.sideBNumber}
                </h5>
              </Col>
              {this.state.rebootOptionsShow === false &&
              this.state.warmStartShow === false
                ? this.showReboot()
                : null}
              {this.showWarmStartOptions()}
              {this.showWarmStartReboot()}
            </Row>
          </WithLoading>
        </ModalBody>
        <ModalFooter>
          <Button className="sram-btn-white" onClick={() => this.closeModal()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default WarmStartRebootModal;
