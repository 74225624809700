import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Table, Button } from "reactstrap";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import PageUtils from "../../core/helpers/page-utils";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { PortalURLProps } from "../settings/portal-urls";
import { manageError } from "../../core/actions/common-actions";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import {
  dispensersList,
  siteView,
  componentStatus,
  tickets,
  warranty,
  flowRate,
  transactionStatus,
} from "../actions/site-actions";
import { alerts, alertEvents } from "../actions/dispenser-actions";
import { listSites } from "../actions/site-actions";
import { NavLink as RRNavLink } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { SiteMsgResProps } from "../messages/site-properties";
import AppUtils from "../../core/helpers/app-utils";
// import DispenserUtils from "../helpers/dispenser-utils";
// import ErrorDetailsModal from "./error-details-modal";
import { checkToken } from "../actions/actions";
import SiteDashboardAlertsModal from "./site-dash-alert-modal";
import Green from "../../../assets/images/greencolor.svg";
import Red from "../../../assets/images/redcolor.svg";
import Yellow from "../../../assets/images/yellowcolor.svg";
import Gray from "../../../assets/images/graycolor.svg";

export class SiteDashboardNew extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcPortalSiteDispenserView = "CP-DSP-DVIW";
  _pcPortalSiteView = "CP-SIT-SVIW";
  _pcPortalViewConnectivityStatus = "CP-DCS-DCNS";
  _pcPortalViewTickets = "HT-TCT-TVIW";
  _pcPortalViewPumpTwoWireStatus = "CP-DPM-DTWS";
  _pcPortalViewPumpSerialInterface = "CP-DPM-DSIF";
  _pcPortalViewCrindCardReader = "CP-DCN-DCDR";
  _pcPortalViewCrindDisplay = "CP-DCN-DDSP";
  _pcPortalViewCrindKeyboard = "CP-DCN-DKBD";
  _pcPortalViewCrindPrinter = "CP-DCN-DPNT";
  _pcPortalViewCrindUPM = "CP-DCN-DUPM";
  _pcPortalViewCEFlowRate = "CP-DCE-DFRT";
  _pcPortalViewCEPaperOut = "CP-DCE-PPOT";
  _pcPortalViewCENoTrans = "CP-DCU-NTSC";
  _pcPortalViewWarrantyStatus = "CP-SIT-SWRY";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      dispensers: null,
      siteItem: null,
      listSites: null,
      site: null,
      partPrivileges: this.initPartPrivileges(),
      sites: null,
      searchText: "",
      searchLoader: false,
      active: "30 Days",
      buttons: [
        { days: "7", name: "7 Days" },
        { days: "30", name: "30 Days" },
        { days: "90", name: "90 Days" },
      ],
      selectedTab: [
        { tab: "1", name: "Dispensers" },
        { tab: "2", name: "ATG" },
        { tab: "3", name: "EV" },
      ],
      days: 30,
      tab: "1",
      activeTab: "Dispensers",
      isAlertsDataFetched: false,
      isFlowRateDataFetched: false,
      isComponentDataFetched: false,
      componentStatus: null,
      newAlertTickets: null,
      isnewAlertTicketsDataFetched: false,
      inprocessAlertTickets: null,
      isInProcessAlertTicketsDataFetched: false,
      isTabPageDataFetched: false,
      warrantyStatus: null,
      iswarrantyDataFetched: false,
      showALertsModal: false,
      allAlerts: null,
      closedTickets: null,
      openTickets: null,
      dispatchTickets: null,
      isAllAlertsDataFetched: false,
      isAlertTicketModal: false,
      ticketStatus: null,
      alertTicketData: null,
      dashboardColor: null,
      flowRateStatus: null,
      isNoTransactionDataFetched: false,
      noTransactionData: null,
      flowStatusError: null,
      noTransactionError: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
    this.interval = setInterval(() => {
      if (this.props.pendingRequest === false && this.state.tab === "1") {
        if (
          this.state.partPrivileges[this._pcPortalViewCrindCardReader] ===
            true ||
          this.state.partPrivileges[this._pcPortalViewCrindDisplay] === true ||
          this.state.partPrivileges[this._pcPortalViewCrindKeyboard] === true ||
          this.state.partPrivileges[this._pcPortalViewCrindPrinter] === true ||
          this.state.partPrivileges[this._pcPortalViewCrindUPM] === true ||
          this.state.partPrivileges[this._pcPortalViewPumpTwoWireStatus] ===
            true ||
          this.state.partPrivileges[this._pcPortalViewCEPaperOut] === true ||
          this.state.partPrivileges[this._pcPortalViewConnectivityStatus] ===
            true ||
          this.state.partPrivileges[this._pcPortalViewTickets] === true
        ) {
          this.getComponentStatus();
          this.getNewAlertTickets();
          this.getInProcessAlertTickets();
        }
      }
    }, 2 * 60 * 1000);
    if (
      this.state.partPrivileges[this._pcPortalViewCrindCardReader] === true ||
      this.state.partPrivileges[this._pcPortalViewCrindDisplay] === true ||
      this.state.partPrivileges[this._pcPortalViewCrindKeyboard] === true ||
      this.state.partPrivileges[this._pcPortalViewCrindPrinter] === true ||
      this.state.partPrivileges[this._pcPortalViewCrindUPM] === true ||
      this.state.partPrivileges[this._pcPortalViewPumpTwoWireStatus] === true ||
      this.state.partPrivileges[this._pcPortalViewCEPaperOut] === true ||
      this.state.partPrivileges[this._pcPortalViewConnectivityStatus] === true
    ) {
      this.getComponentStatus();
    }

    // For ALerts Data
    if (this.state.partPrivileges[this._pcPortalViewTickets] === true) {
      this.setStateAsync({ isAlertsDataFetched: true });
      this.getNewAlertTickets();
      this.getInProcessAlertTickets();
    }
    // For FlowRate Data
    if (this.state.partPrivileges[this._pcPortalViewCEFlowRate] === true) {
      this.getFlowRate();
    }
    //for warranty status
    if (this.state.partPrivileges[this._pcPortalViewWarrantyStatus] === true) {
      this.getWarrantyStatus();
    }

    if (this.state.partPrivileges[this._pcPortalViewCENoTrans] === true) {
      this.getNoTransactionStatus();
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      PageUtils.scrollToTop();
      await this.loadPageData();
      this.interval = setInterval(() => {
        if (this.props.pendingRequest === false && this.state.tab === "1") {
          if (
            this.state.partPrivileges[this._pcPortalViewCrindCardReader] ===
              true ||
            this.state.partPrivileges[this._pcPortalViewCrindDisplay] ===
              true ||
            this.state.partPrivileges[this._pcPortalViewCrindKeyboard] ===
              true ||
            this.state.partPrivileges[this._pcPortalViewCrindPrinter] ===
              true ||
            this.state.partPrivileges[this._pcPortalViewCrindUPM] === true ||
            this.state.partPrivileges[this._pcPortalViewPumpTwoWireStatus] ===
              true ||
            this.state.partPrivileges[this._pcPortalViewCEPaperOut] === true ||
            this.state.partPrivileges[this._pcPortalViewConnectivityStatus] ===
              true ||
            this.state.partPrivileges[this._pcPortalViewTickets] === true
          ) {
            this.getComponentStatus();
            this.getNewAlertTickets();
            this.getInProcessAlertTickets();
          }
        }
      }, 2 * 60 * 1000);
      if (
        this.state.partPrivileges[this._pcPortalViewCrindCardReader] === true ||
        this.state.partPrivileges[this._pcPortalViewCrindDisplay] === true ||
        this.state.partPrivileges[this._pcPortalViewCrindKeyboard] === true ||
        this.state.partPrivileges[this._pcPortalViewCrindPrinter] === true ||
        this.state.partPrivileges[this._pcPortalViewCrindUPM] === true ||
        this.state.partPrivileges[this._pcPortalViewPumpTwoWireStatus] ===
          true ||
        this.state.partPrivileges[this._pcPortalViewCEPaperOut] === true ||
        this.state.partPrivileges[this._pcPortalViewConnectivityStatus] === true
      ) {
        this.getComponentStatus();
      }

      // For ALerts Data
      if (this.state.partPrivileges[this._pcPortalViewTickets] === true) {
        this.setStateAsync({ isAlertsDataFetched: true });
        this.getNewAlertTickets();
        this.getInProcessAlertTickets();
      }
      // For FlowRate Data
      if (this.state.partPrivileges[this._pcPortalViewCEFlowRate] === true) {
        this.getFlowRate();
      }
      //for warranty status
      if (
        this.state.partPrivileges[this._pcPortalViewWarrantyStatus] === true
      ) {
        this.getWarrantyStatus();
      }

      if (this.state.partPrivileges[this._pcPortalViewCENoTrans] === true) {
        this.getNoTransactionStatus();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
    clearInterval(this.interval);
  }

  loadPageData = async () => {
    await this.setStateAsync({
      isPageDataFetched: false,
      isTabPageDataFetched: false,
    });
    await this.setStateAsync({
      dispensers: null,
      sites: null,
      siteItem: null,
      site: null,
      listSites: null,
      isAlertsDataFetched: false,
      isFlowRateDataFetched: false,
      componentStatus: null,
      isComponentDataFetched: false,
      isInProcessAlertTicketsDataFetched: false,
      isnewAlertTicketsDataFetched: false,
      dashboardColor: null,
      isNoTransactionDataFetched: false,
      searchText: "",
      searchLoader: false,
    });
    await this.checkPartPrivilege();
    await this.setPartPrivileges();

    const siteId = this.props.match.params.sid;
    await siteView(siteId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({
            siteItem: res.data.result,
            site: [res.data.result],
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await dispensersList(siteId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records?.length > 0) {
            await this.setStateAsync({ dispensers: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await this.setStateAsync({
      isPageDataFetched: true,
      isTabPageDataFetched: true,
    });
  };

  getComponentStatus = async () => {
    if (this.state.isComponentDataFetched === true) {
    } else {
      await this.setStateAsync({ isComponentDataFetched: false });
      await this.setStateAsync({
        componentStatus: null,
      });
    }
    const siteId = this.props.match.params.sid;
    const queryData = "extractType=latest";
    await componentStatus(siteId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records?.length > 0) {
            await this.setStateAsync({ componentStatus: res.data.records });

            let updatedList = res.data.records;
            if (updatedList && updatedList?.length > 0) {
              updatedList = updatedList.map((item) =>
                item?.connectivity.map((obj) => {
                  if (obj.componentStatus === "Red") return item;
                })
              );
            }
            var merged = [].concat.apply([], updatedList);
            merged = merged.filter(function (element) {
              return element !== undefined;
            });
            await this.setStateAsync({ dashboardColor: merged });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isComponentDataFetched: true });
  };

  getNewAlertTickets = async () => {
    if (this.state.isnewAlertTicketsDataFetched === true) {
    } else {
      await this.setStateAsync({ isnewAlertTicketsDataFetched: false });
      await this.setStateAsync({
        newAlertTickets: null,
      });
    }
    const siteId = this.props.match.params.sid;
    const queryData = "extractType=count&ticketStatus=N";
    await tickets(siteId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records?.length > 0) {
            await this.setStateAsync({ newAlertTickets: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isnewAlertTicketsDataFetched: true });
  };

  getInProcessAlertTickets = async () => {
    if (this.state.isInProcessAlertTicketsDataFetched === true) {
    } else {
      await this.setStateAsync({ isInProcessAlertTicketsDataFetched: false });
      await this.setStateAsync({
        inprocessAlertTickets: null,
      });
    }
    const siteId = this.props.match.params.sid;
    const queryData = "extractType=count&ticketStatus=I";
    await tickets(siteId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records?.length > 0) {
            await this.setStateAsync({
              inprocessAlertTickets: res.data.records,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isInProcessAlertTicketsDataFetched: true });
  };

  getWarrantyStatus = async () => {
    if (this.state.iswarrantyDataFetched === true) {
    } else {
      await this.setStateAsync({ iswarrantyDataFetched: false });
      await this.setStateAsync({
        warrantyStatus: null,
      });
    }
    const siteId = this.props.match.params.sid;
    const queryData = "extractType=warranty";
    await warranty(siteId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records?.length > 0) {
            await this.setStateAsync({
              warrantyStatus: res.data.records,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ iswarrantyDataFetched: true });
  };

  getFlowRate = async () => {
    if (this.state.isFlowRateDataFetched === true) {
    } else {
      await this.setStateAsync({ isFlowRateDataFetched: false });
      await this.setStateAsync({
        flowRateStatus: null,
        flowStatusError: null,
      });
    }
    const siteId = this.props.match.params.sid;
    const queryData = "extractType=latest";
    await flowRate(siteId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records?.length > 0) {
            await this.setStateAsync({
              flowRateStatus: res.data.records,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        // await manageError(err, this.props.history);
        await this.setStateAsync({ flowStatusError: "error" });
      });
    await this.setStateAsync({ isFlowRateDataFetched: true });
  };

  getNoTransactionStatus = async () => {
    if (this.state.isNoTransactionDataFetched === true) {
    } else {
      await this.setStateAsync({ isNoTransactionDataFetched: false });
      await this.setStateAsync({
        noTransactionData: null,
        noTransactionError: null,
      });
    }
    const siteId = this.props.match.params.sid;
    const queryData = "extractType=latest";
    await transactionStatus(siteId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records?.length > 0) {
            await this.setStateAsync({
              noTransactionData: res.data.records,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        // await manageError(err, this.props.history);
        await this.setStateAsync({ noTransactionError: "error" });
      });
    await this.setStateAsync({ isNoTransactionDataFetched: true });
  };

  initPartPrivileges = () => {
    return {
      [this._pcPortalSiteDispenserView]: false,
      [this._pcPortalSiteView]: false,
      [this._pcPortalViewConnectivityStatus]: false,
      [this._pcPortalViewTickets]: false,
      [this._pcPortalViewPumpTwoWireStatus]: false,
      [this._pcPortalViewPumpSerialInterface]: false,
      [this._pcPortalViewCrindCardReader]: false,
      [this._pcPortalViewCrindDisplay]: false,
      [this._pcPortalViewCrindKeyboard]: false,
      [this._pcPortalViewCrindPrinter]: false,
      [this._pcPortalViewCrindUPM]: false,
      [this._pcPortalViewCEFlowRate]: false,
      [this._pcPortalViewCEPaperOut]: false,
      [this._pcPortalViewCENoTrans]: false,
      [this._pcPortalViewWarrantyStatus]: false,
    };
  };

  checkPartPrivilege = async () => {
    const partCodes = [
      this._pcPortalSiteDispenserView,
      this._pcPortalSiteView,
      this._pcPortalViewConnectivityStatus,
      this._pcPortalViewTickets,
      this._pcPortalViewPumpTwoWireStatus,
      this._pcPortalViewPumpSerialInterface,
      this._pcPortalViewCrindCardReader,
      this._pcPortalViewCrindDisplay,
      this._pcPortalViewCrindKeyboard,
      this._pcPortalViewCrindPrinter,
      this._pcPortalViewCrindUPM,
      this._pcPortalViewCEFlowRate,
      this._pcPortalViewCEPaperOut,
      this._pcPortalViewCENoTrans,
      this._pcPortalViewWarrantyStatus,
    ];
    const partPrivilege = await getPagePartsPrivilege(
      partCodes,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(PortalURLProps.pageNotFound);
    }
  };
  setPartPrivileges = async () => {
    const partCodes = [
      this._pcPortalSiteDispenserView,
      this._pcPortalSiteView,
      this._pcPortalViewConnectivityStatus,
      this._pcPortalViewTickets,
      this._pcPortalViewPumpTwoWireStatus,
      this._pcPortalViewPumpSerialInterface,
      this._pcPortalViewCrindCardReader,
      this._pcPortalViewCrindDisplay,
      this._pcPortalViewCrindKeyboard,
      this._pcPortalViewCrindPrinter,
      this._pcPortalViewCrindUPM,
      this._pcPortalViewCEFlowRate,
      this._pcPortalViewCEPaperOut,
      this._pcPortalViewCENoTrans,
      this._pcPortalViewWarrantyStatus,
    ];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  onSearchChange = (e) => {
    this.setStateAsync({ searchText: e.target.value });
    var updatedList = this.state.listSites;
    if (updatedList && updatedList?.length > 0) {
      updatedList = updatedList.filter(function (item) {
        return (
          item["siteName"]
            .toLowerCase()
            .search(e.target.value.toLowerCase()) !== -1 ||
          (item["siteNumber"] &&
            item.siteNumber.toString().search(e.target.value.toString()) !== -1)
        );
      });
    }
    this.setStateAsync({ sites: updatedList });
  };
  clearPreviousSearch = async () => {
    await this.setStateAsync({ searchText: "" });
    var updatedList = this.state.listSites;
    await this.setStateAsync({ sites: updatedList });
    if (this.state.searchLoader === false) {
      await this.getSitesList();
    }
  };

  getSitesList = async () => {
    await this.setStateAsync({ searchLoader: false });
    const queryData = "sortField=siteName&sortOrder=1";
    await listSites(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records?.length > 0) {
            await this.setStateAsync({
              sites: res.data.records,
              listSites: res.data.records,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ searchLoader: true });
  };

  async isButtonActive(name, tab) {
    await this.setStateAsync({ activeTab: name, tab: tab });
    await this.setStateAsync({ isTabPageDataFetched: false });
    await checkToken(this.props, this._cancelToken);
    await this.setStateAsync({ isTabPageDataFetched: true });
  }

  getNewAlerts = async (dispenser, status) => {
    await this.setStateAsync({
      isAlertTicketModal: true,
      isAllAlertsDataFetched: false,
    });
    await this.setStateAsync({
      alertTicketData: null,
    });
    const queryData =
      "extractType=list&alertEventStatus=O&ticketStatus=A&dataPeriod=&pageOffset=1&pageLimit=100000&sortField=createTimestamp&sortOrder=-1";
    const siteId = this.props.match.params.sid;
    const dispenserId = dispenser;
    await alertEvents(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({ alertTicketData: res.data.records });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isAllAlertsDataFetched: true });
  };

  getInProgressAlerts = async (dispenser, status) => {
    await this.setStateAsync({
      isAlertTicketModal: true,
      isAllAlertsDataFetched: false,
    });
    await this.setStateAsync({
      alertTicketData: null,
    });
    const queryData =
      "extractType=list&ticketStatus=A&dataPeriod=&pageOffset=1&pageLimit=100000&sortField=createTimestamp&sortOrder=-1";
    const siteId = this.props.match.params.sid;
    const dispenserId = dispenser;
    await alerts(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let inProgressTickets = [];
          res.data.records.forEach(function (element) {
            if (
              element.ticketStatus === "In Progress" ||
              element.ticketStatus === "New"
            ) {
              inProgressTickets.push(element);
            }
          });
          await this.setStateAsync({ alertTicketData: inProgressTickets });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isAllAlertsDataFetched: true });
  };

  showWarrantyStatus(description, date) {
    if (description === "In") {
      return (
        <div className="tooltip ml-1">
          {" "}
          <span>
            &nbsp;
            <img
              src={Green}
              className="tooltip"
              alt="Green"
              style={{ height: "20px", width: "20px" }}
            />
          </span>
          <span className="tooltiptext-1">
            {description} <br /> ({AppUtils.getDate(date)})
          </span>
        </div>
      );
    } else {
      return (
        <div className="tooltip ml-1">
          {" "}
          <span>
            &nbsp;
            <img
              src={Red}
              className="tooltip"
              alt="Red"
              style={{ height: "20px", width: "20px" }}
            />
          </span>
          <span className="tooltiptext-1">
            {description} <br /> ({AppUtils.getDate(date)})
          </span>
        </div>
      );
    }
  }

  getAlertsTabs = async (dispenser, status) => {
    await this.setStateAsync({ showALertsModal: true, ticketStatus: status });
    if (status === "New") {
      await this.getNewAlerts(dispenser, status);
    } else if (status === "In Progress") {
      await this.getInProgressAlerts(dispenser, status);
    }
  };

  clearAlertsData = async () => {
    await this.setStateAsync({ alertTicketData: null });
  };

  showOpenAlerts(obj, dispenser, status) {
    if (obj <= 0) {
      return <span>{obj}</span>;
    } else {
      return (
        <div className="tooltip ml-1">
          {" "}
          <span
            className="sram-color-blue"
            onClick={() => this.getAlertsTabs(dispenser, status)}
          >
            {obj}
          </span>
          <span className="tooltiptext-1">View Alert details</span>
        </div>
      );
    }
  }

  closeAlertTicketModal = async () => {
    this.setState({
      isAlertTicketModal: false,
    });
  };

  showNoTransFlagsData(flags, dataDate, gradeNumber) {
    if (flags) {
      if (flags.toLowerCase() === "green") {
        return (
          <div className="tooltip ml-1">
            {" "}
            <span>
              &nbsp;
              <img
                src={Green}
                className="tooltip"
                alt="Green"
                style={{ height: "20px", width: "20px" }}
              />
            </span>
            <span className="tooltiptext-1">
              ({AppUtils.getDate(dataDate)})
            </span>
          </div>
        );
      } else if (flags.toLowerCase() === "red") {
        return (
          <div className="tooltip ml-1">
            {" "}
            <span>
              &nbsp;
              <img
                src={Red}
                className="tooltip"
                alt="Red"
                style={{ height: "20px", width: "20px" }}
              />
            </span>
            <span className="tooltiptext-1">
              Grade: {gradeNumber?.join(", ")} <br />(
              {AppUtils.getDate(dataDate)})
            </span>{" "}
          </div>
        );
      } else {
        return (
          <div className="tooltip ml-1">
            {" "}
            <span>
              &nbsp;
              <img
                src={Yellow}
                className="tooltip"
                alt="Yellow"
                style={{ height: "20px", width: "20px" }}
              />
            </span>
            <span className="tooltiptext-1">
              Grade: {gradeNumber?.join(", ")} <br />(
              {AppUtils.getDate(dataDate)})
            </span>
          </div>
        );
      }
    } else {
      return (
        <div className="tooltip ml-1">
          {" "}
          <span>
            &nbsp;
            <img
              src={Green}
              className="tooltip"
              alt="Green"
              style={{ height: "20px", width: "20px" }}
            />
          </span>
        </div>
      );
    }
  }

  showFlowRateFlagsData(flags, flowRate, gradeNumber, dataDate) {
    if (flags) {
      if (flags.toLowerCase() === "yellow") {
        return (
          <div className="tooltip ml-1">
            {" "}
            <span>
              &nbsp;
              <img
                src={Yellow}
                className="tooltip"
                alt="Yellow"
                style={{ height: "20px", width: "20px" }}
              />
            </span>
            <span className="tooltiptext-1">
              Flow Rate : {flowRate?.join(", ")} <br /> Grade Number :{" "}
              {gradeNumber?.join(", ")} <br />({AppUtils.getDate(dataDate)} )
            </span>
          </div>
        );
      } else {
        return (
          <div className="tooltip ml-1">
            {" "}
            <span>
              &nbsp;
              <img
                src={Green}
                className="tooltip"
                alt="Green"
                style={{ height: "20px", width: "20px" }}
              />
            </span>
            <span className="tooltiptext-1">
              ({AppUtils.getDate(dataDate)} )
            </span>
          </div>
        );
      }
    } else {
      return (
        <div className="tooltip ml-1">
          {" "}
          <span>
            &nbsp;
            <img
              src={Green}
              className="tooltip"
              alt="Green"
              style={{ height: "20px", width: "20px" }}
            />
          </span>
        </div>
      );
    }
  }

  getItemStatus(itemsStatus) {
    return itemsStatus?.length > 0 ? (
      itemsStatus.map((data, i) => {
        let icon =
          data.componentStatus === "Green"
            ? Green
            : data.componentStatus === "Red"
            ? Red
            : data.componentStatus === "Yellow"
            ? Yellow
            : data.componentStatus === "Gray"
            ? Gray
            : null;
        return (
          <Col key={i}>
            <span className="sram-site-door">
              <span>
                <div className="tooltip ml-1">
                  {" "}
                  <span>
                    &nbsp;
                    <img
                      src={icon}
                      className="tooltip"
                      alt={icon}
                      style={{ height: "20px", width: "20px" }}
                    />
                  </span>
                  {data.componentStatus === "Gray" ? null : (
                    <>
                      {data.description ? (
                        <span className="tooltiptext-1">
                          {data.description} <br />
                          {AppUtils.getDateTime(data.deviceTimestamp)}
                        </span>
                      ) : data.deviceTimestamp ? (
                        <span className="tooltiptext-1">
                          {AppUtils.getDateTime(data.deviceTimestamp)}
                        </span>
                      ) : null}
                    </>
                  )}
                </div>
              </span>
            </span>
          </Col>
        );
      })
    ) : (
      <Col className=" sram-no-data">
        {SiteMsgResProps.body.content.noIssuesFound}
      </Col>
    );
  }

  getStatusA(itemsStatus) {
    let itemDescription = this.getDescription(itemsStatus, "A");
    let componentStatus = this.status(itemsStatus, "A");
    let icon =
      componentStatus === "Green"
        ? Green
        : componentStatus === "Red"
        ? Red
        : componentStatus === "Yellow"
        ? Yellow
        : componentStatus === "Gray"
        ? Gray
        : null;
    return (
      <>
        {itemsStatus?.length > 0 ? (
          <span className="sram-site-door">
            <div className="tooltip ml-1">
              {" "}
              <span>
                &nbsp;
                <img
                  src={icon}
                  className="tooltip"
                  alt={icon}
                  style={{ height: "20px", width: "20px" }}
                />
              </span>
              {componentStatus === "Gray" ? null : (
                <>
                  {itemDescription.every((value) => value === null) ? null : (
                    <span className="tooltiptext-1">{itemDescription}</span>
                  )}
                </>
              )}
            </div>
          </span>
        ) : (
          <span className="sram-no-data">
            {SiteMsgResProps.body.content.noIssuesFound}
          </span>
        )}
      </>
    );
  }

  getStatusB(itemsStatus) {
    let itemDescription = this.getDescription(itemsStatus, "B");
    let componentStatus = this.status(itemsStatus, "B");
    let icon =
      componentStatus === "Green"
        ? Green
        : componentStatus === "Red"
        ? Red
        : componentStatus === "Yellow"
        ? Yellow
        : componentStatus === "Gray"
        ? Gray
        : null;
    return (
      <>
        {itemsStatus?.length > 0 ? (
          <span className="sram-site-door">
            <div className="tooltip ml-1">
              {" "}
              <span>
                &nbsp;
                <img
                  src={icon}
                  className="tooltip"
                  alt={icon}
                  style={{ height: "20px", width: "20px" }}
                />
              </span>
              {componentStatus === "Gray" ? null : (
                <>
                  {itemDescription.every((value) => value === null) ? null : (
                    <span className="tooltiptext-1">{itemDescription}</span>
                  )}
                </>
              )}
            </div>
          </span>
        ) : (
          <span className="sram-no-data">
            {SiteMsgResProps.body.content.noIssuesFound}
          </span>
        )}
      </>
    );
  }

  getDescription(itemsStatus, sideLabel) {
    let componentDescription = [];
    if (itemsStatus) {
      itemsStatus.forEach(function (data, index) {
        if (data.sideLabel === sideLabel) {
          componentDescription.push(
            data.description ? (
              <div key={`description_${index}`}>
                {data.description === "paper out" ||
                data.description === "jammed" ||
                data.description === "paper jam" ||
                data.description === "paper low"
                  ? "Receipt Paper Error"
                  : data.description}{" "}
                <br />
              </div>
            ) : null,
            data.deviceTimestamp ? (
              <div key={`deviceTimestamp_${index}`}>
                {AppUtils.getDateTime(data.deviceTimestamp)} <br />
              </div>
            ) : null
          );
        }
      });
    }
    return componentDescription;
  }

  status(itemsStatus, sideLabel) {
    let status = null;
    if (itemsStatus) {
      itemsStatus.forEach(function (data) {
        if (data.sideLabel === sideLabel) {
          status = data.componentStatus;
        }
      });
    }
    return status;
  }

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-full">
            <Row>
              <div className="breadcrumb sram-bread-crumb">
                <Col sm={12} md={6} lg={6}>
                  <span className="font-light font-18">
                    <RRNavLink to="/portal/sites">
                      <span className="sram-black-clr">
                        {SiteMsgResProps.head.title.sitesHome}
                      </span>
                    </RRNavLink>
                  </span>
                  <span>
                    &nbsp;&nbsp;
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    &nbsp;&nbsp;
                    {this.state.site && this.state.site?.length > 0 ? (
                      <span className="font-light font-18">
                        {this.state.siteItem.siteName}(
                        {SiteMsgResProps.body.form.siteId.label} :{" "}
                        {this.state.siteItem.siteNumber})
                      </span>
                    ) : null}
                  </span>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <UncontrolledDropdown className="sram-site-pull-dropdown-right">
                    <DropdownToggle className="sram-dropdown-transparent">
                      <span onClick={() => this.clearPreviousSearch()}>
                        <i className="fa fa-th sram-black-clr font-medium font-22"></i>
                      </span>
                    </DropdownToggle>

                    <DropdownMenu className="sram-sites-dropdown-menu sram-sites-dropdown-menu-width">
                      <WithLoading
                        isPageDataFetched={this.state.searchLoader}
                        type="page"
                        size="md"
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Site"
                          value={this.state.searchText}
                          onChange={this.onSearchChange}
                        />
                        {this.state.sites && this.state.sites?.length > 0 ? (
                          this.state.sites.map((site, index) => {
                            return (
                              <RRNavLink
                                key={`key-sitesList-${index}`}
                                to={
                                  "/portal/sites/" + site.siteId + "/dashboard"
                                }
                              >
                                <DropdownItem className="sram-drop-down-item">
                                  <span className="sram-site-style sram-black-clr">
                                    {site.siteName} (
                                    {SiteMsgResProps.body.form.siteId.label} :{" "}
                                    {site.siteNumber})
                                  </span>
                                </DropdownItem>
                              </RRNavLink>
                            );
                          })
                        ) : (
                          <DropdownItem className="sram-drop-down-item">
                            <span className="sram-site-style sram-black-clr">
                              {SiteMsgResProps.body.content.noIssuesFound}
                            </span>
                          </DropdownItem>
                        )}
                      </WithLoading>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <div className="sram-dispenser-pull-dropdown-right">
                    {this.state.selectedTab.map((name, i) => {
                      return (
                        <Button
                          key={i}
                          type="submit"
                          className={
                            this.state.activeTab === name.name
                              ? "btn btn-secondary sram-isbutton-active"
                              : "btn btn-secondary sram-not-active-button"
                          }
                          onClick={this.isButtonActive.bind(
                            this,
                            name.name,
                            name.tab
                          )}
                        >
                          {name.name}
                        </Button>
                      );
                    })}
                    &nbsp;
                  </div>
                </Col>
              </div>
            </Row>
            {this.state.tab === "1" && this.state.activeTab === "Dispensers" ? (
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="sram-page-top"
              >
                <div className="table-responsive">
                  <WithLoading
                    isPageDataFetched={this.state.isTabPageDataFetched}
                    type="page"
                    size="md"
                  >
                    <div className="sram-table-wrapper-dispenser-sites">
                      {this.state.dispensers &&
                      this.state.dispensers?.length > 0 ? (
                        <Table>
                          <thead>
                            <tr>
                              <th className="sram-site-dispenser-table-border1 sram-site-dispenser-table-cell-background"></th>
                              <th className="sram-site-dispenser-table-border1 sram-site-dispenser-table-cell-background"></th>
                              {this.state.dispensers &&
                              this.state.dispensers?.length > 0 ? (
                                <>
                                  {this.state.dispensers.map(
                                    (dispenser, index) => {
                                      return (
                                        <th
                                          key={`key-dispensersList-${index}`}
                                          className="sram-site-dispenser-table-heading sram-bg-blue-7"
                                        >
                                          <RRNavLink
                                            to={
                                              "/portal/sites/" +
                                              this.props.match.params.sid +
                                              "/dispensers/" +
                                              dispenser.dispenserId +
                                              "/dashboard"
                                            }
                                          >
                                            <Row className="sram-site-dispensers-diplay">
                                              <Col
                                                xs={8}
                                                sm={8}
                                                md={8}
                                                lg={8}
                                                xl={8}
                                              >
                                                {"Dispenser " +
                                                  dispenser.sideANumber +
                                                  "/" +
                                                  dispenser.sideBNumber}
                                              </Col>
                                              <Col
                                                xs={4}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={4}
                                              >
                                                <span className="fa fa-arrow-right"></span>
                                              </Col>
                                            </Row>
                                          </RRNavLink>
                                        </th>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <div className="sram-td-sub sram-no-data ">
                                  {SiteMsgResProps.body.content.noIssuesFound}
                                </div>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {/* Manufacturer & Model */}
                            <tr>
                              <td className="sram-site-dispenser-table-border p-0">
                                <div className="font-mild sram-td-sub">
                                  <Row className="sram-td-sub">
                                    <Col className="ml-1">
                                      {
                                        SiteMsgResProps.head.title
                                          .manufacturerModel
                                      }
                                    </Col>
                                  </Row>
                                </div>
                              </td>
                              <td className="sram-site-dispenser-table-border1 p-0 sram-table-body-adj sram-site-dispenser-table-border-right"></td>
                              {this.state.dispensers &&
                              this.state.dispensers?.length > 0 ? (
                                <>
                                  {this.state.dispensers.map(
                                    (dispenser, index) => {
                                      return (
                                        <td
                                          key={`key-manufacturer&model-${index}`}
                                          className="sram-site-dispenser-table-body sram-table-body-adj"
                                        >
                                          <div className="font-mild sram-td-sub">
                                            <Row className="sram-td-sub sram-site-dashboard-font">
                                              <Col>
                                                {dispenser.manufacturerName} &
                                                &nbsp;
                                                {dispenser.modelName}
                                              </Col>
                                            </Row>
                                          </div>
                                        </td>
                                      );
                                    }
                                  )}
                                </>
                              ) : (
                                <div className="sram-td-sub sram-no-data ">
                                  {SiteMsgResProps.body.content.noIssuesFound}
                                </div>
                              )}
                            </tr>

                            {/*  Warranty Status */}
                            {this.state.partPrivileges[
                              this._pcPortalViewWarrantyStatus
                            ] === true ? (
                              <tr>
                                <td className="sram-site-dispenser-table-border p-0">
                                  <div className="font-mild sram-td-sub">
                                    <Row className="sram-td-sub">
                                      <Col className="ml-2">
                                        {SiteMsgResProps.head.title.warranty}
                                      </Col>
                                    </Row>
                                  </div>
                                </td>
                                <td className="sram-site-dispenser-table-border1 p-0">
                                  <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                    <Row className="sram-td-sub">
                                      <Col className="">
                                        {
                                          SiteMsgResProps.body.form
                                            .warrantyStatus.label
                                        }
                                      </Col>
                                    </Row>
                                  </div>
                                </td>

                                {this.state.dispensers &&
                                this.state.dispensers?.length > 0 ? (
                                  <>
                                    {this.state.dispensers.map(
                                      (dispenser, index) => {
                                        return (
                                          <td
                                            key={`key-dispensers-${index}`}
                                            className="sram-site-dispenser-table-body sram-table-body-adj"
                                          >
                                            <div className="font-mild sram-td-sub">
                                              <WithLoading
                                                isPageDataFetched={
                                                  this.state
                                                    .iswarrantyDataFetched
                                                }
                                                type="page"
                                                className="sram-spinner"
                                                size="sm"
                                              >
                                                {this.state.warrantyStatus &&
                                                this.state.warrantyStatus
                                                  ?.length > 0 ? (
                                                  this.state.warrantyStatus.map(
                                                    (obj, i) => {
                                                      if (
                                                        dispenser.dispenserId ===
                                                        obj?.dispenserId
                                                      ) {
                                                        return (
                                                          <Row
                                                            className="sram-td-sub"
                                                            key={i}
                                                          >
                                                            {obj?.warrantyDescription &&
                                                            obj?.warrantyExpiryDate ? (
                                                              <Col key={i}>
                                                                <span
                                                                  key={i}
                                                                  className="sram-site-door"
                                                                >
                                                                  <span>
                                                                    {this.showWarrantyStatus(
                                                                      obj?.warrantyDescription,
                                                                      obj?.warrantyExpiryDate
                                                                    )}
                                                                  </span>
                                                                </span>
                                                              </Col>
                                                            ) : (
                                                              <Col className="sram-no-data">
                                                                {
                                                                  SiteMsgResProps
                                                                    .body
                                                                    .content
                                                                    .noIssuesFound
                                                                }
                                                              </Col>
                                                            )}
                                                          </Row>
                                                        );
                                                      }
                                                    }
                                                  )
                                                ) : (
                                                  <Row className="sram-td-sub">
                                                    <Col className="sram-no-data">
                                                      {
                                                        SiteMsgResProps.body
                                                          .content.noIssuesFound
                                                      }
                                                    </Col>
                                                  </Row>
                                                )}
                                              </WithLoading>
                                            </div>
                                          </td>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <div className="sram-td-sub sram-no-data ">
                                    {SiteMsgResProps.body.content.noIssuesFound}
                                  </div>
                                )}
                              </tr>
                            ) : null}

                            {/* ALERTS TABS */}
                            {this.state.partPrivileges[
                              this._pcPortalViewTickets
                            ] === true ? (
                              <tr>
                                <td className="sram-site-dispenser-table-border">
                                  <div className="font-mild sram-td-sub">
                                    <Row className="sram-td-sub">
                                      <Col className="">
                                        {SiteMsgResProps.head.title.openAlerts}
                                      </Col>
                                    </Row>
                                  </div>
                                </td>
                                <td className="sram-site-dispenser-table-border1">
                                  <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                    <Row className="sram-td-sub">
                                      <Col className="">
                                        {SiteMsgResProps.body.form.new.label}
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                    <Row className="sram-td-sub">
                                      <Col className="">
                                        {
                                          SiteMsgResProps.body.form.inProgress
                                            .label
                                        }
                                      </Col>
                                    </Row>
                                  </div>
                                </td>

                                {this.state.dispensers &&
                                this.state.dispensers?.length > 0 ? (
                                  <>
                                    {this.state.dispensers.map(
                                      (dispenser, index) => {
                                        return (
                                          <td
                                            key={index}
                                            className="sram-site-dispenser-table-body sram-table-body-adj"
                                          >
                                            <div className="font-mild sram-td-sub">
                                              <WithLoading
                                                isPageDataFetched={
                                                  this.state
                                                    .isnewAlertTicketsDataFetched
                                                }
                                                type="page"
                                                className="sram-spinner"
                                                size="sm"
                                              >
                                                {this.state.newAlertTickets &&
                                                this.state.newAlertTickets
                                                  ?.length > 0 ? (
                                                  this.state.newAlertTickets.map(
                                                    (obj, i) => {
                                                      if (
                                                        dispenser.dispenserId ===
                                                        obj?.dispenserId
                                                      ) {
                                                        return (
                                                          <Row
                                                            className="sram-td-sub"
                                                            key={i}
                                                          >
                                                            {obj?.alertCounts
                                                              ?.length > 0 ? (
                                                              obj?.alertCounts.map(
                                                                (data, i) => {
                                                                  return (
                                                                    <Col
                                                                      key={i}
                                                                      className="sram-site-dashboard-font ml-2"
                                                                    >
                                                                      {this.showOpenAlerts(
                                                                        data.ticketCount,
                                                                        dispenser.dispenserId,
                                                                        "New"
                                                                      )}
                                                                    </Col>
                                                                  );
                                                                }
                                                              )
                                                            ) : (
                                                              <Col className="sram-no-data">
                                                                {
                                                                  SiteMsgResProps
                                                                    .body
                                                                    .content
                                                                    .noIssuesFound
                                                                }
                                                              </Col>
                                                            )}
                                                          </Row>
                                                        );
                                                      }
                                                    }
                                                  )
                                                ) : (
                                                  <div className="sram-td-sub sram-no-data">
                                                    {
                                                      SiteMsgResProps.body
                                                        .content.noIssuesFound
                                                    }
                                                  </div>
                                                )}
                                              </WithLoading>
                                            </div>

                                            <div className="font-mild sram-td-sub">
                                              <WithLoading
                                                isPageDataFetched={
                                                  this.state
                                                    .isInProcessAlertTicketsDataFetched
                                                }
                                                type="page"
                                                className="sram-spinner"
                                                size="sm"
                                              >
                                                {this.state
                                                  .inprocessAlertTickets &&
                                                this.state.inprocessAlertTickets
                                                  ?.length > 0 ? (
                                                  this.state.inprocessAlertTickets.map(
                                                    (obj, i) => {
                                                      if (
                                                        dispenser.dispenserId ===
                                                        obj?.dispenserId
                                                      ) {
                                                        return (
                                                          <Row
                                                            className="sram-td-sub"
                                                            key={i}
                                                          >
                                                            {obj?.alertCounts
                                                              ?.length > 0 ? (
                                                              obj?.alertCounts.map(
                                                                (data, i) => {
                                                                  return (
                                                                    <Col
                                                                      key={i}
                                                                      className="sram-site-dashboard-font ml-2"
                                                                    >
                                                                      {this.showOpenAlerts(
                                                                        data.ticketCount,
                                                                        dispenser.dispenserId,
                                                                        "In Progress"
                                                                      )}
                                                                    </Col>
                                                                  );
                                                                }
                                                              )
                                                            ) : (
                                                              <Col className="sram-no-data">
                                                                {
                                                                  SiteMsgResProps
                                                                    .body
                                                                    .content
                                                                    .noIssuesFound
                                                                }
                                                              </Col>
                                                            )}
                                                          </Row>
                                                        );
                                                      }
                                                    }
                                                  )
                                                ) : (
                                                  <div className="sram-td-sub sram-no-data">
                                                    {
                                                      SiteMsgResProps.body
                                                        .content.noIssuesFound
                                                    }
                                                  </div>
                                                )}
                                              </WithLoading>
                                            </div>
                                          </td>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <div className="sram-td-sub sram-no-data">
                                    {SiteMsgResProps.body.content.noIssuesFound}
                                  </div>
                                )}
                              </tr>
                            ) : null}

                            {/* Connectivity status */}
                            {this.state.partPrivileges[
                              this._pcPortalViewConnectivityStatus
                            ] === true ? (
                              <tr>
                                <td className="sram-site-dispenser-table-border p-0">
                                  <div className="font-mild sram-td-sub">
                                    <Row className="sram-td-sub">
                                      <Col className="ml-1">
                                        {
                                          SiteMsgResProps.head.title
                                            .connectivityStatus
                                        }
                                      </Col>
                                    </Row>
                                  </div>
                                </td>
                                <td className="sram-site-dispenser-table-border1 p-0 sram-table-body-adj sram-site-dispenser-table-border-right"></td>

                                {this.state.dispensers &&
                                this.state.dispensers?.length > 0 ? (
                                  <>
                                    {this.state.dispensers.map(
                                      (dispenser, index) => {
                                        return (
                                          <td
                                            key={index}
                                            className="sram-site-dispenser-table-body sram-table-body-adj"
                                          >
                                            <div className="font-mild sram-td-sub">
                                              <WithLoading
                                                isPageDataFetched={
                                                  this.state
                                                    .isComponentDataFetched
                                                }
                                                type="page"
                                                className="sram-spinner"
                                                size="sm"
                                              >
                                                {this.state.componentStatus &&
                                                this.state.componentStatus
                                                  ?.length > 0 ? (
                                                  this.state.componentStatus.map(
                                                    (obj, i) => {
                                                      if (
                                                        dispenser.dispenserId ===
                                                        obj?.dispenserId
                                                      ) {
                                                        return (
                                                          <Row
                                                            className="sram-td-sub"
                                                            key={i}
                                                          >
                                                            {this.getItemStatus(
                                                              obj.connectivity
                                                            )}
                                                          </Row>
                                                        );
                                                      }
                                                    }
                                                  )
                                                ) : (
                                                  <div className="sram-td-sub sram-no-data ">
                                                    {
                                                      SiteMsgResProps.body
                                                        .content.noIssuesFound
                                                    }
                                                  </div>
                                                )}
                                              </WithLoading>
                                            </div>
                                          </td>
                                        );
                                      }
                                    )}
                                  </>
                                ) : null}
                              </tr>
                            ) : null}

                            {/* Side Labels */}
                            <tr>
                              <td className="sram-site-dispenser-table-border">
                                <Row></Row>
                              </td>
                              <td className="sram-site-dispenser-table-border1">
                                <div className="font-mild sram-td-sub  sram-site-dispenser-table-border-right"></div>
                              </td>
                              <>
                                {this.state.dispensers.map(
                                  (dispenser, index) => {
                                    return (
                                      <td
                                        key={index}
                                        className="sram-site-dispenser-table-body sram-table-body-adj"
                                      >
                                        <div className="font-mild sram-td-sub">
                                          <WithLoading
                                            isPageDataFetched={
                                              this.state.isPageDataFetched
                                            }
                                            type="page"
                                            className="sram-spinner"
                                            size="sm"
                                          >
                                            <Row className="sram-td-sub sram-site-dashboard-font">
                                              <Col>Side A</Col>
                                              <Col>Side B</Col>
                                            </Row>
                                          </WithLoading>
                                        </div>
                                      </td>
                                    );
                                  }
                                )}
                              </>
                            </tr>

                            {/* PUMP TAB */}
                            {this.state.partPrivileges[
                              this._pcPortalViewPumpTwoWireStatus
                            ] === true ||
                            this.state.partPrivileges[
                              this._pcPortalViewPumpSerialInterface
                            ] === true ? (
                              <tr>
                                <td className="sram-site-dispenser-table-border">
                                  {this.state.partPrivileges[
                                    this._pcPortalViewPumpTwoWireStatus
                                  ] === true ||
                                  this.state.partPrivileges[
                                    this._pcPortalViewPumpSerialInterface
                                  ] === true ? (
                                    <div className="font-mild sram-td-sub">
                                      <Row className="sram-td-sub">
                                        <Col className="">
                                          {SiteMsgResProps.head.title.pump}
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                </td>
                                <td className="sram-site-dispenser-table-border1">
                                  {this.state.partPrivileges[
                                    this._pcPortalViewPumpTwoWireStatus
                                  ] === true ? (
                                    <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                      <Row className="sram-td-sub">
                                        <Col className="">
                                          {
                                            SiteMsgResProps.body.form
                                              .twoWireStatus.label
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                  {this.state.partPrivileges[
                                    this._pcPortalViewPumpSerialInterface
                                  ] === true ? (
                                    <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                      <Row className="sram-td-sub">
                                        <Col className="">
                                          {
                                            SiteMsgResProps.body.form
                                              .serialInterface.label
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                </td>
                                {this.state.dispensers &&
                                this.state.dispensers?.length > 0 ? (
                                  <>
                                    {this.state.dispensers.map(
                                      (dispenser, index) => {
                                        return (
                                          <td
                                            key={index}
                                            className="sram-site-dispenser-table-body sram-table-body-adj"
                                          >
                                            {this.state.partPrivileges[
                                              this
                                                ._pcPortalViewPumpTwoWireStatus
                                            ] === true ? (
                                              <div className="font-mild sram-td-sub">
                                                <WithLoading
                                                  isPageDataFetched={
                                                    this.state
                                                      .isComponentDataFetched
                                                  }
                                                  type="page"
                                                  className="sram-spinner"
                                                  size="sm"
                                                >
                                                  {this.state.componentStatus &&
                                                  this.state.componentStatus
                                                    ?.length > 0 ? (
                                                    this.state.componentStatus.map(
                                                      (obj, i) => {
                                                        if (
                                                          dispenser.dispenserId ===
                                                          obj?.dispenserId
                                                        ) {
                                                          return (
                                                            <Row
                                                              className="sram-td-sub"
                                                              key={i}
                                                            >
                                                              {obj?.twoWire &&
                                                              obj?.twoWire
                                                                .length > 0 ? (
                                                                <>
                                                                  <Col>
                                                                    {obj?.twoWire.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "A"
                                                                    ) ? (
                                                                      this.getStatusA(
                                                                        obj?.twoWire
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                  <Col>
                                                                    {obj?.twoWire.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "B"
                                                                    ) ? (
                                                                      this.getStatusB(
                                                                        obj?.twoWire
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                </>
                                                              ) : (
                                                                <Col className="sram-no-data">
                                                                  {
                                                                    SiteMsgResProps
                                                                      .body
                                                                      .content
                                                                      .noIssuesFound
                                                                  }
                                                                </Col>
                                                              )}
                                                            </Row>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <div className="sram-td-sub sram-no-data">
                                                      {
                                                        SiteMsgResProps.body
                                                          .content.noIssuesFound
                                                      }
                                                    </div>
                                                  )}
                                                </WithLoading>
                                              </div>
                                            ) : null}
                                            {this.state.partPrivileges[
                                              this
                                                ._pcPortalViewPumpSerialInterface
                                            ] === true ? (
                                              <div className="font-mild sram-td-sub">
                                                <WithLoading
                                                  isPageDataFetched={
                                                    this.state
                                                      .isComponentDataFetched
                                                  }
                                                  type="page"
                                                  className="sram-spinner"
                                                  size="sm"
                                                >
                                                  {this.state.componentStatus &&
                                                  this.state.componentStatus
                                                    ?.length > 0 ? (
                                                    this.state.componentStatus.map(
                                                      (obj, i) => {
                                                        if (
                                                          dispenser.dispenserId ===
                                                          obj?.dispenserId
                                                        ) {
                                                          return (
                                                            <Row
                                                              className="sram-td-sub"
                                                              key={i}
                                                            >
                                                              {this.getItemStatus(
                                                                obj.serialInterface
                                                              )}
                                                            </Row>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <div className="sram-td-sub sram-no-data">
                                                      {
                                                        SiteMsgResProps.body
                                                          .content.noIssuesFound
                                                      }
                                                    </div>
                                                  )}
                                                </WithLoading>
                                              </div>
                                            ) : null}
                                          </td>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <div className="sram-td-sub sram-no-data">
                                    {SiteMsgResProps.body.content.noIssuesFound}
                                  </div>
                                )}
                              </tr>
                            ) : null}

                            {this.state.partPrivileges[
                              this._pcPortalViewCrindCardReader
                            ] === true ||
                            this.state.partPrivileges[
                              this._pcPortalViewCrindDisplay
                            ] === true ||
                            this.state.partPrivileges[
                              this._pcPortalViewCrindKeyboard
                            ] === true ||
                            this.state.partPrivileges[
                              this._pcPortalViewCrindPrinter
                            ] === true ||
                            this.state.partPrivileges[
                              this._pcPortalViewCrindUPM
                            ] === true ? (
                              <tr>
                                <td className="sram-site-dispenser-table-border">
                                  <div className="font-mild sram-td-sub">
                                    <Row className="sram-td-sub">
                                      <Col className="">
                                        {SiteMsgResProps.head.title.crind}
                                      </Col>
                                    </Row>
                                  </div>
                                </td>
                                <td className="sram-site-dispenser-table-border1">
                                  {this.state.partPrivileges[
                                    this._pcPortalViewCrindUPM
                                  ] === true ? (
                                    <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                      <Row className="sram-td-sub">
                                        <Col className="">
                                          {SiteMsgResProps.body.form.upm.label}
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                  {this.state.partPrivileges[
                                    this._pcPortalViewCrindCardReader
                                  ] === true ? (
                                    <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                      <Row className="sram-td-sub">
                                        <Col className="">
                                          {
                                            SiteMsgResProps.body.form.cardReader
                                              .label
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                  {this.state.partPrivileges[
                                    this._pcPortalViewCrindDisplay
                                  ] === true ? (
                                    <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                      <Row className="sram-td-sub">
                                        <Col className="">
                                          {
                                            SiteMsgResProps.body.form.display
                                              .label
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                  {this.state.partPrivileges[
                                    this._pcPortalViewCrindKeyboard
                                  ] === true ? (
                                    <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                      <Row className="sram-td-sub">
                                        <Col className="">
                                          {
                                            SiteMsgResProps.body.form.keyboard
                                              .label
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                  {this.state.partPrivileges[
                                    this._pcPortalViewCrindPrinter
                                  ] === true ? (
                                    <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                      <Row className="sram-td-sub">
                                        <Col className="">
                                          {
                                            SiteMsgResProps.body.form.printer
                                              .label
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                </td>
                                {this.state.dispensers &&
                                this.state.dispensers?.length > 0 ? (
                                  <>
                                    {this.state.dispensers.map(
                                      (dispenser, index) => {
                                        return (
                                          <td
                                            key={index}
                                            className="sram-site-dispenser-table-body sram-table-body-adj"
                                          >
                                            {/* //UPM */}

                                            {this.state.partPrivileges[
                                              this._pcPortalViewCrindUPM
                                            ] === true ? (
                                              <div className="font-mild sram-td-sub">
                                                <WithLoading
                                                  isPageDataFetched={
                                                    this.state
                                                      .isComponentDataFetched
                                                  }
                                                  type="page"
                                                  className="sram-spinner"
                                                  size="sm"
                                                >
                                                  {this.state.componentStatus &&
                                                  this.state.componentStatus
                                                    ?.length > 0 ? (
                                                    this.state.componentStatus.map(
                                                      (obj, i) => {
                                                        if (
                                                          dispenser.dispenserId ===
                                                          obj?.dispenserId
                                                        ) {
                                                          return (
                                                            <Row
                                                              className="sram-td-sub"
                                                              key={i}
                                                            >
                                                              {obj?.upm &&
                                                              obj?.upm.length >
                                                                0 ? (
                                                                <>
                                                                  <Col>
                                                                    {obj?.upm.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "A"
                                                                    ) ? (
                                                                      this.getStatusA(
                                                                        obj?.upm
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                  <Col>
                                                                    {obj?.upm.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "B"
                                                                    ) ? (
                                                                      this.getStatusB(
                                                                        obj?.upm
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                </>
                                                              ) : (
                                                                <Col className="sram-no-data">
                                                                  {
                                                                    SiteMsgResProps
                                                                      .body
                                                                      .content
                                                                      .noIssuesFound
                                                                  }
                                                                </Col>
                                                              )}
                                                            </Row>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <div className="sram-td-sub sram-no-data">
                                                      {
                                                        SiteMsgResProps.body
                                                          .content.noIssuesFound
                                                      }
                                                    </div>
                                                  )}
                                                </WithLoading>
                                              </div>
                                            ) : null}

                                            {/* //CARD READER */}
                                            {this.state.partPrivileges[
                                              this._pcPortalViewCrindCardReader
                                            ] === true ? (
                                              <div className="font-mild sram-td-sub">
                                                <WithLoading
                                                  isPageDataFetched={
                                                    this.state
                                                      .isComponentDataFetched
                                                  }
                                                  type="page"
                                                  className="sram-spinner"
                                                  size="sm"
                                                >
                                                  {this.state.componentStatus &&
                                                  this.state.componentStatus
                                                    ?.length > 0 ? (
                                                    this.state.componentStatus.map(
                                                      (obj, i) => {
                                                        if (
                                                          dispenser.dispenserId ===
                                                          obj?.dispenserId
                                                        ) {
                                                          return (
                                                            <Row
                                                              className="sram-td-sub"
                                                              key={i}
                                                            >
                                                              {obj?.cardReader &&
                                                              obj?.cardReader
                                                                .length > 0 ? (
                                                                <>
                                                                  <Col>
                                                                    {obj?.cardReader.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "A"
                                                                    ) ? (
                                                                      this.getStatusA(
                                                                        obj?.cardReader
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                  <Col>
                                                                    {obj?.cardReader.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "B"
                                                                    ) ? (
                                                                      this.getStatusB(
                                                                        obj?.cardReader
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                </>
                                                              ) : (
                                                                <Col className="sram-no-data">
                                                                  {
                                                                    SiteMsgResProps
                                                                      .body
                                                                      .content
                                                                      .noIssuesFound
                                                                  }
                                                                </Col>
                                                              )}
                                                            </Row>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <div className="sram-td-sub sram-no-data">
                                                      {
                                                        SiteMsgResProps.body
                                                          .content.noIssuesFound
                                                      }
                                                    </div>
                                                  )}
                                                </WithLoading>
                                              </div>
                                            ) : null}

                                            {/* //DISPLAY */}
                                            {this.state.partPrivileges[
                                              this._pcPortalViewCrindDisplay
                                            ] === true ? (
                                              <div className="font-mild sram-td-sub">
                                                <WithLoading
                                                  isPageDataFetched={
                                                    this.state
                                                      .isComponentDataFetched
                                                  }
                                                  type="page"
                                                  className="sram-spinner"
                                                  size="sm"
                                                >
                                                  {this.state.componentStatus &&
                                                  this.state.componentStatus
                                                    ?.length > 0 ? (
                                                    this.state.componentStatus.map(
                                                      (obj, i) => {
                                                        if (
                                                          dispenser.dispenserId ===
                                                          obj?.dispenserId
                                                        ) {
                                                          return (
                                                            <Row
                                                              className="sram-td-sub"
                                                              key={i}
                                                            >
                                                              {obj?.display &&
                                                              obj?.display
                                                                .length > 0 ? (
                                                                <>
                                                                  <Col>
                                                                    {obj?.display.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "A"
                                                                    ) ? (
                                                                      this.getStatusA(
                                                                        obj?.display
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                  <Col>
                                                                    {obj?.display.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "B"
                                                                    ) ? (
                                                                      this.getStatusB(
                                                                        obj?.display
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                </>
                                                              ) : (
                                                                <Col className="sram-no-data">
                                                                  {
                                                                    SiteMsgResProps
                                                                      .body
                                                                      .content
                                                                      .noIssuesFound
                                                                  }
                                                                </Col>
                                                              )}
                                                            </Row>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <div className="sram-td-sub sram-no-data">
                                                      {
                                                        SiteMsgResProps.body
                                                          .content.noIssuesFound
                                                      }
                                                    </div>
                                                  )}
                                                </WithLoading>
                                              </div>
                                            ) : null}

                                            {/* //EPP KEBOARD */}

                                            {this.state.partPrivileges[
                                              this._pcPortalViewCrindKeyboard
                                            ] === true ? (
                                              <div className="font-mild sram-td-sub">
                                                <WithLoading
                                                  isPageDataFetched={
                                                    this.state
                                                      .isComponentDataFetched
                                                  }
                                                  type="page"
                                                  className="sram-spinner"
                                                  size="sm"
                                                >
                                                  {this.state.componentStatus &&
                                                  this.state.componentStatus
                                                    ?.length > 0 ? (
                                                    this.state.componentStatus.map(
                                                      (obj, i) => {
                                                        if (
                                                          dispenser.dispenserId ===
                                                          obj?.dispenserId
                                                        ) {
                                                          return (
                                                            <Row
                                                              className="sram-td-sub"
                                                              key={i}
                                                            >
                                                              {obj?.keyboard &&
                                                              obj?.keyboard
                                                                .length > 0 ? (
                                                                <>
                                                                  <Col>
                                                                    {obj?.keyboard.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "A"
                                                                    ) ? (
                                                                      this.getStatusA(
                                                                        obj?.keyboard
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                  <Col>
                                                                    {obj?.keyboard.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "B"
                                                                    ) ? (
                                                                      this.getStatusB(
                                                                        obj?.keyboard
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                </>
                                                              ) : (
                                                                <Col className="sram-no-data">
                                                                  {
                                                                    SiteMsgResProps
                                                                      .body
                                                                      .content
                                                                      .noIssuesFound
                                                                  }
                                                                </Col>
                                                              )}
                                                            </Row>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <div className="sram-td-sub sram-no-data">
                                                      {
                                                        SiteMsgResProps.body
                                                          .content.noIssuesFound
                                                      }
                                                    </div>
                                                  )}
                                                </WithLoading>
                                              </div>
                                            ) : null}

                                            {/* //PRINTER */}

                                            {this.state.partPrivileges[
                                              this._pcPortalViewCrindPrinter
                                            ] === true ? (
                                              <div className="font-mild sram-td-sub">
                                                <WithLoading
                                                  isPageDataFetched={
                                                    this.state
                                                      .isComponentDataFetched
                                                  }
                                                  type="page"
                                                  className="sram-spinner"
                                                  size="sm"
                                                >
                                                  {this.state.componentStatus &&
                                                  this.state.componentStatus
                                                    ?.length > 0 ? (
                                                    this.state.componentStatus.map(
                                                      (obj, i) => {
                                                        if (
                                                          dispenser.dispenserId ===
                                                          obj?.dispenserId
                                                        ) {
                                                          return (
                                                            <Row
                                                              className="sram-td-sub"
                                                              key={i}
                                                            >
                                                              {obj?.printer &&
                                                              obj?.printer
                                                                .length > 0 ? (
                                                                <>
                                                                  <Col>
                                                                    {obj?.printer.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "A"
                                                                    ) ? (
                                                                      this.getStatusA(
                                                                        obj?.printer
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                  <Col>
                                                                    {obj?.printer.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "B"
                                                                    ) ? (
                                                                      this.getStatusB(
                                                                        obj?.printer
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                </>
                                                              ) : (
                                                                <Col className="sram-no-data">
                                                                  {
                                                                    SiteMsgResProps
                                                                      .body
                                                                      .content
                                                                      .noIssuesFound
                                                                  }
                                                                </Col>
                                                              )}
                                                            </Row>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <div className="sram-td-sub sram-no-data">
                                                      {
                                                        SiteMsgResProps.body
                                                          .content.noIssuesFound
                                                      }
                                                    </div>
                                                  )}
                                                </WithLoading>
                                              </div>
                                            ) : null}
                                          </td>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <div className="sram-td-sub sram-no-data">
                                    {SiteMsgResProps.body.content.noIssuesFound}
                                  </div>
                                )}
                              </tr>
                            ) : null}

                            {/* CUSTOMER EXPERIENCE */}

                            {this.state.partPrivileges[
                              this._pcPortalViewCEPaperOut
                            ] === true ||
                            this.state.partPrivileges[
                              this._pcPortalViewCENoTrans
                            ] === true ||
                            this.state.partPrivileges[
                              this._pcPortalViewCEFlowRate
                            ] === true ? (
                              <tr>
                                <td className="sram-site-dispenser-table-border">
                                  <div className="font-mild sram-td-sub">
                                    <Row className="sram-td-sub">
                                      <Col className="">
                                        {
                                          SiteMsgResProps.head.title
                                            .customerExperience
                                        }
                                      </Col>
                                    </Row>
                                  </div>
                                </td>
                                <td className="sram-site-dispenser-table-border1">
                                  {this.state.partPrivileges[
                                    this._pcPortalViewCEPaperOut
                                  ] === true ? (
                                    <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                      <Row className="sram-td-sub">
                                        <Col className="">
                                          {
                                            SiteMsgResProps.body.form.paperOut
                                              .label
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                  {this.state.partPrivileges[
                                    this._pcPortalViewCENoTrans
                                  ] === true ? (
                                    <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                      <Row className="sram-td-sub">
                                        <Col className="">
                                          {
                                            SiteMsgResProps.body.form
                                              .noTransaction.label
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                  {this.state.partPrivileges[
                                    this._pcPortalViewCEFlowRate
                                  ] === true ? (
                                    <div className="font-mild sram-td-sub sram-site-dispenser-table-border-right">
                                      <Row className="sram-td-sub">
                                        <Col className="">
                                          {
                                            SiteMsgResProps.body.form.flowRate
                                              .label
                                          }
                                        </Col>
                                      </Row>
                                    </div>
                                  ) : null}
                                </td>
                                {this.state.dispensers &&
                                this.state.dispensers?.length > 0 ? (
                                  <>
                                    {this.state.dispensers.map(
                                      (dispenser, index) => {
                                        return (
                                          <td
                                            key={index}
                                            className="sram-site-dispenser-table-body sram-table-body-adj"
                                          >
                                            {/*CUSTOMER EXPERIENCE PAPER OUT */}
                                            {this.state.partPrivileges[
                                              this._pcPortalViewCEPaperOut
                                            ] === true ? (
                                              <div className="font-mild sram-td-sub">
                                                <WithLoading
                                                  isPageDataFetched={
                                                    this.state
                                                      .isComponentDataFetched
                                                  }
                                                  type="page"
                                                  className="sram-spinner"
                                                  size="sm"
                                                >
                                                  {this.state.componentStatus &&
                                                  this.state.componentStatus
                                                    ?.length > 0 ? (
                                                    this.state.componentStatus.map(
                                                      (obj, i) => {
                                                        if (
                                                          dispenser.dispenserId ===
                                                          obj?.dispenserId
                                                        ) {
                                                          return (
                                                            <Row
                                                              className="sram-td-sub"
                                                              key={i}
                                                            >
                                                              {obj?.paperOut &&
                                                              obj?.paperOut
                                                                .length > 0 ? (
                                                                <>
                                                                  <Col>
                                                                    {obj?.paperOut.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "A"
                                                                    ) ? (
                                                                      this.getStatusA(
                                                                        obj?.paperOut
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                  <Col>
                                                                    {obj?.paperOut.some(
                                                                      (el) =>
                                                                        el.sideLabel ===
                                                                        "B"
                                                                    ) ? (
                                                                      this.getStatusB(
                                                                        obj?.paperOut
                                                                      )
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="Green"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                </>
                                                              ) : (
                                                                <Col className="sram-no-data">
                                                                  {
                                                                    SiteMsgResProps
                                                                      .body
                                                                      .content
                                                                      .noIssuesFound
                                                                  }
                                                                </Col>
                                                              )}
                                                            </Row>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <div className="sram-td-sub sram-no-data">
                                                      {
                                                        SiteMsgResProps.body
                                                          .content.noIssuesFound
                                                      }
                                                    </div>
                                                  )}
                                                </WithLoading>
                                              </div>
                                            ) : null}

                                            {/* No Transaction */}
                                            {this.state.partPrivileges[
                                              this._pcPortalViewCENoTrans
                                            ] === true ? (
                                              <div className="font-mild sram-td-sub">
                                                <WithLoading
                                                  isPageDataFetched={
                                                    this.state
                                                      .isNoTransactionDataFetched
                                                  }
                                                  type="page"
                                                  className="sram-spinner"
                                                  size="sm"
                                                >
                                                  {this.state
                                                    .noTransactionData &&
                                                  this.state.noTransactionData
                                                    ?.length > 0 ? (
                                                    this.state.noTransactionData.map(
                                                      (obj, i) => {
                                                        if (
                                                          dispenser.dispenserId ===
                                                          obj?.dispenserId
                                                        ) {
                                                          return (
                                                            <Row
                                                              className="sram-td-sub"
                                                              key={i}
                                                            >
                                                              {obj
                                                                ?.transactionStatus
                                                                ?.length > 0 ? (
                                                                <>
                                                                  <Col
                                                                    xs={6}
                                                                    sm={6}
                                                                    md={6}
                                                                    lg={6}
                                                                    xl={6}
                                                                  >
                                                                    {obj
                                                                      ?.transactionStatus[0]
                                                                      ?.pumpNumber %
                                                                      2 !==
                                                                    0 ? (
                                                                      <span className="sram-site-door">
                                                                        {this
                                                                          .state
                                                                          .dashboardColor &&
                                                                        this
                                                                          .state
                                                                          .dashboardColor
                                                                          .length >
                                                                          0 &&
                                                                        this.state.dashboardColor?.some(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.dispenserId ===
                                                                            dispenser?.dispenserId
                                                                        ) ? (
                                                                          <div className="tooltip ml-1">
                                                                            {" "}
                                                                            <span>
                                                                              &nbsp;
                                                                              <img
                                                                                src={
                                                                                  Gray
                                                                                }
                                                                                className="tooltip"
                                                                                alt="-"
                                                                                style={{
                                                                                  height:
                                                                                    "20px",
                                                                                  width:
                                                                                    "20px",
                                                                                }}
                                                                              />
                                                                            </span>
                                                                          </div>
                                                                        ) : (
                                                                          <span>
                                                                            {this.showNoTransFlagsData(
                                                                              obj
                                                                                ?.transactionStatus[0]
                                                                                ?.flag,
                                                                              obj
                                                                                ?.transactionStatus[0]
                                                                                ?.dataDate,
                                                                              obj
                                                                                ?.transactionStatus[0]
                                                                                ?.gradeNumber
                                                                            )}
                                                                          </span>
                                                                        )}
                                                                      </span>
                                                                    ) : this
                                                                        .state
                                                                        .dashboardColor &&
                                                                      this.state
                                                                        .dashboardColor
                                                                        .length >
                                                                        0 &&
                                                                      this.state.dashboardColor?.some(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item.dispenserId ===
                                                                          dispenser?.dispenserId
                                                                      ) ? (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Gray
                                                                            }
                                                                            className="tooltip"
                                                                            alt="-"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="-"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                  <Col
                                                                    xs={6}
                                                                    sm={6}
                                                                    md={6}
                                                                    lg={6}
                                                                    xl={6}
                                                                  >
                                                                    {obj
                                                                      ?.transactionStatus[1] &&
                                                                    obj
                                                                      ?.transactionStatus[1]
                                                                      ?.pumpNumber %
                                                                      2 ===
                                                                      0 ? (
                                                                      <span className="sram-site-door">
                                                                        {this
                                                                          .state
                                                                          .dashboardColor &&
                                                                        this
                                                                          .state
                                                                          .dashboardColor
                                                                          .length >
                                                                          0 &&
                                                                        this.state.dashboardColor?.some(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.dispenserId ===
                                                                            dispenser?.dispenserId
                                                                        ) ? (
                                                                          <div className="tooltip ml-1">
                                                                            {" "}
                                                                            <span>
                                                                              &nbsp;
                                                                              <img
                                                                                src={
                                                                                  Gray
                                                                                }
                                                                                className="tooltip"
                                                                                alt="-"
                                                                                style={{
                                                                                  height:
                                                                                    "20px",
                                                                                  width:
                                                                                    "20px",
                                                                                }}
                                                                              />
                                                                            </span>
                                                                          </div>
                                                                        ) : (
                                                                          <span>
                                                                            {this.showNoTransFlagsData(
                                                                              obj
                                                                                ?.transactionStatus[1]
                                                                                ?.flag,
                                                                              obj
                                                                                ?.transactionStatus[1]
                                                                                ?.dataDate,
                                                                              obj
                                                                                ?.transactionStatus[1]
                                                                                ?.gradeNumber
                                                                            )}
                                                                          </span>
                                                                        )}
                                                                      </span>
                                                                    ) : obj
                                                                        ?.transactionStatus[0]
                                                                        ?.pumpNumber %
                                                                        2 ===
                                                                      0 ? (
                                                                      <span className="sram-site-door">
                                                                        {this
                                                                          .state
                                                                          .dashboardColor &&
                                                                        this
                                                                          .state
                                                                          .dashboardColor
                                                                          .length >
                                                                          0 &&
                                                                        this.state.dashboardColor?.some(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.dispenserId ===
                                                                            dispenser?.dispenserId
                                                                        ) ? (
                                                                          <div className="tooltip ml-1">
                                                                            {" "}
                                                                            <span>
                                                                              &nbsp;
                                                                              <img
                                                                                src={
                                                                                  Gray
                                                                                }
                                                                                className="tooltip"
                                                                                alt="-"
                                                                                style={{
                                                                                  height:
                                                                                    "20px",
                                                                                  width:
                                                                                    "20px",
                                                                                }}
                                                                              />
                                                                            </span>
                                                                          </div>
                                                                        ) : (
                                                                          <span>
                                                                            {this.showNoTransFlagsData(
                                                                              obj
                                                                                ?.transactionStatus[0]
                                                                                ?.flag,
                                                                              obj
                                                                                ?.transactionStatus[0]
                                                                                ?.dataDate,
                                                                              obj
                                                                                ?.transactionStatus[0]
                                                                                ?.gradeNumber
                                                                            )}
                                                                          </span>
                                                                        )}
                                                                      </span>
                                                                    ) : this
                                                                        .state
                                                                        .dashboardColor &&
                                                                      this.state
                                                                        .dashboardColor
                                                                        .length >
                                                                        0 &&
                                                                      this.state.dashboardColor?.some(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item.dispenserId ===
                                                                          dispenser?.dispenserId
                                                                      ) ? (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Gray
                                                                            }
                                                                            className="tooltip"
                                                                            alt="-"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="-"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                </>
                                                              ) : (
                                                                <Col className="sram-no-data">
                                                                  {this.state
                                                                    .noTransactionError ? (
                                                                    <span>
                                                                      {
                                                                        SiteMsgResProps
                                                                          .body
                                                                          .content
                                                                          .errorOccured
                                                                      }
                                                                    </span>
                                                                  ) : (
                                                                    <span>
                                                                      {
                                                                        SiteMsgResProps
                                                                          .body
                                                                          .content
                                                                          .noIssuesFound
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </Col>
                                                              )}
                                                            </Row>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <Row className="sram-td-sub">
                                                      <Col className="sram-no-data">
                                                        {this.state
                                                          .noTransactionError ? (
                                                          <span>
                                                            {
                                                              SiteMsgResProps
                                                                .body.content
                                                                .errorOccured
                                                            }
                                                          </span>
                                                        ) : (
                                                          <span>
                                                            {
                                                              SiteMsgResProps
                                                                .body.content
                                                                .noIssuesFound
                                                            }
                                                          </span>
                                                        )}
                                                      </Col>
                                                    </Row>
                                                  )}
                                                </WithLoading>
                                              </div>
                                            ) : null}

                                            {/*CUSTOMER EXPERIENCE FLOW RATE */}
                                            {this.state.partPrivileges[
                                              this._pcPortalViewCEFlowRate
                                            ] === true ? (
                                              <div className="font-mild sram-td-sub">
                                                <WithLoading
                                                  isPageDataFetched={
                                                    this.state
                                                      .isFlowRateDataFetched
                                                  }
                                                  type="page"
                                                  className="sram-spinner"
                                                  size="sm"
                                                >
                                                  {this.state.flowRateStatus &&
                                                  this.state.flowRateStatus
                                                    ?.length > 0 ? (
                                                    this.state.flowRateStatus.map(
                                                      (obj, i) => {
                                                        if (
                                                          dispenser.dispenserId ===
                                                          obj?.dispenserId
                                                        ) {
                                                          return (
                                                            <Row
                                                              className="sram-td-sub"
                                                              key={i}
                                                            >
                                                              {obj?.flowStatus
                                                                ?.length > 0 ? (
                                                                <>
                                                                  <Col
                                                                    xs={6}
                                                                    sm={6}
                                                                    md={6}
                                                                    lg={6}
                                                                    xl={6}
                                                                  >
                                                                    {obj
                                                                      ?.flowStatus[0]
                                                                      ?.pumpNumber %
                                                                      2 !==
                                                                    0 ? (
                                                                      <span className="sram-site-door">
                                                                        {this
                                                                          .state
                                                                          .dashboardColor &&
                                                                        this
                                                                          .state
                                                                          .dashboardColor
                                                                          .length >
                                                                          0 &&
                                                                        this.state.dashboardColor?.some(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.dispenserId ===
                                                                            dispenser?.dispenserId
                                                                        ) ? (
                                                                          <div className="tooltip ml-1">
                                                                            {" "}
                                                                            <span>
                                                                              &nbsp;
                                                                              <img
                                                                                src={
                                                                                  Gray
                                                                                }
                                                                                className="tooltip"
                                                                                alt="-"
                                                                                style={{
                                                                                  height:
                                                                                    "20px",
                                                                                  width:
                                                                                    "20px",
                                                                                }}
                                                                              />
                                                                            </span>
                                                                          </div>
                                                                        ) : (
                                                                          <span>
                                                                            {this.showFlowRateFlagsData(
                                                                              obj
                                                                                ?.flowStatus[0]
                                                                                ?.flag,
                                                                              obj
                                                                                ?.flowStatus[0]
                                                                                ?.flowRate,
                                                                              obj
                                                                                ?.flowStatus[0]
                                                                                ?.gradeNumber,
                                                                              obj
                                                                                ?.flowStatus[0]
                                                                                ?.dataDate
                                                                            )}
                                                                          </span>
                                                                        )}
                                                                      </span>
                                                                    ) : this
                                                                        .state
                                                                        .dashboardColor &&
                                                                      this.state
                                                                        .dashboardColor
                                                                        .length >
                                                                        0 &&
                                                                      this.state.dashboardColor?.some(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item.dispenserId ===
                                                                          dispenser?.dispenserId
                                                                      ) ? (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Gray
                                                                            }
                                                                            className="tooltip"
                                                                            alt="-"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="-"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                  <Col
                                                                    xs={6}
                                                                    sm={6}
                                                                    md={6}
                                                                    lg={6}
                                                                    xl={6}
                                                                  >
                                                                    {obj
                                                                      ?.flowStatus[1] &&
                                                                    obj
                                                                      ?.flowStatus[1]
                                                                      ?.pumpNumber %
                                                                      2 ===
                                                                      0 ? (
                                                                      <span className="sram-site-door">
                                                                        {this
                                                                          .state
                                                                          .dashboardColor &&
                                                                        this
                                                                          .state
                                                                          .dashboardColor
                                                                          .length >
                                                                          0 &&
                                                                        this.state.dashboardColor?.some(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.dispenserId ===
                                                                            dispenser?.dispenserId
                                                                        ) ? (
                                                                          <div className="tooltip ml-1">
                                                                            {" "}
                                                                            <span>
                                                                              &nbsp;
                                                                              <img
                                                                                src={
                                                                                  Gray
                                                                                }
                                                                                className="tooltip"
                                                                                alt="-"
                                                                                style={{
                                                                                  height:
                                                                                    "20px",
                                                                                  width:
                                                                                    "20px",
                                                                                }}
                                                                              />
                                                                            </span>
                                                                          </div>
                                                                        ) : (
                                                                          <span>
                                                                            {this.showFlowRateFlagsData(
                                                                              obj
                                                                                ?.flowStatus[1]
                                                                                ?.flag,
                                                                              obj
                                                                                ?.flowStatus[1]
                                                                                ?.flowRate,
                                                                              obj
                                                                                ?.flowStatus[1]
                                                                                ?.gradeNumber,
                                                                              obj
                                                                                ?.flowStatus[1]
                                                                                ?.dataDate
                                                                            )}
                                                                          </span>
                                                                        )}
                                                                      </span>
                                                                    ) : obj
                                                                        ?.flowStatus[0]
                                                                        ?.pumpNumber %
                                                                        2 ===
                                                                      0 ? (
                                                                      <span className="sram-site-door">
                                                                        {this
                                                                          .state
                                                                          .dashboardColor &&
                                                                        this
                                                                          .state
                                                                          .dashboardColor
                                                                          .length >
                                                                          0 &&
                                                                        this.state.dashboardColor?.some(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.dispenserId ===
                                                                            dispenser?.dispenserId
                                                                        ) ? (
                                                                          <div className="tooltip ml-1">
                                                                            {" "}
                                                                            <span>
                                                                              &nbsp;
                                                                              <img
                                                                                src={
                                                                                  Gray
                                                                                }
                                                                                className="tooltip"
                                                                                alt="-"
                                                                                style={{
                                                                                  height:
                                                                                    "20px",
                                                                                  width:
                                                                                    "20px",
                                                                                }}
                                                                              />
                                                                            </span>
                                                                          </div>
                                                                        ) : (
                                                                          <span>
                                                                            {this.showFlowRateFlagsData(
                                                                              obj
                                                                                ?.flowStatus[0]
                                                                                ?.flag,
                                                                              obj
                                                                                ?.flowStatus[0]
                                                                                ?.flowRate,
                                                                              obj
                                                                                ?.flowStatus[0]
                                                                                ?.gradeNumber,
                                                                              obj
                                                                                ?.flowStatus[0]
                                                                                ?.dataDate
                                                                            )}
                                                                          </span>
                                                                        )}
                                                                      </span>
                                                                    ) : this
                                                                        .state
                                                                        .dashboardColor &&
                                                                      this.state
                                                                        .dashboardColor
                                                                        .length >
                                                                        0 &&
                                                                      this.state.dashboardColor?.some(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item.dispenserId ===
                                                                          dispenser?.dispenserId
                                                                      ) ? (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Gray
                                                                            }
                                                                            className="tooltip"
                                                                            alt="-"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    ) : (
                                                                      <div className="tooltip ml-1">
                                                                        {" "}
                                                                        <span>
                                                                          &nbsp;
                                                                          <img
                                                                            src={
                                                                              Green
                                                                            }
                                                                            className="tooltip"
                                                                            alt="-"
                                                                            style={{
                                                                              height:
                                                                                "20px",
                                                                              width:
                                                                                "20px",
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </div>
                                                                    )}
                                                                  </Col>
                                                                </>
                                                              ) : (
                                                                <Col className="sram-no-data">
                                                                  {this.state
                                                                    .noTransactionError ? (
                                                                    <span>
                                                                      {
                                                                        SiteMsgResProps
                                                                          .body
                                                                          .content
                                                                          .errorOccured
                                                                      }
                                                                    </span>
                                                                  ) : (
                                                                    <span>
                                                                      {
                                                                        SiteMsgResProps
                                                                          .body
                                                                          .content
                                                                          .noIssuesFound
                                                                      }
                                                                    </span>
                                                                  )}
                                                                </Col>
                                                              )}
                                                            </Row>
                                                          );
                                                        }
                                                      }
                                                    )
                                                  ) : (
                                                    <Row className="sram-td-sub">
                                                      <Col className="sram-no-data">
                                                        {this.state
                                                          .noTransactionError ? (
                                                          <span>
                                                            {
                                                              SiteMsgResProps
                                                                .body.content
                                                                .errorOccured
                                                            }
                                                          </span>
                                                        ) : (
                                                          <span>
                                                            {
                                                              SiteMsgResProps
                                                                .body.content
                                                                .noIssuesFound
                                                            }
                                                          </span>
                                                        )}
                                                      </Col>
                                                    </Row>
                                                  )}
                                                </WithLoading>
                                              </div>
                                            ) : null}
                                          </td>
                                        );
                                      }
                                    )}
                                  </>
                                ) : (
                                  <div className="sram-td-sub sram-no-data">
                                    {SiteMsgResProps.body.content.noIssuesFound}
                                  </div>
                                )}
                              </tr>
                            ) : null}
                          </tbody>
                        </Table>
                      ) : (
                        <div className="pt-8 py-8 font-18">
                          {SiteMsgResProps.body.content.noIssuesFound}
                        </div>
                      )}
                    </div>
                  </WithLoading>
                </div>
              </Col>
            ) : this.state.tab === "2" && this.state.activeTab === "ATG" ? (
              <Col sm={12} md={12} lg={12} className="sram-page-top">
                <div className="table-responsive">
                  <WithLoading
                    isPageDataFetched={this.state.isTabPageDataFetched}
                    type="page"
                    size="md"
                  >
                    <div className="pt-8 py-8 font-18">
                      {SiteMsgResProps.body.content.noIssuesFound}
                    </div>
                  </WithLoading>
                </div>
              </Col>
            ) : this.state.tab === "3" && this.state.activeTab === "EV" ? (
              <Col sm={12} md={12} lg={12} className="sram-page-top">
                <div className="table-responsive">
                  <WithLoading
                    isPageDataFetched={this.state.isTabPageDataFetched}
                    type="page"
                    size="md"
                  >
                    <div className="pt-8 py-8 font-18">
                      {SiteMsgResProps.body.content.noIssuesFound}
                    </div>
                  </WithLoading>
                </div>
              </Col>
            ) : null}
            {/* </WithLoading> */}

            {this.state.showALertsModal ? (
              <SiteDashboardAlertsModal
                isAlertTicketModal={this.state.isAlertTicketModal}
                closeAlertTicketModal={this.closeAlertTicketModal}
                allAlerts={this.state.alertTicketData}
                isAllAlertsDataFetched={this.state.isAllAlertsDataFetched}
                openTickets={this.state.openTickets}
                // openAlertsLoading={this.state.isOpenAlertsDataFetched}
                closedTickets={this.state.closedTickets}
                dispatchTickets={this.state.dispatchTickets}
              />
            ) : null}
          </div>
        </WithLoading>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  pendingRequest: state.layoutSettings.pendingRequest,
});
export default connect(mapStateToProps)(SiteDashboardNew);
