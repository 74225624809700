import React from "react";
import { Route, Redirect } from "react-router-dom";
import axios from "axios";
import { AppURLProps } from "../core/settings/app-urls";
import AppUtils from "../core/helpers/app-utils";
import { AppConfigProps } from "../core/settings/app-config";
import { refreshToken } from "../core/actions/identity-actions";
//import { manageError } from "../core/actions/common-actions";

const _axiosSource = axios.CancelToken.source();
const _cancelToken = { cancelToken: _axiosSource.token };

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const tokenData = AppUtils.getIdentityTokenData();
      if (
        tokenData &&
        tokenData.userUUID &&
        tokenData.username &&
        tokenData.email &&
        tokenData.tokenExpiry >= Date.now() / 1000
      ) {
        AppUtils.setRequestHeaders();

        if (
          tokenData.tokenExpiry - parseInt(Date.now() / 1000) <=
          AppConfigProps.identitySession.expiryCheckRemainingSeconds
        ) {
          refreshToken(_cancelToken);
        }
        // authorised so return component
        return <Component {...props} />;
      } else {
        AppUtils.removeIdentityToken();
        return (
          <Redirect
            to={{
              pathname: AppURLProps.login,
              state: { from: props.location },
            }}
          />
        );
      }
    }}
  />
);

export default PrivateRoute;
