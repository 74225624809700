import React, { Component } from "react";
import { AppMsgResProps } from "../core/messages/app-properties";

class AppFooter extends Component {
  render() {
    return (
      <footer className="footer text-left font-12">
        {AppMsgResProps.body.content.copyright()}
      </footer>
    );
  }
}
export default AppFooter;
