import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Table,
  Input,
  FormGroup,
  Label,
  Alert,
  Spinner,
} from "reactstrap";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import ConfirmModal from "../../core/helpers/confirm-modal";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import PageDisplay from "../../core/helpers/page-display";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import PageUtils from "../../core/helpers/page-utils";
import { SetupURLProps } from "../settings/setup-urls";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { UserMsgResProps } from "../messages/user-properties";
import { manageError } from "../../core/actions/common-actions";
import { isPagePartPrivileged } from "../../core/actions/identity-actions";
import {
  getUser,
  listSites,
  getUserSites,
  editUserSites,
  searchUsersSites,
} from "../actions/user-actions";
import PageListPagination from "../../core/helpers/page-list-pagination";
import PageListLimitSelect from "../../core/helpers/page-list-limit-select";
import PageListSearchField from "../../core/helpers/page-list-search-field";

class UserSiteAccess extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _helpers = null;
  _pcUserSiteAccess = "AS-USR-USTA";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isOpenConfirmModal: false,
      formInputFields: null,
      user: null,
      sites: null,
      userSites: null,
      tempUserSitesObj: null,
      showAccessPrevilages: false,
      siteAllRecords: null,
      siteDisplayRecords: null,
      sitePageRecords: null,
      searchValue: "",
      searchKeyword: "",
      pageOffset: AppConfigProps.listPage.pageOffset.defaultValue,
      pageLimit: AppConfigProps.listPage.pageLimit.defaultValue,
      userSitesLength: null,
      loadSitesLength: null,
      selectedOptions: "",
      ConfirmModalData: true,
      isChecked: false,
      isCheckedDataLoaded: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    const userId = this.props.match.params.uid;
    await this.loadPageData(userId);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const userId = this.props.match.params.uid;
    if (userId !== prevProps.match.params.uid) {
      PageUtils.scrollToTop();
      await this.loadPageData(userId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async (userId) => {
    if (
      userId === null ||
      userId === undefined ||
      userId !== parseInt(userId).toString()
    )
      this.props.history.push(SetupURLProps.users.listUser);

    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ user: null });
    await this.checkPartPrivilege();
    await getUser(userId, this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ user: res.data.result });
          if (this.state.user && this.state.user.organizationId) {
            await this.loadSites(this.state.user.organizationId);
            await this.loadUserSites(this.state.user.userId);
          }
          await this.setStateAsync({
            formInputFields: this.initFormInputFields(),
          });
        } else {
          this.props.history.push(SetupURLProps.users.listUser);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await this.setStateAsync({ isPageDataFetched: true });
  };

  loadSites = async (organizationId) => {
    await this.setStateAsync({ sites: null });
    await this.setStateAsync({
      siteAllRecords: null,
      siteDisplayRecords: null,
      sitePageRecords: null,
    });
    const queryData = "sortField=siteName&sortOrder=1";
    await listSites(organizationId, queryData, this._cancelToken)
      .then(async (res) => {
        await this.setStateAsync({ loadSitesLength: res.data.records?.length });
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ sites: res.data.records });
            await this.setStateAsync({ siteAllRecords: res.data.records });
            let sortedRecords = await PageUtils.sortListRecords(
              this.state.siteAllRecords,
              this.state.sortedColumn,
              this.state.sortedOrder
            );
            await this.setStateAsync({ siteDisplayRecords: sortedRecords });
            let slicedRecords = await PageUtils.sliceListRecords(
              this.state.siteDisplayRecords,
              this.state.pageOffset,
              this.state.pageLimit
            );
            await this.setStateAsync({ sitePageRecords: slicedRecords });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  loadUserSites = async (userId) => {
    await this.setStateAsync({ userSites: null });
    await getUserSites(userId, this._cancelToken)
      .then(async (res) => {
        await this.setStateAsync({
          userSitesLength: res.data.result.sites.length,
        });
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          if (res.data.result.sites && res.data.result.sites.length > 0) {
            await this.setStateAsync({ userSites: res.data.result.sites });
          }
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    await this.radioCheckBoxInitiate();
  };

  radioCheckBoxInitiate = async () => {
    if (this.state.loadSitesLength === this.state.userSitesLength) {
      const value = this.state.sites?.map((site) => {
        return site.siteId;
      });
      const userSitesObj = {
        userId: this.state.user.userId,
        siteId: value,
      };
      await this.setStateAsync({ tempUserSitesObj: userSitesObj });

      this.setStateAsync({
        selectedOptions: UserMsgResProps.body.form.radio.label,
      });
      this.setStateAsync({ showAccessPrevilages: false, isChecked: true });
    } else {
      this.setStateAsync({
        selectedOptions: UserMsgResProps.body.form.radio1.label,
      });
      this.setStateAsync({ showAccessPrevilages: true, isChecked: false });
    }
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcUserSiteAccess}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  initFormInputFields = () => {
    let selSiteId = [];
    if (this.state.userSites && this.state.userSites.length > 0) {
      this.state.userSites.forEach((siteId) => {
        selSiteId.push(siteId);
      });
    }
    return {
      siteId: selSiteId,
    };
  };

  formValidationSchema = () => {
    const schema = {};
    return schema;
  };

  handleConfirmation = async (values, helpers) => {
    // Remove Duplicated from Array
    let uniqueArr = values.siteId.filter((c, index) => {
      return values.siteId.indexOf(c) === index;
    });

    if (this.state.ConfirmModalData === true) {
      if (
        this.state.selectedOptions !== UserMsgResProps.body.form.radio.label
      ) {
        this._helpers = helpers;
        const userSitesObj = {
          userId: this.state.user.userId,
          siteId: uniqueArr,
          orgActiveSitesCount: this.state?.siteAllRecords?.length,
          userOrganizationId: this.state?.user?.organizationId,
        };
        await this.setStateAsync({ tempUserSitesObj: userSitesObj });
        this.openConfirmModal();
      } else {
        this.state.tempUserSitesObj["orgActiveSitesCount"] =
          this.state?.siteAllRecords?.length;
        this.state.tempUserSitesObj["userOrganizationId"] =
          this.state?.user?.organizationId;
        this._helpers = helpers;
        this.openConfirmModal();
      }
    } else {
      await this.setStateAsync({ ConfirmModalData: true });
    }
  };

  handleSubmit = async () => {
    const userSitesObj = this.state.tempUserSitesObj;
    const helpers = this._helpers;
    this.closeConfirmModal();
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }
    await editUserSites(userSitesObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          const statusMsg = [
            "S",
            UserMsgResProps.body.notification.success.editUserAccess,
          ];
          if (res.data.result) {
            await this.setStateAsync({ userSites: res.data.result.sites });
            await this.setStateAsync({
              formInputFields: this.initFormInputFields(),
            });
            if (
              document.getElementsByName("selectAll") &&
              document.getElementsByName("selectAll").length > 0
            ) {
              for (
                let i = 0;
                i < document.getElementsByName("selectAll").length;
                i++
              ) {
                let elem = document.getElementsByName("selectAll")[i];
                elem.checked = false;
              }
            }
          }
          if (this._isMounted) {
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          UserMsgResProps.body.notification.error.message,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);

          if (err.data && err.data.errors && err.data.errors.length > 0) {
            const errors = err.data.errors;
            errors.forEach((error) => {
              if (error.element && error.message && error.location === "body") {
                helpers.setFieldError(error.element, error.message);
              }
            });
          }
          PageUtils.scrollToTop();
        }
      });
    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  toggleMultipleActions = async (e, sites, arrayHelpers, formik) => {
    if (sites && sites.length > 0) {
      if (e && e.target.checked && e.target.checked !== null) {
        //Remove previous Selected
        const idx = formik.values.siteId;
        idx &&
          idx.map(async (site) => {
            arrayHelpers.remove(site);
            arrayHelpers.pop();
          });

        const value = sites?.map((site) => {
          return site.siteId;
        });
        //Add to array
        sites &&
          sites.map((site) => {
            return arrayHelpers.push(site.siteId);
          });
        const userSitesObj = {
          userId: this.state.user.userId,
          siteId: value,
        };
        await this.setStateAsync({ tempUserSitesObj: userSitesObj });
      }
    }
  };

  toggleSingleAction = async (e, site, formik, arrayHelpers) => {
    if (e.target.checked) arrayHelpers.push(site.siteId);
    else {
      const idx = formik.values.siteId.indexOf(site.siteId);
      arrayHelpers.remove(idx);
    }
  };

  handleOutputChange = async (e, arrayHelpers, formik) => {
    let outputValue = e.target.value;
    this.setStateAsync({ selectedOptions: outputValue });
    if (outputValue === UserMsgResProps.body.form.radio.label) {
      this.setStateAsync({ showAccessPrevilages: false, isChecked: true });
      await this.toggleMultipleActions(
        e,
        this.state.sites,
        arrayHelpers,
        formik
      );
    } else {
      await this.setStateAsync({ showAccessPrevilages: true });
    }
  };

  handlePageLimitChange = async (pageLimit) => {
    if (pageLimit > 0) {
      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.siteDisplayRecords,
        pageOffset,
        pageLimit
      );
      await this.setStateAsync({ sitePageRecords: slicedRecords });
      await this.setStateAsync({ pageLimit: pageLimit });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handlePageOffsetChange = async (pageOffset) => {
    await this.setStateAsync({ ConfirmModalData: false });
    if (pageOffset >= 0) {
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.siteDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );
      await this.setStateAsync({ sitePageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchChange = async (e) => {
    await this.setStateAsync({ searchValue: e.target.value });
  };

  handleSearchSubmit = async (e) => {
    if (this.state.siteAllRecords && this.state.siteAllRecords.length > 0) {
      let searchKeyword = this.state.searchValue;
      await this.setStateAsync({ searchKeyword: searchKeyword });
      let searchedRecords = await searchUsersSites(
        this.state.siteAllRecords,
        this.state.searchKeyword
      );

      let sortedRecords = await PageUtils.sortListRecords(
        searchedRecords,
        this.state.sortedColumn,
        this.state.sortedOrder
      );
      await this.setStateAsync({ siteDisplayRecords: sortedRecords });

      let pageOffset = 0;
      let slicedRecords = await PageUtils.sliceListRecords(
        this.state.siteDisplayRecords,
        pageOffset,
        this.state.pageLimit
      );

      await this.setStateAsync({ sitePageRecords: slicedRecords });
      await this.setStateAsync({ pageOffset: pageOffset });
    }
  };

  handleSearchCancel = async (e) => {
    e.preventDefault();
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.siteAllRecords,
      this.state.sortedColumn,
      this.state.sortedOrder
    );
    await this.setStateAsync({ siteDisplayRecords: sortedRecords });
    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.siteDisplayRecords,
      this.state.pageOffset,
      this.state.pageLimit
    );
    await this.setStateAsync({ sitePageRecords: slicedRecords });

    await this.setStateAsync({ searchKeyword: "", searchValue: "" });
    await this.setStateAsync({ pageOffset: pageOffset });
  };

  checkAllSites = async (event, formik, arrayHelpers) => {
    event.persist();
    await this.setStateAsync({
      isChecked: event.target.checked,
      isCheckedDataLoaded: true,
    });

    let sites = this.state.sites;
    setTimeout(async () => {
      if (this.state.isChecked) {
        await sites.forEach((obj) => {
          if (
            obj.siteContractedStatus === 1 &&
            formik.values.siteId?.includes(obj.siteId) === false
          ) {
            arrayHelpers.push(obj.siteId);
          }
        });
        await this.setStateAsync({ isCheckedDataLoaded: false });
      } else {
        await sites.map(async (site) => {
          arrayHelpers.remove(site.siteId);
          arrayHelpers.pop();
        });
        await this.setStateAsync({ isCheckedDataLoaded: false });
      }
    }, 0);
  };

  renderAccessPrivilegesForm(formik) {
    return (
      <>
        {this.state.siteAllRecords && this.state.siteAllRecords.length > 0 ? (
          <div className="sram-list-head">
            <span className="float-left">
              <PageListSearchField
                searchValue={this.state.searchValue}
                searchKeyword={this.state.searchKeyword}
                handleSearchChange={this.handleSearchChange}
                handleSearchSubmit={this.handleSearchSubmit}
                handleSearchCancel={this.handleSearchCancel}
              />
            </span>
            <span className="float-right">
              {PageDisplay.getListPageRecordsDisplayInfo(
                this.state.siteDisplayRecords
                  ? this.state.siteDisplayRecords.length
                  : 0,
                this.state.pageOffset,
                this.state.pageLimit
              )}
            </span>
          </div>
        ) : null}
        {this.state.sitePageRecords && this.state.sitePageRecords.length > 0 ? (
          <FieldArray
            name="siteId"
            render={(arrayHelpers) => (
              <Table responsive size="sm" className="sram-table-def">
                <thead>
                  <tr>
                    {this.state.sitePageRecords &&
                    this.state.sitePageRecords.length > 0 ? (
                      <th className="sram-text-font sram-font-weight-600 sram-table-th">
                        <div>
                          <Spinner
                            size="sm"
                            color="secondary"
                            className="sram-user-load"
                            style={{
                              display:
                                this.state.isCheckedDataLoaded === true
                                  ? ""
                                  : "none",
                            }}
                          />
                          <input
                            name="allSites"
                            type="checkbox"
                            className="sram-user-access-select-all"
                            checked={this.state.isChecked}
                            onChange={(e) =>
                              this.checkAllSites(e, formik, arrayHelpers)
                            }
                          />
                          &nbsp;
                          {UserMsgResProps.head.title.siteName}
                        </div>
                      </th>
                    ) : null}
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {UserMsgResProps.head.title.siteId}
                    </th>
                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                      {UserMsgResProps.head.title.contracted}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sitePageRecords &&
                    this.state.sitePageRecords.map((site, index) => {
                      let keyString = {
                        key: `key-siteaccess-${index}`,
                      };
                      return (
                        <tr {...keyString}>
                          <td>
                            <FormGroup check inline>
                              <Label check>
                                <Input
                                  name="siteId"
                                  id={`check_${site.siteId}`}
                                  type="checkbox"
                                  value={site.siteId}
                                  checked={formik.values?.siteId?.includes(
                                    site.siteId
                                  )}
                                  disabled={site.siteContractedStatus === 0}
                                  onChange={async (e) => {
                                    await this.toggleSingleAction(
                                      e,
                                      site,
                                      formik,
                                      arrayHelpers
                                    );
                                  }}
                                />
                                &nbsp;
                                {site.siteName}
                              </Label>
                            </FormGroup>
                          </td>
                          <td>{site.siteNumber ? site.siteNumber : "-"}</td>
                          <td>
                            {site.siteContractedStatus === 0 ? (
                              <i className="fa fa-times" aria-hidden="true"></i>
                            ) : (
                              <i className="fa fa-check" aria-hidden="true"></i>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          ></FieldArray>
        ) : (
          <div className="pt-4">
            <div className="pt-4">
              {/* {PageDisplay.showWarningNotification(
                UserMsgResProps.body.content.noSitesFound
              )} */}
              <Alert
                color="secondary"
                fade={true}
                className="bg-white border-secondary rounded-lg text-secondary mb-4"
              >
                <span className="d-inline float-left mr-3">
                  <i className="fas fa-exclamation-circle font-22"></i>
                </span>
                <span>{UserMsgResProps.body.content.noSitesFound}</span>
              </Alert>
            </div>
          </div>
        )}
        {this.state.sitePageRecords && this.state.sitePageRecords.length ? (
          <div className="pb-4">
            <div className="float-left">
              <PageListLimitSelect
                pageLimit={this.state.pageLimit}
                pageOffset={this.state.pageOffset}
                totalRecords={this.state.siteDisplayRecords.length}
                handlePageLimitChange={this.handlePageLimitChange}
              />
            </div>
            <div className="float-right">
              <PageListPagination
                pageLimit={this.state.pageLimit}
                pageOffset={this.state.pageOffset}
                totalRecords={this.state.siteDisplayRecords.length}
                handlePageOffsetChange={this.handlePageOffsetChange}
              />
            </div>
          </div>
        ) : null}
      </>
    );
  }

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            {this.state.user ? (
              <>
                <Card className="sram-page-form">
                  <CardTitle>
                    <span>{UserMsgResProps.body.content.userAccess}</span>
                    <span className="float-right">
                      {PageDisplay.showGoBackLink(
                        "L",
                        SetupURLProps.users.listUser,
                        this.props.history
                      )}
                    </span>
                  </CardTitle>
                  <CardBody>
                    <Formik
                      initialValues={this.state.formInputFields}
                      validationSchema={Yup.object().shape(
                        this.formValidationSchema()
                      )}
                      onSubmit={(values, helpers) =>
                        this.handleConfirmation(values, helpers)
                      }
                    >
                      {(formik) => (
                        <Form id="userForm" {...formEvent}>
                          <div className="sram-form-main">
                            <div>
                              {formik.status &&
                              Array.isArray(formik.status) &&
                              formik.status.length === 2 &&
                              (formik.status[0] === "E" ||
                                formik.status[0] === "S") ? (
                                <Row>
                                  <Col xs={12}>
                                    {formik.status[0] === "E"
                                      ? PageDisplay.showErrorNotification(
                                          formik.status[1]
                                        )
                                      : PageDisplay.showSuccessNotification(
                                          formik.status[1]
                                        )}
                                  </Col>
                                </Row>
                              ) : null}
                            </div>
                            <div className="sram-view-data">
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  {PageDisplay.getFormFieldLabelValue(
                                    UserMsgResProps.body.form.username.label,
                                    this.state.user.username
                                  )}
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  {PageDisplay.getFormFieldLabelValue(
                                    UserMsgResProps.body.form.email.label,
                                    this.state.user.email
                                  )}
                                </Col>
                              </Row>
                              <Row form>
                                <Col sm={12} md={6} lg={6}>
                                  {PageDisplay.getFormFieldLabelValue(
                                    UserMsgResProps.body.form.organization
                                      .label,
                                    this.state.user.organizationTreepath
                                  )}
                                </Col>
                                <Col sm={12} md={6} lg={6}>
                                  {PageDisplay.getFormFieldLabelValue(
                                    UserMsgResProps.body.form.role.label,
                                    this.state.user.roleName
                                  )}
                                </Col>
                              </Row>
                            </div>
                            <div>
                              <FieldArray
                                name="siteId"
                                render={(arrayHelpers) => (
                                  <FormGroup
                                    tag="fieldset"
                                    onChange={(e) =>
                                      this.handleOutputChange(
                                        e,
                                        arrayHelpers,
                                        formik
                                      )
                                    }
                                  >
                                    <Row>
                                      <Col>
                                        <FormGroup check>
                                          <Label check className="font-medium">
                                            <Input
                                              type="radio"
                                              name="radio1"
                                              value={
                                                UserMsgResProps.body.form.radio
                                                  .label
                                              }
                                              defaultChecked={
                                                this.state.selectedOptions ===
                                                UserMsgResProps.body.form.radio
                                                  .label
                                              }
                                            />{" "}
                                            Access to all{" "}
                                            {this.state.siteAllRecords?.length}{" "}
                                            sites
                                          </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                          <Label check className="font-medium">
                                            <Input
                                              type="radio"
                                              name="radio1"
                                              value={
                                                UserMsgResProps.body.form.radio1
                                                  .label
                                              }
                                              defaultChecked={
                                                this.state.selectedOptions ===
                                                UserMsgResProps.body.form.radio1
                                                  .label
                                              }
                                            />{" "}
                                            Choose sites below
                                          </Label>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                )}
                              ></FieldArray>
                              {this.state.showAccessPrevilages === true
                                ? this.renderAccessPrivilegesForm(formik)
                                : null}
                            </div>
                          </div>
                          <div className="sram-form-foot">
                            {this.state.sites && this.state.sites.length > 0
                              ? PageDisplay.showSaveButton(formik.isSubmitting)
                              : null}
                            {PageDisplay.showCancelButton(this.props.history)}
                          </div>
                          <FormFieldFocusError />
                        </Form>
                      )}
                    </Formik>
                  </CardBody>
                </Card>
                {this.state.tempUserSitesObj &&
                this.state.ConfirmModalData === true ? (
                  <ConfirmModal
                    isOpenConfirmModal={this.state.isOpenConfirmModal}
                    closeConfirmModal={this.closeConfirmModal}
                    handleSubmit={this.handleSubmit}
                  >
                    <div>
                      <div>
                        <Label>
                          {
                            UserMsgResProps.body.notification.confirmation
                              .editUserAccess
                          }
                        </Label>
                      </div>
                    </div>
                  </ConfirmModal>
                ) : null}
              </>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  UserMsgResProps.body.content.noUserFound
                )}
              </>
            )}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default UserSiteAccess;
