import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Collapse,
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { DispenserMsgResProps } from "../messages/dispenser-properties";
import WithLoading from "../../core/helpers/with-loading";
import classnames from "classnames";

class DispenserTechnicalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      activeTab: "1",
      setActiveTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, setActiveTab: tab });
    }
  }
  handleTechnicalDetails() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <>
        <Card className="sram-page-form sram-dispenser-card-bottom">
          <CardTitle
            onClick={this.handleTechnicalDetails.bind(this)}
            className="sram-title-cursor"
          >
            <span>{DispenserMsgResProps.head.title.technicalData}</span>
          </CardTitle>
          <Collapse isOpen={this.state.isOpen}>
            <CardBody className="sram-dispenser-section-2 sram-dispenser-card-bdy">
              <div>
                <Nav tabs>
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={this.toggle.bind(this, "1")}
                    >
                      <span>{DispenserMsgResProps.body.content.en_pump}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={this.toggle.bind(this, "2")}
                    >
                      <span>{DispenserMsgResProps.body.content.ssom}</span>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={this.toggle.bind(this, "3")}
                    >
                      <span>{DispenserMsgResProps.body.content.gsom}</span>
                    </NavLink>
                  </NavItem> */}
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={this.toggle.bind(this, "4")}
                    >
                      <span>
                        {DispenserMsgResProps.body.content.cardReader}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "5",
                      })}
                      onClick={this.toggle.bind(this, "5")}
                    >
                      <span>{DispenserMsgResProps.body.content.upm}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "6",
                      })}
                      onClick={this.toggle.bind(this, "6")}
                    >
                      <span>{DispenserMsgResProps.body.content.display}</span>
                    </NavLink>
                  </NavItem>
                  {/* <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "7",
                      })}
                      onClick={this.toggle.bind(this, "7")}
                    >
                      <span>
                        {DispenserMsgResProps.body.content.omnia}
                      </span>
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "7",
                      })}
                      onClick={this.toggle.bind(this, "7")}
                    >
                      <span>
                        {DispenserMsgResProps.body.content.blendRatio}
                      </span>
                    </NavLink>
                  </NavItem>
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "8",
                      })}
                      onClick={this.toggle.bind(this, "8")}
                    >
                      <span>
                        {DispenserMsgResProps.body.content.calibration}
                      </span>
                    </NavLink>
                  </NavItem> */}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <WithLoading
                      isPageDataFetched={this.props.isPageDataFetched}
                      type="page"
                      size="sm"
                    >
                      {this.props.data &&
                      this.props.technicalDeatils &&
                      this.props.data.length > 0 ? (
                        <>
                          {this.props.dispenser.manufacturerName ===
                          "Gilbarco" ? (
                            <Row className="pt-3">
                              <br />
                              {this.props.breifTechinicalDetails === true ||
                              this.props.detailedTechinicalDetails === true ? (
                                <>
                                  <Col sm={12} md={4} lg={3}>
                                    <p className="sram-text-font sram-font-weight-600">
                                      {
                                        DispenserMsgResProps.body.content
                                          .pumpModel
                                      }
                                    </p>
                                    <p className="sram-text-font">
                                      {this.props.technicalDeatils.pumpType !==
                                        null &&
                                      this.props.technicalDeatils.pumpType.trim()
                                        .length > 0
                                        ? this.props.technicalDeatils.pumpType
                                        : "-"}
                                    </p>
                                  </Col>
                                  <Col sm={12} md={4} lg={3}>
                                    <p className="sram-text-font sram-font-weight-600">
                                      {
                                        DispenserMsgResProps.body.content
                                          .softwareVersion
                                      }
                                    </p>
                                    <p className="sram-text-font">
                                      {this.props.technicalDeatils
                                        .appSoftwareVersion !== null &&
                                      this.props.technicalDeatils.appSoftwareVersion.trim()
                                        .length > 0
                                        ? this.props.technicalDeatils
                                            .appSoftwareVersion
                                        : "-"}
                                    </p>
                                  </Col>
                                  <Col sm={12} md={4} lg={3}>
                                    <p className="sram-text-font sram-font-weight-600">
                                      {
                                        DispenserMsgResProps.body.content
                                          .bootVersion
                                      }
                                    </p>
                                    <p className="sram-text-font">
                                      {this.props.technicalDeatils
                                        .bootVersion !== null &&
                                      this.props.technicalDeatils.bootVersion.trim()
                                        .length > 0
                                        ? this.props.technicalDeatils
                                            .bootVersion
                                        : "-"}
                                    </p>
                                  </Col>
                                  <Col sm={12} md={4} lg={3}>
                                    <p className="sram-text-font sram-font-weight-600">
                                      {
                                        DispenserMsgResProps.body.content
                                          .doorNodeVersion
                                      }
                                    </p>
                                    <p className="sram-text-font">
                                      {this.props.technicalDeatils.doorBNode !==
                                        null &&
                                      this.props.technicalDeatils.doorBNode.trim()
                                        .length > 0
                                        ? this.props.technicalDeatils.doorBNode
                                        : "-"}
                                    </p>
                                  </Col>
                                  <Col sm={12} md={4} lg={3}>
                                    <p className="sram-text-font sram-font-weight-600">
                                      {
                                        DispenserMsgResProps.body.content
                                          .mipVersion
                                      }
                                    </p>
                                    <p className="sram-text-font">
                                      {this.props.technicalDeatils
                                        .mipVersion !== null &&
                                      this.props.technicalDeatils.mipVersion.trim()
                                        .length > 0
                                        ? this.props.technicalDeatils.mipVersion
                                        : "-"}
                                    </p>
                                  </Col>
                                  <Col sm={12} md={4} lg={3}>
                                    <p className="sram-text-font sram-font-weight-600">
                                      {
                                        DispenserMsgResProps.body.content
                                          .unitType
                                      }
                                    </p>
                                    <p className="sram-text-font">
                                      {this.props.technicalDeatils.unitType !==
                                        null &&
                                      this.props.technicalDeatils.unitType.trim()
                                        .length > 0
                                        ? this.props.technicalDeatils.unitType
                                        : "-"}
                                    </p>
                                  </Col>
                                </>
                              ) : null}
                              {/* {this.props.detailedTechinicalDetails === true ? (
                                <Col sm={12} md={4} lg={3}>
                                  <p className="sram-text-font sram-font-weight-600">
                                    {
                                      DispenserMsgResProps.body.content
                                        .siteiqSoftwareVersion
                                    }
                                  </p>
                                  <p className="sram-text-font">
                                    {this.props.technicalDeatils
                                      .siteiqSoftwareVersion !== null &&
                                    this.props.technicalDeatils.siteiqSoftwareVersion.trim()
                                      .length > 0
                                      ? this.props.technicalDeatils
                                          .siteiqSoftwareVersion
                                      : "-"}
                                  </p>
                                </Col>
                              ) : null} */}
                            </Row>
                          ) : (
                            <Row>
                              <br />
                              {/* {this.props.breifTechinicalDetails === true ||
                      this.props.detailedTechinicalDetails === true ? ( */}
                              <>
                                <Col sm={12} md={4} lg={3}>
                                  <p className="sram-text-font sram-font-weight-600">
                                    {
                                      DispenserMsgResProps.body.content
                                        .pumpModel
                                    }
                                  </p>
                                  <p className="sram-text-font">
                                    {this.props.technicalDeatils.modelName !==
                                      null &&
                                    this.props.technicalDeatils.modelName.trim()
                                      .length > 0
                                      ? this.props.technicalDeatils.modelName
                                      : "-"}
                                  </p>
                                </Col>
                                <Col sm={12} md={4} lg={3}>
                                  <p className="sram-text-font sram-font-weight-600">
                                    {
                                      DispenserMsgResProps.body.content
                                        .templatePartNumber
                                    }
                                  </p>
                                  <p className="sram-text-font">
                                    {this.props.technicalDeatils
                                      .templatePartNumber !== null &&
                                    this.props.technicalDeatils.templatePartNumber.trim()
                                      .length > 0
                                      ? this.props.technicalDeatils
                                          .templatePartNumber
                                      : "-"}
                                  </p>
                                </Col>
                                <Col sm={12} md={4} lg={3}>
                                  <p className="sram-text-font sram-font-weight-600">
                                    {
                                      DispenserMsgResProps.body.content
                                        .templateDescription
                                    }
                                  </p>
                                  <p className="sram-text-font">
                                    {this.props.technicalDeatils
                                      .templateDescription !== null &&
                                    this.props.technicalDeatils.templateDescription.trim()
                                      .length > 0
                                      ? this.props.technicalDeatils
                                          .templateDescription
                                      : "-"}
                                  </p>
                                </Col>
                                <Col sm={12} md={4} lg={3}>
                                  <p className="sram-text-font sram-font-weight-600">
                                    {
                                      DispenserMsgResProps.body.content
                                        .igemVersion
                                    }
                                  </p>
                                  <p className="sram-text-font">
                                    {this.props.technicalDeatils.igemVersion !==
                                      null &&
                                    this.props.technicalDeatils.igemVersion !==
                                      ""
                                      ? this.props.technicalDeatils.igemVersion
                                      : "-"}
                                  </p>
                                </Col>
                                {/* {this.props.detailedTechinicalDetails ===
                                true ? (
                                  <Col sm={12} md={4} lg={3}>
                                    <p className="sram-text-font sram-font-weight-600">
                                      {
                                        DispenserMsgResProps.body.content
                                          .siteiqSoftwareVersion
                                      }
                                    </p>
                                    <p className="sram-text-font">
                                      {this.props.technicalDeatils
                                        .siteiqSoftwareVersion !== null &&
                                      this.props.technicalDeatils.siteiqSoftwareVersion.trim()
                                        .length > 0
                                        ? this.props.technicalDeatils
                                            .siteiqSoftwareVersion
                                        : "-"}
                                    </p>
                                  </Col>
                                ) : null} */}
                              </>
                              {/* ) : null} */}
                            </Row>
                          )}
                        </>
                      ) : (
                        <div className="pt-3 py-3 sram-no-data">
                          {DispenserMsgResProps.body.content.noIssuesFound}
                        </div>
                      )}
                    </WithLoading>
                  </TabPane>
                  <TabPane tabId="2">
                  <WithLoading
                      isPageDataFetched={this.props.isPageDataFetched}
                      type="page"
                      size="sm"
                    >
                      {this.props.technicalDetailsData &&
                      this.props.technicalDetailsData?.length > 0 ? (
                        <>
                          {this.props.technicalDetailsData.map((obj, index) => {
                            return (
                              <Row key={index}>
                                <br />
                                {/* <Col sm={12} md={6} lg={6} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.hardware}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : A
                                        </div>
                                        {obj?.cardReader?.hardware?.length >
                                        0 ? (
                                          obj?.cardReader?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "A" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : B
                                        </div>
                                        {obj?.cardReader?.hardware?.length >
                                        0 ? (
                                          obj?.cardReader?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "B" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col> */}
                                <Col sm={12} md={12} lg={12} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.software}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        className="pad-left-0"
                                      >
                                        {obj?.ssom?.software?.length >
                                        0 ? (
                                          obj?.ssom?.software.map(
                                            (ssom, j) => {
                                              return (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {ssom.software !==
                                                      null &&
                                                    ssom.software !== ""
                                                      ? ssom.software
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {ssom.value !== "" &&
                                                    ssom.value !== null
                                                      ? ssom.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              )
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      ) : (
                        <div className="pt-3 py-3 sram-no-data">
                          {DispenserMsgResProps.body.content.noIssuesFound}
                        </div>
                      )}
                    </WithLoading>
                  </TabPane>
                  <TabPane tabId="3">
                  <WithLoading
                      isPageDataFetched={this.props.isPageDataFetched}
                      type="page"
                      size="sm"
                    >
                      {this.props.technicalDetailsData &&
                      this.props.technicalDetailsData?.length > 0 ? (
                        <>
                          {this.props.technicalDetailsData.map((obj, index) => {
                            return (
                              <Row key={index}>
                                <br />
                                {/* <Col sm={12} md={6} lg={6} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.hardware}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : A
                                        </div>
                                        {obj?.cardReader?.hardware?.length >
                                        0 ? (
                                          obj?.cardReader?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "A" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : B
                                        </div>
                                        {obj?.cardReader?.hardware?.length >
                                        0 ? (
                                          obj?.cardReader?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "B" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col> */}
                                <Col sm={12} md={12} lg={12} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.software}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        {obj?.gsom?.software?.length >
                                        0 ? (
                                          obj?.gsom?.software.map(
                                            (software, j) => {
                                              return (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {software.software !==
                                                      null &&
                                                    software.software !== ""
                                                      ? software.software
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {software.value !== "" &&
                                                    software.value !== null
                                                      ? software.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              )
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        {obj?.gsom?.software?.length >
                                        0 ? (
                                          obj?.gsom?.software.map(
                                            (software, j) => {
                                              return (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {software.software !==
                                                      null &&
                                                    software.software !== ""
                                                      ? software.software
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {software.value !== "" &&
                                                    software.value !== null
                                                      ? software.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              )
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      ) : (
                        <div className="pt-3 py-3 sram-no-data">
                          {DispenserMsgResProps.body.content.noIssuesFound}
                        </div>
                      )}
                    </WithLoading>
                  </TabPane>
                  <TabPane tabId="4">
                    <WithLoading
                      isPageDataFetched={this.props.isPageDataFetched}
                      type="page"
                      size="sm"
                    >
                      {this.props.technicalDetailsData &&
                      this.props.technicalDetailsData?.length > 0 ? (
                        <>
                          {this.props.technicalDetailsData.map((obj, index) => {
                            return (
                              <Row key={index}>
                                <br />
                                {/* <Col sm={12} md={6} lg={6} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.hardware}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : A
                                        </div>
                                        {obj?.cardReader?.hardware?.length >
                                        0 ? (
                                          obj?.cardReader?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "A" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : B
                                        </div>
                                        {obj?.cardReader?.hardware?.length >
                                        0 ? (
                                          obj?.cardReader?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "B" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col> */}
                                <Col sm={12} md={12} lg={12} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.software}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : A
                                        </div>
                                        {obj?.cardReader?.software?.length >
                                        0 ? (
                                          obj?.cardReader?.software.map(
                                            (software, j) => {
                                              return software.sideLabel !==
                                                null &&
                                                software.sideLabel !== "" &&
                                                software.sideLabel === "A" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {software.software !==
                                                      null &&
                                                    software.software !== ""
                                                      ? software.software
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {software.value !== "" &&
                                                    software.value !== null
                                                      ? software.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : B
                                        </div>
                                        {obj?.cardReader?.software?.length >
                                        0 ? (
                                          obj?.cardReader?.software.map(
                                            (software, j) => {
                                              return software.sideLabel !==
                                                null &&
                                                software.sideLabel !== "" &&
                                                software.sideLabel === "B" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {software.software !==
                                                      null &&
                                                    software.software !== ""
                                                      ? software.software
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {software.value !== "" &&
                                                    software.value !== null
                                                      ? software.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      ) : (
                        <div className="pt-3 py-3 sram-no-data">
                          {DispenserMsgResProps.body.content.noIssuesFound}
                        </div>
                      )}
                    </WithLoading>
                  </TabPane>
                  <TabPane tabId="5">
                    <WithLoading
                      isPageDataFetched={this.props.isPageDataFetched}
                      type="page"
                      size="sm"
                    >
                      {this.props.technicalDetailsData &&
                      this.props.technicalDetailsData?.length > 0 ? (
                        <>
                          {this.props.technicalDetailsData.map((obj, index) => {
                            return (
                              <Row key={index}>
                                <br />
                                {/* <Col sm={12} md={6} lg={6} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.hardware}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : A
                                        </div>
                                        {obj?.upm?.hardware?.length > 0 ? (
                                          obj?.upm?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "A" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : B
                                        </div>
                                        {obj?.upm?.hardware?.length > 0 ? (
                                          obj?.upm?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "B" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col> */}
                                <Col sm={12} md={12} lg={12} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.software}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : A
                                        </div>
                                        {obj?.upm?.software?.length > 0 ? (
                                          obj?.upm?.software.map(
                                            (software, j) => {
                                              return software.sideLabel !==
                                                null &&
                                                software.sideLabel !== "" &&
                                                software.sideLabel === "A" ? (
                                                // software.software !==
                                                // "PCI_Bui" ? (
                                                  <div key={j}>
                                                    <span className="sram-text-font sram-font-weight-500">
                                                      {software.software !==
                                                        null &&
                                                      software.software !== ""
                                                        ? software.software
                                                        : "-"}
                                                      &nbsp; :
                                                    </span>
                                                    &nbsp;
                                                    <span className="sram-text-font">
                                                      {software.value !== "" &&
                                                      software.value !== null
                                                        ? software.value
                                                        : "-"}
                                                    </span>
                                                  </div>
                                                // ) : (
                                                //   <div>
                                                //       <span className="sram-text-font sram-font-weight-500">
                                                //         Software Version : 
                                                //       </span>
                                                //       &nbsp;
                                                //       <span className="sram-text-font">
                                                //         {
                                                //           software.value
                                                //             .softwareVersion
                                                //         }
                                                //       </span>
                                                //       <br/>
                                                //       <span className="sram-text-font sram-font-weight-500">
                                                //         BOOT :
                                                //       </span>
                                                //       &nbsp;
                                                //       <span className="sram-text-font">
                                                //         {
                                                //           software.value
                                                //             .bootVersion
                                                //         }
                                                //       </span>
                                                //       <br/>
                                                //       <span className="sram-text-font sram-font-weight-500">
                                                //         CRINDBIOS :
                                                //       </span>
                                                //       &nbsp;
                                                //       <span className="sram-text-font">
                                                //         {software.value.crindbios}
                                                //       </span>
                                                //       <br/>
                                                //       <span className="sram-text-font sram-font-weight-500">
                                                //         Display Manager :
                                                //       </span>
                                                //       &nbsp;
                                                //       <span className="sram-text-font">
                                                //         {
                                                //           software.value
                                                //             .displayManager
                                                //         }
                                                //       </span>
                                                //       <br/>
                                                //       <span className="sram-text-font sram-font-weight-500">
                                                //         EMV :
                                                //       </span>
                                                //       &nbsp;
                                                //       <span className="sram-text-font">
                                                //         {software.value.emv}
                                                //       </span>
                                                //     </div>
                                                // )
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : B
                                        </div>
                                        {obj?.upm?.software?.length > 0 ? (
                                          obj?.upm?.software.map(
                                            (software, j) => {
                                              return software.sideLabel !==
                                                null &&
                                                software.sideLabel !== "" &&
                                                software.sideLabel === "B" ? (
                                                  // software.software !==
                                                  // "PCI_Bui" ? (
                                                    <div key={j}>
                                                      <span className="sram-text-font sram-font-weight-500">
                                                        {software.software !==
                                                          null &&
                                                        software.software !== ""
                                                          ? software.software
                                                          : "-"}
                                                        &nbsp; :
                                                      </span>
                                                      &nbsp;
                                                      <span className="sram-text-font">
                                                        {software.value !== "" &&
                                                        software.value !== null
                                                          ? software.value
                                                          : "-"}
                                                      </span>
                                                    </div>
                                                  // ) : (
                                                  //   <div>
                                                  //     <span className="sram-text-font sram-font-weight-500">
                                                  //       Software Version : 
                                                  //     </span>
                                                  //     &nbsp;
                                                  //     <span className="sram-text-font">
                                                  //       {
                                                  //         software.value
                                                  //           .softwareVersion
                                                  //       }
                                                  //     </span>
                                                  //     <br/>
                                                  //     <span className="sram-text-font sram-font-weight-500">
                                                  //       BOOT :
                                                  //     </span>
                                                  //     &nbsp;
                                                  //     <span className="sram-text-font">
                                                  //       {
                                                  //         software.value
                                                  //           .bootVersion
                                                  //       }
                                                  //     </span>
                                                  //     <br/>
                                                  //     <span className="sram-text-font sram-font-weight-500">
                                                  //       CRINDBIOS :
                                                  //     </span>
                                                  //     &nbsp;
                                                  //     <span className="sram-text-font">
                                                  //       {software.value.crindbios}
                                                  //     </span>
                                                  //     <br/>
                                                  //     <span className="sram-text-font sram-font-weight-500">
                                                  //       Display Manager :
                                                  //     </span>
                                                  //     &nbsp;
                                                  //     <span className="sram-text-font">
                                                  //       {
                                                  //         software.value
                                                  //           .displayManager
                                                  //       }
                                                  //     </span>
                                                  //     <br/>
                                                  //     <span className="sram-text-font sram-font-weight-500">
                                                  //       EMV :
                                                  //     </span>
                                                  //     &nbsp;
                                                  //     <span className="sram-text-font">
                                                  //       {software.value.emv}
                                                  //     </span>
                                                  //   </div>
                                                  // )
                                                ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      ) : (
                        <div className="pt-3 py-3 sram-no-data">
                          {DispenserMsgResProps.body.content.noIssuesFound}
                        </div>
                      )}
                    </WithLoading>
                  </TabPane>
                  <TabPane tabId="6">
                  <WithLoading
                      isPageDataFetched={this.props.isPageDataFetched}
                      type="page"
                      size="sm"
                    >
                      {this.props.technicalDetailsData &&
                      this.props.technicalDetailsData?.length > 0 ? (
                        <>
                          {this.props.technicalDetailsData.map((obj, index) => {
                            return (
                              <Row key={index}>
                                <br />
                                {/* <Col sm={12} md={6} lg={6} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.hardware}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : A
                                        </div>
                                        {obj?.display?.hardware?.length > 0 ? (
                                          obj?.display?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "A" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : B
                                        </div>
                                        {obj?.display?.hardware?.length > 0 ? (
                                          obj?.display?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "B" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col> */}
                                <Col sm={12} md={12} lg={12} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.software}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : A
                                        </div>
                                        {obj?.display?.software?.length > 0 ? (
                                          obj?.display?.software.map(
                                            (software, j) => {
                                              return software.sideLabel !==
                                                null &&
                                                software.sideLabel !== "" &&
                                                software.sideLabel === "A" ? (
                                                // software.software !==
                                                // "PCI_Bui" ? (
                                                  <div key={j}>
                                                    <span className="sram-text-font sram-font-weight-500">
                                                      {software.software !==
                                                        null &&
                                                      software.software !== ""
                                                        ? software.software
                                                        : "-"}
                                                      &nbsp; :
                                                    </span>
                                                    &nbsp;
                                                    <span className="sram-text-font">
                                                      {software.value !== "" &&
                                                      software.value !== null
                                                        ? software.value
                                                        : "-"}
                                                    </span>
                                                  </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : B
                                        </div>
                                        {obj?.display?.software?.length > 0 ? (
                                          obj?.display?.software.map(
                                            (software, j) => {
                                              return software.sideLabel !==
                                                null &&
                                                software.sideLabel !== "" &&
                                                software.sideLabel === "B" ? (
                                                    <div key={j}>
                                                      <span className="sram-text-font sram-font-weight-500">
                                                        {software.software !==
                                                          null &&
                                                        software.software !== ""
                                                          ? software.software
                                                          : "-"}
                                                        &nbsp; :
                                                      </span>
                                                      &nbsp;
                                                      <span className="sram-text-font">
                                                        {software.value !== "" &&
                                                        software.value !== null
                                                          ? software.value
                                                          : "-"}
                                                      </span>
                                                    </div>
                                                ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      ) : (
                        <div className="pt-3 py-3 sram-no-data">
                          {DispenserMsgResProps.body.content.noIssuesFound}
                        </div>
                      )}
                    </WithLoading>
                  </TabPane>
                  <TabPane tabId="7">
                  <WithLoading
                      isPageDataFetched={this.props.isPageDataFetched}
                      type="page"
                      size="sm"
                    >
                      {this.props.technicalDetailsData &&
                      this.props.technicalDetailsData?.length > 0 ? (
                        <>
                          {this.props.technicalDetailsData.map((obj, index) => {
                            return (
                              <Row key={index}>
                                <br />
                                {/* <Col sm={12} md={6} lg={6} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.hardware}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : A
                                        </div>
                                        {obj?.display?.hardware?.length > 0 ? (
                                          obj?.display?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "A" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        <div className="sram-font-weight-600">
                                          {
                                            DispenserMsgResProps.body.content
                                              .sidelabel
                                          }{" "}
                                          : B
                                        </div>
                                        {obj?.display?.hardware?.length > 0 ? (
                                          obj?.display?.hardware.map(
                                            (hardware, j) => {
                                              return hardware.sideLabel !==
                                                null &&
                                                hardware.sideLabel !== "" &&
                                                hardware.sideLabel === "B" ? (
                                                <div key={j}>
                                                  <span className="sram-text-font sram-font-weight-500">
                                                    {hardware.hardware !==
                                                      null &&
                                                    hardware.hardware !== ""
                                                      ? hardware.hardware
                                                      : "-"}
                                                    &nbsp; :
                                                  </span>
                                                  &nbsp;
                                                  <span className="sram-text-font">
                                                    {hardware.value !== "" &&
                                                    hardware.value !== null
                                                      ? hardware.value
                                                      : "-"}
                                                  </span>
                                                </div>
                                              ) : null;
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col> */}
                                <Col sm={12} md={12} lg={12} className="pt-3">
                                  <div className="sram-font-weight-600">
                                    {DispenserMsgResProps.body.content.software}
                                  </div>
                                  <div className="pl-3 pt-2">
                                    <Row>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        {obj?.omnia?.software?.length > 0 ? (
                                          obj?.omnia?.software.map(
                                            (software, j) => {
                                              return (
                                                // software.software !==
                                                // "PCI_Bui" ? (
                                                  <div key={j}>
                                                    <span className="sram-text-font sram-font-weight-500">
                                                      {software.software !==
                                                        null &&
                                                      software.software !== ""
                                                        ? software.software
                                                        : "-"}
                                                      &nbsp; :
                                                    </span>
                                                    &nbsp;
                                                    <span className="sram-text-font">
                                                      {software.value !== "" &&
                                                      software.value !== null
                                                        ? software.value
                                                        : "-"}
                                                    </span>
                                                  </div>
                                              )
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col
                                        sm={12}
                                        md={6}
                                        lg={6}
                                        className="pad-left-0"
                                      >
                                        {obj?.omnia?.software?.length > 0 ? (
                                          obj?.omnia?.software.map(
                                            (software, j) => {
                                              return (
                                                    <div key={j}>
                                                      <span className="sram-text-font sram-font-weight-500">
                                                        {software.software !==
                                                          null &&
                                                        software.software !== ""
                                                          ? software.software
                                                          : "-"}
                                                        &nbsp; :
                                                      </span>
                                                      &nbsp;
                                                      <span className="sram-text-font">
                                                        {software.value !== "" &&
                                                        software.value !== null
                                                          ? software.value
                                                          : "-"}
                                                      </span>
                                                    </div>
                                                )
                                            }
                                          )
                                        ) : (
                                          <div className="pt-3 py-3 sram-no-data">
                                            {
                                              DispenserMsgResProps.body.content
                                                .noIssuesFound
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      ) : (
                        <div className="pt-3 py-3 sram-no-data">
                          {DispenserMsgResProps.body.content.noIssuesFound}
                        </div>
                      )}
                    </WithLoading>
                  </TabPane>
                  {/* <TabPane tabId="7">
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <div className="pt-3 py-3 sram-no-data">
                            {DispenserMsgResProps.body.content.noIssuesFound}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane> */}
                  {/* <TabPane tabId="8">
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <div className="pt-3 py-3 sram-no-data">
                            {DispenserMsgResProps.body.content.noIssuesFound}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane> */}
                </TabContent>
              </div>
            </CardBody>
          </Collapse>
        </Card>
      </>
    );
  }
}

export default DispenserTechnicalDetails;
