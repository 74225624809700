import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Badge,
  Collapse,
} from "reactstrap";
import { updateTicket, getHistory } from "../actions/alert-actions";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import { AppMsgResProps } from "../../core/messages/app-properties";
import axios from "axios";
import TicketsView from "./tickets-view";
import PerfectScrollbar from "react-perfect-scrollbar";
import Select from "react-select";

class Tickets extends React.Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  constructor() {
    super();
    this.state = {
      prioritylist: [
        {
          label: "High",
          value: "High",
        },
        {
          label: "Medium",
          value: "Medium",
        },
        {
          label: "Low",
          value: "Low",
        },
      ],
      changePriority: { label: "Change Priority", value: "" },
      isAlertStatusUpdateModal: false,
      alertTicketId: null,
      ticketPriority: null,
      lockStatus: null,
      statusData: null,
      status: null,
      statusDataFetched: false,
      isHistoryDataFetched: false,
      history: null,
      ticketStatus: null,
      assignId: null,
      wip: null,
      collapsePriority: false,
      collapseApproval: false,
      collapseStatus: false,
      collapseWIP: false,
      collapseTicketId: false,
      showSelectedFilters: [],
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  updateTicket = async () => {
    let assignId = this.props.assignId;
    await this.setStateAsync({ assignId });
    const ticketObj = {
      alertTicketId: this.state.alertTicketId,
      ticketPriority: this.state.ticketPriority,
      ticketStatus: this.state.ticketStatus,
      lockStatus: this.state.lockStatus,
      assignId: this.state.assignId,
    };
    await updateTicket(ticketObj, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data
        ) {
          await this.props.getAlertTickets();
          await this.setStateAsync({
            ticketPriority: "",
            ticketStatus: "",
            lockStatus: "",
            assignId: "",
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  getHistory = async (alertTicketId) => {
    await this.setStateAsync({
      isHistoryDataFetched: false,
      history: null,
    });
    await getHistory(alertTicketId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let ticketsList = this.props.tickets;
          for (const data of ticketsList) {
            if (data.alertTicketId === alertTicketId) {
              data.alertHistory = res.data.records;
            }
          }
          this.props.ticketDataFetch(ticketsList);
          await this.setStateAsync({
            history: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isHistoryDataFetched: true });
  };

  checkTicket = async (event) => {
    var value = event.target.value;
    var checked = event.target.checked;
    let todos = this.props.todos;
    let todos1 = this.props.todos1;
    let todos2 = this.props.todos2;
    let todos3 = this.props.todos3;
    let todos4 = this.props.todos4;
    todos.forEach((obj) => {
      if (obj.value === value) obj["isChecked"] = checked;
    });
    await this.props.checkPriority(todos);
    todos1.forEach((obj) => {
      if (obj.value === value) obj["isChecked"] = checked;
    });
    await this.props.checkApproval(todos1);
    todos2.forEach((obj) => {
      if (obj.value === value) obj["isChecked"] = checked;
    });
    await this.props.checkWIP(todos2);
    todos3.forEach((obj) => {
      if (obj.value === value) obj["isChecked"] = checked;
    });
    await this.props.checkAlertsStatus(todos3);
    todos4.forEach((obj) => {
      if (obj.value === value) {
        obj["isChecked"] = checked;
        obj["content"] = value;
        obj["value"] = value;
      }
    });
    await this.props.checkTicketIDs(todos4);
    const filterList = [...todos, ...todos1, ...todos2, ...todos3, ...todos4];
    this.setState({ showSelectedFilters: filterList });
    await this.props.reloadTicketsData();
  };

  handleSearchTickets = async (e) => {
    let isChecked;
    let searchKeyword;
    if (e === null) {
      isChecked = false;
      searchKeyword = "";
    } else {
      isChecked = true;
      searchKeyword = e.ticketNumber;
    }

    let event = {
      target: {
        content: searchKeyword,
        value: searchKeyword,
        checked: isChecked,
      },
    };

    let updateArray = [
      {
        id: 100,
        content: searchKeyword,
        value: searchKeyword,
        isChecked: isChecked,
      },
    ];
    await this.props.checkTicketIDs(updateArray);
    await this.checkTicket(event);
  };

  handleRemoveClick = async (obj) => {
    if (obj && obj.id === 100) {
      let updateArray = [
        {
          id: 100,
          content: "",
          value: "",
          checked: false,
        },
      ];
      let event = {
        target: {
          content: "",
          value: "",
          checked: false,
        },
      };
      await this.props.checkTicketIDs(updateArray);
      await this.checkTicket(event);
    } else {
      let event = {
        target: {
          value: obj.value,
          checked: false,
        },
      };
      await this.checkTicket(event);
    }
  };

  getAddress(siteAddress, siteAddress1) {
    let address = "";
    if (siteAddress && siteAddress1) {
      address = siteAddress + "," + siteAddress1;
    } else if (siteAddress) {
      address = siteAddress;
    } else {
      address = siteAddress1;
    }
    return address;
  }

  getEventStatus(status) {
    if (status !== " ") {
      if (
        status.toLowerCase() === "down" ||
        status.toLowerCase() === "critical" ||
        status.toLowerCase() === "in-error"
      ) {
        return (
          <div className="tooltip">
            {" "}
            <span
              className="sram-circle-red tooltip"
              style={{ height: "20px", width: "20px" }}
            >
              &nbsp;
            </span>
            <span className="tooltiptext">Status : {status}</span>
          </div>
        );
      } else if (status.toLowerCase() === "in-warning") {
        return (
          <div className="tooltip">
            {" "}
            <span
              className="sram-circle-yellow tooltip"
              style={{ height: "20px", width: "20px" }}
            >
              &nbsp;
            </span>
            <span className="tooltiptext">Status : {status}</span>
          </div>
        );
      } else {
        return (
          <div className="tooltip">
            {" "}
            <span
              className="sram-circle-orange tooltip"
              style={{ height: "20px", width: "20px" }}
            >
              &nbsp;
            </span>
            <span className="tooltiptext">Status : {status}</span>
          </div>
        );
      }
    } else {
      return (
        <div className="tooltip">
          {" "}
          <span
            className="sram-circle-gray tooltip"
            style={{ height: "20px", width: "20px" }}
          >
            &nbsp;
          </span>
          <span className="tooltiptext">Status : No status from dispenser</span>
        </div>
      );
    }
  }

  getSquare(status) {
    if (status) {
      if (status.toLowerCase() === "high") {
        return (
          <div className="tooltip">
            {" "}
            <Badge className="sram-badge-danger" color="danger">
              &nbsp;
            </Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      } else if (status.toLowerCase() === "medium") {
        return (
          <div className="tooltip">
            {" "}
            <Badge className="sram-badge-warning" color="warning">
              &nbsp;
            </Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      } else if (status.toLowerCase() === "low") {
        return (
          <div className="tooltip">
            {" "}
            <Badge className="sram-badge-success" color="success">
              &nbsp;
            </Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      } else {
        return (
          <div className="tooltip">
            {" "}
            <Badge color="secondary">&nbsp;</Badge>
            <span className="tooltiptext">Priority : {status}</span>
          </div>
        );
      }
    } else {
      return (
        <div className="tooltip">
          {" "}
          <Badge className="sram-badge-gray">&nbsp;</Badge>
          <span className="tooltiptext">Priority : None</span>
        </div>
      );
    }
  }

  priorityChange = async (alertTicketId, event) => {
    // await this.setStateAsync({ changePriority: event });
    await this.setStateAsync({ alertTicketId: alertTicketId });

    await this.setStateAsync({
      ticketPriority: event.value,
    });
    await this.updateTicket();
    await this.getHistory(alertTicketId);
  };

  handleLockClick = async (alertTicketId, lockStatus) => {
    var lock_status = "";
    if (lockStatus === "Open") {
      lock_status = "C";
    }
    if (lockStatus === "Close") {
      lock_status = "O";
    }
    await this.setStateAsync({ lockStatus: lock_status });
    await this.setStateAsync({ alertTicketId: alertTicketId });
    await this.updateTicket();
  };

  closeAlertStatusUpdateModal = async () => {
    this.setState({
      isAlertStatusUpdateModal: false,
    });
  };

  openAlertStatusUpdateModal = async (alertTicketId, ticketStatus) => {
    await this.setStateAsync({ statusDataFetched: false });
    this.setState({
      isAlertStatusUpdateModal: true,
      alertTicketId: alertTicketId,
      status: ticketStatus,
    });
    await this.getHistory(alertTicketId);
    var statusData = "";
    var wip = [];
    for (const data of this.state.history) {
      if (data.changeValue === "Pending Close") {
        statusData = data.changeCategory;
        break;
      } else if (data.changeValue === "In Progress") {
        statusData = data.changeCategory.split(", ");
        for (const val of statusData) {
          wip.push({
            label: val,
            value: val,
          });
        }
        break;
      }
    }
    await this.setStateAsync({
      statusData: statusData,
      wip: wip,
      statusDataFetched: true,
    });
  };

  toggleOptions = (key) => {
    const selectedOption = key;
    if (selectedOption === "priority") {
      this.setStateAsync({ collapsePriority: !this.state.collapsePriority });
    } else if (selectedOption === "clientApproval") {
      this.setStateAsync({ collapseApproval: !this.state.collapseApproval });
    } else if (selectedOption === "wipStatus") {
      this.setStateAsync({ collapseWIP: !this.state.collapseWIP });
    } else if (selectedOption === "alertStatus") {
      this.setStateAsync({ collapseStatus: !this.state.collapseStatus });
    } else if (selectedOption === "ticketId") {
      this.setStateAsync({ collapseTicketId: !this.state.collapseTicketId });
    }
  };

  clearAllticketsFilters = async () => {
    await this.props.clearAllticketsFilters();
    await this.setStateAsync({
      collapsePriority: false,
      collapseApproval: false,
      collapseWIP: false,
      collapseStatus: false,
      collapseTicketId: false,
    });
    await this.props.reloadTicketsData();
  };

  render() {
    const filteredData = this.state.showSelectedFilters.filter(
      (item) => item.isChecked
    );
    return (
      <>
        <Row
          style={{ padding: "15px" }}
          className="sram-table-wrapper-ticketsView-height"
        >
          {/* <Col sm={12} md={12} lg={12}>
            <Row> */}
          <Col
            sm={12}
            md={3}
            lg={2}
            className="pad-right-0 pad-left-0"
            style={{ borderRight: "2px solid #ddd" }}
          >
            <div className="todo-widget sram-todo-widget">
              <PerfectScrollbar className="sram-table-wrapper-ticket-filters">
                <ListGroup
                  className="list-task todo-list list-group m-b-0"
                  data-role="tasklist"
                >
                  {/* <Col sm={12} md={12} lg={12}> */}
                  <ListGroupItem
                    className={
                      "list-group-item todo-item sram-todo-item border-0"
                    }
                  >
                    <span className="sram-text-font">FilterBy:</span>
                    {filteredData && filteredData.length > 0 ? (
                      <span
                        className="sram-text-font sram-alert-clear mr-3 float-right"
                        onClick={() => this.clearAllticketsFilters()}
                      >
                        <u>Clear All</u>
                      </span>
                    ) : null}
                  </ListGroupItem>

                  {this.state.showSelectedFilters &&
                    this.state.showSelectedFilters.map((obj, i) => {
                      return obj.isChecked === true ? (
                        <ListGroupItem
                          className={
                            "list-group-item todo-item sram-todo-item  sram-text-font border-0 sram-font-weight-500"
                          }
                        >
                          <>
                            {obj.content}{" "}
                            <i
                              className="fa fa-times mr-3 sram-title-cursor"
                              aria-hidden="true"
                              onClick={() => this.handleRemoveClick(obj)}
                            ></i>
                          </>
                        </ListGroupItem>
                      ) : null;
                    })}
                  <div
                    className="sram-tickets-filters sram-title-cursor sram-text-font pb-1"
                    onClick={() => this.toggleOptions("priority")}
                  >
                    <b>Priority</b>
                    <span className="mr-3 float-right">
                      {this.state.collapsePriority === false ? (
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                  <Collapse isOpen={this.state.collapsePriority}>
                    {this.props.todos.map((todo, i) => {
                      return (
                        <ListGroupItem
                          className={
                            "list-group-item todo-item sram-todo-item border-0"
                          }
                          key={i}
                        >
                          <div className="sram-custom-checkbox-pad ">
                            <input
                              type="checkbox"
                              value={todo.value}
                              id={todo.id}
                              checked={todo.isChecked}
                              onChange={this.checkTicket}
                              className="form-check-input"
                              data-toggle="checkbox"
                            />
                            <label
                              className="sram-custom-control-label todo-label"
                              htmlFor={todo.id}
                            >
                              <span className="todo-desc sram-text-font">
                                {todo.content}
                              </span>
                            </label>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </Collapse>

                  <div
                    className="sram-tickets-filters-1 sram-title-cursor sram-text-font pb-1"
                    onClick={() => this.toggleOptions("clientApproval")}
                  >
                    <b>Client Notification</b>
                    <span className="mr-3 float-right">
                      {this.state.collapseApproval === false ? (
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                  <Collapse isOpen={this.state.collapseApproval}>
                    {this.props.todos1.map((todo, i) => {
                      return (
                        <ListGroupItem
                          className={
                            "list-group-item todo-item sram-todo-item border-0"
                          }
                          key={i}
                        >
                          <div className=" sram-custom-checkbox-pad">
                            <input
                              type="checkbox"
                              value={todo.value}
                              id={todo.id}
                              checked={todo.isChecked}
                              onChange={this.checkTicket}
                              className="form-check-input"
                              data-toggle="checkbox"
                            />
                            <label
                              className="sram-custom-control-label todo-label"
                              htmlFor={todo.id}
                            >
                              <span className="todo-desc sram-text-font">
                                {todo.content}
                              </span>
                            </label>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </Collapse>

                  <div
                    className="sram-tickets-filters-1 sram-title-cursor sram-text-font pb-1"
                    onClick={() => this.toggleOptions("wipStatus")}
                  >
                    <b>WIP</b>
                    <span className="mr-3 float-right">
                      {this.state.collapseWIP === false ? (
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                  <Collapse isOpen={this.state.collapseWIP}>
                    {this.props.todos2.map((todo, i) => {
                      return (
                        <ListGroupItem
                          className={
                            "list-group-item todo-item sram-todo-item border-0"
                          }
                          key={i}
                        >
                          <div className=" sram-custom-checkbox-pad">
                            <input
                              type="checkbox"
                              value={todo.value}
                              id={todo.id}
                              checked={todo.isChecked}
                              onChange={this.checkTicket}
                              className="form-check-input"
                              data-toggle="checkbox"
                            />
                            <label
                              className="sram-custom-control-label todo-label"
                              htmlFor={todo.id}
                            >
                              <span className="todo-desc sram-text-font">
                                {todo.content}
                              </span>
                            </label>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </Collapse>

                  <div
                    className="sram-tickets-filters-1 sram-title-cursor sram-text-font pb-1"
                    onClick={() => this.toggleOptions("alertStatus")}
                  >
                    <b>Alert Status</b>
                    <span className="mr-3 float-right">
                      {this.state.collapseStatus === false ? (
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>
                  <Collapse isOpen={this.state.collapseStatus}>
                    {this.props.todos3.map((todo, i) => {
                      return (
                        <ListGroupItem
                          className={
                            "list-group-item todo-item sram-todo-item border-0"
                          }
                          key={i}
                        >
                          <div className=" sram-custom-checkbox-pad">
                            <input
                              type="checkbox"
                              value={todo.value}
                              id={todo.id}
                              checked={todo.isChecked}
                              onChange={this.checkTicket}
                              className="form-check-input"
                              data-toggle="checkbox"
                            />
                            <label
                              className="sram-custom-control-label todo-label"
                              htmlFor={todo.id}
                            >
                              <span className="todo-desc sram-text-font">
                                {todo.content}
                              </span>
                            </label>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </Collapse>

                  <div
                    className="sram-tickets-filters-1 sram-title-cursor sram-text-font pb-1"
                    onClick={() => this.toggleOptions("ticketId")}
                  >
                    <b>Ticket ID</b>
                    <span className="mr-3 float-right">
                      {this.state.collapseTicketId === false ? (
                        <i className="fa fa-plus" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-minus" aria-hidden="true"></i>
                      )}
                    </span>
                  </div>

                  <Collapse isOpen={this.state.collapseTicketId}>
                    {this.props.todos4.map((todo, i) => {
                      return (
                        <div className={"pt-2 mr-2"} key={i}>
                          <Select
                            placeholder="Search Tickets"
                            options={this.props.ticketsList}
                            getOptionLabel={(option) => option?.ticketNumber}
                            getOptionValue={(option) => option?.ticketNumber}
                            value={
                              this.props.ticketsList &&
                              this.props.ticketsList?.filter(function (option) {
                                return option.ticketNumber === todo.value;
                              })
                            }
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: "#ced4da",
                                primary: "#ced4da",
                              },
                            })}
                            styles={{
                              option: (base) => ({
                                ...base,
                                color: "#000000",
                              }),
                            }}
                            isClearable
                            onChange={(e) => this.handleSearchTickets(e)}
                          ></Select>
                        </div>
                      );
                    })}
                  </Collapse>
                </ListGroup>
              </PerfectScrollbar>
            </div>
          </Col>

          <Col sm={12} md={9} lg={10} className="pad-left-0 pad-right-0">
            <PerfectScrollbar className="sram-table-wrapper-ticketsView">
              <TicketsView
                isTicketsDataFetched={this.props.isTicketsDataFetched}
                tickets={this.props.tickets}
                ticketsList={this.props.ticketsList}
                profile={this.props.profile}
                assignId={this.props.assignId}
                getAlertTickets={this.props.getAlertTickets}
                ticketDataFetch={this.props.ticketDataFetch}
                // checkStatus = {this.props.checkStatus}
                // checkPriority = {this.props.checkPriority}
              />
            </PerfectScrollbar>
          </Col>
          {/* </Row>
          </Col> */}
        </Row>
      </>
    );
  }
}

export default Tickets;
