export const CurrentMessageStatusResProps = {
  head: {
    title: {},
  },
  body: {
    content: {
      currentMessageStatus: "Current Message Status",
    },
    form: {
      site: {
        label: "Site",
      },
      dispenser: {
        label: "Dispenser",
      },
      event: {
        label: "Event",
      },
      pumpErrorLog: {
        label: "Pump Error Log",
      },
      meterData: {
        label: "Meter Data",
      },
      configStatus: {
        label: "Config Status",
      },
      performance: {
        label: "Performance",
      },
    },
  },
};

export const CurrentMessageSizeReportResProps = {
  head: {
    title: {
      date: {
        label: "Date",
      },
      output: {
        label: "Output",
      },
      site: {
        label: "Site",
      },
      dispenser: {
        label: "Dispenser",
      },
      imei: {
        label: "IMEI Number",
      },
      topic: {
        label: "Topic",
      },
      size: {
        label: "Size (KB)",
      },
      sizevalues: {
        label: "Size Values",
      },
      total: {
        label: "Total Size",
      },
      topicsize: {
        label: "Topic Size",
      },
      both: {
        label: "Both",
      },
    },
  },
  body: {
    content: {
      CurrentMessageSizeReport: "Message Size Report",
    },
    form: {
      html: {
        label: "HTML",
      },
      csv: {
        label: "CSV",
      },
      site: {
        label: "Site",
      },
      dispenser: {
        label: "Dispenser",
      },
      topic: {
        label: "Topic",
      },
      size: {
        label: "Size",
      },
    },
  },
};

export const CoreReportResProps = {
  head: {
    title: {
      user: {
        label: "Select User",
      },
      organizationName: {
        label: "Organization Name",
      },
      selectDate: {
        label: "Select Date",
      },
      selectDivision: {
        label: "Select Division",
      },
      selectSubDivision: {
        label: "Select Sub Division",
      },
      selectCompanies: {
        label: "Select Company",
      },
      fromDate: {
        label: "From Date",
      },
      toDate: {
        label: "To Date",
      },
      site: {
        label: "Select Sites",
      },
      siteName: {
        label: "Site Name",
      },
      fileType: {
        label: "File Type",
      },
      scheduler: {
        label: "Schedule",
      },
    },
  },
  body: {
    content: {
      coreReports: "Reports",
      schedule: "Schedule",
      reportsHistory: "Reports History",
      scheduleHistory: "Schedule History",
      deleteScheduleReport: "Delete Schedule Report",
      noReportsFound: "No Reports Found",
    },
    form: {
      executionDate: {
        label: "Execution Date",
      },
      creationDate: {
        label: "Creation Date",
      },
      companyName: {
        label: "Company Name",
      },
      reportName: {
        label: "Report Name",
      },
      fileType: {
        label: "File Type",
      },
      sites: {
        label: "Sites",
      },
      organizationName: {
        label: "Organization Name",
      },
      scheduleExpression: {
        label: "Schedule Expression",
      },
      viewReport: {
        label: "View Report",
      },
      createdBy: {
        label: "Created By",
      },
      submit: {
        label: "Submit",
      },
      runReport: {
        label: "Run Report",
      },
      scheduleReport: {
        label: "Schedule Report",
      },
      cancel: {
        label: "Cancel",
      },
      date: {
        label: "Date",
      },
      fromDate: {
        label: "From Date",
      },
      toDate: {
        label: "To Date",
      },
      dateRange: {
        label: "Date Range",
      },
    },
    notification: {
      noReportsFound: "No reports found.",
      noSchedulesFound: "No schedule reports found.",
      required: {
        users: "User is required.",
        sites: "Site is required.",
        dates: "From Date and To Date is required.",
        selectDate: "Date is required.",
        fromDate: "From Date is required.",
        toDate: "To Date is required.",
        reportType: "Report Type is required.",
        scheduler: "Scheduler is required.",
      },
      confirmation: {
        disableScheduleReport:
          "Are you sure you want to disable the scheduled report ?",
        enableScheduleReport:
          "Are you sure you want to enable the scheduled report ?",
        deleteScheduleReport:
          "Are you sure you want to delete the scheduled report ?",
      },
    },
  },
};
