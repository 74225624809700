import React, { Component } from "react";


class ErrorCodes extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  render() {
    return (
      <>
        
      </>
    );
  }
}
export default ErrorCodes;
