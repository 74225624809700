import React, { Component } from "react";
import axios from "axios";
import { Card, CardTitle, CardBody, Table } from "reactstrap";
import WithLoading from "../../core/helpers/with-loading";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import PageUtils from "../../core/helpers/page-utils";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
// import PageDisplay from "../../core/helpers/page-display";
import { CurrentMessageStatusResProps } from "../messages/current-message-status-properties";
import { ReportsURLProps } from "../settings/report-urls";
import { messageLogs } from "../actions/current-message-status-actions";
import CurrentMessageStatusUtils from "../helpers/current-message-status-utils";

class CurrentMessageStatus extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcViewMesageLog = "AT-DVC-DVIW";
  _pcMessageLogView = "CP-SIT-SVIW";
  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      partPrivileges: this.initPartPrivileges(),
      messageLogs: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
    window.appHistory = this.props.history;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      PageUtils.scrollToTop();
      await this.loadPageData();
    }
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ messageLogs: null });
    await this.checkPartPrivilege();
    await this.setPartPrivileges();
    const queryData = "sortField=siteName&sortOrder=1";

    await messageLogs(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ messageLogs: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  initPartPrivileges = () => {
    return {
      [this._pcViewMesageLog]: false,
      [this._pcMessageLogView]: false,
    };
  };

  checkPartPrivilege = async () => {
    const partCode = [this._pcViewMesageLog, this._pcMessageLogView];
    const partPrivilege = await getPagePartsPrivilege(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(ReportsURLProps.pageNotFound);
    }
  };

  setPartPrivileges = async () => {
    const partCodes = [this._pcViewMesageLog, this._pcMessageLogView];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  getEvent(obj) {
    return (
      <span>
        {obj.deviceTimestamp}, {obj.messageStatus} ({obj.statusMessage})
      </span>
    );
  }

  getClass(gap) {
    if (gap > 1) {
      const name = "sram-site-door-status-open sram-text-font";
      return name;
    } else {
      const name = "sram-text-font";
      return name;
    }
  }

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <Card className="sram-page-list">
              <CardTitle>
                <span>
                  {
                    CurrentMessageStatusResProps.body.content
                      .currentMessageStatus
                  }
                </span>
              </CardTitle>
              <CardBody>
                <div className="sram-list-main">
                  <Table responsive hover size="sm" className="sram-table-main">
                    <thead>
                      <tr>
                        <th>
                          {CurrentMessageStatusResProps.body.form.site.label}
                        </th>
                        <th>
                          {
                            CurrentMessageStatusResProps.body.form.dispenser
                              .label
                          }
                        </th>
                        <th>
                          {CurrentMessageStatusResProps.body.form.event.label}
                        </th>
                        <th>
                          {
                            CurrentMessageStatusResProps.body.form.pumpErrorLog
                              .label
                          }
                        </th>
                        <th>
                          {
                            CurrentMessageStatusResProps.body.form.meterData
                              .label
                          }
                        </th>
                        <th>
                          {
                            CurrentMessageStatusResProps.body.form.configStatus
                              .label
                          }
                        </th>
                        <th>
                          {
                            CurrentMessageStatusResProps.body.form.performance
                              .label
                          }
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.messageLogs &&
                      this.state.messageLogs.length > 0
                        ? this.state.messageLogs.map((obj, index) => {
                            const eventGap = CurrentMessageStatusUtils.getDays(obj,obj.event.deviceTimestamp);
                            const pumpErrorLogGap = CurrentMessageStatusUtils.getDays(obj,obj.pumpErrorLog.deviceTimestamp);
                           const meterGap = CurrentMessageStatusUtils.getDays(obj,obj.meterData.deviceTimestamp);
                            const configGap = CurrentMessageStatusUtils.getDays(obj,obj.configStatus.deviceTimestamp);
                            const performanceGap = CurrentMessageStatusUtils.getDays(obj,obj.performance.deviceTimestamp);
                            const maxDate = CurrentMessageStatusUtils.getMaxdate(obj)
                            const maxGap = CurrentMessageStatusUtils.getDays(obj,maxDate);
                            
                            return (
                              <tr key={index}>
                                <td className="sram-text-font">{obj.siteName}</td>
                                <td className="sram-text-font">
                                  Dispenser{" "}
                                  {obj.sideANumber + "/" + obj.sideBNumber}{" "}
                                  <div className={this.getClass(maxGap)}>{maxDate}</div>
                                </td>

                                <td>
                                  {Object.keys(obj.event).length > 0 ? (
                                    <div>
                                      <div className={this.getClass(eventGap)} data-toggle="tooltip" data-placement="top" title="Event" style={{cursor:'pointer'}}>{obj.event.deviceTimestamp}</div>
                                      <div className="sram-text-font">
                                        {obj.event.messageStatus +
                                          "  (" +
                                          obj.event.statusMessage +
                                          ")"}
                                      </div>
                                    </div>
                                  ) : null}
                                </td>
                                <td>
                                  {Object.keys(obj.pumpErrorLog).length > 0 ? (
                                    <div>
                                      <div className={this.getClass(pumpErrorLogGap)} data-toggle="tooltip" data-placement="top" title="Pump Error Log" style={{cursor:'pointer'}}>
                                        {obj.pumpErrorLog.deviceTimestamp}
                                      </div>
                                      <div className="sram-text-font">
                                        {obj.pumpErrorLog.messageStatus +
                                          "  (" +
                                          obj.pumpErrorLog.statusMessage +
                                          ")"}
                                      </div>
                                    </div>
                                  ) : null}
                                </td>
                                <td>
                                  {Object.keys(obj.meterData).length > 0 ? (
                                    <div>
                                      <div className={this.getClass(meterGap)} data-toggle="tooltip" data-placement="top" title="Meter Data" style={{cursor:'pointer'}}>{obj.meterData.deviceTimestamp}</div>
                                      <div className="sram-text-font">
                                        {obj.meterData.messageStatus +
                                          "  (" +
                                          obj.meterData.statusMessage +
                                          ")"}
                                      </div>
                                    </div>
                                  ) : null}
                                </td>
                                <td>
                                  {Object.keys(obj.configStatus).length > 0 ? (
                                    <div>
                                      <div className={this.getClass(configGap)} data-toggle="tooltip" data-placement="top" title="Config Status" style={{cursor:'pointer'}}>
                                        {obj.configStatus.deviceTimestamp}
                                      </div>
                                      <div className="sram-text-font">
                                        {obj.configStatus.messageStatus +
                                          "  (" +
                                          obj.configStatus.statusMessage +
                                          ")"}
                                      </div>
                                    </div>
                                  ) : null}
                                </td>
                                <td>
                                  {Object.keys(obj.performance).length > 0 ? (
                                    <div>
                                      <div className={this.getClass(performanceGap)} data-toggle="tooltip" data-placement="top" title="Performance" style={{cursor:'pointer'}}>
                                        {obj.performance.deviceTimestamp}
                                      </div>
                                      <div className="sram-text-font">
                                        {obj.performance.messageStatus +
                                          "  (" +
                                          obj.performance.statusMessage +
                                          ")"}
                                      </div>
                                    </div>
                                  ) : null}
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </div>
        </WithLoading>
      </>
    );
  }
}
export default CurrentMessageStatus;
