import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import WithLoading from "../../core/helpers/with-loading";
import ConfirmModal from "../../core/helpers/confirm-modal";
import GeoAddressSearchField from "../../core/helpers/geo-address-search-field";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import { SetupURLProps } from "../settings/setup-urls";
import { SetupValidations } from "..//settings/setup-validations";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { AppDataProps } from "../../core/settings/app-data";
import { ServiceProviderMsgResProps } from "../messages/service-provider-properties";
import { manageError } from "../../core/actions/common-actions";
import { isPagePartPrivileged } from "../../core/actions/identity-actions";
import {
  getServiceProviderOrganizations,
  getSiteOwnerCompanies,
} from "../actions/organization-actions";
import { addSite } from "../actions/service-provider-actions";
import { getPlaceTimezone } from "../../core/actions/geo-acitons";

class serviceProviderSiteAdd extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _helpers = null;
  _pcServiceProviderSiteAdd = "AS-SPV-STAD";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isOpenConfirmModal: false,
      serviceProviderOrganizations: null,
      siteOwnerCompanies: null,
      formInputFields: this.initFormInputFields(),
      tempSiteObj: null,
      isAddressFieldsPopulated: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ serviceProviderOrganizations: null });
    await this.setStateAsync({ siteOwnerCompanies: null });
    await this.checkPartPrivilege();
    await this.loadServiceProviderOrganizations();
    await this.loadSiteOwnerCompanies();
    await this.setStateAsync({ isPageDataFetched: true });
  };

  loadServiceProviderOrganizations = async () => {
    const queryData = "";

    await getServiceProviderOrganizations(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({
              serviceProviderOrganizations: res.data.records,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  loadSiteOwnerCompanies = async () => {
    const queryData = "";

    await getSiteOwnerCompanies(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({
              siteOwnerCompanies: res.data.records,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcServiceProviderSiteAdd}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  initFormInputFields = () => {
    return {
      serviceProviderOrganizationId: "",
      siteName: "",
      siteNumber: "",
      addressCode: "",
      customerNumber: "",
      addressAutocomplete: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      sitePhone: "",
      locationLatitude: "",
      locationLongitude: "",
      siteTimezone: "",
      siteNote: "",
      siteOwnerOrganizationId: "",
    };
  };

  populateAddressFields = async (place, formik) => {
    if (place && formik) {
      const address = PageUtils.getGeoAddressFieldsData(place);
      if (address) {
        formik.setFieldValue("streetAddress1", address.streetAddress1);
        formik.setFieldValue("streetAddress2", address.streetAddress2);
        formik.setFieldValue("city", address.city);
        formik.setFieldValue("state", address.state);
        formik.setFieldValue("country", address.country);
        formik.setFieldValue("zipCode", address.zipCode);
        formik.setFieldValue("locationLatitude", address.locationLatitude);
        formik.setFieldValue("locationLongitude", address.locationLongitude);
      }
      this.setStateAsync({ isAddressFieldsPopulated: true });
    }
  };

  formValidationSchema = () => {
    const schema = {
      serviceProviderOrganizationId: Yup.string().required(
        ServiceProviderMsgResProps.body.notification.error.companyDivision.empty
      ),
      siteName: Yup.string()
        .trim()
        .required(
          ServiceProviderMsgResProps.body.notification.error.siteName.empty
        )
        .matches(
          SetupValidations.sites.siteName,
          ServiceProviderMsgResProps.body.notification.error.siteName.minLength
        ),
      siteNumber: Yup.string()
        .required(
          ServiceProviderMsgResProps.body.notification.error.siteId.empty
        )
        .matches(
          SetupValidations.sites.siteId,
          ServiceProviderMsgResProps.body.notification.error.siteId.invalid
        ),
      siteOwnerOrganizationId: Yup.string().required(
        ServiceProviderMsgResProps.body.notification.error
          .siteOwnerOrganizationName.empty
      ),
      addressCode: Yup.string().required(
        ServiceProviderMsgResProps.body.notification.error.addressCode.empty
      ),
      // .matches(
      //   SetupValidations.sites.siteId,
      //   ServiceProviderMsgResProps.body.notification.error.addressCode
      //     .invalid
      // ),
      customerNumber: Yup.string().required(
        ServiceProviderMsgResProps.body.notification.error.customerNumber.empty
      ),
      // .matches(
      //   SetupValidations.sites.siteId,
      //   ServiceProviderMsgResProps.body.notification.error.customerNumber
      //     .invalid
      // ),
      city: Yup.string()
        .trim()
        .required(
          ServiceProviderMsgResProps.body.notification.error.city.empty
        ),
      state: Yup.string()
        .trim()
        .required(
          ServiceProviderMsgResProps.body.notification.error.state.empty
        ),
      country: Yup.string()
        .trim()
        .required(
          ServiceProviderMsgResProps.body.notification.error.country.empty
        ),
      zipCode: Yup.string()
        .trim()
        .required(
          ServiceProviderMsgResProps.body.notification.error.zipCode.empty
        )
        .matches(
          SetupValidations.sites.zipCode,
          ServiceProviderMsgResProps.body.notification.error.zipCode.invalid
        ),
      locationLatitude: Yup.string()
        .trim()
        .required(
          ServiceProviderMsgResProps.body.notification.error.locationLatitude
            .empty
        )
        .matches(
          SetupValidations.sites.locationLatitude,
          ServiceProviderMsgResProps.body.notification.error.locationLatitude
            .invalid
        ),
      locationLongitude: Yup.string()
        .trim()
        .required(
          ServiceProviderMsgResProps.body.notification.error.locationLongitude
            .empty
        )
        .matches(
          SetupValidations.sites.locationLongitude,
          ServiceProviderMsgResProps.body.notification.error.locationLongitude
            .invalid
        ),
      sitePhone: Yup.string().matches(
        SetupValidations.sites.sitePhone,
        ServiceProviderMsgResProps.body.notification.error.sitePhone.invalid
      ),
    };
    return schema;
  };

  handleConfirmation = async (values, helpers) => {
    this._helpers = helpers;
    const siteObj = {
      siteName: values.siteName,
      siteNumber: values.siteNumber,
      addressCode: values.addressCode,
      customerNumber: values.customerNumber,
      streetAddress1: values.streetAddress1,
      streetAddress2: values.streetAddress2,
      city: values.city,
      state: values.state,
      country: values.country,
      zipCode: values.zipCode,
      sitePhone: values.sitePhone,
      locationLatitude: values.locationLatitude,
      locationLongitude: values.locationLongitude,
      siteNote: values.siteNote,
      serviceProviderOrganizationId: values.serviceProviderOrganizationId,
      siteOwnerOrganizationId: values.siteOwnerOrganizationId
        ? values.siteOwnerOrganizationId
        : null,
    };
    await this.setStateAsync({ tempSiteObj: siteObj });
    this.openConfirmModal();
  };

  handleSubmit = async () => {
    const siteObj = this.state.tempSiteObj;
    const helpers = this._helpers;
    this.closeConfirmModal();
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }
    let timezone = null;
    if (
      siteObj.locationLatitude.toString().length > 0 &&
      siteObj.locationLongitude.toString().length > 0
    ) {
      timezone = await getPlaceTimezone(
        siteObj.locationLatitude,
        siteObj.locationLongitude,
        this._cancelToken
      );
    }
    if (timezone && timezone.timeZoneId) {
      siteObj.siteTimezone = timezone.timeZoneId;
    } else {
      siteObj.siteTimezone = null;
    }
    await addSite(siteObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          await this.loadServiceProviderOrganizations();
          await this.loadSiteOwnerCompanies();
          const statusMsg = [
            "S",
            ServiceProviderMsgResProps.body.notification.success.addSite,
          ];
          if (this._isMounted) {
            helpers.resetForm();
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          ServiceProviderMsgResProps.body.notification.error.message,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);

          if (err.data && err.data.errors && err.data.errors.length > 0) {
            const errors = err.data.errors;
            errors.forEach((error) => {
              if (error.element && error.message && error.location === "body") {
                helpers.setFieldError(error.element, error.message);
              }
            });
          }
          PageUtils.scrollToTop();
        }
      });
    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  updateOrganisation = async (formik, organizationId) => {
    await formik.setFieldValue("siteOwnerOrganizationId", organizationId);
    const filterCustomerNumber = [];
    await this.state.siteOwnerCompanies?.forEach((obj) => {
      if (obj.organizationId === parseInt(organizationId)) {
        return filterCustomerNumber.push(obj);
      }
    });
    await formik.setFieldValue(
      "customerNumber",
      filterCustomerNumber[0]?.customerNumber
        ? filterCustomerNumber[0]?.customerNumber
        : ""
    );
  };

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <Card className="sram-page-form">
              <CardTitle>
                <span>
                  {
                    ServiceProviderMsgResProps.body.content
                      .serviceProviderAddSite
                  }
                </span>
                <span className="float-right">
                  {PageDisplay.showGoBackLink(
                    "L",
                    SetupURLProps.serviceProviders.listSite,
                    this.props.history
                  )}
                </span>
              </CardTitle>
              <CardBody>
                <Formik
                  initialValues={this.state.formInputFields}
                  validationSchema={Yup.object().shape(
                    this.formValidationSchema()
                  )}
                  onSubmit={(values, helpers) =>
                    this.handleConfirmation(values, helpers)
                  }
                >
                  {(formik) => (
                    <Form id="siteOwnerSiteForm" {...formEvent}>
                      <div className="sram-form-main">
                        <div>
                          {formik.status &&
                          Array.isArray(formik.status) &&
                          formik.status.length === 2 &&
                          (formik.status[0] === "E" ||
                            formik.status[0] === "S") ? (
                            <Row>
                              <Col xs={12}>
                                {formik.status[0] === "E"
                                  ? PageDisplay.showErrorNotification(
                                      formik.status[1]
                                    )
                                  : PageDisplay.showSuccessNotification(
                                      formik.status[1]
                                    )}
                              </Col>
                            </Row>
                          ) : null}
                        </div>
                        <div>
                          <Row form>
                            <Col sm={6} md={6} lg={6}>
                              <FormGroup>
                                <Label for="serviceProviderOrganizationId">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .companyDivision.label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="serviceProviderOrganizationId"
                                  as="select"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.serviceProviderOrganizationId,
                                    formik.touched.serviceProviderOrganizationId
                                  )}
                                  value={
                                    formik.values.serviceProviderOrganizationId
                                  }
                                >
                                  <option value=""></option>
                                  {this.state.serviceProviderOrganizations &&
                                  this.state.serviceProviderOrganizations
                                    .length > 0
                                    ? this.state.serviceProviderOrganizations.map(
                                        (owner, index) => {
                                          return (
                                            <option
                                              value={owner.organizationId}
                                              key={`key-soso-option-${index}`}
                                            >
                                              {owner.organizationTreepath}
                                            </option>
                                          );
                                        }
                                      )
                                    : null}
                                </Field>
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "serviceProviderOrganizationId"
                                )}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="siteNumber">
                                  {
                                    ServiceProviderMsgResProps.body.form.siteId
                                      .label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="siteNumber"
                                  type="text"
                                  maxLength="10"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.siteNumber,
                                    formik.touched.siteNumber
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "siteNumber"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="siteName">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .siteName.label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="siteName"
                                  type="text"
                                  maxLength="50"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.siteName,
                                    formik.touched.siteName
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "siteName"
                                )}
                              </FormGroup>
                            </Col>

                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="siteOwnerOrganizationId">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .siteOwner.label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="siteOwnerOrganizationId"
                                  type="text"
                                  as="select"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.siteOwnerOrganizationId,
                                    formik.touched.siteOwnerOrganizationId
                                  )}
                                  value={formik.values.siteOwnerOrganizationId}
                                  onChange={(e) =>
                                    this.updateOrganisation(
                                      formik,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">
                                    {/* {
                                      ServiceProviderMsgResProps.body.form
                                        .serviceProvider.optionNone
                                    } */}
                                  </option>
                                  {this.state.siteOwnerCompanies &&
                                  this.state.siteOwnerCompanies.length > 0
                                    ? this.state.siteOwnerCompanies.map(
                                        (provider, index) => {
                                          return (
                                            <option
                                              value={provider.organizationId}
                                              key={`key-sosp-option-${index}`}
                                            >
                                              {provider.organizationName}
                                            </option>
                                          );
                                        }
                                      )
                                    : null}
                                </Field>
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "siteOwnerOrganizationId"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="addressCode">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .addressCode.label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="addressCode"
                                  type="text"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.addressCode,
                                    formik.touched.addressCode
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "addressCode"
                                )}
                              </FormGroup>
                            </Col>

                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="customerNumber">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .customerNumber.label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="customerNumber"
                                  type="text"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.customerNumber,
                                    formik.touched.customerNumber
                                  )}
                                  value={formik.values.customerNumber}
                                  disabled={
                                    formik.values.customerNumber &&
                                    formik.values.customerNumber.length > 6
                                  }
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "customerNumber"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={12} lg={12} className="pt-3">
                              <GeoAddressSearchField
                                callback={this.populateAddressFields}
                                formik={formik}
                              />
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="streetAddress1">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .streetAddress1.label
                                  }
                                </Label>
                                <Field
                                  name="streetAddress1"
                                  type="text"
                                  maxLength="100"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.streetAddress1,
                                    formik.touched.streetAddress1
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "streetAddress1"
                                )}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="streetAddress2">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .streetAddress2.label
                                  }
                                </Label>
                                <Field
                                  name="streetAddress2"
                                  type="text"
                                  maxLength="100"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.streetAddress2,
                                    formik.touched.streetAddress2
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "streetAddress2"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="city">
                                  {
                                    ServiceProviderMsgResProps.body.form.city
                                      .label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="city"
                                  type="text"
                                  maxLength="50"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.city,
                                    formik.touched.city
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage("city")}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="state">
                                  {
                                    ServiceProviderMsgResProps.body.form.state
                                      .label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="state"
                                  as="select"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.state,
                                    formik.touched.state
                                  )}
                                >
                                  <option value=""></option>
                                  {AppDataProps.address.states &&
                                  AppDataProps.address.states.length > 0
                                    ? AppDataProps.address.states.map(
                                        (state, index) => {
                                          return (
                                            <option
                                              value={state}
                                              key={`key-addr-state-${index}`}
                                            >
                                              {state}
                                            </option>
                                          );
                                        }
                                      )
                                    : null}
                                </Field>
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "state"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="country">
                                  {
                                    ServiceProviderMsgResProps.body.form.country
                                      .label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="country"
                                  as="select"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.country,
                                    formik.touched.country
                                  )}
                                >
                                  <option value=""></option>
                                  {AppDataProps.address.countries &&
                                  AppDataProps.address.countries.length > 0
                                    ? AppDataProps.address.countries.map(
                                        (country, index) => {
                                          return (
                                            <option
                                              value={country}
                                              key={`key-addr-country-${index}`}
                                            >
                                              {country}
                                            </option>
                                          );
                                        }
                                      )
                                    : null}
                                </Field>
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "country"
                                )}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="zipCode">
                                  {
                                    ServiceProviderMsgResProps.body.form.zipCode
                                      .label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="zipCode"
                                  type="text"
                                  maxLength="5"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.zipCode,
                                    formik.touched.zipCode
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "zipCode"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="locationLatitude">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .locationLatitude.label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="locationLatitude"
                                  type="text"
                                  maxLength="20"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.locationLatitude,
                                    formik.touched.locationLatitude
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "locationLatitude"
                                )}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="locationLongitude">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .locationLongitude.label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="locationLongitude"
                                  type="text"
                                  maxLength="20"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.locationLongitude,
                                    formik.touched.locationLongitude
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "locationLongitude"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="sitePhone">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .sitePhone.label
                                  }
                                </Label>
                                <Field
                                  name="sitePhone"
                                  type="text"
                                  maxLength="10"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.sitePhone,
                                    formik.touched.sitePhone
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "sitePhone"
                                )}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="siteNote">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .siteNote.label
                                  }
                                </Label>
                                <Field
                                  name="siteNote"
                                  as="textarea"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.siteNote,
                                    formik.touched.siteNote
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "siteNote"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>
                      <div className="sram-form-foot">
                        {PageDisplay.showSaveButton(formik.isSubmitting)}
                        {PageDisplay.showCancelButton(this.props.history)}
                      </div>
                      <FormFieldFocusError />
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
            {this.state.tempSiteObj ? (
              <ConfirmModal
                isOpenConfirmModal={this.state.isOpenConfirmModal}
                closeConfirmModal={this.closeConfirmModal}
                handleSubmit={this.handleSubmit}
              >
                <div>
                  <div>
                    <Label>
                      {
                        ServiceProviderMsgResProps.body.notification
                          .confirmation.addSiteTitle
                      }
                    </Label>
                  </div>
                </div>
              </ConfirmModal>
            ) : null}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default serviceProviderSiteAdd;
