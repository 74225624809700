import axios from "axios";
import { includes } from "lodash";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";

const actionFileName = "user-actions.js";

// List Users
export function listUsers(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "";
    if (queryData) {
      queryParams = "?" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "users" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listUser()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Add User
export function addUser(userObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(AppConfigProps.serverRoutePrefix + "users", userObj, cancelToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">addUser()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Get User
export function getUser(userId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(AppConfigProps.serverRoutePrefix + "users/" + userId, cancelToken)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getUser()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Update User
export function editUser(userObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix + "users/" + userObj.userId,
        userObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">editUser()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Check Username or Email availability
export function checkAvailability(queryParam, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "users/availability" + queryParam,
        cancelToken
      )
      .then((res) => {
        if (res.data && res.data.result) {
          resolve(false);
        }
        resolve(true);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">checkAvailability()",
            err.message
          );
        } else {
          resolve(true);
        }
      });
  });
}

// List of Sites based on user's organization
export function listSites(organizationId, queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "";
    if (queryData) {
      queryParams = "?" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
        "organizations/" +
        organizationId +
        "/sites" +
        queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listSites()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// List of Sites assigned to user
export function getUserSites(userId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "users/" + userId + "/sites",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getUserSites()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Update User Sites
export function editUserSites(userSitesObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
        "users/" +
        userSitesObj.userId +
        "/sites",
        userSitesObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">editUserSites()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Search users
export function searchUsers(list, searchKeyword) {
  return new Promise(async function (resolve, reject) {
    if (
      list &&
      list.length > 0 &&
      searchKeyword &&
      searchKeyword.trim().length > 0
    ) {
      let searchRecords = list.filter((user) => {
        return (
          includes(
            user.email.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            user.firstName.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            user.lastName.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            user.username.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          )
        );
      });

      resolve(searchRecords);
    } else {
      resolve(list);
    }
  });
}

// Search User Site Access
export function searchUsersSites(list, searchKeyword) {
  return new Promise(async function (resolve, reject) {
    if (
      list &&
      list.length > 0 &&
      searchKeyword &&
      searchKeyword.trim().length > 0
    ) {
      let searchRecords = list.filter((user) => {
        return (
          includes(
            user.siteNumber.toString(),
            searchKeyword.trim().toString()
          ) ||
          includes(
            user.siteName.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          )
        );
      });

      resolve(searchRecords);
    } else {
      resolve(list);
    }
  });
}

// User Disable/Enable
export function userStatus(userObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "users/usersstatus",
        userObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">userStatus()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Delete User
export function deleteUser(userObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "users/usersdelete",
        userObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">deleteUser()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}