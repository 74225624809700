import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Input,
  Label,
} from "reactstrap";
import { AppMsgResProps } from "../../core/messages/app-properties";
import axios from "axios";
// import AppUtils from "../../core/helpers/app-utils";
import { AlertMsgResProps } from "../messages/alert-properties";

class DatePickerModal extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(new Date().setDate(new Date().getDate())),
      endDate: new Date(new Date().setDate(new Date().getDate())),
      isSubmitting: false,
      sd: "",
      ed: "",
      error: "",
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    // PageUtils.scrollToTop();
    window.appHistory = this.props.history;
    if (this.state.sd === "" && this.state.ed === "") {
      await this.setStateAsync({
        sd: this.props.filter ? this.props.filter?.startDate : "",
        ed: this.props.filter ? this.props.filter?.endDate : "",
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  handleSdChange = async (e) => {
    e.preventDefault();
    let sd = e.target.value;
    await this.setStateAsync({ sd: sd, error: "", isSubmitting: false });
  };

  handleEdChange = async (e) => {
    e.preventDefault();
    let ed = e.target.value;
    await this.setStateAsync({ ed: ed, error: "", isSubmitting: false });
  };

  handleSubmit = async () => {
    await this.setStateAsync({ isSubmitting: true });
    if (this.state.sd > this.state.ed) {
      await this.setStateAsync({
        error: "From Date should not be greater than To Date",
      });
    }
    if (this.state.sd <= this.state.ed && this.state.error === "") {
      this.props.getDates(this.state.sd, this.state.ed);
      this.props.closeDatePickerModal();
      await this.setStateAsync({ isSubmitting: false });
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isDatePickerModal}
        toggle={this.props.closeDatePickerModal}
        size="md"
        backdrop="static"
        centered={false}
        className="sram-modal-content sram-index"
      >
        <ModalHeader>Choose Dates</ModalHeader>
        <ModalBody className="mb-2">
          <Row>
            <Col sm={12} md={6} lg={6} className="mb-2">
              <Label className="pt-2">From: </Label>
              <Input
                type="date"
                defaultValue={this.props.filter.startDate}
                className={
                  this.state.error !== "" ||
                  ((this.state.sd === "" || this.state.sd === undefined) &&
                    this.state.isSubmitting === true)
                    ? "sram-ticket-view-email-validation form-control"
                    : "form-control"
                }
                onChange={this.handleSdChange}
              />
              {(this.state.sd === "" || this.state.sd === undefined) &&
              this.state.isSubmitting === true ? (
                <span
                  style={{ display: "block" }}
                  className="invalid-feedback mb-2"
                >
                  {AlertMsgResProps.body.notification.datePicker.fromDate}
                </span>
              ) : null}
            </Col>
            <Col sm={12} md={6} lg={6} className="mb-2">
              <Label className="pt-2">To: </Label>
              <Input
                type="date"
                defaultValue={this.props.filter.endDate}
                className={
                  this.state.error !== "" ||
                  ((this.state.ed === "" || this.state.ed === undefined) &&
                    this.state.isSubmitting === true)
                    ? "sram-ticket-view-email-validation form-control"
                    : "form-control"
                }
                onChange={this.handleEdChange}
              />
              {(this.state.ed === "" || this.state.ed === undefined) &&
              this.state.isSubmitting === true ? (
                <span
                  style={{ display: "block" }}
                  className="invalid-feedback mb-2"
                >
                  {AlertMsgResProps.body.notification.datePicker.toDate}
                </span>
              ) : null}
            </Col>
            <Col
              sm={12}
              md={12}
              lg={12}
              style={{ display: "block", textAlign: "center " }}
              className="invalid-feedback mb-2"
            >
              {this.state.error}
            </Col>
          </Row>
          <br />
        </ModalBody>
        <ModalFooter className="m-1 p-2">
          <Button
            type="button"
            className="sram-btn-blue mx-2"
            onClick={this.handleSubmit}
          >
            OK
          </Button>
          <Button
            type="button"
            className="sram-btn-white mx-2"
            onClick={this.props.closeDatePickerModal}
          >
            {AlertMsgResProps.footer.close.label}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DatePickerModal;
