import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import axios from "axios";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import WithLoading from "../../core/helpers/with-loading";
import ConfirmModal from "../../core/helpers/confirm-modal";
import GeoAddressSearchField from "../../core/helpers/geo-address-search-field";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import FormFieldFocusError from "../../core/helpers/form-field-focus-error";
import { SetupURLProps } from "../settings/setup-urls";
import { SetupValidations } from "..//settings/setup-validations";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { AppDataProps } from "../../core/settings/app-data";
import { ServiceProviderMsgResProps } from "../messages/service-provider-properties";
import { manageError } from "../../core/actions/common-actions";
import { isPagePartPrivileged } from "../../core/actions/identity-actions";
import { getServiceProviderOrganizations } from "../actions/organization-actions";
import { addDivision } from "../actions/service-provider-actions";

class ServiceProviderDivisionAdd extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _helpers = null;
  _pcServiceProviderDivisionAdd = "AS-SPV-DVAD";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      isOpenConfirmModal: false,
      organizations: null,
      formInputFields: this.initFormInputFields(),
      tempDivisionObj: null,
      isAddressFieldsPopulated: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ organizations: null });
    await this.checkPartPrivilege();
    await this.loadOrganizations();
    await this.setStateAsync({ isPageDataFetched: true });
  };

  loadOrganizations = async () => {
    const queryData = "";

    await getServiceProviderOrganizations(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ organizations: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcServiceProviderDivisionAdd}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  initFormInputFields = () => {
    return {
      parentId: "",
      organizationName: "",
      addressAutocomplete: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
      //locationLatitude: "",
      //locationLongitude: "",
    };
  };

  populateAddressFields = (place, formik) => {
    if (place && formik) {
      const address = PageUtils.getGeoAddressFieldsData(place);
      if (address) {
        formik.setFieldValue("streetAddress1", address.streetAddress1);
        formik.setFieldValue("streetAddress2", address.streetAddress2);
        formik.setFieldValue("city", address.city);
        formik.setFieldValue("state", address.state);
        formik.setFieldValue("country", address.country);
        formik.setFieldValue("zipCode", address.zipCode);
      }

      this.setStateAsync({ isAddressFieldsPopulated: true });
    }
  };

  formValidationSchema = () => {
    const schema = {
      parentId: Yup.string().required(
        ServiceProviderMsgResProps.body.notification.error.parentCompanyDivision
          .empty
      ),
      organizationName: Yup.string()
        .trim()
        .required(
          ServiceProviderMsgResProps.body.notification.error.divisionName.empty
        )
        .matches(
          SetupValidations.organizations.organizationName,
          ServiceProviderMsgResProps.body.notification.error.divisionName
            .minLength
        ),
      zipCode: Yup.string().matches(
        SetupValidations.organizations.zipCode,
        ServiceProviderMsgResProps.body.notification.error.zipCode.invalid
      ),
    };
    return schema;
  };

  handleConfirmation = async (values, helpers) => {
    this._helpers = helpers;
    const divisionObj = {
      organizationName: values.organizationName,
      businessName: null,
      streetAddress1: values.streetAddress1,
      streetAddress2: values.streetAddress2,
      city: values.city,
      state: values.state,
      country: values.country,
      zipCode: values.zipCode,
      locationLatitude: null, //values.locationLatitude,
      locationLongitude: null, //values.locationLongitude,
      rootId: null,
      parentId: values.parentId,
    };
    await this.setStateAsync({ tempDivisionObj: divisionObj });
    this.openConfirmModal();
  };

  handleSubmit = async () => {
    const divisionObj = this.state.tempDivisionObj;
    const helpers = this._helpers;
    this.closeConfirmModal();
    if (this._isMounted) {
      helpers.setSubmitting(true);
    }

    await addDivision(divisionObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          await this.loadOrganizations();
          const statusMsg = [
            "S",
            ServiceProviderMsgResProps.body.notification.success.addDivision,
          ];
          if (this._isMounted) {
            helpers.resetForm();
            helpers.setStatus(statusMsg);
            PageUtils.scrollToTop();
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          ServiceProviderMsgResProps.body.notification.error.message,
        ];
        if (this._isMounted) {
          helpers.setStatus(statusMsg);

          if (err.data && err.data.errors && err.data.errors.length > 0) {
            const errors = err.data.errors;
            errors.forEach((error) => {
              if (error.element && error.message && error.location === "body") {
                helpers.setFieldError(error.element, error.message);
              }
            });
          }
          PageUtils.scrollToTop();
        }
      });
    if (this._isMounted) {
      helpers.setSubmitting(false);
    }
  };

  closeConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: false,
    });
  };

  openConfirmModal = async () => {
    await this.setStateAsync({
      isOpenConfirmModal: true,
    });
  };

  render() {
    const formEvent = PageDisplay.getFormEnterSubmitEvent();
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <Card className="sram-page-form">
              <CardTitle>
                <span>
                  {
                    ServiceProviderMsgResProps.body.content
                      .serviceProviderAddDivision
                  }
                </span>
                <span className="float-right">
                  {PageDisplay.showGoBackLink(
                    "L",
                    SetupURLProps.serviceProviders.listDivision,
                    this.props.history
                  )}
                </span>
              </CardTitle>
              <CardBody>
                <Formik
                  initialValues={this.state.formInputFields}
                  validationSchema={Yup.object().shape(
                    this.formValidationSchema()
                  )}
                  onSubmit={(values, helpers) =>
                    this.handleConfirmation(values, helpers)
                  }
                >
                  {(formik) => (
                    <Form id="serviceProviderDivisionForm" {...formEvent}>
                      <div className="sram-form-main">
                        <div>
                          {formik.status &&
                          Array.isArray(formik.status) &&
                          formik.status.length === 2 &&
                          (formik.status[0] === "E" ||
                            formik.status[0] === "S") ? (
                            <Row>
                              <Col xs={12}>
                                {formik.status[0] === "E"
                                  ? PageDisplay.showErrorNotification(
                                      formik.status[1]
                                    )
                                  : PageDisplay.showSuccessNotification(
                                      formik.status[1]
                                    )}
                              </Col>
                            </Row>
                          ) : null}
                        </div>
                        <div>
                          <Row form>
                            <Col sm={12} md={12} lg={12}>
                              <FormGroup>
                                <Label for="parentId">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .parentCompanyDivision.label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="parentId"
                                  as="select"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.parentId,
                                    formik.touched.parentId
                                  )}
                                  value={formik.values.parentId}
                                >
                                  <option value=""></option>
                                  {this.state.organizations &&
                                  this.state.organizations.length > 0
                                    ? this.state.organizations.map(
                                        (organization, index) => {
                                          return (
                                            <option
                                              value={
                                                organization.organizationId
                                              }
                                              key={`key-soc-option-${index}`}
                                            >
                                              {
                                                organization.organizationTreepath
                                              }
                                            </option>
                                          );
                                        }
                                      )
                                    : null}
                                </Field>
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "parentId"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={12} lg={12}>
                              <FormGroup>
                                <Label for="organizationName">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .divisionName.label
                                  }{" "}
                                  *
                                </Label>
                                <Field
                                  name="organizationName"
                                  type="text"
                                  maxLength="50"
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.organizationName,
                                    formik.touched.organizationName
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "organizationName"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={12} lg={12} className="pt-3">
                              <GeoAddressSearchField
                                callback={this.populateAddressFields}
                                formik={formik}
                              />
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="streetAddress1">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .streetAddress1.label
                                  }
                                </Label>
                                <Field
                                  name="streetAddress1"
                                  type="text"
                                  maxLength="100"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.streetAddress1,
                                    formik.touched.streetAddress1
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "streetAddress1"
                                )}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="streetAddress2">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .streetAddress2.label
                                  }
                                </Label>
                                <Field
                                  name="streetAddress2"
                                  type="text"
                                  maxLength="100"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.streetAddress2,
                                    formik.touched.streetAddress2
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "streetAddress2"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="city">
                                  {
                                    ServiceProviderMsgResProps.body.form.city
                                      .label
                                  }
                                </Label>
                                <Field
                                  name="city"
                                  type="text"
                                  maxLength="50"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.city,
                                    formik.touched.city
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage("city")}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="state">
                                  {
                                    ServiceProviderMsgResProps.body.form.state
                                      .label
                                  }
                                </Label>
                                <Field
                                  name="state"
                                  as="select"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.state,
                                    formik.touched.state
                                  )}
                                >
                                  {" "}
                                  <option value=""></option>
                                  {AppDataProps.address.states &&
                                  AppDataProps.address.states.length > 0
                                    ? AppDataProps.address.states.map(
                                        (state, index) => {
                                          return (
                                            <option
                                              value={state}
                                              key={`key-addr-state-${index}`}
                                            >
                                              {state}
                                            </option>
                                          );
                                        }
                                      )
                                    : null}
                                </Field>
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "state"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row form>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="country">
                                  {
                                    ServiceProviderMsgResProps.body.form.country
                                      .label
                                  }
                                </Label>
                                <Field
                                  name="country"
                                  as="select"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.country,
                                    formik.touched.country
                                  )}
                                >
                                  <option value=""></option>
                                  {AppDataProps.address.countries &&
                                  AppDataProps.address.countries.length > 0
                                    ? AppDataProps.address.countries.map(
                                        (country, index) => {
                                          return (
                                            <option
                                              value={country}
                                              key={`key-addr-country-${index}`}
                                            >
                                              {country}
                                            </option>
                                          );
                                        }
                                      )
                                    : null}
                                </Field>
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "country"
                                )}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="zipCode">
                                  {
                                    ServiceProviderMsgResProps.body.form.zipCode
                                      .label
                                  }
                                </Label>
                                <Field
                                  name="zipCode"
                                  type="text"
                                  maxLength="5"
                                  disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.zipCode,
                                    formik.touched.zipCode
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "zipCode"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          {/**  
                          <Row form>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="locationLatitude">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .locationLatitude.label
                                  }
                                </Label>
                                <Field
                                  name="locationLatitude"
                                  type="text"
                                  maxLength="30"
                                   disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.locationLatitude,
                                    formik.touched.locationLatitude
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "locationLatitude"
                                )}
                              </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                              <FormGroup>
                                <Label for="locationLongitude">
                                  {
                                    ServiceProviderMsgResProps.body.form
                                      .locationLongitude.label
                                  }
                                </Label>
                                <Field
                                  name="locationLongitude"
                                  type="text"
                                  maxLength="30"
                                   disabled={
                                    !this.state.isAddressFieldsPopulated
                                  }
                                  className={PageDisplay.getFormikFieldClassName(
                                    formik.errors.locationLongitude,
                                    formik.touched.locationLongitude
                                  )}
                                />
                                {PageDisplay.getFormikFieldErrorMessage(
                                  "locationLongitude"
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                          */}
                        </div>
                      </div>
                      <div className="sram-form-foot">
                        {PageDisplay.showSaveButton(formik.isSubmitting)}
                        {PageDisplay.showCancelButton(this.props.history)}
                      </div>
                      <FormFieldFocusError />
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
            {this.state.tempDivisionObj ? (
              <ConfirmModal
                isOpenConfirmModal={this.state.isOpenConfirmModal}
                closeConfirmModal={this.closeConfirmModal}
                handleSubmit={this.handleSubmit}
              >
                <div>
                  <div>
                    <Label>
                      {
                        ServiceProviderMsgResProps.body.notification
                          .confirmation.addDivisionTitle
                      }
                    </Label>
                  </div>
                  <div className="sram-confirm-warn-text">
                    {
                      ServiceProviderMsgResProps.body.notification.confirmation
                        .addDivisionNote
                    }
                  </div>
                </div>
              </ConfirmModal>
            ) : null}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default ServiceProviderDivisionAdd;
