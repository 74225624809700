import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import PageUtils from "../../core/helpers/page-utils";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { PortalURLProps } from "../settings/portal-urls";
import { manageError } from "../../core/actions/common-actions";
import { getPagePartsPrivilege } from "../../core/actions/identity-actions";
import { siteView, dispensersList } from "../actions/site-actions";
import {
  dispenserView,
  doorAlerts,
  sensorTempHumidity,
  prices,
  meterVolume,
  pumpErrorLogs,
  technicalDetails,
  flowRates,
  overRuns,
  standAlones,
  rebootHistory,
  technicalDetailsUpmCard,
  priceConfig,
  transactionData,
  alerts,
  alertTickets,
  componentFailure,
  transactionFlowData,
  alertEvents,
  pcnAlertEvents,
  noTrasactionStatus,
  flowRateAlerts,
} from "../actions/dispenser-actions";
import { NavLink as RRNavLink } from "react-router-dom";
import { DispenserMsgResProps } from "../messages/dispenser-properties";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DispenserCustomerExperience from "./dispenser-customer-experience";
import DispenserTechnicalDetails from "./dispenser-techical-details";
import { checkToken } from "../actions/actions";
import DispenserAlerts from "./dispenser-alerts";
import DispenserComponentFailure from "./dispenser-component-failure";
import DispenserPCNAlerts from "./dispenser-pcn-alerts";
// import DispenserErrorLogs from "./dispenser-error-logs";
// import DispenserPricesData from "./dispenser-prices-data";
////
// import DispenserVolume from "./dispenser-volume";
// import DispenserReboot from "./dispenser-reboot";
// import DispenserSensorData from "./dispenser-sensor-data";
// import DispenserTransactionData from "./dispenser-transaction-data";
// import DispenserTransactionFlow from "./dispenser-transaction-flow";
// import DispenserRebootV2 from "./dispenser-reboot-v2";
import DispenserWarmStartReboot from "./dispenser-warm-start-reboot";

export class DispenserDashboard extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcPortalSiteDispenserView = "CP-DSP-DVIW";
  _pcPortalSiteView = "CP-SIT-SVIW";
  _pcPortalViewErrorLogs = "CP-DEL-RELV";
  _pcPortalViewErrorLogsHistory = "CP-DEL-ELHV";
  _pcPortalViewRebootHistory = "CP-DRB-RBHV";
  _pcPortalViewCustomerExperienceMeterData = "CP-DCE-MTDV";
  _pcPortalViewCustomerExperienceOverrunData = "CP-DCE-ORDV";
  _pcPortalViewDoorAlerts = "CP-DSC-DRAV";
  _pcPortalViewStandaloneAlerts = "CP-DSC-SAAV";
  _pcPortalViewMeterVolumeData = "CP-DVL-MVDV";
  _pcPortalViewBriefTechnicalData = "CP-DTD-BTDV";
  _pcPortalViewDetailTechnicalData = "CP-DTD-DTDV";
  _pcPortalViewTemaparature = "CP-DSD-TEMV";
  _pcPortalViewHumidity = "CP-DSD-HUMV";
  _pcPortalViewPriceData = "CP-DPR-PRDV";
  _pcPortalPerformReboot = "CP-DRB-RBAP";
  _pcPortalViewPriceConfig = "CP-DPR-PRDV";
  _pcPortalViewTransactionData = "CP-DPR-PRDV";

  _pcPortalViewCrindCardReader = "CP-DCN-DCDR";
  _pcPortalViewCrindDisplay = "CP-DCN-DDSP";
  _pcPortalViewCrindPrinter = "CP-DCN-DPNT";
  _pcPortalViewCrindUPM = "CP-DCN-DUPM";
  _pcPortalViewCrindSSOM = "CP-DCN-DSSO";
  _pcPortalViewCrindGSOM = "CP-DCN-DGSM";
  _pcPortalViewTickets = "HT-TCT-TVIW";
  _pcPortalViewComponentFailureData = "CP-DCF-DCPF";
  _pcPortalViewCEFlowRate = "CP-DCE-DFRT";
  _pcPortalViewCENoTrans = "CP-DCU-NTSC";
  _pcPortalViewCEPrinterJam = "CP-DCE-PNJM";
  _pcPortalViewCEPaperO = "CP-DCE-PPOT";
  _pcSiteViewErrorModal = "CP-ERC-EVIW";
  _pcPortalViewPCNAlerts = "CP-DPC-PCNA";
  _pcPortalPCNCreateAlertsView = "CP-DPC-PCNC";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      dispensers: null,
      siteItem: null,
      listSites: null,
      site: null,
      partPrivileges: this.initPartPrivileges(),
      sites: null,
      searchText: "",
      dispenserItem: null,
      dispenser: null,
      active: "30 Days",
      buttons: [
        { days: "7", name: "7 Days" },
        { days: "30", name: "30 Days" },
        { days: "90", name: "90 Days" },
      ],
      days: 30,
      doorAlerts: null,
      sensorData: null,
      prices: null,
      meterVolume: null,
      technicalDeatils: null,
      technicalData: null,
      sideAFlowRates: null,
      sideBFlowRates: null,
      liveCriticalErrorLogsHistory: null,
      nonCriticalErrorLogsHistory: null,
      nonCriticalErrorLogsRecent: null,
      criticalErrorLogsRecent: null,
      isDoorAlertsDataFetched: false,
      isSensorDataFetched: false,
      isPriceDataFetched: false,
      isMeterDataFetched: false,
      isNonCriticalErrorLogsHistoryFetched: false,
      isLiveCriticalErrorLogsHistoryFetched: false,
      isNonCriticalErrorLogsRecentFetched: false,
      isCriticalErrorLogsRecentFetched: false,
      isTechnicalDetailsFetched: false,
      isFlowRatesFetched: false,
      recentCriticalPageOffset: 0,
      recentNonCriticalPageOffset: 0,
      historyCriticalPageOffset: 0,
      historyNonCriticalPageOffset: 0,
      pageLimit: 50,
      recentCriticalLoading: false,
      recentCriticalLength: "",
      recentNonCriticalLoading: false,
      recentNonCriticalLength: "",
      HistoryCriticalLoading: false,
      HistoryCriticalLength: "",
      historyNonCriticalLoading: false,
      historyNonCriticalLength: "",
      presetOverRuns: null,
      isPresetOverRunsFetched: false,
      standAlones: null,
      isStandAlonesFetched: false,
      standAlonesSideA: true,
      standAlonesSideB: true,
      rebootHistory: null,
      isRebootHistoryDataFetched: false,
      openAlerts: null,
      closedAlerts: null,
      isOpenAlertsDataFetched: false,
      isClosedAlertsDataFetched: false,
      sideAFlowSnapshot: null,
      sideBFlowSnapshot: null,
      isFlowSnapshotDataFetched: false,
      dispatchTickets: null,
      closedTickets: null,
      openTickets: null,
      allAlerts: null,
      isAllAlertsDataFetched: false,
      componentsData: null,
      iscomponentsDataFetched: false,
      componentCardReader: null,
      componentPrinter: null,
      componentSSOM: null,
      componentGSOM: null,
      componentDisplay: null,
      componentUPM: null,
      componentEncorePump: null,
      technicalDetailsData: null,
      pricesConfigData: null,
      isPricesConfigDataFetched: false,
      transaction: null,
      istransactionDataFetched: false,
      transactionPeakFlowA: null,
      transactionPeakFlowB: null,
      transactionFlow: null,
      transactionFlowAxis: null,
      istransactionFlowDataFetched: false,
      printerPaperOutA: null,
      printerPaperOutB: null,
      alertEventData: null,
      isAlertEventDataFetched: false,
      pcnAlertEventData: null,
      isPCNAlertEventDataFetched: false,
      isNoTransactionDataFetched: false,
      noTransactionSideAAllRecords: null,
      noTransactionSideADisplayRecords: null,
      noTransactionSideAPageRecords: null,
      noTransactionSideBAllRecords: null,
      noTransactionSideBDisplayRecords: null,
      noTransactionSideBPageRecords: null,
      sortedColumnA: "dataDate",
      sortedOrderA: AppConfigProps.listPage.sortOrder.defaultValue,
      sortedColumnB: "dataDate",
      sortedOrderB: AppConfigProps.listPage.sortOrder.defaultValue,
      flowRatesAlertsData: null,
      noTransactionAlertsData: null,
      isFlowRatesAlertsDataFetched: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
    if (
      this.state.partPrivileges[this._pcPortalViewComponentFailureData] === true
    ) {
      this.getComponentFailureData();
      // For the Flow Rate Alerts Section in Customer Experience
      this.getFlowRateAlerts();
    }
    if (this.state.partPrivileges[this._pcPortalViewErrorLogs] === true) {
      this.getCriticalErrorLogsRecent();
      this.getNonCriticalErrorLogsRecent();
    }
    if (
      this.state.partPrivileges[this._pcPortalViewErrorLogsHistory] === true
    ) {
      this.getLiveCriticalErrorLogsHistory();
      this.getNonCriticalErrorLogsHistory();
    }
    if (
      this.state.partPrivileges[
        this._pcPortalViewCustomerExperienceMeterData
      ] === true
    ) {
      // this.getFlowSnapshot();
      this.getFlowRates();
    }
    if (this.state.partPrivileges[this._pcPortalViewRebootHistory] === true) {
      this.getRebootHistory();
    }
    if (
      this.state.partPrivileges[this._pcPortalViewStandaloneAlerts] === true
    ) {
      this.getStandAlones();
    }
    if (
      this.state.partPrivileges[
        this._pcPortalViewCustomerExperienceOverrunData
      ] === true
    ) {
      this.getOverRus();
    }
    // if (this.state.partPrivileges[this._pcPortalViewDoorAlerts] === true) {
    //   this.getDoorAlerts();
    // }
    if (
      this.state.partPrivileges[this._pcPortalViewDetailTechnicalData] ===
        true ||
      this.state.partPrivileges[this._pcPortalViewBriefTechnicalData] === true
    ) {
      this.getTechnicalDeatils();
      this.getTechnicalDeatilsData();
    }
    if (
      this.state.partPrivileges[this._pcPortalViewTemaparature] === true ||
      this.state.partPrivileges[this._pcPortalViewHumidity] === true
    ) {
      this.sensorData();
    }
    if (this.state.partPrivileges[this._pcPortalViewPriceData] === true) {
      this.getPrices();
    }
    if (this.state.partPrivileges[this._pcPortalViewMeterVolumeData] === true) {
      this.getMeterVolume();
    }
    if (this.state.partPrivileges[this._pcPortalViewPriceConfig] === true) {
      this.getPriceConfig();
    }
    if (this.state.partPrivileges[this._pcPortalViewTransactionData] === true) {
      this.getTransactionData();
      this.getTransactionFlowData();
    }
    // if (this.state.partPrivileges[this._pcPortalViewCEFlowRate] === true) {
    //   this.getTransactionFlowData();
    // }
    // FOR ALERTS IN DISPENSER DASHBOARD
    if (this.state.partPrivileges[this._pcPortalViewTickets] === true) {
      this.getAllAlerts();
      this.getAlertEvents();
    }
    // FOR FLOW RATE IN DISPENSER DASHBOARD
    if (this.state.partPrivileges[this._pcPortalViewCEFlowRate] === true) {
      await this.setStateAsync({ isFlowSnapshotDataFetched: true });
    }
    //FOR NO TRANS IN DISPENSER DASHBOARD
    if (this.state.partPrivileges[this._pcPortalViewCENoTrans] === true) {
      this.getNoTransactionStatus();
    }
    if (this.state.partPrivileges[this._pcPortalViewPCNAlerts] === true) {
      this.getPCNAlertEvents();
    }

    // this.interval = setInterval(() => {
    //   if (this.state.partPrivileges[this._pcPortalViewDoorAlerts] === true) {
    //     this.getDoorAlerts();
    //   }
    // }, 5000);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      PageUtils.scrollToTop();
      await this.loadPageData();
      if (
        this.state.partPrivileges[this._pcPortalViewComponentFailureData] ===
        true
      ) {
        this.getComponentFailureData();
        // For the Flow Rate Alerts Section in Customer Experience
        this.getFlowRateAlerts();
      }
      if (this.state.partPrivileges[this._pcPortalViewErrorLogs] === true) {
        this.getCriticalErrorLogsRecent();
        this.getNonCriticalErrorLogsRecent();
      }
      if (
        this.state.partPrivileges[this._pcPortalViewErrorLogsHistory] === true
      ) {
        this.getLiveCriticalErrorLogsHistory();
        this.getNonCriticalErrorLogsHistory();
      }
      if (
        this.state.partPrivileges[
          this._pcPortalViewCustomerExperienceMeterData
        ] === true
      ) {
        // this.getFlowSnapshot();
        this.getFlowRates();
      }
      if (this.state.partPrivileges[this._pcPortalViewRebootHistory] === true) {
        this.getRebootHistory();
      }
      if (
        this.state.partPrivileges[this._pcPortalViewStandaloneAlerts] === true
      ) {
        this.getStandAlones();
      }
      if (
        this.state.partPrivileges[
          this._pcPortalViewCustomerExperienceOverrunData
        ] === true
      ) {
        this.getOverRus();
      }
      // if (this.state.partPrivileges[this._pcPortalViewDoorAlerts] === true) {
      //   this.getDoorAlerts();
      // }
      if (
        this.state.partPrivileges[this._pcPortalViewDetailTechnicalData] ===
          true ||
        this.state.partPrivileges[this._pcPortalViewBriefTechnicalData] === true
      ) {
        this.getTechnicalDeatils();
        this.getTechnicalDeatilsData();
      }
      if (
        this.state.partPrivileges[this._pcPortalViewTemaparature] === true ||
        this.state.partPrivileges[this._pcPortalViewHumidity] === true
      ) {
        this.sensorData();
      }
      if (this.state.partPrivileges[this._pcPortalViewPriceData] === true) {
        this.getPrices();
      }
      if (
        this.state.partPrivileges[this._pcPortalViewMeterVolumeData] === true
      ) {
        this.getMeterVolume();
      }
      if (this.state.partPrivileges[this._pcPortalViewPriceConfig] === true) {
        this.getPriceConfig();
      }
      if (
        this.state.partPrivileges[this._pcPortalViewTransactionData] === true
      ) {
        this.getTransactionData();
        this.getTransactionFlowData();
      }
      // if (this.state.partPrivileges[this._pcPortalViewCEFlowRate] === true) {
      //   this.getTransactionFlowData();
      // }
      // FOR ALERTS IN DISPENSER DASHBOARD
      if (this.state.partPrivileges[this._pcPortalViewTickets] === true) {
        this.getAllAlerts();
        this.getAlertEvents();
      }
      // FOR FLOW RATE IN DISPENSER DASHBOARD
      if (this.state.partPrivileges[this._pcPortalViewCEFlowRate] === true) {
        await this.setStateAsync({ isFlowSnapshotDataFetched: true });
      }
      //FOR NO TRANS IN DISPENSER DASHBOARD
      if (this.state.partPrivileges[this._pcPortalViewCENoTrans] === true) {
        this.getNoTransactionStatus();
      }
      if (this.state.partPrivileges[this._pcPortalViewPCNAlerts] === true) {
        this.getPCNAlertEvents();
      }
      // this.interval = setInterval(() => {
      //   if (this.state.partPrivileges[this._pcPortalViewDoorAlerts] === true) {
      //     this.getDoorAlerts();
      //   }
      // }, 5000);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
    clearInterval(this.interval);
  }

  loadPageData = async () => {
    await this.setStateAsync({
      isPageDataFetched: false,
      isDoorAlertsDataFetched: false,
      isSensorDataFetched: false,
      isPriceDataFetched: false,
      isMeterDataFetched: false,
      isNonCriticalErrorLogsHistoryFetched: false,
      isLiveCriticalErrorLogsHistoryFetched: false,
      isNonCriticalErrorLogsRecentFetched: false,
      isCriticalErrorLogsRecentFetched: false,
      isTechnicalDetailsFetched: false,
      isFlowRatesFetched: false,
      allAlerts: null,
      isAllAlertsDataFetched: false,
      componentsData: null,
      componentPrinter: null,
      componentCardReader: null,
      iscomponentsDataFetched: false,
      componentSSOM: null,
      componentGSOM: null,
      componentDisplay: null,
      componentUPM: null,
      componentEncorePump: null,
      isPricesConfigDataFetched: false,
      istransactionDataFetched: false,
      istransactionFlowDataFetched: false,
      isNoTransactionDataFetched: false,
      isFlowRatesAlertsDataFetched: false,
    });
    await this.setStateAsync({
      dispensers: null,
      sites: null,
      siteItem: null,
      site: null,
      listSites: null,
      dispenserItem: null,
      dispenser: null,
      doorAlerts: null,
      sensorData: null,
      prices: null,
      meterVolume: null,
      technicalData: null,
      technicalDeatils: null,
      sideAFlowRates: null,
      sideBFlowRates: null,
      rebootHistory: null,
      liveCriticalErrorLogsHistory: null,
      nonCriticalErrorLogsHistory: null,
      nonCriticalErrorLogsRecent: null,
      criticalErrorLogsRecent: null,
      recentCriticalPageOffset: 0,
      recentNonCriticalPageOffset: 0,
      historyCriticalPageOffset: 0,
      historyNonCriticalPageOffset: 0,
      recentCriticalLoading: false,
      recentCriticalLength: "",
      recentNonCriticalLoading: false,
      recentNonCriticalLength: "",
      HistoryCriticalLoading: false,
      HistoryCriticalLength: "",
      historyNonCriticalLoading: false,
      historyNonCriticalLength: "",
      sideAFlowSnapshot: null,
      sideBFlowSnapshot: null,
      isFlowSnapshotDataFetched: false,
      technicalDetailsData: null,
      pricesConfigData: null,
      transaction: null,
      transactionFlow: null,
      transactionFlowAxis: null,
      noTransactionSideAAllRecords: null,
      noTransactionSideADisplayRecords: null,
      noTransactionSideAPageRecords: null,
      noTransactionSideBAllRecords: null,
      noTransactionSideBDisplayRecords: null,
      noTransactionSideBPageRecords: null,
      flowRatesAlertsData: null,
      noTransactionAlertsData: null,
    });
    await this.checkPartPrivilege();
    await this.setPartPrivileges();
    const siteId = this.props.match.params.sid;

    await siteView(siteId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({
            siteItem: res.data.result,
            site: [res.data.result],
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await dispensersList(siteId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            await this.setStateAsync({ dispensers: res.data.records });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });

    const dispenserId = this.props.match.params.did;
    await dispenserView(siteId, dispenserId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({
            dispenserItem: res.data.result,
            dispenser: [res.data.result],
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  getDoorAlerts = async () => {
    if (this.state.isPageDataFetched === false) {
      await this.setStateAsync({
        isDoorAlertsDataFetched: false,
        doorAlerts: null,
      });
    }
    // await this.setStateAsync({
    //   doorAlerts: null,
    // });
    const queryData = "extractType=period&dataPeriod=" + this.state.days;
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await doorAlerts(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            doorAlerts: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isDoorAlertsDataFetched: true });
  };

  sensorData = async () => {
    await this.setStateAsync({ isSensorDataFetched: false });
    await this.setStateAsync({
      sensorData: null,
    });
    const queryData =
      "extractType=period&dataPeriod=" +
      this.state.days +
      "&sortField=deviceTimestamp&sortOrder=1";
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await sensorTempHumidity(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            sensorData: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isSensorDataFetched: true });
  };

  getPrices = async () => {
    await this.setStateAsync({ isPriceDataFetched: false });
    await this.setStateAsync({
      prices: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData =
      "extractType=dayPrice&dataPeriod=" +
      this.state.days +
      "&sortField=dataDate&sortOrder=1";
    await prices(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            prices: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPriceDataFetched: true });
  };

  getMeterVolume = async () => {
    await this.setStateAsync({ isMeterDataFetched: false });
    await this.setStateAsync({
      meterVolume: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData = "extractType=dayVolume&dataPeriod=" + this.state.days;
    await meterVolume(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            meterVolume: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isMeterDataFetched: true });
  };

  getCriticalErrorLogsRecent = async () => {
    await this.setStateAsync({ isCriticalErrorLogsRecentFetched: false });
    await this.setStateAsync({
      criticalErrorLogsRecent: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData =
      "segment=recent&severityLevel=1,2&extractType=period&dataPeriod=" +
      this.state.days +
      "&pageOffset=" +
      this.state.recentCriticalPageOffset +
      "&pageLimit=" +
      this.state.pageLimit;
    await pumpErrorLogs(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            criticalErrorLogsRecent: res.data.records,
            // recentCriticalPageOffset: res.data.records.length,
            recentCriticalLength: res.data.records.length,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isCriticalErrorLogsRecentFetched: true });
  };

  getNonCriticalErrorLogsRecent = async () => {
    await this.setStateAsync({ isNonCriticalErrorLogsRecentFetched: false });
    await this.setStateAsync({
      nonCriticalErrorLogsRecent: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData =
      "segment=recent&severityLevel=3,4,5&extractType=period&dataPeriod=" +
      this.state.days +
      "&pageOffset=" +
      this.state.recentNonCriticalPageOffset +
      "&pageLimit=" +
      this.state.pageLimit;
    await pumpErrorLogs(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            nonCriticalErrorLogsRecent: res.data.records,
            // recentNonCriticalPageOffset: res.data.records.length,
            recentNonCriticalLength: res.data.records.length,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isNonCriticalErrorLogsRecentFetched: true });
  };

  getLiveCriticalErrorLogsHistory = async () => {
    await this.setStateAsync({ isLiveCriticalErrorLogsHistoryFetched: false });
    await this.setStateAsync({
      liveCriticalErrorLogsHistory: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData =
      "segment=history&severityLevel=1,2&extractType=period&dataPeriod=" +
      this.state.days +
      "&pageOffset=" +
      this.state.historyCriticalPageOffset +
      "&pageLimit=" +
      this.state.pageLimit;

    await pumpErrorLogs(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            liveCriticalErrorLogsHistory: res.data.records,
            // historyCriticalPageOffset: res.data.records.length,
            historyNonCriticalLength: res.data.records.length,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isLiveCriticalErrorLogsHistoryFetched: true });
  };

  getNonCriticalErrorLogsHistory = async () => {
    await this.setStateAsync({ isNonCriticalErrorLogsHistoryFetched: false });
    await this.setStateAsync({
      nonCriticalErrorLogsHistory: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData =
      "segment=history&severityLevel=3,4,5&extractType=period&dataPeriod=" +
      this.state.days +
      "&pageOffset=" +
      this.state.historyNonCriticalPageOffset +
      "&pageLimit=" +
      this.state.pageLimit;

    await pumpErrorLogs(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            nonCriticalErrorLogsHistory: res.data.records,
            // historyNonCriticalPageOffset: res.data.records.length,
            historyNonCriticalLength: res.data.records.length,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isNonCriticalErrorLogsHistoryFetched: true });
  };

  getTechnicalDeatils = async () => {
    await this.setStateAsync({ isTechnicalDetailsFetched: false });
    await this.setStateAsync({
      technicalDeatils: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await technicalDetails(siteId, dispenserId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({
            technicalDeatils: res.data.result,
            technicalData: [res.data.result],
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isTechnicalDetailsFetched: true });
  };

  getTechnicalDeatilsData = async () => {
    await this.setStateAsync({ isTechnicalDetailsFetched: false });
    await this.setStateAsync({
      technicalDetailsData: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await technicalDetailsUpmCard(siteId, dispenserId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({
            // technicalDetailsData: res.data.result,
            technicalDetailsData: [res.data.result],
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isTechnicalDetailsFetched: true });
  };

  getFlowRates = async () => {
    await this.setStateAsync({ isFlowRatesFetched: false });
    await this.setStateAsync({
      sideAFlowRates: null,
      sideBFlowRates: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData =
      "extractType=dayFlow&dataPeriod=" +
      this.state.days +
      "&sortField=dataDate&sortOrder=1";
    await flowRates(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          const Side_A = [];
          const Side_B = [];
          for (const data of res.data.records) {
            if (data.sideLabel === "A") {
              Side_A.push(data);
            }
            if (data.sideLabel === "B") {
              Side_B.push(data);
            }
          }
          await this.setStateAsync({
            sideAFlowRates: Side_A,
            sideBFlowRates: Side_B,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isFlowRatesFetched: true });
  };

  getRecentCriticalLoadMore = async () => {
    checkToken(this.props, this._cancelToken);
    await this.setStateAsync({ recentCriticalLoading: true });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await this.setStateAsync({
      recentCriticalPageOffset: this.state.recentCriticalPageOffset + 1,
    });
    // this.state.recentCriticalPageOffset = this.state.recentCriticalPageOffset + 1
    const queryData =
      "segment=recent&severityLevel=1,2&extractType=period&dataPeriod=" +
      this.state.days +
      "&pageOffset=" +
      this.state.recentCriticalPageOffset +
      "&pageLimit=" +
      this.state.pageLimit;

    await pumpErrorLogs(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          const totalRecord = [
            ...this.state.criticalErrorLogsRecent,
            ...res.data.records,
          ];
          await this.setStateAsync({
            criticalErrorLogsRecent: totalRecord,
            // recentCriticalPageOffset: totalRecord.length,
            recentCriticalLength: res.data.records.length,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ recentCriticalLoading: false });
  };

  getRecentNonCriticalLoadMore = async () => {
    checkToken(this.props, this._cancelToken);
    await this.setStateAsync({ recentNonCriticalLoading: true });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await this.setStateAsync({
      recentNonCriticalPageOffset: this.state.recentNonCriticalPageOffset + 1,
    });
    // this.state.recentNonCriticalPageOffset = this.state.recentNonCriticalPageOffset + 1
    const queryData =
      "segment=recent&severityLevel=3,4,5&extractType=period&dataPeriod=" +
      this.state.days +
      "&pageOffset=" +
      this.state.recentNonCriticalPageOffset +
      "&pageLimit=" +
      this.state.pageLimit;

    await pumpErrorLogs(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          const totalRecord = [
            ...this.state.nonCriticalErrorLogsRecent,
            ...res.data.records,
          ];
          await this.setStateAsync({
            nonCriticalErrorLogsRecent: totalRecord,
            // recentNonCriticalPageOffset: totalRecord.length,
            recentNonCriticalLength: res.data.records.length,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ recentNonCriticalLoading: false });
  };

  getHistoryCriticalLoadMore = async () => {
    checkToken(this.props, this._cancelToken);
    await this.setStateAsync({ historyCriticalLoading: true });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await this.setStateAsync({
      historyCriticalPageOffset: this.state.historyCriticalPageOffset + 1,
    });
    // this.state.historyCriticalPageOffset = this.state.historyCriticalPageOffset + 1
    const queryData =
      "segment=history&severityLevel=1,2&extractType=period&dataPeriod=" +
      this.state.days +
      "&pageOffset=" +
      this.state.historyCriticalPageOffset +
      "&pageLimit=" +
      this.state.pageLimit;

    await pumpErrorLogs(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          const totalRecord = [
            ...this.state.liveCriticalErrorLogsHistory,
            ...res.data.records,
          ];
          await this.setStateAsync({
            liveCriticalErrorLogsHistory: totalRecord,
            // historyCriticalPageOffset: totalRecord.length,
            historyCriticalLength: res.data.records.length,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ historyCriticalLoading: false });
  };

  getHistoryNonCriticalLoadMore = async () => {
    checkToken(this.props, this._cancelToken);
    await this.setStateAsync({ historyNonCriticalLoading: true });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await this.setStateAsync({
      historyNonCriticalPageOffset: this.state.historyNonCriticalPageOffset + 1,
    });
    // this.state.historyNonCriticalPageOffset = this.state.historyNonCriticalPageOffset + 1
    const queryData =
      "segment=history&severityLevel=3,4,5&extractType=period&dataPeriod=" +
      this.state.days +
      "&pageOffset=" +
      this.state.historyNonCriticalPageOffset +
      "&pageLimit=" +
      this.state.pageLimit;

    await pumpErrorLogs(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          const totalRecord = [
            ...this.state.nonCriticalErrorLogsHistory,
            ...res.data.records,
          ];
          await this.setStateAsync({
            nonCriticalErrorLogsHistory: totalRecord,
            // historyNonCriticalPageOffset: totalRecord.length,
            historyNonCriticalLength: res.data.records.length,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ historyNonCriticalLoading: false });
  };
  getOverRus = async () => {
    await this.setStateAsync({ isPresetOverRunsFetched: false });
    await this.setStateAsync({
      presetOverRuns: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData = "extractType=dayCount&dataPeriod=" + this.state.days;
    await overRuns(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({
            presetOverRuns: res.data.result,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPresetOverRunsFetched: true });
  };

  getStandAlones = async () => {
    await this.setStateAsync({ isStandAlonesFetched: false });
    await this.setStateAsync({
      standAlones: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData = "extractType=dayCount&dataPeriod=" + this.state.days;
    await standAlones(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({
            standAlones: res.data.result,
          });
          // if (res.data.records.length > 0){
          //   let arr = []
          //   let arr1 = []
          //   for (const data of res.data.records) {
          //     arr.push(data.sideA)
          //     arr1.push(data.sideB)
          //   }
          //   let data = arr.reduce((total, val) => total + parseInt(val, 10), 0);
          //   let data1 = arr1.reduce((total, val) => total + parseInt(val, 10), 0);
          //   await this.setStateAsync({standAlonesSideA : data})
          //   await this.setStateAsync({standAlonesSideB : data1})
          // }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isStandAlonesFetched: true });
  };
  getRebootHistory = async () => {
    await this.setStateAsync({ isRebootHistoryDataFetched: false });
    // await this.setStateAsync({
    //   rebootHistory: null,
    // });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData =
      "extractType=period&dataPeriod=" +
      this.state.days +
      "&pageOffset=0&pageLimit=0&sortOrder=-1";
    await rebootHistory(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            rebootHistory: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isRebootHistoryDataFetched: true });
  };

  getPriceConfig = async () => {
    await this.setStateAsync({ isPricesConfigDataFetched: false });
    await this.setStateAsync({
      pricesConfigData: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData =
      "extractType=timePrice&dataPeriod=" +
      this.state.days +
      "&sortField=deviceTimestamp&sortOrder=-1";
    await priceConfig(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            pricesConfigData: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPricesConfigDataFetched: true });
  };

  getTransactionData = async () => {
    await this.setStateAsync({ istransactionDataFetched: false });
    await this.setStateAsync({
      transaction: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData =
      "extractType=list&dataPeriod=" +
      this.state.days +
      "&sortField=deviceTimestamp&sortOrder=-1";
    await transactionData(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            transaction: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ istransactionDataFetched: true });
  };

  getTransactionFlowData = async () => {
    await this.setStateAsync({ istransactionFlowDataFetched: false });
    await this.setStateAsync({
      transactionFlow: null,
      transactionFlowAxis: null,
    });
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    const queryData =
      "extractType=dayFlow&dataPeriod=" +
      this.state.days +
      "&sortField=dataDate&sortOrder=-1";
    await transactionFlowData(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            transactionFlow: res.data.records,
            transactionFlowAxis: res.data?.axisResponse,
          });
          var even = [];
          var odd = [];
          res.data.records &&
            res.data.records.forEach(function (element) {
              if (element.pumpNumber % 2 === 0) {
                even.push(element);
              } else if (element.pumpNumber % 2 !== 0) {
                odd.push(element);
              }
            });
          this.setState({
            transactionPeakFlowA: odd,
            transactionPeakFlowB: even,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ istransactionFlowDataFetched: true });
  };

  getAllAlerts = async () => {
    await this.setStateAsync({ isAllAlertsDataFetched: false });
    await this.setStateAsync({
      allAlerts: null,
    });
    const queryData =
      "extractType=period&ticketStatus=A&dataPeriod=" +
      this.state.days +
      "&sortField=createTimestamp&sortOrder=-1";
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await alerts(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            allAlerts: res.data.records,
          });
          let closedTickets = [];
          let dispatchTickets = [];
          let openTickets = [];
          res.data.records.forEach(function (element) {
            if (element.ticketStatus === "Closed") {
              closedTickets.push(element);
            } else if (element.ticketStatus === "Dispatched") {
              dispatchTickets.push(element);
            } else if (
              element.ticketStatus === "New" ||
              element.ticketStatus === "In Progress"
            ) {
              openTickets.push(element);
            }
          });
          await this.setStateAsync({
            openTickets: openTickets,
            dispatchTickets: dispatchTickets,
            closedTickets: closedTickets,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isAllAlertsDataFetched: true });
  };

  getOpenAlerts = async () => {
    await this.setStateAsync({ isOpenAlertsDataFetched: false });
    await this.setStateAsync({
      openAlerts: null,
    });
    const queryData =
      "extractType=period&alertStatus=Open&dataPeriod=" +
      this.state.days +
      "&sortField=startTimestamp&sortOrder=-1";
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await alertTickets(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            openAlerts: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isOpenAlertsDataFetched: true });
  };

  getClosedAlerts = async () => {
    await this.setStateAsync({ isClosedAlertsDataFetched: false });
    await this.setStateAsync({
      closedAlerts: null,
    });
    const queryData =
      "extractType=period&alertStatus=Closed&dataPeriod=" +
      this.state.days +
      "&sortField=startTimestamp&sortOrder=-1";
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await alertTickets(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            closedAlerts: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isClosedAlertsDataFetched: true });
  };

  getComponentFailureData = async () => {
    await this.setStateAsync({ iscomponentsDataFetched: false });
    await this.setStateAsync({
      componentsData: null,
      componentPrinter: null,
      componentCardReader: null,
    });
    const queryData =
      "extractType=period&componentName=&dataPeriod=" +
      this.state.days +
      "&sortField=deviceTimestamp&sortOrder=-1";
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await componentFailure(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          let data = [...res.data.records];
          let componentsData = [];
          let componentPrinter = [];
          let componentCardReader = [];
          let componentSSOM = [];
          let componentGSOM = [];
          let componentDisplay = [];
          let componentUPM = [];
          let componentEncorePump = [];
          let printerPaperOutA = [];
          let printerPaperOutB = [];

          data.forEach(function (element) {
            if (
              element.componentName !== null &&
              element.eventCause !== null &&
              element.eventCause?.toLowerCase() !== "paper out" &&
              element.eventCause?.toLowerCase() !== "paper low" &&
              element.eventCause?.toLowerCase() !== "jammed"
            ) {
              componentsData.push(element);
            }

            if (
              element.componentName !== null &&
              element.componentName?.toLowerCase() === "cardreader"
            ) {
              componentCardReader.push(element);
            } else if (
              element.componentName !== null &&
              element.componentName?.toLowerCase() === "printer"
            ) {
              // componentPrinter.push(element);
              if (
                element.eventCause !== null &&
                (element.eventCause?.toLowerCase() === "paper out" ||
                  element.eventCause?.toLowerCase() === "paper low" ||
                  element.eventCause?.toLowerCase() === "jammed")
              ) {
                if (element.sideLabel === "A") {
                  printerPaperOutA.push(element);
                } else if (element.sideLabel === "B") {
                  printerPaperOutB.push(element);
                }
              } else if (
                element.eventCause !== null &&
                (element.eventCause?.toLowerCase() !== "paper out" ||
                  element.eventCause?.toLowerCase() !== "paper low" ||
                  element.eventCause?.toLowerCase() !== "jammed")
              ) {
                componentPrinter.push(element);
              }
            } else if (
              element.componentName !== null &&
              element.componentName?.toLowerCase() === "ssom"
            ) {
              componentSSOM.push(element);
            } else if (
              element.componentName !== null &&
              element.componentName?.toLowerCase() === "gsom"
            ) {
              componentGSOM.push(element);
            } else if (
              element.componentName !== null &&
              element.componentName?.toLowerCase() === "display"
            ) {
              componentDisplay.push(element);
            } else if (
              element.componentName !== null &&
              element.componentName?.toLowerCase() === "upm"
            ) {
              componentUPM.push(element);
            } else if (
              element.componentName !== null &&
              element.componentName?.toLowerCase() === "encore pump"
            ) {
              componentEncorePump.push(element);
            }
          });
          await this.setStateAsync({
            componentPrinter: componentPrinter,
            componentCardReader: componentCardReader,
            componentEncorePump: componentEncorePump,
            componentsData: componentsData,
          });
          await this.setStateAsync({
            componentUPM: componentUPM,
            componentDisplay: componentDisplay,
            componentGSOM: componentGSOM,
            componentSSOM: componentSSOM,
            printerPaperOutA: printerPaperOutA,
            printerPaperOutB: printerPaperOutB,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ iscomponentsDataFetched: true });
  };

  getAlertEvents = async () => {
    await this.setStateAsync({ isAlertEventDataFetched: false });
    await this.setStateAsync({
      alertEventData: null,
    });
    const queryData =
      "extractType=period&alertEventStatus=O&dataPeriod=" +
      this.state.days +
      "&sortField=createTimestamp&sortOrder=-1";
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await alertEvents(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            alertEventData: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isAlertEventDataFetched: true });
  };

  getPCNAlertEvents = async () => {
    await this.setStateAsync({ isPCNAlertEventDataFetched: false });
    await this.setStateAsync({
      pcnAlertEventData: null,
    });
    const queryData =
      "extractType=period&alertEventStatus=O&dataPeriod=" +
      this.state.days +
      "&sortField=createTimestamp&sortOrder=-1&componentName=pumpcontrolnode";
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await pcnAlertEvents(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          await this.setStateAsync({
            pcnAlertEventData: res.data.records,
          });
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPCNAlertEventDataFetched: true });
  };

  getNoTransactionStatus = async () => {
    await this.setStateAsync({ isNoTransactionDataFetched: false });
    await this.setStateAsync({
      noTransactionSideAAllRecords: null,
      noTransactionSideADisplayRecords: null,
      noTransactionSideAPageRecords: null,
      noTransactionSideBAllRecords: null,
      noTransactionSideBDisplayRecords: null,
      noTransactionSideBPageRecords: null,
    });
    const queryData =
      "extractType=dayFlow&dataPeriod=" +
      this.state.days +
      "&sortField=dataDate&sortOrder=-1";
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await noTrasactionStatus(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records?.length > 0) {
            let data = [...res.data.records];
            let sideA = [];
            let sideB = [];
            data.forEach(function (element) {
              if (element.pumpNumber !== null && element.pumpNumber % 2 === 0) {
                sideB.push(element);
              } else if (
                element.pumpNumber !== null &&
                element.pumpNumber % 2 !== 0
              ) {
                sideA.push(element);
              }
            });
            await this.setStateAsync({
              noTransactionSideAAllRecords: sideA,
              noTransactionSideBAllRecords: sideB,
            });
            // No Transaction Side A Data
            let sideASortedRecords = await PageUtils.sortListRecords(
              this.state.noTransactionSideAAllRecords,
              this.state.sortedColumn,
              this.state.sortedOrder
            );
            await this.setStateAsync({
              noTransactionSideADisplayRecords: sideASortedRecords,
            });
            let sideASlicedRecords = await PageUtils.sliceListRecords(
              this.state.noTransactionSideADisplayRecords,
              this.state.pageOffset,
              this.state.pageLimit
            );
            await this.setStateAsync({
              noTransactionSideAPageRecords: sideASlicedRecords,
            });

            // No Transaction Side B
            let sideBSortedRecords = await PageUtils.sortListRecords(
              this.state.noTransactionSideBAllRecords,
              this.state.sortedColumn,
              this.state.sortedOrder
            );
            await this.setStateAsync({
              noTransactionSideBDisplayRecords: sideBSortedRecords,
            });
            let sideBSlicedRecords = await PageUtils.sliceListRecords(
              this.state.noTransactionSideBDisplayRecords,
              this.state.pageOffset,
              this.state.pageLimit
            );
            await this.setStateAsync({
              noTransactionSideBPageRecords: sideBSlicedRecords,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isNoTransactionDataFetched: true });
  };

  getFlowRateAlerts = async () => {
    await this.setStateAsync({ isFlowRatesAlertsDataFetched: false });
    await this.setStateAsync({
      flowRatesAlertsData: null,
      noTransactionAlertsData: null,
    });
    const queryData =
      "extractType=period&componentName=Flowrate&dataPeriod=" +
      this.state.days +
      "&sortField=deviceTimestamp&sortOrder=-1";
    const siteId = this.props.match.params.sid;
    const dispenserId = this.props.match.params.did;
    await flowRateAlerts(siteId, dispenserId, queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records?.length > 0) {
            let data = [...res.data.records];
            let flowRatesAlerts = [];
            let noTransactionAlerts = [];

            data.forEach(function (element) {
              if (
                element.componentName !== null &&
                element.componentName?.toLowerCase() === "no transaction"
              ) {
                noTransactionAlerts.push(element);
              }
              if (
                element.componentName !== null &&
                element.componentName?.toLowerCase() === "flowrate"
              ) {
                flowRatesAlerts.push(element);
              }
            });
            await this.setStateAsync({
              flowRatesAlertsData: flowRatesAlerts,
              noTransactionAlertsData: noTransactionAlerts,
            });
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isFlowRatesAlertsDataFetched: true });
  };

  initPartPrivileges = () => {
    return {
      [this._pcPortalSiteDispenserView]: false,
      [this._pcPortalSiteView]: false,
      [this._pcPortalViewErrorLogs]: false,
      [this._pcPortalViewErrorLogsHistory]: false,
      [this._pcPortalViewRebootHistory]: false,
      [this._pcPortalViewCustomerExperienceMeterData]: false,
      [this._pcPortalViewCustomerExperienceOverrunData]: false,
      [this._pcPortalViewDoorAlerts]: false,
      [this._pcPortalViewStandaloneAlerts]: false,
      [this._pcPortalViewMeterVolumeData]: false,
      [this._pcPortalViewBriefTechnicalData]: false,
      [this._pcPortalViewDetailTechnicalData]: false,
      [this._pcPortalViewTemaparature]: false,
      [this._pcPortalViewHumidity]: false,
      [this._pcPortalViewPriceData]: false,
      [this._pcPortalPerformReboot]: false,
      [this._pcPortalViewPriceConfig]: false,
      [this._pcPortalViewTransactionData]: false,

      [this._pcPortalViewCrindCardReader]: false,
      [this._pcPortalViewCrindDisplay]: false,
      [this._pcPortalViewCrindPrinter]: false,
      [this._pcPortalViewCrindUPM]: false,
      [this._pcPortalViewCrindSSOM]: false,
      [this._pcPortalViewCrindGSOM]: false,
      [this._pcPortalViewCEFlowRate]: false,
      [this._pcPortalViewCENoTrans]: false,
      [this._pcPortalViewTickets]: false,
      [this._pcPortalViewComponentFailureData]: false,
      [this._pcPortalViewCEPrinterJam]: false,
      [this._pcPortalViewCEPaperO]: false,
      [this._pcSiteViewErrorModal]: false,
      [this._pcPortalViewPCNAlerts]: false,
      [this._pcPortalPCNCreateAlertsView]: false,
    };
  };

  checkPartPrivilege = async () => {
    const partCodes = [
      this._pcPortalSiteDispenserView,
      this._pcPortalSiteView,
      this._pcPortalViewErrorLogs,
      this._pcPortalViewErrorLogsHistory,
      this._pcPortalViewRebootHistory,
      this._pcPortalViewCustomerExperienceMeterData,
      this._pcPortalViewCustomerExperienceOverrunData,
      this._pcPortalViewDoorAlerts,
      this._pcPortalViewStandaloneAlerts,
      this._pcPortalViewMeterVolumeData,
      this._pcPortalViewBriefTechnicalData,
      this._pcPortalViewDetailTechnicalData,
      this._pcPortalViewTemaparature,
      this._pcPortalViewHumidity,
      this._pcPortalViewPriceData,
      this._pcPortalPerformReboot,
      this._pcPortalViewPriceConfig,
      this._pcPortalViewTransactionData,

      this._pcPortalViewCrindCardReader,
      this._pcPortalViewCrindDisplay,
      this._pcPortalViewCrindPrinter,
      this._pcPortalViewCrindUPM,
      this._pcPortalViewCrindSSOM,
      this._pcPortalViewCrindGSOM,
      this._pcPortalViewCEFlowRate,
      this._pcPortalViewCENoTrans,
      this._pcPortalViewTickets,
      this._pcPortalViewComponentFailureData,
      this._pcPortalViewCEPrinterJam,
      this._pcPortalViewCEPaperO,
      this._pcSiteViewErrorModal,
      this._pcPortalViewPCNAlerts,
      this._pcPortalPCNCreateAlertsView,
    ];
    const partPrivilege = await getPagePartsPrivilege(
      partCodes,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(PortalURLProps.pageNotFound);
    }
  };

  setPartPrivileges = async () => {
    const partCodes = [
      this._pcPortalSiteDispenserView,
      this._pcPortalSiteView,
      this._pcPortalViewErrorLogs,
      this._pcPortalViewErrorLogsHistory,
      this._pcPortalViewRebootHistory,
      this._pcPortalViewCustomerExperienceMeterData,
      this._pcPortalViewCustomerExperienceOverrunData,
      this._pcPortalViewDoorAlerts,
      this._pcPortalViewStandaloneAlerts,
      this._pcPortalViewMeterVolumeData,
      this._pcPortalViewBriefTechnicalData,
      this._pcPortalViewDetailTechnicalData,
      this._pcPortalViewTemaparature,
      this._pcPortalViewHumidity,
      this._pcPortalViewPriceData,
      this._pcPortalPerformReboot,
      this._pcPortalViewPriceConfig,
      this._pcPortalViewTransactionData,

      this._pcPortalViewCrindCardReader,
      this._pcPortalViewCrindDisplay,
      this._pcPortalViewCrindPrinter,
      this._pcPortalViewCrindUPM,
      this._pcPortalViewCrindSSOM,
      this._pcPortalViewCrindGSOM,
      this._pcPortalViewCEFlowRate,
      this._pcPortalViewCENoTrans,
      this._pcPortalViewTickets,
      this._pcPortalViewComponentFailureData,
      this._pcPortalViewCEPrinterJam,
      this._pcPortalViewCEPaperO,
      this._pcSiteViewErrorModal,
      this._pcPortalViewPCNAlerts,
      this._pcPortalPCNCreateAlertsView,
    ];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  isButtonActive = async (name, days) => {
    checkToken(this.props, this._cancelToken);
    await this.setStateAsync({ active: name, days: days });
    await this.setStateAsync({
      isDoorAlertsDataFetched: false,
      isSensorDataFetched: false,
      isPriceDataFetched: false,
      isMeterDataFetched: false,
      isNonCriticalErrorLogsHistoryFetched: false,
      isLiveCriticalErrorLogsHistoryFetched: false,
      isNonCriticalErrorLogsRecentFetched: false,
      isCriticalErrorLogsRecentFetched: false,
      isFlowRatesFetched: false,
      isTechnicalDetailsFetched: false,
      isRebootHistoryDataFetched: false,
      doorAlerts: null,
      sensorData: null,
      prices: null,
      meterVolume: null,
      sideAFlowRates: null,
      sideBFlowRates: null,
      liveCriticalErrorLogsHistory: null,
      nonCriticalErrorLogsHistory: null,
      nonCriticalErrorLogsRecent: null,
      criticalErrorLogsRecent: null,
      recentCriticalPageOffset: 0,
      recentNonCriticalPageOffset: 0,
      historyCriticalPageOffset: 0,
      historyNonCriticalPageOffset: 0,
      recentCriticalLoading: false,
      recentCriticalLength: "",
      recentNonCriticalLoading: false,
      recentNonCriticalLength: "",
      HistoryCriticalLoading: false,
      HistoryCriticalLength: "",
      historyNonCriticalLoading: false,
      historyNonCriticalLength: "",
      technicalData: null,
      // technicalDeatils: null,
      rebootHistory: null,
      pumpStatus: null,
      cardReader: null,
      epp: null,
      ppuDisplay: null,
      printer: null,
      fcb: null,
      gsom: null,
      omnia: null,
      isPumpStatusDataFetched: false,
      isCardReaderDataFetched: false,
      isEppDataFetched: false,
      isPpuDisplayDataFetched: false,
      isPrinterDataFetched: false,
      isFcbDataFetched: false,
      isGsomDataFetched: false,
      isOmniaDataFetched: false,
      printerJammed: null,
      paperOut: null,
      isPrinterJammedDataFetched: false,
      isPaperOutDataFetched: false,
      openAlerts: null,
      closedAlerts: null,
      isOpenAlertsDataFetched: false,
      isClosedAlertsDataFetched: false,
      sideAFlowSnapshot: null,
      sideBFlowSnapshot: null,
      isFlowSnapshotDataFetched: false,
      allAlerts: null,
      isAllAlertsDataFetched: false,
      componentsData: null,
      componentPrinter: null,
      componentCardReader: null,
      iscomponentsDataFetched: false,
      componentSSOM: null,
      componentGSOM: null,
      componentDisplay: null,
      componentUPM: null,
      componentEncorePump: null,
      technicalDetailsData: null,
      pricesConfigData: null,
      isPricesConfigDataFetched: false,
      transaction: null,
      istransactionDataFetched: false,
      isNoTransactionDataFetched: false,
      noTransactionSideAAllRecords: null,
      noTransactionSideADisplayRecords: null,
      noTransactionSideAPageRecords: null,
      noTransactionSideBAllRecords: null,
      noTransactionSideBDisplayRecords: null,
      noTransactionSideBPageRecords: null,
      flowRatesAlertsData: null,
      noTransactionAlertsData: null,
      isFlowRatesAlertsDataFetched: false,
      pcnAlertEventData: null,
      isPCNAlertEventDataFetched: false,
    });
    if (
      this.state.partPrivileges[this._pcPortalViewComponentFailureData] === true
    ) {
      this.getComponentFailureData();
      // For the Flow Rate Alerts Section in Customer Experience
      this.getFlowRateAlerts();
    }
    if (this.state.partPrivileges[this._pcPortalViewErrorLogs] === true) {
      this.getCriticalErrorLogsRecent();
      this.getNonCriticalErrorLogsRecent();
    }
    if (
      this.state.partPrivileges[this._pcPortalViewErrorLogsHistory] === true
    ) {
      this.getLiveCriticalErrorLogsHistory();
      this.getNonCriticalErrorLogsHistory();
    }
    if (
      this.state.partPrivileges[
        this._pcPortalViewCustomerExperienceMeterData
      ] === true
    ) {
      // this.getFlowSnapshot();
      this.getFlowRates();
    }
    if (this.state.partPrivileges[this._pcPortalViewRebootHistory] === true) {
      this.getRebootHistory();
    }
    if (
      this.state.partPrivileges[this._pcPortalViewStandaloneAlerts] === true
    ) {
      this.getStandAlones();
    }
    if (
      this.state.partPrivileges[
        this._pcPortalViewCustomerExperienceOverrunData
      ] === true
    ) {
      this.getOverRus();
    }
    // if (this.state.partPrivileges[this._pcPortalViewDoorAlerts] === true) {
    //   this.getDoorAlerts();
    // }
    if (
      this.state.partPrivileges[this._pcPortalViewDetailTechnicalData] ===
        true ||
      this.state.partPrivileges[this._pcPortalViewBriefTechnicalData] === true
    ) {
      this.getTechnicalDeatils();
      this.getTechnicalDeatilsData();
    }
    if (
      this.state.partPrivileges[this._pcPortalViewTemaparature] === true ||
      this.state.partPrivileges[this._pcPortalViewHumidity] === true
    ) {
      this.sensorData();
    }
    if (this.state.partPrivileges[this._pcPortalViewPriceData] === true) {
      this.getPrices();
    }
    if (this.state.partPrivileges[this._pcPortalViewMeterVolumeData] === true) {
      this.getMeterVolume();
    }
    if (this.state.partPrivileges[this._pcPortalViewPriceConfig] === true) {
      this.getPriceConfig();
    }
    if (this.state.partPrivileges[this._pcPortalViewTransactionData] === true) {
      this.getTransactionData();
      this.getTransactionFlowData();
    }
    // if (this.state.partPrivileges[this._pcPortalViewCEFlowRate] === true) {
    //   this.getTransactionFlowData();
    // }
    // FOR ALERTS IN DISPENSER DASHBOARD
    if (this.state.partPrivileges[this._pcPortalViewTickets] === true) {
      // await this.setStateAsync({ isAllAlertsDataFetched: true });
      // this.getOpenAlerts();
      // this.getClosedAlerts();
      this.getAllAlerts();
      this.getAlertEvents();
    }
    // FOR FLOW RATE IN DISPENSER DASHBOARD
    if (this.state.partPrivileges[this._pcPortalViewCEFlowRate] === true) {
      await this.setStateAsync({ isFlowSnapshotDataFetched: true });
    }
    //FOR NO TRANS IN DISPENSER DASHBOARD
    if (this.state.partPrivileges[this._pcPortalViewCENoTrans] === true) {
      this.getNoTransactionStatus();
    }
    if (this.state.partPrivileges[this._pcPortalViewPCNAlerts] === true) {
      this.getPCNAlertEvents();
    }
    // this.getFlowRateAlerts();
  };

  handleSortChangeSideA = async (sortColumn, sortOrder, sortType) => {
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.noTransactionSideADisplayRecords,
      sortColumn,
      sortOrder,
      sortType
    );
    this.setState({ noTransactionSideADisplayRecords: sortedRecords });
    this.setState({
      sortedColumnA: sortColumn,
      sortedOrderA: sortOrder,
    });

    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.noTransactionSideADisplayRecords,
      pageOffset,
      this.state.pageLimit
    );
    this.setState({ noTransactionSideAPageRecords: slicedRecords });
    this.setState({ pageOffset: pageOffset });
  };

  handleSortChangeSideB = async (sortColumn, sortOrder, sortType) => {
    let sortedRecords = await PageUtils.sortListRecords(
      this.state.noTransactionSideBDisplayRecords,
      sortColumn,
      sortOrder,
      sortType
    );
    this.setState({ noTransactionSideBDisplayRecords: sortedRecords });
    this.setState({
      sortedColumnB: sortColumn,
      sortedOrderB: sortOrder,
    });

    let pageOffset = 0;
    let slicedRecords = await PageUtils.sliceListRecords(
      this.state.noTransactionSideBDisplayRecords,
      pageOffset,
      this.state.pageLimit
    );
    this.setState({ noTransactionSideBPageRecords: slicedRecords });
    this.setState({ pageOffset: pageOffset });
  };

  reloadAlertsTabs = async () => {
    if (this.state.partPrivileges[this._pcPortalViewPCNAlerts] === true) {
      await this.getPCNAlertEvents();
    }
    if (this.state.partPrivileges[this._pcPortalViewTickets] === true) {
      await this.getAllAlerts();
      await this.getAlertEvents();
    }
  };

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-full">
            <Row>
              <div className="breadcrumb sram-bread-crumb">
                <Col xs={12} sm={12} md={12} lg={12} xl={12} className="font-0">
                  <span className="font-light font-18">
                    <RRNavLink to="/portal/sites">
                      <span className="sram-black-clr">
                        {DispenserMsgResProps.head.title.sitesHome}
                      </span>
                    </RRNavLink>
                  </span>
                  <span>
                    &nbsp;&nbsp;
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                    &nbsp;&nbsp;
                    {this.state.site && this.state.site.length > 0 ? (
                      <RRNavLink
                        to={
                          "/portal/sites/" +
                          this.state.siteItem.siteId +
                          "/dashboard"
                        }
                      >
                        <span className="sram-black-clr font-light font-18">
                          {this.state.siteItem.siteName} ({" "}
                          {DispenserMsgResProps.body.siteId.label} :{" "}
                          {this.state.siteItem.siteNumber})
                        </span>
                      </RRNavLink>
                    ) : null}
                  </span>
                  &nbsp;&nbsp;
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  &nbsp;&nbsp;
                  <span className="font-light font-18">
                    {this.state.dispenser && this.state.dispenser.length > 0
                      ? "Dispenser " +
                        this.state.dispenserItem.sideANumber +
                        "/" +
                        this.state.dispenserItem.sideBNumber
                      : null}
                  </span>
                  {/* </Col>
                <Col sm={12} md={6} lg={4}> */}
                  <UncontrolledDropdown className="sram-dispenser-pull-dropdown-right">
                    <DropdownToggle className="sram-dropdown-transparent">
                      <i className="fa fa-th sram-black-clr font-medium font-22"></i>
                    </DropdownToggle>

                    {this.state.dispensers &&
                    this.state.dispensers.length > 0 ? (
                      <DropdownMenu className="sram-sites-dropdown-menu">
                        {this.state.dispensers.map((dispenser, index) => {
                          return (
                            <RRNavLink
                              key={index}
                              to={
                                "/portal/sites/" +
                                this.state.siteItem.siteId +
                                "/dispensers/" +
                                dispenser.dispenserId +
                                "/dashboard"
                              }
                            >
                              <DropdownItem
                                className="sram-drop-down-item"
                                key={index}
                              >
                                <span className="sram-site-style sram-black-clr">
                                  {"Dispenser " +
                                    dispenser.sideANumber +
                                    "/" +
                                    dispenser.sideBNumber}
                                </span>
                              </DropdownItem>
                            </RRNavLink>
                          );
                        })}
                      </DropdownMenu>
                    ) : null}
                  </UncontrolledDropdown>
                  <div className="sram-dispenser-pull-dropdown-right">
                    {this.state.buttons.map((name, i) => {
                      return (
                        <Button
                          key={i}
                          type="submit"
                          className={
                            this.state.active === name.name
                              ? "btn btn-secondary sram-isbutton-active"
                              : "btn btn-secondary sram-not-active-button"
                          }
                          onClick={this.isButtonActive.bind(
                            this,
                            name.name,
                            name.days
                          )}
                        >
                          {name.name}
                        </Button>
                      );
                    })}
                    &nbsp;
                  </div>
                </Col>
              </div>
            </Row>
            <div className="sram-dispenser-content sram-page-top">
              <Row>
                <Col
                  sm={12}
                  md={12}
                  lg={
                    this.state.partPrivileges[
                      this._pcPortalViewRebootHistory
                    ] === true
                      ? 8
                      : 12
                  }
                  // className="sram-dispenser-section-alerts-max-width"
                >
                  {this.state.partPrivileges[this._pcPortalViewTickets] ===
                  true ? (
                    <DispenserAlerts
                      allAlerts={this.state.allAlerts}
                      isAllAlertsDataFetched={this.state.isAllAlertsDataFetched}
                      openTickets={this.state.openTickets}
                      closedTickets={this.state.closedTickets}
                      dispatchTickets={this.state.dispatchTickets}
                      alertEventData={this.state.alertEventData}
                      isAlertEventDataFetched={
                        this.state.isAlertEventDataFetched
                      }
                    />
                  ) : null}
                </Col>
                <Col sm={12} md={4} lg={4}>
                  <DispenserWarmStartReboot
                    showRebootHistory={
                      this.state.partPrivileges[this._pcPortalViewRebootHistory]
                    }
                    siteItem={this.state.siteItem}
                    dispenserItem={this.state.dispenserItem}
                    isPageDataFetched={this.state.isRebootHistoryDataFetched}
                    rebootHistory={this.state.rebootHistory}
                    showReboot={
                      this.state.partPrivileges[this._pcPortalPerformReboot]
                    }
                    getRebootHistory={this.getRebootHistory.bind(this)}
                    propsHistory={this.props}
                    cancelToken={this._cancelToken}
                  />
                </Col>
                {/* <Col sm={12} md={4} lg={4}>
                  {this.state.dispenserItem &&
                  this.state.dispenserItem.deviceVersion === 2 ? (
                    <DispenserRebootV2
                      showRebootHistory={
                        this.state.partPrivileges[
                          this._pcPortalViewRebootHistory
                        ]
                      }
                      siteItem={this.state.siteItem}
                      dispenserItem={this.state.dispenserItem}
                      isPageDataFetched={this.state.isRebootHistoryDataFetched}
                      rebootHistory={this.state.rebootHistory}
                      performReboot={
                        this.state.partPrivileges[this._pcPortalPerformReboot]
                      }
                      getRebootHistory={this.getRebootHistory.bind(this)}
                      propsHistory={this.props}
                      cancelToken={this._cancelToken}
                    />
                  ) : (
                    <DispenserReboot
                      showRebootHistory={
                        this.state.partPrivileges[
                          this._pcPortalViewRebootHistory
                        ]
                      }
                      siteItem={this.state.siteItem}
                      dispenserItem={this.state.dispenserItem}
                      isPageDataFetched={this.state.isRebootHistoryDataFetched}
                      rebootHistory={this.state.rebootHistory}
                      performReboot={
                        this.state.partPrivileges[this._pcPortalPerformReboot]
                      }
                      getRebootHistory={this.getRebootHistory.bind(this)}
                      propsHistory={this.props}
                      cancelToken={this._cancelToken}
                    />
                  )}
                </Col> */}
                <Col sm={12} md={12} lg={12}>
                  {this.state.partPrivileges[
                    this._pcPortalViewComponentFailureData
                  ] === true ? (
                    <DispenserComponentFailure
                      showComponentFailure={
                        this.state.partPrivileges[
                          this._pcPortalViewComponentFailureData
                        ]
                      }
                      showCardReader={
                        this.state.partPrivileges[
                          this._pcPortalViewCrindCardReader
                        ]
                      }
                      showDisplay={
                        this.state.partPrivileges[
                          this._pcPortalViewCrindDisplay
                        ]
                      }
                      showPrinter={
                        this.state.partPrivileges[
                          this._pcPortalViewCrindPrinter
                        ]
                      }
                      showUPM={
                        this.state.partPrivileges[this._pcPortalViewCrindUPM]
                      }
                      showSSOM={
                        this.state.partPrivileges[this._pcPortalViewCrindSSOM]
                      }
                      showGSOM={
                        this.state.partPrivileges[this._pcPortalViewCrindGSOM]
                      }
                      siteItem={this.state.siteItem}
                      dispenserItem={this.state.dispenserItem}
                      isPageDataFetched={this.state.iscomponentsDataFetched}
                      componentsData={this.state.componentsData}
                      cardReader={this.state.componentCardReader}
                      printer={this.state.componentPrinter}
                      upm={this.state.componentUPM}
                      ssom={this.state.componentSSOM}
                      gsom={this.state.componentGSOM}
                      display={this.state.componentDisplay}
                      encorePump={this.state.componentEncorePump}
                      propsHistory={this.props}
                      cancelToken={this._cancelToken}
                    />
                  ) : null}
                </Col>

                {/* <Col sm={12} md={12} lg={12}>
                  {(this.state.partPrivileges[this._pcPortalViewErrorLogs] ===
                    true ||
                    this.state.partPrivileges[
                      this._pcPortalViewErrorLogsHistory
                    ] === true) &&
                  this.state.dispenser &&
                  this.state.dispenser.length > 0 ? (
                    <DispenserErrorLogs
                      showErrorLogs={
                        this.state.partPrivileges[this._pcPortalViewErrorLogs]
                      }
                      showRecentErrorLogs={
                        this.state.partPrivileges[
                          this._pcPortalViewErrorLogsHistory
                        ]
                      }
                      installTime={
                        this.state.dispenserItem.deviceInstalltionTimestamp
                      }
                      recentCriticalLength={this.state.recentCriticalLength}
                      loading={this.state.recentCriticalLoading}
                      recentNonCriticalLength={
                        this.state.recentNonCriticalLength
                      }
                      nonCriticalLoading={this.state.recentNonCriticalLoading}
                      getRecentCriticalLoadMore={this.getRecentCriticalLoadMore.bind(
                        this
                      )}
                      getRecentNonCriticalLoadMore={this.getRecentNonCriticalLoadMore.bind(
                        this
                      )}
                      dispenser={this.state.dispenserItem}
                      site={this.state.siteItem}
                      critical={this.state.criticalErrorLogsRecent}
                      iscriticalPageDataFetched={
                        this.state.isCriticalErrorLogsRecentFetched
                      }
                      nonCritical={this.state.nonCriticalErrorLogsRecent}
                      isPageDataFetched={
                        this.state.isNonCriticalErrorLogsRecentFetched
                      }
                      liveCriticalHistory={
                        this.state.liveCriticalErrorLogsHistory
                      }
                      isHistoryLivePageDataFetched={
                        this.state.isLiveCriticalErrorLogsHistoryFetched
                      }
                      nonCriticalHistory={
                        this.state.nonCriticalErrorLogsHistory
                      }
                      isHistoryPageDataFetched={
                        this.state.isNonCriticalErrorLogsHistoryFetched
                      }
                      historyCriticalLength={this.state.historyCriticalLength}
                      criticalHistoryLoading={this.state.historyCriticalLoading}
                      getHistoryCriticalLoadMore={this.getHistoryCriticalLoadMore.bind(
                        this
                      )}
                      historyNonCriticalLength={
                        this.state.historyNonCriticalLength
                      }
                      nonCriticalHistoryLoading={
                        this.state.historyNonCriticalLoading
                      }
                      getHistoryNonCriticalLoadMore={this.getHistoryNonCriticalLoadMore.bind(
                        this
                      )}
                      showErrorCodesModal={
                        this.state.partPrivileges[this._pcSiteViewErrorModal]
                      }
                    />
                  ) : null}
                </Col> */}

                <Col
                  sm={12}
                  md={12}
                  lg={12}
                  // className="sram-dispenser-section-alerts-max-width"
                >
                  {this.state.partPrivileges[this._pcPortalViewPCNAlerts] ===
                  true ? (
                    <DispenserPCNAlerts
                      alertEventData={this.state.pcnAlertEventData}
                      isAlertEventDataFetched={
                        this.state.isPCNAlertEventDataFetched
                      }
                      siteId={this.props.match.params.sid}
                      dispenserId={this.props.match.params.did}
                      reloadAlertsData={() => this.reloadAlertsTabs()}
                      createAlertsView={
                        this.state.partPrivileges[
                          this._pcPortalPCNCreateAlertsView
                        ]
                      }
                    />
                  ) : null}
                </Col>

                <Col sm={12} md={12} lg={12}>
                  <DispenserCustomerExperience
                    showFlowRates={
                      this.state.partPrivileges[this._pcPortalViewCEFlowRate]
                    }
                    showOverRuns={
                      this.state.partPrivileges[
                        this._pcPortalViewCustomerExperienceOverrunData
                      ]
                    }
                    showPaperOut={
                      this.state.partPrivileges[this._pcPortalViewCEPaperO]
                    }
                    // sideAFlowRates={this.state.sideAFlowRates}
                    // sideBFlowRates={this.state.sideBFlowRates}
                    // isPageDataFetched={this.state.isFlowRatesFetched}
                    // isOverRunsFetched={this.state.isPresetOverRunsFetched}
                    // overRuns={this.state.presetOverRuns}
                    showPrinterJammed={
                      this.state.partPrivileges[this._pcPortalViewCEPrinterJam]
                    }
                    paperOutB={this.state.printerPaperOutB}
                    paperOutA={this.state.printerPaperOutA}
                    isPrinterJammed={this.state.isPrinterJammedDataFetched}
                    isPaperOut={this.state.isPaperOutDataFetched}
                    // flowSnapshotLoading={this.state.isFlowSnapshotDataFetched}
                    // sideAFlowSnapshot={this.state.sideAFlowSnapshot}
                    // sideBFlowSnapshot={this.state.sideBFlowSnapshot}
                    dispenserItem={this.state.dispenserItem}
                    //for standalone data
                    isDoorAlertsPageDataFetched={
                      this.state.isDoorAlertsDataFetched
                    }
                    showDoorAlerts={
                      this.state.partPrivileges[this._pcPortalViewDoorAlerts]
                    }
                    showStandAlone={
                      this.state.partPrivileges[
                        this._pcPortalViewStandaloneAlerts
                      ]
                    }
                    isStandAlonesFetched={this.state.isStandAlonesFetched}
                    doorAlerts={this.state.doorAlerts}
                    standAlones={this.state.standAlones}
                    // transaction peak Flow
                    showPeakFlowRates={
                      this.state.partPrivileges[
                        this._pcPortalViewTransactionData
                      ]
                    }
                    transactionData={this.state.transactionFlow}
                    transactionFlowAxis={this.state.transactionFlowAxis}
                    transactionPeakFlowA={this.state.transactionPeakFlowA}
                    transactionPeakFlowB={this.state.transactionPeakFlowB}
                    isPageDataFetched={this.state.istransactionFlowDataFetched}
                    // No-Transaction Status
                    showNoTransaction={
                      this.state.partPrivileges[this._pcPortalViewCENoTrans]
                    }
                    isNoTransactionDataFetched={
                      this.state.isNoTransactionDataFetched
                    }
                    noTransactionSideAData={
                      this.state.noTransactionSideAPageRecords
                    }
                    noTransactionSideBData={
                      this.state.noTransactionSideBPageRecords
                    }
                    handleSortChangeSideA={() => this.handleSortChangeSideA}
                    handleSortChangeSideB={() => this.handleSortChangeSideB}
                    sortedColumnA={this.state.sortedColumnA}
                    sortedOrderA={this.state.sortedOrderA}
                    sortedColumnB={this.state.sortedColumnB}
                    sortedOrderB={this.state.sortedOrderB}
                    flowRatesAlertsData={this.state.flowRatesAlertsData}
                    noTransactionAlertsData={this.state.noTransactionAlertsData}
                    isFlowRatesAlertsDataFetched={
                      this.state.isFlowRatesAlertsDataFetched
                    }
                  />

                  {/* Transaction Data */}
                  {/* {this.state.partPrivileges[
                    this._pcPortalViewTransactionData
                  ] === true ? (
                    <DispenserTransactionData
                      transactionData={this.state.transaction}
                      isPageDataFetched={this.state.istransactionDataFetched}
                    />
                  ) : null} */}

                  {/* Transaction Data */}
                  {/* {this.state.partPrivileges[
                    this._pcPortalViewTransactionData
                  ] === true ? (
                    <DispenserTransactionFlow
                      transactionData={this.state.transactionFlow}
                      transactionPeakFlowA= {this.state.transactionPeakFlowA}
                      transactionPeakFlowB= {this.state.transactionPeakFlowB}
                      isPageDataFetched={this.state.istransactionFlowDataFetched}
                    />
                  ) : null} */}

                  {this.state.partPrivileges[
                    this._pcPortalViewDetailTechnicalData
                  ] === true ||
                  this.state.partPrivileges[
                    this._pcPortalViewBriefTechnicalData
                  ] === true ? (
                    <DispenserTechnicalDetails
                      isPageDataFetched={this.state.isTechnicalDetailsFetched}
                      technicalDeatils={this.state.technicalDeatils}
                      data={this.state.technicalData}
                      dispenser={this.state.dispenserItem}
                      breifTechinicalDetails={
                        this.state.partPrivileges[
                          this._pcPortalViewBriefTechnicalData
                        ]
                      }
                      detailedTechinicalDetails={
                        this.state.partPrivileges[
                          this._pcPortalViewDetailTechnicalData
                        ]
                      }
                      technicalDetailsData={this.state.technicalDetailsData}
                    />
                  ) : null}

                  {/* {this.state.partPrivileges[
                    this._pcPortalViewMeterVolumeData
                  ] === true ||
                  this.state.partPrivileges[this._pcPortalViewPriceData] ===
                    true ? (
                    <DispenserVolume
                      showVolumeData={
                        this.state.partPrivileges[
                          this._pcPortalViewMeterVolumeData
                        ]
                      }
                      showPriceData={
                        this.state.partPrivileges[this._pcPortalViewPriceData]
                      }
                      isPageMeterDataFetched={this.state.isMeterDataFetched}
                      meterVolume={this.state.meterVolume}
                      isPagePriceDataFetched={this.state.isPriceDataFetched}
                      prices={this.state.prices}
                    />
                  ) : null} */}

                  {/* Prices Data */}
                  {/* {this.state.partPrivileges[this._pcPortalViewPriceConfig] ===
                  true ? (
                    <DispenserPricesData
                      pricesData={this.state.pricesConfigData}
                      isPageDataFetched={this.state.isPricesConfigDataFetched}
                    />
                  ) : null} */}
                </Col>
                {/* <Col sm={12} md={12} lg={12}>
                  <DispenserSensorData
                    isPageDataFetched={this.state.isSensorDataFetched}
                    showTemp={
                      this.state.partPrivileges[this._pcPortalViewTemaparature]
                    }
                    showHumidity={
                      this.state.partPrivileges[this._pcPortalViewHumidity]
                    }
                    sensorData={this.state.sensorData}
                  />
                </Col> */}
              </Row>
            </div>
          </div>
        </WithLoading>
      </>
    );
  }
}
export default DispenserDashboard;
