import AppUtils from '../../core/helpers/app-utils';

export default class ChartOptions {
  static getPriceOptions(data, name) {
    const date = data.map((grade) => {
      return grade.dataDate;
    });

    const options = {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "Date",
              headerValue: "value",
              dateFormatter(timestamp) {
                return AppUtils.getDate(timestamp);
              },
            },
          },
          autoSelected: "zoom",
        },
        id: name
          .replace(/[^\w\s]/gi, "")
          .split(" ")
          .join(""),
        animations: {
          easing: "easeinout",
        },
      },
      title: {
        align: "left",
        margin: 10,
        style: {
          fontSize: "14px",
          color: "#6E8192",
        },
      },
      grid: {
        row: {
          colors: ["#e9ecef", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type: "datetime",
        categories: date,
        labels: {
          format: "MM/dd",
        },
      },
      yaxis: {
        min: 1,
        labels: {
          show: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          show: true,
          format: "MM/dd/yyyy",
        },
      },
      colors: ["#398bf7", "#FF4500"],
      // stroke: {
      //   curve: "straight",
      //   width: "4",
      // },
    };
    return options;
  }

  static getMeterOptions(data, name) {
    const date = data.map((meter) => {
      return meter.dataDate;
    });

    const options = {
      colors: ["#745af2", "#263238", "#4fc3f7"],
      chart: {
        id: name
          .replace(/[^\w\s]/gi, "")
          .split(" ")
          .join(""),

        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "Date",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toLocaleDateString();
              },
            },
          },
          autoSelected: "zoom",
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          // distributed: true
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          show: true,
          format: "MM/dd/yyyy",
        },
        y: {
          formatter: function (val) {
            return val + " Gallons";
          },
        },
      },
      // stroke: {
      //   show: true,
      //   width: 3,
      //   colors: ["transparent"],
      // },
      xaxis: {
        type: "datetime",
        categories: date,
        labels: {
          format: "MM/dd",
        },
      },
      yaxis: {
        labels: {
          show: true,
        },
        title: {
          text: "Gallons",
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: true,
      },
    };
    return options;
  }
  static getSensorTempOptions(data) {
    const date = data.map((obj) => {
      return obj.deviceTimestamp;
    });
    const options = {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "DateTime",
              headerValue: "value",
              dateFormatter(timestamp) {
                return AppUtils.getDateTime(timestamp);
              },
            },
          },
          autoSelected: "zoom",
        },
        id: "Temperature",
        animations: {
          easing: "easeinout",
        },
      },
      title: {
        align: "left",
        margin: 10,
        style: {
          fontSize: "14px",
          color: "#6E8192",
        },
      },
      grid: {
        row: {
          colors: ["#e9ecef", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type: "datetime",
        categories: date,
        tickAmount: 3,
        labels: {
          format: "MM/dd",
        },
      },
      yaxis: {
        // min: 0,
        // max:150,
        labels: {
          show: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          show: true,
          formatter: (function(value){
            return AppUtils.getDateTime(value);
        }),
          format: "MM/dd/yyyy hh:mm:ss",
        },
      },
      colors: ["#398bf7", "#FF4500"],
      // stroke: {
      //   curve: "straight",
      //   width: "3",
      // },
    };
    return options;
  }

  static getSensorHumidityOptions(data) {
    const date = data.map((obj) => {
      return obj.deviceTimestamp;
    });
    const options = {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "DateTime",
              headerValue: "value",
              dateFormatter(timestamp) {
                return AppUtils.getDateTime(timestamp);
              },
            },
          },
          autoSelected: "zoom",
        },
        id: "Humidity",
        animations: {
          easing: "easeinout",
        },
      },
      title: {
        align: "left",
        margin: 10,
        style: {
          fontSize: "14px",
          color: "#6E8192",
        },
      },
      grid: {
        row: {
          colors: ["#e9ecef", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type: "datetime",
        categories: date,
        tickAmount: 3,
        labels: {
          format: "MM/dd",
        },
      },
      yaxis: {
        // min: 0,
        // max:150,
        labels: {
          show: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          show: true,
          formatter: (function(value){
            return AppUtils.getDateTime(value);
        }),
          format: "MM/dd/yyyy hh:mm:ss",
        },
      },
      colors: ["#398bf7", "#FF4500"],
      // stroke: {
      //   curve: "straight",
      //   width: "3",
      // },
    };
    return options;
  }
  // flowrates

  static getFlowRateOptions(data, name) {
    const date = data.map((obj) => {
      return obj.deviceTimestamp;
    });
    // let unique = [];
    // date.forEach((element) => {
    //   if (!unique.includes(element)) {
    //     unique.push(element);
    //     return unique;
    //   }
    // });
    const options = {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "DateTime",
              headerValue: "value",
              dateFormatter(timestamp) {
                return AppUtils.getDateTime(timestamp);
              },
            },
          },
          autoSelected: "zoom",
        },
        id: name
          .replace(/[^\w\s]/gi, "")
          .split(" ")
          .join(""),
        animations: {
          easing: "easeinout",
        },
      },
      title: {
        align: "left",
        margin: 10,
        style: {
          fontSize: "14px",
          color: "#6E8192",
        },
      },
      grid: {
        row: {
          colors: ["#e9ecef", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type: "datetime",
        categories: date,
        tickAmount: 6,
        labels: {
          format: "MM/dd",
        },
      },
      yaxis: {
        // min: 0,
        labels: {
          show: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          show: true,
          format: "MM/dd/yyyy hh:mm:ss",
        },
      },
      colors: [
        "#398bf7",
        "#FF4500",
        "#775DD0",
        "#ECBD42",
        "#25B3CA",
        "#3D5AF3",
      ],
      // stroke: {
      //   curve: "straight",
      // },
    };
    return options;
  }

  static getFlowDataOptions(data, name, dispenser, axis) {
    const date = data.map((obj) => {
      return obj.dataDate;
    });
    let unique = [];
    date.forEach((element) => {
      if (!unique.includes(element)) {
        unique.push(element);
        return unique;
      }
    });
    const options = {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            followCursor: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "Date",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toLocaleDateString();
              },
            },
          },
          autoSelected: "zoom",
        },
        id: name
          .replace(/[^\w\s]/gi, "")
          .split(" ")
          .join(""),
        animations: {
          easing: "easeinout",
        },
      },
      title: {
        align: "left",
        margin: 10,
        style: {
          fontSize: "14px",
          color: "#6E8192",
        },
      },
      grid: {
        row: {
          colors: ["#e9ecef", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type: "datetime",
        categories: unique,
        labels: {
          format: "MM/dd",
        },
      },
      yaxis: {
        min: dispenser?.dispenserFlowType === 0 ? 0 : axis?.min_value,
        max: dispenser?.dispenserFlowType === 0 ? 12 : axis?.max_value,
        tickAmount: dispenser?.dispenserFlowType === 0 ? 6 : 10,
        labels: {
          show: true,
          formatter: function (val) {
            return val.toFixed(0)
          }
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          show: true,
          format: "MM/dd/yyyy",
        },
      },
      colors: [
        "#386c98",
        "#64a41c",
        "#775DD0",
        "#e4ac04",
        "#25B3CA",
        "#3D5AF3",
      ],
      annotations: {
        xaxis: [{
          label: {
              mouseEnter: '#fff',
              mouseLeave: '#fff',
          },
        }],        
        yaxis: [
          {
            y: dispenser?.dispenserFlowRate,
            borderColor: '#646464',
            width: '100%',
            strokeDashArray: 0,
            label: {
              borderColor: '#646464',
              style: {
                color: '#fff',
                background: '#646464'
              },
              mouseEnter: '#fff',
              mouseLeave: '#fff',
            },            
          },
          // {
          //   y: 6,
          //   borderColor: '#646464',
          //   width: '100%',
          //   strokeDashArray: 0,
          //   label: {
          //     borderColor: '#646464',
          //     style: {
          //       color: '#fff',
          //       background: '#646464'
          //     },
          //     mouseEnter: '#fff',
          //     mouseLeave: '#fff',
          //   },            
          // }
        ]
      },
      // stroke: {
      //   curve: "straight",
      // },
    };
    return options;
  }



  static getOverRunsOptions(data) {
    const date = data.sideA.map((obj) => {
      return obj.dataDate;
    });

    const options = {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "Date",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toLocaleDateString();
              },
            },
          },
          autoSelected: "zoom",
        },
        id: "Overrun",
        animations: {
          easing: "easeinout",
        },
      },
      title: {
        align: "left",
        margin: 10,
        style: {
          fontSize: "14px",
          color: "#6E8192",
        },
      },
      grid: {
        row: {
          colors: ["#e9ecef", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type: "datetime",
        categories: date,
        tickAmount: 3,
        labels: {
          format: "MM/dd",
        },
      },
      yaxis: {
        // min: 0,
        labels: {
          show: true,
          // formatter: (value) => { return value },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          show: true,
          format: "MM/dd/yyyy",
        },
      },
      colors: ["#398bf7", "#FF4500"],
      // stroke: {
      //   curve: "straight",
      //   width: "4",
      // },
    };
    return options;
  }

  static getStandAloneOptions(data) {

    const date = data.sideA.map((obj) => {
      return obj.dataDate;
    });

    const options = {
      colors: ["#398bf7", "#FF4500"],
      chart: {
        id: "Standalone",

        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "Date",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toLocaleDateString();
              },
            },
          },
          autoSelected: "zoom",
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50%",
          // distributed: true
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          show: true,
          format: "MM/dd/yyyy",
        },
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      xaxis: {
        type: "datetime",
        categories: date,
        labels: {
          format: "MM/dd",
        },
      },
      yaxis: {
        labels: {
          show: true,
        },
        title: {
          text: "",
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        show: true,
      },
    };
    return options;
  }

  static getPriceChangesData(data, name) {
    const date = data.map((grade) => {
      return grade.dataDate;
    });

    const options = {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          // showForSingleSeries: true,
          // showForNullSeries: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ",",
              headerCategory: "DateTime",
              headerValue: "value",
              dateFormatter(timestamp) {
                return AppUtils.getDateTimeFormat(timestamp);
              },
            },
          },
          autoSelected: "zoom",
        },
        id: name
          .replace(/[^\w\s]/gi, "")
          .split(" ")
          .join(""),
        animations: {
          easing: "easeinout",
        },
      },
      title: {
        align: "left",
        margin: 10,
        style: {
          fontSize: "14px",
          color: "#6E8192",
        },
      },
      grid: {
        row: {
          colors: ["#e9ecef", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        type:"datetime",
        categories: date,
     
        labels: {
          showDuplicates: false,
          // format: "MM/dd",
          format: "MM/dd/yyyy",
        },
      },
      yaxis: {
        min: 1,
        labels: {
          show: true,        
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        x: {
          show: true,
          formatter: (function(value){
            return AppUtils.getDateTime(value);
        }),
          format: "MM/dd/yyyy hh:mm:ss",
        },
        
      },
      colors: ["#398bf7", "#FF4500"],
      // stroke: {
      //   curve: "straight",
      //   width: "4",
      // },
    };
    return options;
  }

}
