import axios from "axios";
import { includes } from "lodash";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";
import SiteUtils from "../../portal/helpers/site-utils";

const actionFileName = "service-provider-actions.js";

// List of Service Provider Companies
export function listCompanies(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "";
    if (queryData) {
      queryParams = "?" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "service-providers/companies" +
          queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listCompanies()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Add Service Provider Company
export function addCompany(companyObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "service-providers/companies",
        companyObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">addCompany()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Get Service Provider Company
export function getCompany(organizationId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "service-providers/companies/" +
          organizationId,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getCompany()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Service Provider Edit Company
export function editCompany(companyObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix +
          "service-providers/companies/" +
          companyObj.organizationId,
        companyObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">editCompany()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// List of Service Provider Divisions
export function listDivisions(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "";
    if (queryData) {
      queryParams = "?" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "service-providers/divisions" +
          queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listDivisions()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Add Service Provider Division
export function addDivision(divisionObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "service-providers/divisions",
        divisionObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">addDivision()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Get Service Provider Division
export function getDivision(organizationId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "service-providers/divisions/" +
          organizationId,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getDivision()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Service Provider Edit Division
export function editDivision(divisionObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix +
          "service-providers/divisions/" +
          divisionObj.organizationId,
        divisionObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">editDivision()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// List of Service Provider Sites
export function listSites(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "";
    if (queryData) {
      queryParams = "?" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
          "service-providers/sites" +
          queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">listSites()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Get Service Provider Site
export function getSite(siteId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "service-providers/sites/" + siteId,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getSite()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// List of Users assigned to site
export function getSiteUsers(siteId, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "sites/" + siteId + "/users",
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">getSiteUsers()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Update Site Users
export function editSiteUsers(siteUsersObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix +
          "sites/" +
          siteUsersObj.siteId +
          "/users",
        siteUsersObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">editSiteUsers()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Search User Site Access
export function searchUsersSites(list, searchKeyword) {
  return new Promise(async function (resolve, reject) {
    if (
      list &&
      list.length > 0 &&
      searchKeyword &&
      searchKeyword.trim().length > 0
    ) {
      let searchRecords = list.filter((user) => {
        return (
          includes(
            user.username.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            user.email.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            user.firstName.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            user.lastName.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          )
        );
      });

      resolve(searchRecords);
    } else {
      resolve(list);
    }
  });
}

// Add Service Provider Site
export function addSite(siteObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "service-providers/sites",
        siteObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">addSite()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Service Provider Edit Site
export function editSite(siteObj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .put(
        AppConfigProps.serverRoutePrefix +
          "service-providers/sites/" +
          siteObj.siteId,
        siteObj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">editSite()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Search Service Company
export function searchOrgCompanyList(list, searchKeyword) {
  return new Promise(async function (resolve, reject) {
    if (
      list &&
      list.length > 0 &&
      searchKeyword &&
      searchKeyword.trim().length > 0
    ) {
      let searchRecords = list.filter((org) => {
        return includes(
          org.organizationName.toLowerCase(),
          searchKeyword.trim().toLowerCase()
        );
      });

      resolve(searchRecords);
    } else {
      resolve(list);
    }
  });
}

// Search Service Division
export function searchOrgDivisionList(list, searchKeyword) {
  return new Promise(async function (resolve, reject) {
    if (
      list &&
      list.length > 0 &&
      searchKeyword &&
      searchKeyword.trim().length > 0
    ) {
      let searchRecords = list.filter((org) => {
        return includes(
          org.organizationTreepath.toLowerCase(),
          searchKeyword.trim().toLowerCase()
        );
      });

      resolve(searchRecords);
    } else {
      resolve(list);
    }
  });
}

// Search Service Sites
export function searchOrgSiteList(list, searchKeyword) {
  return new Promise(async function (resolve, reject) {
    if (
      list &&
      list.length > 0 &&
      searchKeyword &&
      searchKeyword.trim().length > 0
    ) {
      let searchRecords = list.filter((org) => {
        const siteAddress = SiteUtils.getSiteAddress(org);
        const siteAddress1 = SiteUtils.getSiteAddress1(org);
        return (
          includes(
            org.siteNumber.toString(),
            searchKeyword.trim().toString()
          ) ||
          includes(
            org.organizationTreepath.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            org.siteName.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            org.streetAddress1.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            org.streetAddress2.toLowerCase(),
            searchKeyword.trim().toLowerCase()
          ) ||
          includes(
            siteAddress.toString().toLowerCase() +
              "," +
              siteAddress1.toString().toLowerCase(),
            searchKeyword.trim().toLowerCase()
          )
        );
      });

      resolve(searchRecords);
    } else {
      resolve(list);
    }
  });
}

// Search Query Param sites Based on Companies or Divisions
export function searchOrgQuerySiteList(list, searchKeyword) {
  return new Promise(async function (resolve, reject) {
    if (
      list &&
      list.length > 0 &&
      searchKeyword &&
      searchKeyword.trim().length > 0
    ) {
      let searchRecords = list.filter((org) => {
        return includes(
          org.organizationTreepath.toLowerCase(),
          searchKeyword.trim().toLowerCase()
        );
      });

      resolve(searchRecords);
    } else {
      resolve(list);
    }
  });
}

// // Get Service Provider Dispensers Flow Rate
// export function getDispenserFlowRate(organizationId, cancelToken) {
//   return new Promise(function (resolve, reject) {
//     axios
//       .get(
//         AppConfigProps.serverRoutePrefix +
//           "configreset/" +
//           organizationId,
//         cancelToken
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         if (axios.isCancel(err)) {
//           logMessage("warn", actionFileName + ">getDispenserFlowRate()", err.message);
//         } else {
//           reject(err.response);
//         }
//       });
//   });
// }

// // Reset Service Provider Dispensers Flow Rate
// export function resetDispenserFlowRate(organizationId, cancelToken) {
//   return new Promise(function (resolve, reject) {
//     axios
//       .put(
//         AppConfigProps.serverRoutePrefix +
//           "configreset/" +
//           organizationId,
//         cancelToken
//       )
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         if (axios.isCancel(err)) {
//           logMessage("warn", actionFileName + ">resetDispenserFlowRate()", err.message);
//         } else {
//           reject(err.response);
//         }
//       });
//   });
// }