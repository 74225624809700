import axios from "axios";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";
import { AppURLProps } from "../../core/settings/app-urls";
import AppUtils from "../../core/helpers/app-utils";
import { refreshToken } from "../../core/actions/identity-actions";
// import { Redirect } from "react-router-dom";

const actionFileName = "actions.js";


// token expiry check
export function checkToken(props, cancelToken) {
  const tokenData = AppUtils.getIdentityTokenData();
  if (
    tokenData &&
    tokenData.userUUID &&
    tokenData.username &&
    tokenData.email &&
    tokenData.tokenExpiry >= Date.now() / 1000
  ) {
    AppUtils.setRequestHeaders();

    if (
      tokenData.tokenExpiry - parseInt(Date.now() / 1000) <=
      AppConfigProps.identitySession.expiryCheckRemainingSeconds
    ) {
      refreshToken(cancelToken);
    }

    // return props
  } else {
    AppUtils.removeIdentityToken();
    props.history.push(AppURLProps.login);
    // return (
    //   <Redirect
    //     to={{
    //       pathname: AppURLProps.login,
    //       state: { from: props.location },
    //     }}
    //   />
    // );
  }
}


// ErrorCode PossibleFixes
export function possibleFixes(
  siteId,
  dispenserId,
  pumpErrorLogId,
  cancelToken
) {
  return new Promise(function (resolve, reject) {
    axios
      .get(
        AppConfigProps.serverRoutePrefix +
        "portal/sites/" +
        siteId +
        "/dispensers/" +
        dispenserId +
        "/pump-errors/" +
        pumpErrorLogId,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">PossibleFixes()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}

// Feedback
export function feedBack(obj, cancelToken) {
  return new Promise(function (resolve, reject) {
    axios
      .post(
        AppConfigProps.serverRoutePrefix + "portal/error-codes/feedback",
        obj,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage("warn", actionFileName + ">addRole()", err.message);
        } else {
          reject(err.response);
        }
      });
  });
}
