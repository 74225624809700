import React, { Component } from "react";
import { getApiUrl, getApiKey } from "../../core/actions/common-actions";

class SitesMap extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.loadGoogleMap();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.sitesLength !== this.props.sitesLength){
      const map = new window.google.maps.Map(
        document.getElementById("sites-map"),
        {
          zoom: 8,
          disableDefaultUI: true,
        }
      );
      this.props.onMapLoad(map);
    }
  }

  loadGoogleMap = () => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `${getApiUrl("google-maps")}?key=${getApiKey(
        "google-maps"
      )}&libraries=places`;
      var scriptItem = document.getElementsByTagName("script")[0];
      scriptItem.parentNode.insertBefore(script, scriptItem);

      // Below is important.
      //We cannot access google.maps until it's finished loading
      script.addEventListener("load", (e) => {
        this.onScriptLoad();
      });
    } else {
      this.onScriptLoad();
    }
  };

  onScriptLoad() {
    const map = new window.google.maps.Map(
      document.getElementById("sites-map"),
      {
        zoom: 8,
        disableDefaultUI: true,
      }
    );

    this.initMapTypeControl(map);
    this.initZoomControl(map);

    this.props.onMapLoad(map);
  }

  initZoomControl = (map) => {
    document.querySelector(".zoom-control-in").onclick = () => {
      map.setZoom(map.getZoom() + 1);
    };

    document.querySelector(".zoom-control-out").onclick = () => {
      map.setZoom(map.getZoom() - 1);
    };
    map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
      document.querySelector(".zoom-control")
    );
  };

  initMapTypeControl = (map) => {
    const mapTypeControlDiv = document.querySelector(".maptype-control");

    document.querySelector(".maptype-control-map").onclick = () => {
      mapTypeControlDiv.classList.add("maptype-control-is-map");
      mapTypeControlDiv.classList.remove("maptype-control-is-satellite");
      map.setMapTypeId("roadmap");
    };

    document.querySelector(".maptype-control-satellite").onclick = () => {
      mapTypeControlDiv.classList.remove("maptype-control-is-map");
      mapTypeControlDiv.classList.add("maptype-control-is-satellite");
      map.setMapTypeId("hybrid");
    };
    map.controls[window.google.maps.ControlPosition.RIGHT_TOP].push(
      mapTypeControlDiv
    );
  };

  render() {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
          id="sites-map"
        />
        <div style={{ display: "none" }}>
          <div className="controls zoom-control">
            <button className="zoom-control-in" title="Zoom In">
              +
            </button>
            <button className="zoom-control-out" title="Zoom Out">
              −
            </button>
          </div>
          <div className="controls maptype-control maptype-control-is-map">
            <button className="maptype-control-map" title="Show road map">
              Map
            </button>
            <button
              className="maptype-control-satellite"
              title="Show satellite imagery"
            >
              Satellite
            </button>
          </div>
          <div className="controls fullscreen-control">
            <button title="Toggle Fullscreen">
              <div className="fullscreen-control-icon fullscreen-control-top-left"></div>
              <div className="fullscreen-control-icon fullscreen-control-top-right"></div>
              <div className="fullscreen-control-icon fullscreen-control-bottom-left"></div>
              <div className="fullscreen-control-icon fullscreen-control-bottom-right"></div>
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default SitesMap;
