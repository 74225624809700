import React, { Component } from "react";
import {
  Card,
  FormGroup,
  Label,
  Row,
  Col,
  Button,
  Input,
  Table,
  Spinner,
  Collapse,
} from "reactstrap";
// import classnames from "classnames";
import AppUtils from "../../core/helpers/app-utils";
import axios from "axios";
import Select from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";
import WithLoading from "../../core/helpers/with-loading";
import PageUtils from "../../core/helpers/page-utils";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { exportReports } from "../actions/current-message-status-actions";
import { AppConfigProps } from "../../core/settings/app-config";
import { manageError } from "../../core/actions/common-actions";
import { CoreReportResProps } from "../messages/current-message-status-properties";
import Switch from "react-switch";
import ConfirmModal from "../../core/helpers/confirm-modal";
import { Modal, ModalBody } from "reactstrap";

class CoreReportsForm extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.state = {
      isOpenScheduleEnableDisableConfirmModal: false,
      tempScheduleEnableDisableObj: null,
      scheduleStatus: null,
      status: null,
      isOpenScheduleDeleteConfirmModal: false,
      tempScheduleDeleteObj: null,
      isOpenSitesModel: false,
      reportData: null,
      collapse: false,
      isOpenScheduleViewMoreModel: false,
      scheduleCollapse: false,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    window.appHistory = this.props.history;
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {}

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
    clearInterval(this.interval);
  }

  downloadReport = async (s3Name) => {
    this.getExportReports(s3Name);
  };

  getExportReports = async (s3Name) => {
    const queryData = s3Name;
    await exportReports(queryData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          if (res.data.records.length > 0) {
            window.open(res.data.records);
          }
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
  };

  handleChange = async (ruleName, reportsUUID, checked) => {
    let status = null;
    if (checked) {
      status = "Disable";
      await this.setStateAsync({ scheduleStatus: 0 });
    } else {
      status = "Enable";
      await this.setStateAsync({ scheduleStatus: 1 });
    }
    await this.setStateAsync({ status: status });
    const scheduleObj = {
      ruleName: ruleName,
      reportsUUID: reportsUUID,
    };
    await this.setStateAsync({ tempScheduleEnableDisableObj: scheduleObj });
    this.openScheduleEnableDisableConfirmModal();
  };

  closeScheduleEnableDisableConfirmModal = async () => {
    await this.setStateAsync({
      isOpenScheduleEnableDisableConfirmModal: false,
    });
  };

  openScheduleEnableDisableConfirmModal = async () => {
    await this.setStateAsync({
      isOpenScheduleEnableDisableConfirmModal: true,
    });
  };

  handleScheduleEnableDisableSubmit = async () => {
    await this.props.handleScheduleStatusSubmit(
      this.state.tempScheduleEnableDisableObj,
      this.state.status
    );
    this.closeScheduleEnableDisableConfirmModal();
  };

  toggleScheduleReportDelete = async (ruleName, reportsUUID) => {
    let status = null;
    status = "Delete";
    await this.setStateAsync({ status: status });
    const scheduleObj = {
      ruleName: ruleName,
      reportsUUID: reportsUUID,
    };
    await this.setStateAsync({ tempScheduleDeleteObj: scheduleObj });
    this.openScheduleDeleteConfirmModal();
  };

  closeScheduleDeleteConfirmModal = async () => {
    await this.setStateAsync({
      isOpenScheduleDeleteConfirmModal: false,
    });
  };

  openScheduleDeleteConfirmModal = async () => {
    await this.setStateAsync({
      isOpenScheduleDeleteConfirmModal: true,
    });
  };

  handleScheduleDeleteSubmit = async () => {
    await this.props.handleScheduleStatusSubmit(
      this.state.tempScheduleDeleteObj,
      this.state.status
    );
    this.closeScheduleDeleteConfirmModal();
  };

  openSitesModel = async (data) => {
    await this.setStateAsync({ isOpenSitesModel: true });
    await this.setStateAsync({ reportData: data });
  };
  closeSitesModel = async () => {
    await this.setStateAsync({ isOpenSitesModel: false });
    await this.setStateAsync({ reportData: null });
  };

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }

  openScheduleViewMoreModel = async (data) => {
    await this.setStateAsync({ isOpenScheduleViewMoreModel: true });
    await this.setStateAsync({ reportData: data });
  };
  closeScheduleViewMoreModel = async () => {
    await this.setStateAsync({ isOpenScheduleViewMoreModel: false });
    await this.setStateAsync({ reportData: null });
  };

  handleToggle() {
    this.setState({ scheduleCollapse: !this.state.scheduleCollapse });
  }
  disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  disableCompanyDropdown = () => {
    if (
      this.props.user?.rootId === null &&
      this.props.user?.parentId === null
    ) {
      return false;
    } else {
      return true;
    }
  };

  disableDivisionDropdown = () => {
    if (
      this.props.user?.rootId === null &&
      this.props.user?.parentId === null
    ) {
      return false;
    } else if (this.props.user?.rootId === this.props.user?.parentId) {
      return true;
    } else {
      return true;
    }
  };

  disableSubDivisionDropdown = () => {
    if (
      this.props.user?.rootId === null &&
      this.props.user?.parentId === null
    ) {
      return false;
    } else if (this.props.user?.rootId === this.props.user?.parentId) {
      return false;
    } else {
      return true;
    }
  };

  setDefaultCompanyName = () => {
    if (
      this.props.user?.rootId === null &&
      this.props.user?.parentId === null
    ) {
      return;
    } else {
      const parts = this.props.user?.organizationTreepath?.split(">");
      const companyValue = parts[0]?.trim();
      return {
        organizationName: companyValue,
      };
    }
  };

  setDefaultDivisionName = () => {
    if (
      this.props.user?.rootId !== null &&
      this.props.user?.parentId !== null
    ) {
      const parts = this.props.user?.organizationTreepath?.split(">");
      const divisionValue = parts[1]?.trim();
      return {
        organizationName: divisionValue,
      };
    }
  };

  setDefaultSubDivisionName = () => {
    if (this.props.user?.rootId !== this.props.user?.parentId) {
      const parts = this.props.user?.organizationTreepath?.split(">");
      const subDivisionValue = parts[2]?.trim();
      return {
        organizationName: subDivisionValue,
      };
    }
  };

  render() {
    return (
      <WithLoading
        isPageDataFetched={this.props.isReportFormDataFetched}
        type="page"
        size="lg"
      >
        <div className="mt-2 pt-2">
          {this.props.userProfile?.userType === "S" ||
          this.props.userProfile?.userType === "P" ? (
            <Row className="content-centre">
              <Col xl={6} lg={6} md={6} sm={6}>
                <FormGroup>
                  <Label>
                    <span className="sram-font-weight-500">
                      {CoreReportResProps.head.title.organizationName.label}:
                    </span>{" "}
                    <span>{this.props.user?.organizationName}</span>
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          ) : null}

          {this.props.userProfile?.userType === "O" ? (
            <>
              <Row className="content-centre">
                <Col xl={6} lg={6} md={6} sm={6}>
                  <FormGroup>
                    <Label>
                      <span className="sram-font-weight-500">
                        {CoreReportResProps.head.title.organizationName.label}:
                      </span>{" "}
                      <span>{this.props.user?.organizationTreepath}</span>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : null}
          {this.props.userProfile?.userType === "S" ||
          this.props.userProfile?.userType === "P" ||
          this.props.userProfile?.userType === "O" ? (
            <>
              <Row className="content-centre">
                <Col xl={6} lg={6} md={6} sm={6}>
                  <FormGroup>
                    <Label>
                      <span className="">
                        {CoreReportResProps.head.title.selectCompanies.label}
                      </span>{" "}
                    </Label>
                    <WithLoading
                      isPageDataFetched={this.props.isCompaniesDataFetched}
                      type="page"
                      size="sm"
                    >
                      <Select
                        placeholder=" Select Company"
                        options={this.props.userCompanies}
                        getOptionLabel={(option) => option?.organizationName}
                        getOptionValue={(option) => option?.organizationName}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary26: "#ced4da",
                            primary: "#ced4da",
                          },
                        })}
                        styles={{
                          option: (base) => ({
                            ...base,
                            color: "#000000",
                          }),
                        }}
                        isClearable
                        // isMulti
                        onChange={async (e) => {
                          await this.props.handleSelectCompanies(e);
                        }}
                        defaultValue={this.setDefaultCompanyName()}
                        isDisabled={this.disableCompanyDropdown()}
                      />
                    </WithLoading>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="content-centre">
                <Col xl={6} lg={6} md={6} sm={6}>
                  <FormGroup>
                    <Label>
                      <span className="">
                        {CoreReportResProps.head.title.selectDivision.label}
                      </span>{" "}
                    </Label>
                    <WithLoading
                      isPageDataFetched={this.props.isDivisionsDataFetched}
                      type="page"
                      size="sm"
                    >
                      <Select
                        placeholder=" Select Division"
                        options={this.props.userDivisions}
                        getOptionLabel={(option) => option?.organizationName}
                        getOptionValue={(option) => option?.organizationName}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary26: "#ced4da",
                            primary: "#ced4da",
                          },
                        })}
                        styles={{
                          option: (base) => ({
                            ...base,
                            color: "#000000",
                          }),
                        }}
                        isClearable
                        // isMulti
                        onChange={async (e) => {
                          await this.props.handleSelectDivision(e);
                        }}
                        defaultValue={this.setDefaultDivisionName()}
                        isDisabled={this.disableDivisionDropdown()}
                      />
                    </WithLoading>
                  </FormGroup>
                </Col>
              </Row>

              <Row className="content-centre">
                <Col xl={6} lg={6} md={6} sm={6}>
                  <FormGroup>
                    <Label>
                      <span className="">
                        {CoreReportResProps.head.title.selectSubDivision.label}
                      </span>{" "}
                    </Label>
                    <WithLoading
                      isPageDataFetched={this.props.isSubDivisionsDataFetched}
                      type="page"
                      size="sm"
                    >
                      <Select
                        placeholder=" Select Sub Division"
                        options={this.props.userSubDivisions}
                        getOptionLabel={(option) => option?.organizationName}
                        getOptionValue={(option) => option?.organizationName}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary26: "#ced4da",
                            primary: "#ced4da",
                          },
                        })}
                        styles={{
                          option: (base) => ({
                            ...base,
                            color: "#000000",
                          }),
                        }}
                        isClearable
                        // isMulti
                        onChange={async (e) => {
                          await this.props.handleSelectSubDivision(e);
                        }}
                        defaultValue={this.setDefaultSubDivisionName()}
                        isDisabled={this.disableSubDivisionDropdown()}
                      />
                    </WithLoading>
                  </FormGroup>
                </Col>
              </Row>
            </>
          ) : null}
          <Row className="content-centre">
            <Col xl={6} lg={6} md={6} sm={6}>
              <FormGroup>
                <Label>
                  <span className="">
                    {CoreReportResProps.head.title.site.label}
                  </span>{" "}
                  *
                </Label>
                <WithLoading
                  isPageDataFetched={this.props.isSitesDataFetched}
                  type="page"
                  size="sm"
                >
                  <Select
                    placeholder=" Select Site"
                    options={this.props.siteOptions}
                    getOptionLabel={(option) => option?.siteName}
                    getOptionValue={(option) => option?.siteName}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary26: "#ced4da",
                        primary: "#ced4da",
                      },
                    })}
                    styles={{
                      option: (base) => ({
                        ...base,
                        color: "#000000",
                      }),
                    }}
                    isMulti
                    onChange={async (e) => {
                      await this.props.handleSelectSites(e);
                    }}
                    className={
                      (this.props.selectedSites === "" ||
                        this.props.selectedSites === undefined) &&
                      this.props.submmitReport === true
                        ? "sram-ticket-view-email-validation"
                        : null
                    }
                  />
                </WithLoading>
              </FormGroup>
              {(this.props.selectedSites === "" ||
                this.props.selectedSites === undefined) &&
              this.props.submmitReport === true ? (
                <span
                  style={{ display: "block" }}
                  className="invalid-feedback mb-2"
                >
                  {CoreReportResProps.body.notification.required.sites}
                </span>
              ) : null}
            </Col>
          </Row>
          {this.props.activeTab === "1" ? (
            <Row className="content-centre">
              {this.props.selectedReportName ===
                "sites_interaction_closed_tickets" ||
              this.props.selectedReportName === "sites_interaction" ? (
                <>
                  <Col xl={3} lg={3} md={3} sm={3}>
                    <FormGroup>
                      <Label>
                        <span className="">
                          {CoreReportResProps.head.title.fromDate.label}
                        </span>{" "}
                        *
                      </Label>
                      <Input
                        type="date"
                        value={this.props.sd}
                        className={
                          this.props.error !== "" ||
                          (this.props.sd === "" &&
                            this.props.submmitReport === true)
                            ? "sram-ticket-view-email-validation form-control"
                            : "form-control"
                        }
                        onChange={(e) => this.props.handleSdChange(e)}
                      />
                    </FormGroup>
                    {this.props.sd === "" &&
                    this.props.submmitReport === true ? (
                      <span
                        style={{ display: "block" }}
                        className="invalid-feedback mb-2"
                      >
                        {CoreReportResProps.body.notification.required.fromDate}
                      </span>
                    ) : null}
                  </Col>

                  <Col xl={3} lg={3} md={3} sm={3}>
                    <FormGroup>
                      <Label>
                        <span className="">
                          {CoreReportResProps.head.title.toDate.label}
                        </span>{" "}
                        *
                      </Label>
                      <Input
                        type="date"
                        value={this.props.ed}
                        className={
                          this.props.error !== "" ||
                          (this.props.sd === "" &&
                            this.props.submmitReport === true)
                            ? "sram-ticket-view-email-validation form-control"
                            : "form-control"
                        }
                        onChange={(e) => this.props.handleEdChange(e)}
                      />
                    </FormGroup>
                    {this.props.ed === "" &&
                    this.props.submmitReport === true ? (
                      <span
                        style={{ display: "block" }}
                        className="invalid-feedback mb-2"
                      >
                        {CoreReportResProps.body.notification.required.toDate}
                      </span>
                    ) : null}
                  </Col>
                </>
              ) : (
                <Col xl={6} lg={6} md={6} sm={6}>
                  <FormGroup>
                    <Label>
                      <span className="">
                        {CoreReportResProps.head.title.selectDate.label}
                      </span>{" "}
                      *
                    </Label>
                    <input
                      type="date"
                      value={this.props.ed}
                      className={
                        this.props.error !== "" ||
                        (this.props.ed === "" &&
                          this.props.submmitReport === true)
                          ? "sram-ticket-view-email-validation form-control"
                          : "form-control"
                      }
                      max={this.disablePastDate()}
                      onChange={(e) => this.props.handleDateChange(e)}
                    />
                  </FormGroup>
                  {this.props.error !== "" ||
                  (this.props.ed === "" &&
                    this.props.submmitReport === true) ? (
                    <span
                      style={{ display: "block" }}
                      className="invalid-feedback mb-2"
                    >
                      {CoreReportResProps.body.notification.required.selectDate}
                    </span>
                  ) : null}
                </Col>
              )}
              <Col
                sm={12}
                md={12}
                lg={12}
                style={{ display: "block", textAlign: "center " }}
                className="invalid-feedback mb-2"
              >
                {this.props.error}
              </Col>
            </Row>
          ) : null}
          <Row className="content-centre">
            <Col xl={6} lg={6} md={6} sm={6}>
              <Label>
                <span className="">
                  {CoreReportResProps.head.title.fileType.label}
                </span>{" "}
                *
              </Label>
              <Select
                placeholder="Select File Type"
                options={this.props.reportTypes}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option?.name}
                hasValue={true}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary25: "#ced4da",
                    primary: "#ced4da",
                  },
                })}
                styles={{
                  option: (base) => ({
                    ...base,
                    color: "#000000",
                  }),
                }}
                isMulti
                onChange={this.props.handleReportChange}
                className={
                  (this.props.selectedFileTypes === "" ||
                    this.props.selectedFileTypes === undefined) &&
                  this.props.submmitReport === true
                    ? "sram-ticket-view-email-validation"
                    : null
                }
              />
              {(this.props.selectedFileTypes === "" ||
                this.props.selectedFileTypes === undefined) &&
              this.props.submmitReport === true ? (
                <span
                  style={{
                    display: "block",
                    textAlign: "centre",
                  }}
                  className="invalid-feedback mb-2"
                >
                  {CoreReportResProps.body.notification.required.reportType}
                </span>
              ) : null}
            </Col>
          </Row>

          {this.props.activeTab === "2" ? (
            <Row className="content-centre mt-3">
              <Col xl={6} lg={6} md={6} sm={6}>
                <FormGroup>
                  <Label>
                    <span className="">
                      {CoreReportResProps.head.title.scheduler.label}
                    </span>{" "}
                    *
                  </Label>
                  <Select
                    placeholder=" Select Scheduler"
                    options={this.props.scheduler}
                    getOptionLabel={(option) => option?.value}
                    getOptionValue={(option) => option?.value}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary26: "#ced4da",
                        primary: "#ced4da",
                      },
                    })}
                    styles={{
                      option: (base) => ({
                        ...base,
                        color: "#000000",
                      }),
                    }}
                    isClearable
                    // isMulti
                    onChange={async (e) => {
                      await this.props.handleSelectScheduler(e);
                    }}
                    className={
                      (this.props.selectedScheduler === "" ||
                        this.props.selectedScheduler === undefined) &&
                      this.props.submmitReport === true
                        ? "sram-ticket-view-email-validation"
                        : null
                    }
                  />
                </FormGroup>
                {(this.props.selectedScheduler === "" ||
                  this.props.selectedScheduler === undefined) &&
                this.props.submmitReport === true ? (
                  <span
                    style={{ display: "block" }}
                    className="invalid-feedback mb-2"
                  >
                    {CoreReportResProps.body.notification.required.scheduler}
                  </span>
                ) : null}
              </Col>
            </Row>
          ) : null}
          <Row className="content-centre">
            <Col xl={6} lg={6} md={6} sm={6}>
              <Button
                type="button"
                className="sram-btn-white ml-3 mt-4 float-right"
                onClick={() => this.props.pushBack()}
              >
                {CoreReportResProps.body.form.cancel.label}
              </Button>
              <Button
                type="button"
                className="sram-btn-blue mt-4 float-right"
                onClick={() => this.props.handleSubmit()}
              >
                {this.props.activeTab === "1"
                  ? CoreReportResProps.body.form.runReport.label
                  : CoreReportResProps.body.form.scheduleReport.label}
                {this.props.activeTab === "1" ? (
                  this.props.selectedSites !== "" &&
                  this.props.selectedSites !== undefined &&
                  this.props.sd !== "" &&
                  this.props.ed !== "" &&
                  this.props.error === "" &&
                  this.props.selectedFileTypes !== "" &&
                  this.props.selectedFileTypes !== undefined &&
                  this.props.submmitReport === true ? (
                    <Spinner size="sm" color="light" className="pb-1 ml-2" />
                  ) : null
                ) : this.props.activeTab === "2" ? (
                  this.props.selectedSites !== "" &&
                  this.props.selectedSites !== undefined &&
                  this.props.selectedFileTypes !== "" &&
                  this.props.selectedFileTypes !== undefined &&
                  this.props.selectCustomDatePicker !== "" &&
                  this.props.selectCustomDatePicker !== undefined &&
                  this.props.selectedScheduler !== "" &&
                  this.props.selectedScheduler !== undefined &&
                  this.props.submmitReport === true ? (
                    <Spinner size="sm" color="light" className="pb-1 ml-2" />
                  ) : null
                ) : null}
              </Button>
            </Col>
          </Row>

          <div className="mt-3">
            {this.props.activeTab && this.props.activeTab === "1" ? (
              <Row>
                <Col sm="12">
                  <span className="sram-dispenser-section-sub-heading">
                    {CoreReportResProps.body.content.reportsHistory}
                  </span>
                  <Card body className="">
                    <WithLoading
                      isPageDataFetched={this.props.isReportsHistoryDataFetched}
                      type="page"
                      size="sm"
                    >
                      <PerfectScrollbar className="sram-dispenser-card-body-height">
                        {this.props.reportsHistory &&
                        this.props.reportsHistory.length > 0 ? (
                          <Table>
                            <thead>
                              <tr>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    CoreReportResProps.body.form.executionDate
                                      .label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    CoreReportResProps.body.form.reportName
                                      .label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {CoreReportResProps.body.form.fileType.label}
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {CoreReportResProps.body.form.sites.label}
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    CoreReportResProps.body.form
                                      .organizationName.label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {CoreReportResProps.body.form.createdBy.label}
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    CoreReportResProps.body.form.viewReport
                                      .label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.reportsHistory &&
                                this.props.reportsHistory.map((obj, index) => {
                                  let fileType = "";
                                  if (obj.fileType) {
                                    if (obj.fileType === "pdf") {
                                      fileType = "PDF";
                                    } else if (obj.fileType === "excel") {
                                      fileType = "Excel";
                                    } else {
                                      fileType = "-";
                                    }
                                  } else {
                                    fileType = "-";
                                  }
                                  return (
                                    <tr key={index}>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.createdOn
                                          ? AppUtils.getDateTime(obj.createdOn)
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.reportName
                                          ? obj.reportName?.replace(
                                              /paper_out/,
                                              "receipt_paper"
                                            )
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {fileType}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.sites ? (
                                          <p>
                                            {obj.sites.length > 10 ? (
                                              <span>
                                                {obj.sites.substring(0, 10)}...
                                              </span>
                                            ) : (
                                              obj.sites
                                            )}
                                          </p>
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.organizationName
                                          ? obj.organizationName
                                          : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.username ? obj.username : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.s3Name ? (
                                          <i
                                            className={`fa fa-cloud-download-alt sram-icon-view sram-icon-small report-download-icon`}
                                            aria-hidden="true"
                                            onClick={this.downloadReport.bind(
                                              this,
                                              obj.s3Name
                                            )}
                                          ></i>
                                        ) : (
                                          "Report generation is in progress"
                                        )}
                                      </td>
                                      <td>
                                        <span
                                          onClick={() =>
                                            this.openSitesModel(obj)
                                          }
                                          title="View More"
                                          className="sram-page-action-icon ml-3"
                                        >
                                          <i
                                            className={`d-inline far fa-dot-circle sram-icon-view sram-icon-small}`}
                                          ></i>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        ) : (
                          <div className="pt-3 py-3 ml-3 sram-no-data">
                            {
                              CoreReportResProps.body.notification
                                .noReportsFound
                            }
                          </div>
                        )}
                      </PerfectScrollbar>
                    </WithLoading>
                  </Card>
                </Col>
              </Row>
            ) : this.props.activeTab === "2" ? (
              <Row>
                <Col sm="12">
                  <span className="sram-dispenser-section-sub-heading">
                    {CoreReportResProps.body.content.scheduleHistory}
                  </span>
                  <Card body className="">
                    <WithLoading
                      isPageDataFetched={
                        this.props.isScheduledReportsHistoryDataFetched
                      }
                      type="page"
                      size="sm"
                    >
                      <PerfectScrollbar className="sram-dispenser-card-body-height">
                        {this.props.scheduledReportsHistory &&
                        this.props.scheduledReportsHistory.length > 0 ? (
                          <Table>
                            <thead>
                              <tr>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    CoreReportResProps.body.form.creationDate
                                      .label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    CoreReportResProps.body.form.reportName
                                      .label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {CoreReportResProps.body.form.fileType.label}
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {CoreReportResProps.body.form.sites.label}
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    CoreReportResProps.body.form.companyName
                                      .label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {
                                    CoreReportResProps.body.form
                                      .scheduleExpression.label
                                  }
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                  {CoreReportResProps.body.form.createdBy.label}
                                </th>
                                <th className="sram-text-font sram-font-weight-600 sram-table-th"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.props.scheduledReportsHistory &&
                                this.props.scheduledReportsHistory.map(
                                  (obj, index) => {
                                    let fileType = "";
                                    if (obj.fileType) {
                                      if (obj.fileType === "pdf") {
                                        fileType = "PDF";
                                      } else if (obj.fileType === "excel") {
                                        fileType = "Excel";
                                      } else if (obj.fileType === "excel,pdf") {
                                        fileType = "Excel, PDF";
                                      } else if (obj.fileType === "pdf,excel") {
                                        fileType = "PDF, Excel";
                                      } else {
                                        fileType = "-";
                                      }
                                    } else {
                                      fileType = "-";
                                    }
                                    return (
                                      <tr key={index}>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.createdOn
                                            ? AppUtils.getDateTime(
                                                obj.createdOn
                                              )
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.reportName
                                            ? obj.reportName === "paper_out"
                                              ? "receipt_paper"
                                              : obj.reportName
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {fileType}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.sites ? (
                                            <p>
                                              {obj.sites.length > 10 ? (
                                                <span>
                                                  {obj.sites.substring(0, 10)}
                                                  ...{" "}
                                                </span>
                                              ) : (
                                                obj.sites
                                              )}
                                            </p>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.organizationName
                                            ? obj.organizationName
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.scheduleExpression
                                            ? obj.scheduleExpression
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.username ? obj.username : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          <span
                                            onClick={() =>
                                              this.openScheduleViewMoreModel(
                                                obj
                                              )
                                            }
                                            title="View More"
                                            className="sram-page-action-icon ml-3"
                                          >
                                            <i
                                              className={`d-inline far fa-dot-circle sram-icon-view sram-icon-medium}`}
                                            ></i>
                                          </span>
                                          {this.props.scheduleEnableDisable ===
                                          true ? (
                                            <a className="ml-3">
                                              <Switch
                                                onChange={this.handleChange.bind(
                                                  this,
                                                  obj.ruleName,
                                                  obj.reportsUUID,
                                                  obj.scheduleStatus
                                                )}
                                                checked={obj.scheduleStatus}
                                                height={18}
                                                width={40}
                                                className="sram-react-switch"
                                              />
                                            </a>
                                          ) : null}
                                          &nbsp; &nbsp;
                                          {this.props.scheduleDelete ===
                                          true ? (
                                            <span
                                              title={
                                                CoreReportResProps.body.content
                                                  .deleteScheduleReport
                                              }
                                              className="sram-page-action-icon"
                                              onClick={() =>
                                                this.toggleScheduleReportDelete(
                                                  obj.ruleName,
                                                  obj.reportsUUID
                                                )
                                              }
                                            >
                                              <i
                                                className={`d-inline fa fa-trash sram-icon-medium`}
                                              ></i>
                                            </span>
                                          ) : null}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </Table>
                        ) : (
                          <div className="pt-3 py-3 ml-3 sram-no-data">
                            {
                              CoreReportResProps.body.notification
                                .noReportsFound
                            }
                          </div>
                        )}
                      </PerfectScrollbar>
                    </WithLoading>
                  </Card>
                  {this.state.tempScheduleEnableDisableObj ? (
                    <ConfirmModal
                      isOpenConfirmModal={
                        this.state.isOpenScheduleEnableDisableConfirmModal
                      }
                      closeConfirmModal={
                        this.closeScheduleEnableDisableConfirmModal
                      }
                      handleSubmit={this.handleScheduleEnableDisableSubmit}
                    >
                      <div>
                        <div>
                          <Label>
                            {this.state.scheduleStatus === 0
                              ? CoreReportResProps.body.notification
                                  .confirmation.disableScheduleReport
                              : CoreReportResProps.body.notification
                                  .confirmation.enableScheduleReport}
                          </Label>
                        </div>
                      </div>
                    </ConfirmModal>
                  ) : null}

                  {this.state.tempScheduleDeleteObj ? (
                    <ConfirmModal
                      isOpenConfirmModal={
                        this.state.isOpenScheduleDeleteConfirmModal
                      }
                      closeConfirmModal={this.closeScheduleDeleteConfirmModal}
                      handleSubmit={this.handleScheduleDeleteSubmit}
                    >
                      <div>
                        <div>
                          <Label>
                            {
                              CoreReportResProps.body.notification.confirmation
                                .deleteScheduleReport
                            }
                          </Label>
                        </div>
                      </div>
                    </ConfirmModal>
                  ) : null}
                </Col>
              </Row>
            ) : null}
            {this.state.isOpenSitesModel === true ? (
              <Modal
                isOpen={this.state.isOpenSitesModel}
                toggle={this.closeSitesModel}
                size="xl"
                backdrop="static"
                centered={false}
                className="sram-modal-content"
              >
                <div className="modal-header">
                  <span>
                    <h5 className="modal-title">
                      {CoreReportResProps.body.form.sites.label}
                    </h5>
                  </span>
                  <span
                    className="pull-right sram-times"
                    onClick={() => {
                      this.closeSitesModel();
                    }}
                  >
                    <i className="fa fa-times-circle"></i>
                  </span>
                </div>
                <ModalBody className="mb-2">
                  <Table>
                    <thead>
                      <tr>
                        <th
                          className="sram-text-font sram-font-weight-600 sram-table-th"
                          width="10%"
                        >
                          {CoreReportResProps.body.form.fileType.label}
                        </th>
                        <th
                          className="sram-text-font sram-font-weight-600 sram-table-th"
                          width="20%"
                        >
                          {CoreReportResProps.body.form.organizationName.label}
                        </th>
                        {this.props.selectedReportName ===
                          "sites_interaction_closed_tickets" ||
                        this.props.selectedReportName ===
                          "sites_interaction" ? (
                          <>
                            <th
                              className="sram-text-font sram-font-weight-600 sram-table-th"
                              width="10%"
                            >
                              {CoreReportResProps.body.form.fromDate.label}
                            </th>
                            <th
                              className="sram-text-font sram-font-weight-600 sram-table-th"
                              width="10%"
                            >
                              {CoreReportResProps.body.form.toDate.label}
                            </th>
                          </>
                        ) : (
                          <th
                            className="sram-text-font sram-font-weight-600 sram-table-th"
                            width="10%"
                          >
                            {CoreReportResProps.body.form.date.label}
                          </th>
                        )}
                        <th
                          className="sram-text-font sram-font-weight-600 sram-table-th"
                          width="50%"
                        >
                          {CoreReportResProps.body.form.sites.label}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="sram-text-font sram-table-td">
                          {this.state.reportData
                            ? this.state.reportData.fileType
                            : "-"}
                        </td>
                        <td className="sram-text-font sram-table-td">
                          {this.state.reportData
                            ? this.state.reportData.organizationName
                            : "-"}
                        </td>
                        {this.props.selectedReportName ===
                          "sites_interaction_closed_tickets" ||
                        this.props.selectedReportName ===
                          "sites_interaction" ? (
                          <>
                            <td className="sram-text-font sram-table-td">
                              {this.state.reportData
                                ? AppUtils.getDate(
                                    this.state.reportData.fromDate
                                  )
                                : "-"}
                            </td>
                            <td className="sram-text-font sram-table-td">
                              {this.state.reportData
                                ? AppUtils.getDate(this.state.reportData.toDate)
                                : "-"}
                            </td>
                          </>
                        ) : (
                          <td className="sram-text-font sram-table-td">
                            {this.state.reportData
                              ? AppUtils.getDate(this.state.reportData.toDate)
                              : "-"}
                          </td>
                        )}
                        <td className="sram-text-font sram-table-td">
                          {this.state.reportData &&
                          this.state.reportData.sites ? (
                            <p className="sram-text-font">
                              {this.state.reportData.sites.length > 50 ? (
                                <span>
                                  {this.state.collapse === false ? (
                                    <>
                                      <span>
                                        {this.state.reportData.sites.substring(
                                          0,
                                          50
                                        )}
                                        ...
                                      </span>
                                      <span
                                        onClick={this.toggle}
                                        className="sram-links"
                                      >
                                        Read More
                                      </span>
                                    </>
                                  ) : null}
                                  <Collapse isOpen={this.state.collapse}>
                                    <div>{this.state.reportData.sites}</div>
                                    <div>
                                      <span
                                        onClick={this.toggle}
                                        className="sram-links"
                                      >
                                        Read Less
                                      </span>
                                    </div>
                                  </Collapse>
                                </span>
                              ) : (
                                this.state.reportData.sites
                              )}
                            </p>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
            ) : null}

            {this.state.isOpenScheduleViewMoreModel === true ? (
              <Modal
                isOpen={this.state.isOpenScheduleViewMoreModel}
                toggle={this.closeScheduleViewMoreModel}
                size="xl"
                backdrop="static"
                centered={false}
                className="sram-modal-content"
              >
                <div className="modal-header">
                  <span>
                    <h5 className="modal-title">
                      {CoreReportResProps.body.form.sites.label}
                    </h5>
                  </span>
                  <span
                    className="pull-right sram-times"
                    onClick={() => {
                      this.closeScheduleViewMoreModel();
                    }}
                  >
                    <i className="fa fa-times-circle"></i>
                  </span>
                </div>
                <ModalBody className="mb-2">
                  <Table>
                    <thead>
                      <tr>
                        <th
                          className="sram-text-font sram-font-weight-600 sram-table-th"
                          width="10%"
                        >
                          {CoreReportResProps.body.form.reportName.label}
                        </th>
                        <th
                          className="sram-text-font sram-font-weight-600 sram-table-th"
                          width="10%"
                        >
                          {CoreReportResProps.body.form.fileType.label}
                        </th>
                        <th
                          className="sram-text-font sram-font-weight-600 sram-table-th"
                          width="20%"
                        >
                          {CoreReportResProps.body.form.organizationName.label}
                        </th>
                        <th
                          className="sram-text-font sram-font-weight-600 sram-table-th"
                          width="10%"
                        >
                          {CoreReportResProps.body.form.dateRange.label}
                        </th>
                        <th
                          className="sram-text-font sram-font-weight-600 sram-table-th"
                          width="50%"
                        >
                          {CoreReportResProps.body.form.sites.label}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="sram-text-font sram-table-td">
                          {this.state.reportData
                            ? this.state.reportData?.reportName === "paper_out"
                              ? "receipt_paper"
                              : this.state.reportData?.reportName
                            : "-"}
                        </td>
                        <td className="sram-text-font sram-table-td">
                          {this.state.reportData
                            ? this.state.reportData.fileType
                            : "-"}
                        </td>
                        <td className="sram-text-font sram-table-td">
                          {this.state.reportData
                            ? this.state.reportData.organizationName
                            : "-"}
                        </td>
                        <td className="sram-text-font sram-table-td">
                          {this.state.reportData
                            ? this.state.reportData.dateRange
                            : "-"}
                        </td>
                        <td className="sram-text-font sram-table-td">
                          {this.state.reportData &&
                          this.state.reportData.sites ? (
                            <p className="sram-text-font">
                              {this.state.reportData.sites.length > 50 ? (
                                <span>
                                  {this.state.collapse === false ? (
                                    <>
                                      <span>
                                        {this.state.reportData.sites.substring(
                                          0,
                                          50
                                        )}
                                        ...
                                      </span>
                                      <span
                                        onClick={this.toggle}
                                        className="sram-links"
                                      >
                                        Read More
                                      </span>
                                    </>
                                  ) : null}
                                  <Collapse isOpen={this.state.collapse}>
                                    <div>{this.state.reportData.sites}</div>
                                    <div>
                                      <span
                                        onClick={this.toggle}
                                        className="sram-links"
                                      >
                                        Read Less
                                      </span>
                                    </div>
                                  </Collapse>
                                </span>
                              ) : (
                                this.state.reportData.sites
                              )}
                            </p>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
            ) : null}
          </div>
        </div>
      </WithLoading>
    );
  }
}
export default CoreReportsForm;
