import axios from "axios";
import { AppConfigProps } from "../../core/settings/app-config";
import { logMessage } from "../../core/actions/common-actions";

const actionFileName = "organization-actions.js";

// List of Guardian Connect Companies
export function getSiteIQCompanies(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "?filterType=siteiq-companies";
    if (queryData) {
      queryParams += "&" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "organizations" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">getSiteIQCompanies()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// List of Guardian Connect Organizations
export function getSiteIQOrganizations(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "?filterType=siteiq-organizations";
    if (queryData) {
      queryParams += "&" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "organizations" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">getSiteIQOrganizations()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// List of Site Owner Companies
export function getSiteOwnerCompanies(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "?filterType=site-owner-companies";
    if (queryData) {
      queryParams += "&" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "organizations" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">getSiteOwnerCompanies()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// List of Site Owner Organizations
export function getSiteOwnerOrganizations(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "?filterType=site-owner-organizations";
    if (queryData) {
      queryParams += "&" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "organizations" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">getSiteOwnerOrganizations()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// List of Service Provider Companies
export function getServiceProviderCompanies(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "?filterType=service-provider-companies";
    if (queryData) {
      queryParams += "&" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "organizations" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">getServiceProviderCompanies()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// List of Service Provider Organizations
export function getServiceProviderOrganizations(queryData, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams = "?filterType=service-provider-organizations";
    if (queryData) {
      queryParams += "&" + queryData;
    }
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "organizations" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">getServiceProviderOrganizations()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}

// List of Organizations below a Organization
export function getOrganizationsBelow(organizationId, cancelToken) {
  return new Promise(function (resolve, reject) {
    let queryParams =
      "?filterType=organizations-below&organizationId=" + organizationId;
    axios
      .get(
        AppConfigProps.serverRoutePrefix + "organizations" + queryParams,
        cancelToken
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          logMessage(
            "warn",
            actionFileName + ">getOrganizationsBelow()",
            err.message
          );
        } else {
          reject(err.response);
        }
      });
  });
}
