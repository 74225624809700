import React, { Component } from "react";
import { Card, CardTitle, CardBody, Row, Col } from "reactstrap";
import { NavLink as RRNavLink } from "react-router-dom";
import axios from "axios";
import WithLoading from "../helpers/with-loading";
import PageDisplay from "../helpers/page-display";
import PageUtils from "../helpers/page-utils";
import AppUtils from "../helpers/app-utils";
import { AppURLProps } from "../settings/app-urls";
import { AppConfigProps } from "../settings/app-config";
import { AppMsgResProps } from "../messages/app-properties";
import { IdentityMsgResProps } from "../messages/identity-properties";
import { manageError } from "../actions/common-actions";
import { getProfile, getPagePartsPrivilege } from "../actions/identity-actions";

class MyAccount extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcChangePassword = "AC-UID-UCPW";
  _pcUpdateProfile = "AC-UID-UUPF";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      partPrivileges: this.initPartPrivileges(),
      profile: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    await this.loadPageData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async () => {
    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ profile: null });
    await this.setPartPrivileges();
    await getProfile(this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ profile: res.data.result });
          AppUtils.setProfile(res.data.result)
        } else {
          this.props.history.push(AppURLProps.home);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  initPartPrivileges = () => {
    return {
      [this._pcChangePassword]: false,
      [this._pcUpdateProfile]: false,
    };
  };

  setPartPrivileges = async () => {
    const partCodes = [this._pcChangePassword, this._pcUpdateProfile];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            {this.state.profile ? (
              <Card className="sram-page-view">
                <CardTitle>
                  <span>{IdentityMsgResProps.body.content.myAccount}</span>
                </CardTitle>
                <CardBody>
                  <div className="sram-form-head">
                    <Row form>
                      <Col sm={12}>
                        {this.state.partPrivileges[this._pcChangePassword] ===
                        true ? (
                          <RRNavLink
                            to={AppURLProps.changePassword}
                            className="sram-page-link"
                          >
                            {IdentityMsgResProps.body.content.changePassword}
                          </RRNavLink>
                        ) : null}
                        {this.state.partPrivileges[this._pcUpdateProfile] ===
                        true ? (
                          <RRNavLink
                            to={AppURLProps.updateProfile}
                            className="sram-page-link"
                          >
                            {IdentityMsgResProps.body.content.updateProfile}
                          </RRNavLink>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                  <div className="sram-view-main">
                    <div className="sram-view-data">
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            IdentityMsgResProps.body.form.username.label,
                            this.state.profile.username
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            IdentityMsgResProps.body.form.email.label,
                            this.state.profile.email
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            IdentityMsgResProps.body.form.firstName.label,
                            this.state.profile.firstName
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            IdentityMsgResProps.body.form.lastName.label,
                            this.state.profile.lastName
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            IdentityMsgResProps.body.form.mobile.label,
                            this.state.profile.mobile
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            IdentityMsgResProps.body.form.streetAddress1.label,
                            this.state.profile.streetAddress1
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            IdentityMsgResProps.body.form.streetAddress2.label,
                            this.state.profile.streetAddress2
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            IdentityMsgResProps.body.form.city.label,
                            this.state.profile.city
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            IdentityMsgResProps.body.form.state.label,
                            this.state.profile.state
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            IdentityMsgResProps.body.form.country.label,
                            this.state.profile.country
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            IdentityMsgResProps.body.form.zipCode.label,
                            this.state.profile.zipCode
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  IdentityMsgResProps.body.content.noDataFound
                )}
              </>
            )}
          </div>
        </WithLoading>
      </>
    );
  }
}

export default MyAccount;
