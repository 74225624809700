import React, { Component } from "react";
import {
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Button,
  Label,
} from "reactstrap";
import axios from "axios";
import WithLoading from "../../core/helpers/with-loading";
import PageDisplay from "../../core/helpers/page-display";
import PageUtils from "../../core/helpers/page-utils";
import { SetupURLProps } from "../settings/setup-urls";
import { AppConfigProps } from "../../core/settings/app-config";
import { AppMsgResProps } from "../../core/messages/app-properties";
import { AppURLProps } from "../../core/settings/app-urls";
import { UserMsgResProps } from "../messages/user-properties";
import { manageError } from "../../core/actions/common-actions";
import {
  isPagePartPrivileged,
  getPagePartsPrivilege,
} from "../../core/actions/identity-actions";
import { getUser, deleteUser } from "../actions/user-actions";

class UserView extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  _pcUserView = "AS-USR-UVIW";
  _pcUserDelete = "AS-USR-USDT";

  constructor(props) {
    super(props);
    this.state = {
      isPageDataFetched: false,
      user: null,
      partPrivileges: this.initPartPrivileges(),
      isUserDeleteModalStatus: false,
      statusMessage: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    PageUtils.scrollToTop();
    const userId = this.props.match.params.uid;
    await this.loadPageData(userId);
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const userId = this.props.match.params.uid;
    if (userId !== prevProps.match.params.uid) {
      PageUtils.scrollToTop();
      await this.loadPageData(userId);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  loadPageData = async (userId) => {
    if (
      userId === null ||
      userId === undefined ||
      userId !== parseInt(userId).toString()
    )
      this.props.history.push(SetupURLProps.users.listUser);

    await this.setStateAsync({ isPageDataFetched: false });
    await this.setStateAsync({ user: null });
    await this.checkPartPrivilege();
    await this.setPartPrivileges();
    await getUser(userId, this._cancelToken)
      .then(async (res) => {
        if (
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.result
        ) {
          await this.setStateAsync({ user: res.data.result });
        } else {
          this.props.history.push(SetupURLProps.users.listUser);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({ isPageDataFetched: true });
  };

  checkPartPrivilege = async () => {
    const partCode = `${this._pcUserView}`;
    const partPrivilege = await isPagePartPrivileged(
      partCode,
      this._cancelToken
    );
    if (partPrivilege === false) {
      this.props.history.push(AppURLProps.pageNotFound);
    }
  };

  initPartPrivileges = () => {
    return {
      [this._pcUserDelete]: false,
    };
  };

  setPartPrivileges = async () => {
    const partCodes = [this._pcUserDelete];
    const privs = await getPagePartsPrivilege(partCodes, this._cancelToken);
    await this.setStateAsync({
      partPrivileges: {
        ...this.state.partPrivileges,
        ...privs,
      },
    });
  };

  toggleUserDelete() {
    this.setStateAsync({
      isUserDeleteModalStatus: !this.state.isUserDeleteModalStatus,
    });
  }

  async handleUserDelete() {
    const userObj = {
      username: this.state.user.username,
    };
    await this.setStateAsync({ statusMessage: null });
    await deleteUser(userObj, this._cancelToken)
      .then(async (res) => {
        if (res && res.status === AppConfigProps.httpStatusCode.ok) {
          await this.toggleUserDelete();
          const statusMsg = [
            "S",
            UserMsgResProps.body.notification.success.deleteUser,
          ];
          await this.setStateAsync({ statusMessage: statusMsg });
          PageUtils.scrollToTop();
          setTimeout(async () => {
            await this.props.history.push(SetupURLProps.users.listUser);
          }, 2000);
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        const statusMsg = [
          "E",
          UserMsgResProps.body.notification.error.deleteUser,
        ];
        await this.setStateAsync({ statusMessage: statusMsg });
        await manageError(err, this.props.history);
      });
    PageUtils.scrollToTop();
  }

  render() {
    return (
      <>
        <WithLoading
          isPageDataFetched={this.state.isPageDataFetched}
          type="page"
        >
          <div className="page-content-space">
            <div>
              {this.state.statusMessage ? (
                <Row>
                  <Col xs={12}>
                    {this.state.statusMessage[0] === "E"
                      ? PageDisplay.showErrorNotification(
                          this.state.statusMessage[1]
                        )
                      : PageDisplay.showSuccessNotification(
                          this.state.statusMessage[1]
                        )}
                  </Col>
                </Row>
              ) : null}
            </div>
            {this.state.user ? (
              <Card className="sram-page-view">
                <CardTitle>
                  <span>{UserMsgResProps.body.content.userDetails}</span>
                  <span className="float-right">
                    {this.state.partPrivileges[this._pcUserDelete] === true ? (
                      <span
                        title={UserMsgResProps.body.content.deleteUser}
                        className="sram-page-action-icon"
                        onClick={() => this.toggleUserDelete()}
                      >
                        <i
                          className={`d-inline fa fa-trash sram-delete-icon sram-icon-wide-medium`}
                        ></i>
                      </span>
                    ) : null}

                    <span className="ml-3">
                      {PageDisplay.showGoBackLink(
                        "L",
                        SetupURLProps.users.listUser,
                        this.props.history
                      )}
                    </span>
                  </span>
                </CardTitle>
                <CardBody>
                  <div className="sram-view-main">
                    <div className="sram-view-data">
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.username.label,
                            this.state.user.username
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Label className="d-block font-light">
                            {UserMsgResProps.body.form.username.notification}
                          </Label>
                          <Label className="d-flex font-medium">
                            {this.state?.user?.emailNotificationStatus ? (
                              <i class="fa fa-check" aria-hidden="true"></i>
                            ) : (
                              <i class="fa fa-times" aria-hidden="true"></i>
                            )}
                          </Label>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.firstName.label,
                            this.state.user.firstName
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.lastName.label,
                            this.state.user.lastName
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.email.label,
                            this.state.user.email
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.mobile.label,
                            this.state.user.mobile
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.organization.label,
                            this.state.user.organizationTreepath
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.role.label,
                            this.state.user.roleName
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.streetAddress1.label,
                            this.state.user.streetAddress1
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.streetAddress2.label,
                            this.state.user.streetAddress2
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.city.label,
                            this.state.user.city
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.state.label,
                            this.state.user.state
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.country.label,
                            this.state.user.country
                          )}
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          {PageDisplay.getFormFieldLabelValue(
                            UserMsgResProps.body.form.zipCode.label,
                            this.state.user.zipCode
                          )}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <>
                {PageDisplay.showWarningNotification(
                  UserMsgResProps.body.content.noUserFound
                )}
              </>
            )}
          </div>
        </WithLoading>

        <Modal
          isOpen={this.state.isUserDeleteModalStatus}
          toggle={() => this.toggleUserDelete()}
          size="md"
          backdrop="static"
          centered={false}
          className="sram-modal-content"
        >
          <ModalHeader>{UserMsgResProps.body.content.deleteUser}</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => e.preventDefault()} noValidate>
              <Label>
                {UserMsgResProps.body.notification.confirmation.deleteUserTitle}
              </Label>
            </Form>
          </ModalBody>
          <ModalFooter className="m-1 p-2">
            <Button
              type="button"
              className="sram-btn-blue mx-2"
              onClick={() => {
                this.handleUserDelete();
              }}
            >
              {AppMsgResProps.body.form.yes.label}
            </Button>
            <Button
              type="button"
              className="sram-btn-white mx-2"
              onClick={() => this.toggleUserDelete()}
            >
              {AppMsgResProps.body.form.no.label}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default UserView;
