import React from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Spinner
} from "reactstrap";
import { checkTicketApproval, acceptApproval, rejectApproval } from "../actions/site-visitor-actions";
import WithLoading from "../helpers/with-loading";
import axios from "axios";
import imgLogo from "../../../assets/images/guardianConnect.png";
import { AppConfigProps } from "../settings/app-config";
import { manageError } from "../actions/common-actions";
import PageUtils from "../../core/helpers/page-utils";
import { AppMsgResProps } from "../messages/app-properties";
// import SiteUtils from "../../portal/helpers/site-utils";

class siteVisitor extends React.Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };
  constructor() {
    super();
    this.state = {
      isPageDataFetched: false,
      alertTicketId: null,
      emailGuid: null,
      status: null,
      siteVisitorData: null,
      buttonStatus: false,
      serviceMessage: false,
      serviceMessageView: false,
      showMessage: null
    }
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    if (this.props.history.location.search !== "") {
      let value = this.props.history.location.search
      let data = PageUtils.getQueryParams(value)
      let alertTicketId = data["alert-ticket-id"]
      let emailGuid = data["email-uuid"]
      await this.setStateAsync({ alertTicketId: alertTicketId });
      await this.setStateAsync({ emailGuid: emailGuid });
      await this.setStateAsync({ status: data.status });
      await this.getTicketApproval();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  getTicketApproval = async () => {
    this.setStateAsync({ isPageDataFetched: false })
    const alertTicketId = this.state.alertTicketId;
    await checkTicketApproval(alertTicketId, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.records
        ) {
          this.setStateAsync({ siteVisitorData: res.data.records })
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    this.setStateAsync({ isPageDataFetched: true })
  };

  getVisitorAccept = async () => {
    await this.setStateAsync({ buttonStatus: true })
    const queryData = "clientApprove";
    const alertTicketId = parseInt(this.state.alertTicketId);
    const acceptData = {
      emailGuid: this.state.emailGuid,
      // alertTicketId: this.state.alertTicketId
    }
    await acceptApproval(alertTicketId, queryData, acceptData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.message
        ) {
          this.setState({ showMessage: res.data.message })
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    await this.setStateAsync({
      serviceMessage: true,
      serviceMessageView: true
    })
    // this.setStateAsync({ buttonStatus: false })
  };

  getVisitorReject = async () => {
    this.setStateAsync({ buttonStatus: true });
    const queryData = "rejected";
    const alertTicketId = parseInt(this.state.alertTicketId);
    const rejectData = {
      emailGuid: this.state.emailGuid,
      // alertTicketId: this.state.alertTicketId
    }
    await rejectApproval(alertTicketId, queryData, rejectData, this._cancelToken)
      .then(async (res) => {
        if (
          res &&
          res.status === AppConfigProps.httpStatusCode.ok &&
          res.data &&
          res.data.message
        ) {
          this.setState({ showMessage: res.data.message })
        } else {
          await manageError(res, this.props.history);
        }
      })
      .catch(async (err) => {
        await manageError(err, this.props.history);
      });
    this.setStateAsync({
      serviceMessage: true,
      serviceMessageView: true
    })
    // this.setStateAsync({ buttonStatus: false });
  };

  close = () => {
    this.setStateAsync({
      buttonStatus: true,
      serviceMessageView: true,
      serviceMessage: true,
    })
  }

  render() {
    // const siteAddress = SiteUtils.getSiteAddress(this.state.siteVisitorData);
    // const siteAddress1 = SiteUtils.getSiteAddress1(this.state.siteVisitorData);
    return (
      <>
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center">
          <div className="auth-box">
            <div id="jpinnowform">
              <div className="logo">
                <span className="db">
                    <img
                      src={imgLogo}
                      alt={AppMsgResProps.body.content.siteiq}
                    />
                </span>
              </div>
              <div className="pl-4 pr-4 pb4" >
                <WithLoading
                  isPageDataFetched={this.state.isPageDataFetched}
                  type="page"
                  size="md"
                >
                  <Container>
                    <div className="auth-form">
                      <Row className="mb-2">
                        <Row>
                          {/* <Col sm={12} md={12} lg={12} xl={12} className="pt-2">
                          <span className="sram-font-weight-500"> Issue identified at the site located at: {siteAddress + siteAddress1} </span>
                        </Col>
                        <hr ></hr>
                        <Col sm={12} md={12} lg={12} xl={12} className="pt-2">
                          Guardian Connect has identified an issue at the site located at: <span className="sram-font-weight-500">{siteAddress + siteAddress1}</span> <br />
                          <span className="sram-font-weight-500">Site Name:</span> <span> {this.state.siteVisitorData?.siteName} </span><br />
                          <span className="sram-font-weight-500">Ticket Id:</span> <span> {this.state.siteVisitorData?.ticketNumber} </span>   <br />
                          <span className="sram-font-weight-500">SIQ ID:</span> <span> {this.state.siteVisitorData?.siteNumber} </span> <br />
                          <br />
                        </Col> */}
                          {this.state.serviceMessage === false ?
                            <>
                              {this.state.siteVisitorData?.approveStatusDescription === "Pending Approval" ?
                                this.state.status === "approve" ?
                                  <Col sm={12} md={12} lg={12} xl={12} className="pt-2">
                                    <span style={{ fontSize: "1rem" }} className="sram-font-weight-500 vertical-center">Are you sure want to Approve?</span>
                                  </Col>
                                  :
                                  <Col sm={12} md={12} lg={12} xl={12} className="pt-2" >
                                    <span style={{ fontSize: "1rem" }} className="sram-font-weight-500 vertical-center">Are you sure want to Reject?</span>
                                  </Col>
                                : null}
                              <hr ></hr>
                              {/* {this.state.buttonStatus === false ? */}
                              <Col sm={12} md={12} lg={12} className="pt-4">
                                {this.state.siteVisitorData?.approveStatusDescription === "Pending Approval" ?
                                  <div className="vertical-center">
                                    {this.state.status === "approve" ?
                                      <>
                                        {this.state.buttonStatus === false ?
                                          <Button
                                            className={
                                              "sram-btn-blue sram-form-btn mr-3 btn btn-secondary"
                                            }
                                            onClick={this.getVisitorAccept}
                                          >
                                            Yes
                                          </Button>
                                          :
                                          <Button
                                            className={"sram-btn-blue sram-form-btn mr-3 btn btn-secondary disabled-link"}
                                          // onClick={this.getVisitorAccept}
                                          >
                                            Yes &nbsp;
                                            <Spinner
                                              size="sm"
                                              color="light"
                                              style={{ marginBottom: "2px" }}
                                            />
                                          </Button>
                                        }
                                        <Button
                                          className={"sram-btn-white sram-form-btn mr-3 btn btn-secondary"}
                                          onClick={this.close}
                                        >
                                          No
                                        </Button>
                                      </>
                                      :
                                      // this.state.status === "reject" ?
                                      <>
                                        {this.state.buttonStatus === false ?
                                          <Button
                                            className={
                                              "sram-btn-blue sram-form-btn mr-3 btn btn-secondary"
                                            }
                                            onClick={this.getVisitorReject}
                                          >
                                            Yes
                                          </Button>
                                          :
                                          <Button
                                            className={"sram-btn-blue sram-form-btn mr-3 btn btn-secondary disabled-link"}
                                          // onClick={this.getVisitorAccept}
                                          >
                                            Yes &nbsp;
                                            <Spinner
                                              size="sm"
                                              color="light"
                                              style={{ marginBottom: "2px" }}
                                            />
                                          </Button>
                                        }
                                        <Button
                                          className={"sram-btn-white sram-form-btn mr-3 btn btn-secondary"}
                                          onClick={this.close}
                                        >
                                          No
                                        </Button>
                                      </>
                                      // :
                                      // <div className="auth-form pl-4 ml-4 vertical-center sram-font-weight-500">
                                      //   Wrong URL 
                                      // </div>
                                    }
                                  </div>
                                  :
                                  <div className="auth-form pl-2 ml-2  sram-font-weight-500">
                                    This request is already {this.state.siteVisitorData?.approveStatusDescription}
                                  </div>
                                }
                              </Col>
                              {/* : null} */}
                            </>
                            : null}
                          <Col sm={12} md={12} lg={12} className="pt-4 pl-4 ml-4">
                            {this.state.serviceMessageView === true ?
                              <>
                                <div style={{ fontSize: "1rem" }} className="auth-form vertical-center sram-font-weight-500">
                                  {this.state.showMessage}
                                </div>

                                <div style={{ fontSize: "1rem" }} className="auth-form vertical-center sram-font-weight-500">
                                  Thank You
                                </div>
                              </>
                              : null}

                          </Col>
                        </Row>
                      </Row>
                    </div>
                  </Container>
                </WithLoading>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default siteVisitor;

