import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Input,
  Label,
  Alert,
  FormGroup,
  Table,
  Spinner,
} from "reactstrap";
import WithLoading from "../../core/helpers/with-loading";
import Select from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import PageDisplay from "../../core/helpers/page-display";
import { AppMsgResProps } from "../../core/messages/app-properties";
import axios from "axios";
import { AlertMsgResProps } from "../messages/alert-properties";
import { clientNotificationConstants } from "../helpers/alert-utils";
import AppUtils from "../../core/helpers/app-utils";

class ClientApprovalModal extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  constructor(props) {
    super(props);
    this.state = {
      clientNotificationOptions: clientNotificationConstants,
      viewApprovalModel: false,
      selectedNotification: null,
      modalHead: null,
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    // PageUtils.scrollToTop();
    window.appHistory = this.props.history;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  formValidationSchema = () => {
    const schema = {
      approvalComments: Yup.string()
        .trim()
        .required(AlertMsgResProps.body.notification.approveComments.empty),
    };
    return schema;
  };

  formEmailValidationSchema = () => {
    const schema = {
      ticketSubject: Yup.string()
        .trim()
        .required(AlertMsgResProps.body.notification.subject.empty),
      ticketMessageHead: Yup.string()
        .trim()
        .required(AlertMsgResProps.body.notification.message.empty),
      ticketMessageBody: Yup.string()
        .trim()
        .required(AlertMsgResProps.body.notification.message.empty),
      ticketRegrds: Yup.string()
        .trim()
        .required(AlertMsgResProps.body.notification.regards.empty),
    };
    return schema;
  };

  handleOutputTypeChange = (event) => {
    event.persist();
    if (event) {
      this.props.handleOutputTypeChange(event.target.value);
    }
  };

  handleChange = (modalHead, value) => {
    this.setStateAsync({
      viewApprovalModel: !this.state.viewApprovalModel,
      selectedNotification: value,
      modalHead: modalHead,
    });
  };

  handleSelectedEmail = (event) => {
    // event.persist()
    if (event) {
      this.props.handleSelectedEmail(event);
    }
  };

  closeApproveModel = () => {
    this.setStateAsync({ viewApprovalModel: false });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isEmailApprovalModel}
        toggle={() => this.props.closeEmailModel()}
        size="lg"
        backdrop="static"
        // centered={true}
        className="sram-modal-email-content"
      >
        {/* <ModalHeader> */}
        <div className="modal-header">
          <span>
            <h5 className="modal-title">
              {AlertMsgResProps.head.title.clientNotification}
            </h5>
          </span>
          {this.props.userSelectedoption !== null ? (
            <>
              <span
                className="pull-right sram-times"
                style={{ marginLeft: "auto" }}
                title="Go Back"
                onClick={() => this.props.goBacktoNotifications()}
              >
                <i className="fa fa-arrow-circle-left"></i>
              </span>
              &nbsp;&nbsp;{" "}
            </>
          ) : null}
          <span
            className="pull-right sram-times"
            title="Close"
            onClick={() => this.props.closeEmailModel()}
          >
            <i className="fa fa-times-circle"></i>
          </span>
        </div>
        {/* </ModalHeader> */}
        <div className="sram-ticket-view-modal-spinner">
          <WithLoading
            isPageDataFetched={this.props.isemailDataFetched}
            type="page"
            size="md"
          >
            {this.props.userSelectedoption === null ? (
              <PerfectScrollbar className="sram-view-clientNotification-modal">
                <div className="ml-4 pl-3 sram-modal-email-align-items">
                  {this.state.clientNotificationOptions &&
                    this.state.clientNotificationOptions.map((obj) => {
                      return (
                        <>
                          <Row>
                            <Label className="sram-font-weight-500">
                              {obj.title?.key}
                            </Label>
                          </Row>
                          {obj.title?.options &&
                            obj.title?.options.map((opt) => {
                              return (
                                <Row className="ml-3">
                                  <span
                                    onChange={(e) => {
                                      this.handleOutputTypeChange(e);
                                    }}
                                  >
                                    <Label>
                                      <Input
                                        value={opt.key}
                                        type="radio"
                                        name="radio1"
                                      />{" "}
                                      <span className="sram-text-font sram-font-weight-500 ">
                                        {opt.value}
                                      </span>
                                    </Label>
                                  </span>{" "}
                                  {this.props &&
                                  this.props?.clientNotificationHistory[
                                    obj?.title?.key
                                  ][opt?.value]?.length > 0 ? (
                                    <span
                                      onClick={() => {
                                        this.handleChange(
                                          opt.value,
                                          this.props.clientNotificationHistory[
                                            obj?.title?.key
                                          ][opt?.value]
                                        );
                                      }}
                                    >
                                      {/* <i
                                  className="fas fa-info-circle font-18 text-secondary ml-3"
                                  id="clientNotificationOptions"
                                ></i> */}
                                      <i
                                        className="clientNotificationOptions_circle ml-1"
                                        title="View Status"
                                      >
                                        {
                                          this.props?.clientNotificationHistory[
                                            obj?.title?.key
                                          ][opt?.value]?.length
                                        }
                                      </i>
                                    </span>
                                  ) : null}
                                </Row>
                              );
                            })}
                        </>
                      );
                    })}
                </div>
              </PerfectScrollbar>
            ) : null}
          </WithLoading>
        </div>
        {this.props.userSelectedoption !== null ? (
          <Formik
            initialValues={this.props.formInputFields}
            validationSchema={Yup.object().shape(
              this.formEmailValidationSchema()
            )}
            onSubmit={(values, helpers) =>
              this.props.handleEmailConfirmation(values, helpers)
            }
          >
            {(formik) => (
              <Form id="modelForm">
                <ModalBody className="pb-0">
                  <Row className="sram-modal-email-align-items">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Select
                        placeholder="To:"
                        options={this.props.ticketEmails?.users}
                        getOptionLabel={(option) => option?.email}
                        getOptionValue={(option) => option?.email}
                        hasValue={true}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#ced4da",
                            primary: "#ced4da",
                          },
                        })}
                        styles={{
                          option: (base) => ({
                            ...base,
                            color: "#000000",
                          }),
                        }}
                        isMulti
                        onChange={this.handleSelectedEmail}
                        className={
                          this.props.emailValidation === true
                            ? "sram-ticket-view-email-validation"
                            : null
                        }
                      />
                      <div>
                        {this.props.emailValidation === true ? (
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {AlertMsgResProps.body.notification.email.empty}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-2 sram-modal-email-align-items">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Field
                        placeholder="subject:"
                        name="ticketSubject"
                        type="text"
                        maxLength="100"
                        className={PageDisplay.getFormikFieldClassName(
                          formik.errors.ticketSubject,
                          formik.touched.ticketSubject
                        )}
                      />
                      {PageDisplay.getFormikFieldErrorMessage("ticketSubject")}
                    </Col>
                  </Row>
                  <Row className="pt-1">
                    <Col>
                      <Label
                        for="ticketMessageHead"
                        className="sram-font-weight-600 pt-1"
                      >
                        Message:
                      </Label>
                    </Col>
                    <Col>
                      {this.props.emailTextField ? (
                        <Button
                          style={{ color: "white" }}
                          className="pull-right"
                          onClick={() => this.props.handleedittext()}
                        >
                          <span className="fa fa-pencil-alt"></span> Edit
                        </Button>
                      ) : (
                        <Button
                          style={{ color: "white" }}
                          className="pull-right"
                          onClick={() => this.props.handleedittext()}
                        >
                          <span className="fa fa-check"></span> Save
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row className="pt-1">
                    <Col>
                      <Field
                        disabled={this.props.emailTextField}
                        name="ticketMessageHead"
                        as="textarea"
                        rows="1"
                        className={PageDisplay.getFormikFieldClassName(
                          formik.errors.ticketMessageHead,
                          formik.touched.ticketMessageHead
                        )}
                      />
                      {PageDisplay.getFormikFieldErrorMessage(
                        "ticketMessageHead"
                      )}
                    </Col>
                  </Row>
                  <Row className="pt-1 mt-1">
                    <Col>
                      <div>
                        <span className="sram-font-weight-500">
                          {AlertMsgResProps.head.title.siteName}:
                        </span>{" "}
                        <span>{this.props.modelData?.siteName}</span>, &nbsp;
                        <span className="sram-font-weight-500">
                          {AlertMsgResProps.head.title.ticketid}:
                        </span>{" "}
                        <span>{this.props.modelData?.ticketNumber}</span>,
                        &nbsp;
                        <span className="sram-font-weight-500">
                          {AlertMsgResProps.head.title.siteId}:
                        </span>{" "}
                        <span>{this.props.modelData?.siteNumber}</span>, &nbsp;
                        <span className="sram-font-weight-500">
                          {AlertMsgResProps.head.title.warrantystatus}:
                        </span>{" "}
                        <span>
                          {this.props.modelData?.warrantyDescription
                            ? this.props.modelData?.warrantyDescription
                            : "-"}
                        </span>{" "}
                        &nbsp;
                      </div>
                    </Col>
                  </Row>
                  <Row className="pt-1 mt-1">
                    <Col>
                      <Field
                        disabled={this.props.emailTextField}
                        name="ticketMessageBody"
                        as="textarea"
                        rows="3"
                        className={PageDisplay.getFormikFieldClassName(
                          formik.errors.ticketMessageBody,
                          formik.touched.ticketMessageBody
                        )}
                      />
                      {PageDisplay.getFormikFieldErrorMessage(
                        "ticketMessageBody"
                      )}
                    </Col>
                  </Row>
                  <Row className="pt-1 mt-2">
                    <Col>
                      <div className="sram-font-weight-500">
                        {AlertMsgResProps.body.content.issueidentified}
                      </div>
                      <PerfectScrollbar className="sram-table-ticket-view">
                        <Table hover size="sm" className="sram-table-main">
                          <thead>
                            <tr>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {AlertMsgResProps.head.title.dispenserName}
                              </th>
                              {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {AlertMsgResProps.head.title.itemName}
                              </th> */}
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {AlertMsgResProps.head.title.eventCause}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {AlertMsgResProps.head.title.componentName}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {AlertMsgResProps.head.title.eventStatus}
                              </th>
                              <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                {AlertMsgResProps.head.title.eventTimeStamp}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.modelData?.alertTickets &&
                            this.props.modelData?.alertTickets.length > 0 ? (
                              this.props.modelData?.alertTickets?.map(
                                (obj, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.dispenserName}
                                      </td>
                                      {/* <td className="sram-text-font sram-table-td">
                                      {obj.itemName}
                                    </td> */}
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventCause}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.componentName}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventStatus}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventTimestamp}
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td className="sram-no-data">
                                  {AlertMsgResProps.body.content.noDataFound}
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </PerfectScrollbar>
                    </Col>
                  </Row>
                  <Row className="pt-1">
                    <Col>
                      <FormGroup>
                        <Field
                          disabled={this.props.emailTextField}
                          name="ticketRegrds"
                          as="textarea"
                          rows="3"
                          className={PageDisplay.getFormikFieldClassName(
                            formik.errors.ticketRegrds,
                            formik.touched.ticketRegrds
                          )}
                        />
                        {PageDisplay.getFormikFieldErrorMessage("ticketRegrds")}
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.props.responseMailData !== null ? (
                    <Col sm={12} md={12} lg={12}>
                      <Alert
                        color="secondary"
                        fade={true}
                        className="bg-white border-secondary rounded-lg text-secondary mb-4"
                      >
                        <span className="d-inline float-left mr-3">
                          <i className="fas fa-exclamation-circle font-22"></i>
                        </span>
                        <span> {this.props.responseMailData}</span>
                      </Alert>
                    </Col>
                  ) : null}
                </ModalBody>
                <ModalFooter>
                  {this.props.responseMailData === null ? (
                    <Button className="sram-btn-blue mx-2" type="submit">
                      {AlertMsgResProps.body.form.submit} &nbsp;
                      {this.props.submitspinner === true ? (
                        <Spinner
                          size="sm"
                          color="light"
                          style={{ marginBottom: "2px" }}
                        />
                      ) : null}
                    </Button>
                  ) : null}
                  <Button
                    type="button"
                    className="sram-btn-white mx-2"
                    onClick={() => this.props.closeEmailModel()}
                  >
                    {AlertMsgResProps.body.form.cancel}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        ) : null}

        <Modal
          isOpen={this.state.viewApprovalModel}
          toggle={() => this.closeApproveModel()}
          size="lg"
          backdrop="static"
          // centered={true}
          className=""
        >
          {/* <ModalHeader> */}
          <div className="modal-header">
            <span>
              <h5 className="modal-title">{this.state.modalHead}</h5>
            </span>
            <span
              className="pull-right sram-times"
              title="Close"
              onClick={() => this.closeApproveModel()}
            >
              <i className="fa fa-times-circle"></i>
            </span>
          </div>
          {/* </ModalHeader> */}
          <div className="">
            <PerfectScrollbar className="sram-view-clientNotification-modal">
              <Col sm="12" className="pb-2">
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th className="sram-text-font sram-font-weight-600 sram-table-th">
                        {AlertMsgResProps.body.form.sno}
                      </th>
                      <th className="sram-text-font sram-font-weight-600 sram-table-th">
                        {AlertMsgResProps.body.form.emailSentTO}
                      </th>
                      <th className="sram-text-font sram-font-weight-600 sram-table-th">
                        {AlertMsgResProps.body.form.emailStatus}
                      </th>
                      <th className="sram-text-font sram-font-weight-600 sram-table-th">
                        {AlertMsgResProps.body.form.emailConfirmation}
                      </th>
                      <th className="sram-text-font sram-font-weight-600 sram-table-th">
                        {AlertMsgResProps.body.form.createTimeStamp}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.selectedNotification &&
                    this.state.selectedNotification.length > 0 ? (
                      this.state.selectedNotification.map(
                        (alertsData, index) => {
                          return (
                            <tr key={index}>
                              <td className="sram-text-font sram-table-td">
                                {index + 1}
                              </td>
                              <td className="sram-text-font sram-table-td">
                                {alertsData.email_sent_to
                                  ? alertsData.email_sent_to
                                  : "-"}
                              </td>
                              <td className="sram-text-font sram-table-td">
                                {alertsData.email_sent_status
                                  ? alertsData.email_sent_status
                                  : "-"}
                              </td>
                              <td className="sram-text-font sram-table-td">
                                {alertsData.email_confirm_status
                                  ? alertsData.email_confirm_status
                                  : "-"}
                              </td>
                              <td className="sram-text-font sram-table-td">
                                {alertsData.create_timestamp
                                  ? AppUtils.getDateTime(
                                      alertsData.create_timestamp
                                    )
                                  : "-"}
                              </td>
                            </tr>
                          );
                        }
                      )
                    ) : (
                      <tr>
                        <td className="sram-no-data">
                          {AlertMsgResProps.body.content.noDataFound}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </PerfectScrollbar>
          </div>
        </Modal>
      </Modal>
    );
  }
}

export default ClientApprovalModal;
