export const SiteImportMsgResProps = {
    head: {
        title: {
            softwareVersions: "Software Versions",
            importHistory: "Sites Import History"
        },
    },
    body: {
        content: {
            noSites: "Sites not in UI",
            sites: "Sites (GVR ID's)",
            sitesCount: "Sites Count",
            dispenserId: "Dispenser ID's",
            comment: "Comments",
            status: "Status",
            goBack: "Go Back",
            uploadTimestamp: "Uploaded Timestamp",
            site: "Sites",
            siteName: "Site Name",
            siteId: " GVR ID",
            userName: "Username",
            address: "Site Address"
        },
        form: {
            uploadFile: {
                label: "Upload Excel File"
            }
        },
        notification: {
            noDataFound: "No data found.",
            error: {
                uploadError: "The following sites not added to Guardian Connect website"
            },
            confirmation: {
                versionDetailTitle: function (versionName) {
                    return `${versionName} details will be updated for the following sites.`;
                },
                versionNoTitle: function (versionName) {
                    return `${versionName} technical details not found for the given sites.`;
                },
            },
            success: {},
        },
    },
};

export const siteImportSteps = [
    { index: 1, key: 'softwareVersions', value: 'Software Versions' },
    { index: 2, key: 'ssom', value: 'SSOM' },
    { index: 3, key: 'encore-pump', value: 'Encore Pump' },
    { index: 4, key: 'upm5', value: 'UPM 5' },
    { index: 5, key: 'upm7', value: 'UPM 7' },
    { index: 6, key: 'gsom', value: 'GSOM' },
    { index: 7, key: 'omnia', value: 'Omnia' },
]