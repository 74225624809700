import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  Collapse,
  CardBody,
  Row,
  Col,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { DispenserMsgResProps } from "../messages/dispenser-properties";
import PerfectScrollbar from "react-perfect-scrollbar";
import WithLoading from "../../core/helpers/with-loading";
import AppUtils from "../../core/helpers/app-utils";
// import DispenserUtils from "../helpers/dispenser-utils";
// import Button from "reactstrap-button-loader";
// import ErrorDetailsModal from "./error-details-modal";
import { AppMsgResProps } from "../../core/messages/app-properties";
import axios from "axios";

class DispenserComponentFailure extends Component {
  _isMounted = false;
  _axiosSource = axios.CancelToken.source();
  _cancelToken = { cancelToken: this._axiosSource.token };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
      activeTab: "1",
      setActiveTab: "1",
    };
  }

  setStateAsync = (state) => {
    if (this._isMounted) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }
  };

  async componentDidMount() {
    this._isMounted = true;
    // PageUtils.scrollToTop();
    window.appHistory = this.props.history;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this._axiosSource.cancel(
      AppMsgResProps.body.notification.warning.requestCancelled
    );
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab, setActiveTab: tab });
    }
  }
  handleComponentFailure() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    return (
      <Card className="sram-page-form sram-dispenser-card-bottom">
        <CardTitle
          onClick={this.handleComponentFailure.bind(this)}
          className="sram-title-cursor"
        >
          <span>{DispenserMsgResProps.head.title.failure}</span>
          {/* <div className="sram-title-sub-adj">
            {DispenserMsgResProps.head.title.installDate} :{" "}
            {AppUtils.getDateTime(this.props.installTime)}
          </div> */}
        </CardTitle>
        <Collapse isOpen={this.state.isOpen}>
          <CardBody className="sram-dispenser-section-1-errorlogs sram-dispenser-card-bdy">
            <div>
              <Nav tabs>
                <NavItem className="sram-nav-active sram-title-cursor">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1",
                    })}
                    onClick={this.toggle.bind(this, "1")}
                  >
                    <span>{DispenserMsgResProps.body.content.all}</span>
                  </NavLink>
                </NavItem>
                {/* <NavItem className="sram-nav-active sram-title-cursor">
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "2",
                    })}
                    onClick={this.toggle.bind(this, "2")}
                  >
                    <span>{DispenserMsgResProps.body.content.en_pump}</span>
                  </NavLink>
                </NavItem> */}
                {this.props.showSSOM ? (
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={this.toggle.bind(this, "3")}
                    >
                      <span>{DispenserMsgResProps.body.content.ssom}</span>
                    </NavLink>
                  </NavItem>
                ) : null}
                {/* {this.props.showGSOM ? (
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={this.toggle.bind(this, "4")}
                    >
                      <span>{DispenserMsgResProps.body.content.gsom}</span>
                    </NavLink>
                  </NavItem>
                ) : null} */}
                {this.props.showCardReader ? (
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "5",
                      })}
                      onClick={this.toggle.bind(this, "5")}
                    >
                      <span>
                        {DispenserMsgResProps.body.content.cardReader}
                      </span>
                    </NavLink>
                  </NavItem>
                ) : null}
                {this.props.showUPM ? (
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "6",
                      })}
                      onClick={this.toggle.bind(this, "6")}
                    >
                      <span>{DispenserMsgResProps.body.content.upm}</span>
                    </NavLink>
                  </NavItem>
                ) : null}
                {this.props.showDisplay ? (
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "7",
                      })}
                      onClick={this.toggle.bind(this, "7")}
                    >
                      <span>{DispenserMsgResProps.body.content.display}</span>
                    </NavLink>
                  </NavItem>
                ) : null}
                {this.props.showPrinter ? (
                  <NavItem className="sram-nav-active sram-title-cursor">
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "8",
                      })}
                      onClick={this.toggle.bind(this, "8")}
                    >
                      <span>{DispenserMsgResProps.body.content.printer}</span>
                    </NavLink>
                  </NavItem>
                ) : null}
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <WithLoading
                    isPageDataFetched={this.props.isPageDataFetched}
                    type="page"
                    size="sm"
                  >
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <PerfectScrollbar className="sram-dispenser-card-body-height">
                            <Table>
                              <thead>
                                <tr>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.componentName
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.side.label}
                                  </th>
                                  {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {DispenserMsgResProps.body.itemName.label}
                                    </th> */}
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.eventCause.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventTimeStamp
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.closedTimeStamp
                                        .label
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.componentsData &&
                                this.props.componentsData.length > 0 ? (
                                  this.props.componentsData.map(
                                    (obj, index) => {
                                      // const description = DispenserUtils.capitalize(
                                      //   obj.errorDescription
                                      // );
                                      return (
                                        <tr key={index}>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.componentName
                                              ? obj.componentName
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.sideLabel
                                              ? obj.sideLabel
                                              : "-"}
                                          </td>
                                          {/* <td className="sram-text-font sram-table-td">
                                            {obj.itemName ? obj.itemName : "-"}
                                          </td> */}
                                          <td className="sram-text-font sram-table-td">
                                            {obj.eventStatus
                                              ? obj.eventStatus
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.eventCause
                                              ? obj.eventCause
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.eventTimestamp
                                              ? AppUtils.getDateTime(
                                                  obj.eventTimestamp
                                                )
                                              : "-"}
                                          </td>
                                          <td className="sram-text-font sram-table-td">
                                            {obj.closedTimestamp
                                              ? AppUtils.getDateTime(
                                                  obj.closedTimestamp
                                                )
                                              : "-"}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  )
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </PerfectScrollbar>
                        </Card>
                      </Col>
                    </Row>
                  </WithLoading>
                </TabPane>

                {/* <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <Card body className="sram-dispenser-card-bdy1">
                        <PerfectScrollbar className="sram-dispenser-card-body-height">
                          {this.props.encorePump &&
                            this.props.encorePump.length > 0 ? (
                            <Table>
                              <thead>
                                <tr>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.componentName
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.itemName.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.eventCause.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventTimeStamp
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.closedTimeStamp
                                        .label
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.encorePump.map((obj, index) => {
                                  // const description = DispenserUtils.capitalize(
                                  //   obj.errorDescription
                                  // );
                                  return (
                                    <tr key={index}>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.componentName ? obj.componentName : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.itemName ? obj.itemName : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventStatus ? obj.eventStatus : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventCause ? obj.eventCause : "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.eventTimestamp ? AppUtils.getDateTime(
                                          obj.eventTimestamp
                                        ): "-"}
                                      </td>
                                      <td className="sram-text-font sram-table-td">
                                        {obj.closedTimestamp ? AppUtils.getDateTime(
                                          obj.closedTimestamp
                                        ): "-"}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          ) : (
                            <div className="pt-3 py-3 sram-no-data">
                              {DispenserMsgResProps.body.content.noIssuesFound}
                            </div>
                          )}
                        </PerfectScrollbar>
                      </Card>
                    </Col>
                  </Row>
                </TabPane> */}
                {this.props.showSSOM ? (
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <PerfectScrollbar className="sram-dispenser-card-body-height">
                            <Table>
                              <thead>
                                <tr>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.componentName
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.side.label}
                                  </th>
                                  {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {DispenserMsgResProps.body.itemName.label}
                                    </th> */}
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.eventCause.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventTimeStamp
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.closedTimeStamp
                                        .label
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.ssom &&
                                this.props.ssom.length > 0 ? (
                                  this.props.ssom.map((obj, index) => {
                                    // const description = DispenserUtils.capitalize(
                                    //   obj.errorDescription
                                    // );
                                    return (
                                      <tr key={index}>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.componentName
                                            ? obj.componentName
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.sideLabel ? obj.sideLabel : "-"}
                                        </td>
                                        {/* <td className="sram-text-font sram-table-td">
                                          {obj.itemName ? obj.itemName : "-"}
                                        </td> */}
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventStatus
                                            ? obj.eventStatus
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventCause
                                            ? obj.eventCause
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventTimestamp
                                            ? AppUtils.getDateTime(
                                                obj.eventTimestamp
                                              )
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.closedTimestamp
                                            ? AppUtils.getDateTime(
                                                obj.closedTimestamp
                                              )
                                            : "-"}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </PerfectScrollbar>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                ) : null}
                {/* {this.props.showGSOM ? (
                  <TabPane tabId="4">
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <PerfectScrollbar className="sram-dispenser-card-body-height">
                            {this.props.gsom && this.props.gsom.length > 0 ? (
                              <Table>
                                <thead>
                                  <tr>
                                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {
                                        DispenserMsgResProps.body.componentName
                                          .label
                                      }
                                    </th>
                                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {DispenserMsgResProps.body.itemName.label}
                                    </th>
                                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {
                                        DispenserMsgResProps.body.eventStatus
                                          .label
                                      }
                                    </th>
                                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {
                                        DispenserMsgResProps.body.eventCause
                                          .label
                                      }
                                    </th>
                                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {
                                        DispenserMsgResProps.body.eventTimeStamp
                                          .label
                                      }
                                    </th>
                                    <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {
                                        DispenserMsgResProps.body.closedTimeStamp
                                          .label
                                      }
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.props.gsom.map((obj, index) => {
                                    return (
                                      <tr key={index}>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.componentName ? obj.componentName : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.itemName ? obj.itemName : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventStatus ? obj.eventStatus : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventCause ? obj.eventCause : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventTimestamp ? AppUtils.getDateTime(
                                            obj.eventTimestamp
                                          ): "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.closedTimestamp ? AppUtils.getDateTime(
                                            obj.closedTimestamp
                                          ): "-"}
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            ) : (
                              <div className="pt-3 py-3 sram-no-data">
                                {DispenserMsgResProps.body.content.noIssuesFound}
                              </div>
                            )}
                          </PerfectScrollbar>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                ) : null} */}
                {this.props.showCardReader ? (
                  <TabPane tabId="5">
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <PerfectScrollbar className="sram-dispenser-card-body-height">
                            <Table>
                              <thead>
                                <tr>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.componentName
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.side.label}
                                  </th>
                                  {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {DispenserMsgResProps.body.itemName.label}
                                    </th> */}
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.eventCause.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventTimeStamp
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.closedTimeStamp
                                        .label
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.cardReader &&
                                this.props.cardReader.length > 0 ? (
                                  this.props.cardReader.map((obj, index) => {
                                    // const description = DispenserUtils.capitalize(
                                    //   obj.errorDescription
                                    // );
                                    return (
                                      <tr key={index}>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.componentName
                                            ? obj.componentName
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.sideLabel ? obj.sideLabel : "-"}
                                        </td>
                                        {/* <td className="sram-text-font sram-table-td">
                                          {obj.itemName ? obj.itemName : "-"}
                                        </td> */}
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventStatus
                                            ? obj.eventStatus
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventCause
                                            ? obj.eventCause
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventTimestamp
                                            ? AppUtils.getDateTime(
                                                obj.eventTimestamp
                                              )
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.closedTimestamp
                                            ? AppUtils.getDateTime(
                                                obj.closedTimestamp
                                              )
                                            : "-"}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </PerfectScrollbar>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                ) : null}
                {this.props.showUPM ? (
                  <TabPane tabId="6">
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <PerfectScrollbar className="sram-dispenser-card-body-height">
                            <Table>
                              <thead>
                                <tr>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.componentName
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.side.label}
                                  </th>
                                  {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {DispenserMsgResProps.body.itemName.label}
                                    </th> */}
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.eventCause.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventTimeStamp
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.closedTimeStamp
                                        .label
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.upm && this.props.upm.length > 0 ? (
                                  this.props.upm.map((obj, index) => {
                                    // const description = DispenserUtils.capitalize(
                                    //   obj.errorDescription
                                    // );
                                    return (
                                      <tr key={index}>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.componentName
                                            ? obj.componentName
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.sideLabel ? obj.sideLabel : "-"}
                                        </td>
                                        {/* <td className="sram-text-font sram-table-td">
                                          {obj.itemName ? obj.itemName : "-"}
                                        </td> */}
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventStatus
                                            ? obj.eventStatus
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventCause
                                            ? obj.eventCause
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventTimestamp
                                            ? AppUtils.getDateTime(
                                                obj.eventTimestamp
                                              )
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.closedTimestamp
                                            ? AppUtils.getDateTime(
                                                obj.closedTimestamp
                                              )
                                            : "-"}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </PerfectScrollbar>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                ) : null}
                {this.props.showDisplay ? (
                  <TabPane tabId="7">
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <PerfectScrollbar className="sram-dispenser-card-body-height">
                            <Table>
                              <thead>
                                <tr>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.componentName
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.side.label}
                                  </th>
                                  {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {DispenserMsgResProps.body.itemName.label}
                                    </th> */}
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.eventCause.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventTimeStamp
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.closedTimeStamp
                                        .label
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.display &&
                                this.props.display.length > 0 ? (
                                  this.props.display.map((obj, index) => {
                                    // const description = DispenserUtils.capitalize(
                                    //   obj.errorDescription
                                    // );
                                    return (
                                      <tr key={index}>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.componentName
                                            ? obj.componentName
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.sideLabel ? obj.sideLabel : "-"}
                                        </td>
                                        {/* <td className="sram-text-font sram-table-td">
                                          {obj.itemName ? obj.itemName : "-"}
                                        </td> */}
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventStatus
                                            ? obj.eventStatus
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventCause
                                            ? obj.eventCause
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventTimestamp
                                            ? AppUtils.getDateTime(
                                                obj.eventTimestamp
                                              )
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.closedTimestamp
                                            ? AppUtils.getDateTime(
                                                obj.closedTimestamp
                                              )
                                            : "-"}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </PerfectScrollbar>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                ) : null}
                {this.props.showPrinter ? (
                  <TabPane tabId="8">
                    <Row>
                      <Col sm="12">
                        <Card body className="sram-dispenser-card-bdy1">
                          <PerfectScrollbar className="sram-dispenser-card-body-height">
                            <Table>
                              <thead>
                                <tr>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.componentName
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.side.label}
                                  </th>
                                  {/* <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                      {DispenserMsgResProps.body.itemName.label}
                                    </th> */}
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventStatus
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {DispenserMsgResProps.body.eventCause.label}
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.eventTimeStamp
                                        .label
                                    }
                                  </th>
                                  <th className="sram-text-font sram-font-weight-600 sram-table-th">
                                    {
                                      DispenserMsgResProps.body.closedTimeStamp
                                        .label
                                    }
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.props.printer &&
                                this.props.printer.length > 0 ? (
                                  this.props.printer.map((obj, index) => {
                                    // const description = DispenserUtils.capitalize(
                                    //   obj.errorDescription
                                    // );
                                    return (
                                      <tr key={index}>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.componentName
                                            ? obj.componentName
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.sideLabel ? obj.sideLabel : "-"}
                                        </td>
                                        {/* <td className="sram-text-font sram-table-td">
                                          {obj.itemName ? obj.itemName : "-"}
                                        </td> */}
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventStatus
                                            ? obj.eventStatus
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventCause
                                            ? obj.eventCause
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.eventTimestamp
                                            ? AppUtils.getDateTime(
                                                obj.eventTimestamp
                                              )
                                            : "-"}
                                        </td>
                                        <td className="sram-text-font sram-table-td">
                                          {obj.closedTimestamp
                                            ? AppUtils.getDateTime(
                                                obj.closedTimestamp
                                              )
                                            : "-"}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="6"
                                      className="pt-3 py-3 sram-no-data"
                                    >
                                      {
                                        DispenserMsgResProps.body.content
                                          .noIssuesFound
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </PerfectScrollbar>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                ) : null}
              </TabContent>
            </div>
          </CardBody>
        </Collapse>
      </Card>
    );
  }
}

export default DispenserComponentFailure;
